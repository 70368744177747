import { FastTagType } from "common/clients/fastTag/FastTagType";
import { combineReducers } from "redux";
import { FAST_TAG_CONNECT } from "./model/FastTagOperation";
import { fastTagFlows } from "./model/flows";
// needed to type any since reduce struggles to account for intermediate state and final state when typing
export const fastTagFlowsInitialState = Object.keys(fastTagFlows).reduce((combinedFastTags, fastTag) => {
  return {
    ...combinedFastTags,
    [fastTag]: Object.entries(fastTagFlows[fastTag]).reduce((combinedFlows, [operation, flow]) => {
      return {
        ...combinedFlows,
        [operation]: flow.initialState
      };
    }, {})
  };
}, {});
export const fastTagFlowsReducer = combineReducers(Object.keys(fastTagFlows).reduce((combinedFastTags, fastTag) => {
  return {
    ...combinedFastTags,
    [fastTag]: combineReducers(Object.entries(fastTagFlows[fastTag]).reduce((combinedFlows, [operation, flow]) => {
      return {
        ...combinedFlows,
        [operation]: flow.reducer
      };
    }, {}))
  };
}, {}));