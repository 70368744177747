import { defineMessages } from "react-intl";
export const OnboardingMessages = defineMessages({
  continue: {
    id: "onboarding.common.continue",
    defaultMessage: "Continue"
  },
  requiredField: {
    id: "onboarding.common.requiredField",
    defaultMessage: "{field} is required."
  },
  apiKey: {
    id: "onboarding.apiKey",
    defaultMessage: "API Key"
  },
  previous: {
    id: "onboarding.common.previous",
    defaultMessage: "Previous"
  }
});