import { useSelector } from "react-redux";
import { getSellerId } from "common/user/UserSelectors";
import { useFetchFreightState } from "./useFetchFreightState";
import { getLoadedShipmentId } from "inbounds/store/selectors/shipments";
export const useLegacyFreightBooking = () => {
  const sellerId = useSelector(getSellerId);
  const shipmentId = useSelector(getLoadedShipmentId);
  const {
    data,
    isLoading
  } = useFetchFreightState({
    sellerId,
    shipmentId
  });
  if (!shipmentId || isLoading || !data) {
    return false;
  }
  const isLegacyFreightBooking = data?.[shipmentId].rateQuoteResult?.quotes?.[0]?.shippingProvider !== "Freight Service";
  return isLegacyFreightBooking;
};