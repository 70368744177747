import { notifyUserOfError } from "common/ErrorToast";
import { appendListType, ListType } from "common/list/ListType";
import { createActionCreator } from "common/ReduxUtils";
import { searchServiceFor } from "common/search/Factory";
import { FeatureName, isFeatureOn } from "common/Split";
import log, { logError } from "Logger";
export let ListActionTypes;
(function (ListActionTypes) {
  ListActionTypes["SEARCH_INDEX"] = "SEARCH_INDEX";
  ListActionTypes["SET_SEARCH_TERM"] = "SET_SEARCH_TERM";
  ListActionTypes["SET_PAGE_CHECKED"] = "SET_PAGE_CHECKED";
  ListActionTypes["SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST"] = "SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST";
  ListActionTypes["SET_HIT_CHECKED"] = "SET_HIT_CHECKED";
  ListActionTypes["SET_SORT"] = "SET_SORT";
  ListActionTypes["SET_PAGE"] = "SET_PAGE";
  ListActionTypes["CLEAR_ALL_CHECKED"] = "CLEAR_ALL_CHECKED";
})(ListActionTypes || (ListActionTypes = {}));
export const createListActions = listType => {
  const searchIndexLocally = async ({
    searchTerm,
    filters = [],
    numericFilter,
    customizedOpenSearchFilters,
    clearCacheOnSearch,
    pageSize,
    page,
    sort
  }) => {
    const ctx = {
      fn: "searchIndexLocally",
      listType,
      searchTerm
    };
    log.info({
      ...ctx
    }, "searching index");

    // Override searchService if listType is inventory and feature is on
    const searchService = getSearchService(listType);
    try {
      const newSearchTerm = searchTerm ?? "";
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const newPage = page || 0;
      const searchResult = await searchService.execute({
        searchTerm: newSearchTerm,
        page: newPage,
        sort,
        filters,
        numericFilter,
        customizedOpenSearchFilters,
        clearCacheOnSearch,
        pageSize
      });
      log.info(ctx, "retrieved results");
      return searchResult;
    } catch (err) {
      logError(ctx, err, "error searching index");
      throw new Error(err);
    }
  };
  const searchIndex = ({
    searchTerm,
    filters = [],
    numericFilter,
    customizedOpenSearchFilters,
    clearCacheOnSearch,
    page,
    sort
  }) => async (dispatch, getState) => {
    const state = getState();
    const {
      user: {
        resultsPerPage
      }
    } = state;
    try {
      const {
        response,
        hits: listItems
      } = await searchIndexLocally({
        searchTerm,
        filters,
        numericFilter,
        customizedOpenSearchFilters,
        clearCacheOnSearch,
        pageSize: resultsPerPage[listType],
        page,
        sort
      });
      dispatch({
        type: appendListType(ListActionTypes.SEARCH_INDEX, listType),
        listItems,
        page: response.page,
        totalPages: response.nbPages,
        listItemCount: response.nbHits,
        itemsPerPage: response.hitsPerPage
      });
    } catch (err) {
      notifyUserOfError({
        err,
        toastId: "searchIndexError"
      });
    }
  };
  const setSearchTerm = createActionCreator(appendListType(ListActionTypes.SET_SEARCH_TERM, listType), "searchTerm");
  const setPageChecked = createActionCreator(appendListType(ListActionTypes.SET_PAGE_CHECKED, listType), "checked");
  const setRemovalPagePartiallyChecked = createActionCreator(appendListType(ListActionTypes.SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST, listType), "checked");
  const setHitChecked = createActionCreator(appendListType(ListActionTypes.SET_HIT_CHECKED, listType), "hitIndex", "checked");
  const clearAllChecked = createActionCreator(appendListType(ListActionTypes.CLEAR_ALL_CHECKED, listType));
  const setSort = createActionCreator(appendListType(ListActionTypes.SET_SORT, listType), "sort");
  const setPage = createActionCreator(appendListType(ListActionTypes.SET_PAGE, listType), "page");
  const clearSearchCache = () => (_, getState) => {
    getSearchService(listType).clearCache();
  };
  const overrideListType = lt => {
    if (lt === ListType.Inventory && isFeatureOn(FeatureName.LogisticsSearchInventoryListing)) {
      return ListType.InventoryV2;
    }
    if (lt === ListType.Bundle && isFeatureOn(FeatureName.LogisticsSearchInventoryListing)) {
      return ListType.BundleV2;
    }
    return lt;
  };
  const getSearchService = lt => {
    const finalListType = overrideListType(lt);
    // Get search service singleton function
    return searchServiceFor(finalListType);
  };
  return {
    getSearchService,
    searchIndex,
    searchIndexLocally,
    setSearchTerm,
    setPageChecked,
    setRemovalPagePartiallyChecked,
    setHitChecked,
    clearAllChecked,
    setSort,
    setPage,
    clearSearchCache
  };
};