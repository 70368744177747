import { defineMessages } from "react-intl";
export const replenishmentOrderDetailLabels = {
  order: {
    costs: defineMessages({
      actualCostTitle: {
        id: "replenishment.orderDetails.costs.actualCostTitle",
        defaultMessage: "Actual Cost"
      },
      estimatedCostTitle: {
        id: "replenishment.orderDetails.costs.estimatedCostTitle",
        defaultMessage: "Estimated Cost"
      },
      viewDetails: {
        id: "replenishment.orderDetails.costs.viewDetails",
        defaultMessage: "View Details"
      },
      actualTotalTitle: {
        id: "replenishment.orderDetails.costs.actualCostTitle",
        defaultMessage: "Total Cost"
      },
      tbdCost: {
        id: "replenishment.orderDetails.costs.tbdCost",
        defaultMessage: "TBD"
      }
    }),
    pricingSummary: defineMessages({
      title: {
        id: "replenishment.orderDetails.pricingSummary.title",
        defaultMessage: "Pricing Summary"
      }
    })
  }
};