import { defineMessages } from "react-intl";
export const StorageShipmentConfirmationMessages = defineMessages({
  CARGO_TYPE_TITLE: {
    id: "storage.inbounds.detail.confirmation.cargoTypeTitle",
    defaultMessage: "Cargo type"
  },
  SHIPPING_TITLE: {
    id: "storage.inbounds.detail.confirmation.shippingTitle",
    defaultMessage: "Shipping"
  },
  RECEIVED_TITLE: {
    id: "storage.inbounds.detail.confirmation.receivedTitle",
    defaultMessage: "Received"
  },
  TOTAL_COST_TITLE: {
    id: "storage.inbounds.detail.confirmation.totalCostTitle",
    defaultMessage: "Estimated total receiving cost"
  },
  ESTIMATED_TOTAL_COST_TITLE: {
    id: "storage.inbounds.detail.confirmation.estimatedTotalCostTitle",
    defaultMessage: "Estimated cost upon receiving"
  },
  PALLET_CARGO_TYPE_CONTENT: {
    id: "storage.inbounds.detail.confirmation.palletCargoTypeContent",
    defaultMessage: "Pallets"
  },
  PALLET_SHIPPING_CONTENT: {
    id: "storage.inbounds.detail.confirmation.palletShippingContent",
    defaultMessage: "{palletCount, plural, one {# pallet} other {# pallets}}"
  },
  PALLET_SHIPPING_DETAILS: {
    id: "storage.inbounds.detail.confirmation.palletShippingDetails",
    defaultMessage: "{skuCount, plural, one {# SKU} other {# SKUs}} · {caseCount, plural, one {# case} other {# cases}} · {unitCount, plural, one {# unit} other {# units}}"
  },
  PALLET_EXPECTED_SHIPPING_DETAILS: {
    id: "storage.inbounds.detail.confirmation.palletExpectedShippingDetails",
    defaultMessage: "Expected · {palletCount, plural, one {# pallet} other {# pallets}} · {skuCount, plural, one {# SKU} other {# SKUs}} · {caseCount, plural, one {# case} other {# cases}} · {unitCount, plural, one {# unit} other {# units}}"
  },
  FLOOR_LOADED_CARGO_TYPE_CONTENT: {
    id: "storage.inbounds.detail.confirmation.floorLoadedCargoTypeContent",
    defaultMessage: "Floor loaded"
  },
  FLOOR_LOADED_SHIPPING_CONTENT: {
    id: "storage.inbounds.detail.confirmation.floorLoadedShippingContent",
    defaultMessage: "{caseCount, plural, one {# case} other {# cases}}"
  },
  FLOOR_LOADED_SHIPPING_DETAILS: {
    id: "storage.inbounds.detail.confirmation.floorLoadedShippingDetails",
    defaultMessage: "{skuCount, plural, one {# SKU} other {# SKUs}} · {unitCount, plural, one {# unit} other {# units}}"
  },
  FLOOR_LOADED_EXPECTED_SHIPPING_DETAILS: {
    id: "storage.inbounds.detail.confirmation.floorLoadedExpectedShippingDetails",
    defaultMessage: "Expected · {skuCount, plural, one {# SKU} other {# SKUs}} · {caseCount, plural, one {# case} other {# cases}} · {unitCount, plural, one {# unit} other {# units}}"
  },
  PRICING_SUMMARY_TABLE_UNIT_COST_TITLE: {
    id: "storage.inbounds.detail.confirmation.pricingSummaryTable.unitCost",
    defaultMessage: "Cost per Pallet"
  },
  PRICING_SUMMARY_TABLE_AMOUNT_TITLE: {
    id: "storage.inbounds.detail.confirmation.pricingSummaryTable.amount",
    defaultMessage: "Amount"
  },
  PRICING_SUMMARY_TABLE_INBOUND_FLOOR_LOADED_HANDLING_ROW_LABEL: {
    id: "storage.inbounds.detail.confirmation.pricingSummaryTable.inboundFloorLoadedHandlingRowLabel",
    defaultMessage: "Inbound handling for {containerSize}"
  },
  ESTIMATED_TOTAL_LABEL: {
    id: "storage.inbounds.detail.confirmation.pricingSummaryTable.estimatedTotalLabel",
    defaultMessage: "Estimated total upon receiving"
  },
  CARGO_READY_DATE: {
    id: "storage.inbounds.detail.confirmation.cargoReadyDate",
    defaultMessage: "Cargo Ready Date"
  },
  CARGO_READY_TIME_WINDOW: {
    id: "storage.inbounds.detail.confirmation.cargoReadyTimeWindow",
    defaultMessage: "{startTime} - {endTime} {timezone}"
  }
});