import { useEffect, useState } from "react";

/**
 * Load a third-party script using react hooks
 * @param url - URL you'd like to load
 * @param loadCondition - the condition under which you'd like to load the script
 * @param attributes - object of attribute keys and values
 */

export const useScript = (url, loadCondition = true, attributes) => {
  const [status, setStatus] = useState("loading");
  useEffect(() => {
    if (loadCondition) {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      if (attributes) {
        Object.keys(attributes).forEach(key => {
          script.setAttribute(key, attributes[key]);
        });
      }
      document.body.appendChild(script);
      const setStateFromEvent = event => {
        setStatus(event.type === "load" ? "ready" : "error");
      };
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);
      return () => {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
        document.body.removeChild(script);
      };
    } else {
      return;
    }
  }, [url, loadCondition, attributes]);
  return status;
};