import styled from "@emotion/styled";
import { isMobile } from "../shared/helpers/utils";
import { TextAlign } from "../shared";
const setSize = (size = "body", theme) => {
  const sizes = {
    navigation: "0.68rem",
    caption: theme.font.size.F1,
    body: isMobile(theme) ? theme.font.size.F3 : theme.font.size.F2,
    label: theme.font.size.F2,
    bodyLarge: theme.font.size.F3,
    h2: theme.font.size.F6
  };
  return `font-size: ${sizes[size]};`;
};
export const Text = styled.p(({
  theme,
  appearance,
  bold,
  uppercase,
  size,
  textAlign = TextAlign.start
}) => `
  margin-bottom: 0;
  color: ${appearance ? theme.colors[theme.config.colorByAppearance[appearance]][300] : "inherit"};
  font-weight: ${bold ? theme.font.weight.BOLD : theme.font.weight.REGULAR};
  text-transform: ${uppercase ? `uppercase` : `none`};
  text-align: ${textAlign};
  ${setSize(size, theme)}
`);