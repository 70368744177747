import { parseISO } from "date-fns";
import { createSelector } from "reselect";
import { SupportedNotificationTypes } from "./SupportedNotificationType";
export const isNotificationsSuccess = ({
  notifications
}) => !notifications.loading && !notifications.error;
export const isNotificationsError = ({
  notifications
}) => notifications.error;
export const getNotifications = createSelector(state => state.notifications.byId, state => state.notifications.sortedIds, (byId, sortedIds) => sortedIds.map(id => byId[id]).map(notification => ({
  ...notification,
  createdAt: parseISO(notification.createdAt),
  updatedAt: parseISO(notification.updatedAt)
})).filter(notification => SupportedNotificationTypes.includes(notification.type)));
export const areAnyUnviewedNotifications = ({
  notifications
}) => !notifications.viewed && notifications.sortedIds.length > 0;