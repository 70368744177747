import useSWR from "swr";
import compact from "lodash/compact";
import log from "Logger";
import { s3FileHandlerClient } from "common/clients/instances";

/* The pre-signed URLs expire after 10 minutes, which means the seller can no longer save the image
  from their browser to their device. Therefore, we re-fetch the image URLs every 8 minutes */
const REFRESH_INTERVAL_MS = 8 * 60 * 1000;
export const useFetchProblemPhotoUrls = photos => {
  const {
    data
  } = useSWR(["problemPhotoUrls", photos], async ([_, _photos]) => {
    const photoUrls = await Promise.all(_photos.map(async photo => {
      try {
        return await s3FileHandlerClient.getS3File(photo);
      } catch (error) {
        log.warn({
          fn: "useFetchProblemPhotoUrls",
          photo,
          error
        }, "error fetching problem photo url");
        return;
      }
    }));
    return compact(photoUrls);
  }, {
    revalidateOnFocus: false,
    refreshInterval: REFRESH_INTERVAL_MS
  });
  return {
    photoUrls: data ?? []
  };
};