import { toDate, parseISO } from "date-fns";

/**
 *  A date represented by an ISO formatted string, unix timestamp or Date object.
 **/

/**
 *
 * @param date {LooseDate} date to be normalized
 * @returns Date representation of the object passed in
 */
export const fromLooseDate = date => {
  switch (typeof date) {
    case "string":
      return parseISO(date);
    case "number":
      return toDate(date);
    default:
      return date;
  }
};