import { ApiClient } from "common/clients/core/ApiClient";
import { isAuthenticated } from "common/clients/core/authentication";
import { mapError } from "common/clients/core/mapError";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { EBayEGDError } from "./EbayEGDError";
export class EBayEGDClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.EBAY_EGD_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async enableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/dsku/${dsku}`,
        body: {
          isActive: true
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [EBayEGDError.NOT_FOUND]);
    }
  }
  async disableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/dsku/${dsku}`,
        body: {
          isActive: false
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [EBayEGDError.NOT_FOUND]);
    }
  }
  async getDskuStateCoverage(dsku) {
    try {
      return await this.apiClient.get({
        url: `/availability/dsku/${dsku}/coverage`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getStateCoverageByServiceLevel(dsku) {
    const coverage = await this.getDskuStateCoverage(dsku);
    return {
      3: coverage
    };
  }
  async isFastTagEnabled(dsku) {
    try {
      return await this.apiClient.get({
        url: `/product/dsku/${dsku}/active`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getLinkForDsku(dsku) {
    try {
      return await this.apiClient.get({
        url: `/product/dsku/${dsku}/listings`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async updateLinkForDsku(sellerId, dsku, url) {
    try {
      return await this.apiClient.put({
        url: `/product/seller/${sellerId}/dsku/${dsku}/listings`,
        body: {
          url
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [EBayEGDError.INVALID_URL]);
    }
  }

  /**
   * initiates the oauth flow for a seller, calling this will redirect the page to ebay
   * which starts the oauth flow
   */
  async initOAuth() {
    try {
      return await this.apiClient.get({
        url: `/ebay/oauth/init`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }

  /**
   * Completes the oauth flow by passing the sellerId and authCode back to the service
   * @param sellerId
   * @param authCode
   * @throws INVALID_CODE if auth code was not valid
   */
  async completeOAuth(sellerId, authCode) {
    try {
      await this.apiClient.post({
        url: `/ebay/oauth/token`,
        body: {
          sellerId,
          authCode
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      switch (err.response?.status) {
        case 400:
          throw mapError(err, [EBayEGDError.INVALID_CODE]);
        default:
          throw mapError(err);
      }
    }
  }

  /**
   * validates if the ebay seller has the correct rate table ids
   * @param sellerId
   * @returns true if rate tables are validated in EGD
   */
  async validateRateTables(sellerId) {
    try {
      await this.apiClient.post({
        url: `/rate-tables/${sellerId}/validate`,
        body: null,
        authentication: isAuthenticated
      });
      return true;
    } catch (err) {
      switch (err.response?.status) {
        case 400:
          return false;
        default:
          throw mapError(err);
      }
    }
  }

  /**
   * this will deactivate egd for seller
   * @param sellerId
   */
  async deactivateEGD(sellerId) {
    try {
      await this.apiClient.delete({
        url: `/ebay/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
}