import { useEffect, useState } from "react";
import { removeAllEmTags } from "common/StringUtils";
export const useNavbarEditableItem = ({
  displayItemValue,
  handleSubmit
}) => {
  const sanitizedItemValue = removeAllEmTags(displayItemValue);
  const [isEditing, setIsEditing] = useState(false);
  const [editableItemValue, setEditableItemValue] = useState(sanitizedItemValue);
  const handleInputBlur = () => {
    handleSubmit?.(editableItemValue);
    setIsEditing(false);
  };
  const handleItemClick = event => {
    event.stopPropagation();
    setEditableItemValue(sanitizedItemValue);
    setIsEditing(true);
    // Suggestion: Add a callback below in case the user wants to do something after they click the name
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    handleSubmit?.(editableItemValue);
    setIsEditing(false);
  };
  const handleInputChange = event => {
    if (event.target.value) {
      setEditableItemValue(event.target.value);
    }
  };
  useEffect(() => {
    if (sanitizedItemValue) {
      setEditableItemValue(sanitizedItemValue);
    }
  }, [sanitizedItemValue]);
  return {
    isEditing,
    editableItemValue,
    handleInputBlur,
    handleItemClick,
    handleFormSubmit,
    handleInputChange
  };
};