import { BarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import { logError } from "Logger";
import { getStorageInboundDetail } from "../store";
export const selectStorageBarcodePrintItems = state => {
  const {
    storageItemDetails,
    storageInboundOrder: {
      storageItems
    }
  } = getStorageInboundDetail(state);
  const ctx = {
    fn: "selectStorageBarcodePrintItems",
    shippingPlanId: storageItemDetails.shippingPlanId
  };
  return storageItems.map(({
    dsku,
    expectedQty
  }) => {
    const product = storageItemDetails[dsku];
    if (!product) {
      logError({
        ...ctx,
        dsku
      }, new Error("Product not found in storageItemDetails"));
      return undefined;
    }
    return {
      barcode: product.fnsku ?? product.asin,
      format: BarcodeFormat.FNSKU,
      name: product.name,
      qty: expectedQty
    };
  }).filter(Boolean);
};