import { PREP_TYPE_DESCRIPTION_LABELS } from "prep/labels";
import { defineMessages } from "react-intl";
import { PrepCombo, PrepType, SimplePrepType } from "@deliverr/prep-service-client";
import { PREP_TILE_COST_LABELS } from "./PrepTileCost.labels";
export const PREP_TILE_GROUP_LABELS = {
  [SimplePrepType.SKU_LABELS]: defineMessages({
    description: PREP_TYPE_DESCRIPTION_LABELS[SimplePrepType.SKU_LABELS],
    pricing: PREP_TILE_COST_LABELS.standard
  }),
  [PrepCombo.BAGGING_PROTECTION]: defineMessages({
    description: PREP_TYPE_DESCRIPTION_LABELS[PrepCombo.BAGGING_PROTECTION],
    pricing: PREP_TILE_COST_LABELS.standard
  }),
  [PrepCombo.DAMAGE_PROTECTION]: defineMessages({
    description: PREP_TYPE_DESCRIPTION_LABELS[PrepCombo.DAMAGE_PROTECTION],
    pricing: PREP_TILE_COST_LABELS.standard
  }),
  [PrepType.KITTING]: defineMessages({
    description: PREP_TYPE_DESCRIPTION_LABELS[PrepType.KITTING],
    pricing: PREP_TILE_COST_LABELS.total
  })
};