import { AdminClient } from "./seller/AdminClient";
import { AvailabilityClient } from "./availability/AvailabilityClient";
import { BillingClient } from "./billing/BillingClient";
import { BulkUploadClient } from "./bulkUpload/BulkUploadClient";
import { ClaimClient } from "./claim/ClaimClient";
import { CrossdockClient } from "./crossdock/CrossdockClient";
import { DashboardClient } from "./dashboard/DashboardClient";
import { EBayEGDClient } from "./ebayEGD/EbayEGDClient";
import { FacebookClient } from "./facebook/FacebookClient";
import { FreightApiAdapter } from "./transportation/FreightApiAdapter";
import { GoogleShoppingClient } from "./googleShopping/GoogleShoppingClient";
import { InboundEstimationClient } from "./inboundEstimation/InboundEstimationClient";
import { InventoryClient } from "./inventory/InventoryClient";
import { LogisticsApiClient } from "./logisticsApi/LogisticsApiClient";
import { NotificationClient } from "./notifications/NotificationClient";
import { OnboardingClient } from "./onboarding/OnboardingClient";
import { OnboardingClientV2 } from "./onboarding/OnboardingClientV2";
import { OrdersClient } from "./orders/OrdersClient";
import { OrganizationClient } from "./seller/OrganizationClient";
import { PricerClient } from "./pricer/PricerClient";
import { RSIPClient } from "./rsip/RSIPClient";
import { ReferralProgramsClient } from "./referralPrograms/ReferralProgramsClient";
import { ReportingClient } from "./reporting/ReportingClient";
import { S3FileHandlerClient } from "./nonCompliance/S3FileHandlerClient";
import { SellerClient } from "./seller/SellerClient";
import { SfnClient } from "./seller/SfnClient";
import { ShopifyClient } from "./shopify/ShopifyClient";
import { StorageClient } from "./storage/StorageClient";
import { TransportationClient } from "./transportation/TransportationClient";
import { UploadClient } from "./upload/UploadClient";
import { UserClient } from "./seller/UserClient";
import { WalmartClient } from "./walmart/WalmartClient";
import { WarehouseClient } from "./warehouse/WarehouseClient";
import { WebsiteFastTagClient } from "./websiteFastTag/WebsiteFastTagClient";
import { WishClient } from "./wish/WishClient";
export const availabilityClient = new AvailabilityClient();
export const billingClient = new BillingClient();
export const bulkUploadClient = new BulkUploadClient();
export const crossdockClient = new CrossdockClient();
export const dashboardClient = new DashboardClient();
export const egdClient = new EBayEGDClient();
export const facebookClient = new FacebookClient();
export const googleShoppingClient = new GoogleShoppingClient();
export const inventoryClient = new InventoryClient();
export const inboundEstimationClient = new InboundEstimationClient();
export const onboardingClient = new OnboardingClient();
export const onboardingClientV2 = new OnboardingClientV2();
export const ordersClient = new OrdersClient();
export const organizationClient = new OrganizationClient();
export const reportingClient = new ReportingClient();
export const userClient = new UserClient();
export const warehouseClient = new WarehouseClient();
export const uploadClient = new UploadClient();
export const claimClient = new ClaimClient();
export const notificationClient = new NotificationClient();
export const pricerClient = new PricerClient();
export const shopifyClient = new ShopifyClient();
export const referralProgramsClient = new ReferralProgramsClient();
export const sellerClient = new SellerClient();
export const sfnClient = new SfnClient();
export const storageClient = new StorageClient();
export const rsipClient = new RSIPClient();
export const transportationClient = new TransportationClient();
export const freightApiAdapter = new FreightApiAdapter();
export const walmartClient = new WalmartClient();
export const websiteFastTagClient = new WebsiteFastTagClient();
export const wishClient = new WishClient();
export const s3FileHandlerClient = new S3FileHandlerClient();
export const logisticsApiClient = new LogisticsApiClient();
export const adminClient = new AdminClient();