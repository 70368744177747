import { DistributionChannel, OrderStatus, ShipmentStatus, ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { isOutOfStockItemUnremoved, isOutOfStockOrder } from "./details/out-of-stock-items/OutOfStockOrderUtils";
export const allowCancelStatus = [OrderStatus.DRAFT, OrderStatus.CREATED, OrderStatus.SUBMITTED];
export const checkIsCancellable = replenishmentOrder => allowCancelStatus.includes(replenishmentOrder.orderStatus);
export const checkIsPartiallyFulfillable = (replenishmentOrder, warehouseId) => replenishmentOrder.orderStatus === OrderStatus.DRAFT && isOutOfStockOrder(replenishmentOrder) && (!!replenishmentOrder.orderItems?.length || replenishmentOrder?.invalidOrderItems?.some(isOutOfStockItemUnremoved)) && !!warehouseId;
export const shouldShowShipmentInstructions = (shipment, order) => {
  return (order.distributionChannel === DistributionChannel.FBA_MANUAL || order.distributionChannel === DistributionChannel.WHOLESALE) && shipment.shippingPartner === ShippingPartner.SELLER && (shipment.shippingType === ShippingType.FTL || shipment.shippingType === ShippingType.LTL) && [ShipmentStatus.CREATED, ShipmentStatus.SUBMITTED].includes(shipment.status);
};
export const shouldShowBookFreight = (shipment, order) => {
  return (order.distributionChannel === DistributionChannel.FBA_MANUAL || order.distributionChannel === DistributionChannel.WHOLESALE) && shipment.shippingPartner === ShippingPartner.SELLER && (shipment.shippingType === ShippingType.FTL || shipment.shippingType === ShippingType.LTL) && shipment.status === ShipmentStatus.READY_TO_SHIP;
};