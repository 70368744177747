import { useEffect } from "react";
import { storageClient } from "common/clients/instances";
import { useDispatch, useSelector } from "react-redux";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { getSellerId } from "common/user/UserSelectors";
import { getFreightCargoPalletDetails } from "freight/FreightSelectors";
import { FreightModeFTL, FreightModeLTL } from "@deliverr/freight-client";
import { useAsyncFn } from "react-use";
import { setFreightOrderDetails } from "../../actions/setFreightOrderDetails";
import { FREIGHT_LTL_PALLET_LIMIT } from "storage/inbounds/create/cargoType/useStorageCargoType";
import { logError } from "Logger";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { setSelectedQuoteDetails } from "../../actions/setSelectedQuoteDetails";
import { FreightType } from "@deliverr/storage-client";
import { DeliverrError } from "@deliverr/commons-objects";
import { isEmpty } from "lodash/fp";
import { resetSelectedQuoteDetails } from "../../actions/resetSelectedQuoteDetails";
export const useCalculateFreightRate = () => {
  const dispatch = useDispatch();
  const sellerId = useSelector(getSellerId);
  const {
    fromAddress
  } = useSelector(state => state.inbound);
  const {
    destinationStorageWarehouse,
    numOfPallets,
    selectedQuoteDetails,
    isCalculateRateDisabled,
    palletConfigurations
  } = useSelector(getStorageInboundCreate);
  const cargoPalletDetails = useSelector(getFreightCargoPalletDetails);
  const {
    formatMessage
  } = useIntl();
  useEffect(() => {
    selectedQuoteDetails && dispatch(resetSelectedQuoteDetails());
  }, [palletConfigurations]);
  const [quoteLoadingState, handleCalculateRateClick] = useAsyncFn(async () => {
    const ctx = {
      fn: "useCalculateFreightRate.handleCalculateRateClick"
    };
    if (numOfPallets && destinationStorageWarehouse?.address.zip && fromAddress.zip) {
      const quoteRequestBody = {
        fromZip: fromAddress.zip,
        toZip: destinationStorageWarehouse?.address.zip,
        createdBy: "STORAGE",
        sellerId
      };
      try {
        let response;
        if (numOfPallets > FREIGHT_LTL_PALLET_LIMIT) {
          const serviceRequest = [{
            type: FreightModeFTL.FTL,
            numberOfTrucks: 1
          }];
          response = await storageClient.generateFtlQuote({
            ...quoteRequestBody,
            serviceRequest
          });
        } else {
          if (palletConfigurations && !isEmpty(palletConfigurations)) {
            const serviceRequest = [{
              type: FreightModeLTL.LTL,
              pallets: palletConfigurations?.map(palletConfiguration => {
                const {
                  length,
                  width,
                  weight,
                  height
                } = palletConfiguration;
                return {
                  palletDimensions: {
                    length,
                    width,
                    weight,
                    height
                  },
                  totalPallets: palletConfiguration.palletCount
                };
              })
            }];
            response = await storageClient.generateLtlQuote({
              ...quoteRequestBody,
              serviceRequest
            });
          } else {
            throw new DeliverrError({
              code: "INVALID_PAYLOAD",
              functionName: "useCalculateFreightRate.handleCalculateRateClick",
              message: `palletConfigurations is empty`
            });
          }
        }
        dispatch(setSelectedQuoteDetails(response?.value));
        return response?.value;
      } catch (error) {
        logError(ctx, error);
        toast.error(formatMessage({
          id: "storage.inbounds.create.selectShipping.loadQuote.error",
          defaultMessage: "Generate Quote failed"
        }));
      }
    }
    return undefined;
  }, [palletConfigurations, numOfPallets, cargoPalletDetails, destinationStorageWarehouse, fromAddress]);
  const handleQuoteSelection = ev => {
    dispatch(setFreightOrderDetails({
      quoteId: Number(ev.target.value),
      estimatedCost: quoteLoadingState?.value?.serviceCosts?.[0]?.totalCost,
      type: (numOfPallets ?? 1) <= FREIGHT_LTL_PALLET_LIMIT ? FreightType.LTL : FreightType.FTL
    }));
  };
  return {
    isCalculateRateDisabled,
    handleCalculateRateClick,
    handleQuoteSelection,
    isQuoteLoading: quoteLoadingState.loading,
    quoteResponse: selectedQuoteDetails
  };
};