/* eslint-disable @typescript-eslint/no-dynamic-delete */

import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import produce from "immer";
import { StorageCargoActionTypes } from "../cargoType/StorageCargoAction.types";
import { StorageCaseDimensionsActionTypes } from "../dimensions/StorageCaseDimensionsAction.types";
import { StorageDestinationActionTypes } from "../selectDestinationV2/StorageDestinationAction.types";
import { StorageSelectProductsActionTypes } from "../selectProducts/StorageSelectProductAction.types";
import { StorageInboundCreateActionTypes } from "./StorageInboundCreateAction.types";
import { StorageInboundLotInformationAction } from "../lotTracking/StorageInboundLotInformationAction.types";
import { StorageSelectCasePackActionTypes } from "../selectProductCasePacks/SelectProductCasePackAction.types";
import { StorageSelectShippingActionTypes } from "../selectShipping/StorageSelectShippingActionTypes";
import { StorageFromAddressActionTypes } from "../fromAddress/freightAddressType/StorageAddressAction.types";
import { mapProductCasePackToContainer } from "../selectProductCasePacks/mapProductCasePackToContainer";
import { StorageCargoPalletDetailsActionTypes } from "../selectShipping/steps/addShipmentDetails/actions/StorageCargoPalletDetailsActionTypes";
import { IDENTICAL_PALLETS } from "../selectShipping/steps/addShipmentDetails/shipmentDetailsConstants";
import { STORAGE_INBOUND_MAX_CASE_LONGEST_IN, STORAGE_INBOUND_MAX_CASE_MIDDLE_IN, STORAGE_INBOUND_MAX_CASE_SHORTEST_IN, STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS } from "storage/inbounds/CaseDimensionsConstants";
import { keyBy } from "lodash";
import { StorageDestinationType } from "../selectDestination/StorageDestinationType.types";
import { CarrierType, ContainerSize, InboundStatus, ShipmentType } from "@deliverr/storage-client";
export const storageInboundCreateInitialState = {
  containerSize: ContainerSize.GENERAL_40FT,
  selectedProducts: {},
  productDetails: {},
  cargoType: ShipmentType.PALLETIZED,
  selectedProductsCaseDimensions: {},
  selectedProductsLotInformation: {},
  selectedProductsCasePacks: {},
  storageWarehouses: [],
  destinationType: StorageDestinationType.NEAREST_WAREHOUSE,
  internalStatus: InboundStatus.IN_DRAFT,
  carrierType: CarrierType.DELIVERR,
  isLimitedAccess: false,
  isResidential: false,
  palletConfigurations: [{
    palletCount: 1,
    length: STORAGE_INBOUND_MAX_CASE_SHORTEST_IN,
    width: STORAGE_INBOUND_MAX_CASE_MIDDLE_IN,
    height: STORAGE_INBOUND_MAX_CASE_LONGEST_IN,
    weight: STORAGE_INBOUND_MAX_CASE_WEIGHT_LBS,
    lengthUnit: "in",
    weightUnit: "lb"
  }],
  isCalculateRateDisabled: false
};
const reducers = {
  ...handleSimpleReducerUpdates([StorageCargoActionTypes.STORAGE_SET_CARGO_TYPE, StorageCargoActionTypes.STORAGE_SET_CONTAINER_ID, StorageCargoActionTypes.STORAGE_SET_CONTAINER_SIZE, StorageCargoActionTypes.STORAGE_SET_NUM_OF_PALLETS, StorageCargoActionTypes.STORAGE_SET_ESTIMATED_NUM_OF_PALLETS, StorageDestinationActionTypes.STORAGE_SET_DESTINATION_TYPE, StorageDestinationActionTypes.STORAGE_SET_DESTINATION_WAREHOUSE, StorageDestinationActionTypes.STORAGE_SET_NEAREST_WAREHOUSE, StorageDestinationActionTypes.STORAGE_SET_WAREHOUSE_LIST, StorageInboundCreateActionTypes.SET_STORAGE_INBOUND_ORDER_ID, StorageInboundCreateActionTypes.SET_STORAGE_INBOUND_SHIPPING_PLAN_ID, StorageInboundLotInformationAction.SET_STORAGE_INBOUND_LOT_INFORMATION, StorageSelectShippingActionTypes.STORAGE_SET_CARRIER_TYPE, StorageFromAddressActionTypes.STORAGE_SET_IS_LIMITED_ACCESS, StorageFromAddressActionTypes.STORAGE_SET_IS_RESIDENTIAL, StorageCargoPalletDetailsActionTypes.SET_IS_CALCULATE_RATE_DISABLED, StorageSelectShippingActionTypes.STORAGE_SET_TOTAL_CARGO_WEIGHT]),
  [StorageInboundCreateActionTypes.SET_STORAGE_INBOUND_CREATE_DRAFT]: (state, {
    storageInboundCreateDraft
  }) => ({
    ...state,
    ...storageInboundCreateDraft
  }),
  [StorageSelectProductsActionTypes.STORAGE_ADD_PRODUCT]: (state, {
    dsku,
    product
  }) => {
    return produce(state, draft => {
      draft.selectedProducts = {
        ...draft.selectedProducts,
        [dsku]: createStorageInboundProductItem(product)
      };
      draft.productDetails[dsku] = product;
      draft.selectedProductsCaseDimensions[dsku] = {
        lengthUnit: "in",
        weightUnit: "lb",
        length: undefined,
        width: undefined,
        height: undefined,
        weight: undefined
      };
    });
  },
  [StorageSelectShippingActionTypes.STORAGE_SET_SELECTED_QUOTE_DETAILS]: (state, {
    selectedQuoteDetails
  }) => {
    return produce(state, draft => {
      draft.selectedQuoteDetails = {
        ...selectedQuoteDetails
      };
    });
  },
  [StorageSelectShippingActionTypes.STORAGE_SET_PALLET_CONFIGURATIONS]: (state, {
    palletConfigurations
  }) => {
    return produce(state, draft => {
      draft.palletConfigurations = [...palletConfigurations];
    });
  },
  [StorageSelectShippingActionTypes.STORAGE_SET_FREIGHT_ORDER_DETAILS]: (state, {
    freightOrderDetails
  }) => {
    return produce(state, draft => {
      draft.freightOrderDetails = {
        ...draft.freightOrderDetails,
        ...freightOrderDetails
      };
    });
  },
  [StorageSelectProductsActionTypes.STORAGE_ADD_PRODUCT_DETAILS]: (state, {
    productDetails
  }) => {
    return produce(state, draft => {
      Object.keys(productDetails).forEach(dsku => {
        draft.selectedProducts[dsku].name = productDetails[dsku].name;
        draft.selectedProducts[dsku].msku = productDetails[dsku].msku;
        draft.selectedProducts[dsku].actualCaseQty = productDetails[dsku].caseQty;
      });
    });
  },
  [StorageCargoPalletDetailsActionTypes.STORAGE_REMOVE_PALLET_LINE_ITEM]: (state, {
    index
  }) => {
    return produce(state, draft => {
      draft.palletConfigurations?.splice(index, 1);
    });
  },
  [StorageCargoPalletDetailsActionTypes.STORAGE_ADD_NEW_PALLET_LINE_ITEM]: (state, {
    palletLineItem
  }) => {
    return produce(state, draft => {
      draft.palletConfigurations?.push(palletLineItem);
    });
  },
  [StorageCargoPalletDetailsActionTypes.STORAGE_UPDATE_PALLET_LINE_ITEM]: (state, {
    index,
    name,
    value
  }) => {
    return produce(state, draft => {
      if (name === IDENTICAL_PALLETS && draft.palletConfigurations?.[index]) {
        draft.palletConfigurations[index].palletCount = value;
      } else if (draft.palletConfigurations?.[index]) {
        draft.palletConfigurations[index][name] = value;
      }
    });
  },
  [StorageSelectProductsActionTypes.STORAGE_REMOVE_PRODUCT]: (state, {
    dsku
  }) => {
    return produce(state, draft => {
      delete draft.productDetails[dsku];
      delete draft.selectedProducts[dsku];
      delete draft.selectedProductsCaseDimensions[dsku];
      delete draft.selectedProductsLotInformation[dsku];
      delete draft.selectedProductsCasePacks[dsku];
    });
  },
  [StorageSelectProductsActionTypes.STORAGE_UPDATE_NUMBER_OF_CASES]: (state, {
    dsku,
    numberOfCases
  }) => {
    return produce(state, draft => {
      const product = draft.selectedProducts[dsku];
      product.numberOfCases = numberOfCases;
      product.qty = numberOfCases * (product?.caseQty ?? 1);
    });
  },
  [StorageSelectProductsActionTypes.STORAGE_UPDATE_QTY_PER_CASE]: (state, {
    dsku,
    caseQty
  }) => {
    return produce(state, draft => {
      const product = draft.selectedProducts[dsku];
      product.caseQty = caseQty;
      product.qty = caseQty * product.numberOfCases;
    });
  },
  [StorageCaseDimensionsActionTypes.STORAGE_SET_CASE_DIMENSIONS]: (state, {
    selectedProductsCaseDimensions
  }) => {
    return produce(state, draft => {
      Object.keys(selectedProductsCaseDimensions).forEach(dsku => {
        draft.selectedProductsCaseDimensions[dsku] = state.selectedProductsCaseDimensions && {
          ...state.selectedProductsCaseDimensions[dsku],
          ...selectedProductsCaseDimensions[dsku]
        };
      });
    });
  },
  [StorageInboundCreateActionTypes.RESET_STORAGE_INBOUND_CREATE]: () => ({
    ...storageInboundCreateInitialState
  }),
  [StorageSelectCasePackActionTypes.STORAGE_SET_PRODUCT_CASE_PACK_OPTIONS]: (state, {
    dsku,
    productCasePacks
  }) => {
    return produce(state, draft => {
      const product = draft.selectedProducts[dsku];
      product.productCasePackOptions = productCasePacks;
    });
  },
  [StorageSelectCasePackActionTypes.STORAGE_SET_SELECTED_CASE_PACK_OPTION]: (state, {
    dsku,
    productCasePack
  }) => {
    return produce(state, draft => {
      draft.selectedProductsCasePacks[dsku] = productCasePack;
      draft.selectedProductsCaseDimensions[dsku] = mapProductCasePackToContainer(productCasePack);
    });
  },
  [StorageSelectCasePackActionTypes.STORAGE_SET_NEW_PRODUCT_CASE_PACK]: (state, {
    dsku,
    productCasePack
  }) => {
    return produce(state, draft => {
      const product = draft.selectedProducts[dsku];
      product.productCasePackOptions[productCasePack.dsku] = productCasePack;
    });
  },
  [StorageSelectShippingActionTypes.RESET_STORAGE_SELECTED_QUOTE_DETAILS]: state => {
    return produce(state, draft => {
      delete draft.selectedQuoteDetails;
    });
  },
  [StorageCargoActionTypes.STORAGE_RESET_CARGO_TYPE]: state => {
    return produce(state, draft => {
      delete draft.cargoType;
    });
  },
  [StorageSelectCasePackActionTypes.STORAGE_SET_CASE_PACK_LOADING]: (state, {
    dsku,
    arePacksLoading
  }) => {
    return produce(state, draft => {
      const product = draft.selectedProducts[dsku];
      product.arePacksLoading = arePacksLoading;
    });
  },
  [StorageSelectProductsActionTypes.STORAGE_ADD_BULK_UPLOAD_PRODUCT]: (state, {
    product,
    pack
  }) => {
    return produce(state, draft => {
      if (!product?.qty) {
        return;
      }
      const caseQty = pack?.quantity ?? product.caseQty ?? 1;
      const {
        dsku,
        qty
      } = product;
      draft.selectedProducts[dsku] = {
        caseQty,
        dsku,
        qty,
        numberOfCases: qty / caseQty,
        actualCaseQty: caseQty,
        productCasePackOptions: keyBy(product.productCasePacks ?? [], casePack => casePack.dsku),
        arePacksLoading: false
      };
      draft.productDetails[dsku] = product;
      if (pack) {
        draft.selectedProductsCasePacks[dsku] = pack;
        draft.selectedProductsCaseDimensions[dsku] = mapProductCasePackToContainer(pack);
      } else {
        draft.selectedProductsCaseDimensions[dsku] = {
          lengthUnit: "in",
          weightUnit: "lb"
        };
      }
    });
  }
};
const createStorageInboundProductItem = product => {
  const caseQty = product.caseQty ?? 1;
  return {
    caseQty,
    qty: caseQty,
    dsku: product.dsku,
    numberOfCases: 1,
    actualCaseQty: product.caseQty,
    productCasePackOptions: keyBy(product.productCasePacks ?? [], pack => pack.dsku),
    arePacksLoading: false
  };
};
export const storageInboundCreateReducer = createReducer(storageInboundCreateInitialState, reducers);