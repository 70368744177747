import { getIsShipToOne } from "inbounds/steps/ship/view/ViewShipmentSelectors";
import { useSelector } from "react-redux";
import { getCrossdockInboundQuote, getCrossdockInboundShipment } from "inbounds/crossdock/store/selectors";
import { calculateIntermediateValues, isCrossdockChargeablePlanAndShipment } from "../../util";
export const useCrossdockCharging = () => {
  const isShipToOne = Boolean(useSelector(getIsShipToOne));
  const loadedShipmentId = useSelector(state => state.inbound.loadedShipmentId);
  const quote = useSelector(getCrossdockInboundQuote);
  const shipment = useSelector(getCrossdockInboundShipment);
  const {
    finalizedCost
  } = shipment ?? {};
  const areCrossdockChargesFinal = finalizedCost !== undefined && finalizedCost !== null;
  const {
    totalCost
  } = quote ?? {};
  const currentTotalCrossdockCharges = areCrossdockChargesFinal ? finalizedCost : totalCost;
  const isMissingQuoteForEligiblePlan = isShipToOne && !quote;

  // Quote is fully discount if the percent discount is 1.0
  const isQuoteFullyDiscounted = !!quote && Math.abs(quote.percentDiscount - 1.0) < Number.EPSILON;
  return {
    isChargeable: isCrossdockChargeablePlanAndShipment(isShipToOne, loadedShipmentId, quote),
    isMissingQuoteForEligiblePlan,
    quote,
    shipment,
    areCrossdockChargesFinal,
    currentTotalCrossdockCharges,
    intermediateValues: calculateIntermediateValues(quote),
    isQuoteFullyDiscounted
  };
};