import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { getSellerInfo as getSellerInfoAction } from "./store/action/getSellerInfo";
export const useSellerInfo = () => {
  const sellerId = useSelector(state => state.user.sellerId);
  const {
    sellerInfo,
    destinationOptions,
    qcAddresses,
    fetching
  } = useSelector(state => state.returnsSeller);
  const dispatch = useDispatch();
  const deliverrSellerId = sellerInfo?.deliverrSellerId;
  const enableReturn = sellerInfo?.enableReturn ?? false;
  React.useEffect(() => {
    if (sellerId !== deliverrSellerId && !fetching) {
      dispatch(getSellerInfoAction(sellerId));
    }
  }, [sellerId, dispatch, deliverrSellerId]);
  const getSellerInfo = () => dispatch(getSellerInfoAction(sellerId));
  return {
    sellerInfo,
    destinationOptions,
    qcAddresses,
    enableReturn,
    getSellerInfo
  };
};