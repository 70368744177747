export class SearchService {
  async search(query, pageNum = 0, sortBy, filters = [], numericFilter, resultsSize) {
    return await this.execute({
      searchTerm: query,
      page: pageNum,
      pageSize: resultsSize,
      filters,
      numericFilter,
      sort: sortBy
    });
  }
  clearCache() {
    return this;
  }
}