export let FontFamily;
(function (FontFamily) {
  FontFamily["STANDARD"] = "STANDARD";
  FontFamily["TITLE"] = "TITLE";
})(FontFamily || (FontFamily = {}));
export let FontSize;
(function (FontSize) {
  FontSize["F1"] = "F1";
  FontSize["F2"] = "F2";
  FontSize["F3"] = "F3";
  FontSize["F4"] = "F4";
  FontSize["F5"] = "F5";
  FontSize["F6"] = "F6";
  FontSize["F7"] = "F7";
})(FontSize || (FontSize = {}));
export let LineHeight;
(function (LineHeight) {
  LineHeight["LH1"] = "LH1";
  LineHeight["LH2"] = "LH2";
  LineHeight["LH3"] = "LH3";
  LineHeight["LH4"] = "LH4";
  LineHeight["LH5"] = "LH5";
  LineHeight["LH6"] = "LH6";
  LineHeight["LH7"] = "LH7";
})(LineHeight || (LineHeight = {}));
export let LetterSpacing;
(function (LetterSpacing) {
  LetterSpacing["LS0"] = "LS0";
  LetterSpacing["LS1"] = "LS1";
  LetterSpacing["LS2"] = "LS2";
  LetterSpacing["LS3"] = "LS3";
  LetterSpacing["LS4"] = "LS4";
  LetterSpacing["LS5"] = "LS5";
})(LetterSpacing || (LetterSpacing = {}));
export let FontWeight;
(function (FontWeight) {
  FontWeight["REGULAR"] = "REGULAR";
  FontWeight["BOLD"] = "BOLD";
  FontWeight["BLACK"] = "BLACK";
})(FontWeight || (FontWeight = {}));
const FAMILY = {
  [FontFamily.STANDARD]: "Lato, sans-serif",
  [FontFamily.TITLE]: "Montserrat, sans-serif"
};
const SIZE = {
  [FontSize.F1]: "0.75rem",
  [FontSize.F2]: "0.875rem",
  [FontSize.F3]: "1rem",
  [FontSize.F4]: "1.125rem",
  [FontSize.F5]: "1.375rem",
  [FontSize.F6]: "1.75rem",
  [FontSize.F7]: "2.25rem"
};
const LINEHEIGHT = {
  [LineHeight.LH1]: "1rem",
  [LineHeight.LH2]: "1.25rem",
  [LineHeight.LH3]: "1.5rem",
  [LineHeight.LH4]: "1.625rem",
  [LineHeight.LH5]: "2rem",
  [LineHeight.LH6]: "2.25rem",
  [LineHeight.LH7]: "2.625rem"
};
const WEIGHT = {
  [FontWeight.REGULAR]: "400",
  [FontWeight.BOLD]: "700",
  [FontWeight.BLACK]: "900"
};
const LETTERSPACING = {
  [LetterSpacing.LS0]: "0rem",
  [LetterSpacing.LS1]: "0rem",
  [LetterSpacing.LS2]: "0rem",
  [LetterSpacing.LS3]: "0rem",
  [LetterSpacing.LS4]: "0.0437rem",
  [LetterSpacing.LS5]: "0rem"
};
export const font = {
  /**
   * @constant `STANDARD` Lato, sans-serif
   * @constant `TITLE` Montserrat, sans-serif
   */
  family: FAMILY,
  /**
   * @constant `F1` 12px | 0.75rem
   * @constant `F2` 14px | 0.875rem
   * @constant `F3` 16px | 1rem
   * @constant `F4` 18px | 1.125rem
   * @constant `F5` 22px | 1.375rem
   * @constant `F6` 28px | 1.75rem
   * @constant `F7` 36px | 2.25rem
   */
  size: SIZE,
  /**
   * @constant `REGULAR` 400
   * @constant `BOLD` 700
   * @constant `BLACK` 900
   */
  weight: WEIGHT,
  /**
   * @constant `LH1` 16px | 1rem
   * @constant `LH2` 20px | 1.25rem
   * @constant `LH3` 24px | 1.5rem
   * @constant `LH4` 26px | 1.625rem
   * @constant `LH5` 32px | 2rem
   * @constant `LH6` 36px | 2.25rem
   * @constant `LH7` 42px | 2.625rem
   */
  lineHeight: LINEHEIGHT,
  letterSpacing: LETTERSPACING
};