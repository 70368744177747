import { defineMessages } from "react-intl";
export const packingInstructionsLabels = defineMessages({
  casePackLabel: {
    id: "storage.inbounds.detail.casePackingInstructions.casePack",
    defaultMessage: "Case packs must have product information or a scannable barcode on the outside of the case."
  },
  palletLabel: {
    id: "storage.inbounds.detail.casePackingInstructions.palletLabel",
    defaultMessage: "1 pallet label must be applied to each side of the pallet."
  },
  woodenPalletsLabel: {
    id: "inbounds.palletPackingInstructions.woodenPallets",
    defaultMessage: "Four-way pallets must be used. Pallets must be in good condition."
  },
  maxWeightLabel: {
    id: "inbounds.palletPackingInstructions.maxWeight",
    defaultMessage: `Each pallet must weigh less than {maxWeight}lbs.`
  },
  maxHeightLabel: {
    id: "inbounds.palletPackingInstructions.maxHeight",
    defaultMessage: `Pallet height, including the pallet, must be shorter than {maxHeight}". Pallets taller than {maxHeight}" are re-palletized at an additional cost.`
  }
});