import { FreightMode } from "@deliverr/freight-client";
import { getSelectOptions } from "common/utils/getSelectOptions";
import { Ports } from "freight/constants/Ports";
import { FreightAddressTypes } from "freight/store/freightAddress/FreightAddressTypes";
import { LimitedAccessLocation, LocationType } from "freight/types/AddressTypes";
import { defineMessages } from "react-intl";
export const FreightBookingCommonLabels = defineMessages({
  headerTitle: {
    id: "freight.header.title",
    defaultMessage: "Freight"
  },
  order: {
    id: "freight.header.order",
    defaultMessage: "Order {orderNumber}"
  },
  quote: {
    id: "freight.header.quote",
    defaultMessage: "Quote {orderNumber}"
  },
  footerPreviousButton: {
    id: "freight.footer.previousButton",
    defaultMessage: "Previous"
  },
  footerNextButton: {
    id: "freight.footer.nextButton",
    defaultMessage: "Next"
  },
  footerCancelButton: {
    id: "freight.footer.cancelButton",
    defaultMessage: "Cancel"
  },
  loadingTitle: {
    id: "freight.loading.title",
    defaultMessage: "Generating Freight Booking"
  },
  loadingMessage: {
    id: "freight.loading.message",
    defaultMessage: "It might take a minute to create your freight booking. Please be patient as we process your request."
  },
  documentUploadError: {
    id: "freight.steps.setup.steps.drayageDetails.documentUploadError",
    defaultMessage: "Error uploading document, please try again"
  }
});
export const FreightBookingAddressLabels = defineMessages({
  title: {
    id: "freight.address.title",
    defaultMessage: "Add {action} address"
  },
  [FreightAddressTypes.PICKUP]: {
    id: "freight.address.pickup",
    defaultMessage: "pickup"
  },
  [FreightAddressTypes.DELIVERY]: {
    id: "freight.address.delivery",
    defaultMessage: "delivery"
  },
  savedAddressesLoading: {
    id: "freight.address.savedAddressesLoading",
    defaultMessage: "Loading..."
  },
  savedAddressesPlaceholder: {
    id: "freight.address.savedAddressesPlaceholder",
    defaultMessage: "Select an address"
  },
  locationType: {
    id: "freight.address.locationType",
    defaultMessage: "Location type"
  },
  limitedAccessLocation: {
    id: "freight.address.limitedAccessLocation",
    defaultMessage: "Limited access location"
  },
  contactTitle: {
    id: "freight.address.contact.title",
    defaultMessage: "Contact for freight scheduling"
  },
  companyName: {
    id: "freight.address.companyName",
    defaultMessage: "Company name"
  },
  contactFirstName: {
    id: "freight.address.contact.firstName",
    defaultMessage: "First name"
  },
  contactLastName: {
    id: "freight.address.contact.lastName",
    defaultMessage: "Last name"
  },
  contactEmail: {
    id: "freight.address.contact.email",
    defaultMessage: "Email address"
  },
  contactPhone: {
    id: "freight.address.contact.phone",
    defaultMessage: "Phone number"
  },
  contactPhoneHelp: {
    id: "freight.address.contact.phoneHelp",
    defaultMessage: "US numbers only"
  },
  contactPhoneExt: {
    id: "freight.address.contact.phoneExt",
    defaultMessage: "Ext"
  },
  errorMessage: {
    id: "freight.address.errorMessage",
    defaultMessage: "This field is required"
  },
  emailErrorMessage: {
    id: "freight.address.emailErrorMessage",
    defaultMessage: "Please enter valid email address"
  },
  phoneErrorMessage: {
    id: "freight.address.phoneErrorMessage",
    defaultMessage: "Please enter valid phone number"
  },
  addressErrorMessage: {
    id: "freight.address.addressErrorMessage",
    defaultMessage: "Missing address fields: {fields}"
  },
  limitedAccessLocationErrorTitle: {
    id: "freight.address.limitedAccessLocationErrorTitle",
    defaultMessage: "Limited access location coming soon"
  },
  limitedAccessLocationErrorMessage: {
    id: "freight.address.limitedAccessLocationErrorMessage",
    defaultMessage: "Please contact us directly to book your order."
  },
  limitedAccessLocationErrorContact: {
    id: "freight.address.limitedAccessLocationErrorContact",
    defaultMessage: "Contact us"
  },
  savedAddressesError: {
    id: "freight.address.savedAddressesError",
    defaultMessage: "Error loading saved addresses"
  }
});
export const FreightCommonTableLabels = defineMessages({
  toHeader: {
    id: "freightList.table.header.shippingTo",
    defaultMessage: "Shipping to"
  },
  cargoHeader: {
    id: "freightList.table.header.cargo",
    defaultMessage: "Cargo"
  },
  [FreightMode.FTL]: {
    cargoValue: {
      id: "freightList.table.item.cargo",
      defaultMessage: "{count, plural, one {# pallet} other {# pallets}}"
    }
  },
  [FreightMode.LTL]: {
    cargoValue: {
      id: "freightList.table.item.cargo",
      defaultMessage: "{count, plural, one {# pallet} other {# pallets}}"
    }
  },
  [FreightMode.DRAYAGE]: {
    cargoValue: {
      id: "freightList.table.item.cargo",
      defaultMessage: "{count, plural, one {# container} other {# containers}}"
    }
  }
});
export const FreightBookingTableLabels = defineMessages({
  orderHeader: {
    id: "freightList.table.header.orders",
    defaultMessage: "Orders"
  },
  orderValue: {
    id: "freightList.table.item.orders",
    defaultMessage: "Order {id}"
  },
  lastUpdatedHeader: {
    id: "freightList.table.header.lastUpdated",
    defaultMessage: "Last updated"
  },
  statusHeader: {
    id: "freightList.table.header.status",
    defaultMessage: "Status"
  }
});
export const FreightQuoteTableLabels = defineMessages({
  quoteHeader: {
    id: "freightQuotes.table.header.quotes",
    defaultMessage: "Quotes"
  },
  quoteValue: {
    id: "freightQuotes.table.item.quotes",
    defaultMessage: "Quote {id}"
  },
  quoteExpirationText: {
    id: "freightQuotes.table.header.quoteExpirationText",
    defaultMessage: "Expires {expirationTime}"
  },
  quoteExpiredText: {
    id: "freightQuotes.table.header.quoteExpiredText",
    defaultMessage: "Expired"
  },
  fromHeader: {
    id: "freightQuotes.table.header.shippingFrom",
    defaultMessage: "Shipping from"
  },
  priceHeader: {
    id: "freightQuotes.table.header.price",
    defaultMessage: "Price quoted"
  },
  priceOptionsText: {
    id: "freightQuotes.table.header.priceOptionsText",
    defaultMessage: "+{count} more {count, plural, one {option} other {options}}"
  }
});
const LocationTypeNames = {
  COMMERCIAL: "Commercial",
  RESIDENTIAL: "Residential"
};
export const LOCATION_TYPE_LABELS = defineMessages({
  [LocationType.COMMERCIAL]: {
    id: "freightBooking.pickupAddress.locationType.commercial",
    defaultMessage: LocationTypeNames.COMMERCIAL
  },
  [LocationType.RESIDENTIAL]: {
    id: "freightBooking.pickupAddress.locationType.residential",
    defaultMessage: LocationTypeNames.RESIDENTIAL
  }
});
const LOCATION_TYPES = Object.values(LocationType);
export const getFreightBookingLocationTypeOptions = getSelectOptions(LOCATION_TYPES, LOCATION_TYPE_LABELS);
const LimitedAccessLocationNames = {
  NONE: "None",
  AIRPORT: "Airport",
  MILITARY_BASE: "Military base"
};
export const LIMITED_ACCESS_LOCATION_LABELS = defineMessages({
  [LimitedAccessLocation.NONE]: {
    id: "freightBooking.pickupAddress.limitedAccess.none",
    defaultMessage: LimitedAccessLocationNames.NONE
  },
  [LimitedAccessLocation.AIRPORT]: {
    id: "freightBooking.pickupAddress.limitedAccess.airport",
    defaultMessage: LimitedAccessLocationNames.AIRPORT
  },
  [LimitedAccessLocation.MILITARY_BASE]: {
    id: "freightBooking.pickupAddress.limitedAccess.militaryBase",
    defaultMessage: LimitedAccessLocationNames.MILITARY_BASE
  }
});
const LIMITED_ACCESS_LOCATION_TYPES = Object.values(LimitedAccessLocation);
export const getFreightBookingLimitedAccessOptions = getSelectOptions(LIMITED_ACCESS_LOCATION_TYPES, LIMITED_ACCESS_LOCATION_LABELS);
export const CarrierMethodTitles = defineMessages({
  [FreightMode.FTL]: {
    id: "freightBooking.carrierMethod.ftl",
    defaultMessage: "Exclusive (FTL)"
  },
  [FreightMode.LTL]: {
    id: "freightBooking.carrierMethod.ltl",
    defaultMessage: "Shared (LTL)"
  },
  longHaul: {
    id: "freightBooking.carrierMethod.longHaul",
    defaultMessage: "Long Haul (Drayage & FTL)"
  },
  portPickup: {
    id: "freightBooking.carrierMethod.portPickup",
    defaultMessage: "Port Pickup (Drayage)"
  }
});
export const FREIGHT_TABLE_PORT_LABELS = defineMessages({
  [Ports.PORT_OF_JERSEY]: {
    id: "freightList.port.portOfJersey",
    defaultMessage: "Port of Jersey"
  },
  [Ports.PORT_OF_NEWARK]: {
    id: "freightList.port.portOfNewark",
    defaultMessage: "Port of Newark"
  },
  [Ports.PORT_OF_SAVANNAH]: {
    id: "freightList.port.portOfSavannah",
    defaultMessage: "Port of Savannah"
  },
  [Ports.PORT_OF_LONG_BEACH]: {
    id: "freightList.port.portOfLongBeach",
    defaultMessage: "Port of Long Beach"
  },
  [Ports.PORT_OF_LOS_ANGELES]: {
    id: "freightList.port.portOfLosAngeles",
    defaultMessage: "Port of Los Angeles"
  }
});