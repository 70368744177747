import { crossdockClient } from "common/clients/instances";
import { setCrossdockInboundQuote } from "../quote";
import { setCrossdockInboundShipment } from "./setCrossdockInboundShipment";
import log from "Logger";
import { batch } from "react-redux";
export const fetchCrossdockInboundShipment = (sellerId, shippingPlanId) => async dispatch => {
  const ctx = {
    fn: "fetchCrossdockInboundShipment",
    sellerId,
    shippingPlanId
  };
  log.info(ctx, "start");
  try {
    const shipment = await crossdockClient.getInboundShipmentByShippingPlan(sellerId, shippingPlanId);
    if (shipment) {
      batch(() => {
        dispatch(setCrossdockInboundShipment(shipment));
        dispatch(setCrossdockInboundQuote(shipment.quote));
      });
    }
    return shipment;
  } catch (err) {
    log.info(ctx, "No crossdock inbound shipment found.");
    return;
  }
};