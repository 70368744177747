import { useState } from "react";
export const useStepStack = (steps, initialStep) => {
  const [stepStack, setStepStack] = useState(initialStep);
  const currentStep = steps[stepStack[stepStack.length - 1]];
  const popStep = () => {
    if (stepStack.length > 1) {
      const newStack = stepStack.slice(0, stepStack.length - 1);
      setStepStack(newStack);
      return newStack;
    }
    return stepStack;
  };
  const pushStep = name => {
    const newStack = !name ? stepStack : Array.isArray(name) ? [...name] : !steps[name] ? stepStack : [...stepStack, name];
    setStepStack(newStack);
    return newStack;
  };
  const revert = toStepName => {
    const stepIndex = stepStack.findIndex(stepName => stepName === toStepName);
    if (stepIndex === -1) {
      return stepStack;
    }
    const newStack = stepStack.slice(0, stepIndex + 1);
    setStepStack(newStack);
    return newStack;
  };
  const replace = name => {
    const newStack = [...stepStack.slice(0, stepStack.length - 1), name];
    setStepStack(newStack);
    return newStack;
  };
  const StepComponent = currentStep.component;
  return {
    currentStep,
    pushStep,
    popStep,
    revert,
    size: stepStack.length,
    StepComponent,
    setStepStack,
    replace,
    stepStack
  };
};