/** Get an array of PrepTypes and PrepCombos that consolidates types that are contained within a combo. */
export const getConsolidatedPrepTypesFromPrepJob = prepJob => {
  if (!prepJob) {
    return [];
  }
  return Array.from(prepJob.tasks.reduce((superPrepTypesSet, {
    prepType,
    comboType
  }) => {
    superPrepTypesSet.add(comboType ?? prepType);
    return superPrepTypesSet;
  }, new Set()));
};