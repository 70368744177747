import { combineReducers } from "redux";
import { selectedActionReducer, initialSelectedAction } from "./selectedActionReducer";
import { unsyncStatusReducer, initialUnsyncStatus } from "./unsyncStatusReducer";
import { adSetsReducer, initialAdSets } from "tags/facebook/common/state/adSetsReducer";
export const facebookEditFlowInitialState = {
  selectedAction: initialSelectedAction,
  unsyncStatus: initialUnsyncStatus,
  adSets: initialAdSets
};
export const facebookEditFlowReducer = combineReducers({
  selectedAction: selectedActionReducer,
  unsyncStatus: unsyncStatusReducer,
  adSets: adSetsReducer
});