import { processPrepTaskResponse } from "./processPrepTaskResponse";

/**
 * The Prep Service doesn't properly return dates as Date objects, so we need to transform them for all Date fields on the PrepJobResponse.
 */
export const processPrepJobResponse = prepJobResponse => {
  return {
    ...prepJobResponse,
    createdAt: new Date(prepJobResponse.createdAt),
    updatedAt: new Date(prepJobResponse.updatedAt),
    receivedAt: prepJobResponse.receivedAt ? new Date(prepJobResponse.receivedAt) : undefined,
    startedAt: prepJobResponse.startedAt ? new Date(prepJobResponse.startedAt) : undefined,
    completedAt: prepJobResponse.completedAt ? new Date(prepJobResponse.completedAt) : undefined,
    billedAt: prepJobResponse.billedAt ? new Date(prepJobResponse.billedAt) : undefined,
    completionDueAt: prepJobResponse.completionDueAt ? new Date(prepJobResponse.completionDueAt) : undefined,
    slaStartedAt: prepJobResponse.slaStartedAt ? new Date(prepJobResponse.slaStartedAt) : undefined,
    nonComplianceReportedAt: prepJobResponse.nonComplianceReportedAt ? new Date(prepJobResponse.nonComplianceReportedAt) : undefined,
    nonComplianceResolvedAt: prepJobResponse.nonComplianceResolvedAt ? new Date(prepJobResponse.nonComplianceResolvedAt) : undefined,
    tasks: prepJobResponse.tasks.map(prepTask => processPrepTaskResponse(prepTask))
  };
};