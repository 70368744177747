import { useMemo, useState } from "react";
import { produce } from "immer";
import { sortBy } from "lodash/fp";
import { getAccordionMetadataMap } from "inbounds/utils/getAccordionMetadataMap";
export const useStepMetadata = steps => {
  const [accordionMetadataMap, setAccordionMetadataMap] = useState(getAccordionMetadataMap(steps));
  const [openSteps, setOpenSteps] = useState(() => {
    const initialStepState = {};
    if (steps[0]) {
      initialStepState[steps[0].id] = true;
    }
    return initialStepState;
  });
  const setStepIsValid = ({
    stepKey,
    isValid
  }) => {
    if (accordionMetadataMap[stepKey]) {
      setAccordionMetadataMap(prevMetadataMap => produce(prevMetadataMap, draft => {
        draft[stepKey].isValid = isValid;
      }));
    }
  };
  const toggleOpen = key => {
    setOpenSteps(prevSteps => ({
      ...prevSteps,
      [key]: !prevSteps[key]
    }));
  };
  const stepAccordions = useMemo(() =>
  // the component will iterate through each StepAccordionMetadata to render the accordions
  steps.map((step, idx) => {
    const sortedSteps = sortBy(({
      index
    }) => index, Object.values(accordionMetadataMap));
    // A step is disabled if any of its preceding steps are not valid
    const disabled = !sortedSteps.slice(0, idx).every(({
      isValid
    }) => isValid);
    const firstInvalidStep = sortedSteps.filter(({
      isValid
    }) => !isValid)?.[0];
    // A step is open by default if it's not disabled and is the first invalid step
    const isDefaultOpen = !disabled && idx === firstInvalidStep?.index;
    return {
      ...step,
      disabled,
      isDefaultOpen
    };
  }), [steps, accordionMetadataMap]);
  return {
    stepAccordions,
    setStepIsValid,
    openSteps,
    toggleOpen
  };
};