import { getLoadedShipmentSkuCount, getLoadedPlannedShipment, getLoadedShipmentPackages } from "inbounds/steps/InboundStepSelectors";
import { sumBy, uniq } from "lodash/fp";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { commonMsgs } from "inbounds/utils/commonMsgs";
import { getIsSpdDeliverr } from "inbounds/utils/shippingMethodUtils";

/**
 * Get relevant data for displaying Deliverr rate estimates and general shipment info.
 */
export const useDeliverrRateSummary = () => {
  const {
    formatMessage
  } = useIntl();
  const skuCount = useSelector(getLoadedShipmentSkuCount);
  const {
    estimatedRates,
    identicalPackageCounts,
    boxArrangement,
    shippingMethod
  } = useSelector(getLoadedPlannedShipment);
  const isSpdDeliverr = getIsSpdDeliverr(shippingMethod);
  const packages = useSelector(getLoadedShipmentPackages);
  const totalPackageCount = packages.reduce((count, _pkg, pkgIndex) => count + (identicalPackageCounts[pkgIndex] || 1), 0);
  const totalWeightLbs = packages.reduce((totalWeight, pkg, pkgIndex) => totalWeight + pkg.weight * (identicalPackageCounts[pkgIndex] || 1), 0);
  const estimatedRatesAmount = estimatedRates && sumBy("amount", estimatedRates);
  const shippingProviders = uniq(estimatedRates?.map(({
    shippingProvider
  }) => shippingProvider));
  const boxesCountPluralized = formatMessage(commonMsgs.box, {
    boxesQty: totalPackageCount
  });
  const boxesWeightPluralized = formatMessage(commonMsgs.lb, {
    weight: totalWeightLbs
  });
  const deliverrShipmentOption = isSpdDeliverr ? shippingProviders : formatMessage({
    id: "inbounds.shippingMethod.palletsFlexport",
    defaultMessage: "LTL or FTL (Flexport rates)"
  });
  return {
    estimatedRates,
    estimatedRatesAmount,
    skuCount,
    totalWeightLbs,
    shippingProviders,
    deliverrShipmentOption,
    totalPackageCount,
    boxesCountPluralized,
    boxesWeightPluralized,
    boxArrangement
  };
};