import { AttachmentType, ShippingPartner } from "@deliverr/replenishment-client";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { transferCreateSetFbaReferenceId, transferCreateSetFbaShipmentId } from "transfers/create/actions";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { FBA_REFERENCE_ID_REGEX_PATTERN, FBA_SHIPMENT_ID_REGEX_PATTERN } from "common/regex/fbaIds";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
import { FBA_SHIPMENT_ID_LENGTH, FBA_REFERENCE_ID_LENGTH, AMAZON_UPSTREAM_STORAGE_SHIPMENT_ID_LENGTH } from "../../const/fbaConstants";
function isValidAttributeValue(attribute, validRegex) {
  if (!attribute) {
    return false;
  }
  return validRegex.test(attribute);
}
export const useReplenishmentShippingDetailsStep = () => {
  const {
    fbaShipmentId,
    fbaReferenceId,
    wholesaleOrder,
    attachments = {},
    shippingMethod,
    cargoType,
    destinationAddress
  } = useSelector(selectTransferCreate);
  const dispatch = useDispatch();
  const [isUploadingLabel, setIsUploadingLabel] = useState({});
  const boxLabelFilename = attachments?.[AttachmentType.CASE_LABELS]?.fileName;
  const commercialInvoiceFileName = attachments?.[AttachmentType.COMMERCIAL_INVOICE]?.fileName;
  const isLoadingOrder = useSelector(selectAnyIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);
  const [currentFbaShipmentId, setCurrentFbaShipmentId] = useState(fbaShipmentId ?? "FBA");
  const [currentFbaReferenceId, setCurrentFbaReferenceId] = useState(fbaReferenceId ?? "");
  const [shouldShowFbaRefHint, setShowFbaRefHint] = useState(false);
  const [shouldShowFbaShipmentHint, setShowFbaShipmentHint] = useState(false);
  const [shouldShowBoxLabelHint, setShowBoxLabelHint] = useState(false);
  const isSellerCaseLabel = shippingMethod === ShippingPartner.SELLER && cargoType === WholesaleShipmentType.PARCEL;
  const isShipmentTypeParcel = cargoType === WholesaleShipmentType.PARCEL;
  const destinationCountry = destinationAddress?.country ?? "";
  const isCommercialInvoiceRequired = destinationCountry === "CA";
  const [shouldShowCommercialInvoiceHint, setShowCommercialInvoiceHint] = useState(false);
  const updateShipmentDetailsState = useCallback(() => {
    if (currentFbaShipmentId) {
      dispatch(transferCreateSetFbaShipmentId(currentFbaShipmentId));
    }
    if (currentFbaReferenceId) {
      dispatch(transferCreateSetFbaReferenceId(currentFbaReferenceId));
    }
  }, [currentFbaShipmentId, currentFbaReferenceId, dispatch]);
  const handleIdUpdate = useCallback((value, regex) => {
    const isValidAttr = isValidAttributeValue(value, regex);
    if (isValidAttr) {
      updateShipmentDetailsState();
    }
    return isValidAttr;
  }, [updateShipmentDetailsState]);
  const onShipmentIdChange = useCallback(evt => {
    const value = evt.currentTarget.value.toUpperCase();
    const shouldUpdateValue = !(value.startsWith("FBA") && value.length > FBA_SHIPMENT_ID_LENGTH || value.startsWith("STAR") && value.length > AMAZON_UPSTREAM_STORAGE_SHIPMENT_ID_LENGTH);
    if (shouldUpdateValue) {
      setCurrentFbaShipmentId(value);
    }
    if (value.length === FBA_SHIPMENT_ID_LENGTH || value.length === AMAZON_UPSTREAM_STORAGE_SHIPMENT_ID_LENGTH) {
      setShowFbaShipmentHint(!handleIdUpdate(value, FBA_SHIPMENT_ID_REGEX_PATTERN));
    }
  }, [handleIdUpdate]);
  const onReferenceIdChange = useCallback(evt => {
    const value = evt.currentTarget.value.toUpperCase();
    if (value.length <= FBA_REFERENCE_ID_LENGTH) {
      setCurrentFbaReferenceId(value);
    }
    if (value.length === FBA_REFERENCE_ID_LENGTH) {
      setShowFbaRefHint(!handleIdUpdate(value, FBA_REFERENCE_ID_REGEX_PATTERN));
    }
  }, [handleIdUpdate]);
  const onShipmentIdBlur = useCallback(() => {
    setShowFbaShipmentHint(!handleIdUpdate(currentFbaShipmentId, FBA_SHIPMENT_ID_REGEX_PATTERN));
  }, [currentFbaShipmentId, handleIdUpdate]);
  const onReferenceIdBlur = useCallback(() => {
    setShowFbaRefHint(!handleIdUpdate(currentFbaReferenceId, FBA_REFERENCE_ID_REGEX_PATTERN));
  }, [currentFbaReferenceId, handleIdUpdate]);
  const validate = () => {
    let canClickNext = true;
    if (!isValidAttributeValue(currentFbaShipmentId, FBA_SHIPMENT_ID_REGEX_PATTERN)) {
      setShowFbaShipmentHint(true);
      canClickNext = false;
    }
    if (!isShipmentTypeParcel && !isValidAttributeValue(currentFbaReferenceId, FBA_REFERENCE_ID_REGEX_PATTERN)) {
      setShowFbaRefHint(true);
      canClickNext = false;
    }
    if (!boxLabelFilename) {
      setShowBoxLabelHint(true);
      canClickNext = false;
    }
    if (isCommercialInvoiceRequired && !commercialInvoiceFileName) {
      setShowCommercialInvoiceHint(true);
      canClickNext = false;
    }
    return canClickNext;
  };
  return {
    currentFbaShipmentId,
    currentFbaReferenceId,
    isSellerCaseLabel,
    isUploadingLabel,
    setIsUploadingLabel,
    wholesaleOrder,
    attachments,
    isLoadingOrder,
    shouldShowFbaShipmentHint,
    shouldShowFbaRefHint,
    shouldShowBoxLabelHint,
    isShipmentTypeParcel,
    validate,
    onShipmentIdChange,
    onShipmentIdBlur,
    onReferenceIdChange,
    onReferenceIdBlur,
    shouldShowCommercialInvoiceHint,
    isCommercialInvoiceRequired
  };
};