import { FeatureName, useFeatureOn } from "common/Split";
import { useCallback, useState } from "react";
import { isEmpty, isNil } from "lodash/fp";
import { createDispatcher } from "common/Hooks";
import { batch, useDispatch, useSelector } from "react-redux";
import { getSelectedProducts } from "../../store/selector/getSelectedProducts";
import { addBulkUploadProduct, addStorageProductDetails, removeStorageProduct, updateCaseQty, updateNumberOfCases } from "../SelectStorageProductsActions";
import { storageClient } from "common/clients/instances";
import { getSellerId } from "common/user/UserSelectors";
import { setSelectedProductsCaseDimensions } from "../../dimensions/StorageCaseDimensionActions";
import { setStorageInboundLotInformation } from "../../lotTracking/actions/setStorageInboundLotInformation";
import { mapStorageItemToProductLotInformationFields } from "storage/inbounds/utils/mapStorageItemToProductLotInformationFields";
import { getStorageItemDetails } from "../getStorageItemDetails";
import { setSelectedCasePackOption } from "../../selectProductCasePacks/SelectProductCasePackActions";
import { getSelectedProductCasePacks } from "storage/inbounds/create/store/selector/getSelectedCasePacks";
import { createNewProductCasePack } from "../../selectProductCasePacks/actions/createNewProductCasePack";
import { forEach, keyBy, map } from "lodash";
import { addStorageProductWithCaseDetails } from "../../store/actions/addStorageProductWithCaseDetails";
export const useStorageInboundProductChooser = () => {
  const dispatch = useDispatch();
  const dispatcher = createDispatcher(dispatch);
  const selectedProducts = useSelector(getSelectedProducts);
  const sellerId = useSelector(getSellerId);
  const [shouldShowModal, setShowModal] = useState(false);
  const isStorageMultiCasePackOn = !!useFeatureOn(FeatureName.StorageMultiCasePack);
  const shouldShowFixCasePackWarning = !isStorageMultiCasePackOn && selectedProducts.some(val => !val.actualCaseQty);
  const selectedProductCasePacks = useSelector(getSelectedProductCasePacks);
  const onChangeSelectedProductCasePack = productCasePack => {
    dispatch(setSelectedCasePackOption(productCasePack.packOf, productCasePack));
    dispatch(updateCaseQty(productCasePack.packOf, productCasePack.quantity));
  };
  const onNewCasePackFormSubmit = productCasePackData => {
    dispatch(createNewProductCasePack(productCasePackData));
  };
  const addProduct = (dsku, product) => {
    return dispatch(addStorageProductWithCaseDetails(dsku, product));
  };
  const removeProduct = e => {
    dispatch(removeStorageProduct(e));
  };
  const onCreateProductSuccess = product => {
    addProduct(product.dsku, product);
    window.open(`/inventory/${product.dsku}`, "_blank");
  };
  const handleStorageBulkUploadSuccess = useCallback(async (products, caseDimensions, lotTracking) => {
    batch(() => {
      selectedProducts.forEach(({
        dsku
      }) => dispatch(removeStorageProduct(dsku)));
    });
    batch(() => {
      products.forEach(product => {
        dispatch(addBulkUploadProduct(product, undefined));
      });
      dispatch(getStorageItemDetails(products));
    });
    if ((caseDimensions ?? []).length > 0) {
      const productsContainerDimensions = caseDimensions?.reduce((productsContainerDimension, {
        dsku,
        height,
        weight,
        length,
        width,
        dimensionUnit,
        weightUnit
      }) => {
        productsContainerDimension[dsku] = {
          height,
          weight,
          length,
          width,
          lengthUnit: dimensionUnit,
          weightUnit
        };
        return productsContainerDimension;
      }, {});
      productsContainerDimensions && dispatch(setSelectedProductsCaseDimensions(productsContainerDimensions));
    }
    if (lotTracking && !isEmpty(lotTracking)) {
      const lotTrackingObject = {};
      Object.keys(lotTracking).forEach(dsku => {
        lotTrackingObject[dsku] = mapStorageItemToProductLotInformationFields(lotTracking[dsku]);
      });
      dispatch(setStorageInboundLotInformation(lotTrackingObject));
    }
  }, [dispatch, selectedProducts]);
  const handleStorageBulkUploadSuccessForMultiPack = useCallback(async (inputProducts, inputPackDimensions, lotTracking) => {
    const inputPackDimensionsByDsku = keyBy(inputPackDimensions, ({
      dsku
    }) => dsku);
    const storageInboundBulkUploadRequest = {
      sellerId,
      records: map(inputProducts, product => ({
        productData: product,
        packData: inputPackDimensionsByDsku?.[product.dsku],
        lotTrackingData: lotTracking?.[product.dsku]
      }))
    };
    const selectedProductRecords = (await storageClient.processStorageInboundBulkUploads(storageInboundBulkUploadRequest)).value ?? [];
    const productDetails = keyBy(map(selectedProductRecords, ({
      product
    }) => product), ({
      dsku
    }) => dsku);
    batch(() => {
      selectedProducts.forEach(({
        dsku
      }) => dispatch(removeStorageProduct(dsku)));
    });
    batch(() => {
      forEach(selectedProductRecords, ({
        product,
        pack
      }) => {
        if (!isNil(product.qty) && product.qty > 0) {
          dispatch(addBulkUploadProduct(product, pack));
        }
      });
      dispatch(addStorageProductDetails(productDetails));
    });
    if (lotTracking && !isEmpty(lotTracking)) {
      const lotTrackingObject = {};
      Object.keys(lotTracking).forEach(dsku => {
        lotTrackingObject[dsku] = mapStorageItemToProductLotInformationFields(lotTracking[dsku]);
      });
      dispatch(setStorageInboundLotInformation(lotTrackingObject));
    }
  }, [dispatch, selectedProducts, sellerId]);
  return {
    addProduct,
    selectedProducts,
    shouldShowFixCasePackWarning,
    removeProduct,
    updateCaseQty: dispatcher(updateCaseQty),
    updateNumberOfCases: dispatcher(updateNumberOfCases),
    shouldShowModal,
    setShowModal,
    handleStorageBulkUploadSuccess: isStorageMultiCasePackOn ? handleStorageBulkUploadSuccessForMultiPack : handleStorageBulkUploadSuccess,
    selectedProductCasePacks,
    onChangeSelectedProductCasePack,
    onNewCasePackFormSubmit,
    showCasePackSelectionDropdown: isStorageMultiCasePackOn,
    shouldHideCasepackSingleUnitWarning: isStorageMultiCasePackOn,
    onCreateProductSuccess
  };
};