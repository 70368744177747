import { sumBy } from "lodash/fp";
import { useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
export const useReplenishmentShipmentSummary = () => {
  const {
    selectedProducts,
    originStorageWarehouse,
    destinationAddress,
    errorType,
    replenishmentOrder,
    destinationType
  } = useSelector(selectTransferCreate);
  const numOfSkus = Object.keys(selectedProducts).length;
  const numOfUnits = sumBy(({
    qty
  }) => qty, Object.values(selectedProducts));
  const numOfCases = sumBy(({
    qty,
    caseQty
  }) => qty / (caseQty ?? 1), Object.values(selectedProducts));
  const replenishmentShipment = replenishmentOrder?.shipments?.length ? replenishmentOrder.shipments[0] : undefined;
  return {
    numOfCases,
    numOfSkus,
    numOfUnits,
    originStorageWarehouse,
    toAddress: replenishmentShipment?.destinationWarehouseAddress ?? destinationAddress,
    errorType,
    replenishmentOrder,
    destinationType
  };
};