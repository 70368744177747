import { createReducer, handleSimpleReducerUpdates } from "../ReduxUtils";
import { FlexportUserActionTypes } from "./FlexportUserActions";
export const flexportUserInitialState = {
  dbid: undefined,
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  admin: false,
  companyEntityId: undefined,
  clientFid: undefined,
  displaySmbOnboarding: false,
  completedSmbCompanyDetailsOnboarding: false,
  submittedSmbCompanyDetailsOnboarding: false,
  completedSmbAuthorizationOnboarding: false,
  submittedSmbAuthorizationOnboarding: false,
  isFlexportCompanyExisting: null,
  isUserConnectedWithFlexport: null
};
const reducerDict = {
  ...handleSimpleReducerUpdates([FlexportUserActionTypes.SET_IS_FLEXPORT_COMPANY_EXISTING, FlexportUserActionTypes.SET_IS_USER_CONNECTED_WITH_FLEXPORT]),
  [FlexportUserActionTypes.SET_FLEXPORT_USER]: (state, action) => {
    return {
      ...state,
      name: action.name,
      firstName: action.firstName,
      lastName: action.lastName,
      email: action.email,
      admin: action.admin,
      dbid: action.dbid,
      companyEntityId: action.companyEntityId,
      clientFid: action.clientFid,
      displaySmbOnboarding: action.displaySmbOnboarding,
      completedSmbCompanyDetailsOnboarding: action.completedSmbCompanyDetailsOnboarding,
      submittedSmbCompanyDetailsOnboarding: action.submittedSmbCompanyDetailsOnboarding,
      completedSmbAuthorizationOnboarding: action.completedSmbAuthorizationOnboarding,
      submittedSmbAuthorizationOnboarding: action.submittedSmbAuthorizationOnboarding,
      // the following values are set from separate REST calls
      isFlexportCompanyExisting: action.isFlexportCompanyExisting ?? state.isFlexportCompanyExisting,
      isUserConnectedWithFlexport: action.isUserConnectedWithFlexport ?? state.isUserConnectedWithFlexport
    };
  }
};
export const flexportUserReducer = createReducer(flexportUserInitialState, reducerDict);