var _usePlaceSearchOriginQuery, _usePlaceSearchDestinationQuery;
import { isEmpty } from "lodash/fp";
import { toast } from "common/components/ui";
import { useState, useCallback } from "react";
import { useController } from "react-hook-form";
import { logError, logStart } from "Logger";
import { defineMessages, useIntl } from "react-intl";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import debounce from "lodash/debounce";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm/useCreateShipmentForm.types";
import { createShipmentInitialFormState } from "inbounds/createShipment/store";
const originSearchQuery = _usePlaceSearchOriginQuery !== void 0 ? _usePlaceSearchOriginQuery : (_usePlaceSearchOriginQuery = require("../../../../common/relay/__generated__/usePlaceSearchOriginQuery.graphql"), _usePlaceSearchOriginQuery.hash && _usePlaceSearchOriginQuery.hash !== "4e612eec6307c62c9e657d405b1d4c28" && console.error("The definition of 'usePlaceSearchOriginQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _usePlaceSearchOriginQuery);
export const destinationSearchQuery = _usePlaceSearchDestinationQuery !== void 0 ? _usePlaceSearchDestinationQuery : (_usePlaceSearchDestinationQuery = require("../../../../common/relay/__generated__/usePlaceSearchDestinationQuery.graphql"), _usePlaceSearchDestinationQuery.hash && _usePlaceSearchDestinationQuery.hash !== "5f36a39bd0e43db12a3f271a3558d2c4" && console.error("The definition of 'usePlaceSearchDestinationQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _usePlaceSearchDestinationQuery);
const formattedCityLabel = city => {
  return `${city?.city}, ${city?.country ?? city?.state}${city?.zipCode ? `, ${city?.zipCode}` : ""}`;
};
const formattedAddressValue = address => {
  return {
    name: address?.address ?? "",
    street1: address?.streetAddress ?? "",
    city: address?.city ?? "",
    country: address?.country ?? "",
    state: address?.state ?? "",
    zip: address?.zipCode ?? "",
    latLng: address?.longitude && address?.latitude ? {
      lat: address?.latitude ?? null,
      lng: address?.longitude ?? null
    } : undefined,
    countryCode: address?.countryCode ?? ""
  };
};
const formattedPortLabel = port => {
  if (!port) {
    return "";
  }
  const stateOrCountry = "state" in port.city ? port.city?.state : port.city?.country;
  return `All ports near ${port.city?.city}, ${stateOrCountry} (${port.nearbyPorts?.map(nearbyPort => nearbyPort?.abbr).filter(abbr => !isEmpty(abbr)).join(", ")})`;
};
const formattedPortValue = port => {
  return port?.nearbyPorts?.map(nearByPort => {
    return {
      abbr: nearByPort?.abbr ?? "",
      fid: nearByPort?.fid ?? ""
    };
  }) ?? [];
};
export const formattedCityOption = cities => {
  return cities?.slice(0, 4).map(city => {
    return {
      value: {
        address: formattedAddressValue(city),
        ports: [],
        distanceKm: 0
      },
      label: city?.address ?? formattedCityLabel(city)
    };
  });
};
const formattedPortOption = ports => {
  return ports?.slice(0, 4).map(port => {
    return {
      value: {
        ports: formattedPortValue(port),
        address: formattedAddressValue(port?.city),
        distanceKm: port?.distanceKm
      },
      label: formattedPortLabel(port)
    };
  });
};
const PLACE_SEARCH_GROUP_LABELS = {
  cities: defineMessages({
    title: {
      id: "placeSearch.locationTitle",
      defaultMessage: "Location"
    },
    description: {
      id: "placeSearch.citiesDescription",
      defaultMessage: "Includes trucking"
    }
  }),
  ports: defineMessages({
    title: {
      id: "placeSearch.portsTitle",
      defaultMessage: "Ports "
    },
    description: {
      id: "placeSearch.portsDescription",
      defaultMessage: "Does not include trucking"
    }
  })
};
const usePlaceSearchToDropdown = isPortOnly => {
  const {
    formatMessage
  } = useIntl();
  const placeSearchToDropDown = useCallback(placeSearch => {
    const cityOptions = {
      label: {
        title: formatMessage(PLACE_SEARCH_GROUP_LABELS.cities.title),
        description: formatMessage(PLACE_SEARCH_GROUP_LABELS.cities.description)
      },
      options: formattedCityOption(placeSearch?.cities)
    };
    const portOptions = {
      label: {
        title: formatMessage(PLACE_SEARCH_GROUP_LABELS.ports.title),
        description: formatMessage(PLACE_SEARCH_GROUP_LABELS.ports.description)
      },
      options: formattedPortOption(placeSearch?.ports)
    };
    return isPortOnly ? [portOptions] : [cityOptions, portOptions];
  }, [formatMessage, isPortOnly]);
  return placeSearchToDropDown;
};
export const usePlaceSearch = (type, isAddressReturningUserOnly, isPortOnly) => {
  const {
    formatMessage
  } = useIntl();
  const isOriginSearch = type === CreateShipmentInputName.ORIGIN;
  const environment = useRelayEnvironment();
  const ctx = isOriginSearch ? logStart({
    fn: "getOriginSearch"
  }) : logStart({
    fn: "getDestinationSearch"
  });
  const {
    field
  } = useController({
    name: type
  });
  const placeOption = field.value;
  const [isSelected, setIsSelected] = useState(placeOption !== null);
  const placeSearchToDropDown = usePlaceSearchToDropdown(isPortOnly);
  const [inputValue, setInputValue] = useState(field.value?.label);
  const handleSelect = value => {
    if (value !== null) {
      field.onChange(value);
      setInputValue(value.label);
    } else {
      field.onChange({
        value: {
          address: createShipmentInitialFormState.origin.value.address,
          ports: []
        },
        label: ""
      });
    }
    setIsSelected(true);
  };
  const handleInputChange = (value, {
    action
  }) => {
    if (action === "input-change") {
      field.onChange({
        value: {
          address: createShipmentInitialFormState.origin.value.address,
          ports: []
        },
        label: isEmpty(value.trim()) ? "" : value
      });
      setInputValue(value);
      setIsSelected(false);
    }
  };
  const isMenuOpen = placeOption.label.length !== 0 && !isSelected;
  const handleBlur = () => {
    setIsSelected(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originLoadOptions = useCallback(debounce((input, callback) => {
    fetchQuery(environment, originSearchQuery, {
      query: input,
      isAddressOnly: isAddressReturningUserOnly
    }).subscribe({
      next: ({
        originSearch
      }) => {
        callback(placeSearchToDropDown(originSearch));
      },
      start: () => {},
      error: error => {
        logError(ctx, error);
        toast.error(formatMessage({
          id: "originSearch.error",
          defaultMessage: "Error searching for origins"
        }), {
          toastId: "originSearch.error"
        });
      }
    });
  }, Number(process.env.DEFAULT_SEARCH_THROTTLE_MS)), [placeSearchToDropDown, environment, originSearchQuery, isAddressReturningUserOnly]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const destinationLoadOptions = useCallback(debounce((input, callback) => {
    fetchQuery(environment, destinationSearchQuery, {
      query: input,
      isAddressOnly: isAddressReturningUserOnly
    }).subscribe({
      next: ({
        destinationSearch
      }) => {
        callback(placeSearchToDropDown(destinationSearch));
      },
      start: () => {},
      error: error => {
        logError(ctx, error);
        toast.error(formatMessage({
          id: "destinationSearch.error",
          defaultMessage: "Error searching for destinations"
        }), {
          toastId: "destinationSearch.error"
        });
      }
    });
  }, Number(process.env.DEFAULT_SEARCH_THROTTLE_MS)), [placeSearchToDropDown, environment, destinationSearchQuery, isAddressReturningUserOnly]);
  const loadOptions = isOriginSearch ? originLoadOptions : destinationLoadOptions;
  return {
    inputValue,
    placeOption,
    handleSelect,
    isMenuOpen,
    handleInputChange,
    handleBlur,
    loadOptions
  };
};