import { OriginType } from "inbounds/createShipment/CreateShipmentTypes";
import { getIsPlaceAddress } from "inbounds/createShipment/steps/OriginSearchStep/utils";
import { _parseBookingAddress } from "./_parseBookingAddress";
export const getOriginState = booking => {
  const origin = _parseBookingAddress(booking.from ?? undefined, booking.originPortGrouping);
  const isOriginAddress = getIsPlaceAddress(origin);
  const originFacilityName = isOriginAddress ? booking.from?.name ?? null : null;
  return {
    originType: OriginType.INTERNATIONAL,
    origin,
    originFacilityName,
    isOriginPort: !isOriginAddress,
    createdBy: booking.createdBy
  };
};