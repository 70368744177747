import produce from "immer";
import update from "immutability-helper";
import { cloneDeep, isEmpty, map, mapValues, omit } from "lodash/fp";
import { addAllToById, emptyById, removeFromById, updateInById } from "common/ById";
import { deleteItemAt, insertItemAt } from "common/CollectionUtils";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { InboundStep } from "inbounds/InboundTypes";
import { validateBoxes, validateShipmentAndBoxes } from "inbounds/InboundValidationUtils";
import { AddressActionTypes } from "inbounds/steps/address/AddressActions";
import { newPackage, setPackageDskuQty } from "inbounds/steps/CreateDraftShipment";
import { CLEAR_INBOUND, LOAD_INBOUND } from "inbounds/steps/InboundLoadActions";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
import { customsInitialState, customsReducers } from "./steps/customs/CustomsReducers";
import BoxArrangement from "./steps/ship/BoxArrangement";
import { isShipToOneDispersalMethod } from "./steps/ship/InboundUtils";
import { freightTrackingInfoReducer } from "./store/reducers/freightTrackingInfoReducer";
import { appendBatteriesToShipments } from "./store/util/appendBatteriesToShipments";
import { LtlReducer } from "./store/reducers/LtlReducer";
import { initLtlById } from "./store/util/initLtlById";
import { CROSSDOCK_INITIAL_STATE, crossdockReducers } from "./crossdock/store";
import { updateShipmentQuantityReducer } from "./store/reducers/updateShipmentQuantityReducer";
import { boxLabelsFormatsState } from "./steps/ship/labels/BoxLabelUtils";
import { InboundShipmentActionTypes } from "inbounds/store/actions/shipment/InboundShipmentActionTypes";
import { getNormalizedShippingPlanItems } from "./utils/shippingPlan";
import { updateShipmentReducer, updatePackagesForShipmentReducer } from "./store/reducers/InboundReducer";
export const inboundInitialState = {
  addresses: [],
  asns: {},
  crossdock: CROSSDOCK_INITIAL_STATE,
  customs: customsInitialState,
  fromAddress: {
    name: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    country: "",
    zip: ""
  },
  hasDownloadedLabels: false,
  hasInactiveLabels: false,
  isFirstInbound: false,
  labels: {
    plan: {
      0: boxLabelsFormatsState
    },
    shipment: {
      0: boxLabelsFormatsState
    }
  },
  ltl: {},
  packages: {},
  persistedPlanItemsById: {},
  plan: {
    id: 0,
    name: ""
  },
  planItems: emptyById,
  plannedShipments: emptyById,
  plannedPackages: [],
  plannedBarcodes: {},
  isUploadingPackages: false,
  productDetailsCache: {},
  casePackDefaults: {},
  receivingInfo: {},
  shipments: emptyById,
  showBlacklisted: false,
  step: InboundStep.SELECT_PRODUCTS,
  shipmentEtaDetails: undefined,
  bulkUploadSessionId: undefined,
  isGeneratingShipments: false
};
const reducers = {
  ...handleSimpleReducerUpdates([AddressActionTypes.SET_FROM_ADDRESS, AddressActionTypes.GET_ADDRESSES, InboundActionTypes.SET_IS_REDISTRIBUTIONS, InboundActionTypes.SET_DISPERSAL_METHOD, InboundActionTypes.SET_HAS_DOWNLOADED_LABELS, InboundActionTypes.UPDATE_PRODUCT_CACHE, InboundActionTypes.LOAD_RECEIVING_INFO, InboundActionTypes.SHOW_MAX_UNITS_EXCEEDED, InboundActionTypes.SHOW_BLACKLISTED, InboundActionTypes.SET_CASE_PACK_DEFAULTS, InboundActionTypes.SET_BULK_UPLOAD_SESSION_ID, InboundActionTypes.SET_IS_GENERATING_SHIPMENTS, InboundActionTypes.SET_HAS_INACTIVE_LABELS, InboundActionTypes.SET_IS_UPLOADING_PACKAGES]),
  ...customsReducers,
  ...LtlReducer,
  ...crossdockReducers
};
reducers[CLEAR_INBOUND] = state => ({
  ...inboundInitialState,
  fromAddress: state.fromAddress
});
reducers[InboundActionTypes.SAVE_NEW_SHIPPING_PLAN_SUCCESS] = (state, {
  plan
}) => {
  return {
    ...state,
    plan
  };
};
reducers[InboundActionTypes.CREATE_SHIPPING_PLAN] = (state, {
  plan,
  products
}) => ({
  ...inboundInitialState,
  fromAddress: state.fromAddress,
  plan,
  productDetailsCache: {
    ...state.productDetailsCache,
    ...products
  }
});

// qty = case qty * number of cases
reducers[InboundActionTypes.ADD_PRODUCTS] = (state, {
  planItems,
  productDetailsCache,
  persistedPlanItemsById
}) => {
  return {
    ...state,
    planItems,
    productDetailsCache,
    persistedPlanItemsById
  };
};
reducers[InboundActionTypes.ADD_PACKAGES] = (state, {
  packages
}) => {
  return {
    ...state,
    plannedPackages: packages ?? []
  };
};
reducers[InboundActionTypes.UPDATE_BARCODES] = (state, {
  barcodes
}) => {
  return {
    ...state,
    plannedBarcodes: barcodes
  };
};
reducers[InboundActionTypes.REMOVE_PRODUCT] = (state, {
  dsku
}) => ({
  ...state,
  planItems: removeFromById(dsku, state.planItems),
  productDetailsCache: omit([dsku], state.productDetailsCache),
  persistedPlanItemsById: omit(dsku, state.persistedPlanItemsById)
});

/* Set the total quantity of a product selected for a new inbound */
reducers[InboundActionTypes.UPDATE_QTY] = (state, {
  dsku,
  qty
}) => ({
  ...state,
  planItems: updateInById(planItem => ({
    ...planItem,
    qty
  }), dsku, state.planItems)
});

/* Set the # of cases of a product selected for a new inbound when using case packs */
reducers[InboundActionTypes.UPDATE_NUMBER_OF_CASES] = (state, {
  dsku,
  numberOfCases
}) => ({
  ...state,
  planItems: updateInById(planItem => ({
    ...planItem,
    numberOfCases,
    qty: planItem.caseQty * numberOfCases
  }), dsku, state.planItems)
});

/* Set the # of units of a product per case selected for a new inbound when using case packs */
reducers[InboundActionTypes.UPDATE_QTY_PER_CASE] = (state, {
  dsku,
  caseQty
}) => ({
  ...state,
  planItems: updateInById(planItem => ({
    ...planItem,
    caseQty,
    qty: planItem.numberOfCases * caseQty
  }), dsku, state.planItems)
});
reducers[InboundActionTypes.SET_CURRENT_STEP] = (state, {
  step
}) => ({
  ...state,
  step
});
reducers[InboundShipmentActionTypes.UPDATE_SHIPMENT_ID] = (state, {
  shipmentId
}) => ({
  ...state,
  loadedShipmentId: shipmentId
});
reducers[InboundShipmentActionTypes.UPDATE_SHIPMENT] = updateShipmentReducer;
reducers[InboundShipmentActionTypes.SET_PACKAGES_FOR_SHIPMENT] = updatePackagesForShipmentReducer;
reducers[InboundActionTypes.ADD_BOX_SIZE] = (state, {
  shipmentId,
  length,
  width,
  height
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    boxSizes: [...plannedShipment.boxSizes, {
      width,
      height,
      length
    }]
  }), shipmentId, state.plannedShipments),
  hasDownloadedLabels: false
});
reducers[InboundActionTypes.REMOVE_BOX_SIZE] = (state, {
  shipmentId,
  boxSizeIndex
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    const boxSizes = deleteItemAt(plannedShipment.boxSizes, boxSizeIndex);
    const selectedBoxSizes = plannedShipment.selectedBoxSizes.map(selectedIx => {
      if (selectedIx === boxSizeIndex) {
        return 0;
      }
      if (selectedIx > boxSizeIndex) {
        return selectedIx - 1;
      }
      return selectedIx;
    });
    return {
      ...plannedShipment,
      boxSizes,
      selectedBoxSizes
    };
  }, shipmentId, state.plannedShipments)
});

/* Set the total number of packages in many-SKUs-per-box mode */
reducers[InboundActionTypes.SET_PACKAGE_COUNT] = (state, {
  shipmentId,
  packageCount
}) => produce(state, draft => {
  const plannedShipmentDraft = draft.plannedShipments.byId[shipmentId];
  const {
    packageCount: oldPackageCount,
    packages,
    boxSizes,
    selectedBoxSizes,
    identicalPackageCounts
  } = plannedShipmentDraft;
  const packageCountDifference = packageCount - oldPackageCount;
  plannedShipmentDraft.packageCount = packageCount;
  if (packageCountDifference <= 0) {
    plannedShipmentDraft.boxSizes = boxSizes.slice(0, packageCount);
    plannedShipmentDraft.packages = packages.slice(0, packageCount);
    plannedShipmentDraft.identicalPackageCounts = identicalPackageCounts.slice(0, packageCount);
    // if selected box size index is out of range, default to 0
    plannedShipmentDraft.selectedBoxSizes = selectedBoxSizes.slice(0, packageCount).map(boxSizeIndex => boxSizeIndex >= plannedShipmentDraft.boxSizes.length ? 0 : boxSizeIndex);
  } else {
    const existingShipment = draft.shipments.byId[plannedShipmentDraft.id];
    const emptyPackageJson = JSON.stringify(newPackage(existingShipment, draft.fromAddress));
    for (let i = 0; i < packageCountDifference; i++) {
      plannedShipmentDraft.packages.push(JSON.parse(emptyPackageJson));
      plannedShipmentDraft.identicalPackageCounts.push(1);
      plannedShipmentDraft.selectedBoxSizes.push(0);
    }
  }
});
reducers[InboundActionTypes.ADD_ONE_SKU_BOX_CONFIG] = (state, {
  dsku
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    const {
      packages,
      selectedBoxSizes,
      boxSizes,
      identicalPackageCounts
    } = plannedShipment;
    const lastPackageIndexWithThisDsku = packages.length - 1 - packages.slice().reverse().findIndex(pkg => pkg.items.some(item => item.dsku === dsku));
    const shipment = state.shipments.byId[plannedShipment.id];
    const insertAtIndex = lastPackageIndexWithThisDsku + 1;
    const newPackageForDsku = setPackageDskuQty(newPackage(shipment, state.fromAddress), dsku, 0);
    return {
      ...plannedShipment,
      packages: insertItemAt(packages, insertAtIndex, newPackageForDsku),
      boxSizes: [...boxSizes, {
        width: 0,
        length: 0,
        height: 0
      }],
      packageCount: packages.length + 1,
      selectedBoxSizes: insertItemAt(selectedBoxSizes, insertAtIndex, boxSizes.length),
      identicalPackageCounts: insertItemAt(identicalPackageCounts, insertAtIndex, 0)
    };
  }, state.loadedShipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.DUPLICATE_PACKAGE] = (state, {
  packageIndex
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    let {
      packages
    } = plannedShipment;
    const {
      selectedBoxSizes,
      identicalPackageCounts
    } = plannedShipment;
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const insertAtIndex = packageIndex + 1;
    packages = insertItemAt(packages, insertAtIndex, {
      ...newPackage(state.shipments.byId[plannedShipment.id], state.fromAddress),
      ...cloneDeep(packages[packageIndex])
    });
    return {
      ...plannedShipment,
      packages,
      packageCount: packages.length,
      selectedBoxSizes: insertItemAt(selectedBoxSizes, insertAtIndex, selectedBoxSizes[packageIndex]),
      identicalPackageCounts: insertItemAt(identicalPackageCounts, insertAtIndex, 0)
    };
  }, state.loadedShipmentId, state.plannedShipments)
});

/* Set the number of identical packages for a given package spec in one-sku-per-box mode */
reducers[InboundActionTypes.SET_NUMBER_OF_BOXES] = (state, {
  packageIndex,
  numberOfBoxes
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    identicalPackageCounts: update(plannedShipment.identicalPackageCounts, {
      $splice: [[packageIndex, 1, numberOfBoxes]]
    })
  }), state.loadedShipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.REMOVE_PACKAGE] = (state, {
  packageIndex
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    const {
      packages,
      selectedBoxSizes,
      identicalPackageCounts
    } = plannedShipment;
    return {
      ...plannedShipment,
      packageCount: packages.length - 1,
      packages: deleteItemAt(packages, packageIndex),
      selectedBoxSizes: deleteItemAt(selectedBoxSizes, packageIndex),
      identicalPackageCounts: deleteItemAt(identicalPackageCounts, packageIndex)
    };
  }, state.loadedShipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.SET_BOX_QTY] = (state, {
  shipmentId,
  dsku,
  boxIndex,
  qty
}) => produce(state, draft => {
  const plannedShipmentDraft = draft.plannedShipments.byId[shipmentId];
  const items = plannedShipmentDraft.packages[boxIndex].items;
  const itemIndex = items.findIndex(_item => _item.dsku === dsku);
  if (itemIndex >= 0) {
    items[itemIndex].qty = qty;
  } else {
    items.push({
      dsku,
      qty
    });
  }
});
reducers[InboundActionTypes.SET_BOX_SIZE] = (state, {
  shipmentId,
  boxIndex,
  boxSizeIndex
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    const selectedBoxSizes = [...plannedShipment.selectedBoxSizes];
    selectedBoxSizes[boxIndex] = boxSizeIndex;
    return {
      ...plannedShipment,
      selectedBoxSizes
    };
  }, shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.SET_BOX_DIMENSIONS] = (state, {
  shipmentId,
  boxIndex,
  width,
  length,
  height
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    const boxSizes = [...plannedShipment.boxSizes];
    boxSizes[boxIndex] = {
      width,
      length,
      height
    };
    return {
      ...plannedShipment,
      boxSizes
    };
  }, shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.SET_NUM_BOXES] = (state, {
  shipmentId,
  boxIndex,
  numBoxes
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    const identicalPackageCounts = [...plannedShipment.identicalPackageCounts];
    identicalPackageCounts[boxIndex] = numBoxes;
    return {
      ...plannedShipment,
      identicalPackageCounts
    };
  }, shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.SET_BOX_WEIGHT] = (state, {
  shipmentId,
  boxIndex,
  weightLbs
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => {
    const packages = [...plannedShipment.packages];
    packages[boxIndex] = {
      ...packages[boxIndex],
      weight: weightLbs,
      weightUnit: "lb"
    };
    return {
      ...plannedShipment,
      packages
    };
  }, shipmentId, state.plannedShipments)
});
reducers[InboundShipmentActionTypes.ATTEMPT_CONFIRM_BOXES] = (state, {
  shipmentId
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    boxConfirmAttempted: true
  }), shipmentId, state.plannedShipments)
});

/**
 * In the situation where we have case packs if we confirm boxes for one shipment,
 * we would also like to copy those same settings over to the other shipments.
 * @param state
 * @param param1
 */
reducers[InboundShipmentActionTypes.CONFIRM_BOXES] = (state, {
  shipmentId,
  hasBlindBoxContentsLabelsPrep
}) => {
  const shipment = state.plannedShipments.byId[shipmentId];
  const isValid = validateShipmentAndBoxes(shipment, state.shipments.byId[shipmentId], {
    hasBlindBoxContentsLabelsPrep
  });
  if (isValid && state.plan.useCasePack && shipment.boxArrangement === BoxArrangement.OneSKUPerBox) {
    const casePackInfoLookup = Object.assign({}, ...shipment.packages.filter(pkg => pkg.items.length === 1).map(({
      items,
      weight
    }, index) => ({
      [items[0].dsku]: {
        length: shipment.boxSizes[index].length,
        width: shipment.boxSizes[index].width,
        height: shipment.boxSizes[index].height,
        weight
      }
    })));
    const numberOfSkus = Object.keys(casePackInfoLookup).length;
    state.plannedShipments.ids.forEach(id => {
      const shipmentToUpdate = state.plannedShipments.byId[id];
      if (numberOfSkus !== shipmentToUpdate.packages.length) {
        return;
      }
      const boxesAndPkgs = shipmentToUpdate.packages.map((pkg, index) => {
        const box = shipmentToUpdate.boxSizes[index];
        if (pkg.items.length > 1 || box.length > 0 || box.height > 0 || box.width > 0) {
          return {
            package: pkg,
            box
          };
        }

        // provide fallback of empty object as dims should be non-blocking if not populated / found in look-up
        const {
          length,
          width,
          height,
          weight
        } = casePackInfoLookup[pkg.items[0].dsku] ?? {};
        return {
          package: {
            ...pkg,
            weight
          },
          box: {
            length,
            width,
            height
          }
        };
      });
      state = {
        ...state,
        plannedShipments: updateInById(plannedShipment => ({
          ...plannedShipment,
          packages: boxesAndPkgs.map(both => both.package),
          boxSizes: boxesAndPkgs.map(both => both.box)
        }), id, state.plannedShipments)
      };
    });
  }
  return {
    ...state,
    plannedShipments: updateInById(plannedShipment => ({
      ...plannedShipment,
      boxesConfirmed: validateBoxes(plannedShipment, state.shipments.byId[shipmentId], {
        hasBlindBoxContentsLabelsPrep
      }),
      isValid: validateShipmentAndBoxes(plannedShipment, state.shipments.byId[shipmentId], {
        hasBlindBoxContentsLabelsPrep
      })
    }), shipmentId, state.plannedShipments),
    // anytime we confirm boxes, whatever box content label pdf links are in state are no longer valid
    // removing them from state forces the re-fetching of new label links with the latest package data
    labels: {
      plan: {
        [state.plan.id]: boxLabelsFormatsState
      },
      shipment: {
        [shipmentId]: boxLabelsFormatsState
      }
    }
  };
};
reducers[InboundShipmentActionTypes.COST_ESTIMATE_COMPLETE] = (state, {
  shipmentId,
  rates
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    estimatedRates: rates,
    estimatedRatesUpdatedAt: Date.now()
  }), shipmentId, state.plannedShipments)
});
reducers[InboundShipmentActionTypes.ROLLBACK_CONFIRM_BOXES] = (state, {
  shipmentId
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    boxesConfirmed: false,
    isValid: false
  }), shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.ACCEPT_CHARGES_START] = (state, {
  shipmentId
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    hasChargesAccepted: false
  }), shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.UPDATE_TRACKING_NUMBER] = (state, {
  shipmentId,
  savedPackages
}) => ({
  ...state,
  packages: {
    ...state.packages,
    [shipmentId]: savedPackages
  }
});
reducers[InboundActionTypes.ACCEPT_CHARGES_SUCCESS] = (state, {
  shipmentId
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    hasChargesAccepted: true
  }), shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.CONFIRM_PRICING_AND_FEES] = (state, {
  shipmentId
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    hasConfirmedPricingAndFees: true
  }), shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.SET_SHIPPING_METHOD] = (state, {
  shipmentId,
  shippingMethod
}) => {
  return {
    ...state,
    plannedShipments: updateInById(plannedShipment => ({
      ...plannedShipment,
      shippingMethod,
      isValid: false
    }), shipmentId, state.plannedShipments)
  };
};
reducers[InboundActionTypes.SET_CARGO_TYPE] = (state, {
  shipmentId,
  cargoType
}) => {
  return {
    ...state,
    plannedShipments: updateInById(plannedShipment => ({
      ...plannedShipment,
      cargoType
    }), shipmentId, state.plannedShipments),
    freightShipmentInfo: {
      ...state.freightShipmentInfo,
      [shipmentId]: {
        ...state.freightShipmentInfo?.[shipmentId],
        hasConfirmedPalletCompliance: false
      }
    }
  };
};
reducers[LOAD_INBOUND] = (state, action) => {
  const {
    plan,
    planItems,
    plannedShipments,
    plannedPackages,
    productDetailsCache,
    shipments,
    loadedShipmentId,
    step,
    packagesByShipmentId,
    asnsByShipmentId,
    crossDockAsn,
    fromAddress,
    shipmentToReceivingInfo,
    casePackDefaults,
    ltl,
    shipmentEtaDetails
  } = action;
  const updatedProductDetails = {
    ...state.productDetailsCache,
    ...productDetailsCache
  };
  const baseNewState = {
    ...state,
    plan,
    planItems: planItems ?? state.planItems,
    step,
    productDetailsCache: updatedProductDetails,
    loadedShipmentId,
    packages: packagesByShipmentId,
    plannedPackages: plannedPackages ?? [],
    asns: asnsByShipmentId,
    crossDockAsn,
    fromAddress,
    casePackDefaults,
    isRedistributions: Boolean(isShipToOneDispersalMethod(plan.dispersalMethod)),
    dispersalMethod: plan.dispersalMethod,
    receivingInfo: shipmentToReceivingInfo,
    ltl,
    shipmentEtaDetails
  };
  if (!isEmpty(shipments)) {
    const shipmentIds = shipments.map(shipment => shipment.id);
    const loadedShipments = addAllToById(shipmentIds, plannedShipments);
    const shipmentById = addAllToById(shipmentIds, shipments);

    /* each shipment requires a boolean that checks for hazmat compliance if applicable */
    loadedShipments.byId = appendBatteriesToShipments(loadedShipments.byId);
    return {
      ...baseNewState,
      loadedShipmentId: loadedShipmentId || shipmentIds[0],
      shipments: shipmentById,
      plannedShipments: loadedShipments
    };
  }
  return baseNewState;
};
reducers[InboundActionTypes.CREATE_SHIPMENTS_SUCCESS] = (state, {
  shipmentIds,
  shipments,
  plannedShipments
}) => {
  const createdShipments = addAllToById(shipmentIds, plannedShipments);
  /* each shipment requires a boolean that checks for hazmat compliance if applicable */
  createdShipments.byId = appendBatteriesToShipments(createdShipments.byId);
  return {
    ...state,
    shipments: addAllToById(shipmentIds, shipments),
    packages: {},
    plannedShipments: createdShipments,
    ltl: initLtlById(shipmentIds)
  };
};
reducers[InboundShipmentActionTypes.REPLACE_SHIPMENT] = (state, {
  shipment
}) => {
  return produce(state, draft => {
    draft.plannedShipments.byId[shipment.id] = shipment;
  });
};
reducers[InboundActionTypes.COMPLETE_SHIPMENT] = (state, {
  shipmentId,
  savedPackages,
  shipment
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    // Discard the extra packages we were temporarily keeping dims/weights for
    packages: plannedShipment.packages.slice(0, plannedShipment.packageCount)
  }), shipmentId, state.plannedShipments),
  shipments: updateInById(_ => shipment, shipmentId, state.shipments),
  packages: {
    ...state.packages,
    [shipmentId]: savedPackages
  }
});

// swap planItems and persistedPlanItems
reducers[InboundActionTypes.SET_USE_CASE_PACKS] = (state, {
  useCasePack
}) => ({
  ...state,
  plan: {
    ...state.plan,
    useCasePack
  },
  planItems: {
    ...state.planItems,
    byId: state.persistedPlanItemsById && Object.keys(state.persistedPlanItemsById).length ? {
      ...state.persistedPlanItemsById
    } : {
      ...state.planItems.byId
    }
  },
  persistedPlanItemsById: {
    ...state.planItems.byId
  }
});
reducers[InboundActionTypes.SET_SHIPMENT_FREIGHT_INFO] = freightTrackingInfoReducer;
reducers[InboundActionTypes.UPDATE_SHIPMENT_QUANTITY] = updateShipmentQuantityReducer;
reducers[InboundShipmentActionTypes.LOAD_ASNS] = (state, {
  asns,
  shipmentId,
  crossDockAsn,
  receivingInfo
}) => ({
  ...state,
  asns: {
    ...state.asns,
    [shipmentId]: asns
  },
  receivingInfo: {
    ...state.receivingInfo,
    [shipmentId]: receivingInfo
  },
  crossDockAsn: crossDockAsn || state.crossDockAsn
});
reducers[InboundShipmentActionTypes.SET_BOX_ARRANGEMENT] = (state, {
  boxArrangement,
  shipmentId
}) => ({
  ...state,
  plannedShipments: updateInById(plannedShipment => ({
    ...plannedShipment,
    boxArrangement
  }), shipmentId, state.plannedShipments)
});
reducers[InboundActionTypes.SET_IS_FIRST_INBOUND] = (state, {
  isFirstInbound
}) => ({
  ...state,
  isFirstInbound
});
reducers[InboundActionTypes.SAVE_OLD_INBOUND_STATE] = state => ({
  ...state,
  oldState: omit(["oldState"], state)
});
reducers[InboundShipmentActionTypes.CLEAR_RATES] = state => ({
  ...state,
  plannedShipments: {
    ...state.plannedShipments,
    byId: mapValues(plannedShipment => ({
      ...plannedShipment,
      estimatedRates: undefined,
      estimatedRatesUpdatedAt: undefined
    }), state.plannedShipments.byId)
  }
});
reducers[AddressActionTypes.COPY_ADDRESS_TO_ALL_SHIPMENTS] = state => ({
  ...state,
  plannedShipments: {
    ids: state.plannedShipments.ids,
    byId: mapValues(plannedShipment => ({
      ...plannedShipment,
      fromAddress: state.fromAddress
    }), state.plannedShipments.byId)
  }
});
reducers[InboundActionTypes.UPDATE_PLAN] = (state, {
  planUpdate
}) => ({
  ...state,
  plan: {
    ...state.plan,
    ...planUpdate
  },
  planItems: planUpdate.items ? getNormalizedShippingPlanItems(planUpdate) : state.planItems
});
reducers[InboundActionTypes.LOAD_SHIPMENTS] = (state, {
  shipments
}) => ({
  ...state,
  shipments: addAllToById(map("id", shipments), shipments)
});
reducers[InboundActionTypes.SET_BOX_LABEL_URL] = (state, {
  format,
  labelLanguage,
  shipmentId,
  shippingPlanId,
  url
}) => {
  // ignore "OK" response
  if (!url || url.length < 3) {
    return state;
  }
  return produce(state, draft => {
    if (shipmentId) {
      const shipmentLabelMap = draft.labels.shipment[shipmentId] || {};
      shipmentLabelMap[format] = draft.labels.shipment[shipmentId]?.[format] || {};
      shipmentLabelMap[format][labelLanguage] = url;
      draft.labels.shipment[shipmentId] = shipmentLabelMap;
    } else {
      const planLabelMap = draft.labels.plan[shippingPlanId] || {};
      planLabelMap[format] = draft.labels.plan[shippingPlanId]?.[format] || {};
      planLabelMap[format][labelLanguage] = url;
      draft.labels.plan[shippingPlanId] = planLabelMap;
    }
  });
};
reducers[InboundActionTypes.SET_HAS_DOWNLOADED_SHIPMENT_BOX_LABELS] = (state, {
  shipmentId,
  hasDownloadedBoxLabels
}) => {
  if (!shipmentId) {
    return state;
  }
  return produce(state, draft => {
    draft.plannedShipments.byId[shipmentId].hasDownloadedBoxLabels = hasDownloadedBoxLabels;
  });
};
reducers[InboundActionTypes.TOGGLE_BATTERY_COMPLIANCE] = (state, {
  checked
}) => {
  return produce(state, draft => {
    const currentShipmentId = draft.loadedShipmentId;
    if (currentShipmentId) {
      draft.plannedShipments.byId[currentShipmentId].hasConfirmedBatteriesCompliance = checked;
    }
    return draft;
  });
};
reducers[InboundActionTypes.TOGGLE_PACKING_LIST_DOWNLOADED] = (state, {
  hasDownloaded
}) => {
  return produce(state, draft => {
    const currentShipmentId = draft.loadedShipmentId;
    if (currentShipmentId) {
      draft.plannedShipments.byId[currentShipmentId].hasDownloadedPackingList = hasDownloaded;
    }
  });
};
export const inboundReducer = createReducer(inboundInitialState, reducers);