import produce from "immer";
import { CrossdockActionTypes } from "./CrossdockActionTypes";
export const CROSSDOCK_INITIAL_STATE = {};
export const crossdockReducers = {
  [CrossdockActionTypes.SET_CROSSDOCK_INBOUND_QUOTE]: (state, {
    quote
  }) => produce(state, draft => {
    draft.crossdock.quote = quote;
  }),
  [CrossdockActionTypes.SET_CROSSDOCK_INBOUND_SHIPMENT]: (state, {
    shipment
  }) => produce(state, draft => {
    draft.crossdock.shipment = shipment;
  })
};