import { getResponsiveStyles } from "../shared";
import { getNegativeMargin } from "./getNegativeMargin";
import { getPositiveMargin } from "./getPositiveMargin";
export const getVerticalStyles = (theme, center, gap) => `
  flex-direction: column;
  ${center ? `align-items: center;` : ``}
  ${getResponsiveStyles("margin-top", gap, getNegativeMargin(theme))}
  ${getResponsiveStyles("margin-bottom", gap, getNegativeMargin(theme))}
  > * {
    ${getResponsiveStyles("margin-top", gap, getPositiveMargin(theme))}
    ${getResponsiveStyles("margin-bottom", gap, getPositiveMargin(theme))}
  }
`;