export const BASE_SHIPPING_DAY_OFFSET = {
  weekdays: 3
};
export let DistributeSelectShippingOptions;
(function (DistributeSelectShippingOptions) {
  DistributeSelectShippingOptions["DIRECT"] = "DIRECT";
  DistributeSelectShippingOptions["FORWARDING"] = "FORWARDING";
})(DistributeSelectShippingOptions || (DistributeSelectShippingOptions = {}));
export const DistributeSelectShippingDays = {
  [DistributeSelectShippingOptions.DIRECT]: "1-3",
  [DistributeSelectShippingOptions.FORWARDING]: "3-11"
};