export let CreateShipmentFormActionTypes;
(function (CreateShipmentFormActionTypes) {
  CreateShipmentFormActionTypes["SET_ORIGIN_TYPE"] = "IPB_SET_ORIGIN_TYPE";
  CreateShipmentFormActionTypes["SET_ORIGIN"] = "IPB_SET_ORIGIN";
  CreateShipmentFormActionTypes["SET_DESTINATION"] = "IPB_SET_DESTINATION";
  CreateShipmentFormActionTypes["SET_IS_LIFTGATE_REQUIRED"] = "IPB_SET_IS_LIFTGATE_REQUIRED";
  CreateShipmentFormActionTypes["SET_DELIVERY_DATE"] = "IPB_SET_DELIVERY_DATE";
  CreateShipmentFormActionTypes["SET_UNIT_SYSTEM"] = "IPB_SET_UNIT_SYSTEM";
  CreateShipmentFormActionTypes["SET_SHIPMENT_TOTALS"] = "IPB_SET_SHIPMENT_TOTALS";
  CreateShipmentFormActionTypes["SET_HAS_DANGEROUS_GOODS"] = "IPB_SET_HAS_DANGEROUS_GOODS";
  CreateShipmentFormActionTypes["SET_LITHIUM_BATTERY_PACKAGING"] = "IPB_SET_LITHIUM_BATTERY_PACKAGING";
  CreateShipmentFormActionTypes["SET_DANGEROUS_GOODS"] = "IPB_SET_DANGEROUS_GOODS";
  CreateShipmentFormActionTypes["SET_CARGO_READY_DATE"] = "IPB_SET_CARGO_READY_DATE";
  CreateShipmentFormActionTypes["SET_DESTINATION_SELECT"] = "IPB_SET_DESTINATION_SELECT";
  CreateShipmentFormActionTypes["SET_AMAZON_DESTINATION_TYPE"] = "IPB_SET_AMAZON_DESTINATION_TYPE";
  CreateShipmentFormActionTypes["SET_AMAZON_PREP_TYPE"] = "IPB_SET_AMAZON_PREP_TYPE";
  CreateShipmentFormActionTypes["SET_AMAZON_ADDRESS"] = "IPB_SET_AMAZON_ADDRESS";
  CreateShipmentFormActionTypes["SET_FREIGHT_SERVICE"] = "IPB_SET_FREIGHT_SERVICE";
  CreateShipmentFormActionTypes["SET_DOMESTIC_DESTINATION_TYPE"] = "IPB_SET_DOMESTIC_DESTINATION_TYPE";
  CreateShipmentFormActionTypes["SET_DISTRIBUTION_TYPE"] = "SET_DISTRIBUTION_TYPE";
  CreateShipmentFormActionTypes["SET_SUPPLIER"] = "IPB_SET_SUPPLIER";
  CreateShipmentFormActionTypes["SET_ORIGIN_FACILITY_NAME"] = "IPB_SET_ORIGIN_FACILITY_NAME";
  CreateShipmentFormActionTypes["SET_DESTINATION_FACILITY_NAME"] = "IPB_SET_DESTINATION_FACILITY_NAME";
  CreateShipmentFormActionTypes["SET_DESTINATION_ADDRESS_FID"] = "IPB_SET_DESTINATION_ADDRESS_FID";
  CreateShipmentFormActionTypes["SET_IS_OWN_TRANSPORTATION"] = "IPB_SET_IS_OWN_TRANSPORTATION";
  CreateShipmentFormActionTypes["CLEAR_FORM"] = "IPB_CLEAR_FORM";
  CreateShipmentFormActionTypes["PREPOPULATE_FORM"] = "IPB_PREPOPULATE_FORM";
  CreateShipmentFormActionTypes["SET_HAS_CARGO_INSURANCE"] = "IPB_SET_HAS_CARGO_INSURANCE";
  CreateShipmentFormActionTypes["SET_INSURANCE_INFO"] = "IPB_SET_INSURANCE_INFO";
  CreateShipmentFormActionTypes["SET_BOOKING_PRODUCTS"] = "IPB_SET_BOOKING_PRODUCTS";
  CreateShipmentFormActionTypes["SET_AIR_SHIPMENT_CARGO_DETAILS"] = "SET_AIR_SHIPMENT_CARGO_DETAILS";
  CreateShipmentFormActionTypes["SET_DOMESTIC_ECOMM_STATE"] = "IPB_SET_DOMESTIC_ECOMM_STATE";
  CreateShipmentFormActionTypes["SET_DOMESTIC_STORAGE_STATE"] = "IPB_SET_DOMESTIC_STORAGE_STATE";
  CreateShipmentFormActionTypes["SET_HAS_ANNUAL_CUSTOMS_BOND"] = "IPB_SET_HAS_ANNUAL_CUSTOMS_BOND";
  CreateShipmentFormActionTypes["SET_FLEXPORT_CAPITAL_SELECTED"] = "IPB_SET_FLEXPORT_CAPITAL_SELECTED";
  CreateShipmentFormActionTypes["SET_IS_ORIGIN_PORT"] = "IPB_SET_IS_ORIGIN_PORT";
  CreateShipmentFormActionTypes["SET_CARGO_INPUT_TYPE"] = "IPB_SET_CARGO_INPUT_TYPE";
  CreateShipmentFormActionTypes["SET_IS_CARGO_MIXED_SKU"] = "IPB_SET_IS_CARGO_MIXED_SKU";
  CreateShipmentFormActionTypes["SET_DTC_PREP_OPTION"] = "IPB_SET_DTC_PREP_OPTION";
  CreateShipmentFormActionTypes["SET_SINGLE_SKU_PACKAGES"] = "IPB_SET_SINGLE_SKU_PACKAGES";
  CreateShipmentFormActionTypes["SET_MIXED_SKU_PACKAGES"] = "IPB_SET_MIXED_SKU_PACKAGES";
  CreateShipmentFormActionTypes["SET_RESERVE_STORAGE"] = "IPB_SET_RESERVE_STORAGE";
  CreateShipmentFormActionTypes["SET_CROSSDOCK_WAREHOUSE"] = "IPB_SET_CROSSDOCK_WAREHOUSE";
})(CreateShipmentFormActionTypes || (CreateShipmentFormActionTypes = {}));
export let CreateShipmentActionTypes;
(function (CreateShipmentActionTypes) {
  CreateShipmentActionTypes["SET_STEP_STACK"] = "IPB_SET_STEP_STACK";
  CreateShipmentActionTypes["SET_IS_IPB"] = "IPB_SET_IS_IPB";
  CreateShipmentActionTypes["SET_SHIPPING_PLAN_ID"] = "IPB_SET_SHIPPING_PLAN_ID";
  CreateShipmentActionTypes["SET_IS_SAVING"] = "IPB_SET_IS_SAVING";
  CreateShipmentActionTypes["SET_IS_LOADING"] = "IPB_SET_IS_LOADING";
  CreateShipmentActionTypes["SET_IS_RETURNING_USER"] = "IPB_SET_IS_RETURNING_USER";
  CreateShipmentActionTypes["CLEAR_META"] = "IPB_CLEAR_META";
  CreateShipmentActionTypes["HIDE_IPB_DEV_TOOLS"] = "IPB_HIDE_IPB_DEV_TOOLS";
  CreateShipmentActionTypes["SET_SAVED_FREIGHT_SERVICE_TITLE"] = "IPB_SET_SAVED_FREIGHT_SERVICE_TITLE";
})(CreateShipmentActionTypes || (CreateShipmentActionTypes = {}));
export let CreateShipmentQueryActionTypes;
(function (CreateShipmentQueryActionTypes) {
  CreateShipmentQueryActionTypes["SET_SUPPLIERS"] = "SET_SUPPLIERS";
})(CreateShipmentQueryActionTypes || (CreateShipmentQueryActionTypes = {}));