import { flowRight, fromPairs, map, toPairs } from "lodash/fp";
import { createReducer } from "common/ReduxUtils";
import { Enable, FastTagsActionTypes } from "tags/FastTagsActions";
import produce from "immer";
export const fastTagsInitialState = {
  activationDates: {},
  nextButtonEnabled: {},
  pageTransitionEnabled: {},
  ableToReactivate: {},
  shopConnectionStatus: {}
};
const reducers = {
  [FastTagsActionTypes.GET_STATUS]: (state, {
    program,
    programProps
  }) => {
    return {
      ...state,
      ...flowRight(fromPairs, map(([programPropType, programPropValue]) => [programPropType, {
        ...state[programPropType],
        [program]: programPropValue
      }]), toPairs)(programProps)
    };
  },
  [FastTagsActionTypes.ENABLE_NEXT]: (state, {
    enableType,
    propName
  }) => {
    const reducerProp = enableType === Enable.NextButton ? "nextButtonEnabled" : "pageTransitionEnabled";
    return {
      ...state,
      [reducerProp]: {
        ...state[reducerProp],
        [propName]: true
      }
    };
  },
  [FastTagsActionTypes.DISABLE_NEXT]: (state, {
    enableType,
    propName
  }) => {
    const reducerProp = enableType === Enable.NextButton ? "nextButtonEnabled" : "pageTransitionEnabled";
    return {
      ...state,
      [reducerProp]: {
        ...state[reducerProp],
        [propName]: false
      }
    };
  },
  [FastTagsActionTypes.UPDATE_LOCAL_TAGS]: () => {
    return fastTagsInitialState;
  },
  [FastTagsActionTypes.SHOP_CONNECTION_STATUS_RECEIVED]: (state, {
    fastTag,
    shops
  }) => produce(state, draftState => {
    draftState.shopConnectionStatus[fastTag] = shops;
  })
};
export const fastTagsReducer = createReducer(fastTagsInitialState, reducers);