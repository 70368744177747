export let ShipmentInstructionsStepId;
(function (ShipmentInstructionsStepId) {
  ShipmentInstructionsStepId["DOWNLOAD_PALLET_LABEL"] = "DOWNLOAD_PALLET_LABEL";
  ShipmentInstructionsStepId["SEND_SHIPMENT"] = "SEND_SHIPMENT";
  ShipmentInstructionsStepId["ADD_CONTAINER_ID"] = "ADD_CONTAINER_ID";
  ShipmentInstructionsStepId["ADVANCED_SCHEDULE_APPOINTMENT"] = "ADVANCED_SCHEDULE_APPOINTMENT";
  ShipmentInstructionsStepId["PACKING_INSTRUCTIONS"] = "PACKING_INSTRUCTIONS";
  ShipmentInstructionsStepId["PICKUP_DETAILS"] = "PICKUP_DETAILS";
  ShipmentInstructionsStepId["DOWNLOAD_BOL"] = "DOWNLOAD_BOL";
  ShipmentInstructionsStepId["SEND_AND_TRACK_SHIPMENT"] = "SEND_AND_TRACK_SHIPMENT";
})(ShipmentInstructionsStepId || (ShipmentInstructionsStepId = {}));