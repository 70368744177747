import { DEFAULT_CURRENCY } from "common/components/Currency";
import { truncateDecimal } from "common/utils/number";
import { useIntl } from "react-intl";
const DEFAULT_OPTIONS = {
  currency: DEFAULT_CURRENCY,
  maximumFractionDigits: 2,
  fallback: ""
};
export const useCurrencyValue = ({
  currency,
  maximumFractionDigits,
  fallback,
  truncate,
  truncateNonFractional
} = DEFAULT_OPTIONS) => {
  const {
    formatNumber
  } = useIntl();
  const currencyOptions = {
    style: "currency",
    currencyDisplay: "symbol",
    currency: currency ?? DEFAULT_OPTIONS.currency,
    maximumFractionDigits: maximumFractionDigits ?? DEFAULT_OPTIONS.maximumFractionDigits
  };
  return value => {
    const isNonFractional = value !== undefined && value % 1 === 0;
    const actualMaxFractionalDigits = truncateNonFractional && isNonFractional ? 0 : currencyOptions.maximumFractionDigits;
    const shouldTruncate = truncate ?? truncateNonFractional;
    const transformedVal = shouldTruncate ? truncateDecimal(value, actualMaxFractionalDigits) : value;
    return transformedVal !== undefined ? formatNumber(transformedVal, {
      ...currencyOptions,
      maximumFractionDigits: actualMaxFractionalDigits
    }) : fallback ?? DEFAULT_OPTIONS.fallback;
  };
};