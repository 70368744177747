import produce from "immer";
import { find } from "lodash/fp";
import { createSelector } from "reselect";
import { selectWholesaleCreate } from "./WholesaleCreateSelectors";
export const selectWholesaleSelectedProductsQty = createSelector(selectWholesaleCreate, wholesaleCreate => {
  const {
    selectedProducts,
    originStorageWarehouse
  } = wholesaleCreate;
  const selectedProductsQtyForGivenStorageWarehouse = produce(selectedProducts, draft => {
    Object.keys(draft).forEach(dsku => {
      const availableQtyForDskuInWarehouse = find(dskuQty => dskuQty.dsku === dsku, originStorageWarehouse?.dskuQuantities);
      const availableQty = availableQtyForDskuInWarehouse?.qty ?? 0;
      draft[dsku].storageOnHandQty = availableQty;
      draft[dsku].storageUnfilledOrderQty = 0;
      draft[dsku].storageUnavailableQty = 0;
    });
  });
  return Object.values(selectedProductsQtyForGivenStorageWarehouse);
});