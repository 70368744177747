import { toast } from "common/components/ui";
import { productClient } from "Clients";
import log, { logError, logStart } from "Logger";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { UpdateStorageProductCaseQtyError } from "../UpdateStorageProductCaseQtyErrorMessages";
export const updateStorageProductCaseQty = () => async (_dispatch, getState) => {
  const ctx = logStart({
    fn: "updateStorageProductCaseQty"
  });
  log.info(ctx, "updating storage product case qty if not defined");
  const {
    productDetails,
    selectedProducts
  } = getStorageInboundCreate(getState());
  try {
    await Promise.all(Object.values(selectedProducts).filter(product => {
      return !productDetails[product.dsku].caseQty;
    }).map(async ({
      caseQty,
      dsku
    }) => await productClient.update({
      dsku,
      caseQty
    })));
  } catch (err) {
    logError(ctx, err, "error updating storage product case qty");
    toast.error(UpdateStorageProductCaseQtyError, {
      toastId: "updateStorageProductCaseQtyError"
    });
  }
};