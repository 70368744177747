import { defineMessages } from "react-intl";
export const transferToEcomConfirmStepLabels = defineMessages({
  palletQuantity: {
    id: "transfers.create.steps.confirm.palletQuantity",
    defaultMessage: `{numOfPallets, plural,
      one {# pallet}
      other {# pallets}
    }`
  },
  boxQuantity: {
    id: "transfers.create.steps.confirm.boxQuantity",
    defaultMessage: `{numOfCases, plural,
      one {# box}
      other {# boxes}
    }`
  },
  unitQuantity: {
    id: "transfers.create.steps.confirm.unitQuantity",
    defaultMessage: `{numOfUnits, plural,
      one {# unit}
      other {# units}
    }`
  },
  feesTitle: {
    id: "transfers.create.steps.confirm.feesTitle",
    defaultMessage: "Fees"
  },
  quantityTitle: {
    id: "transfers.create.steps.confirm.quantityTitle",
    defaultMessage: "Quantity"
  },
  costTitle: {
    id: "transfers.create.steps.confirm.costTitle",
    defaultMessage: "Cost"
  },
  amountTitle: {
    id: "transfers.create.steps.confirm.amountTitle",
    defaultMessage: "Amount"
  },
  retrieval: {
    id: "transfers.create.steps.confirm.retrievalFees",
    defaultMessage: "Retrieval"
  },
  retrievalFeeTooltip: {
    id: "transfers.create.steps.confirm.retrievalFeeTooltip",
    defaultMessage: "This includes processing and handling the shipment"
  }
});