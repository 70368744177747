/* eslint-disable no-console */
import { isDebugServer } from "./Config";
// based in https://github.com/jefflombard/use-reducer-logger/blob/master/srhfl.js
// Helder: I'm writing(part-time) an integration library with Redux devtools simpler than reinspect
function logger(reducer) {
  return (state, action) => {
    const result = reducer(state, action); // Immutable State FTW \o/

    console.group("action " + JSON.stringify(action.type));
    console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
    console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
    console.log("%cNext State:", "color: #47B04B; font-weight: 700;", result);
    console.groupEnd();
    return result;
  };
}
export function createReducerHook(actionMap) {
  const reducerCallback = (state, action) => action && actionMap[action.type] ? actionMap[action.type](state, action) : state;
  return !isDebugServer ? reducerCallback : logger(reducerCallback);
}
export const createDispatcher = dispatch => action => (...args) => dispatch(action(...args));