import { isProdEnv } from "common/Config";
import log from "Logger";
const googleTagManagerDataLayer = window.dataLayer;
export function sendGTMData(payload) {
  if (isProdEnv && googleTagManagerDataLayer) {
    try {
      googleTagManagerDataLayer.push(payload);
    } catch (error) {
      log.error({
        fn: "sendGTMData"
      }, error);
    }
  }
}
export const track = (event, isAdmin) => {
  if (process.env.ENV !== "production" || isAdmin) {
    return;
  }

  // FB Pixel Tracking
  try {
    const windowFb = window.fbq;
    if (windowFb) {
      windowFb("trackCustom", event);
    }
  } catch (error) {
    log.error({
      fn: "track"
    }, error);
  }
  // GA/GTM Event Tracking
  sendGTMData({
    event,
    category: event
  });
};