import { storageClient } from "common/clients/instances";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { logError, logStart } from "Logger";
import { setSelectedProductsCaseDimensions } from "../../dimensions/StorageCaseDimensionActions";
import { CaseDimensionLoaderId } from "../../dimensions/CaseDimensionLoaderId";
import { DimensionField } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionField";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { chunk, isEmpty } from "lodash/fp";
export const getStorageProductDimensions = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "getStorageProductDimensions"
  });
  const {
    selectedProductsCaseDimensions
  } = getStorageInboundCreate(getState());
  try {
    dispatch(addLoader(CaseDimensionLoaderId.productCaseDimensions));
    const selectedProductsWithoutDims = [];
    const selectedProductsCaseDims = {};
    let productCaseDimensionsMap = {};
    Object.keys(selectedProductsCaseDimensions).forEach(dsku => {
      if (isEmpty(selectedProductsCaseDimensions[dsku]) || Object.entries(selectedProductsCaseDimensions[dsku]).some(([key, value]) => DimensionField[key.toUpperCase()] && !value)) {
        selectedProductsWithoutDims.push(dsku);
      } else {
        selectedProductsCaseDims[dsku] = selectedProductsCaseDimensions[dsku];
      }
    });
    if (selectedProductsWithoutDims.length > 0) {
      let productsCaseDimensions = [];
      const CHUNK_SIZE = 50;
      for (const dskusChunk of chunk(CHUNK_SIZE, selectedProductsWithoutDims)) {
        try {
          const newChunkCaseDimensions = (await storageClient.getCaseConfig(dskusChunk))?.value;
          if (newChunkCaseDimensions) {
            productsCaseDimensions = [...productsCaseDimensions, ...newChunkCaseDimensions];
          }
        } catch (err) {
          logError({
            ...ctx,
            err
          }, "error fetching product dimensions");
        }
      }
      productCaseDimensionsMap = productsCaseDimensions?.reduce((productCaseDimsAccumulator, productsCaseDimensionValue) => {
        productCaseDimsAccumulator[productsCaseDimensionValue.dsku] = {
          height: productsCaseDimensionValue.caseHeight,
          weight: productsCaseDimensionValue.caseWeight,
          length: productsCaseDimensionValue.caseLength,
          width: productsCaseDimensionValue.caseWidth,
          lengthUnit: productsCaseDimensionValue.lengthUnit,
          weightUnit: productsCaseDimensionValue.weightUnit
        };
        return productCaseDimsAccumulator;
      }, {});
    }
    productCaseDimensionsMap && dispatch(setSelectedProductsCaseDimensions({
      ...selectedProductsCaseDims,
      ...productCaseDimensionsMap
    }));
  } catch (err) {
    logError({
      ...ctx,
      err
    }, "error fetching product dimensions");
  } finally {
    dispatch(clearLoader(CaseDimensionLoaderId.productCaseDimensions));
  }
};