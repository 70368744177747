import { NavBarProgressActionTypes } from "common/components/NavBarProgress/NavBarProgressActions";
import { createReducer } from "common/ReduxUtils";

// page type represents the section of the sites (inbounds, egd), page is the specific page

export const navBarProgressInitialState = {
  currentSteps: {}
};
const reducers = {
  [NavBarProgressActionTypes.UPDATE_CURRENT_PAGE]: (state, action) => ({
    ...state,
    currentSteps: {
      ...state.currentSteps,
      [action.pageType]: action.currentStep
    }
  })
};
export const navBarProgressReducer = createReducer(navBarProgressInitialState, reducers);