import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { mustBeDefined } from "common/utils/mustBeDefined";
export class DashboardClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.DASHBOARD_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getFulfilledOrders(sellerId, startDate, endDate, timezone) {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/orders/fulfilled-orders`,
        params: {
          startDate,
          endDate,
          timezone
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getServiceLevelBreakdownV2(sellerId, startDate, endDate, timezone) {
    try {
      return await this.apiClient.get({
        url: `/v2/seller/${sellerId}/orders/service-level-breakdown`,
        params: {
          startDate,
          endDate,
          timezone
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getCurrentOrdersV3(sellerId, timezone) {
    try {
      return await this.apiClient.get({
        url: `/v3/seller/${sellerId}/orders/current`,
        params: {
          timezone
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getFulfillmentMetricsV2(sellerId, startDate, endDate, timezone) {
    try {
      return await this.apiClient.get({
        url: `/v2/seller/${sellerId}/orders/fulfillment-metrics`,
        params: {
          startDate,
          endDate,
          timezone
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getFastTagRecommendations(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/fast-tags/recommendations`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getSkuReplenishment(sellerId, page, maxDaysLeft, sortMethod, maxRegions, excludeSkusWithInbounds) {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/inventory/replenishment`,
        params: {
          page,
          maxDaysLeft,
          sortMethod,
          maxRegions,
          excludeSkusWithInbounds
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getUnitsOverview(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/units/overview`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getSkuReplenishmentCsv(sellerId, skuReplenishmentFilters) {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/inventory/replenishment/csv`,
        params: skuReplenishmentFilters,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getShopifyFastTagPerformance(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/fast-tags/performance/shopify`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getReturnOrdersV3(sellerId, startDate, endDate, timezone) {
    try {
      return await this.apiClient.get({
        url: `/v3/seller/${sellerId}/returns/daily-breakdown`,
        params: {
          startDate,
          endDate,
          timezone
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
  async getReturnItemStatusBreakdown(sellerId, startDate, endDate, timezone) {
    try {
      return await this.apiClient.get({
        url: `/v1/seller/${sellerId}/returns/item-status-breakdown`,
        params: {
          startDate,
          endDate,
          timezone
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
}