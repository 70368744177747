export let ElevioArticleId;
(function (ElevioArticleId) {
  ElevioArticleId["Acenda"] = "403";
  ElevioArticleId["AddNewSkus"] = "3";
  ElevioArticleId["Amazon"] = "667";
  ElevioArticleId["AmazonBoxLabeling"] = "788";
  ElevioArticleId["AmazonDirect"] = "791";
  ElevioArticleId["AmazonFBA"] = "790";
  ElevioArticleId["AmazonFBAOrderErrors"] = "902";
  ElevioArticleId["AmazonFBM"] = "561";
  ElevioArticleId["AmazonFBM2"] = "667";
  ElevioArticleId["AmazonShipping"] = "450";
  ElevioArticleId["AmazonIntegratedTransfers"] = "701";
  ElevioArticleId["BatteryRequirements"] = "501";
  ElevioArticleId["BigCommerce"] = "443";
  ElevioArticleId["BoxContentsLabelingReqs"] = "557";
  ElevioArticleId["BundleFAQ"] = "632";
  ElevioArticleId["BundleHowToSetup"] = "629";
  ElevioArticleId["BundleWhatIs"] = "630";
  ElevioArticleId["CasePack"] = "97";
  ElevioArticleId["CargoInsuranceAnnualPolicy"] = "836";
  ElevioArticleId["CargoInsuranceOverview"] = "806";
  ElevioArticleId["CargoTotalInsuranceValue"] = "799";
  ElevioArticleId["CargoTotalValue"] = "802";
  ElevioArticleId["ChannelAdvisor"] = "108";
  ElevioArticleId["ClaimsSubmitted"] = "573";
  ElevioArticleId["CommercialInvoicePackingList"] = "786";
  ElevioArticleId["CustomsBond"] = "781";
  ElevioArticleId["CustomsClearanceService"] = "784";
  ElevioArticleId["CustomsContact"] = "785";
  ElevioArticleId["CustomsDuties"] = "794";
  ElevioArticleId["DamagedUnits"] = "654";
  ElevioArticleId["DangerousGoods"] = "801";
  ElevioArticleId["DeliverrAPI"] = "648";
  ElevioArticleId["DeliverrDistribution"] = "154";
  ElevioArticleId["DeliverrFees"] = "127";
  ElevioArticleId["DeliverrFreight"] = "360";
  ElevioArticleId["DimensionalRequirements"] = "59";
  ElevioArticleId["DoubleChannelConnection"] = "475";
  ElevioArticleId["Ebay"] = "401";
  ElevioArticleId["Ecomdash"] = "163";
  ElevioArticleId["EgdRateTables"] = "86";
  ElevioArticleId["EIN"] = "796";
  ElevioArticleId["Etsy"] = "572";
  ElevioArticleId["Exceptions"] = "793";
  ElevioArticleId["Facebook"] = "511";
  ElevioArticleId["FastShippingTags"] = "85";
  ElevioArticleId["FastTagCoverage"] = "150";
  ElevioArticleId["FlexportPlus"] = "841";
  ElevioArticleId["Flieber"] = "570";
  ElevioArticleId["FreeInbound"] = "330";
  ElevioArticleId["FreeLTLWeek"] = "357";
  ElevioArticleId["FreightFullfilmentInbound"] = "825";
  ElevioArticleId["FulfillmentFees"] = "673";
  ElevioArticleId["GeekSeller"] = "124";
  ElevioArticleId["GeneralRateIncrease"] = "922";
  ElevioArticleId["GoogleShopping"] = "489";
  ElevioArticleId["GoogleSupportedSalesChannels"] = "402";
  ElevioArticleId["GoogleTermConditions"] = "331";
  ElevioArticleId["HowBarcodeProducts"] = "33";
  ElevioArticleId["HowDoesShipmentForwardingWork"] = "344";
  ElevioArticleId["InboundingToDeliverr"] = "119";
  ElevioArticleId["InboundPerformanceCategory"] = "472";
  ElevioArticleId["InboundsImportProducts"] = "599";
  ElevioArticleId["InboundsPackages"] = "514";
  ElevioArticleId["InboundsShipments"] = "513";
  ElevioArticleId["InternationalOriginPickup"] = "782";
  ElevioArticleId["InternationalShipping"] = "560";
  ElevioArticleId["InventoryEcommerceMonthlyReconciliation"] = "750";
  ElevioArticleId["InventoryEcommerceMonthlyReconciliationUsingLedger"] = "959";
  ElevioArticleId["InventoryEcommerceTransactionHistory"] = "751";
  ElevioArticleId["InventoryLevels"] = "512";
  ElevioArticleId["InventoryLevelsUsingLedger"] = "512";
  ElevioArticleId["InventoryLongTermStorage"] = "521";
  ElevioArticleId["InventoryNewReportsAvailable"] = "960";
  ElevioArticleId["Mirakl"] = "645";
  ElevioArticleId["InventoryMonthlyReconciliation"] = "510";
  ElevioArticleId["InventoryReconciliation"] = "562";
  ElevioArticleId["linnworks"] = "365";
  ElevioArticleId["LiquidsRequirements"] = "128";
  ElevioArticleId["ListingMirror"] = "183";
  ElevioArticleId["ListOfSupportedOriginCountries"] = "891";
  ElevioArticleId["LotTracking"] = "647";
  ElevioArticleId["InventoryLotTrackingFEFOAllProducts"] = "883";
  ElevioArticleId["InventoryLotTrackingFEFOAllProductsUsingLedger"] = "883";
  ElevioArticleId["InventoryLotTrackingFEFOSingleProduct"] = "884";
  ElevioArticleId["InventoryLotTrackingFEFOSingleProductUsingLedger"] = "884";
  ElevioArticleId["InventoryDetailReserveStorageUsingLedger"] = "884";
  ElevioArticleId["MobilityeCommerce"] = "497";
  ElevioArticleId["MultiFactorAuthentication"] = "619";
  ElevioArticleId["NCFees"] = "473";
  ElevioArticleId["NewProduct"] = "547";
  ElevioArticleId["NonCompliance"] = "417";
  ElevioArticleId["NonCompliantProduct"] = "84";
  ElevioArticleId["OrderIngestion"] = "543";
  ElevioArticleId["OrderNotInDeliverr"] = "504";
  ElevioArticleId["OrdersAll"] = "531";
  ElevioArticleId["OrdersShipments"] = "532";
  ElevioArticleId["PackingInstructions"] = "461";
  ElevioArticleId["Pallets"] = "800";
  ElevioArticleId["ParcelCreateBulkCSV"] = "921";
  ElevioArticleId["ParcelTracking"] = "935";
  ElevioArticleId["Pipe17"] = "419";
  ElevioArticleId["PreparingInstructions"] = "468";
  ElevioArticleId["PrepBcl"] = "699";
  ElevioArticleId["PrepKitting"] = "753";
  ElevioArticleId["PrepFLCUnloading"] = "911";
  ElevioArticleId["PrepServicesOverview"] = "553";
  ElevioArticleId["PrepPricingAndBilling"] = "747";
  ElevioArticleId["ProductCategories"] = "551";
  ElevioArticleId["ProductHandling"] = "498";
  ElevioArticleId["ProductPackagingOverView"] = "541";
  ElevioArticleId["ProductsAllSkusWithAlias"] = "520";
  ElevioArticleId["ProductsAvailableSkusWithAlias"] = "519";
  ElevioArticleId["ProductsAvailableSkusWithNoAlias"] = "518";
  ElevioArticleId["ProductsSKUsWithLinkedAliases"] = "590";
  ElevioArticleId["ReportingHowTo"] = "137";
  ElevioArticleId["RateValidityPeriod"] = "845";
  ElevioArticleId["ReserveStorage"] = "621";
  ElevioArticleId["ReserveStorageOrdersAndTransfers"] = "756";
  ElevioArticleId["ReserveStorageOrdersAndTransfersWithSkus"] = "952";
  ElevioArticleId["RestrictedProducts"] = "14";
  ElevioArticleId["RestrictedProductsFreight"] = "843";
  ElevioArticleId["ReturnsAll"] = "594";
  ElevioArticleId["ReturnsGetStarted"] = "656";
  ElevioArticleId["ReturnsCreateOrder"] = "598";
  ElevioArticleId["ReturnsUpdateRMTAddress"] = "657";
  ElevioArticleId["ReturnsAllBySKU"] = "593";
  ElevioArticleId["ReturnsClosedBoxSopDetails"] = "676";
  ElevioArticleId["ReturnsFAQ"] = "604";
  ElevioArticleId["ReturnsPricingPolicy"] = "635";
  ElevioArticleId["ReturnsRmtConnectLoop"] = "660";
  ElevioArticleId["ReturnsRmtConnectReturnly"] = "664";
  ElevioArticleId["ReturnsRmtConnectAmazon"] = "665";
  ElevioArticleId["ReturnsRmtConnectReturnGo"] = "666";
  ElevioArticleId["SafetyStock"] = "502";
  ElevioArticleId["SalesChannelsWeSupport"] = "63";
  ElevioArticleId["Sellbrite"] = "64";
  ElevioArticleId["SellerActive"] = "121";
  ElevioArticleId["SellerChamp"] = "421";
  ElevioArticleId["SellerCloud"] = "145";
  ElevioArticleId["ShipFromMistakes"] = "345";
  ElevioArticleId["ShippingFreight"] = "38";
  ElevioArticleId["ShippingPlanReconciliation"] = "634";
  ElevioArticleId["Shopify"] = "89";
  ElevioArticleId["ShopifyAutomaticFulfillment"] = "102";
  ElevioArticleId["ShopifyConfigurations"] = "492";
  ElevioArticleId["ShopifyMultipleLocations"] = "493";
  ElevioArticleId["ShortShippedBoxes"] = "555";
  ElevioArticleId["ShouldIWalmartDirect"] = "133";
  ElevioArticleId["SimplePrepOld"] = "708";
  ElevioArticleId["SimplePrep"] = "910";
  ElevioArticleId["SigningPowerOfAttorney"] = "779";
  ElevioArticleId["Skubana"] = "153";
  ElevioArticleId["Skupreme"] = "571";
  ElevioArticleId["SKUSpecialChars"] = "537";
  ElevioArticleId["StorageFees"] = "88";
  ElevioArticleId["StorageJIT"] = "678";
  ElevioArticleId["StorageInboundsImportProducts"] = "696";
  ElevioArticleId["StoreAutomator"] = "595";
  ElevioArticleId["TransfersAll"] = "679";
  ElevioArticleId["TransferCreateAmazonPreRequirements"] = "670";
  ElevioArticleId["TransferCreateShippingDeliverrPartner"] = "670";
  ElevioArticleId["TransferCreateShippingYourCarrier"] = "670";
  ElevioArticleId["TransferFromReserveStorage"] = "733";
  ElevioArticleId["StorageTransportationToCrossdock"] = "694";
  ElevioArticleId["UnavailableForSale"] = "618";
  ElevioArticleId["UserContactAreas"] = "658";
  ElevioArticleId["UserManagement"] = "476";
  ElevioArticleId["WalmartDirect"] = "132";
  ElevioArticleId["WalmartDirectHow"] = "129";
  ElevioArticleId["WalmartKeys"] = "95";
  ElevioArticleId["WalmartMultinodeFaq"] = "335";
  ElevioArticleId["WfsReplenishmentSettings"] = "896";
  ElevioArticleId["WhichItemsWalmart2Day"] = "92";
  ElevioArticleId["Wish"] = "361";
  ElevioArticleId["WishExpressFAQ"] = "362";
  ElevioArticleId["WooCommerce"] = "525";
  ElevioArticleId["Zentail"] = "116";
  ElevioArticleId["BadgeAvailability"] = "596";
  ElevioArticleId["FastTagBoost"] = "592";
  ElevioArticleId["BillingCredits"] = "681";
  ElevioArticleId["FbaReferenceId"] = "727";
  ElevioArticleId["FbaIntegratedShipments"] = "701";
  ElevioArticleId["ReplenishmentTransfersWholesale"] = "734";
  ElevioArticleId["ReplenishmentNonCompliance"] = "950";
  ElevioArticleId["ReserveStoragePalletInventory"] = "741";
  ElevioArticleId["ReserveStorageInboundPackSelection"] = "755";
  ElevioArticleId["ShippingRestrictionsForFbaInbounds"] = "760";
  ElevioArticleId["ReserveStorageNonCompliance"] = "761";
  ElevioArticleId["ReplenishmentOverview"] = "736";
  ElevioArticleId["ArticleOfIncoporation"] = "897";
  ElevioArticleId["CreditCardFees"] = "919";
  ElevioArticleId["SupplyChainFinancing"] = "885";
})(ElevioArticleId || (ElevioArticleId = {}));
export let ElevioModuleId;
(function (ElevioModuleId) {
  ElevioModuleId["SupportTicket"] = "8";
})(ElevioModuleId || (ElevioModuleId = {}));