import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { isAddressValid } from "freight/common/utils/validateAddressDetails";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";
import produce from "immer";
import { PickupDetailsActionTypes } from "../actions/PickupDetailsActionTypes";
import { pickupDetailsInitialState } from "./freightPickupDetailsInitialState";
const reducer = {
  ...handleSimpleReducerUpdates([PickupDetailsActionTypes.SET_PICKUP_APPOINTMENT_REQUIRED, PickupDetailsActionTypes.SET_CARGO_READY_DATE, PickupDetailsActionTypes.SET_PICKUP_REFERENCE_NUMBER, PickupDetailsActionTypes.SET_CUSTOMER_REFERENCE_NUMBER, PickupDetailsActionTypes.SET_PICKUP_FACILITY_OPERATING_HOURS, PickupDetailsActionTypes.SET_PICKUP_INSTRUCTIONS, PickupDetailsActionTypes.SET_PICKUP_CONTACT]),
  [PickupDetailsActionTypes.SET_PICKUP_ADDRESS]: (state, {
    address
  }) => produce(state, draft => {
    const {
      name,
      ...pickupAddress
    } = address;
    draft.address = {
      ...pickupAddress,
      name: draft.address.name
    };
  }),
  [PickupDetailsActionTypes.SET_PICKUP_COMPANY_NAME]: (state, {
    companyName
  }) => produce(state, draft => {
    draft.address = {
      ...draft.address,
      name: companyName
    };
  }),
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => pickupDetailsInitialState,
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => pickupDetailsInitialState,
  [FreightBookingActionTypes.SET_FREIGHT_BOOKING_QUOTE]: (state, {
    quote
  }) => produce(state, draft => {
    if (quote.from?.address && isAddressValid(quote.from.address)) {
      draft.address = quote.from.address;
    }
  })
};
export const pickupDetailsReducer = createReducer(pickupDetailsInitialState, reducer);