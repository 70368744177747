import log from "Logger";
import { conditionalPricingRegistry } from "./utils/ConditionalPricing";
import { verifyProductProps } from "./utils/product/verifyProductProps";
import { getDestinationParams } from "./utils/product/getDestinationParams";
import { destinationByService } from "./utils/serviceLevels/destinationByService";
import { pricerClient } from "common/clients/instances";
import { verifyMultipleProductProps } from "./utils/product/verifyMultipleProductProps";
import { isInternationalServiceLevel } from "./utils/serviceLevels/serviceLevels";
export const getEstimatedCosts = async ({
  items,
  showNewPrices = false,
  address,
  serviceLevel,
  countryCode,
  sellerId,
  orderShipTime
}) => {
  if (isInternationalServiceLevel(serviceLevel) && !countryCode) {
    return undefined;
  }
  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const itemSpec = verifyProductProps(items);
  if (!itemSpec || itemSpec.length === 0) {
    return undefined;
  }
  try {
    const priceRes = await pricerClient.getItemizedPriceForOrderPerUnitDetail({
      items: itemSpec,
      serviceLevel,
      referenceDate: orderShipTime ?? referenceDate ?? new Date(),
      destination: address ? getDestinationParams(address) : destinationByService(serviceLevel),
      isRemoval: false,
      countryCode: isInternationalServiceLevel(serviceLevel) ? countryCode : undefined,
      sellerId
    });

    // combine prices to avoid regressions with FE
    const priceResConverted = priceRes.orderItems.reduce((acc, curr) => {
      return {
        basePrice: acc.basePrice + curr.basePrice,
        surcharges: [...acc.surcharges, ...curr.surcharges],
        totalPrice: acc.totalPrice + curr.totalPrice
      };
    }, {
      basePrice: 0,
      surcharges: [],
      totalPrice: 0
    }) ?? [];
    return priceResConverted;
  } catch (err) {
    log.warn({
      err,
      itemSpec
    }, "error getting unit price");
    return;
  }
};
export const getMultipleEstimatedCosts = async ({
  items,
  showNewPrices = false,
  address,
  serviceLevel,
  countryCode,
  sellerId
}) => {
  if (isInternationalServiceLevel(serviceLevel) && !countryCode) {
    return undefined;
  }
  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const priceItems = verifyMultipleProductProps(items);
  const validPriceItems = priceItems.filter(item => item !== undefined);
  try {
    const shouldMakeRequest = validPriceItems.length > 0;
    const priceRes = shouldMakeRequest ? await pricerClient.getItemizedPriceForMultipleItems({
      items: validPriceItems,
      serviceLevel,
      referenceDate: referenceDate ?? new Date(),
      destination: address ? getDestinationParams(address) : destinationByService(serviceLevel),
      isRemoval: false,
      countryCode: isInternationalServiceLevel(serviceLevel) ? countryCode : undefined,
      sellerId
    }) : [];
    const dskuToItemizedPrice = priceRes.reduce((acc, item, i) => {
      acc[validPriceItems[i].dsku] = item;
      return acc;
    }, {});
    return priceItems.map(item => item !== undefined ? dskuToItemizedPrice[item.dsku] : null);
  } catch (err) {
    log.warn({
      err,
      priceItems,
      validPriceItems
    }, "error getting price for multiple items");
    return;
  }
};
export const getMultipleBundleEstimatedCosts = async ({
  items,
  showNewPrices = false,
  address,
  serviceLevel,
  countryCode,
  sellerId
}) => {
  if (isInternationalServiceLevel(serviceLevel) && !countryCode) {
    return undefined;
  }
  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const itemSpec = [];
  const nullIndices = [];
  items.forEach((item, index) => {
    const verifiedItem = verifyProductProps(item);
    if (verifiedItem) {
      itemSpec.push(verifiedItem);
    } else {
      nullIndices.push(index);
    }
  });
  try {
    const priceRes = await pricerClient.getItemizedPriceForMultipleOrdersPerUnitDetail({
      orders: itemSpec,
      serviceLevel,
      referenceDate: referenceDate ?? new Date(),
      destination: address ? getDestinationParams(address) : destinationByService(serviceLevel),
      isRemoval: false,
      countryCode: isInternationalServiceLevel(serviceLevel) ? countryCode : undefined,
      sellerId
    });
    const prices = priceRes;
    nullIndices.forEach(index => {
      prices?.splice(index, 0, null);
    });
    return prices;
  } catch (err) {
    log.warn({
      err,
      itemSpec
    }, "error getting price for bundle list");
    return;
  }
};
export const getRemovalCosts = async ({
  priceItems,
  address,
  removalType,
  showNewPrices,
  sellerId
}) => {
  const destination = getDestinationParams(address);
  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const itemSpec = verifyProductProps(priceItems);
  if (!itemSpec) {
    return undefined;
  }
  try {
    const removalCost = await pricerClient.getTotalRemovalPrice({
      priceItems: itemSpec,
      type: removalType,
      referenceDate: referenceDate ?? new Date(),
      destination,
      sellerId
    });
    return {
      basePrice: removalCost,
      surcharges: [],
      totalPrice: removalCost
    };
  } catch (err) {
    log.warn({
      err
    }, "error getting removal price");
    return;
  }
};
const getReferenceDateForEstimate = showNewPrices => {
  // if the seller has conditional pricing, we should use the comparison date to fetch the "old" rates
  if (conditionalPricingRegistry.hasActivePricing()) {
    return showNewPrices ? conditionalPricingRegistry.getActivePricingReferenceDate() : conditionalPricingRegistry.getActivePricingComparisonDate();
  }
  return new Date();
};