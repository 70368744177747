import { useSelector } from "react-redux";
import { useShipmentProblems } from "../../useShipmentProblems";
import { useFetchProductFromChildOrParentDsku } from "storage/hooks/useFetchProductFromChildOrParentDsku";
import { getStorageInboundDetail } from "storage/inbounds/detail/store";
export const useShipmentProblemUnexpectedSkuBlade = shipmentProblem => {
  const {
    hideShipmentProblemBlade,
    setSelectedProblem,
    isShipmentProblemBladeOpen
  } = useShipmentProblems();
  const {
    mappedSkus
  } = shipmentProblem;
  const {
    storageInboundOrder
  } = useSelector(getStorageInboundDetail);
  const {
    shippingPlanId,
    shippingPlanName
  } = storageInboundOrder;
  const {
    value: product
  } = useFetchProductFromChildOrParentDsku({
    dsku: mappedSkus[0]?.dsku
  });
  const skuPair = product && {
    dsku: product.dsku,
    msku: product.msku,
    productName: product.name
  };
  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };
  return {
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    isShipmentProblemBladeOpen,
    skuPair
  };
};