import { useEffect, useMemo } from "react";
import { useAsync } from "react-use";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { inboundClient } from "Clients";
import log from "Logger";
export const useGetAllRelatedProductsAndCasePacks = ({
  dsku,
  asnId
}) => {
  const {
    formatMessage
  } = useIntl();
  const ctx = useMemo(() => ({
    fn: "useGetAllRelatedProductsAndCasePacks",
    dsku
  }), [dsku]);
  const {
    value,
    loading: isLoading,
    error
  } = useAsync(async () => {
    if (!dsku || !asnId) {
      return;
    }
    log.info({
      ...ctx
    }, "getting related products and casePacks");
    return await inboundClient.getAllRelatedProductsAndCasePacks(asnId, dsku);
  }, [asnId, dsku]);
  useEffect(() => {
    if (error) {
      log.warn({
        ...ctx,
        error
      }, "error fetching related products and casePacks");
      toast.error(formatMessage({
        id: "inbound.fetchError",
        defaultMessage: "There was an issue fetching related products and casePacks"
      }));
    }
  }, [ctx, error, formatMessage]);
  return {
    value,
    isLoading,
    error
  };
};