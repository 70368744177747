export let ShopifyActionTypes;
(function (ShopifyActionTypes) {
  ShopifyActionTypes["UPDATE_STORE_NAME"] = "UPDATE_STORE_NAME";
  ShopifyActionTypes["UPDATE_USE_STORE_CREDIT"] = "UPDATE_USE_STORE_CREDIT";
  ShopifyActionTypes["UPDATE_BADGE_PLACEMENT"] = "UPDATE_BADGE_PLACEMENT";
  ShopifyActionTypes["UPDATE_IS_RECONFIGURING_SHOPIFY"] = "UPDATE_IS_RECONFIGURING_SHOPIFY";
  ShopifyActionTypes["GET_SHOPIFY_EXAMPLE_LINK"] = "GET_SHOPIFY_EXAMPLE_LINK";
  ShopifyActionTypes["APP_INSTALLED"] = "APP_INSTALLED";
  ShopifyActionTypes["UPDATE_CART_MINIMUM"] = "UPDATE_CART_MINIMUM";
  ShopifyActionTypes["STANDARD_BADGE_OPTION_SELECTED"] = "STANDARD_BADGE_OPTION_SELECTED";
  ShopifyActionTypes["SELLER_CONNECTION_STATUS_RECEIVED"] = "SELLER_CONNECTION_STATUS_RECEIVED";
  ShopifyActionTypes["SHOPIFY_SHOP_DEACTIVATED"] = "SHOPIFY_SHOP_DEACTIVATED";
  ShopifyActionTypes["SHOPIFY_SHOP_RECEIVED"] = "SHOPIFY_SHOP_RECEIVED";
  ShopifyActionTypes["SHOPIFY_SHOP_CONFIG_RECEIVED"] = "SHOPIFY_SHOP_CONFIG_RECEIVED";
  ShopifyActionTypes["SHOPIFY_CONFIG_LOADER"] = "SHOPIFY_CONFIG_LOADER";
  ShopifyActionTypes["SHOPIFY_SHOP_APP_EMBED_BLOCK_ENABLED_RECEIVED"] = "SHOPIFY_SHOP_APP_EMBED_BLOCK_ENABLED_RECEIVED";
  ShopifyActionTypes["SHOPIFY_SHOP_APP_BLOCK_TEMPLATES_RECEIVED"] = "SHOPIFY_SHOP_APP_BLOCK_TEMPLATES_RECEIVED";
  ShopifyActionTypes["SHOPIFY_SHIPPING_SETTINGS_CONFIG_LOADED"] = "SHOPIFY_SHIPPING_SETTINGS_CONFIG_LOADED";
  ShopifyActionTypes["SHOPIFY_SHIPPING_SETTINGS_CONFIG_UPDATED"] = "SHOPIFY_SHIPPING_SETTINGS_CONFIG_UPDATED";
  ShopifyActionTypes["SHOPIFY_SHIPPING_SETTINGS_CONFIG_RESET"] = "SHOPIFY_SHIPPING_SETTINGS_CONFIG_RESET";
  ShopifyActionTypes["SET_EXPERIMENT_ENROLLMENT"] = "SET_EXPERIMENT_ENROLLMENT";
})(ShopifyActionTypes || (ShopifyActionTypes = {}));