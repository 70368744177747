import { organizationClient } from "common/clients/instances";
import { createNewOnboardingStage } from "common/organization/OnboardingStage";
import log, { logError, logStart } from "Logger";
export let OrganizationActionTypes;
(function (OrganizationActionTypes) {
  OrganizationActionTypes["UPDATE_ORGANIZATION"] = "UPDATE_ORGANIZATION";
  OrganizationActionTypes["ADD_USER_TO_ORGANIZATION"] = "ADD_USER_TO_ORGANIZATION";
})(OrganizationActionTypes || (OrganizationActionTypes = {}));
export const updateLocalOrganization = organization => {
  return {
    type: OrganizationActionTypes.UPDATE_ORGANIZATION,
    ...{
      organizationId: organization.sellerId,
      ...organization
    }
  };
};
export const setOrganization = businessName => async dispatch => {
  dispatch({
    type: OrganizationActionTypes.UPDATE_ORGANIZATION,
    businessName
  });
};
export const updateOrganization = sellerId => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "getOrganization"
  });
  const {
    organization: {
      organizationId
    }
  } = getState();
  const orgSellerId = sellerId ?? organizationId;
  log.info({
    ...ctx,
    organizationId: orgSellerId,
    sellerId: orgSellerId
  }, "updating organization");
  try {
    const organization = await organizationClient.getOrganizationWithUsers(orgSellerId);
    dispatch(updateLocalOrganization(organization?.data));
  } catch (err) {
    logError({
      ...ctx,
      organizationId: orgSellerId,
      sellerId: orgSellerId
    }, err, "error getting organization");
  }
};
export const updateOrganizationClaims = ({
  isCatalogImported,
  onboardingStage,
  dealStage,
  hasChargebeeAccount
}, {
  rethrowErrors,
  noOrgUpdate
} = {}) => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "updateOrganizationClaims"
  });
  const {
    organization: {
      organizationId
    }
  } = getState();
  try {
    log.info({
      ...ctx,
      isCatalogImported,
      onboardingStage
    }, "update organization claims");
    await organizationClient.updateSellerClaims(organizationId, isCatalogImported, onboardingStage, dealStage, hasChargebeeAccount);
    if (!noOrgUpdate) {
      await dispatch(updateOrganization());
    }
  } catch (err) {
    logError({
      ...ctx,
      isCatalogImported,
      onboardingStage
    }, err, "error updating organization attributes claims");
    if (rethrowErrors) {
      throw err;
    }
  }
};
export const updateOrganizationInfo = ({
  businessName,
  publicEmail
}) => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "updateOrganizationInfo"
  });
  const {
    organization: {
      organizationId
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "update organization info");
    await organizationClient.updateOrganization(organizationId, {
      businessName,
      publicEmail
    });
  } catch (err) {
    logError({
      ...ctx
    }, err, "error updating organization info");
    throw err;
  }
};
export const syncOnboardingStage = stage => async (dispatch, getState) => {
  const newStage = createNewOnboardingStage(stage, getState);
  dispatch(updateOrganizationClaims({
    onboardingStage: newStage
  }));
};