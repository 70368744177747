export let IndeterminateCheckboxState;
(function (IndeterminateCheckboxState) {
  IndeterminateCheckboxState["ALL_SELECTED"] = "ALL_SELECTED";
  IndeterminateCheckboxState["NONE_SELECTED"] = "NONE_SELECTED";
  IndeterminateCheckboxState["PARTIALLY_SELECTED"] = "PARTIALLY_SELECTED";
})(IndeterminateCheckboxState || (IndeterminateCheckboxState = {}));
export const getNextState = currentState => {
  if (currentState !== IndeterminateCheckboxState.ALL_SELECTED) {
    return IndeterminateCheckboxState.ALL_SELECTED;
  }
  return IndeterminateCheckboxState.NONE_SELECTED;
};