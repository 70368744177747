import { Path } from "paths";

// DO NOT MAKE process.env.ENV a variable since it's computed in compile time
export const isDevEnv = process.env.ENV === "development";
export const isProdEnv = process.env.ENV === "production";
export const isStagingEnv = process.env.ENV === "staging";
export const isDebugServer = process.env.NODE_ENV === "development";
export const isProdServer = !isDebugServer && isProdEnv;
export const isStagingServer = !isDebugServer && isStagingEnv;
export const isProdDebug = isDebugServer && isProdEnv;
export const isDebuggable = isDebugServer || !isProdEnv;
export const isHeadlessLogin = HEADLESS_LOGIN === "true" || window.location.hash === Path.headlessLogin;
export const authCookieDomain = isHeadlessLogin ? window.location.hostname : process.env.SSO_COOKIE_DOMAIN;

// those are functions to enable mocking in node
export function isProdUrl() {
  return window.location.href.startsWith("https://sellerportal.deliverr.com") || window.location.href.startsWith("https://test-portal.flexport.com") || window.location.href.startsWith("https://portal.flexport.com");
}
export function isStagingUrl() {
  return window.location.href.startsWith("https://staging.sellerportal.deliverr.dev");
}
export function skipRedirect() {
  return window.location.search.includes("skipRedirect");
}