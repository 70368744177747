import { EntityName } from "@deliverr/replenishment-client";
import { chain, filter, isEmpty, isNil, keyBy } from "lodash";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { transferCreateRemoveProduct, transferCreateSetReplenishmentError } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { ReplenishmentOrderCreationErrorDescriptions, ReplenishmentUnknownErrorDescription } from "./ReplenishmentErrorMessages";
import { useLoader } from "common/components/WithLoader/useLoader";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { ReplenishmentUnknownErrorCode } from "./ReplenishmentErrorCodes";
import { useUnmount } from "react-use";
export const useReplenishmentOrderCreationError = () => {
  const {
    destinationType,
    selectedProducts,
    replenishmentError
  } = useSelector(selectTransferCreate);
  const error = replenishmentError?.errors?.[0];
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();

  // Both order and shipment entity errors may be part of errors property
  const orderErrors = filter(error?.errors, err => err.entity === EntityName.ORDER || err.entity === EntityName.SHIPMENT);
  const itemErrors = filter(error?.errors, err => err.entity === EntityName.ORDER_ITEM || err.entity === EntityName.SHIPMENT_ITEM);

  // Only show root-level Entity orderError if both item and shipment sub-errors are empty
  if (isEmpty(orderErrors) && isEmpty(itemErrors) && error?.entity === EntityName.ORDER) {
    orderErrors.push(error);
  }
  const orderErrorDescriptions = orderErrors.map(err => getOrderErrorDescription(err, formatMessage, destinationType));
  const dskuToItemErrors = keyBy(itemErrors, itemError => itemError.entityId);
  const productsWithErrors = chain(selectedProducts).values().filter(product => !isNil(dskuToItemErrors[product.packOf ?? product.dsku])).map(product => {
    const {
      productErrorDescription,
      errorCode,
      rawErrorMessage
    } = getProductErrorDescription(dskuToItemErrors[product.packOf ?? product.dsku], formatMessage, destinationType);
    return {
      productErrorDescription,
      errorCode,
      product,
      rawErrorMessage
    };
  }).value();
  const isNextLoading = useLoader(CreateTransferLoader.CreateTransferOrderLoader);
  const onRemoveProduct = dsku => {
    return dispatch(transferCreateRemoveProduct(dsku));
  };
  useUnmount(() => {
    dispatch(transferCreateSetReplenishmentError({
      errors: []
    }));
  });
  return {
    productsWithErrors,
    orderErrorDescriptions,
    onRemoveProduct,
    isNextLoading
  };
};
const getProductErrorDescription = (productError, formatMessage, destinationType) => {
  if (!destinationType || !productError.code) {
    return {
      productErrorDescription: productError?.message,
      errorCode: ReplenishmentUnknownErrorCode
    };
  }
  const errorDescriptions = ReplenishmentOrderCreationErrorDescriptions[destinationType]?.itemErrors;
  const errorDescriptor = errorDescriptions?.[productError.code];
  return errorDescriptor ? {
    productErrorDescription: formatMessage(errorDescriptor),
    errorCode: productError.code,
    rawErrorMessage: productError?.message
  } : {
    productErrorDescription: productError?.message ?? formatMessage(ReplenishmentUnknownErrorDescription.UnknownError),
    errorCode: ReplenishmentUnknownErrorCode
  };
};
const getOrderErrorDescription = (orderError, formatMessage, destinationType) => {
  if (!destinationType || !orderError.code) {
    return {
      orderErrorDescription: orderError?.message,
      errorCode: ReplenishmentUnknownErrorCode
    };
  }
  const errorDescriptions = ReplenishmentOrderCreationErrorDescriptions[destinationType]?.orderErrors;
  const errorDescriptor = orderError?.code ? errorDescriptions?.[orderError.code] : undefined;
  return errorDescriptor ? {
    orderErrorDescription: formatMessage(errorDescriptor),
    errorCode: orderError?.code,
    rawErrorMessage: orderError?.message
  } : {
    orderErrorDescription: orderError?.message ?? formatMessage(ReplenishmentUnknownErrorDescription.UnknownError),
    errorCode: ReplenishmentUnknownErrorCode
  };
};