import { CLEAR_INBOUND } from "inbounds/steps/InboundLoadActions";
import { CreateShipmentActionTypes, CreateShipmentFormActionTypes } from "../CreateShipmentActionTypes";
import { resetStorageInboundCreate } from "storage/inbounds/create/store/actions/resetStorageInboundCreate";
import { batch } from "react-redux";
import { setFromAddress } from "inbounds/steps/address/AddressActions";
export const clearInbound = () => dispatch => {
  batch(() => {
    // resets the legacy ecom flow, except address
    dispatch({
      type: CLEAR_INBOUND
    });
    // resets the IPB form
    dispatch({
      type: CreateShipmentFormActionTypes.CLEAR_FORM
    });
    // resets the fromAddress in inbound state
    dispatch(setFromAddress({
      name: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zip: ""
    }));
    // reset the IPB meta state
    dispatch({
      type: CreateShipmentActionTypes.CLEAR_META
    });
    // reset any storage state
    dispatch(resetStorageInboundCreate());
  });
};