import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { InventoryActionTypes } from "./InventoryActions.types";
import { ReplenishmentStrategy } from "common/clients/storage/StorageJit/ReplenishmentStrategy";
import { defaultLeadTimeWeeks, defaultReplenishMonths } from "./JITRecommendationsConfigOptions";
export const initialInventoryState = {
  jitConfig: {
    isJitEnabled: false,
    replenishmentStrategy: ReplenishmentStrategy.DYNAMIC,
    shouldAutoConfirmTransfer: false,
    autoConfirmAfterHours: 0,
    autoConfirmUpdatedAt: null
  },
  replenishmentStrategies: [],
  recommnendationSellerConfig: {
    sellerFBAConfig: {
      isActive: false,
      shouldAutoConfirmTransfer: false,
      autoConfirmUpdatedAt: null
    },
    sellerWFSConfig: {
      isActive: false
    }
  },
  storageRecommendationSellerConfig: {
    isEnabled: false,
    leadTimeWeeks: defaultLeadTimeWeeks,
    replenishMonths: defaultReplenishMonths
  }
};
const reducers = {
  ...handleSimpleReducerUpdates([InventoryActionTypes.SET_JIT_REPLENISHMENT_STRATEGIES, InventoryActionTypes.SET_STORAGE_JIT_CONFIG, InventoryActionTypes.UPDATE_STORAGE_JIT_CONFIG])
};
export const inventoryReducer = createReducer(initialInventoryState, reducers);