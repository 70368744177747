import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ReturnsListActionTypes } from "./OrderReturnsListStore.types";
export const orderReturnsListInitialState = {
  orderReturnsList: []
};
const reducers = {
  ...handleSimpleReducerUpdates([ReturnsListActionTypes.RETURNS_GET_LIST]),
  [ReturnsListActionTypes.RETURNS_RESET_LIST]: state => ({
    ...state,
    ...orderReturnsListInitialState
  })
};
export const orderReturnsListReducer = createReducer(orderReturnsListInitialState, reducers);