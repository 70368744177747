import { defineMessages } from "react-intl";
export const PREP_HELP_CENTER_NOTIFICATION_LABELS = defineMessages({
  description: {
    id: "prep.helpCenterNotification.description",
    defaultMessage: "Check Prep's help center to learn how to create a Prep request."
  },
  button: {
    id: "prep.helpCenterNotification.button",
    defaultMessage: "Visit Help Center"
  },
  link: {
    id: "prep.helpCenterNotification.link",
    defaultMessage: "Learn More"
  }
});