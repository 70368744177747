export let FreightBookingSetupStepType;
(function (FreightBookingSetupStepType) {
  FreightBookingSetupStepType["PICKUP_DETAILS"] = "PICKUP_DETAILS";
  FreightBookingSetupStepType["DELIVERY_DETAILS"] = "DELIVERY_DETAILS";
  FreightBookingSetupStepType["FREIGHT_INSTRUCTIONS"] = "FREIGHT_INSTRUCTIONS";
  FreightBookingSetupStepType["CARGO_PALLET_DETAILS"] = "CARGO_PALLET_DETAILS";
  FreightBookingSetupStepType["CONFIRM_SHIPMENT"] = "CONFIRM_SHIPMENT";
  FreightBookingSetupStepType["DRAYAGE_DETAILS"] = "DRAYAGE_DETAILS";
  FreightBookingSetupStepType["DRAYAGE_CARGO_DETAILS"] = "DRAYAGE_CARGO_DETAILS";
  FreightBookingSetupStepType["FBA_DELIVERY_DETAILS"] = "FBA_DELIVERY_DETAILS";
})(FreightBookingSetupStepType || (FreightBookingSetupStepType = {}));