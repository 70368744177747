import { AsnStatus, ShippingPlanDispersalMethod } from "@deliverr/legacy-inbound-client";
import { isNil } from "lodash/fp";
import { batch } from "react-redux";
import produce from "immer";
import history from "BrowserHistory";
import { inboundClient, productClient } from "Clients";
import { addAllToById, getItemsFromById } from "common/ById";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { notifyUserOfError } from "common/ErrorToast";
import { OnboardingStage } from "common/organization/OnboardingStage";
import { createActionCreator } from "common/ReduxUtils";
import { removeAllEmTags } from "common/StringUtils";
import { track } from "common/utils/Analytics";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { InboundStep } from "inbounds/InboundTypes";
import { dispatchThenSaveInbound } from "inbounds/steps/InboundSaveActions";
import { getBulkUploadSessionId, getCrossDockWarehouse, getIsFinalUnconfirmedShipment, getLoadedPlannedShipment, getNextIncompleteShipmentId, getUseCasePackModified } from "inbounds/steps/InboundStepSelectors";
import { isShipToOneDispersalMethod, isShipToOnePlan } from "inbounds/steps/ship/InboundUtils";
import log, { logError, logStart, logSuccess } from "Logger";
import { syncOnboardingStage, updateOrganizationClaims } from "organization/OrganizationActions";
import { DynamicRoutes } from "paths";
import { handleSaveNewInboundError } from "inbounds/error/handleSaveNewInboundError";
import { getIsShipToOne } from "./steps/ship/view/ViewShipmentSelectors";
import { getIsDeliverrRates, getLoadedShipment, getLoadedShipmentId } from "./store/selectors/shipments";
import { getCrossdockInboundQuote } from "./crossdock/store/selectors";
import { getOrgCompletedInbound, getOrgCreatedInbound, getOrgOnboardingStage } from "organization/OrganizationSelectors";
import { getSellerId } from "common/user/UserSelectors";
import { createFreightTrackingInfo } from "./store/actions/freight/createFreightTrackingInfo";
import { getLoadedShipmentFreightInfo, getLtlCompliance } from "./store/selectors/freight";
import { getShouldShowCheckoutModalOnClick } from "./steps/ship/ShipmentSetupSelectors";
import { fetchCrossdockInboundShipment } from "./crossdock/store/actions";
import { goToInboundStep } from "./store/actions/navigation/goToInboundStep";
import { switchShipmentView } from "./store/actions/navigation/switchShipmentView";
import { InboundActionTypes } from "./store/InboundActionTypes";
import { getIsFreightExternal, getIsFreightDeliverr } from "inbounds/utils/shippingMethodUtils";
import { SHIPPING_METHOD_TO_OPTION } from "./constants/shippingMethodToOption";
import { updateShippingPlanItems } from "./store/actions/shippingPlan/updateShippingPlanItems";
import { updateLoadedShipmentContext } from "./store/actions/shipment/updateLoadedShipmentContext";
import { selectCanDownloadPalletLabels } from "./store/selectors/freight/selectCanDownloadPalletLabels";
import { getShipmentReceivingInfo } from "./store/actions/shipment/getShipmentReceivingInfo";
import { selectFromAddress } from "storage/inbounds/create/store/selector/selectFromAddress";
import { parseAddress } from "./utils/parseAddress";
import { selectFloorLoadedContainerDetails } from "prep/store/selectors/selectFloorLoadedContainerDetails";
import { loadPrepByShippingPlanId } from "prep/store";
import { setFCLContainerDetails } from "prep/store/actions/setFCLContainerDetails";
export const loadShipments = () => async (dispatch, getState) => {
  const {
    inbound: {
      plan: {
        id: shippingPlanId
      }
    },
    user: {
      sellerId
    }
  } = getState();
  try {
    dispatch({
      type: InboundActionTypes.LOAD_SHIPMENTS,
      shipments: await inboundClient.getShipments(sellerId, shippingPlanId)
    });
  } catch (err) {
    logError({
      fn: "loadShipments"
    }, err, "error loading shipments");
  }
};
export const addProduct = (product, prevQuantities) => async dispatch => {
  await dispatch(addProducts([product], prevQuantities ? {
    [product.dsku]: prevQuantities
  } : undefined));
};
export const addProducts = (products, prevQuantitiesByDsku) => async (dispatch, getState) => {
  let casePackDefaults = {};
  const dskus = products.map(({
    dsku
  }) => dsku);
  const state = getState();
  try {
    // if this plan is being duplicated we don't want to fetch the case pack defaults and rely on
    // the duplicate data
    if (!prevQuantitiesByDsku) {
      casePackDefaults = await inboundClient.getCasePackDefaults(dskus);
    }
  } catch (err) {
    casePackDefaults = undefined;
  }
  const productsWithDefaults = products.map(product => ({
    ...product,
    caseQty: casePackDefaults?.[product.dsku]?.unitsPerCase ?? product.caseQty
  }));
  dispatch({
    type: InboundActionTypes.ADD_PRODUCTS,
    planItems: createPlanItems(productsWithDefaults, state.inbound.planItems, state.inbound.plan.useCasePack, prevQuantitiesByDsku),
    productDetailsCache: createProductDetailsCache(state.inbound.productDetailsCache, productsWithDefaults),
    persistedPlanItemsById: createPersistedPlanItemsById(productsWithDefaults, state.inbound.persistedPlanItemsById, state.inbound.plan.useCasePack, prevQuantitiesByDsku)
  });
  await dispatch(updateProductCache(dskus));
};
const createPersistedPlanItemsById = (products, persistedPlanItemsById, isCurrentlyCasePack = false, prevQuantitiesByDsku) => {
  const packItemsByDsku = {};
  products.forEach(product => {
    const packItem = getPackItem(product, isCurrentlyCasePack, prevQuantitiesByDsku?.[product.dsku]);
    packItemsByDsku[product.dsku] = packItem;
  });
  return {
    ...persistedPlanItemsById,
    ...packItemsByDsku
  };
};
const createProductDetailsCache = (previousCache, products) => {
  const productsByDsku = {};
  products.forEach(product => {
    productsByDsku[product.dsku] = product;
  });
  return {
    ...previousCache,
    ...productsByDsku
  };
};
const createPlanItems = (products, planItems, isCurrentlyCasePack = false, prevQuantitiesByDsku) => {
  return produce(planItems, draft => {
    const packItems = products.map(product => getPackItem(product, isCurrentlyCasePack, prevQuantitiesByDsku?.[product.dsku]));
    return addAllToById(products.map(product => product.dsku), packItems, planItems);
  });
};
const getPackItem = (product, isCurrentlyCasePack, prevQuantities) => {
  const {
    dsku
  } = product;
  if (isCurrentlyCasePack) {
    return prevQuantities && prevQuantities.caseQty > 1 ? {
      dsku,
      caseQty: prevQuantities.caseQty,
      numberOfCases: prevQuantities.qty / prevQuantities.caseQty,
      qty: prevQuantities.qty
    } : {
      dsku,
      caseQty: product.caseQty ?? 1,
      numberOfCases: 1,
      qty: product.caseQty ?? 1
    };
  }
  return {
    dsku,
    caseQty: 1,
    numberOfCases: 1,
    qty: prevQuantities && prevQuantities.caseQty === 1 ? prevQuantities.qty : 1
  };
};
export const removeProduct = createActionCreator(InboundActionTypes.REMOVE_PRODUCT, "dsku");
export const updateQty = createActionCreator(InboundActionTypes.UPDATE_QTY, "dsku", "qty");
export const updateBarcodes = createActionCreator(InboundActionTypes.UPDATE_BARCODES, "barcodes");
export const updateCaseQty = createActionCreator(InboundActionTypes.UPDATE_QTY_PER_CASE, "dsku", "caseQty");
export const updateNumberOfCases = createActionCreator(InboundActionTypes.UPDATE_NUMBER_OF_CASES, "dsku", "numberOfCases");

/* Initial save of a draft inbound to the back-end; happens only after products are selected */
export const saveNewInbound = () => async (dispatch, getState) => {
  const state = getState();
  const onboardingStage = getOrgOnboardingStage(state);
  const hasCreatedInbound = getOrgCreatedInbound(state);
  const {
    inbound: {
      plan
    },
    user: {
      sellerId
    }
  } = state;
  const ctx = logStart({
    fn: "saveNewInbound",
    sellerId,
    plan
  });
  const bulkUploadSessionId = getBulkUploadSessionId(state);
  const fromAddress = selectFromAddress(state);
  log.info(ctx, "saving new inbound");
  let newShippingPlan;
  try {
    newShippingPlan = await inboundClient.createShippingPlanV2({
      sellerId,
      name: plan.name,
      useCasePack: plan.useCasePack,
      sessionUuid: bulkUploadSessionId,
      fromAddress: fromAddress?.country ? parseAddress(fromAddress) : undefined
    });
    logSuccess({
      ...ctx,
      newShippingPlan
    }, "successfully created shipping plan");
  } catch (error) {
    logError(ctx, error, "error saving new inbound");
    handleSaveNewInboundError(error);
    throw error;
  }
  dispatch({
    type: InboundActionTypes.SAVE_NEW_SHIPPING_PLAN_SUCCESS,
    plan: newShippingPlan
  });

  // allow update call to throw
  await dispatch(updateShippingPlanItems());
  if (!(OnboardingStage.CreatedInbound in onboardingStage) || !hasCreatedInbound) {
    track("Create Inbound");
    updateOrganizationClaims({
      dealStage: "Create Inbound"
    });
    dispatch(syncOnboardingStage(OnboardingStage.CreatedInbound));
  }
  history.replace(DynamicRoutes.shippingPlanProducts.parse({
    shippingPlanId: newShippingPlan.id
  }));
};
const finalizeShipmentIfInitialized = async ({
  sellerId,
  shipmentId,
  crossdockQuoteId
}) => {
  const asns = await inboundClient.getAsnsByShipmentId(sellerId, shipmentId);
  const hasInitializedAsns = asns.some(asn => asn.status === AsnStatus.INITIALIZED);
  if (hasInitializedAsns) {
    await inboundClient.finalizeShipmentUnifiedV2({
      shipmentId,
      crossdockQuoteId
    });
  }
  return hasInitializedAsns;
};
export const completeShipment = dispatchThenSaveInbound(inputShipmentId => async (dispatch, getState) => {
  const state = getState();
  const didCompleteInbound = getOrgCompletedInbound(state);
  const onboardingStage = getOrgOnboardingStage(state);
  const {
    id,
    sellerId
  } = getLoadedShipment(state);
  const shipmentId = inputShipmentId ?? id;
  const {
    plan
  } = state.inbound;
  const isForwardingShipment = isShipToOnePlan(plan);
  const floorLoadedContainerDetails = selectFloorLoadedContainerDetails(state);
  const ctx = logStart({
    fn: "completeShipment",
    sellerId,
    shipmentId
  });
  log.info(ctx, "completing shipment");
  try {
    let crossdockQuoteId;
    // Forwarding validation must happen before attempting to finalize as we must ensure we have a quote for finalization
    if (isForwardingShipment) {
      const crossdockWarehouse = getCrossDockWarehouse(state);
      crossdockQuoteId = getCrossdockInboundQuote(state)?.id;
      if (isNil(crossdockQuoteId)) {
        throw new Error("Cannot complete the shipment without a Crossdock Quote");
      }
      if (!crossdockWarehouse) {
        throw new Error("Cannot complete the shipment without an assigned Crossdock");
      }
    }
    await finalizeShipmentIfInitialized({
      sellerId,
      shipmentId,
      crossdockQuoteId
    });

    // Must do this step after ensuring shipment is finalized, and then guarantee that a CrossdockInboundShipment was generated
    if (isForwardingShipment) {
      const cdShipment = await dispatch(fetchCrossdockInboundShipment(sellerId, plan.id));
      if (!cdShipment) {
        throw new Error("Cannot complete the shipment without a Crossdock Shipment");
      }
      if (floorLoadedContainerDetails) {
        try {
          await inboundClient.requestFloorLoadedContainerPrep(sellerId, plan.id, floorLoadedContainerDetails);
          await dispatch(loadPrepByShippingPlanId({
            sellerId,
            shippingPlanId: plan.id
          }));
        } catch (error) {
          logError(ctx, error, "error requesting floor loaded container prep, swallowing error");
        }
      }
    }
    await inboundClient.completeShipment(sellerId, shipmentId);
    logSuccess(ctx, "completed shipment");
    const [shipment, savedPackages] = await Promise.all([inboundClient.getShipment(sellerId, shipmentId), inboundClient.getActivePackages(sellerId, shipmentId)]);
    log.info({
      ...ctx,
      shipment,
      savedPackages
    }, "retrieved shipment and packages");
    dispatch({
      type: InboundActionTypes.COMPLETE_SHIPMENT,
      shipmentId,
      savedPackages,
      shipment
    });
    if (!(OnboardingStage.CompletedInbound in onboardingStage) || !didCompleteInbound) {
      track("Complete Inbound");
      updateOrganizationClaims({
        dealStage: "Complete Inbound"
      });
      dispatch(syncOnboardingStage(OnboardingStage.CompletedInbound));
    }
    await dispatch(updateProductCaseQty());
  } catch (err) {
    logError(ctx, err, "error completing shipment");
    notifyUserOfError({
      err,
      toastId: "completeShipmentError"
    });
  }
});
export const completeAllShipments = () => async (dispatch, getState) => {
  const {
    inbound: {
      shipments: {
        ids: shipmentIds
      }
    }
  } = getState();
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return batch(async () => {
    await Promise.all(shipmentIds.map(shipmentId => dispatch(completeShipment(shipmentId))));
  });
};
export const addBoxSize = dispatchThenSaveInbound(() => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.ADD_BOX_SIZE,
    shipmentId: loadedShipmentId
  });
});
export const removeBoxSize = dispatchThenSaveInbound(boxSizeIndex => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.REMOVE_BOX_SIZE,
    shipmentId: loadedShipmentId,
    boxSizeIndex
  });
});
export const setPackageCount = packageCount => (dispatch, getState) => {
  const state = getState();
  const loadedShipmentId = getLoadedShipmentId(state);
  dispatch({
    type: InboundActionTypes.SET_PACKAGE_COUNT,
    shipmentId: loadedShipmentId,
    packageCount
  });
};
export const setBoxQty = dispatchThenSaveInbound((dsku, boxIndex, qty) => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.SET_BOX_QTY,
    shipmentId: loadedShipmentId,
    dsku,
    boxIndex,
    qty
  });
});
export const setBoxSize = dispatchThenSaveInbound((boxIndex, boxSizeIndex) => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.SET_BOX_SIZE,
    shipmentId: loadedShipmentId,
    boxIndex,
    boxSizeIndex
  });
});
export const setBoxDimensions = dispatchThenSaveInbound((boxIndex, width, length, height) => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.SET_BOX_DIMENSIONS,
    shipmentId: loadedShipmentId,
    boxIndex,
    width,
    length,
    height
  });
});
export const setBoxWeight = dispatchThenSaveInbound((boxIndex, weightLbs) => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.SET_BOX_WEIGHT,
    shipmentId: loadedShipmentId,
    boxIndex,
    weightLbs
  });
});
export const setNumBoxes = dispatchThenSaveInbound((boxIndex, numBoxes) => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.SET_NUM_BOXES,
    shipmentId: loadedShipmentId,
    boxIndex,
    numBoxes
  });
});
export const setCargoType = dispatchThenSaveInbound(cargoType => (dispatch, getState) => {
  const {
    inbound: {
      loadedShipmentId
    }
  } = getState();
  dispatch({
    type: InboundActionTypes.SET_CARGO_TYPE,
    shipmentId: loadedShipmentId,
    cargoType
  });
  dispatch(setFCLContainerDetails(undefined));
});
export const setFloorLoadedContainerDetails = dispatchThenSaveInbound((containerType, containerId) => dispatch => {
  dispatch(setFCLContainerDetails({
    type: containerType,
    containerId: containerId?.length ? containerId : undefined
  }));
});
export const confirmShipment = dispatchThenSaveInbound(() => async (dispatch, getState) => {
  const state = getState();
  const {
    shippingMethod
  } = getLoadedPlannedShipment(state);
  dispatch(addLoader(InboundLoaderId.finalizingShipment));
  const isFinalUnconfirmedShipment = getIsFinalUnconfirmedShipment(state);
  const {
    hasConfirmedPalletCompliance,
    hasConfirmedAppointment,
    ...storedFreightInfo
  } = getLoadedShipmentFreightInfo(state) ?? {};
  const isFreightExternal = getIsFreightExternal(shippingMethod);
  const isShipToOne = Boolean(getIsShipToOne(state));
  const nextIncompleteShipmentId = getNextIncompleteShipmentId(state);
  const shouldShowCheckoutModalOnClick = getShouldShowCheckoutModalOnClick(state);
  const isDeliverrRates = getIsDeliverrRates(state);
  const isLtlCompliant = getLtlCompliance(state);
  const canDownloadPalletLabels = selectCanDownloadPalletLabels(state);
  const ctx = {
    fn: "confirmShipment",
    shouldShowCheckoutModalOnClick,
    isFreightExternal,
    isShipToOne,
    isFinalUnconfirmedShipment,
    isDeliverrRates,
    shippingMethod,
    isLtlCompliant
  };
  logStart(ctx);
  await dispatch(getShipmentReceivingInfo());
  if (!isShipToOne) {
    await dispatch(completeShipment());
  } else if (isFinalUnconfirmedShipment) {
    await dispatch(completeAllShipments());
  } else {
    await dispatch(switchShipmentView(nextIncompleteShipmentId));
  }

  // Handle freight external flow
  if (isFreightExternal) {
    await dispatch(createFreightTrackingInfo(storedFreightInfo));
  }

  /**
   * The Shipment Confirmation page is not a typical step in the flow, so it needs to be explicitly invoked
   * for flows that require confirmation pages.
   */
  if (canDownloadPalletLabels || getIsFreightDeliverr(shippingMethod)) {
    dispatch(goToInboundStep(InboundStep.SHIPMENT_CONFIRMED));
  }
  dispatch(clearLoader(InboundLoaderId.finalizingShipment));
});
export const setShippingMethod = dispatchThenSaveInbound(shippingMethod => async (dispatch, getState) => {
  const state = getState();
  const loadedShipmentId = getLoadedShipmentId(state);
  const {
    shippingMethod: oldShippingMethod
  } = getLoadedPlannedShipment(state);
  if (loadedShipmentId === undefined || shippingMethod === oldShippingMethod) {
    return;
  }
  dispatch({
    type: InboundActionTypes.SET_SHIPPING_METHOD,
    shipmentId: loadedShipmentId,
    shippingMethod
  });
  dispatch(setFCLContainerDetails(undefined));
  const sellerId = getSellerId(state);
  const shippingOption = SHIPPING_METHOD_TO_OPTION[shippingMethod];
  try {
    dispatch(addLoader(InboundLoaderId.updateShippingOption));
    // update the shipment and existing packages to the new shipping method
    const updatedShipment = await inboundClient.updateShippingOption(sellerId, loadedShipmentId, shippingOption);
    await dispatch(updateLoadedShipmentContext(updatedShipment));
  } catch (err) {
    logError({
      fn: "setShippingMethod",
      oldShippingMethod,
      shippingMethod,
      loadedShipmentId
    }, err);
  } finally {
    dispatch(clearLoader(InboundLoaderId.updateShippingOption));
  }
});
export const setBulkUploadSessionId = createActionCreator(InboundActionTypes.SET_BULK_UPLOAD_SESSION_ID, "bulkUploadSessionId");
export const setUseCasePack = createActionCreator(InboundActionTypes.SET_USE_CASE_PACKS, "useCasePack");
export const addOneSkuBoxConfig = dispatchThenSaveInbound(dsku => ({
  type: InboundActionTypes.ADD_ONE_SKU_BOX_CONFIG,
  dsku
}));
export const duplicatePackage = createActionCreator(InboundActionTypes.DUPLICATE_PACKAGE, "packageIndex");
export const removePackage = dispatchThenSaveInbound(createActionCreator(InboundActionTypes.REMOVE_PACKAGE, "packageIndex"));
export const setNumberOfBoxes = dispatchThenSaveInbound(createActionCreator(InboundActionTypes.SET_NUMBER_OF_BOXES, "packageIndex", "numberOfBoxes"));
export const updateProductCaseQty = () => async (dispatch, getState) => {
  const ctx = {
    fn: "updateProductCaseQty"
  };
  log.info(ctx, "updating product case qty");
  const state = getState();
  const {
    inbound: {
      planItems,
      productDetailsCache
    }
  } = state;
  try {
    const loadedShipmentItems = getLoadedShipment(state).items;
    const caseQtyUpdateRequests = getItemsFromById(planItems).filter(planItem => {
      const product = productDetailsCache[planItem.dsku];
      const hasProductInShipment = loadedShipmentItems.some(({
        dsku
      }) => planItem.dsku === dsku);
      const isCaseQtyUpdated = product && planItem.caseQty !== product.caseQty;
      const isCasePack = planItem.caseQty !== 1;
      return hasProductInShipment && isCaseQtyUpdated && isCasePack;
    })
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    .map(({
      caseQty,
      dsku
    }) => productClient.update({
      dsku,
      caseQty
    }));
    await Promise.all(caseQtyUpdateRequests);
    dispatch({
      type: InboundActionTypes.UPDATE_PRODUCT_CASE_QTY_SUCCESS
    });
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error updating product case qty");
    notifyUserOfError({
      err,
      toastId: "updateProductCaseQtyError"
    });
    dispatch({
      type: InboundActionTypes.UPDATE_PRODUCT_CASE_QTY_ERROR
    });
  }
};
export const setIsFirstInbound = (isFirstInbound = false) => ({
  type: InboundActionTypes.SET_IS_FIRST_INBOUND,
  isFirstInbound
});
export const saveOldState = createActionCreator(InboundActionTypes.SAVE_OLD_INBOUND_STATE);
export const updatePlanCasePack = () => async (dispatch, getState) => {
  const state = getState();
  const isUseCasePackModified = getUseCasePackModified(state);
  if (isUseCasePackModified) {
    const {
      id,
      useCasePack: isCasePack
    } = state.inbound.plan;
    const ctx = logStart({
      fn: "updatePlanCasePack",
      isCasePack
    });
    try {
      const planUpdate = await inboundClient.updateShippingPlan(state.user.sellerId, id, {
        useCasePack: Boolean(isCasePack)
      });
      dispatch({
        type: InboundActionTypes.UPDATE_PLAN,
        planUpdate
      });
      logSuccess(ctx, "successfully updated case pack selection");
    } catch (error) {
      logError(ctx, error);
      throw error;
    }
  }
};
export const setIsRedistributions = createActionCreator(InboundActionTypes.SET_IS_REDISTRIBUTIONS, "isRedistributions");

/**
 * Effectively extends setIsRedistributions, but with added support for enumerated Dispersal Methods
 */
export const setDispersalMethod = createActionCreator(InboundActionTypes.SET_DISPERSAL_METHOD, "dispersalMethod", "isRedistributions");
export const setShippingPlanShipToOneOptions = () => async (dispatch, getState) => {
  const {
    inbound: {
      plan: {
        id
      },
      dispersalMethod: nullableDispersalMethod
    },
    user: {
      sellerId
    }
  } = getState();
  const dispersalMethod = nullableDispersalMethod ?? ShippingPlanDispersalMethod.DIRECT;
  const isShipToOne = Boolean(isShipToOneDispersalMethod(dispersalMethod));
  const ctx = logStart({
    fn: "setShippingPlanShipToOneOptions",
    shippingPlanId: id,
    isShipToOne,
    dispersalMethod
  });
  try {
    const planUpdate = await inboundClient.updateShippingPlan(sellerId, id, {
      isForwarding: isShipToOne,
      dispersalMethod
    });
    batch(() => {
      dispatch(setDispersalMethod(dispersalMethod, isShipToOne));
      dispatch({
        type: InboundActionTypes.UPDATE_PLAN,
        planUpdate
      });
    });
  } catch (err) {
    logError(ctx, err);
    notifyUserOfError({
      err,
      toastId: "planUpdateError"
    });
    throw err; // interrupt transition
  }
};

export const updateProductCache = dskus => async (dispatch, getState) => {
  const state = getState();
  const dskusToFetch = dskus ?? Object.keys(state.inbound.productDetailsCache);
  const productDetailsCache = await productClient.getUnifiedProducts(dskusToFetch.map(removeAllEmTags), {
    includeCustomsInformation: true,
    includeHazmatInformation: true,
    includeProductPreparation: true
  });
  dispatch({
    type: InboundActionTypes.UPDATE_PRODUCT_CACHE,
    productDetailsCache: {
      ...state.inbound.productDetailsCache,
      ...productDetailsCache
    }
  });
};