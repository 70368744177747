import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";
export const selectBookingCreateRequest = () => createSelector(selectBookingRequest(false), bookingRequest => {
  const {
    from,
    originIsPort,
    originAddressFid,
    originPortFid,
    originPortGrouping
  } = bookingRequest;
  const createBookingRequest = {
    from,
    originIsPort,
    originAddressFid,
    originPortFid,
    originPortGrouping
  };
  return createBookingRequest;
});