import { createShipments } from "inbounds/store/actions/shippingPlan/createShipments";
import { copyAddressToAllShipments } from "inbounds/steps/address/AddressActions";
import { updateShipmentId } from "inbounds/store/actions/shipment/updateShipmentId";
import { setIsGeneratingShipments } from "inbounds/store/actions/shippingPlan/setIsGeneratingShipments";
import { getSellerId } from "common/user/UserSelectors";
import { getShippingPlanId } from "../../steps/InboundStepSelectors";
import { loadHasInactiveLabels } from "../../store/actions/shippingPlan/loadHasInactiveLabels";
import { getShipmentAsns } from "inbounds/store/actions/shipment/getShipmentAsns";

// Errors for draft shipment creation are currently handled in that action

export const generateShipmentsTransitionAction = () => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const shippingPlanId = getShippingPlanId(state);
  try {
    dispatch(setIsGeneratingShipments(true));
    await dispatch(createShipments());
    await dispatch(copyAddressToAllShipments());
    await dispatch(updateShipmentId());
    await dispatch(getShipmentAsns());
    dispatch(loadHasInactiveLabels(sellerId, shippingPlanId));
  } finally {
    dispatch(setIsGeneratingShipments(false));
  }
};