export const getStorageWarehouseOptions = warehouses => {
  const bestWarehouses = new Array();
  const warehousesWithPalletsSet = new Set();
  warehouses.forEach(warehouse => {
    if (warehouse.hasAvailablePalletCapacity) {
      bestWarehouses.push(warehouse);
    }
    if (warehouse.palletCount > 0 && !warehouse.hasAvailablePalletCapacity) {
      warehousesWithPalletsSet.add(warehouse);
    }
  });
  bestWarehouses.forEach(warehouse => warehousesWithPalletsSet.add(warehouse));
  const warehousesWithHigestScore = Array.from(warehousesWithPalletsSet.values());
  warehousesWithHigestScore.sort((warehouse1, warehouse2) => warehouse2.score - warehouse1.score);
  return warehousesWithHigestScore;
};