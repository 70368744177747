import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { handleSellerServiceError } from "./handleSellerServiceError";
export class AdminClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/admin`
    });
  }
  async impersonateOrganization(sellerId) {
    try {
      await this.apiClient.put({
        url: `/organization/${sellerId}/impersonate`,
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("impersonateOrganization", error);
    }
  }
}