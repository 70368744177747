import * as clients from "Clients";
import * as Cookie from "js-cookie";
import { isEmpty, omit, pick } from "lodash/fp";
import { setLogUser } from "Logger";
import { fetchBillingSummary } from "billing/BillingActions";
import { getSalesChannels } from "channels/ChannelsActions";
import { setUser } from "common/elevio";
import { sendGTMData } from "common/utils/Analytics";
import store from "store";
import { redirectToInitialUrl } from "common/utils/UrlIntegration";
import { getOrgHasFirstOrder } from "organization/OrganizationSelectors";
import { loadFullStoryIfNotLoaded } from "common/utils/thirdParty/FullStory";
import { updateDataDogIdentity } from "common/utils/thirdParty/DataDog/DataDog";
import { getIsTestSeller } from "organization/utils/getIsTestSeller";
import { identifyUserInChameleon } from "common/utils/thirdParty/Chameleon";
export function attachSellerToken(token) {
  Object.values(clients).forEach(client => {
    client.withAccessToken(token);
  });
}
export const onSellerLogin = async () => {
  const state = store.getState();
  const {
    user,
    organization
  } = state;
  const hasFirstOrder = getOrgHasFirstOrder(state);
  const dispatch = store.dispatch;
  const {
    sellerId,
    email
  } = user;
  if (!isEmpty(sellerId)) {
    void dispatch(getSalesChannels()).then(() => {
      setUser(user);
    });

    // Get billing summary information with count of unpaid invoices so we can display
    // the UnpaidInvoice banner if there are multiple unpaid invoices.
    dispatch(fetchBillingSummary());

    // Google Tag Manager
    const googleTagManagerUserData = omit(["algoliaSearchKey", "onboardingStage"], user);
    const googleTagManagerOrganizationData = pick(["onboardingStage", "dealStage"], organization);

    // this will be deprecated in favor of sendGTMData event
    Cookie.set("userId", googleTagManagerUserData.email);
    Cookie.set("sellerId", googleTagManagerUserData.sellerId);
    Cookie.set("listingTool", googleTagManagerUserData.listingTool);
    sendGTMData({
      event: "onSellerLogin",
      ...googleTagManagerUserData,
      ...googleTagManagerOrganizationData,
      hasFirstOrder
    });
    // End of Google Tag Manager

    // Full Story
    loadFullStoryIfNotLoaded();
    if (window.FS) {
      window.FS.identify(email, user);
    }
    const isTestSeller = getIsTestSeller({
      email,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      isTestAccount: organization.isTestAccount || user.groups.includes("admin"),
      isLoose: true
    });
    updateDataDogIdentity({
      user: {
        ...user,
        isTestSeller
      }
    });
    identifyUserInChameleon(user);
    if (window.DeliverrAttribution) {
      window.DeliverrAttribution.identify(sellerId);
    }
    setLogUser({
      sellerId,
      email
    });
    redirectToInitialUrl();
  }
};