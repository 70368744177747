import { ApiClient } from "../core/ApiClient";
import { DeliverrError } from "@deliverr/commons-objects";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
export class ReportingClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.REPORTING_API_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/reports`
    });
  }
  async getReports(sellerId) {
    return await this.apiClient.get({
      url: `/${sellerId}`,
      authentication: isAuthenticated
    });
  }
  async getReportUrl(sellerId, reportName) {
    return await this.apiClient.get({
      url: `/${sellerId}/${reportName}`,
      authentication: isAuthenticated
    });
  }
  async generateReport(sellerId, type, dateStart, dateEnd, logisticsSku) {
    try {
      return await this.apiClient.post({
        url: `/${sellerId}`,
        body: {
          type,
          dateStart,
          dateEnd,
          logisticsSku
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      if (!err.response || err.response.status !== 400) {
        throw err;
      } else {
        throw new DeliverrError("MISSING_PARAMETERS");
      }
    }
  }
}