import { format } from "date-fns";
import { DistributionChannel, Source } from "@deliverr/replenishment-client";
import { fromLooseDate } from "common/date/LooseDate";
import { sumBy } from "lodash";
import { FullCommonDate } from "common/date/DateFormat";
export let SummaryLevel;
(function (SummaryLevel) {
  SummaryLevel[SummaryLevel["SHIPMENT"] = 0] = "SHIPMENT";
  SummaryLevel[SummaryLevel["ORDER"] = 1] = "ORDER";
})(SummaryLevel || (SummaryLevel = {}));
function formatDateString(date) {
  return fromLooseDate(date).toLocaleString(undefined, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
    second: "2-digit"
  });
}
export function useReplenishmentOrderSummary(replenishmentOrder) {
  const isMultiShipment = (replenishmentOrder?.shipments?.length ?? 0) > 1;
  const shipment = replenishmentOrder?.shipments?.[0];
  const isWholesaleOrder = replenishmentOrder?.distributionChannel === DistributionChannel.WHOLESALE || replenishmentOrder?.source === Source.WHOLESALE_MIGRATION;
  const formattedShipByDate = replenishmentOrder?.shipBy && format(new Date(replenishmentOrder.shipBy), FullCommonDate);
  return {
    numSkus: replenishmentOrder?.orderItems?.length ?? 0,
    numUnits: sumBy(replenishmentOrder?.orderItems, item => item.totalUnits ?? 0),
    submitTimestamp: formatDateString(String(replenishmentOrder?.createdAt)),
    poNumber: isWholesaleOrder ? replenishmentOrder?.externalId : undefined,
    replenishmentOrder,
    isMultiShipment,
    isWholesaleOrder,
    shipment,
    formattedShipByDate
  };
}