import { ShortMonthDateYear } from "common/date/DateFormat";
import { format } from "date-fns-tz";
import { useIntl } from "react-intl";
import { SHORT_SHIP_PACKAGES_TABLE_LABELS } from "./labels";
import { fromLooseDate } from "common/date/LooseDate";
export const useShortShipPackagesTable = ({
  summary
}) => {
  const {
    formatMessage,
    formatNumber
  } = useIntl();
  const {
    createdAt,
    expectedBoxQty,
    arrivedBoxQty
  } = summary;
  const FractionCell = ({
    value
  }) => formatMessage({
    id: "inbounds.shipmentProblems.shortShipBlade.packagesTable.arrivedQtyValue",
    defaultMessage: value
  });
  const NumberCell = ({
    value
  }) => formatNumber(value);
  const columns = [{
    id: "dateReceived",
    accessor: "dateReceived",
    Header: formatMessage(SHORT_SHIP_PACKAGES_TABLE_LABELS.alertSent),
    Cell: ({
      value
    }) => format(fromLooseDate(value), ShortMonthDateYear)
  }, {
    id: "arrivedQty",
    accessor: "arrivedQty",
    Header: formatMessage(SHORT_SHIP_PACKAGES_TABLE_LABELS.arrivedQty),
    Cell: FractionCell
  }, {
    id: "discrepancyQty",
    accessor: "discrepancyQty",
    Header: formatMessage(SHORT_SHIP_PACKAGES_TABLE_LABELS.discrepancyQty),
    Cell: NumberCell
  }];
  const data = [{
    dateReceived: createdAt,
    arrivedQty: `${arrivedBoxQty}/${expectedBoxQty}`,
    discrepancyQty: arrivedBoxQty - expectedBoxQty
  }];
  return {
    data,
    columns
  };
};