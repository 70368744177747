import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { removeEmTag, toast } from "common/components/ui";
import { useIntl } from "react-intl";
import history from "BrowserHistory";
import { useStepMetadata } from "inbounds/hooks/useStepMetadata";
import { MissingBarcodeSteps, MISSING_BARCODE_STEPS } from "../MissingBarcodeCase/MissingBarcodeSteps";
import { useFetchMissingBarcodeCase, useFetchProduct, useFetchShippingPlan } from "inbounds/hooks";
import { sellerProblemsViewClient } from "Clients";
import { removeAllEmTags } from "common/StringUtils";
import log from "Logger";
import RouteParser from "common/RouteParser";
import { nonComplianceInternalRoutes as routes } from "inbounds/non-compliance";
import { isNil } from "lodash";
export const useMissingBarcodeCaseContextProvider = () => {
  const {
    nonCompliantId
  } = useParams();
  const {
    formatMessage
  } = useIntl();
  const [isRecognizedProduct, setIsRecognizedProduct] = useState(true);
  const [foundProduct, setFoundProduct] = useState();
  const [selectedBarcode, setSelectedBarcode] = useState();
  const stepMetadata = useStepMetadata(MISSING_BARCODE_STEPS);
  const {
    value: missingBarcodeCase,
    isLoading: isCaseLoading
  } = useFetchMissingBarcodeCase({
    caseId: nonCompliantId
  });
  const {
    value: shippingPlan
  } = useFetchShippingPlan({
    shippingPlanId: missingBarcodeCase?.shippingPlanId
  });
  const {
    value: productDetails,
    isLoading: isSelectedProductBarcodesLoading
  } = useFetchProduct({
    dsku: foundProduct?.dsku
  });
  const selectedProductBarcodes = productDetails?.barcodes ?? [];
  useEffect(() => {
    // default the selected barcode to the first option
    const firstProductBarcode = selectedProductBarcodes?.[0];
    if (firstProductBarcode !== undefined) {
      setSelectedBarcode(firstProductBarcode);
    }
  }, [selectedProductBarcodes]);
  const {
    setStepIsValid
  } = stepMetadata;
  const onSelectIsRecognizedProduct = ({
    target: {
      value
    }
  }) => setIsRecognizedProduct(value === "true");
  const onProductSelect = selectedProduct => {
    setFoundProduct(isNil(selectedProduct) ? undefined : {
      ...selectedProduct,
      name: removeEmTag(selectedProduct?.name ?? ""),
      msku: removeEmTag(selectedProduct?.msku ?? ""),
      dsku: removeEmTag(selectedProduct?.dsku ?? "")
    });
    setStepIsValid({
      stepKey: MissingBarcodeSteps.IDENTIFY_PRODUCT,
      isValid: false
    });
  };
  const onSubmitProduct = () => {
    setStepIsValid({
      stepKey: MissingBarcodeSteps.IDENTIFY_PRODUCT,
      isValid: true
    });
  };
  const onBarcodeSelect = ({
    value
  }) => {
    setSelectedBarcode(value);
    setStepIsValid({
      stepKey: MissingBarcodeSteps.SELECT_BARCODE,
      isValid: false
    });
  };
  const [{
    loading: isOnResolveLoading
  }, onResolve] = useAsyncFn(async () => {
    const ctx = {
      missingBarcodeCase,
      foundProduct
    };
    if (!missingBarcodeCase) {
      log.error({
        ...ctx
      }, "resolution called without missing barcode case");
      return;
    } else if (!foundProduct || !selectedBarcode) {
      return;
    } else {
      log.info({
        ...ctx
      }, "resolving missing barcode case");
      try {
        await sellerProblemsViewClient.completeMissingBarcodeProductIdentification({
          sellerProblemId: missingBarcodeCase.sellerProblemId,
          dsku: removeAllEmTags(foundProduct.dsku),
          barcode: removeAllEmTags(selectedBarcode)
        });
        history.push(new RouteParser(routes.missingBarcodeSuccess).parse({
          nonCompliantId: missingBarcodeCase.sellerProblemId
        }));
      } catch (error) {
        log.error({
          ...ctx,
          error
        }, "error missing barcode case");
        toast.error(formatMessage({
          id: "nonCompliance.missingBarcodeCase.resolutionError",
          defaultMessage: "There was an issue resolving your shipment problem"
        }));
      }
    }
  }, [missingBarcodeCase, foundProduct, selectedBarcode]);
  const [{
    loading: isOnDismissLoading
  }, onDismiss] = useAsyncFn(async () => {
    const ctx = {
      missingBarcodeCase
    };
    if (!missingBarcodeCase) {
      log.error({
        ...ctx
      }, "resolution called without missing barcode case");
    } else {
      log.info({
        ...ctx
      }, "dismissing missing barcode case");
      try {
        await sellerProblemsViewClient.failedToIdentifyProductForMissingBarcode(missingBarcodeCase.sellerProblemId);
        history.push(new RouteParser(routes.missingBarcodeSuccess).parse({
          nonCompliantId: missingBarcodeCase.sellerProblemId
        }));
      } catch (error) {
        log.error({
          ...ctx,
          error
        }, "error dismissing missing barcode case");
        toast.error(formatMessage({
          id: "nonCompliance.missingBarcodeCase.resolutionError",
          defaultMessage: "There was an issue resolving your shipment problem"
        }));
      }
    }
  }, [missingBarcodeCase]);
  return {
    missingBarcodeCase,
    isCaseLoading,
    shippingPlan,
    selectedProductBarcodes,
    isSelectedProductBarcodesLoading,
    isRecognizedProduct,
    setIsRecognizedProduct: onSelectIsRecognizedProduct,
    foundProduct,
    onProductSelect,
    selectedBarcode,
    onBarcodeSelect,
    onDismiss,
    isOnDismissLoading,
    onSubmitProduct,
    onResolve,
    isOnResolveLoading,
    stepMetadata
  };
};