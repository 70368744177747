import { defineMessages } from "react-intl";
export const storageFreightPackingInstructionsLabels = defineMessages({
  description: {
    id: "storage.freight.instructions.description",
    defaultMessage: "Review packing instructions"
  },
  maxHeight: {
    id: "storage.freight.instructions.maxHeight",
    defaultMessage: `Pallet height, including the pallet, must be shorter than {maxHeight} inches. Pallets taller than 60" are re-palletized at an additional cost.`
  },
  maxWeight: {
    id: "storage.freight.instructions.maxWeight",
    defaultMessage: `Each pallet must weigh less than {maxWeight}lbs.`
  },
  shrinkWrap: {
    id: "storage.freight.instructions.shrinkWrap",
    defaultMessage: "Shrink wrap should go on the outside of the pallet to keep all the boxes secure."
  },
  woodenPallets: {
    id: "storage.freight.instructions.woodenPallets",
    defaultMessage: "Four-way pallets must be used. Pallets must be in good condition."
  },
  palletLabel: {
    id: "storage.freight.instructions.confirmButton.label",
    defaultMessage: "Each pallet in the shipment must have a unique pallet label."
  }
});