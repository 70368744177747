import { parseBookingType } from "../../../utils/parseBookingType";
export const getDestinationState = booking => {
  const {
    isByFlexport,
    isByAmazon,
    isDTCNetwork,
    isP2P
  } = parseBookingType(booking.bookingType);
  return {
    destinationSelect: {
      isByFlexport,
      isByAmazon,
      isDTCNetwork,
      isOwnDestination: isP2P
    }
  };
};