import { wholesaleClient } from "../../Clients";
import { updateExternalAttributesIfNeeded } from "../../transfers/create/actions/patchWholesaleOrder";
const patchFbaReferenceId = async (shipment, fbaReferenceId, orderId) => {
  const patchRequest = {
    externalAttributes: updateExternalAttributesIfNeeded(shipment, undefined, fbaReferenceId)
  };
  await wholesaleClient.patchShipment(orderId, shipment.id, patchRequest);
};
export const updateFbaReferenceIdForAllShipmentInOrder = (fbaReferenceIdMap, wholesaleOrder) => async () => {
  await Promise.all(wholesaleOrder.shipments.map(async shipment => {
    const fbaReferenceId = fbaReferenceIdMap.get(shipment.id);
    fbaReferenceId && (await patchFbaReferenceId(shipment, fbaReferenceId, wholesaleOrder.id));
  }));
};
export const updateFbaReferenceIdForShipment = (fbaReferenceId, shipment, orderId) => async () => {
  await patchFbaReferenceId(shipment, fbaReferenceId, orderId);
};