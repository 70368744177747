export let StorageInboundStep;
(function (StorageInboundStep) {
  StorageInboundStep["SELECT_INBOUND_TYPE"] = "SELECT_INBOUND_TYPE";
  StorageInboundStep["STORAGE_SELECT_PRODUCTS"] = "STORAGE_SELECT_PRODUCTS";
  StorageInboundStep["STORAGE_ADD_LOT_INFORMATION"] = "STORAGE_ADD_LOT_INFORMATION";
  StorageInboundStep["STORAGE_ADD_CASE_DIMENSIONS"] = "STORAGE_ADD_CASE_DIMENSIONS";
  StorageInboundStep["STORAGE_ADD_PRODUCTS_BARCODE"] = "STORAGE_ADD_PRODUCTS_BARCODE";
  StorageInboundStep["STORAGE_FROM_ADDRESS"] = "STORAGE_FROM_ADDRESS";
  StorageInboundStep["STORAGE_DESTINATION_WAREHOUSE"] = "STORAGE_DESTINATION_WAREHOUSE";
  StorageInboundStep["STORAGE_SELECT_SHIPPING"] = "STORAGE_SELECT_SHIPPING";
  StorageInboundStep["STORAGE_ADD_PICKUP_DETAILS"] = "STORAGE_ADD_PICKUP_DETAILS";
  StorageInboundStep["STORAGE_CONFIRM_SHIPMENT"] = "STORAGE_CONFIRM_SHIPMENT";
  StorageInboundStep["STORAGE_SHIPMENT_CONFIRMED"] = "STORAGE_SHIPMENT_CONFIRMED";
})(StorageInboundStep || (StorageInboundStep = {}));