export let TransferListActionTypes;
(function (TransferListActionTypes) {
  TransferListActionTypes["TRANSFER_LIST_GET"] = "TRANSFER_LIST_GET";
  TransferListActionTypes["TRANSFER_LIST_GET_SUCCESS"] = "TRANSFER_LIST_GET_SUCCESS";
  TransferListActionTypes["TRANSFER_LIST_GET_ERROR"] = "TRANSFER_LIST_GET_ERROR";
  TransferListActionTypes["TRANSFER_LIST_CHANGE_PAGE_NUM"] = "TRANSFER_LIST_CHANGE_PAGE_NUM";
  TransferListActionTypes["TRANSFER_LIST_CHANGE_SEARCH_TERM"] = "TRANSFER_LIST_CHANGE_SEARCH_TERM";
  TransferListActionTypes["TRANSFER_LIST_CHANGE_DESTINATION_SEARCH_FILTERS"] = "TRANSFER_LIST_CHANGE_DESTINATION_SEARCH_FILTERS";
  TransferListActionTypes["TRANSFER_LIST_CHANGE_STATUS_SEARCH_FILTERS"] = "TRANSFER_LIST_CHANGE_STATUS_SEARCH_FILTERS";
  TransferListActionTypes["DELETE_TRANSFER_ORDER_SUCCESS"] = "DELETE_TRANSFER_ORDER_SUCCESS";
  TransferListActionTypes["TRANSFER_LIST_SET_TAB_FILTER"] = "TRANSFER_LIST_SET_TAB_FILTER";
  TransferListActionTypes["UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER"] = "UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER";
})(TransferListActionTypes || (TransferListActionTypes = {}));