import { useDispatch, useSelector } from "react-redux";
import { removeAllEmTags } from "common/StringUtils";
import { getStorageInboundDetail, updateStorageInboundOrder } from "../store";
import { useIntl } from "react-intl";
export const useStorageNavbarShippingPlanName = () => {
  const dispatch = useDispatch();
  const {
    formatMessage
  } = useIntl();
  const defaultMessage = formatMessage({
    id: "storage.inbounds.shipmentHeader.title",
    defaultMessage: "Reserve Storage shipment"
  });
  const {
    storageInboundOrder,
    isLoading
  } = useSelector(getStorageInboundDetail);
  const handleSubmit = async editableName => {
    const name = removeAllEmTags(editableName);
    if (name?.length > 0 && storageInboundOrder && name !== storageInboundOrder.shippingPlanName) {
      dispatch(updateStorageInboundOrder({
        ...storageInboundOrder,
        shippingPlanName: name
      }));
    }
  };
  return {
    displayName: storageInboundOrder?.shippingPlanName ?? defaultMessage,
    isLoading,
    handleSubmit
  };
};