import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { ReferralProgramsError } from "./ReferralProgramsError";
export class ReferralProgramsClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.REFERRAL_PROGRAMS_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/`
    });
  }
  async getReferralProgramData(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/participant/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ReferralProgramsError.PARTICIPANT_NOT_FOUND]);
    }
  }
}