import { FastTagType } from "common/clients/fastTag/FastTagType";
import { getSellerId } from "common/user/UserSelectors";
import { Path } from "paths";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { unsyncAds } from "tags/facebook/edit/actions/unsyncAds";
import { unsyncStatus as getUnsyncStatus } from "../../state/unsyncStatus";
export const useProcessUnsync = () => {
  const dispatch = useDispatch();
  const sellerId = useSelector(getSellerId);
  const unsyncStatus = useSelector(getUnsyncStatus);
  const history = useHistory();
  const onDoneClick = () => {
    history.push(generatePath(Path.fastTagDetail, {
      program: FastTagType.FACEBOOK
    }));
  };
  useEffectOnce(() => {
    dispatch(unsyncAds(sellerId));
  });
  return {
    onDoneClick,
    unsyncStatus
  };
};