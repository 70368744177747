/**
 * @generated SignedSource<<ff2509815133d143013b581c88969771>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

const node = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    }],
    v1 = [{
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }],
      "concreteType": "AddressLatLng",
      "kind": "LinkedField",
      "name": "addressLatLngSearch",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lat",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lng",
        "storageKey": null
      }],
      "storageKey": null
    }];
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "useCargoDetailsStepLatLngQuery",
      "selections": v1 /*: any*/,
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "useCargoDetailsStepLatLngQuery",
      "selections": v1 /*: any*/
    },

    "params": {
      "cacheID": "0f07f173f02446cb65d6f1a2aa04a6d4",
      "id": null,
      "metadata": {},
      "name": "useCargoDetailsStepLatLngQuery",
      "operationKind": "query",
      "text": "query useCargoDetailsStepLatLngQuery(\n  $query: String!\n) {\n  addressLatLngSearch(query: $query) {\n    lat\n    lng\n  }\n}\n"
    }
  };
}();
node.hash = "2fb46d766674c44d5b16943955318a37";
export default node;