import produce from "immer";
import { LtlActionTypes } from "../actions/ltl/LtlActionTypes";
import { LTL_PALLET_INITIAL_WIDTH, LTL_PALLET_INITIAL_LENGTH } from "inbounds/steps/ship/ltl/util";
export const initialPallet = {
  dimensionUnit: "in",
  identicalPallets: 0,
  width: LTL_PALLET_INITIAL_WIDTH,
  length: LTL_PALLET_INITIAL_LENGTH,
  height: "",
  // prevent a default value
  weight: "",
  // prevent a default value
  weightUnit: "lb"
};
export const initialDeliverrLtlShipment = {
  billOfLading: "",
  hasConfirmedPallets: false,
  hasConfirmedPickupInfo: false,
  hasConfirmedShipperInfo: false,
  hasConfirmedRateQuote: false,
  hasConfirmedReviewShipment: false,
  hasConfirmedShipmentSummary: false,
  hasDownloadedBol: false,
  numberOfPallets: 0,
  totalCargoWeight: 0,
  palletConfigurations: [initialPallet],
  pickupInfo: {
    pickupDate: undefined,
    weightClass: null,
    declaredValue: undefined,
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    liftGate: false,
    customerReferenceNumber: "",
    facilityOperatingHours: {
      startTime: "",
      endTime: ""
    },
    preferredPickupWindow: {
      startTime: "",
      endTime: ""
    }
  },
  quotes: [],
  selectedQuoteId: 0
};
export const LtlReducer = {
  // FREIGHT PALLETS STEP
  [LtlActionTypes.LTL_ADD_PALLET_CONFIG]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      const currentLtlShipment = ltl[loadedShipmentId];
      currentLtlShipment.hasConfirmedPallets = false;
      currentLtlShipment.hasConfirmedPickupInfo = false; // to collapse the quote card
      currentLtlShipment.hasConfirmedShipperInfo = false;
      currentLtlShipment.hasConfirmedRateQuote = false;
      currentLtlShipment.quotes = [];
      currentLtlShipment.selectedQuoteId = 0;
      currentLtlShipment.palletConfigurations.push(initialPallet);
    });
  },
  [LtlActionTypes.LTL_REMOVE_PALLET_CONFIG]: (state, {
    index
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      const currentLtlShipment = ltl[loadedShipmentId];
      currentLtlShipment.hasConfirmedPallets = false;
      currentLtlShipment.hasConfirmedPickupInfo = false; // to collapse the quote card
      currentLtlShipment.hasConfirmedRateQuote = false;
      currentLtlShipment.quotes = [];
      currentLtlShipment.selectedQuoteId = 0;
      currentLtlShipment.palletConfigurations.splice(index, 1);
    });
  },
  [LtlActionTypes.LTL_SET_PALLET_CONFIG]: (state, {
    index,
    name,
    value
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      const currentLtlShipment = ltl[loadedShipmentId];
      currentLtlShipment.hasConfirmedPallets = false;
      currentLtlShipment.hasConfirmedPickupInfo = false; // to collapse the quote card
      currentLtlShipment.hasConfirmedRateQuote = false;
      currentLtlShipment.quotes = [];
      currentLtlShipment.selectedQuoteId = 0;
      currentLtlShipment.palletConfigurations[index][name] = value;
    });
  },
  [LtlActionTypes.LTL_REPLACE_PALLET_CONFIGS]: (state, {
    palletConfigurations
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      ltl[loadedShipmentId].palletConfigurations = palletConfigurations;
    });
  },
  [LtlActionTypes.LTL_CONFIRM_PALLETS]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        const currentLtlShipment = ltl[loadedShipmentId];
        currentLtlShipment.hasConfirmedPallets = true;
        currentLtlShipment.hasConfirmedPickupInfo = false; // to collapse the quote card
      }
    });
  },

  // FREIGHT PICKUP DETAILS & CONTACT INFO
  [LtlActionTypes.FREIGHT_SET_PICKUP_DETAILS]: (state, {
    pickupDetails
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      const currentShipment = ltl[loadedShipmentId];

      // reset stale quote data if applicable
      currentShipment.hasConfirmedPickupInfo = false;
      currentShipment.hasConfirmedRateQuote = false;
      currentShipment.quotes = [];
      currentShipment.selectedQuoteId = 0;
      currentShipment.pickupInfo = {
        ...currentShipment.pickupInfo,
        ...pickupDetails
      };
    });
  },
  // DEPRECATED
  [LtlActionTypes.LTL_SET_PICKUP_DETAILS]: (state, {
    name,
    value
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      const currentLtlShipment = ltl[loadedShipmentId];
      // TODO: reset stale quote data if applicable
      currentLtlShipment.hasConfirmedRateQuote = false;
      currentLtlShipment.quotes = [];
      currentLtlShipment.selectedQuoteId = 0;

      // update value
      currentLtlShipment.pickupInfo[name] = value;
    });
  },
  // DEPRECATED
  [LtlActionTypes.LTL_SET_IS_LIFT_GATE]: (state, {
    checked
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      const currentLtlShipment = ltl[loadedShipmentId];
      // reset stale quote data if applicable
      currentLtlShipment.hasConfirmedPickupInfo = false;
      currentLtlShipment.hasConfirmedRateQuote = false;
      currentLtlShipment.quotes = [];
      currentLtlShipment.selectedQuoteId = 0;
      currentLtlShipment.pickupInfo.liftGate = checked;
    });
  },
  // DEPRECATED
  [LtlActionTypes.LTL_SET_READY_DATE]: (state, {
    readyDate
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (!loadedShipmentId) {
        return;
      }
      const currentLtlShipment = ltl[loadedShipmentId];
      // reset stale quote data if applicable
      currentLtlShipment.hasConfirmedPickupInfo = false;
      currentLtlShipment.hasConfirmedRateQuote = false;
      currentLtlShipment.quotes = [];
      currentLtlShipment.selectedQuoteId = 0;
      currentLtlShipment.pickupInfo.pickupDate = readyDate;
    });
  },
  // to advance to next step in LTL card flow
  [LtlActionTypes.LTL_CONFIRM_PICKUP_DETAILS]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].hasConfirmedPickupInfo = true;
      }
    });
  },
  [LtlActionTypes.LTL_CONFIRM_SHIPPER_DETAILS]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].hasConfirmedShipperInfo = true;
      }
    });
  },
  // SHIPPING OPTION QUOTES
  [LtlActionTypes.LTL_SET_QUOTES]: (state, {
    quotes
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].quotes = quotes;

        // standard shipping option should always be defaulted
        // also preselects in the event of only one quote being loaded
        ltl[loadedShipmentId].selectedQuoteId = quotes[0].id;
      }
    });
  },
  [LtlActionTypes.LTL_SET_SELECTED_QUOTE_ID]: (state, {
    quoteId
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].selectedQuoteId = quoteId;
      }
    });
  },
  [LtlActionTypes.LTL_CONFIRM_RATE_QUOTE]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].hasConfirmedRateQuote = true;
      }
    });
  },
  [LtlActionTypes.LTL_ROLLBACK_QUOTES]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        // close the quotes card and force to resubmit pickup info to restart quote fetching
        ltl[loadedShipmentId].hasConfirmedPickupInfo = false;
        ltl[loadedShipmentId].hasConfirmedRateQuote = false;
        ltl[loadedShipmentId].quotes = [];
      }
    });
  },
  // REVIEW SHIPMENT
  [LtlActionTypes.LTL_CONFIRM_REVIEW_SHIPMENT]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].hasConfirmedReviewShipment = true;
      }
    });
  },
  // SHIPMENT SUMMARY
  [LtlActionTypes.LTL_CONFIRM_SHIPMENT_SUMMARY]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].hasConfirmedShipmentSummary = true;
      }
    });
  },
  // BILL OF LADING
  [LtlActionTypes.LTL_SET_BILL_OF_LADING]: (state, {
    bol
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].billOfLading = bol;
      }
    });
  },
  [LtlActionTypes.LTL_SET_HAS_DOWNLOADED_BOL]: state => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].hasDownloadedBol = true;
      }
    });
  },
  [LtlActionTypes.LTL_SET_NUMBER_OF_PALLETS]: (state, {
    numberOfPallets
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].numberOfPallets = numberOfPallets;
      }
    });
  },
  [LtlActionTypes.LTL_SET_TOTAL_CARGO_WEIGHT]: (state, {
    totalCargoWeight
  }) => {
    return produce(state, ({
      ltl,
      loadedShipmentId
    }) => {
      if (loadedShipmentId) {
        ltl[loadedShipmentId].totalCargoWeight = totalCargoWeight;
      }
    });
  }
};