export const CARRIER_SELECT_ALGOLIA_CONFIG = {
  indexName: process.env.ALGOLIA_INDEX_CARRIERS,
  searchConfig: {
    hitsPerPage: 10,
    maxValuesPerFacet: 3
  }
};
export function processRawAlgoliaResultsToOptions(data, filterFn) {
  const options = data?.hits ? data.hits.map(result => ({
    label: result.name,
    value: result.code
  })) : [];
  return filterFn ? options.filter(filterFn) : options;
}
export function carrierCodeToOption(carrierCode) {
  return carrierCode ? {
    value: carrierCode,
    label: carrierCode
  } : undefined; // TODO! Fix this to actually resolve the label
}

export const lookupCarrier = carrierCode => {
  return data => {
    if (!carrierCode) {
      return;
    }
    const results = processRawAlgoliaResultsToOptions(data, option => option.value === carrierCode);
    return results?.[0];
  };
};
export const CARRIER_INPUT_DEBOUNCE_INTERVAL = 150;