import { DistributionChannel } from "@deliverr/replenishment-client";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
export const getDistributionChannel = destinationType => {
  switch (destinationType) {
    case TransferCreationTypes.Fba:
      return DistributionChannel.FBA_MANUAL;
    case TransferCreationTypes.FbaIntegration:
      return DistributionChannel.FBA;
    case TransferCreationTypes.Wfs:
      return DistributionChannel.WFS;
    case TransferCreationTypes.Wholesale:
      return DistributionChannel.WHOLESALE;
    default:
      return DistributionChannel.ECOM_VIA_CROSSDOCK;
  }
};