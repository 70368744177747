import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { integratedFbaValidations } from "./integratedFbaValidations";
import { fbaValidations } from "./fbaValidations";
export const getWarningForProductFn = destinationType => {
  if (!destinationType) {
    return undefined;
  }
  switch (destinationType) {
    case TransferCreationTypes.FbaIntegration:
      return integratedFbaValidations;
    case TransferCreationTypes.Fba:
      return fbaValidations;
    default:
      return undefined;
  }
};