import { ApiError } from "@deliverr/commons-objects";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { OrderExceptions } from "./OrderExceptions";
export const defaultOrdersClientConfig = () => {
  if (process.env.FULFILLMENT_SERVICE_URL) {
    return {
      baseURL: `${process.env.FULFILLMENT_SERVICE_URL}/v1/`
    };
  } else {
    throw new Error(`Unable to get default orders client config, environment variable FULFILLMENT_SERVICE_URL could not be found`);
  }
};
export class OrdersClient {
  constructor({
    baseURL
  } = defaultOrdersClientConfig()) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async cancelOrder(orderId, cancelSource) {
    try {
      return await this.apiClient.delete({
        url: `/workflow/order/${orderId}`,
        body: {
          cancelSource
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      if (error.response?.data?.error === OrderExceptions.FAILED_CANCEL_AT_WAREHOUSE) {
        throw new Error(OrderExceptions.FAILED_CANCEL_AT_WAREHOUSE);
      }
      switch (error.response?.status) {
        case 400:
          throw new Error(OrderExceptions.ORDER_SHIPPED);
        case 404:
          throw new Error(OrderExceptions.NOT_FOUND);
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async createOrder(orderData) {
    try {
      return await this.apiClient.post({
        url: "/order",
        body: orderData,
        authentication: isAuthenticated
      });
    } catch (error) {
      switch (error.response?.status) {
        case 400:
          throw new Error(OrderExceptions.INVALID_ADDRESS);
        case 409:
          throw new Error(OrderExceptions.DUPLICATE_ORDER);
        case 410:
          throw new Error(OrderExceptions.STOCKOUT);
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }
  async getBulkOrderPreview(signedUrl) {
    return await this.apiClient.get({
      url: `/orders/bulk/preview?signedUrl=${signedUrl}`,
      authentication: isAuthenticated
    });
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async getOrder(orderId) {
    try {
      return await this.apiClient.get({
        url: `/workflow/order/${orderId}`,
        authentication: isAuthenticated
      });
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          return undefined;
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }
  async importBulkOrder(signedUrl, sellerId) {
    return await this.apiClient.post({
      url: `/orders/bulk`,
      body: {
        sellerId,
        signedUrl
      },
      authentication: isAuthenticated
    });
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async updateShippingAddress(orderId, address, forceUpdate = false) {
    try {
      return await this.apiClient.put({
        url: `/order/${orderId}/address`,
        body: {
          address,
          forceUpdate
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      switch (error.response?.status) {
        case 400:
          throw new Error(OrderExceptions.ORDER_SHIPPED);
        case 404:
          throw new Error(OrderExceptions.NOT_FOUND);
        case 422:
          throw new Error(OrderExceptions.INVALID_ADDRESS);
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }
}