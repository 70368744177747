import { createActionCreator } from "common/ReduxUtils";
import { CreateShipmentActionTypes, CreateShipmentFormActionTypes } from "../CreateShipmentActionTypes";

/**
 * This file is for simple store updates that can be used with handleSimpleReducerUpdates.
 * Do not include any data transformations or api calls here, use a seperate file for complex updates.
 * */

export const setOrigin = createActionCreator(CreateShipmentFormActionTypes.SET_ORIGIN, "origin");
export const setIsLiftgateRequired = createActionCreator(CreateShipmentFormActionTypes.SET_IS_LIFTGATE_REQUIRED, "isLiftgateRequired");
export const setDeliveryDate = createActionCreator(CreateShipmentFormActionTypes.SET_DELIVERY_DATE, "deliveryDate");
export const setUnitSystem = createActionCreator(CreateShipmentFormActionTypes.SET_UNIT_SYSTEM, "unitSystem");
export const setShipmentTotals = createActionCreator(CreateShipmentFormActionTypes.SET_SHIPMENT_TOTALS, "shipmentTotals");
export const setHasDangerousGoods = createActionCreator(CreateShipmentFormActionTypes.SET_HAS_DANGEROUS_GOODS, "hasDangerousGoods");
export const setDangerousGoods = createActionCreator(CreateShipmentFormActionTypes.SET_DANGEROUS_GOODS, "dangerousGoods");
export const setCargoReadyDate = createActionCreator(CreateShipmentFormActionTypes.SET_CARGO_READY_DATE, "cargoReadyDate");
export const setAmazonDestinationType = createActionCreator(CreateShipmentFormActionTypes.SET_AMAZON_DESTINATION_TYPE, "amazonDestinationType");
export const setAmazonAddress = createActionCreator(CreateShipmentFormActionTypes.SET_AMAZON_ADDRESS, "amazonAddress");
export const setReserveStorage = createActionCreator(CreateShipmentFormActionTypes.SET_RESERVE_STORAGE, "reserveStorage");
export const setCrossdockWarehouse = createActionCreator(CreateShipmentFormActionTypes.SET_CROSSDOCK_WAREHOUSE, "crossdockWarehouse");
export const setFreightService = createActionCreator(CreateShipmentFormActionTypes.SET_FREIGHT_SERVICE, "freightService");
export const setOriginType = createActionCreator(CreateShipmentFormActionTypes.SET_ORIGIN_TYPE, "originType");
export const setDomesticDestinationType = createActionCreator(CreateShipmentFormActionTypes.SET_DOMESTIC_DESTINATION_TYPE, "domesticDestinationType");
export const setDistributionType = createActionCreator(CreateShipmentFormActionTypes.SET_DISTRIBUTION_TYPE, "distributionType");
export const setSupplier = createActionCreator(CreateShipmentFormActionTypes.SET_SUPPLIER, "supplier");
export const setOriginFacilityName = createActionCreator(CreateShipmentFormActionTypes.SET_ORIGIN_FACILITY_NAME, "originFacilityName");
export const setDestinationFacilityName = createActionCreator(CreateShipmentFormActionTypes.SET_DESTINATION_FACILITY_NAME, "destinationFacilityName");
export const setDestinationAddressFid = createActionCreator(CreateShipmentFormActionTypes.SET_DESTINATION_ADDRESS_FID, "destinationAddressFid");
export const setHasCargoInsurance = createActionCreator(CreateShipmentFormActionTypes.SET_HAS_CARGO_INSURANCE, "hasCargoInsurance");
export const setAmazonPrepType = createActionCreator(CreateShipmentFormActionTypes.SET_AMAZON_PREP_TYPE, "amazonPrepType");
export const setInsuranceInfo = createActionCreator(CreateShipmentFormActionTypes.SET_INSURANCE_INFO, "insuranceInfo");
export const setBookingProducts = createActionCreator(CreateShipmentFormActionTypes.SET_BOOKING_PRODUCTS, "bookingProducts");
export const setAirShipmentCargoDetails = createActionCreator(CreateShipmentFormActionTypes.SET_AIR_SHIPMENT_CARGO_DETAILS, "airShipmentCargoDetails");
export const setHasAnnualCustomsBond = createActionCreator(CreateShipmentFormActionTypes.SET_HAS_ANNUAL_CUSTOMS_BOND, "hasAnnualCustomsBond");
export const setFlexportCapitalSelected = createActionCreator(CreateShipmentFormActionTypes.SET_FLEXPORT_CAPITAL_SELECTED, "flexportCapitalSelected");
export const setLithiumBatteryPackaging = createActionCreator(CreateShipmentFormActionTypes.SET_LITHIUM_BATTERY_PACKAGING, "lithiumBatteryPackaging");
export const setIsOriginPort = createActionCreator(CreateShipmentFormActionTypes.SET_IS_ORIGIN_PORT, "isOriginPort");
export const setCargoInputType = createActionCreator(CreateShipmentFormActionTypes.SET_CARGO_INPUT_TYPE, "cargoInputType");
export const setIsCargoMixedSku = createActionCreator(CreateShipmentFormActionTypes.SET_IS_CARGO_MIXED_SKU, "isCargoMixedSku");
export const setDtcPrepOption = createActionCreator(CreateShipmentFormActionTypes.SET_DTC_PREP_OPTION, "dtcPrepOption");
export const setMixedSkuPackages = createActionCreator(CreateShipmentFormActionTypes.SET_MIXED_SKU_PACKAGES, "mixedSkuPackages");

// META STATE - PLACE FORM STATE UPDATES ABOVE THIS LINE
export const setStepStack = createActionCreator(CreateShipmentActionTypes.SET_STEP_STACK, "stepStack");
export const setShippingPlanId = createActionCreator(CreateShipmentActionTypes.SET_SHIPPING_PLAN_ID, "shippingPlanId");
export const setIsSaving = createActionCreator(CreateShipmentActionTypes.SET_IS_SAVING, "isSaving");
export const hideIpbDevTools = createActionCreator(CreateShipmentActionTypes.HIDE_IPB_DEV_TOOLS, "isIpbDevToolsHidden");
export const setIsLoading = createActionCreator(CreateShipmentActionTypes.SET_IS_LOADING, "isLoading");
export const setIsReturningUser = createActionCreator(CreateShipmentActionTypes.SET_IS_RETURNING_USER, "isReturningUser");