import { ExternalDestinationType } from "@deliverr/wholesale-client";
import { extractFbaShipmentId } from "./extractFbaShipmentId";
export function isFbaIntegration(wholesaleOrder) {
  return wholesaleOrder.externalDestinationType === ExternalDestinationType.FBA && (wholesaleOrder.createdAutomatically ?? false);
}
export function isFbaMultipleShipments(wholesaleOrder) {
  return isFbaIntegration(wholesaleOrder) && (wholesaleOrder.shipments?.length ?? 0) > 1;
}
export function getConcatenatedFbaShipmentIdsFromWholesaleOrder(wholesaleOrder) {
  return wholesaleOrder?.shipments?.map(shipment => extractFbaShipmentId(shipment)).filter(fbaShipmentId => fbaShipmentId !== undefined).join(", ");
}