import { useDispatch, useSelector } from "react-redux";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { setIsLimitedAccess } from "./actions/setIsLimitedAccess";
import { setIsResidential } from "./actions/setIsResidential";
export const useFreightAddressType = () => {
  const dispatch = useDispatch();
  const {
    isLimitedAccess,
    isResidential
  } = useSelector(getStorageInboundCreate);
  const handleIsResidentialChange = value => {
    dispatch(setIsResidential(value));
  };
  const handleIsLimitedAccessChange = value => {
    dispatch(setIsLimitedAccess(value));
  };
  return {
    handleIsResidentialChange,
    handleIsLimitedAccessChange,
    isLimitedAccess,
    isResidential
  };
};