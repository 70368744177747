import { add } from "date-fns";
import { isObject } from "lodash/fp";
import log from "Logger";
export let LocalStorageKey;
(function (LocalStorageKey) {
  LocalStorageKey["CoverageOption"] = "DSKU_COVERAGE";
  LocalStorageKey["WarehouseToCoordinates"] = "warehouseToCoordinates";
  LocalStorageKey["FulfillmentFeeInventoryDetail"] = "FulfillmentFeeInventoryDetail";
  LocalStorageKey["FulfillmentFeeInventoryList"] = "FulfillmentFeeInventoryList";
  LocalStorageKey["FulfillmentFeeInventoryListSort"] = "FulfillmentFeeInventoryListSort";
  LocalStorageKey["FulfillmentFeeSelectInboundProducts"] = "FulfillmentFeeSelectInboundProducts";
  LocalStorageKey["OnboardingChannel"] = "OnboardingChannel";
  LocalStorageKey["HasMultichannelsStorageId"] = "HasMultichannelsStorageId";
  LocalStorageKey["IntegrationSetupContext"] = "IntegrationSetupContext";
  LocalStorageKey["BoxContentsPreferences"] = "boxContentsPreferences";
  LocalStorageKey["PalletLabelPreferences"] = "palletLabelPreferences";
  LocalStorageKey["FlexportPlusPopUpModalDoNotShowAgain"] = "flexportPlusPopUpModalDoNotShowAgain";
  LocalStorageKey["FlexportCapitalBannerDoNotShowAgain"] = "flexportCapitalBannerDoNotShowAgain";
  LocalStorageKey["FlexportOnboardingCompletedModalDoNotShowAgain"] = "flexportOnboardingCompletedModalDoNotShowAgain";
  LocalStorageKey["FlexportIntlFreightBannerDoNotShowAgain"] = "flexportIntlFreightBannerDoNotShowAgain";
  LocalStorageKey["FlexportPrepRequestBannerDoNotShowAgain"] = "flexportPrepRequestBannerDoNotShowAgain";
  LocalStorageKey["NewFulfillmentRatesBannerDisplayed"] = "newFulfillmentRatesBannerDisplayed";
  LocalStorageKey["WarehouseMoveBannerDisplayed"] = "warehouseMoveBannerDisplayed";
  LocalStorageKey["KittingPrepLaunchBannerClosed"] = "KittingPrepLaunchBannerClosed";
  LocalStorageKey["NewInventoryReportsNotificationDisplayedInReports"] = "newInventoryReportsNotificationDisplayedInReports";
  LocalStorageKey["NewInventoryReportsNotificationDisplayedInInventoryDetail"] = "newInventoryReportsNotificationDisplayedInInventoryDetail";
  LocalStorageKey["PeakSeason2024BannerDisplayed"] = "peakSeason2024BannerDisplayed";
})(LocalStorageKey || (LocalStorageKey = {}));
export const getItemFromLocalStorageAndParse = key => {
  try {
    const serializedItem = localStorage.getItem(key);
    if (serializedItem) {
      const parsedItem = JSON.parse(serializedItem);
      if (!isObject(parsedItem)) {
        return parsedItem;
      }
      if (!parsedItem.expiry) {
        return parsedItem.item ?? parsedItem;
      }
      if (Date.now() < parsedItem.expiry) {
        return parsedItem.item;
      }
    }
  } catch (err) {
    log.error({
      fn: "getItemFromLocalStorageAndParse",
      err
    }, "error deserializing item");
  }
  return;
};
export const setItemInLocalStorage = (key, item, expiryDays) => {
  try {
    localStorage.setItem(key, JSON.stringify({
      item,
      expiry: expiryDays ? add(new Date(), {
        days: expiryDays
      }).valueOf() : undefined
    }));
  } catch (err) {
    log.error({
      fn: "setItemInLocalStorage",
      err
    }, "error setting item in storage");
  }
};
export const maybeGet = async (key, getItem, expiryDays) => {
  const savedItem = getItemFromLocalStorageAndParse(key);
  if (savedItem) {
    return savedItem;
  }
  const item = await getItem();
  if (item) {
    setItemInLocalStorage(key, item, expiryDays);
  }
  return item;
};