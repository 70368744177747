import { defineMessages } from "react-intl";
export const replenishmentOrderLabels = defineMessages({
  error: {
    id: "transfers.common.error",
    defaultMessage: "An error occurred while contacting the server."
  },
  readyToShipTooltip: {
    id: "transfers.common.readyToShip.tooltip",
    defaultMessage: "Action needed"
  },
  status: {
    created: {
      id: "transfers.common.pill.status.created",
      defaultMessage: "Created"
    },
    draft: {
      id: "transfers.common.pill.status.draft",
      defaultMessage: "Draft"
    },
    processing: {
      id: "transfers.common.pill.status.processing",
      defaultMessage: "Processing"
    },
    readyToShip: {
      id: "transfers.common.pill.status.readyToShip",
      defaultMessage: "Ready to ship"
    },
    inTransit: {
      id: "transfers.common.pill.status.inTransit",
      defaultMessage: "In transit"
    },
    delivered: {
      id: "transfers.common.pill.status.delivered",
      defaultMessage: "Delivered"
    },
    shipped: {
      id: "transfers.common.pill.status.shipped",
      defaultMessage: "Shipped"
    },
    archived: {
      id: "transfers.common.pill.status.archived",
      defaultMessage: "Archived"
    },
    scheduled: {
      id: "transfers.common.pill.status.scheduled",
      defaultMessage: "Scheduled"
    },
    unknown: {
      id: "transfers.common.pill.status.unknown",
      defaultMessage: "Unknown"
    },
    cancelled: {
      id: "transfers.common.pill.status.cancelled",
      defaultMessage: "Canceled"
    },
    submitting: {
      id: "transfers.common.pill.status.cancelled",
      defaultMessage: "Submitting"
    },
    arrived: {
      id: "transfers.common.pill.status.arrived",
      defaultMessage: "Arrived"
    },
    receiving: {
      id: "transfers.common.pill.status.receiving",
      defaultMessage: "Receiving"
    },
    completed: {
      id: "transfers.common.pill.status.completed",
      defaultMessage: "Completed"
    },
    error: {
      id: "transfers.common.pill.status.error",
      defaultMessage: "Error"
    },
    submitted: {
      id: "transfers.common.pill.status.submitted",
      defaultMessage: "Submitted"
    }
  },
  breadcrumbs: {
    transfers: {
      id: "transfers.common.breadcrumbs.transfers",
      defaultMessage: "Replenishments"
    },
    createTransfer: {
      id: "transfers.common.breadcrumbs.createTransfer",
      defaultMessage: "Create Transfer"
    },
    createTransferToFBA: {
      id: "transfers.common.breadcrumbs.createTransferToFBA",
      defaultMessage: "Create Transfer to Amazon FBA"
    },
    createTransferToWfs: {
      id: "transfers.common.breadcrumbs.createTransferToWfs",
      defaultMessage: "Create transfer to Walmart Wfs"
    }
  }
});