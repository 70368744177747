import { InternationalRateCountryList } from "common/components/CountrySelect/Countries";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
export const placeToBookingAddress = (place, name, isLiftgateRequired, originIsPort) => {
  const {
    address
  } = place.value;
  const defaultValue = originIsPort ? "N/A" : "";
  return {
    name: name?.length ? name : address.name,
    street1: address.street1 || defaultValue,
    city: address.city ?? "",
    zip: address.zip || defaultValue,
    state: address.state || defaultValue,
    country: InternationalRateCountryList[address.country] ?? address.country,
    optionLabel: place.label,
    latLng: address.latLng ?? undefined,
    countryCode: isEmpty(address.countryCode) ? (address.country in InternationalRateCountryList ? address.country : Object.keys(InternationalRateCountryList).find(k => InternationalRateCountryList[k] === address.country)) ?? "" : address.countryCode,
    isLiftgateRequired
  };
};
export const mapAmazonAddressToBookingAddress = amazonAddress => {
  if (!amazonAddress) {
    return null;
  }
  const {
    name,
    city,
    state,
    street1,
    street2,
    zip,
    country,
    company,
    phone,
    email
  } = amazonAddress ?? {};
  if (isEmpty(name) || isEmpty(city) || isEmpty(state) || isEmpty(street1) || isEmpty(country)) {
    return null;
  }
  return {
    name,
    city,
    state,
    street1,
    street2,
    zip,
    country,
    company,
    phone,
    email,
    latLng: !isNil(amazonAddress.latitude) && !isNil(amazonAddress.longitude) ? {
      lat: amazonAddress.latitude,
      lng: amazonAddress.longitude
    } : undefined
  };
};
export const mapDtcPackageCollectionToPackageSummary = dtcPackages => {
  return Object.values(dtcPackages).flat();
};
export const mapPackagesummaryToInboundPackageSummary = dtcPackages => {
  return dtcPackages.map(pkg => {
    return {
      ...pkg,
      id: undefined,
      casePackDsku: undefined,
      dimensionUnit: pkg.dimensionUnit ?? "in",
      weightUnit: pkg.weightUnit ?? "lb"
    };
  });
};
export const mapSingleSkuInboundPackageSummaryToDtcPackageCollection = packages => {
  const singleSkuPackages = {};
  packages.forEach(pkg => {
    const dsku = pkg.items?.[0]?.dsku;
    if (!singleSkuPackages[dsku]) {
      singleSkuPackages[dsku] = [];
    }
    singleSkuPackages[dsku].push(pkg);
  });
  return singleSkuPackages;
};