import { OpenSearchService } from "common/search/services/OpenSearchService";
export class BundleOpenSearchService extends OpenSearchService {
  buildSearchOptions(request) {
    const requestWithSortProperties = {
      ...request,
      ...{
        sort: request.sort && {
          ...request.sort,
          missing: "_last",
          unmappedType: "long"
        }
      }
    };
    const searchOptions = super.buildSearchOptions(requestWithSortProperties);
    if (this.config.searchConfig.highlightMatches) {
      return {
        ...searchOptions,
        // By default, the seller portal search for inventory
        // targets name, msku, bsku
        highlight: {
          fields: {
            name: {},
            msku: {},
            bsku: {}
          }
        },
        hydrate: true
      };
    }
    return searchOptions;
  }
}