import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
import { createSelector } from "reselect";
import { getStorageInboundCreate } from "./getStorageInboundCreate";
export const getSelectedProducts = createSelector(getStorageInboundCreate, getProductDetailsCache, (storageInboundCreate, productDetailsCache) => {
  const {
    selectedProducts,
    productDetails
  } = storageInboundCreate;
  return Object.keys(selectedProducts).map(dsku => ({
    ...(productDetailsCache[dsku] ?? {}),
    ...productDetails[dsku],
    ...selectedProducts[dsku]
  }));
});