import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export class PricerClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.PRICER_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getItemizedPriceForOrderPerUnitDetail(itemizedPriceForOrderRequest) {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForOrderPerUnitDetail`,
      body: itemizedPriceForOrderRequest,
      authentication: isAuthenticated
    });
  }
  async getItemizedPriceForMultipleItems(itemizedPriceForMultipleItemsRequest) {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForMultipleItems`,
      body: itemizedPriceForMultipleItemsRequest,
      authentication: isAuthenticated
    });
  }
  async getItemizedPriceForMultipleOrders(itemizedPriceForMultipleOrdersRequest) {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForMultipleOrders`,
      body: itemizedPriceForMultipleOrdersRequest,
      authentication: isAuthenticated
    });
  }
  async getItemizedPriceForMultipleOrdersPerUnitDetail(itemizedPriceForMultipleOrdersRequest) {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForMultipleOrdersPerUnitDetail`,
      body: itemizedPriceForMultipleOrdersRequest,
      authentication: isAuthenticated
    });
  }
  async getTotalRemovalPrice(totalRemovalPriceRequest) {
    return await this.apiClient.post({
      url: `/pricer/getTotalRemovalPrice`,
      body: totalRemovalPriceRequest,
      authentication: isAuthenticated
    });
  }
  async getServiceLevelFromOrders(orders) {
    return await this.apiClient.post({
      url: `/pricer/getServiceLevelFromOrders`,
      body: orders,
      authentication: isAuthenticated
    });
  }
  async getServiceLevelFromOrder(order) {
    return await this.apiClient.post({
      url: `/pricer/getServiceLevelFromOrder`,
      body: order,
      authentication: isAuthenticated
    });
  }
  async getAccountPricePlanAssignments(sellerId, cutoffInDays, isRateChangeNotificationRequired) {
    return await this.apiClient.get({
      url: `/pricePlan/v1/assignments`,
      params: {
        sellerId,
        cutoffInDays,
        rateChangeNotificationRequired: isRateChangeNotificationRequired
      },
      authentication: isAuthenticated
    });
  }
  async getRateCardUrl(sellerId, referenceDate) {
    return await this.apiClient.get({
      url: `/pricePlan/v1/rateCard`,
      params: {
        sellerId,
        referenceDate
      },
      authentication: isAuthenticated
    });
  }
}