import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { claimClient } from "common/clients/instances";
export class UploadClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.UPLOAD_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/upload`
    });
  }
  async uploadFile(target, file) {
    const presignedData = await this.getS3PresignedData({
      target,
      contentType: file.type,
      title: file.name
    });
    await this.sendFormData(presignedData, file);
    return presignedData.fields.Key;
  }
  async uploadProof(file, sellerId, index) {
    const response = await claimClient.uploadUrl(file.type, file.name, sellerId, index);
    await this.uploadFileToS3(response.presignedUrl, file);
    let key = `claim/${file.name}`;
    if (response.key) {
      key = response.key;
    }
    return key;
  }
  async uploadFileToS3(url, file) {
    const headers = new Headers();
    return await fetch(url, {
      method: "PUT",
      headers,
      body: file,
      mode: "cors"
    });
  }
  async deleteFile(path) {
    return await this.apiClient.delete({
      url: "/delete",
      body: {
        path
      },
      authentication: isAuthenticated
    });
  }
  async createSignedUrl(target, key) {
    return await this.apiClient.post({
      url: `/download/${target}`,
      body: {
        key
      },
      authentication: isAuthenticated
    });
  }
  async getS3PresignedData(req) {
    return await this.apiClient.post({
      url: req.target,
      body: {
        contentType: req.contentType,
        title: req.title
      },
      authentication: isAuthenticated
    });
  }
  async sendFormData(presignedData, file) {
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach(key => {
      formData.append(key, presignedData.fields[key]);
    });

    // file must be last field
    formData.append("file", file);
    await this.apiClient.post({
      url: presignedData.url,
      body: formData
    });
  }
}