import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
export const useFreightPalletTable = ({
  addPalletConfig,
  removePalletConfig,
  updatePalletConfig,
  hasMaxConfig,
  hasChangesLocked,
  palletSum,
  weightSum,
  palletConfigurations
}) => {
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();
  const addRow = () => dispatch(addPalletConfig());
  const removeRow = index => dispatch(removePalletConfig(index));
  const handlePalletConfigChange = palletIndex => e => {
    const {
      name,
      value
    } = e.target;
    dispatch(updatePalletConfig(palletIndex, name, Number(value)));
  };
  const dimensions = formatMessage({
    id: "freightPalletTable.dimensions",
    defaultMessage: "Dimensions (in)"
  });
  const numberOfPallets = formatMessage({
    id: "freightPalletTable.numberOfPallets",
    defaultMessage: "# of Pallets"
  });
  const total = formatMessage({
    id: "freightPalletTable.totalWeight",
    defaultMessage: "Total"
  });
  const totalWeight = formatMessage({
    id: "freightPalletTable.totalWeight",
    defaultMessage: "Total Weight"
  });
  const weight = formatMessage({
    id: "freightPalletTable.weight",
    defaultMessage: "Weight / Pallet (lbs)"
  });
  return {
    addRow,
    dimensions,
    handlePalletConfigChange,
    hasMaxConfig,
    numberOfPallets,
    palletConfigurations,
    palletSum,
    hasChangesLocked,
    removeRow,
    total,
    totalWeight,
    weight,
    weightSum
  };
};