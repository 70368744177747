import { DeliverrError } from "@deliverr/commons-objects";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { reformatScopesForRenderingByPermissions } from "./getUniqueScopesByPermission";
export class LogisticsApiClient {
  constructor(logisticsApiClientConfig = {
    baseURL: mustBeDefined(process.env.LOGISTICS_API_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${logisticsApiClientConfig.baseURL}/logistics/api/unstable`
    });
  }
  async getIsSandboxAccount() {
    try {
      return await this.apiClient.get({
        url: `/accounts/isSandboxAccount`,
        authentication: isAuthenticated
      });
    } catch (ex) {
      throw new DeliverrError({
        code: ex.response.data.code,
        functionName: "getIsSandboxAccount",
        payload: ex.response.data
      });
    }
  }
  async getAllInstalledApplications() {
    try {
      const installedApp = await this.apiClient.get({
        url: `/installed_applications`,
        authentication: isAuthenticated
      });
      return installedApp.map(item => ({
        ...item,
        uniqueScopes: reformatScopesForRenderingByPermissions(item.scopes.split(" "))
      }));
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "getAllInstalledApplications",
            payload: ex.response.data
          });
      }
    }
  }
  async saveInstalledApplication(createData) {
    try {
      const reformatScope = {
        displayName: createData.displayName,
        scopes: createData.scopes.join(" ")
      };
      return await this.apiClient.post({
        url: `/installed_applications`,
        body: reformatScope,
        authentication: isAuthenticated
      });
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "saveInstalledApplication",
            payload: ex.response.data
          });
      }
    }
  }
  async updateInstalledApplication(installedApplicationId, displayName) {
    try {
      return await this.apiClient.put({
        url: `/installed_applications/${installedApplicationId}/update`,
        body: {
          displayName
        },
        authentication: isAuthenticated
      });
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "updateInstalledApplication",
            payload: ex.response.data
          });
      }
    }
  }
  async disableInstalledApplication(installedApplicationId) {
    try {
      return await this.apiClient.put({
        url: `/installed_applications/${installedApplicationId}/disable`,
        authentication: isAuthenticated
      });
    } catch (ex) {
      switch (ex.response.status) {
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "disableInstalledApplication",
            payload: ex.response.data
          });
      }
    }
  }
}