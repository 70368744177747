import { useIntl } from "react-intl";
import { useWholesaleConfirmCostsColumns } from "wholesale/create/steps/useWholesaleConfirmCostsColumns";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { sumBy } from "lodash";
export const useReplenishmentOrderPricingSummary = (replenishmentOrderEstimates, totalAggregationAltText) => {
  const {
    formatMessage
  } = useIntl();
  const aggregatorOptions = {
    totalRowLabel: formatMessage(wholesaleCreateLabels.steps.confirm.totalRowLabel)
  };
  const columns = useWholesaleConfirmCostsColumns();
  const data = [...(replenishmentOrderEstimates?.charges.map(charge => ({
    rowLabel: formatMessage(charge.feeDescription),
    quantity: charge.unitCount,
    unitLabel: charge.unitOfMeasure,
    unitCost: charge.unitCost,
    amount: charge.totalCost
  })) ?? []), {}];
  const costTableProps = {
    subTables: [{
      data: data,
      columns,
      aggregatorOptions
    }],
    totalAggregation: totalAggregationAltText ?? sumBy(replenishmentOrderEstimates?.charges, x => x.totalCost)
  };
  return costTableProps;
};