import { compact, fromPairs, omit } from "lodash/fp";
export function createActionCreator(type, name1, name2, name3) {
  const argNames = compact([name1, name2, name3]);
  return (...args) => {
    const action = {
      type
    };
    argNames.forEach((_, i) => action[argNames[i]] = args[i]);
    return action;
  };
}
export const createReducer = (initialState, reducerDict) => (state = initialState, action) => reducerDict[action.type] ? reducerDict[action.type](state, action) : state;
const simpleReducerCase = (state, action) => ({
  ...state,
  ...omit(["type"], action)
});
export const handleSimpleReducerUpdates = actionTypes => fromPairs(actionTypes.map(actionType => [actionType, simpleReducerCase]));