import { createSelector } from "reselect";
import { selectCreateShipmentForm } from "./selectCreateShipmentForm";
import { UnitPrepService } from "inbounds/createShipment/CreateShipmentTypes";
import { PrepType } from "@deliverr/prep-service-client";
// Transfer DtcPreOption type (frontend) to PrepType | PrepCombo type (backend), we're not able to use backend type directly because it's not compatible with UI logic.
export const selectInboundDtcPrepOption = createSelector(selectCreateShipmentForm, ({
  dtcPrepOption
}) => {
  const prepTypes = [];
  let prepCombo;
  if (dtcPrepOption.boxLabelingUnitsSelected) {
    prepTypes.push(PrepType.BOX_CONTENT_LABELS);
  }
  if (dtcPrepOption.unitPrepService === UnitPrepService.UNIT_BARCODING) {
    prepTypes.push(PrepType.SKU_LABELS);
  } else {
    prepCombo = dtcPrepOption.unitPrepService ?? undefined;
  }
  return {
    prepTypes,
    prepCombo
  };
});