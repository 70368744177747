import { logError } from "Logger";

/**
 * Converts a PrepJobResponse to an InboundPrepRequest.
 * @param prepJobResponse
 * @returns InboundPrepRequestData
 */
export const mapPrepJobResponseToPrepRequest = prepJobResponse => {
  const {
    prepTypes,
    dpId,
    sourceType,
    sourceId
  } = prepJobResponse;
  const baseRequest = {
    dpId,
    prepTypes
  };
  if (sourceType !== "SHIPPING_PLAN" || !Number.isInteger(+sourceId)) {
    // this should never happen, but logging for visibility
    logError({
      fn: mapPrepJobResponseToPrepRequest.name,
      dpId,
      sourceType,
      sourceId
    }, new Error("Invalid sourceType or sourceId!"));
    return {
      ...baseRequest,
      shippingPlanId: -1
    };
  }
  return {
    prepTypes,
    dpId,
    shippingPlanId: +sourceId
  };
};