/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useState } from "react";
import { compact, find, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableInventoryInEachStorageWarehouse } from "transfers/create/store/getAvailableInventoryInEachStorageWarehouse";
import { transferBulkAddProduct, transferCreateAddProduct, transferCreateRemoveProduct, transferCreateResetProductSelection, transferCreateSetIsEachPickingOrderFlag } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { extractPacksDims } from "storage/packUtils/extractPacksDims";
import { useFeatureOn, FeatureName } from "common/Split";
import { validateWeightAndDims } from "storage/packUtils/validateWeightAndDims";
import { getDistributionChannel } from "transfers/create/actions/replenishmentOrder/utils/getDistributionChannel";
import { extractBulkUploadValidationErrors } from "components/BulkUpload/useBulkUploadModal";
import { BulkUploadError } from "components/BulkUpload/BulkUploadError";
import { DistributionChannel, EntityError, EntityName } from "@deliverr/replenishment-client";
export const useTransferPickProductStep = () => {
  const dispatch = useDispatch();
  const {
    selectedProducts,
    destinationType,
    isEachPickingOrder
  } = useSelector(selectTransferCreate);
  const dskuQuantities = Object.keys(selectedProducts).map(dsku => ({
    dsku
  }));
  const [shouldShowModal, setShowModal] = useState(false);
  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const isNextDisabled = isEmpty(selectedProducts) || !!find(selectedProducts, val => validateWeightAndDims(extractPacksDims(val)));
  const distributionChannel = getDistributionChannel(destinationType);
  const isWholesaleOrderEachPickingOn = useFeatureOn(FeatureName.WholesaleOrderEachPicking);
  const isEachPickingEnabled = distributionChannel === DistributionChannel.WHOLESALE && isWholesaleOrderEachPickingOn;
  const addProduct = (dsku, product) => {
    const dskuList = [...dskuQuantities, {
      dsku
    }];
    dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));
    dispatch(transferCreateAddProduct(dsku, product));
  };
  const removeProduct = dsku => {
    const dskuList = dskuQuantities.filter(dskuQuantity => dskuQuantity.dsku !== dsku);
    dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));
    dispatch(transferCreateRemoveProduct(dsku));
  };
  const handleReplenishmentBulkUploadSuccess = useCallback(async inputProducts => {
    if (!inputProducts) {
      return;
    }
    dispatch(transferCreateResetProductSelection());
    let skuProductMap = {};
    const productsWithoutInventory = [];
    for (const product of inputProducts) {
      skuProductMap = {
        ...skuProductMap,
        [product.dsku]: {
          ...product,
          storageOnHandQty: product.onHandQty,
          storageUnfilledOrderQty: product.unfilledOrderQty,
          storageUnavailableQty: product.unavailableQty,
          objectID: product.dsku
        }
      };
    }
    if (!isEmpty(productsWithoutInventory)) {
      throw new EntityError({
        code: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
        entity: EntityName.REPORT,
        message: "Inventory unavailable for selected products in CSV",
        errors: productsWithoutInventory.map(product => new EntityError({
          code: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
          entity: EntityName.ORDER_ITEM,
          entityId: product.msku,
          payload: product
        }))
      });
    }
    const dskuList = Object.values(skuProductMap).map(product => ({
      dsku: product?.dsku
    }));
    dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));
    dispatch(transferBulkAddProduct(skuProductMap));
  }, [dispatch, isMultiCasePackEnabled]);
  const handleProductImportFailure = (err, setValidationErrors) => {
    if (err instanceof EntityError && err.code === BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE) {
      setValidationErrors(extractBulkUploadValidationErrors({
        [BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE]: {
          cells: compact(map(err.errors, ({
            entityId
          }) => entityId)),
          isWholeSheetError: false,
          error: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE
        }
      }));
    } else {
      throw err;
    }
  };
  const handleEachPickingToggle = () => {
    dispatch(transferCreateSetIsEachPickingOrderFlag(!isEachPickingOrder));
  };
  return {
    isEachPickingEnabled,
    isEachPickingOrder,
    shouldShowModal,
    setShowModal,
    addProduct,
    selectedProducts: Object.values(selectedProducts),
    removeProduct,
    isNextDisabled,
    dskuQuantities,
    isMultiCasePackEnabled,
    destinationType,
    distributionChannel,
    handleReplenishmentBulkUploadSuccess,
    handleProductImportFailure,
    handleEachPickingToggle
  };
};