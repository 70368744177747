import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export class CrossdockClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.CROSSDOCK_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async createInboundQuote({
    sellerId,
    shippingPlanId,
    ...body
  }) {
    return await this.apiClient.post({
      url: `/v1/inbound/quote/${sellerId}/${shippingPlanId}`,
      body,
      authentication: isAuthenticated
    });
  }
  async getActiveInboundQuoteByShippingPlan(sellerId, shippingPlanId) {
    return await this.apiClient.get({
      url: `/v1/inbound/quote/${sellerId}/plan/${shippingPlanId}`,
      authentication: isAuthenticated
    });
  }
  async getInboundShipmentByShippingPlan(sellerId, shippingPlanId) {
    return await this.apiClient.get({
      url: `/v1/inbound/shipment/${sellerId}/plan/${shippingPlanId}`,
      authentication: isAuthenticated
    });
  }
}