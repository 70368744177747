import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";
export const selectBookingDestinationRequest = () => createSelector(selectBookingRequest(false), bookingRequest => {
  const {
    bookingType,
    flexibleDeliveryDate,
    targetDeliveryDate,
    destinationIsPort,
    destinationPortGrouping,
    destinationPortFid,
    destinationAddressFid,
    to
  } = bookingRequest;
  const bookingDestinationRequest = {
    bookingType: bookingType,
    flexibleDeliveryDate,
    targetDeliveryDate,
    destinationIsPort,
    destinationPortGrouping,
    destinationPortFid,
    destinationAddressFid,
    to
  };
  return bookingDestinationRequest;
});