import { getChannelAttribute } from "../../../../channels/edit/ChannelAttributes/useChannelAttributes";
import { useSelector } from "react-redux";
import { getIntegrations } from "../../../../channels/ChannelsSelectors";
import { ListingSolution } from "../../../../common/clients/onboarding/ListingSolution";
import { SalesChannelConnectionStatus } from "../../../../common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
import { ChannelAttributesCode } from "../../../../common/clients/onboarding/SalesChannel/ChannelAttributesCode";
import history from "BrowserHistory";
import { generatePath } from "react-router-dom";
import { Path } from "paths";
export const useAmazonChannelIntegrationStatus = () => {
  const isFbaImportEnabled = amazonChannel => {
    const amazonSettings = getChannelAttribute(amazonChannel, ChannelAttributesCode.AMAZON_SETTINGS);
    return amazonSettings?.attributes ? amazonSettings.attributes.find(attr => attr.code === "FBA_IMPORT")?.booleanValue ?? false : false;
  };
  const integrations = useSelector(getIntegrations);
  const amazonChannel = integrations.find(channel => channel.salesChannelId === ListingSolution.AMAZON);
  const isAmazonConnected = amazonChannel?.connectionStatus === SalesChannelConnectionStatus.CONNECTED;
  const isFbaCatalogSynced = isAmazonConnected && isFbaImportEnabled(amazonChannel);
  const onFbaConnectClick = () => history.push(`${generatePath(Path.channelsIntegrationSetup, {
    channel: ListingSolution.AMAZON
  })}?state=FBA`);
  return {
    isAmazonConnected,
    isFbaCatalogSynced,
    onFbaConnectClick
  };
};