import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { isValidDsku } from "./isValidDsku";
import { useCreateMoreAdSetsModal } from "./useCreateMoreAdSetsModal";
import { fastTagPagePath } from "tags/model/path/fastTagPagePath";
import { fastTagServiceLabel } from "tags/model/fastTagServiceLabel";
export const deliverySpeedSnippet = {
  1: {
    id: "FacebookFastTag.ConfigureTargetedAds.1DayOptionLabel",
    defaultMessage: "NextDay"
  },
  2: {
    id: "FacebookFastTag.ConfigureTargetedAds.2DayOptionLabel",
    defaultMessage: "2-Day"
  }
};
const noDeliverySpeedSnippetTitle = {
  id: "FacebookFastTag.ConfigureTargetedAds.NoDeliverySpeedSnippetTitle",
  defaultMessage: "1_or_2-day"
};
const noDskuSnippetTitle = {
  id: "FacebookFastTag.ConfigureTargetedAds.NoDeliverySpeedSnippetTitle",
  defaultMessage: "DSKU"
};
export const useConfigureAds = ({
  flow,
  page,
  state
}) => {
  const nextPath = fastTagPagePath(flow.getNextPage(page, state));
  const previousPath = fastTagPagePath(flow.getPreviousPage(page));
  const {
    formatMessage
  } = useIntl();
  const [product, setProduct] = useState("");
  const [deliverySpeed, setDeliverySpeed] = useState(undefined);
  const {
    showModal
  } = useCreateMoreAdSetsModal(nextPath);
  const isValidConfiguration = isValidDsku(product) && deliverySpeed !== undefined;
  const deliverySpeedOptions = [{
    value: 1,
    label: fastTagServiceLabel[1]
  }, {
    value: 2,
    label: fastTagServiceLabel[2]
  }];
  const deliverySpeedSnippetTitle = formatMessage(deliverySpeed ? deliverySpeedSnippet[deliverySpeed] : noDeliverySpeedSnippetTitle);
  const dskuSnippetTitle = product !== "" ? product : formatMessage(noDskuSnippetTitle);
  const adSetTitle = `Deliverr_${deliverySpeedSnippetTitle}_${dskuSnippetTitle}`;
  const selectedDeliverySpeed = deliverySpeedOptions.find(({
    value
  }) => value === deliverySpeed);
  const onDeliverySpeedChange = ({
    value
  }) => {
    setDeliverySpeed(value);
  };
  const onProductChange = e => {
    setProduct(e.currentTarget.value);
  };
  const onNextClick = () => {
    showModal();
  };
  const [isCopySnippetSeen, setCopySnippetSeen] = useState(false);
  useEffect(() => {
    if (isValidConfiguration && !isCopySnippetSeen) {
      setCopySnippetSeen(true);
    }
  }, [isValidConfiguration, isCopySnippetSeen]);
  return {
    adSetTitle,
    isCopySnippetSeen,
    deliverySpeed,
    deliverySpeedOptions,
    isValidConfiguration,
    nextPath,
    onDeliverySpeedChange,
    onNextClick,
    onProductChange,
    previousPath,
    product,
    selectedDeliverySpeed
  };
};