import { facebookClient } from "common/clients/instances";
import { logError } from "Logger";
import { adUnsyncCompleted } from "./adUnsyncCompleted";
import { adUnsyncErrored } from "./adUnsyncErrored";
export const unsyncAds = sellerId => async dispatch => {
  const ctx = {
    fn: "facebookUnsyncAds",
    sellerId
  };
  try {
    await facebookClient.syncSellerCreatedAdSets(sellerId);
    try {
      await facebookClient.refreshSeller(sellerId);
    } catch (err) {
      logError(ctx, err);
    }
    dispatch(adUnsyncCompleted());
  } catch (err) {
    logError(ctx, err);
    dispatch(adUnsyncErrored());
  }
};