import { DistributionChannel, IngestionChannel, NonComplianceStatus, ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { checkIsCancellable, checkIsPartiallyFulfillable } from "../utils";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { cancelReplenishmentOrder } from "transfers/create/actions/replenishmentOrder/cancelReplenishmentOrder";
import { cancelShipmentOutOfWindowWarningText, transferHeaderLabels } from "transfers/detail/transferHeaderLabels";
import { differenceInHours } from "date-fns";
import { batch, useDispatch, useStore } from "react-redux";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { toast } from "react-toastify";
import { transfersListLabels } from "transfers/list/transferShipmentsList.labels";
import { ReplenishmentLoader } from "../ReplenishmentLoader";
import { getReplenishmentOrder } from "./ReplenishmentOrderDetailAction";
import { map } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import { Path } from "paths";
import { isOutOfStockOrder } from "./out-of-stock-items/OutOfStockOrderUtils";
import { resolveOutOfStockOrderItems } from "transfers/create/actions/resolveOutOfStockOrderItems";
import { createOrUpdateReplenishmentOrder } from "transfers/create/actions/replenishmentOrder/createOrUpdateReplenishmentOrder";
import { submitReplenishmentOrder } from "transfers/create/actions/replenishmentOrder/submitReplenishmentOrder";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { setPartialFill } from "transfers/create/actions";
export const useReplenishmentOrderDetailsHeader = ({
  replenishmentOrder,
  nonCompliances
}) => {
  const dispatch = useDispatch();
  const {
    getState
  } = useStore();
  const warehouseId = selectTransferCreate(getState()).originStorageWarehouse?.warehouse?.id;
  const isCancellable = checkIsCancellable(replenishmentOrder);
  const isPartiallyFulfillable = checkIsPartiallyFulfillable(replenishmentOrder, warehouseId);
  const history = useHistory();
  const {
    formatMessage
  } = useIntl();
  const onCancelSelect = () => {
    setShowCancelModal(true);
  };
  const nonComplianceStatusOverride = getNonComplianceStatusOverride(nonCompliances);
  const isFBAIntegration = replenishmentOrder?.distributionChannel === DistributionChannel.FBA;
  const isWholesaleOrder = replenishmentOrder?.distributionChannel === DistributionChannel.WHOLESALE;
  const showDuplicateButton = isWholesaleOrder && replenishmentOrder?.ingestionChannel !== IngestionChannel.SPS;
  const [shouldShowCancelModal, setShowCancelModal] = useState(false);
  const onCancelModalConfirm = async () => {
    dispatch(addLoader(ReplenishmentLoader.ReplenishmentOrderDetailsLoader));
    dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
    const orderId = replenishmentOrder?.orderId;
    try {
      if (orderId) {
        await cancelReplenishmentOrder(orderId);
        await dispatch(getReplenishmentOrder(orderId));
        if (isOutOfStockOrder(replenishmentOrder)) {
          history.push(generatePath(Path.wholesaleDetailV2, {
            orderId
          }));
        }
      }
    } catch (err) {
      const messageElt = formatMessage({
        ...transfersListLabels.loadingError
      });
      toast.error(messageElt, {
        autoClose: 5000,
        toastId: "cancelReplenishmentOrderError"
      });
    } finally {
      dispatch(clearLoader(ReplenishmentLoader.ReplenishmentOrderDetailsLoader));
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
    }
  };
  const onDuplicate = () => {
    history.push(generatePath(Path.wholesaleDuplicate, {
      orderId: replenishmentOrder.orderId
    }));
  };
  const {
    shipmentActionsMenuLabels
  } = React.useMemo(() => {
    const shipmentActionsMenuLabelsCopy = {
      ...transferHeaderLabels
    };
    if (isFBAIntegration) {
      const isPartnered = replenishmentOrder.shipments?.[0].shippingPartner !== ShippingPartner.DELIVERR;
      if (isPartnered && replenishmentOrder.shipments?.[0].shippingType === ShippingType.PARCEL && differenceInHours(new Date(), new Date(replenishmentOrder.createdAt)) >= 24) {
        shipmentActionsMenuLabelsCopy.cancelShipmentWarningText = cancelShipmentOutOfWindowWarningText;
      }
    }
    return {
      shipmentActionsMenuLabels: shipmentActionsMenuLabelsCopy
    };
  }, [replenishmentOrder]);
  const onModalClose = () => {
    setShowCancelModal(false);
  };
  const onPartialFulfilClick = async () => {
    batch(() => {
      dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
      dispatch(setPartialFill(true));
    });
    const orderId = replenishmentOrder?.orderId;
    try {
      if (orderId && !!warehouseId && (await resolveOutOfStockOrderItems(dispatch, getState, true)) && (await createOrUpdateReplenishmentOrder(dispatch, getState, true)) && (await submitReplenishmentOrder(dispatch, getState))) {
        toast.success(formatMessage({
          id: "wholesale.detail.shipmentHeader.PartialFillSuccess",
          defaultMessage: "Request to partially fulfill order successfully submitted"
        }), {
          autoClose: 5000,
          toastId: "partialFillReplenishmentOrderSuccess"
        });
        history.push(generatePath(Path.wholesaleDetailV2, {
          orderId
        }));
      } else {
        toast.error(formatMessage({
          id: "wholesale.detail.shipmentHeader.PartialFillError",
          defaultMessage: "Error partially fulfilling order"
        }), {
          autoClose: 5000,
          toastId: "partialFillReplenishmentOrderError"
        });
      }
    } catch (err) {
      toast.error(formatMessage({
        id: "wholesale.detail.shipmentHeader.PartialFillError",
        defaultMessage: "Error partially fulfilling order"
      }), {
        autoClose: 5000,
        toastId: "partialFillReplenishmentOrderError"
      });
    } finally {
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
    }
  };
  return {
    onModalClose,
    nonComplianceStatusOverride,
    shipmentActionsMenuLabels,
    isCancellable,
    shouldShowCancelModal,
    onCancelModalConfirm,
    onCancelSelect,
    isFBAIntegration,
    replenishmentOrder,
    isWholesaleOrder,
    showDuplicateButton,
    isPartiallyFulfillable,
    onPartialFulfilClick,
    onDuplicate
  };
};
const getNonComplianceStatusOverride = nonCompliances => {
  const nonComplianceStatuses = map(nonCompliances, nonCompliance => nonCompliance.nonComplianceStatus);
  if (nonComplianceStatuses?.includes(NonComplianceStatus.CREATED)) {
    return NonComplianceStatus.CREATED;
  }
  if (nonComplianceStatuses?.includes(NonComplianceStatus.PENDING_VERIFICATION)) {
    return NonComplianceStatus.PENDING_VERIFICATION;
  }
  return null;
};