import { WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { filter, isEmpty } from "lodash";
import { mapProductsToMultiPackSkuInventory } from "storage/packUtils/mapProductsToMultiPackSkuInventory";
import { mapProductsToOrderItems } from "transfers/create/steps/common/mapProductsToOrderItems";
export const createWholesaleOrderShipment = (wholesaleOrder, selectedProductsQty, shippingMethod, cargoType, fbaShipmentId, fbaReferenceId, originStorageWarehouse, isMultiPackEnabled) => {
  if (isEmpty(wholesaleOrder.shipments)) {
    return {};
  }
  let shipmentItems;
  if (isMultiPackEnabled) {
    const inventoryBreakdown = mapProductsToMultiPackSkuInventory(selectedProductsQty, originStorageWarehouse?.inventoryBreakdown ?? {});
    shipmentItems = inventoryBreakdown.map(mapProductsToOrderItems);
  } else {
    shipmentItems = selectedProductsQty.map(mapProductsToOrderItems);
  }
  const shipmentResponse = wholesaleOrder.shipments[0];
  const shipment = {
    originWarehouseId: shipmentResponse.originWarehouseId,
    shipmentItems,
    warehouseOrderType: shipmentResponse.warehouseOrderType,
    shipmentType: cargoType ?? shipmentResponse.shipmentType,
    inputDestinationAddress: wholesaleOrder.inputDestination,
    carrierInfo: {
      ...shipmentResponse.carrierInfo,
      shippingMethod: shippingMethod ?? WholesaleShippingMethod.DELIVERR
    }
  };
  return {
    ...shipment,
    externalAttributes: getShipmentExternalAttributes(shipmentResponse, fbaShipmentId, fbaReferenceId)
  };
};
const getShipmentExternalAttributes = (shipment, fbaShipmentId, fbaReferenceId) => {
  const attributes = filter(shipment.externalAttributes, attr => attr.attributeName !== "shipmentId" && attr.attributeName !== "referenceId");
  if (fbaShipmentId) {
    attributes.push({
      attributeName: "shipmentId",
      attributeValue: fbaShipmentId
    });
  }
  if (fbaReferenceId) {
    attributes.push({
      attributeName: "referenceId",
      attributeValue: fbaReferenceId
    });
  }
  return attributes;
};