import styled from "@emotion/styled";
import { Title } from "common/components/ui";
export const ModifiedTitle = styled(Title)(({
  theme,
  space,
  appearance
}) => `
    margin-bottom: ${space ? theme.spacing[space] : 0};
    margin-top: 0;
    color: ${appearance && theme.colors[theme.config.colorByAppearance[appearance]]["300"]};
  `);