export const DEFAULT_INTERNATIONAL_WAREHOUSE_ADDRESS = {
  zip: "92408",
  city: "San Bernardino",
  name: "Flexport HUB",
  phone: "443-745-4235",
  state: "CA",
  country: "US",
  street1: "2615 E 3rd Street",
  street2: " ",
  latLng: {
    lat: 34.1046,
    lng: -117.27806
  }
};