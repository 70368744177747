import { notificationClient } from "common/clients/instances";
import { getIsLinkedToFlexport, getSellerId } from "common/user/UserSelectors";
import log, { logStart, logError } from "Logger";
import { fetchSmbNotifications } from "./FlexportUserNotification";
import { FeatureName, getFeatureSelector } from "../common/Split";
import { useSelector } from "react-redux";
export let NotificationsActionTypes;
(function (NotificationsActionTypes) {
  NotificationsActionTypes["FETCH_NOTIFICATIONS_START"] = "FETCH_NOTIFICATIONS_START";
  NotificationsActionTypes["FETCH_NOTIFICATIONS_SUCCESS"] = "FETCH_NOTIFICATIONS_SUCCESS";
  NotificationsActionTypes["FETCH_NOTIFICATIONS_ERROR"] = "FETCH_NOTIFICATIONS_ERROR";
  NotificationsActionTypes["VIEW_NOTIFICATIONS"] = "VIEW_NOTIFICATIONS";
  NotificationsActionTypes["RESET_NOTIFICATIONS"] = "RESET_NOTIFICATIONS";
})(NotificationsActionTypes || (NotificationsActionTypes = {}));
export const NOTIFICATIONS_PER_PAGE = 20;
export const fetchNotifications = () => async (dispatch, getState) => {
  const state = getState();
  if (!state.notifications.hasNextPage || state.notifications.loading) {
    return;
  }
  const sellerId = getSellerId(state);
  const limit = NOTIFICATIONS_PER_PAGE;
  const offset = state.notifications.nextPage * NOTIFICATIONS_PER_PAGE;
  const ctx = logStart({
    fn: "fetchNotifications"
  });
  dispatch({
    type: NotificationsActionTypes.FETCH_NOTIFICATIONS_START
  });
  try {
    const result = await notificationClient.getNotifications(sellerId, limit, offset);
    log.info(ctx, "successfully retrieved notifications");
    dispatch({
      type: NotificationsActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
      payload: result
    });
  } catch (err) {
    logError(ctx, err);
    dispatch({
      type: NotificationsActionTypes.FETCH_NOTIFICATIONS_ERROR
    });
  }
};
export const fetchBothNotifications = () => async (dispatch, getState) => {
  const state = getState();
  if (state.notifications.loading) {
    return;
  }
  const sellerId = getSellerId(state);
  const limit = NOTIFICATIONS_PER_PAGE;
  const ctx = logStart({
    fn: "fetchNotifications"
  });
  dispatch({
    type: NotificationsActionTypes.FETCH_NOTIFICATIONS_START
  });
  try {
    const dlvrNextPagePromise = fetchNextPage(state.notifications.dlvrPaginationParams, limit, async () => await notificationClient.getNotifications(sellerId, limit, state.notifications.dlvrPaginationParams.offset));
    const smbNexPagePromise = fetchNextPage(state.notifications.smbPaginationParams, limit, async () => await fetchSmbNotifications(sellerId, limit, state.notifications.smbPaginationParams.lastId));
    const [nextPageParamsDeliverr, nexPageParamsSMB] = await Promise.all([dlvrNextPagePromise, smbNexPagePromise]);
    log.info(ctx, "successfully retrieved notifications");
    const mergedNotifications = mergeNotifications(nextPageParamsDeliverr, nexPageParamsSMB);
    dispatch({
      type: NotificationsActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
      payload: mergedNotifications,
      dlvrPaginationParams: nextPageParamsDeliverr,
      smbPaginationParams: nexPageParamsSMB
    });
  } catch (err) {
    logError(ctx, err);
  }
};
export const fetchNextPage = async (paginationParams, limit, fetchFunction) => {
  const reserveListCopy = [...paginationParams.reserveList];
  if (paginationParams.reserveList.length >= limit || !paginationParams.hasNextPage) {
    return {
      ...paginationParams,
      reserveList: reserveListCopy
    };
  }
  try {
    const result = await fetchFunction();
    return {
      hasNextPage: result.length === limit,
      hasError: false,
      offset: paginationParams.offset + limit,
      reserveList: reserveListCopy.concat(result),
      lastId: result.length > 0 ? result[result.length - 1].id : paginationParams.lastId
    };
  } catch (e) {
    const ctx = {
      fn: "fetchNextPageError"
    };
    log.error({
      ...ctx,
      e
    }, "error in fetching ");
    return {
      ...paginationParams,
      hasError: true
    };
  }
};
export const mergeNotifications = (page1, page2) => {
  const mergedNotifications = [];
  // Merge a notification if and only if there is a loaded notification from the other stream
  // that was created after this. This will ensure that there are no notification in
  // the other stream that needs to be merged before current.
  while (page1.reserveList.length > 0 && page2.reserveList.length > 0) {
    if (page1.reserveList[0].createdAt > page2.reserveList[0].createdAt) {
      mergedNotifications.push(page1.reserveList.shift());
    } else {
      mergedNotifications.push(page2.reserveList.shift());
    }
  }
  if (!page1.hasNextPage || page1.hasError) {
    mergedNotifications.push(...page2.reserveList);
    page2.reserveList = [];
  }
  if (!page2.hasNextPage || page2.hasError) {
    mergedNotifications.push(...page1.reserveList);
    page1.reserveList = [];
  }
  return mergedNotifications;
};
export const resetNotifications = () => async (dispatch, getState) => {
  dispatch({
    type: NotificationsActionTypes.RESET_NOTIFICATIONS
  });
  const state = getState();
  const isSmbAccelerateOn = getFeatureSelector(FeatureName.SmbAccelerate)(state);
  const isSmbAccelerateNotificationsOn = isSmbAccelerateOn && getFeatureSelector(FeatureName.SmbAccelerateNotifications)(state);
  const isLinkedToFlexport = useSelector(getIsLinkedToFlexport);
  if (isSmbAccelerateNotificationsOn && isLinkedToFlexport) {
    dispatch(fetchBothNotifications());
  } else {
    dispatch(fetchNotifications());
  }
};