import { isEmpty, reduce, uniq, values } from "lodash";
import { getDefaultPackFromProduct } from "./getDefaultPackFromProduct";
// maps and aggregates order items with a mix of product and pack skus to only pack skus
export const mapReplenishItemsToPackSkus = (items, unifiedProducts) => {
  if (isEmpty(items)) {
    return [];
  }
  const dskus = uniq(items?.map(({
    dsku
  }) => dsku) ?? []);
  const productSkuToDefaultPackSku = values(dskus).reduce((agg, dsku) => {
    const product = unifiedProducts[dsku];
    if (product && !product.packOf) {
      agg[product.dsku] = getDefaultPackFromProduct(product)?.dsku;
    }
    return agg;
  }, {});
  const itemsWithPackSkus = items?.map(item => ({
    ...item,
    dsku: productSkuToDefaultPackSku[item.dsku] ?? item.dsku,
    totalUnits: item.totalUnits,
    unitsPerPack: item.unitsPerPack,
    packOf: item.packOf
  })) ?? [];
  return values(reduce(itemsWithPackSkus, (itemsByPackSkus, item) => {
    itemsByPackSkus[item.dsku] = {
      ...item,
      dsku: item.dsku,
      totalUnits: item.totalUnits + (itemsByPackSkus[item.dsku]?.totalUnits ?? 0),
      unitsPerPack: item.unitsPerPack,
      packOf: item.packOf
    };
    return itemsByPackSkus;
  }, {}));
};