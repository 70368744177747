import { wholesaleCreateAddNotes, wholesaleCreateChooseOriginWarehouse, wholesaleCreateChooseShippingConfig, wholesaleCreateReset, wholesaleSelectDestinationAddress, wholesaleSetPoNumber } from "../actions/WholesaleCreateActions";
import { WholesaleSelectAddressStep } from "./SelectAddressStep";
import { WholesalePickProductStep } from "./SelectProductsStep";
import { ConfirmStep } from "./ConfirmStep";
import { SelectProductQuantityStep } from "./SelectProductsQuantityStep";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { createOrUpdateWholesaleOrder } from "../actions/createOrUpdateWholesaleOrder";
import { WholesalePickShippingStep } from "./WholesalePickShippingStep";
import { noop } from "lodash";
import { LabelsStep } from "./LabelsStep";
import { submitWholesaleOrder } from "../actions/submitWholesaleOrder";
import { WholesalePickStorageWarehouseStep } from "./WholesalePickStorageWarehouseStep";
import { getWholesaleOrders } from "../../list/actions/WholesaleListAction";
import { batch } from "react-redux";
import { patchWholesaleOrder } from "../actions/patchWholesaleOrder";
import { fetchStorageProductsCaseInfo } from "../store/fetchStorageProductsCaseInfo";
import { SharedConfirmedStep } from "common/wholesale-common/components/SharedConfirmedStep";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import history from "BrowserHistory";
export let WholesaleSteps;
(function (WholesaleSteps) {
  WholesaleSteps["SelectAddress"] = "selectAddress";
  WholesaleSteps["SelectProduct"] = "selectProduct";
  WholesaleSteps["SelectQuantities"] = "SelectQuantities";
  WholesaleSteps["SelectStorageWarehouse"] = "SelectStorageWarehouse";
  WholesaleSteps["SelectShippingMethod"] = "selectShippingMethod";
  WholesaleSteps["Labels"] = "uploadLabels";
  WholesaleSteps["Confirm"] = "confirm";
  WholesaleSteps["Confirmed"] = "confirmed";
})(WholesaleSteps || (WholesaleSteps = {}));
export const WholesaleCreateInitialStep = WholesaleSteps.SelectAddress;
export const createWholesaleSteps = (dispatch, getState) => ({
  [WholesaleSteps.SelectAddress]: {
    component: WholesaleSelectAddressStep,
    async next({
      address,
      poNumber
    }) {
      batch(() => {
        dispatch(wholesaleSelectDestinationAddress(address));
        dispatch(wholesaleSetPoNumber(poNumber));
      });
      return WholesaleSteps.SelectProduct;
    },
    back() {
      dispatch(wholesaleCreateReset());
      history.goBack();
    }
  },
  [WholesaleSteps.SelectProduct]: {
    component: WholesalePickProductStep,
    async next() {
      const {
        availableInventory,
        selectedProducts
      } = selectWholesaleCreate(getState());
      await dispatch(fetchStorageProductsCaseInfo(Object.keys(selectedProducts)));
      if (availableInventory.length === 1) {
        dispatch(wholesaleCreateChooseOriginWarehouse(availableInventory[0]));
        return WholesaleSteps.SelectQuantities;
      }
      return WholesaleSteps.SelectStorageWarehouse;
    }
  },
  [WholesaleSteps.SelectStorageWarehouse]: {
    component: WholesalePickStorageWarehouseStep,
    next: async () => WholesaleSteps.SelectQuantities
  },
  [WholesaleSteps.SelectQuantities]: {
    component: SelectProductQuantityStep,
    next: async () => {
      createOrUpdateWholesaleOrder(dispatch, getState).then(noop, noop);
      return WholesaleSteps.SelectShippingMethod;
    }
  },
  [WholesaleSteps.SelectShippingMethod]: {
    component: WholesalePickShippingStep,
    async next(value) {
      const shipBy = value.selectedShipByDate ? value.selectedShipByDate.toISOString() : undefined;
      dispatch(wholesaleCreateChooseShippingConfig({
        shippingMethod: value.selectedShippingMethod,
        cargoType: value.selectedCargo,
        shipBy
      }));
      await patchWholesaleOrder(dispatch, getState);
      return WholesaleSteps.Labels;
    }
  },
  [WholesaleSteps.Labels]: {
    component: LabelsStep,
    async next({
      additionalNotes
    }) {
      dispatch(wholesaleCreateAddNotes(additionalNotes));
      patchWholesaleOrder(dispatch, getState).then(noop, noop);
      return WholesaleSteps.Confirm;
    }
  },
  [WholesaleSteps.Confirm]: {
    component: ConfirmStep,
    async next() {
      if (await submitWholesaleOrder(dispatch, getState)) {
        dispatch(getWholesaleOrders());
        return WholesaleSteps.Confirmed;
      }
      return;
    }
  },
  [WholesaleSteps.Confirmed]: {
    component: SharedConfirmedStep(wholesaleCreateLabels),
    hasNoBack: true
  }
});