import { PillColors } from "../../common/components/Pill";
export let FlexportShipmentStatus;
(function (FlexportShipmentStatus) {
  FlexportShipmentStatus["NEED_PACKAGE_DETAILS"] = "NEED_PACKAGE_DETAILS";
  FlexportShipmentStatus["READY_FOR_WAREHOUSE"] = "READY_FOR_WAREHOUSE";
  FlexportShipmentStatus["IN_TRANSIT"] = "IN_TRANSIT";
  FlexportShipmentStatus["IN_PREP"] = "IN_PREP";
  FlexportShipmentStatus["RECEIVING"] = "RECEIVING";
  FlexportShipmentStatus["COMPLETED"] = "COMPLETED";
  FlexportShipmentStatus["CANCELED"] = "CANCELED";
  FlexportShipmentStatus["IN_PROGRESS"] = "IN_PROGRESS";
  FlexportShipmentStatus["ACTION_NEEDED"] = "ACTION_NEEDED";
})(FlexportShipmentStatus || (FlexportShipmentStatus = {}));
export const flexportStatusPillColors = {
  [FlexportShipmentStatus.NEED_PACKAGE_DETAILS]: PillColors.GRAY,
  [FlexportShipmentStatus.READY_FOR_WAREHOUSE]: PillColors.BLUE,
  [FlexportShipmentStatus.IN_TRANSIT]: PillColors.BLUE,
  [FlexportShipmentStatus.IN_PREP]: PillColors.PURPLE,
  [FlexportShipmentStatus.RECEIVING]: PillColors.PURPLE,
  [FlexportShipmentStatus.COMPLETED]: PillColors.GREEN,
  [FlexportShipmentStatus.CANCELED]: PillColors.GRAY,
  [FlexportShipmentStatus.IN_PROGRESS]: PillColors.BLUE,
  [FlexportShipmentStatus.ACTION_NEEDED]: PillColors.RED
};