import { ListType } from "common/list";
import { AlgoliaSearchService } from "common/search/services/AlgoliaSearchService";
import { configFor } from "./services/SearchConfig";
import { InventorySearchService } from "common/search/services/InventorySearchService";
import { memoize } from "lodash";
import { ParcelSearchService } from "common/search/services/ParcelSearchService";
import { PrepSearchService } from "./services/PrepSearchService";
import { ClaimUploadsSearchService } from "./services/ClaimUploadsSearchService";
import { BundleOpenSearchService } from "./services/BundleOpenSearchService";
export const searchServiceFor = memoize(listType => {
  switch (listType) {
    case ListType.Inventory:
    case ListType.Bundle:
      return new AlgoliaSearchService(configFor(listType));
    case ListType.BundleV2:
      return new BundleOpenSearchService(configFor(listType));
    case ListType.InventoryV2:
      return new InventorySearchService(configFor(listType));
    case ListType.Parcel:
      return new ParcelSearchService(configFor(listType));
    case ListType.Prep:
      return new PrepSearchService(configFor(listType));
    case ListType.ClaimUploads:
      return new ClaimUploadsSearchService(configFor(listType));
    case ListType.Orders:
    case ListType.Inbounds:
    default:
      return new AlgoliaSearchService(configFor(listType));
  }
});