import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { CreateWholesaleLoader } from "../CreateWholesaleLoader.types";
import { selectWholesaleSelectedProductsQty } from "../store/selectWholesaleSelectedProductsQty";
import { sharedUpdateWholesaleOrder } from "../../../common/wholesale-common/sharedUpdateWholesaleOrder";
import { wholesaleCreateSetError, wholesaleCreateSetWholesaleOrder } from "./WholesaleCreateActions";
export const updateWholesaleOrder = sharedUpdateWholesaleOrder({
  createStateSelector: selectWholesaleCreate,
  productSelector: selectWholesaleSelectedProductsQty,
  loaderId: CreateWholesaleLoader.CreateWholesaleOrderLoader,
  setError: wholesaleCreateSetError,
  setValue: wholesaleCreateSetWholesaleOrder
});