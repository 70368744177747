import { defineMessages } from "react-intl";
export const ProductLotNumberSelectMessages = defineMessages({
  lotNumberWithExpiryDesc: {
    id: "productLotNumber.options.lotNumberWithExpiryDesc",
    defaultMessage: "{availableQty} available · Expiry: {expirationDate}"
  },
  lotNumberWithoutExpiryDesc: {
    id: "productLotNumber.options.lotNumberWithoutExpiryDesc",
    defaultMessage: "{availableQty} available"
  },
  fefoLabel: {
    id: "productLotNumber.options.fefoLabel",
    defaultMessage: "First Expiry First Out"
  }
});