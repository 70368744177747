import { FastTagType } from "common/clients/fastTag/FastTagType";
import { FAST_TAG_EDIT } from "tags/model/FastTagOperation";
import { UNKNOWN_COUNT } from "tags/model/StepCount/UnknownCount";
import { actionToPage } from "./actionToPath";
import { facebookEditFlowInitialState, facebookEditFlowReducer } from "./state/FacebookEditFlowState";
import { editAdSetsSyncPage } from "./pages/EditAdSetsSync/editAdSetsSyncPage";
import { editConfigureAdsPage } from "./pages/EditConfigureAds/editConfigureAdsPage";
import { processUnsyncPage } from "./pages/ProcessUnsync/processUnsyncPage";
import { selectActionPage } from "./pages/SelectAction/selectActionPage";
import { syncAdsWarningPage } from "./pages/SyncAdsWarning/syncAdsWarningPage";
import { unsyncAdSetsPage } from "./pages/UnsyncAdSets/unsyncAdSetsPage";
import { unknownPage } from "tags/model/FastTagPage/UnknownPage";
import { fastTagDetailPage } from "tags/model/FastTagPage/PathPage";
export const SelectEditAction = "SelectEditAction";
const pages = [selectActionPage, syncAdsWarningPage, editConfigureAdsPage, editAdSetsSyncPage, unsyncAdSetsPage, processUnsyncPage];
export const facebookEditFlow = {
  fastTag: FastTagType.FACEBOOK,
  operation: FAST_TAG_EDIT,
  entry: selectActionPage,
  initialState: facebookEditFlowInitialState,
  reducer: facebookEditFlowReducer,
  pages,
  getStepCount: page => {
    const currentStepMap = {
      [selectActionPage.id]: 0,
      [syncAdsWarningPage.id]: 1,
      [editConfigureAdsPage.id]: 1,
      [editAdSetsSyncPage.id]: 2,
      [unsyncAdSetsPage.id]: 1,
      [processUnsyncPage.id]: 2
    };
    const remainingStepsMap = {
      [selectActionPage.id]: UNKNOWN_COUNT,
      [syncAdsWarningPage.id]: 1,
      [editConfigureAdsPage.id]: 1,
      [editAdSetsSyncPage.id]: 0,
      [unsyncAdSetsPage.id]: 1,
      [processUnsyncPage.id]: 0
    };
    return {
      currentStep: currentStepMap[page.id] ?? UNKNOWN_COUNT,
      remainingSteps: remainingStepsMap[page.id] ?? UNKNOWN_COUNT
    };
  },
  getPreviousPage: page => {
    const previousPageMap = {
      [syncAdsWarningPage.id]: selectActionPage,
      [editConfigureAdsPage.id]: syncAdsWarningPage,
      [editAdSetsSyncPage.id]: editConfigureAdsPage,
      [unsyncAdSetsPage.id]: selectActionPage,
      [processUnsyncPage.id]: unsyncAdSetsPage
    };
    return previousPageMap[page.id] ?? unknownPage(FastTagType.FACEBOOK);
  },
  getNextPage: (page, state) => {
    const facebookDetailPage = fastTagDetailPage(FastTagType.FACEBOOK);
    if (page.id === editAdSetsSyncPage.id) {
      return facebookDetailPage;
    } else if (page.id === selectActionPage.id) {
      return state.selectedAction === null ? facebookDetailPage : actionToPage[state.selectedAction];
    } else {
      const nextPageMap = {
        [syncAdsWarningPage.id]: editConfigureAdsPage,
        [editConfigureAdsPage.id]: editAdSetsSyncPage,
        [unsyncAdSetsPage.id]: processUnsyncPage
      };
      return nextPageMap[page.id];
    }
  }
};