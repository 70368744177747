import { CommaDateNoYear, TimeWithTz, TimeWithoutTz } from "common/date/DateFormat";
import { format } from "date-fns";
import { getStorageInboundDetail } from "storage/inbounds/detail/store/selectors/getStorageInboundDetail";
import { useSelector } from "react-redux";
export const usePickupAppointmentDetails = () => {
  const {
    storageInboundOrder
  } = useSelector(getStorageInboundDetail);
  const {
    freightOrderDetails
  } = storageInboundOrder;
  const cargoReadyDate = freightOrderDetails?.pickupDetails?.appointment?.startTime && format(new Date(freightOrderDetails?.pickupDetails?.appointment?.startTime), CommaDateNoYear);
  const startTime = freightOrderDetails?.pickupDetails?.appointment?.startTime && format(new Date(freightOrderDetails?.pickupDetails?.appointment?.startTime), TimeWithoutTz);
  const endTime = freightOrderDetails?.pickupDetails?.appointment?.endTime && format(new Date(freightOrderDetails?.pickupDetails?.appointment?.endTime), TimeWithTz);
  const email = freightOrderDetails?.pickupDetails?.location?.email;
  const phone = freightOrderDetails?.pickupDetails?.location?.phone;
  return {
    cargoReadyDate,
    startTime,
    endTime,
    email,
    phone
  };
};