import { FeatureName, getFeatureSelector } from "common/Split";
import { mapProductsToMultiPackSkuInventory } from "storage/packUtils/mapProductsToMultiPackSkuInventory";
import { getSelectedProductsWithPackOf } from "./getSelectedProductsWithPackOf";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
export const getShipmentItems = (selectedProductsQty, originStorageWarehouse, selectedProductsLotNumbers) => async (dispatch, getState) => {
  const {
    isEachPickingOrder
  } = selectTransferCreate(getState());
  const isMultiCasePackEnabled = getFeatureSelector(FeatureName.StorageMultiCasePack)(getState());
  let inventoryBreakdown = [];
  if (isMultiCasePackEnabled) {
    inventoryBreakdown = mapProductsToMultiPackSkuInventory(selectedProductsQty, originStorageWarehouse?.inventoryBreakdown ?? {}, true, selectedProductsLotNumbers);
  }
  const selectedProductsQtyWithMultiCasePriorityInventory = isMultiCasePackEnabled ? inventoryBreakdown : selectedProductsQty;
  const selectedProductsQtyWithPackOf = await getSelectedProductsWithPackOf(dispatch, selectedProductsQtyWithMultiCasePriorityInventory);
  const shipmentItems = selectedProductsQtyWithPackOf.map(({
    dsku,
    qty,
    caseQty,
    packOf,
    lotNumber,
    expirationDate
  }) => ({
    dsku,
    totalUnits: qty,
    unitsPerPack: isEachPickingOrder ? 1 : caseQty ?? 1,
    packOf,
    lotNumber,
    expirationDate
  }));
  return shipmentItems;
};