import { createSelector } from "reselect";
import { compact, pipe, reduce, flatten } from "lodash/fp";
import log, { logError, logStart } from "Logger";
import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
export const getLoadedShipment = createSelector(state => state.inbound.loadedShipmentId, state => state.inbound.shipments.byId, (loadedShipmentId, shipmentsById) => {
  const ctx = logStart({
    fn: "getLoadedShipment",
    msg: `Retrieving loaded shipment with id "${loadedShipmentId}."`
  });
  if (loadedShipmentId === undefined) {
    const msg = "Cannot retrieve loaded shipment with undefined loadedShipmentId.";
    logError(ctx, new Error(msg));
    throw new Error(msg);
  }
  if (!shipmentsById?.[loadedShipmentId]) {
    const msg = "Cannot retrieve shipment with the specified shipment Id.";
    logError(ctx, new Error(`${msg} ${loadedShipmentId}`));
    throw new Error(msg);
  }
  return shipmentsById[loadedShipmentId];
});
export const getLoadedShipmentBarcodes = createSelector(getLoadedShipment, getProductDetailsCache, (shipment, productDetailsCache) => {
  log.info({
    fn: "getLoadedShipmentBarcodes",
    shipmentId: shipment.id
  }, "Retrieving shipment barcodes");
  return productDetailsCache ? pipe(reduce((acc, current) => {
    if (current?.dsku && productDetailsCache[current.dsku]?.barcodes?.length) {
      return acc.concat(productDetailsCache[current.dsku].barcodes);
    }
    return acc;
  }, []), flatten, compact)(shipment.items) : [];
});