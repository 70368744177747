import { clearInbound as clearInboundAction } from "./clearInbound";
import { InboundStep } from "inbounds/InboundTypes";
import { goToInboundStep } from "inbounds/store/actions/navigation/goToInboundStep";
import { selectIpbFeatureOn } from "../selectors/selectIpbFeatureOn";
import { goToCreateShipmentStep } from "inbounds/createShipment/routing/goToCreateShipmentStep";
export const goToCreateInbound = ({
  replace,
  clearInbound,
  routeToProducts
} = {}) => (dispatch, getState) => {
  const state = getState();
  const isIpbFeatureOn = selectIpbFeatureOn(state);
  if (clearInbound) {
    dispatch(clearInboundAction());
  }
  isIpbFeatureOn ? dispatch(goToCreateShipmentStep()) : dispatch(goToInboundStep(routeToProducts ? InboundStep.SELECT_PRODUCTS : InboundStep.SELECT_INBOUND_TYPE, replace));
};