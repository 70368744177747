import { getMappedSkuPairs } from "inbounds/steps/ship/view/cards/ShipmentProblems/spvUtils/getMappedSkuPairs";
import { ProblemType } from "common/problems/ProblemType";
export const getNonComplianceRowsFromOperationalException = (genericIssue, productCollection) => {
  const {
    sellerProblemId,
    status,
    updatedAt,
    dsku,
    shippingPlanId,
    shippingPlanName,
    reportedUnits,
    receivedUnits,
    issueId,
    isLegacyCase,
    affectedCdskus,
    createdAt,
    exceptionTypeName,
    externalMessage,
    dueAt
  } = genericIssue;
  return {
    id: sellerProblemId?.toString() ?? issueId,
    isLegacyCase,
    problemType: ProblemType.FLEXPORT_SHIP,
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    status,
    units: reportedUnits ?? receivedUnits,
    mappedSkus: productCollection ? getMappedSkuPairs(genericIssue, productCollection) : dsku ? [{
      dsku,
      msku: "",
      productName: ""
    }] : [],
    affectedCdskus,
    acknowledgedAt: new Date(),
    externalMessage,
    exceptionTypeName,
    dueAt
  };
};