import { showModal } from "common/components/Modal/ModalActions";
import { createActionCreator } from "common/ReduxUtils";
import InboundModalId from "inbounds/InboundModalId";
import { dispatchThenSaveInbound } from "inbounds/steps/InboundSaveActions";
import { some, values } from "lodash";
export let ProductComplianceActionTypes;
(function (ProductComplianceActionTypes) {
  ProductComplianceActionTypes["TOGGLE_COMPLIANCE_CRITERIA"] = "TOGGLE_COMPLIANCE_CRITERIA";
  ProductComplianceActionTypes["COMPLETE_COMPLIANCE"] = "COMPLETE_COMPLIANCE";
  ProductComplianceActionTypes["CLEAR_PRODUCT_COMPLIANCE"] = "CLEAR_PRODUCT_COMPLIANCE";
  ProductComplianceActionTypes["BATTERY_COMPLIANCE"] = "BATTERY_COMPLIANCE";
  ProductComplianceActionTypes["ACCEPT_ALL_COMPLIANCE_CRITERIA"] = "ACCEPT_ALL_COMPLIANCE_CRITERIA";
})(ProductComplianceActionTypes || (ProductComplianceActionTypes = {}));
export const verifyProductCompliance = () => (dispatch, getState) => {
  const {
    inbound: {
      plan,
      productDetailsCache
    },
    productCompliance: {
      completedShippingPlanIds
    }
  } = getState();
  const hasProductsWithNoBarcodes = some(values(productDetailsCache),
  // eslint-disable-next-line no-prototype-builtins
  product => !product.hasOwnProperty("barcodes") || product?.barcodes?.length === 0);
  const isComplianceCompleted = plan && completedShippingPlanIds.includes(plan.id);
  if (hasProductsWithNoBarcodes && !isComplianceCompleted) {
    dispatch(showModal(InboundModalId.ProductCompliance));
  }
};
export const toggleCompliance = createActionCreator(ProductComplianceActionTypes.TOGGLE_COMPLIANCE_CRITERIA, "criteriaName");
export const completeCompliance = dispatchThenSaveInbound(() => (dispatch, getState) => {
  const {
    inbound: {
      plan
    }
  } = getState();
  dispatch({
    type: ProductComplianceActionTypes.COMPLETE_COMPLIANCE,
    shippingPlanId: plan.id
  });
});
export const clearProductCompliance = createActionCreator(ProductComplianceActionTypes.CLEAR_PRODUCT_COMPLIANCE);
export const initBatteryCompliance = createActionCreator(ProductComplianceActionTypes.BATTERY_COMPLIANCE);
export const acceptAllComplianceCriteria = createActionCreator(ProductComplianceActionTypes.ACCEPT_ALL_COMPLIANCE_CRITERIA);