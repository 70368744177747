import { useModal } from "common/hooks/useModal";
import { useIntl } from "react-intl";
import { getNewCasePackModalIdForSku } from "../NewProductCasePackModal";
import { ProductCasePackSelectMessages } from "./ProductCasePackSelectMessages";
import { getCasePackDimsDescription } from "./ProductCasePackUtils";
import { calculateReserveStorageAvailableQuantity } from "common/utils/product/calculateReserveStorageAvailableQuantity";
import { getProductCasePackErrorMessage } from "./ProductCasePackError";
export const useProductCasePackSelect = ({
  product,
  productCasePacks,
  selectedCasePack,
  onSelectCasePack,
  includeNewCasePackOption,
  showAvailableStorageInventory,
  showProductCasePackErrors,
  maximumSelectableCaseQty
}) => {
  const {
    showModal,
    hideModal
  } = useModal(getNewCasePackModalIdForSku(product.dsku));
  const {
    formatMessage
  } = useIntl();
  const newTemplateOptionValue = "NEW_PACK_OPTION";
  const newTemplateOption = {
    value: newTemplateOptionValue,
    label: `+ ${formatMessage({
      id: "new.productCasePack.newTemplate.message",
      defaultMessage: "Add case pack template"
    })}`,
    subtitle: "",
    isLink: true,
    onLinkClick: () => {
      showModal();
    }
  };
  const selectionOptions = productCasePacks.length ? [...(productCasePacks?.map(productCasePack => ({
    value: productCasePack.dsku,
    label: productCasePack.packName ?? formatMessage(ProductCasePackSelectMessages.defaultCasePackName, {
      units: productCasePack.quantity
    }),
    subtitle: showAvailableStorageInventory ? formatMessage(ProductCasePackSelectMessages.casePackSubtitleWithInventory, {
      units: productCasePack.quantity,
      inventoryUnits: calculateReserveStorageAvailableQuantity(productCasePack),
      dimsInfo: getCasePackDimsDescription(productCasePack)
    }) : formatMessage(ProductCasePackSelectMessages.casePackSubtitle, {
      units: productCasePack.quantity,
      dimsInfo: getCasePackDimsDescription(productCasePack)
    }),
    error: showProductCasePackErrors ? getProductCasePackErrorMessage({
      productCasePack,
      maximumSelectableCaseQty
    }) : undefined
  })) ?? []), ...(includeNewCasePackOption ? [newTemplateOption] : [])] : [];
  const selectedOption = selectedCasePack ? selectionOptions.find(option => option.value === selectedCasePack.dsku) : undefined;
  const selected_option_key = selectedOption ? selectedOption.value : "no_pack_selected";
  const onCasePackChange = option => {
    if (option.value === newTemplateOptionValue) {
      showModal();
      return;
    }
    const productCasePack = (productCasePacks ?? []).find(casePack => casePack.dsku === option.value);
    if (productCasePack) {
      onSelectCasePack?.(productCasePack);
    }
  };
  return {
    selectionOptions,
    selectedOption,
    newTemplateOption,
    onCasePackChange,
    hideModal,
    selected_option_key,
    formatMessage
  };
};