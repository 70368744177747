export const selectedProductsToStorageItemMapper = (selectedProductsValues, selectedProductsCaseDimensions, selectedProductsLotInformation, selectedProductCasePacks, mapSkuToCasePackSku) => {
  return selectedProductsValues.map(storageInboundProductItem => {
    const dsku = storageInboundProductItem.dsku;
    const caseDimensionCollection = selectedProductsCaseDimensions?.[dsku];
    const lotInformationCollection = selectedProductsLotInformation?.[dsku];
    return {
      dsku: mapSkuToCasePackSku ? selectedProductCasePacks[dsku].dsku : dsku,
      caseQty: storageInboundProductItem.caseQty,
      qty: storageInboundProductItem.qty,
      caseWidth: caseDimensionCollection?.width && Number(caseDimensionCollection?.width),
      caseHeight: caseDimensionCollection?.height && Number(caseDimensionCollection?.height),
      caseLength: caseDimensionCollection?.length && Number(caseDimensionCollection?.length),
      caseWeight: caseDimensionCollection?.weight && Number(caseDimensionCollection?.weight),
      expirationDate: lotInformationCollection?.expirationDate,
      expirationDateHasDay: lotInformationCollection?.hasFullExpirationDate.value,
      lotNumber: lotInformationCollection?.lotNumber
    };
  });
};