import { useDispatch, useSelector } from "react-redux";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { updateStorageInboundOrder } from "../store/actions/updateStorageInboundOrder";
import { sumBy } from "lodash/fp";
import { useState } from "react";
import { getIsSelectShippingNextButtonDisabled } from "./getIsSelectShippingNextButtonDisabled";
export const useStorageSelectShipping = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = () => setIsOpen(!isOpen);
  const {
    goToNextStep,
    goToPreviousStep
  } = useStorageInboundNavigation();
  const {
    cargoType: selectedCargoType,
    destinationStorageWarehouse,
    numOfPallets,
    containerSize,
    selectedProducts,
    palletConfigurations,
    freightOrderDetails,
    carrierType
  } = useSelector(getStorageInboundCreate);
  const skuCount = Object.keys(selectedProducts).length;
  const units = sumBy("qty", Object.values(selectedProducts));
  const {
    fromAddress
  } = useSelector(state => state.inbound);
  const isNextDisabled = getIsSelectShippingNextButtonDisabled(carrierType, selectedCargoType, containerSize, numOfPallets, destinationStorageWarehouse, freightOrderDetails, palletConfigurations);
  const onNextClick = async () => {
    dispatch(addLoader(InboundLoaderId.transition));
    if (await dispatch(updateStorageInboundOrder())) {
      await goToNextStep();
    }
    dispatch(clearLoader(InboundLoaderId.transition));
  };
  const onPreviousClick = () => {
    goToPreviousStep();
  };
  return {
    isOpen,
    toggleOpen,
    onNextClick,
    onPreviousClick,
    skuCount,
    units,
    fromAddress,
    isNextDisabled
  };
};