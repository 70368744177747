import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { getReplenishmentShippingTypeFromWholesaleType } from "../../utils/getReplenishmentShippingTypeFromWholesaleType";
import { getShippingQuotes } from "transfers/create/actions/replenishmentOrder/shipping/getShippingQuotes";
import { DistributionChannel, ShippingPartner, ShippingType } from "@deliverr/replenishment-client";
import { getReplenishmentShippingPartnerFromWholesaleType } from "../../utils/getReplenishmentShippingPartnerFromWholesaleType";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { isReplenishmentOrderWithMultipleShipments } from "../../utils/replenenishmentShipments";
import { replenishmentOrderShipmentTypeMap, replenishmentOrderShipmentPartnerMap } from "./ReplenishmentOrderConfirmationMessages";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
export const useReplenishmentOrderConfirmation = () => {
  const {
    shippingMethod,
    cargoType,
    destinationType,
    replenishmentOrder,
    replenishmentOrderEstimates,
    replenishmentError,
    shipmentShippingMethodMap,
    isEachPickingOrder
  } = useSelector(selectTransferCreate);
  const dispatch = useDispatch();
  const {
    formatMessage
  } = useIntl();
  const isLoading = useSelector(selectAnyIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);
  const shouldShowPricingSummary = replenishmentOrderEstimates?.charges && replenishmentOrderEstimates.charges.length > 0;
  const [shouldShowShipmentsModal, setShowShipmentsModal] = useState(false);
  const isMultipleShipments = isReplenishmentOrderWithMultipleShipments(replenishmentOrder);
  const onModalClose = () => {
    setShowShipmentsModal(false);
  };
  const onViewMoreClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowShipmentsModal(true);
  };
  const shippingType = getReplenishmentShippingTypeFromWholesaleType(cargoType);
  const shippingPartner = getReplenishmentShippingPartnerFromWholesaleType(shippingMethod, destinationType);
  const shippingTypeMessage = formatMessage(replenishmentOrderShipmentTypeMap[shippingType]);
  const shippingPartnerMessage = formatMessage(replenishmentOrderShipmentPartnerMap[shippingPartner]);
  useMount(async () => {
    let orderEstimateRequest;
    if (replenishmentOrder?.distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK) {
      // We pass dummy shipmentId as shipments haven't yet been created
      orderEstimateRequest = {
        shipmentShippingPreferences: [{
          shipmentId: "ECOM_SHIPMENT",
          shippingPartner: ShippingPartner.DELIVERR,
          shippingType: ShippingType.LTL
        }]
      };
    } else {
      orderEstimateRequest = {
        shipmentShippingPreferences: replenishmentOrder?.shipments?.map(({
          shipmentId
        }) => {
          const shipmentShippingType = getReplenishmentShippingTypeFromWholesaleType(shipmentShippingMethodMap?.[shippingMethod]?.[shipmentId] ?? cargoType);
          return {
            shipmentId,
            shippingPartner,
            shippingType: shipmentShippingType
          };
        }) ?? []
      };
    }
    await dispatch(getShippingQuotes(orderEstimateRequest, replenishmentOrder?.orderId ?? ""));
  });
  return {
    replenishmentOrderEstimates,
    isLoading,
    onViewMoreClick,
    shouldShowShipmentsModal,
    setShowShipmentsModal,
    onModalClose,
    formatMessage,
    shippingTypeMessage,
    shippingPartnerMessage,
    isMultipleShipments,
    replenishmentError,
    isEachPickingOrder,
    shouldShowPricingSummary
  };
};