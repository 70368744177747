/**
 * Input names should match the path to the field in the state object.
 */

export let CreateShipmentInputName;
(function (CreateShipmentInputName) {
  CreateShipmentInputName["ORIGIN"] = "origin";
  CreateShipmentInputName["ORIGIN_FACILITY_NAME"] = "originFacilityName";
  CreateShipmentInputName["IS_ORIGIN_PORT"] = "isOriginPort";
  CreateShipmentInputName["SUPPLIER"] = "supplier";
  CreateShipmentInputName["SUPPLIER_COMPANY_NAME"] = "supplier.companyName";
  CreateShipmentInputName["SUPPLIER_CONTACT_NAME"] = "supplier.contactName";
  CreateShipmentInputName["SUPPLIER_CONTACT_EMAIL"] = "supplier.contactEmail";
  CreateShipmentInputName["SUPPLIER_CONTACT_PHONE"] = "supplier.contactPhone";
  CreateShipmentInputName["DESTINATION"] = "destination";
  CreateShipmentInputName["DESTINATION_FACILITY_NAME"] = "destinationFacilityName";
  CreateShipmentInputName["IS_LIFTGATE_REQUIRED"] = "isLiftgateRequired";
  CreateShipmentInputName["DELIVERY_DATE"] = "deliveryDate";
  CreateShipmentInputName["DELIVERY_DATE_DATE"] = "deliveryDate.date";
  CreateShipmentInputName["DELIVERY_DATE_IS_FLEXIBLE"] = "deliveryDate.isFlexible";
  CreateShipmentInputName["ORIGIN_TYPE"] = "originType";
  CreateShipmentInputName["CARGO_INPUT_TYPE"] = "cargoInputType";
  CreateShipmentInputName["UNIT_SYSTEM"] = "unitSystem";
  CreateShipmentInputName["TOTAL_VOLUME"] = "shipmentTotals.volume";
  CreateShipmentInputName["TOTAL_WEIGHT"] = "shipmentTotals.weight";
  CreateShipmentInputName["TOTAL_PALLETS"] = "shipmentTotals.pallets";
  CreateShipmentInputName["TOTAL_UNITS"] = "shipmentTotals.units";
  CreateShipmentInputName["TOTAL_BOXES"] = "shipmentTotals.boxes";
  CreateShipmentInputName["BOOKING_PRODUCTS"] = "bookingProducts";
  CreateShipmentInputName["SINGLE_SKU_PACKAGES"] = "singleSkuPackages";
  CreateShipmentInputName["MIXED_SKU_PACKAGES"] = "mixedSkuPackages";
  CreateShipmentInputName["AIR_SHIPMENT_CARGO_DETAILS"] = "airShipmentCargoDetails";
  CreateShipmentInputName["IS_CARGO_MIXED_SKU"] = "isCargoMixedSku";
  CreateShipmentInputName["DESTINATION_SELECT"] = "destinationSelect";
  CreateShipmentInputName["DESTINATION_SELECT_IS_BY_FLEXPORT"] = "destinationSelect.isByFlexport";
  CreateShipmentInputName["DESTINATION_SELECT_IS_BY_AMAZON"] = "destinationSelect.isByAmazon";
  CreateShipmentInputName["DESTINATION_SELECT_IS_DTC_NETWORK"] = "destinationSelect.isDTCNetwork";
  CreateShipmentInputName["DESTINATION_SELECT_IS_OWN_DESTINATION"] = "destinationSelect.isOwnDestination";
  CreateShipmentInputName["RESERVE_STORAGE"] = "reserveStorage";
  CreateShipmentInputName["RESERVE_STORAGE_ADDRESS"] = "reserveStorage.address";
  CreateShipmentInputName["RESERVE_STORAGE_WAREHOUSE_ID"] = "reserveStorage.warehouseId";
  CreateShipmentInputName["CARGO_READY_DATE"] = "cargoReadyDate";
  CreateShipmentInputName["HAS_DANGEROUS_GOODS"] = "hasDangerousGoods";
  CreateShipmentInputName["DANGEROUS_GOODS_LITHIUM_BATTERIES"] = "dangerousGoods.hasLithiumBatteries";
  CreateShipmentInputName["DANGEROUS_GOODS_NON_LITHIUM_BATTERIES"] = "dangerousGoods.hasNonLithiumBatteries";
  CreateShipmentInputName["DANGEROUS_GOODS_OTHER"] = "dangerousGoods.hasOther";
  CreateShipmentInputName["DANGEROUS_GOODS_NONE"] = "dangerousGoods.hasNoDangerousGoods";
  CreateShipmentInputName["LITHIUM_BATTERIES_PACKED_LOOSE"] = "lithiumBatteryPackaging.packedLoose";
  CreateShipmentInputName["LITHIUM_BATTERIES_PACKED_EQUIPMENT"] = "lithiumBatteryPackaging.packedEquipment";
  CreateShipmentInputName["LITHIUM_BATTERIES_IN_EQUIPMENT"] = "lithiumBatteryPackaging.inEquipment";
  CreateShipmentInputName["LITHIUM_BATTERIES_IN_VEHICLE"] = "lithiumBatteryPackaging.inVehicle";
  CreateShipmentInputName["HAS_ANNUAL_CUSTOMS_BOND"] = "hasAnnualCustomsBond";
  CreateShipmentInputName["FLEXPORT_CAPITAL_SELECTED"] = "flexportCapitalSelected";
  CreateShipmentInputName["DISTRIBUTION_TYPE"] = "distributionType";
  CreateShipmentInputName["AMAZON_DESTINATION_TYPE"] = "amazonDestinationType";
  CreateShipmentInputName["AMAZON_PREP_TYPE"] = "amazonPrepType";
  CreateShipmentInputName["AMAZON_ADDRESS"] = "amazonAddress";
  CreateShipmentInputName["DTC_PREP_OPTION_BOX_LABELING_UNITS_SELECTED"] = "dtcPrepOption.boxLabelingUnitsSelected";
  CreateShipmentInputName["DTC_PREP_OPTION_UNIT_PREP_SERVICE"] = "dtcPrepOption.unitPrepService";
  CreateShipmentInputName["FREIGHT_SERVICE"] = "freightService";
  CreateShipmentInputName["DOMESTIC_DESTINATION_TYPE"] = "domesticDestinationType";
  CreateShipmentInputName["HAS_CARGO_INSURANCE"] = "hasCargoInsurance";
  CreateShipmentInputName["CARGO_INSURANCE_TYPE"] = "cargoInsuranceType";
  CreateShipmentInputName["INSURANCE_INFO_TOTAL_CARGO_VALUE"] = "insuranceInfo.totalCargoValue";
})(CreateShipmentInputName || (CreateShipmentInputName = {}));