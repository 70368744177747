import { Card, Text } from "common/components/ui";
import styled from "@emotion/styled";
export const PrepStepContainer = styled.div`
  height: 700px;
`;
export const PrepStepCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const PrepStepTitle = styled.h1(({
  removeTopMargin,
  theme
}) => `
    font-family: Montserrat, sans-serif;
    font-weight: ${theme.font.weight.BOLD};
    font-size: ${theme.font.size.F4};
    margin-top: ${removeTopMargin ? 0 : theme.spacing.S7};
    margin-bottom: ${theme.spacing.S4};
  `);
export const PrepStepDescription = styled(Text)(({
  theme
}) => `
    margin-bottom: ${theme.spacing.S5};
    text-align: center;
    max-width: 520px;
  `);
export const PrepStepContent = styled.div(({
  theme
}) => `
    width: 760px;
    margin-bottom: ${theme.spacing.S7};
  `);