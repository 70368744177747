import { useState, useRef } from "react";
export const useDashboardTooltip = (timeout, onMouseOverHandler, onMouseOutHandler) => {
  const [active, setActive] = useState(false);
  const timer = useRef();
  const onMouseOut = e => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (timeout) {
      timer.current = setTimeout(() => setActive(false), timeout);
    } else {
      setActive(false);
    }
    onMouseOutHandler?.(e);
  };
  const onMouseOver = e => {
    setActive(true);
    onMouseOverHandler?.(e);
  };
  return {
    active,
    onMouseOver,
    onMouseOut
  };
};