import { useDispatch, useSelector } from "react-redux";
import { estimateCosts, setFieldValue } from "common/components/EstimatedCosts/CostEstimatorModalActions";
import { hideModal } from "../Modal/ModalActions";
export const costEstimatorModalId = "costEstimator";
export const useCostEstimatorModal = ({
  type
}) => {
  const dispatch = useDispatch();
  const productName = useSelector(state => state.costEstimatorModal.productName);
  const dimensions = useSelector(state => state.costEstimatorModal.dimensions);
  const areAllInputsValid = useSelector(state => state.costEstimatorModal.allInputsValid);
  const onInputChange = ({
    currentTarget
  }) => {
    let numberValue = Number(currentTarget.value);
    if (isNaN(numberValue)) {
      numberValue = 0;
    }
    dispatch(setFieldValue(currentTarget.dataset.dimension, numberValue));
  };
  const onEstimateClick = async () => {
    await dispatch(estimateCosts(type));
    dispatch(hideModal(costEstimatorModalId));
  };
  return {
    onInputChange,
    onEstimateClick,
    productName,
    dimensions,
    areAllInputsValid
  };
};