import { useEffect, useMemo } from "react";
import { useAsync } from "react-use";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { sellerProblemsViewClient } from "Clients";
import log from "Logger";
import { SellerProblemType } from "@deliverr/legacy-inbound-client";
export const useFetchNCProduct = ({
  sellerProblemId
}) => {
  const {
    formatMessage
  } = useIntl();
  const ctx = useMemo(() => ({
    fn: "useFetchNCProduct",
    sellerProblemId
  }), [sellerProblemId]);
  const {
    value,
    loading: isLoading,
    error
  } = useAsync(async () => {
    if (!sellerProblemId) {
      return;
    }
    log.info({
      ...ctx
    }, "getting NC product");
    return await sellerProblemsViewClient.getSellerProblemOverviewBySellerProblemIdAndType(sellerProblemId, SellerProblemType.UNKNOWN_BARCODE).then(res => res.data);
  }, [sellerProblemId]);
  useEffect(() => {
    if (error) {
      log.warn({
        ...ctx,
        error
      }, "error fetching NC product");
      toast.error(formatMessage({
        id: "nonCompliance.product.fetchError",
        defaultMessage: "There was an issue fetching your shipment problem"
      }));
    }
  }, [ctx, error, formatMessage]);
  return {
    value,
    isLoading,
    error
  };
};