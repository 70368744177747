import { defineMessages } from "react-intl";
import { BulkUploadError } from "./BulkUploadError";
export const BULK_UPLOAD_ERROR_MESSAGES = defineMessages({
  [BulkUploadError.FILE_BAD_EXTENSION]: {
    id: "inbounds.bulkUpload.error.fileBadExtension",
    defaultMessage: "File extension must be xlsx or csv"
  },
  [BulkUploadError.FILE_TOO_BIG]: {
    id: "inbounds.bulkUpload.error.fileTooBig",
    defaultMessage: "File size must be less than 2MB"
  },
  [BulkUploadError.SHEET_OVERFILLED]: {
    id: "inbounds.bulkUpload.error.sheetOverfilled",
    defaultMessage: "Sheet contains too many SKUs"
  },
  [BulkUploadError.SHEET_NOT_FOUND]: {
    id: "inbounds.bulkUpload.error.sheetNotFound",
    defaultMessage: "File is in the incorrect format"
  },
  [BulkUploadError.SHEET_EMPTY]: {
    id: "inbounds.bulkUpload.error.sheetEmpty",
    defaultMessage: "Sheet cannot be empty"
  },
  [BulkUploadError.SHEET_COLUMN_EXTRANEOUS]: {
    id: "inbounds.bulkUpload.error.sheetColumnExtraneous",
    defaultMessage: "File contains extra columns"
  },
  [BulkUploadError.SHEET_ROW_EXTRANEOUS]: {
    id: "inbounds.bulkUpload.error.sheetRowExtraneous",
    defaultMessage: "File must contain 1,000 SKUs or fewer per shipping plan"
  },
  [BulkUploadError.SHEET_ROW_EXTRANEOUS_REPLENISHMENT]: {
    id: "inbounds.bulkUpload.error.sheetRowExtraneous",
    defaultMessage: "File must contain 200 SKUs or fewer per order"
  },
  [BulkUploadError.VALUE_BAD_STRING]: {
    id: "inbounds.bulkUpload.error.valueBadString",
    defaultMessage: "Invalid string value"
  },
  [BulkUploadError.VALUE_BAD_WHOLE_NUMBER]: {
    id: "inbounds.bulkUpload.error.valueBadWholeNumber",
    defaultMessage: "Invalid whole number"
  },
  [BulkUploadError.VALUE_BAD_NUMBER]: {
    id: "inbounds.bulkUpload.error.valueBadNumber",
    defaultMessage: "Invalid number"
  },
  [BulkUploadError.VALUE_MISSING]: {
    id: "inbounds.bulkUpload.error.valueMissing",
    defaultMessage: "Missing value"
  },
  [BulkUploadError.BOX_BAD_WEIGHT]: {
    id: "inbounds.bulkUpload.error.boxBadWeight",
    defaultMessage: "Box weight must be between in 1 and 50 lbs"
  },
  [BulkUploadError.BOX_BAD_DIMENSIONS]: {
    id: "inbounds.bulkUpload.error.boxBadDimensions",
    defaultMessage: 'Box cannot exceed 40"x30"x30"'
  },
  [BulkUploadError.PRODUCT_DIMS_OVERSIZED]: {
    id: "inbounds.bulkUpload.error.productDimsOversized",
    defaultMessage: 'Product dimensions are too big. Longest side must be 40" or less median side must be 30" or less, shortest side must be 15" or less'
  },
  [BulkUploadError.PRODUCT_DOES_NOT_EXIST]: {
    id: "inbounds.bulkUpload.error.productDoesNotExist",
    defaultMessage: "Product does not exist"
  },
  [BulkUploadError.MSKU_DUPLICATE]: {
    id: "inbounds.bulkUpload.error.mskuDuplicate",
    defaultMessage: "Duplicate MSKU"
  },
  [BulkUploadError.UNEXPECTED_ERROR]: {
    id: "inbounds.bulkUpload.error.unexpectedError",
    defaultMessage: "An unexpected error occured"
  },
  [BulkUploadError.UNABLE_TO_IMPORT_PRODUCTS]: {
    id: "inbounds.bulkUpload.error.validationErrorTitle",
    defaultMessage: "Unable to import products"
  },
  [BulkUploadError.UNABLE_TO_IMPORT_PRODUCTS_TRY_AGAIN]: {
    id: "inbounds.bulkUpload.error.validationErrorTitle",
    defaultMessage: "We were unable to import your products. If you haven't already, {link} and try again."
  },
  [BulkUploadError.UNABLE_TO_IMPORT_PRODUCTS_LINK]: {
    id: "inbounds.bulkUpload.error.validationErrorTitleLink",
    defaultMessage: "download the template"
  },
  [BulkUploadError.SHEET_TYPE_MISMATCH]: {
    id: "inbounds.bulkUpload.error.sheetTypeMismatch",
    defaultMessage: "{detectedTemplate} template was detected instead of {expectedTemplate} template"
  },
  [BulkUploadError.CASE_PACKED_TEMPLATE]: {
    id: "inbounds.bulkUpload.error.casePackedTemplate",
    defaultMessage: "Case-packed"
  },
  [BulkUploadError.INDIVIDUAL_TEMPLATE]: {
    id: "inbounds.bulkUpload.error.individualTemplate",
    defaultMessage: "Individual"
  },
  [BulkUploadError.MSKU_DSKU_MISMATCH]: {
    id: "inbounds.bulkUpload.error.MSKU_DSKU_MISMATCH",
    defaultMessage: "DSKU does not belong to the defined MSKU"
  },
  [BulkUploadError.DSKU_CASE_PACK_MISMATCH]: {
    id: "inbounds.bulkUpload.error.DSKU_CASE_PACK_MISMATCH",
    defaultMessage: "Case Pack Template Name does not belong to the defined DSKU"
  },
  [BulkUploadError.MSKU_CASE_PACK_MISMATCH]: {
    id: "inbounds.bulkUpload.error.sheetEmpty",
    defaultMessage: "Case Pack Template Name does not belong to the defined MSKU"
  },
  [BulkUploadError.PRODUCT_ASIN_MISSING]: {
    id: "inbounds.bulkUpload.error.missingAsin",
    defaultMessage: "Asin is missing"
  },
  [BulkUploadError.LARGE_CASE_PACK]: {
    id: "inbounds.bulkUpload.error.largeCasePack",
    defaultMessage: "Case Quantity must be less than 150"
  },
  [BulkUploadError.MISSING_IDENTIFIER]: {
    id: "inbounds.bulkUpload.error.largeCasePack",
    defaultMessage: "Missing Identifier. MSKU / DSKU are mandatory"
  },
  [BulkUploadError.INVENTORY_NOT_AVAILABLE]: {
    id: "inbounds.bulkUpload.error.productInventoryUnavailable",
    defaultMessage: "Inventory is unavailable for products with below SKU(s)"
  },
  [BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE]: {
    id: "inbounds.bulkUpload.error.productInventoryUnavailable",
    defaultMessage: "Inventory is unavailable for products with below MSKU(s)"
  }
});