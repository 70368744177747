import styled from "@emotion/styled";
import { BorderlessButton } from "./BorderlessButton";
export const LinkButton = styled(BorderlessButton)(({
  bold,
  theme
}) => `
    color: ${theme.colors[theme.config.colorByAppearance.PRIMARY][300]};
    font-weight: ${bold ? "bold" : "normal"};

    &:hover {
      color: #11bfe3;
    }
  `);