export let TransferShipmentDetailActionTypes;
(function (TransferShipmentDetailActionTypes) {
  TransferShipmentDetailActionTypes["TRANSFER_GET_DETAILS_SUCCESS_OLD"] = "TRANSFER_GET_DETAILS_SUCCESS_OLD";
  TransferShipmentDetailActionTypes["TRANSFER_GET_DETAILS_SUCCESS"] = "TRANSFER_GET_DETAILS_SUCCESS";
  TransferShipmentDetailActionTypes["TRANSFER_DETAILS_UPLOADING"] = "TRANSFER_DETAILS_UPLOADING";
  TransferShipmentDetailActionTypes["TRANSFER_DETAILS_CLEAR_ERROR"] = "TRANSFER_DETAILS_CLEAR_ERROR";
  TransferShipmentDetailActionTypes["TRANSFER_DETAILS_SET_ERROR"] = "TRANSFER_DETAILS_SET_ERROR";
  TransferShipmentDetailActionTypes["TRANSFER_REDIRECT_TO_INBOUND_ERROR"] = "TRANSFER_REDIRECT_TO_INBOUND_ERROR";
  TransferShipmentDetailActionTypes["TRANSFER_MUTATING"] = "TRANSFER_MUTATING";
  TransferShipmentDetailActionTypes["TRANSFER_MUTATING_CLEAR"] = "TRANSFER_MUTATING_CLEAR";
})(TransferShipmentDetailActionTypes || (TransferShipmentDetailActionTypes = {}));