export let GoogleShoppingError;
(function (GoogleShoppingError) {
  GoogleShoppingError["DUPLICATE_MERCHANT_ID"] = "DUPLICATE_MERCHANT_ID";
  GoogleShoppingError["DUPLICATE_WAREHOUSE_COMBINATION"] = "DUPLICATE_WAREHOUSE_COMBINATION";
  GoogleShoppingError["DUPLICATE_WAREHOUSE_SERVICE_LEVEL"] = "DUPLICATE_WAREHOUSE_SERVICE_LEVEL";
  GoogleShoppingError["INVALID_FEED_ID"] = "INVALID_FEED_ID";
  GoogleShoppingError["INVALID_URL"] = "INVALID_URL";
  GoogleShoppingError["INVALID_ZIP"] = "INVALID_ZIP";
  GoogleShoppingError["MISSING_MERCHANT_ID"] = "MISSING_MERCHANT_ID";
  GoogleShoppingError["NO_SELLER_FAST_TAGS"] = "NO_SELLER_FAST_TAGS";
  GoogleShoppingError["NOT_AN_ADMIN"] = "NOT_AN_ADMIN";
  GoogleShoppingError["NOT_FOUND_IN_MARKETPLACE"] = "NOT_FOUND_IN_MARKETPLACE";
  GoogleShoppingError["ORDER_NOT_FOUND"] = "ORDER_NOT_FOUND";
  GoogleShoppingError["PRODUCT_NOT_FOUND"] = "PRODUCT_NOT_FOUND";
  GoogleShoppingError["REFRESH_ALREADY_IN_PROGRESS"] = "REFRESH_ALREADY_IN_PROGRESS";
  GoogleShoppingError["SELLER_NOT_FOUND"] = "SELLER_NOT_FOUND";
  GoogleShoppingError["TOO_MANY_WAREHOUSE_COMBINATIONS"] = "TOO_MANY_WAREHOUSE_COMBINATIONS";
  GoogleShoppingError["TOO_MANY_WAREHOUSE_SERVICE_LEVELS"] = "TOO_MANY_WAREHOUSE_SERVICE_LEVELS";
  GoogleShoppingError["UNAUTHORIZED"] = "UNAUTHORIZED";
  GoogleShoppingError["UNKNOWN_GOOGLE_API_CLIENT_ERROR"] = "UNKNOWN_GOOGLE_API_CLIENT_ERROR";
})(GoogleShoppingError || (GoogleShoppingError = {}));