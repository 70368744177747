import { complement, difference, isEmpty, keys, pipe, pick, pickBy, trim } from "lodash/fp";
import { addressRequiredFieldsValidation } from "common/AddressUtils";
const isValidValue = val => isEmpty(trim(val));
const keysWithValues = pipe(pickBy(complement(isValidValue)), keys);
export const orderLocationRequiredFieldsValidation = address => {
  const requiredFields = ["street1", "city", "state", "country", "zip"];
  const providedFields = pipe(pick(requiredFields), keysWithValues)(address);
  return difference(requiredFields, providedFields);
};
export const validateContactDetails = contact => {
  return Object.values(contact).every(value => value !== "");
};
export const isQuoteAddressValid = (address, addressMetadata) => addressRequiredFieldsValidation(address, ["city", "state", "zip", "country"]).length === 0 && !addressMetadata.isDeliverrWarehouse;
export const isAddressValid = address => addressRequiredFieldsValidation(address, ["street1", "city", "state", "zip", "country"]).length === 0;