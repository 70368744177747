import { defineMessages } from "react-intl";
export const addressInputFieldNames = defineMessages({
  name: {
    id: "common.address.name",
    defaultMessage: "First & Last Name"
  },
  company: {
    id: "common.address.company",
    defaultMessage: "Company name"
  },
  street1: {
    id: "common.address.street1",
    defaultMessage: "Street Address"
  },
  street2: {
    id: "common.address.street2",
    defaultMessage: "Street Address 2 (Optional)"
  },
  country: {
    id: "common.address.country",
    defaultMessage: "Country"
  },
  city: {
    id: "common.address.city",
    defaultMessage: "City"
  },
  zip: {
    id: "common.address.zip",
    defaultMessage: "ZIP Code"
  },
  state: {
    id: "common.address.state",
    defaultMessage: "State"
  },
  usAddress: {
    id: "common.address.usAddress",
    defaultMessage: "U.S. address only"
  }
});
export const intlAddressInputFieldNames = defineMessages({
  name: {
    id: "common.address.intl.name",
    defaultMessage: "Facility Name"
  },
  street1: {
    id: "common.address.intl.street1",
    defaultMessage: "Address"
  },
  street2: {
    id: "common.address.intl.street2",
    defaultMessage: "Apt / suite / other (optional)"
  },
  zip: {
    id: "common.address.intl.zip",
    defaultMessage: "Postal code"
  },
  state: {
    id: "common.address.intl.state",
    defaultMessage: "State / Province / Region"
  },
  country: {
    id: "common.address.intl.country",
    defaultMessage: "Country"
  },
  city: {
    id: "common.address.intl.city",
    defaultMessage: "City"
  }
});