import { isNil } from "lodash";
import { useParams } from "react-router-dom";
import RouteParser from "common/RouteParser";
import { nonComplianceInternalRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { Path } from "paths";
import { usePreventScrollToTopNavigation } from "common/hooks/usePreventScrollToTopNavigation";
import { useFetchProductFromChildOrParentDsku } from "storage/hooks/useFetchProductFromChildOrParentDsku";
export const useUnexpectedSkuNonComplianceBlade = nonComplianceCase => {
  const navigate = usePreventScrollToTopNavigation();
  const {
    nonCompliantId
  } = useParams();
  const {
    shippingPlanId,
    shippingPlanName,
    mappedSkus
  } = nonComplianceCase;
  const {
    value: product
  } = useFetchProductFromChildOrParentDsku({
    dsku: mappedSkus[0]?.dsku
  });
  const skuPair = product && {
    dsku: product.dsku,
    msku: product.msku,
    productName: product.name
  };
  const onShippingPlanClick = () => {
    window.open(new RouteParser(Path.inboundsStorageDetail).parse({
      shippingPlanId
    }), "_blank");
  };
  const resetBladeState = () => {
    if (!isNil(nonCompliantId) && nonCompliantId.length > 0) {
      navigate(nonComplianceInternalRoutes.summary);
    }
  };
  return {
    onShippingPlanClick,
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    skuPair
  };
};