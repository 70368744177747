import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";
const CFT_TO_CBM = 0.028317;

/**
 * Calculates the volume of a product in cubic feet or meters depending on the unit system
 */
export const calcCasePackTotalVolume = (product, unitSystem) => {
  const {
    length,
    width,
    height,
    numberOfCases
  } = product;
  const caseVolumeCubicIn = (length ?? 0) * (width ?? 0) * (height ?? 0); // cubic in
  const caseVolumeCubicFt = caseVolumeCubicIn / 1728; // cubic feet
  const caseVolume = unitSystem === UnitSystem.CBM_KG ? caseVolumeCubicFt * CFT_TO_CBM : caseVolumeCubicFt;
  return Math.round(caseVolume * numberOfCases * 100) / 100;
};