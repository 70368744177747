import { defineMessages } from "react-intl";
import { MissingBoxesAlertStatus } from "@deliverr/commons-clients";
import { ShortShipConfirmationOption } from "./types";
export const SHORT_SHIP_PACKAGES_TABLE_LABELS = defineMessages({
  alertSent: {
    id: "inbounds.shipmentProblems.shortShipBlade.packagesTable.alertSentLabel",
    defaultMessage: "Date Received"
  },
  expectedQty: {
    id: "inbounds.shipmentProblems.shortShipBlade.packagesTable.expectedQtyLabel",
    defaultMessage: "Expected"
  },
  arrivedQty: {
    id: "inbounds.shipmentProblems.shortShipBlade.packagesTable.arrivedQtyLabel",
    defaultMessage: "Arrived"
  },
  discrepancyQty: {
    id: "inbounds.shipmentProblems.shortShipBlade.packagesTable.discrepancyQtyLabel",
    defaultMessage: "Discrep"
  }
});
export const SHORT_SHIP_DSKUS_TABLE_LABELS = defineMessages({
  dskuDetail: {
    id: "sku",
    defaultMessage: "SKU"
  },
  expectedQty: {
    id: "inbounds.shipmentProblems.shortShipBlade.dskusTable.expectedQtyLabel",
    defaultMessage: "Expected units"
  },
  arrivedQty: {
    id: "inbounds.shipmentProblems.shortShipBlade.dskusTable.arrivedQtyLabel",
    defaultMessage: "Arrived"
  },
  discrepancyQty: {
    id: "inbounds.shipmentProblems.shortShipBlade.dskusTable.discrepancyQtyLabel",
    defaultMessage: "Discrep"
  }
});
export const SHORT_SHIP_BUTTON_LABELS = defineMessages({
  confirm: {
    id: "inbounds.shipmentProblemShortShipBlade.buttonBar.confirmLabel",
    defaultMessage: "Confirm short-ship"
  },
  contact: {
    id: "inbounds.shipmentProblemShortShipBlade.buttonBar.contactLabel",
    defaultMessage: "Contact Flexport"
  }
});
export const SHORT_SHIP_RADIO_TILE_LABELS = defineMessages({
  [ShortShipConfirmationOption.CONFIRM]: {
    id: "inbounds.shipmentProblemShortShipBlade.radioTile.confirmLabel",
    defaultMessage: "Yes"
  },
  [ShortShipConfirmationOption.INVESTIGATE]: {
    id: "inbounds.shipmentProblemShortShipBlade.radioTile.investigateLabel",
    defaultMessage: "No"
  }
});
export const SHORT_SHIP_STATUS_SPECIFIC_LABELS = defineMessages({
  [MissingBoxesAlertStatus.ARRIVED_RESOLVED]: {
    header: {
      id: "inbounds.shipmentProblemShortShipBlade.arrivedResolved.header",
      defaultMessage: "All boxes with Flexport"
    },
    message: {
      id: "inbounds.shipmentProblemShortShipBlade.arrivedResolved.message",
      defaultMessage: "All short-shipped boxes have been identified and accounted for."
    }
  },
  [MissingBoxesAlertStatus.SHORT_SHIP_CONFIRMED]: {
    header: {
      id: "inbounds.shipmentProblemShortShipBlade.confirmed.header",
      defaultMessage: "Short-ship confirmed"
    },
    message: {
      id: "inbounds.shipmentProblemShortShipBlade.confirmed.message",
      defaultMessage: "These boxes were confirmed as short-shipped to Flexport."
    }
  },
  [MissingBoxesAlertStatus.REIMBURSED]: {
    header: {
      id: "inbounds.shipmentProblemShortShipBlade.reimbursed.header",
      defaultMessage: "Claim approved"
    },
    message: {
      id: "inbounds.shipmentProblemShortShipBlade.reimbursed.message",
      defaultMessage: "Flexport reimbursed your account for the missing boxes."
    }
  },
  [MissingBoxesAlertStatus.WITH_SUPPORT]: {
    header: {
      id: "inbounds.shipmentProblemShortShipBlade.withSupport.header",
      defaultMessage: "Pending investigation"
    },
    message: {
      id: "inbounds.shipmentProblemShortShipBlade.withSupport.message",
      defaultMessage: "We received your investigation request and will get back to you shortly."
    }
  },
  [MissingBoxesAlertStatus.CREATED]: {
    header: {
      id: "inbounds.shipmentProblemShortShipBlade.created.header",
      defaultMessage: "Claim created"
    },
    message: {
      id: "inbounds.shipmentProblemShortShipBlade.created.message",
      defaultMessage: "Flexport detected a potential short-ship."
    },
    prompt: {
      id: "inbounds.shipmentProblemShortShipBlade.created.prompt",
      defaultMessage: "Were the boxes short-shipped?"
    }
  }
});
export const SHORT_SHIP_VALIDATION_MESSAGES = defineMessages({
  illegalStatusChange: {
    id: "inbounds.shipmentProblemShortShipBlade.errorMessage.illegalStatusChange",
    defaultMessage: "Cannot submit a Shipment Problem with the current status. Please try again or contact support."
  },
  noOptionSelected: {
    id: "inbounds.shipmentProblemShortShipBlade.errorMessage.noOptionSelected",
    defaultMessage: "Cannot submit a Shipment Problem without selecting a resolution."
  }
});