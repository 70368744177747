import produce from "immer";
import { createReducer } from "common/ReduxUtils";
import { FreightBookingAccordionStepsActionTypes } from "../actions/FreightBookingAccordionStepsActionTypes";
import { freightBookingAccordionStepsInitialState } from "./freightBookingAccordionStepsInitialState";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";
const reducers = {
  [FreightBookingAccordionStepsActionTypes.CONFIRM_STEP]: (state, action) => {
    return produce(state, draft => {
      draft[action.step] = true;
    });
  },
  [FreightBookingAccordionStepsActionTypes.UNCONFIRM_STEP]: (state, action) => {
    return produce(state, draft => {
      draft[action.step] = false;
    });
  },
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => freightBookingAccordionStepsInitialState,
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => freightBookingAccordionStepsInitialState
};
export const freightBookingAccordionStepsReducer = createReducer(freightBookingAccordionStepsInitialState, reducers);