import { Path } from "paths";
import { generatePath } from "react-router-dom";
import { fastTagDetailPath } from "./fastTagDetailPath";
export const fastTagPagePath = (page, fallback = fastTagDetailPath) => {
  switch (page.type) {
    case "unknown":
      return fallback(page);
    case "path":
      return page.id;
    default:
      return generatePath(Path.fastTagsProgram, {
        program: page.fastTag,
        page: page.id
      });
  }
};