import styled from "@emotion/styled";
export const AlertCount = styled.div(({
  theme
}) => `
    margin: auto;
    margin-right: 0;
    padding: 0 ${theme.spacing.S1};
    color: ${theme.colors.NEUTRAL["00"]};
    background-color: ${theme.colors.RED[300]};
    font-weight: ${theme.font.weight.BOLD};
    border-radius: ${theme.border.radius.R2};
  `);