import { defineMessages } from "react-intl";
export let ShippingPlanType; // dummy shipping plan with inboundSource=STORAGE
(function (ShippingPlanType) {
  ShippingPlanType["ALL_TYPES"] = "ALL_TYPES";
  ShippingPlanType["DIRECT"] = "DIRECT";
  ShippingPlanType["FORWARDING"] = "FORWARDING";
  ShippingPlanType["STORAGE"] = "STORAGE";
})(ShippingPlanType || (ShippingPlanType = {}));
export const SHIPPING_PLAN_DISPLAY_TEXT = defineMessages({
  [ShippingPlanType.ALL_TYPES]: {
    defaultMessage: "All types",
    id: `shippingPlan.${[ShippingPlanType.ALL_TYPES]}`
  },
  [ShippingPlanType.DIRECT]: {
    defaultMessage: "Direct",
    id: `shippingPlan.${[ShippingPlanType.DIRECT]}`
  },
  [ShippingPlanType.FORWARDING]: {
    defaultMessage: "Forwarding",
    id: `shippingPlan.${[ShippingPlanType.FORWARDING]}`
  },
  [ShippingPlanType.STORAGE]: {
    defaultMessage: "Reserve Storage",
    id: `shippingPlan.${[ShippingPlanType.STORAGE]}`
  }
});
export let DiscrepancyStatusType;
(function (DiscrepancyStatusType) {
  DiscrepancyStatusType["ALL_STATES"] = "ALL_STATES";
  DiscrepancyStatusType["WITH_DISCREPANCY"] = "WITH_DISCREPANCY";
})(DiscrepancyStatusType || (DiscrepancyStatusType = {}));
export const DISCREPANCY_STATUS_DISPLAY_TEXT = defineMessages({
  [DiscrepancyStatusType.ALL_STATES]: {
    defaultMessage: "All states",
    id: `discrepancyStatus.${DiscrepancyStatusType.ALL_STATES}`
  },
  [DiscrepancyStatusType.WITH_DISCREPANCY]: {
    defaultMessage: "With discrepancy",
    id: `discrepancyStatus.${DiscrepancyStatusType.WITH_DISCREPANCY}`
  }
});