import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { TransferListActionTypes } from "../actions/TransferListActionTypes";
import { ReplenishmentOrderListTabFilter } from "./TransferListState";
import { shipmentListItemResponseToTransferListItem } from "../AlgoliaWholesaleRow";
import produce from "immer";
import { isEmpty } from "lodash/fp";
import { TransferDestinationType } from "./TransferDestinationType";
import { transfersListShipmentStatuses } from "../TransfersListShipmentStatus";
import { DistributionChannel } from "@deliverr/replenishment-client";
export const transferListInitialState = {
  searchTerm: "",
  sort: {
    fieldName: "createdAt",
    direction: SortDir.DESC
  },
  pageNum: 0,
  searchFilters: {
    shipmentStatuses: [...transfersListShipmentStatuses],
    destinationType: TransferDestinationType.ALL,
    tabFilter: ReplenishmentOrderListTabFilter.ALL_ORDERS
  },
  showChannelReplenishmentBanner: {
    [DistributionChannel.FBA]: true,
    [DistributionChannel.WFS]: true
  }
};
const reducers = {
  ...handleSimpleReducerUpdates([TransferListActionTypes.TRANSFER_LIST_CHANGE_PAGE_NUM, TransferListActionTypes.TRANSFER_LIST_GET_ERROR]),
  [TransferListActionTypes.TRANSFER_LIST_CHANGE_DESTINATION_SEARCH_FILTERS]: (state, {
    destinationType
  }) => produce(state, draft => {
    draft.searchFilters.destinationType = destinationType;
  }),
  [TransferListActionTypes.TRANSFER_LIST_CHANGE_STATUS_SEARCH_FILTERS]: (state, {
    shipmentStatuses
  }) => produce(state, draft => {
    draft.searchFilters.shipmentStatuses = shipmentStatuses;
  }),
  [TransferListActionTypes.TRANSFER_LIST_CHANGE_SEARCH_TERM]: (state, {
    searchTerm
  }) => produce(state, draft => {
    draft.searchTerm = searchTerm;
    if (isEmpty(searchTerm)) {
      draft.pageItems = undefined;
    }
    draft.pageNum = 0;
  }),
  [TransferListActionTypes.TRANSFER_LIST_GET_SUCCESS]: (state, {
    listData: {
      shipments,
      nbPages,
      pageNum
    }
  }) => produce(state, draft => {
    draft.pageItems = shipments.map(shipmentListItemResponseToTransferListItem);
    draft.nbPages = nbPages;
    draft.pageNum = pageNum;
    draft.isError = false;
  }),
  [TransferListActionTypes.DELETE_TRANSFER_ORDER_SUCCESS]: (state, {
    transferOrderIds
  }) => produce(state, draft => {
    draft.pageItems = draft.pageItems?.filter(val => !transferOrderIds.includes(val.shipmentId));
    draft.isError = false;
  }),
  [TransferListActionTypes.TRANSFER_LIST_SET_TAB_FILTER]: (state, {
    tabFilter
  }) => produce(state, draft => {
    draft.searchFilters.tabFilter = tabFilter;
  }),
  [TransferListActionTypes.UNSET_SHOW_CHANNEL_REPLENISHMENT_BANNER]: (state, {
    distributionChannel
  }) => produce(state, draft => {
    draft.showChannelReplenishmentBanner[distributionChannel] = false;
  })
};
export const transferListReducer = createReducer(transferListInitialState, reducers);