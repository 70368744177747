import styled from "@emotion/styled";
import { sidebarTheme } from "./SidebarTheme";
/**
 * Container for Sidebar item, which contains an <a> or <button> element as appropriate,
 * which, in turn, contains SidebarItemContent.
 */
export const SidebarItemContainer = styled.li(({
  active,
  mode,
  theme
}) => `
    list-style-type: none;
    height: ${sidebarTheme.item.height[mode]};

    > a, > button {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0;
      color: ${active ? theme.colors.NEUTRAL["00"] : theme.colors.NEUTRAL[200]};
      background-color: ${active ? theme.colors.BLUE[300] : "transparent"};
      border: ${theme.border.width.B2} solid transparent;
      border-radius: ${sidebarTheme.item.borderRadius};

      &:focus {
        outline: 0;
        border-color: ${theme.colors.BLUE[200]};
        
        &:not(:focus-visible) {
          border-color: transparent;
        }
      }
    }

    &:hover {
      > a, > button {
        color: ${theme.colors.NEUTRAL["00"]};
        background-color: ${active ? theme.colors.BLUE[300] : sidebarTheme.item.hoverBackgroundColor};
      }
    }
`);