import { isProdEnv, isProdUrl } from "common/Config";
import logger from "Logger";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
export const getIsValidDataDogEnv = () => isProdEnv && isProdUrl();
export function initDataDog() {
  if (!getIsValidDataDogEnv()) {
    return;
  }
  const baseConfig = {
    clientToken: "pubbed5b5e6dd92869e37ac8cbae3e6b6f4",
    site: "datadoghq.com",
    service: "seller-portal-prod",
    env: "production",
    version: COMMIT
  };

  // Initialize Browser Logger
  datadogLogs.init({
    ...baseConfig,
    forwardErrorsToLogs: true,
    sampleRate: 100
  });
  logger.info({
    fn: "initDataDog"
  }, "Initializing DataDog!");

  // User monitoring / session replay
  datadogRum.init({
    ...baseConfig,
    applicationId: "92c883e0-0e07-4448-92fc-1bd661ec972e",
    // do not sample (collect 100% of events)
    sampleRate: 100,
    // do not sample (collect 100% of session replays)
    replaySampleRate: 100,
    trackInteractions: true,
    // Masks all data in forms
    defaultPrivacyLevel: "allow",
    // This requires server-side changes to permit DataDog headers
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
    allowedTracingOrigins: [/https:\/\/fulfillment\.(prod|staging)\.deliverr\.com/],
    // for MVP / trial, we will only be fully tracing against Fulfillment Service
    enableExperimentalFeatures: ["clickmap"],
    beforeSend: event => {
      // allow overriding event attributes, used for ensuring timing for actions with redirects reflect the initiating view
      const overrides = event.context?.overrides;
      const viewOverrides = overrides?.view;
      if (viewOverrides) {
        Object.keys(viewOverrides).forEach(k => {
          event.view[k] = viewOverrides[k];
        });
      }
    }
  });
  datadogRum.startSessionReplayRecording();
}
export function updateDataDogIdentity({
  user
}) {
  if (!getIsValidDataDogEnv() || !user) {
    return;
  }
  const ctx = {
    fn: "updateDataDogIdentity",
    user: user?.sellerId
  };
  logger.info(ctx, "Updating DataDog user");
  datadogRum.setUser({
    id: user.sellerId,
    email: user.email,
    sellerId: user.sellerId,
    onboardingStage: JSON.stringify(user.onboardingStage),
    dealStage: user.dealStage,
    isTestSeller: user.isTestSeller ?? false
  });
}