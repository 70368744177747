import produce from "immer";
import { createReducer } from "common/ReduxUtils";
import { BladeActionTypes } from "common/components/Blade/BladeActions";
export const bladeInitialState = {
  blades: {}
};
const bladeReducerFunc = (state, action) => produce(state, draft => {
  draft.blades[action.id] = action.type === BladeActionTypes.SHOW_BLADE;
});
const reducers = {
  [BladeActionTypes.SHOW_BLADE]: bladeReducerFunc,
  [BladeActionTypes.HIDE_BLADE]: bladeReducerFunc
};
export const bladeReducer = createReducer(bladeInitialState, reducers);