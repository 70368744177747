import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { OnboardingActionTypes } from "onboarding/OnboardingActions";
export const onboardingInitialState = {
  currentStep: 0,
  isOnboarding: true,
  listingTool: undefined,
  isChecklistBladeOpen: false
};
const reducers = {
  ...handleSimpleReducerUpdates([OnboardingActionTypes.SIGNUP_ERROR, OnboardingActionTypes.SIGNUP_SELECT, OnboardingActionTypes.SET_IS_CHECKLIST_BLADE_OPEN]),
  [OnboardingActionTypes.SIGNUP_SUCCESS]: state => ({
    ...state,
    signupComplete: true
  })
};
export const onboardingReducer = createReducer(onboardingInitialState, reducers);