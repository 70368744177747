import { useIntl } from "react-intl";
import { LTL_MAX_PALLET_HEIGHT, LTL_MAX_PALLET_WEIGHT_FORWARDING } from "@deliverr/commons-clients/lib/transportation/legacy-copy-pasta";
import { storageFreightPackingInstructionsLabels } from "./storageFreightPackingInstructionLabels";
export const useStorageFreightPackingInstruction = () => {
  const {
    formatMessage
  } = useIntl();
  return {
    maxHeightLabel: formatMessage(storageFreightPackingInstructionsLabels.maxHeight, {
      maxHeight: LTL_MAX_PALLET_HEIGHT
    }),
    maxWeightLabel: formatMessage(storageFreightPackingInstructionsLabels.maxWeight, {
      maxWeight: LTL_MAX_PALLET_WEIGHT_FORWARDING
    }),
    shrinkWrapLabel: formatMessage(storageFreightPackingInstructionsLabels.shrinkWrap),
    stepDescription: formatMessage(storageFreightPackingInstructionsLabels.description),
    woodenPalletsLabel: formatMessage(storageFreightPackingInstructionsLabels.woodenPallets)
  };
};