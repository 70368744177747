import { sortBy } from "lodash/fp";
export const composeGetIsOverDimensions = ({
  maxShortestSide,
  maxMiddleSide,
  maxLongestSide
}) => ({
  length,
  width,
  height
}) => {
  const sortedDims = sortBy(value => value, [Number(width ?? 0), Number(height ?? 0), Number(length ?? 0)]);
  return sortedDims[0] > maxShortestSide || sortedDims[1] > maxMiddleSide || sortedDims[2] > maxLongestSide;
};