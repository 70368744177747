import { isFeatureOn } from "./../../Split/isFeatureOn";
import log from "Logger";
import AlgoliaService from "../../list/AlgoliaService";
import { FeatureName } from "common/Split";
import { InventorySearchService } from "common/search/services/InventorySearchService";
import { configFor } from "common/search/services/SearchConfig";
import { ListType } from "common/list";
const algoliaInventoryService = AlgoliaService.get({
  indexName: process.env.ALGOLIA_INDEX_INVENTORY,
  searchConfig: {
    hitsPerPage: 10,
    maxValuesPerFacet: 1
  }
});
const inventorySearchService = new InventorySearchService(configFor(ListType.InventoryV2));
export const fetchProductsByDskus = async dskus => {
  const ctx = {
    fn: "addProductsByDsku"
  };
  log.info(ctx, "Searching products by dsku");
  const productsInInventory = await search(dskus);
  if (productsInInventory.hits.length) {
    return productsInInventory.hits.reduce((acc, product) => {
      acc[product.dsku] = product;
      return acc;
    }, {});
  }
  return {};
};
async function search(dskus) {
  if (isFeatureOn(FeatureName.LogisticsSearchFetchProductsByDskus)) {
    return await inventorySearchService.searchByIds(dskus, "", "dsku");
  }
  return await algoliaInventoryService.searchByIds(dskus, "", "dsku");
}