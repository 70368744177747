import { FeatureName } from "common/Split/FeatureName";
import { isFeatureOn } from "common/Split/isFeatureOn";
import { addContainerIdStep, downloadPalletLabelStep, packingInstructionsStep, pickupAppointmentDetailStep, sendShipmentStep } from ".";
import { advancedAppointmentSchedulingDetailStep } from "./AdvancedAppointmentSchedulingDetail/advancedAppointmentSchedulingDetailStep";
import { downloadBOLStep } from "./DownloadBOL/downloadBOLStep";
import { sendAndTrackShipmentStep } from "./SendAndTrackShipment/sendAndTrackShipmentStep";
import { CarrierType, ShipmentType } from "@deliverr/storage-client";
export const getInstructionStepsForShipmentType = (shipmentType, carrierType) => {
  const steps = [];
  switch (shipmentType) {
    case ShipmentType.FLOOR_LOADED_CONTAINER:
      steps.push(packingInstructionsStep, isFeatureOn(FeatureName.SaveStorageInboundTrackingInfo) ? sendAndTrackShipmentStep : sendShipmentStep, advancedAppointmentSchedulingDetailStep, addContainerIdStep);
      break;
    case ShipmentType.PALLETIZED:
      steps.push(downloadPalletLabelStep, packingInstructionsStep, carrierType === CarrierType.DELIVERR ? pickupAppointmentDetailStep : isFeatureOn(FeatureName.SaveStorageInboundTrackingInfo) ? sendAndTrackShipmentStep : sendShipmentStep, carrierType === CarrierType.DELIVERR ? downloadBOLStep : advancedAppointmentSchedulingDetailStep);
      break;
  }
  return steps;
};