import { openArticle, openSupportTicket } from "./Elevio";
export const useElevio = (articleId, onClick) => {
  const handleClick = event => {
    if (onClick !== undefined) {
      onClick(event);
    }
    articleId === "open-ticket" ? openSupportTicket() : openArticle(articleId);
  };
  return {
    handleClick
  };
};