export let Zindex;
(function (Zindex) {
  Zindex["Z0"] = "Z0";
  Zindex["Z1"] = "Z1";
  Zindex["Z2"] = "Z2";
  Zindex["Z3"] = "Z3";
  Zindex["Z4"] = "Z4";
  Zindex["Z5"] = "Z5";
  Zindex["Z6"] = "Z6";
})(Zindex || (Zindex = {}));
export const zindex = {
  [Zindex.Z0]: 0,
  // reset
  [Zindex.Z1]: 100,
  // whatever
  [Zindex.Z2]: 200,
  // nav
  [Zindex.Z3]: 300,
  // input popups
  [Zindex.Z4]: 400,
  // banners
  [Zindex.Z5]: 500,
  // dialog
  [Zindex.Z6]: 600 // dev
};