import { useState } from "react";
import { notifyUserOfError } from "common/ErrorToast";
import log from "Logger";
import { productClient } from "Clients";
import { DimensionSource } from "common/utils/product/isDimsConfirmed";
import { allDimensionsValid } from "common/components/EstimatedCosts/CostEstimatorModalReducer";
import { useAsyncFn } from "react-use";
export const useUpdateDimensionsModal = (dsku, updateProductDims, setShowUpdateDimensionsModal) => {
  const [dimensions, setDimensions] = useState({
    length: 0,
    width: 0,
    height: 0,
    weight: 0
  });
  const [{
    loading: isLoading
  }, updateProductDimensions] = useAsyncFn(async () => {
    try {
      if (allDimensionsValid(dimensions)) {
        await productClient.update({
          dsku,
          ...dimensions,
          dimSourceType: DimensionSource.SELLER,
          dimSource: "sellerPopUpFlow"
        });
        updateProductDims(dsku, dimensions);
        return true;
      } else {
        notifyUserOfError({
          explanation: "Invalid dimensions provided.",
          toastId: "updateProductDimsError"
        });
      }
    } catch (err) {
      log.error({
        err
      }, "error updating product dims");
      notifyUserOfError({
        err,
        explanation: "Oops. There was an issue updating your product dimensions.",
        toastId: "updateProductDimsError"
      });
    }
    return false;
  }, [dimensions]);
  const closeUpdateDimensionsModal = () => {
    setShowUpdateDimensionsModal(false);
  };
  const saveUpdateDimensionsModal = async () => {
    if (await updateProductDimensions()) {
      setShowUpdateDimensionsModal(false);
    }
  };
  const onInputDimensionsChange = change => {
    setDimensions(currentDimesions => ({
      ...currentDimesions,
      ...change
    }));
  };
  return {
    isLoading,
    saveUpdateDimensionsModal,
    closeUpdateDimensionsModal,
    onInputDimensionsChange
  };
};