import { ValidationTypes } from "./ValidationTypes";
import { shipmentTotalBoxesFbaValidation } from "../../fba/validations/shipmentTotalBoxesFbaValidation";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { isEmpty, sumBy } from "lodash";
import { shipmentInternationalTransportFBAValidation } from "../../fba/validations/shipmentInternationalTransportFBAValidation";
import { estimatedPalletCountFbaIntegrationValidation } from "../../fba/validations/estimatedPalletCountFbaIntegrationValidation";
import { productsDimsFbaIntegrationValidation } from "../../fba/validations/productsDimsFbaIntegrationValidation";
import { eachPickingOrderValidation } from "../../fba/validations/eachPickingOrderValidation";
export const flexportTransportValidator = transportShippingValidations => async (dispatch, getState) => {
  const state = getState();
  const {
    selectedProducts,
    destinationAddress,
    replenishmentOrder,
    isEachPickingOrder
  } = selectTransferCreate(state);
  const shipments = replenishmentOrder?.shipments;
  transportShippingValidations.forEach(async validationType => {
    switch (validationType) {
      case ValidationTypes.TOTAL_NUM_OF_BOXES_FBA:
        {
          const totalNumberOfBoxes = sumBy(Object.values(selectedProducts), "numberOfCases");
          await shipmentTotalBoxesFbaValidation(dispatch, totalNumberOfBoxes);
        }
      case ValidationTypes.INTERNATIONAL_ORDER:
        {
          const destinationCountry = destinationAddress?.country ?? "";
          await shipmentInternationalTransportFBAValidation(dispatch, destinationCountry);
        }
      case ValidationTypes.FBA_PALLET_COUNT_CHECK:
        {
          const shipmentEstimatedPalletCounts = shipments?.map(shipment => shipment?.estimatedPalletCount ?? 0) ?? [];
          if (shipmentEstimatedPalletCounts.length > 0) {
            await estimatedPalletCountFbaIntegrationValidation(dispatch, shipmentEstimatedPalletCounts);
          }
        }
      case ValidationTypes.FBA_INVALID_DIMS:
        {
          if (selectedProducts && !isEmpty(selectedProducts)) {
            await productsDimsFbaIntegrationValidation(dispatch, selectedProducts);
          }
        }
      case ValidationTypes.EACHES_PICKING:
        {
          if (isEachPickingOrder) {
            await eachPickingOrderValidation(dispatch);
          }
        }
    }
  });
  return selectTransferCreate(getState()).shipmentValidationError ?? {};
};