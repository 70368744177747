export let BulkUploadContent;
(function (BulkUploadContent) {
  BulkUploadContent["IMPORT_PRODUCTS"] = "IMPORT_PRODUCTS";
  BulkUploadContent["DOWNLOAD_TEMPLATE"] = "DOWNLOAD_TEMPLATE";
  BulkUploadContent["GET_HELP"] = "GET_HELP";
  BulkUploadContent["PRODUCTS_IMPORTED_SUCCESSFULLY"] = "PRODUCTS_IMPORTED_SUCCESSFULLY";
  BulkUploadContent["PRODUCT_REPLACEMENT_WARNING"] = "PRODUCT_REPLACEMENT_WARNING";
  BulkUploadContent["MAKE_FOLLOWING_CHANGES"] = "MAKE_FOLLOWING_CHANGES";
  BulkUploadContent["SHOW_ALL"] = "SHOW_ALL";
  BulkUploadContent["DOWNLOAD_REPORT"] = "DOWNLOAD_REPORT";
  BulkUploadContent["FILE_TYPE"] = "FILE_TYPE";
  BulkUploadContent["NOTE"] = "NOTE";
  BulkUploadContent["IMPORTING_PRODUCTS"] = "IMPORTING_PRODUCTS";
  BulkUploadContent["DOWNLOAD_TEMPLATE_LINK"] = "DOWNLOAD_TEMPLATE_LINK";
  BulkUploadContent["UPLOAD_FILE"] = "UPLOAD_FILE";
})(BulkUploadContent || (BulkUploadContent = {}));