import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { selectTransferSelectedProductsQty } from "transfers/create/store/selectTransferSelectedProductsQty";
import { getShipmentItems } from "./utils/getShipmentItems";
import { replenishmentClient } from "Clients";
import { Source, DistributionChannel, QuantityUom } from "@deliverr/replenishment-client";
import { getSellerId } from "common/user/UserSelectors";
import { getDistributionChannel } from "./utils/getDistributionChannel";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
export const convertDestinationAddress = destinationAddress => {
  return {
    name: destinationAddress?.name ?? "",
    company: destinationAddress?.company ?? "",
    street1: destinationAddress?.street1 ?? "",
    street2: destinationAddress?.street2 ?? "",
    city: destinationAddress?.city ?? "",
    state: destinationAddress?.state ?? "",
    country: destinationAddress?.country ?? "",
    zip: destinationAddress?.zip ?? "",
    phone: destinationAddress?.phone
  };
};
export const createReplenishmentOrder = submitDraft => async (dispatch, getState) => {
  const state = getState();
  const {
    originStorageWarehouse,
    destinationType,
    destinationAddress,
    prepOption,
    selectedProductsLotNumbers,
    poNumber,
    isEachPickingOrder
  } = selectTransferCreate(state);
  const distributionChannel = getDistributionChannel(destinationType);
  const selectedProductsQty = selectTransferSelectedProductsQty(state);
  const sellerId = getSellerId(state);
  const orderItems = await dispatch(getShipmentItems(selectedProductsQty, originStorageWarehouse, selectedProductsLotNumbers));
  const createOrderRequest = {
    distributionChannel,
    originWarehouseId: originStorageWarehouse?.warehouse?.id,
    sellerId,
    orderItems,
    isDraft: !submitDraft,
    source: Source.SELLER_PORTAL,
    prepOption,
    externalId: destinationType === TransferCreationTypes.Wholesale ? poNumber : undefined,
    shipments: distributionChannel === DistributionChannel.FBA_MANUAL ? [{
      destinationWarehouseAddress: convertDestinationAddress(destinationAddress),
      destinationWarehouseId: destinationAddress?.name ?? "FBA"
    }] : distributionChannel === DistributionChannel.WHOLESALE ? [{
      destinationWarehouseAddress: convertDestinationAddress(destinationAddress)
    }] : undefined,
    quantityUom: isEachPickingOrder ? QuantityUom.EACH : undefined
  };
  const response = (await replenishmentClient.createReplenishmentOrder(createOrderRequest)).value;
  return response;
};