import { emptyAddress } from "common/AddressUtils";
export const deliveryDetailsInitialState = {
  address: {
    ...emptyAddress,
    country: ""
  },
  deliveryReferenceNumber: null,
  facilityOperatingHours: undefined,
  deliveryInstructions: null,
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneExt: ""
  },
  isAppointmentRequired: undefined
};