import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { inventoryClient, onboardingClientV2 } from "common/clients/instances";
import { notifyUserOfError } from "common/ErrorToast";
import { createActionCreator } from "common/ReduxUtils";
import { find } from "lodash/fp";
import log from "Logger";
import { SalesChannelConnectionStatus } from "common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
export let WalmartSyncActionTypes;
(function (WalmartSyncActionTypes) {
  WalmartSyncActionTypes["SHOW_WALMART_SYNC"] = "SHOW_WALMART_SYNC";
  WalmartSyncActionTypes["SET_WALMART_SYNC_IS_ACTIVE"] = "SET_WALMART_SYNC_IS_ACTIVE";
})(WalmartSyncActionTypes || (WalmartSyncActionTypes = {}));
export const setWalmartSyncIsActive = createActionCreator(WalmartSyncActionTypes.SET_WALMART_SYNC_IS_ACTIVE, "walmartSyncIsActive");
const findWalmartDirectV1Channel = salesChannels => find(({
  salesChannelId,
  walmartVersion
}) => salesChannelId === ListingSolution.WALMARTDIRECT && walmartVersion === 1, salesChannels);
export const showWalmartSync = () => async (dispatch, getState) => {
  const ctx = {
    fn: "showWalmartSync"
  };
  log.info(ctx, "checking showWalmartSync");
  const {
    user: {
      sellerId
    },
    channels: {
      salesChannels
    }
  } = getState();
  const walmartDirectChannel = findWalmartDirectV1Channel(salesChannels);
  if (walmartDirectChannel) {
    let skusAvailable = 0;
    try {
      const inventorySummary = await inventoryClient.getInventorySummary(sellerId);
      skusAvailable = inventorySummary.skus_available;
    } catch (err) {
      log.warn({
        ...ctx,
        err
      }, "error getting inventory summary");
    } finally {
      dispatch({
        type: WalmartSyncActionTypes.SHOW_WALMART_SYNC,
        showWalmartSync: true,
        walmartSyncIsActive: walmartDirectChannel.connectionStatus === SalesChannelConnectionStatus.CONNECTED,
        hasInventory: skusAvailable > 0
      });
    }
  }
};
export const activateWalmartSync = () => async (dispatch, getState) => {
  const ctx = {
    fn: "activateWalmartSync"
  };
  log.info(ctx, "activating walmart sync");
  const {
    channels: {
      salesChannels
    }
  } = getState();
  try {
    const walmartDirectChannel = findWalmartDirectV1Channel(salesChannels);
    if (!walmartDirectChannel?.slsUuid) {
      throw new Error("could not get walmart direct channel slsUuid");
    }
    await onboardingClientV2.activateSellerIntegration(walmartDirectChannel.slsUuid);
    dispatch(setWalmartSyncIsActive(true));
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error activating walmart sync");
    notifyUserOfError({
      err,
      explanation: "There was an error activating Walmart direct inventory sync for your account",
      toastId: "activateWalmartSyncError"
    });
    throw err;
  }
};
export const deactivateWalmartSync = () => async (dispatch, getState) => {
  const ctx = {
    fn: "deactivateWalmartSync"
  };
  log.info(ctx, "deactivating walmart sync");
  const {
    channels: {
      salesChannels
    }
  } = getState();
  try {
    const walmartDirectChannel = findWalmartDirectV1Channel(salesChannels);
    if (!walmartDirectChannel?.slsUuid) {
      throw new Error("could not get walmart direct channel slsUuid");
    }
    await onboardingClientV2.deactivateSellerIntegration(walmartDirectChannel.slsUuid);
    dispatch(setWalmartSyncIsActive(false));
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error deactivating walmart sync");
    notifyUserOfError({
      err,
      explanation: "There was an error deactivating Walmart direct inventory sync for your account",
      toastId: "deactivateWalmartSyncError"
    });
    throw err;
  }
};