import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
export const useReturnsDetailHeader = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    returnsDetail
  } = useSelector(state => state.returnsDetail);
  const title = formatMessage({
    id: "returns.returnsDetail.titleReturnId",
    defaultMessage: "Return #{returnId}"
  }, {
    returnId: returnsDetail.rma
  });
  return {
    title,
    returnsDetail
  };
};