import { isEmpty } from "lodash";
import { transferCreateChooseOriginWarehouse } from "transfers/create/actions";
import { replenishmentClient, warehouseClient } from "Clients";
import { FulfillStrategy } from "@deliverr/replenishment-client";
import { loadWarehouses } from "common/deliverr/DeliverrActions";
export const setOriginWarehouseForReplenishmentOutOfStockOrder = orderItemsDskuQty => async dispatch => {
  if (orderItemsDskuQty && !isEmpty(orderItemsDskuQty)) {
    const availableWarehouseInventory = (await replenishmentClient.fetchAvailableWarehouseInventory(orderItemsDskuQty, FulfillStrategy.PARTIAL)).value;
    const warehouseIds = availableWarehouseInventory?.map(({
      warehouseId
    }) => warehouseId) ?? [];
    !isEmpty(warehouseIds) && dispatch(loadWarehouses(warehouseIds));
    const selectedWarehouseId = availableWarehouseInventory?.[0]?.warehouseId;
    const selectedWarehouse = selectedWarehouseId ? await warehouseClient.getWarehouse(selectedWarehouseId) : undefined;
    selectedWarehouse && dispatch(transferCreateChooseOriginWarehouse({
      ...availableWarehouseInventory?.[0],
      warehouse: selectedWarehouse
    }));
    return availableWarehouseInventory;
  }
  return undefined;
};