import { isSameDay } from "date-fns";
import produce from "immer";
import { fromLooseDate } from "common/date/LooseDate";
import { FreightTrackingInfoExternalIdType } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoExternalIdType";
export const freightTrackingInfoReducer = (state, {
  shipmentFreightInfo
}) => {
  // externalId represents shipmentId here
  const {
    externalId,
    externalIdType,
    saveSource,
    ...freightInfo
  } = shipmentFreightInfo;

  // Prevent blank ids or other object types from updating info
  if (!externalId || externalIdType !== FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT) {
    return state;
  }
  return produce(state, draft => {
    const savedInfo = state.freightShipmentInfo?.[externalId]?.savedInfo;
    const prevFreightInfo = state.freightShipmentInfo?.[externalId];
    const mergedInfo = {
      ...prevFreightInfo,
      ...freightInfo
    };
    draft.freightShipmentInfo = {
      ...state.freightShipmentInfo,
      [externalId]: {
        ...mergedInfo,
        // Save the data from the server to track changes if updateSaved is true
        savedInfo: saveSource ? {
          ...savedInfo,
          ...freightInfo
        } : savedInfo
      }
    };

    // Don't check for changes on a fetch
    if (saveSource !== "FETCH") {
      const haveFieldsChanged = (fields, prevChangeStatus) => {
        const fieldsInUpdate = fields.filter(field => Object.keys(freightInfo).includes(field));

        // Use the previous change status if no relevant fields are modified in the update
        if (fieldsInUpdate.length === 0) {
          return prevChangeStatus;
        }
        const hasDetectedChanges = (srcA, srcB, useAllFields = false) => {
          const fieldsToCompare = useAllFields ? fields : fieldsInUpdate;
          let hasChanged = false;
          fieldsToCompare.forEach(field => {
            const sameDay = srcA?.[field] && srcB?.[field] && isSameDay(fromLooseDate(srcA[field]), fromLooseDate(srcB[field]));
            hasChanged = hasChanged || (field === "estimatedShipDate" ? !sameDay : (srcA?.[field] ?? "") !== (srcB?.[field] ?? ""));
          });
          return hasChanged;
        };

        // If the data is updating the saved data, only need to check against current state
        // otherwise, have to check passed-in data against saved info and saved info against previous state
        return saveSource ? hasDetectedChanges(freightInfo, prevFreightInfo) : hasDetectedChanges(freightInfo, savedInfo) || hasDetectedChanges(mergedInfo, savedInfo, true);
      };
      const hasReceivingInfoChanged = haveFieldsChanged(["bolId", "proId"], prevFreightInfo?.hasReceivingInfoChanged);
      const hasShipmentConfirmationInfoChanged = haveFieldsChanged(["shippingProvider", "estimatedShipDate"], prevFreightInfo?.hasShipmentConfirmationInfoChanged);
      draft.freightShipmentInfo[externalId] = {
        ...draft.freightShipmentInfo[externalId],
        hasReceivingInfoChanged,
        hasShipmentConfirmationInfoChanged
      };
    }
  });
};