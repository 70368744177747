export let StoragePickupInputNames;
(function (StoragePickupInputNames) {
  StoragePickupInputNames["FIRST_NAME"] = "FIRST_NAME";
  StoragePickupInputNames["LAST_NAME"] = "LAST_NAME";
  StoragePickupInputNames["PHONE_NUMBER"] = "PHONE_NUMBER";
  StoragePickupInputNames["EMAIL"] = "EMAIL";
  StoragePickupInputNames["OPERATING_HOURS"] = "OPERATING_HOURS";
  StoragePickupInputNames["READY_DATE"] = "READY_DATE";
  StoragePickupInputNames["LIFTGATE_REQUIRED"] = "LIFTGATE_REQUIRED";
  StoragePickupInputNames["PICKUP_REFERENCE_NUMBER"] = "PICKUP_REFERENCE_NUMBER";
})(StoragePickupInputNames || (StoragePickupInputNames = {}));