import { useState } from "react";
import { useAccordionStepValidator } from "./useAccordionStepValidator";
export function useAccordionFlow({
  steps,
  stepCompletionMap
}) {
  const [openSteps, setOpenSteps] = useState({});
  const stepsValidation = useAccordionStepValidator({
    steps,
    stepCompletionMap
  });
  const firstInvalidIndex = Object.values(stepsValidation).findIndex(step => !step?.isValid);
  const toggleOpen = i => {
    setOpenSteps({
      ...openSteps,
      [i]: !openSteps[i]
    });
  };
  return {
    stepsValidation,
    firstInvalidIndex,
    openSteps,
    toggleOpen
  };
}