import { adminInitialState, adminReducer } from "common/components/Admin/AdminReducer";
import { billingInitialState, billingReducer } from "billing/BillingReducer";
import { bladeInitialState, bladeReducer } from "common/components/Blade/BladeReducer";
import { cognitoInitialState, cognitoReducer } from "common/user/CognitoReducer";
import { costEstimatorInitialState, costEstimatorReducer } from "common/components/EstimatedCosts/CostEstimatorModalReducer";
import { createShipmentInitialState, createShipmentReducer } from "inbounds/createShipment/store";
import { deliverrInitialState, deliverrReducer } from "common/deliverr/DeliverrReducer";
import { errorsReducer } from "./common/errors/ErrorsReducer";
import { fastTagFlowsInitialState, fastTagFlowsReducer } from "tags/FastTagFlows";
import { fastTagsInitialState, fastTagsReducer } from "tags/FastTagsReducer";
import { flexportUserInitialState, flexportUserReducer } from "common/flexportUser/FlexportUserReducer";
import { freightQuoteInitialState } from "FreightQuote/store/FreightQuoteState";
import { freightInitialState, freightReducer } from "freight/store/FreightReducer";
import { googleInitialState, googleReducer } from "tags/google/GoogleReducer";
import { inboundInitialState, inboundReducer } from "inbounds/InboundReducer";
import { inventoryDetailInitialState, inventoryDetailReducer } from "inventory/detail/InventoryDetailReducer";
import { inventoryListInitialState, inventoryListReducer } from "inventory/list/InventoryListReducer";
import { loadingInitialState, loadingReducer } from "common/components/WithLoader/LoadingReducer";
import { mediaInitialState, mediaReducer } from "media/MediaReducers";
import { modalInitialState, modalReducer } from "common/components/Modal/ModalReducer";
import { navBarProgressInitialState, navBarProgressReducer } from "common/components/NavBarProgress/NavBarProgressReducer";
import { newOrderInitialState, newOrderReducer } from "order/new/NewOrderReducer";
import { nonComplianceListInitialState, nonComplianceListReducer } from "inbounds/non-compliance/list/NonComplianceListReducer";
import { nonComplianceInitialState, nonComplianceReducer } from "inbounds/non-compliance/NonComplianceReducer";
import { notificationsInitialState, notificationsReducer } from "notifications/NotificationsReducer";
import { onboardingInitialState, onboardingReducer } from "onboarding/OnboardingReducer";
import { orderDetailInitialState, orderDetailReducer } from "order/detail/OrderDetailReducer";
import { orderListInitialState, orderListReducer } from "order/listV2/OrderListReducer";
import { orderReturnsListInitialState, orderReturnsListReducer, returnsCreateInitialState, returnsCreateReducer, returnsDetailInitialState, returnsDetailReducer, returnsLabelInitialState, returnsLabelReducer, returnsListInitialStateV2, returnsListReducerV2, returnsOnboardInitialState, returnsOnboardReducer, returnsSellerInitialState, returnsSellerReducer } from "./returns";
import { organizationInitialState, organizationReducer } from "organization/OrganizationReducer";
import { paginationInitialState, paginationReducer } from "common/components/Pagination/PaginationReducer";
import { parcelDetailInitialState, parcelDetailsReducer } from "parcel/screens/details/ParcelDetailReducer";
import { parcelListInitialState, parcelListReducer } from "parcel/screens/list/ParcelListReducer";
import { prepDetailsInitialState, prepDetailsReducer } from "prep/store/PrepDetailsReducer";
import { prepListInitialState, prepListReducer } from "./prep/store/PrepListReducer";
import { prepStoreInitialState, prepStoreReducer } from "prep/store";
import { productComplianceInitialState, productComplianceReducer } from "inbounds/steps/compliance/ProductComplianceReducer";
import { reportsInitialState, reportsReducer } from "report/ReportsReducer";
import { returnsInspectionConfigInitialState } from "returns/policy/store/ReturnsPolicyReducer";
import { returnsInitialRmtState as returnsRmtInitalState, returnsRmtReducer } from "returns/rmt/store/RmtReducer";
import { salesChannelsInitialState, salesChannelsReducer } from "channels/ChannelsReducers";
import { initialSettingsData, settingsReducer } from "settings/SettingsReducers";
import { shopifyInitialState } from "tags/shopify/ShopifyState";
import { smbShipmentInitialState, smbShipmentReducer } from "inbounds/ShipmentDetails/store";
import { storageInboundCreateInitialState, storageInboundCreateReducer, storageInboundDetailInitialState, storageInboundDetailReducer } from "./storage/inbounds/detail";
import { userInitialState, userReducer } from "common/user/UserReducer";
import { wholesaleOrderDetailInitialState, wholesaleOrderDetailReducer } from "wholesale/detail/store";
import { wishInitialState, wishReducer } from "tags/wish/WishReducer";
import { fbaIntegrationInitialState, fbaIntegrationReducer } from "transfers/fbaIntegration/store/FbaIntegrationReducer";
import { transferCreateInitialState, transferCreateReducer } from "./transfers/create/store/TransferCreateReducer";
import { transferListInitialState, transferListReducer } from "transfers/list/store/TransferListReducer";
import { transferShipmentDetailReducer, transferShipmentInitialState } from "transfers/detail/store/TransferShipmentDetailReducer";
import { wholesaleListInitialState, wholesaleListReducer } from "wholesale/list/store/WholesaleListReducer";
import { wholesaleOrderCreateInitialState, wholesaleOrderCreateReducer } from "wholesale/create/store/WholesaleOrderCreateReducer";
import { SPLIT_INITIAL_STATE } from "common/Split/splitInitialState";
import { combineReducers } from "redux";
import { freightQuoteReducers } from "FreightQuote/store/FreightQuoteReducer";
import { inboundListInitialState } from "inbounds/list/inboundListInitialState";
import { inboundListReducer } from "inbounds/list/InboundListReducer";
import { intlReducer } from "react-intl-redux";
import { messages } from "common/IntlMessages";
import { replenishmentOrderDetailsInitialState } from "./transfers/detail/store/replenishment/ReplenishmentOrderDetailsReducer";
import { replenishmentOrderDetailsReducer } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsReducer";
import { returnsInspectionConfigReducer } from "./returns/policy/store/ReturnsPolicyReducer";
import { shopifyReducer } from "tags/shopify/reducers/shopifyReducer";
import { splitReducer } from "@splitsoftware/splitio-redux";
import { replenishmentNonComplianceInitialState, replenishmentNonComplianceReducer } from "transfers/nonCompliance/ReplenishmentNonComplianceStateReducer";
export const initialState = {
  billing: billingInitialState,
  blade: bladeInitialState,
  channels: salesChannelsInitialState,
  cognito: cognitoInitialState,
  deliverr: deliverrInitialState,
  fastTagFlows: fastTagFlowsInitialState,
  google: googleInitialState,
  inbound: inboundInitialState,
  inboundList: inboundListInitialState,
  productCompliance: productComplianceInitialState,
  inventoryList: inventoryListInitialState,
  inventoryDetail: inventoryDetailInitialState,
  loading: loadingInitialState,
  media: mediaInitialState,
  modal: modalInitialState,
  nonCompliance: nonComplianceInitialState,
  nonComplianceList: nonComplianceListInitialState,
  notifications: notificationsInitialState,
  onboarding: onboardingInitialState,
  orderDetail: orderDetailInitialState,
  orderListV2: orderListInitialState,
  orderNew: newOrderInitialState,
  orderReturns: orderReturnsListInitialState,
  pagination: paginationInitialState,
  parcelDetails: parcelDetailInitialState,
  parcelList: parcelListInitialState,
  prepCreate: prepStoreInitialState,
  prepList: prepListInitialState,
  prepDetails: prepDetailsInitialState,
  progress: navBarProgressInitialState,
  replenishmentOrderDetailsState: replenishmentOrderDetailsInitialState,
  replenishmentNonComplianceState: replenishmentNonComplianceInitialState,
  reports: reportsInitialState,
  returnsCreate: returnsCreateInitialState,
  returnsLabel: returnsLabelInitialState,
  returnsSeller: returnsSellerInitialState,
  returnsDetail: returnsDetailInitialState,
  returnsListV2: returnsListInitialStateV2,
  returnsOnboard: returnsOnboardInitialState,
  returnsRmt: returnsRmtInitalState,
  returnsInspectionConfig: returnsInspectionConfigInitialState,
  settings: initialSettingsData,
  tags: fastTagsInitialState,
  user: userInitialState,
  organization: organizationInitialState,
  admin: adminInitialState,
  costEstimatorModal: costEstimatorInitialState,
  intl: {
    defaultLocale: "en-us",
    locale: navigator.language,
    messages
  },
  shopify: shopifyInitialState,
  wish: wishInitialState,
  storageInboundDetail: storageInboundDetailInitialState,
  storageInboundCreate: storageInboundCreateInitialState,
  transferList: transferListInitialState,
  transferShipmentDetail: transferShipmentInitialState,
  transferCreate: transferCreateInitialState,
  freightQuote: freightQuoteInitialState,
  wholesaleList: wholesaleListInitialState,
  wholesaleOrderCreate: wholesaleOrderCreateInitialState,
  wholesaleOrderDetail: wholesaleOrderDetailInitialState,
  fbaIntegration: fbaIntegrationInitialState,
  freight: freightInitialState,
  splitIo: SPLIT_INITIAL_STATE,
  createShipment: createShipmentInitialState,
  smbShipment: smbShipmentInitialState,
  flexportUser: flexportUserInitialState
};
export const rootReducer = combineReducers({
  billing: billingReducer,
  blade: bladeReducer,
  channels: salesChannelsReducer,
  cognito: cognitoReducer,
  deliverr: deliverrReducer,
  errors: errorsReducer,
  fastTagFlows: fastTagFlowsReducer,
  google: googleReducer,
  inbound: inboundReducer,
  inboundList: inboundListReducer,
  productCompliance: productComplianceReducer,
  intl: intlReducer,
  inventoryList: inventoryListReducer,
  inventoryDetail: inventoryDetailReducer,
  loading: loadingReducer,
  media: mediaReducer,
  modal: modalReducer,
  nonCompliance: nonComplianceReducer,
  nonComplianceList: nonComplianceListReducer,
  notifications: notificationsReducer,
  onboarding: onboardingReducer,
  orderDetail: orderDetailReducer,
  orderListV2: orderListReducer,
  orderNew: newOrderReducer,
  orderReturns: orderReturnsListReducer,
  pagination: paginationReducer,
  parcelDetails: parcelDetailsReducer,
  parcelList: parcelListReducer,
  prepCreate: prepStoreReducer,
  prepList: prepListReducer,
  prepDetails: prepDetailsReducer,
  progress: navBarProgressReducer,
  replenishmentOrderDetailsState: replenishmentOrderDetailsReducer,
  replenishmentNonComplianceState: replenishmentNonComplianceReducer,
  reports: reportsReducer,
  returnsCreate: returnsCreateReducer,
  returnsLabel: returnsLabelReducer,
  returnsDetail: returnsDetailReducer,
  returnsListV2: returnsListReducerV2,
  returnsSeller: returnsSellerReducer,
  returnsOnboard: returnsOnboardReducer,
  returnsRmt: returnsRmtReducer,
  returnsInspectionConfig: returnsInspectionConfigReducer,
  settings: settingsReducer,
  tags: fastTagsReducer,
  user: userReducer,
  organization: organizationReducer,
  admin: adminReducer,
  costEstimatorModal: costEstimatorReducer,
  shopify: shopifyReducer,
  wish: wishReducer,
  storageInboundDetail: storageInboundDetailReducer,
  storageInboundCreate: storageInboundCreateReducer,
  transferList: transferListReducer,
  transferShipmentDetail: transferShipmentDetailReducer,
  transferCreate: transferCreateReducer,
  freightQuote: freightQuoteReducers,
  wholesaleList: wholesaleListReducer,
  wholesaleOrderCreate: wholesaleOrderCreateReducer,
  wholesaleOrderDetail: wholesaleOrderDetailReducer,
  fbaIntegration: fbaIntegrationReducer,
  freight: freightReducer,
  splitIo: splitReducer,
  createShipment: createShipmentReducer,
  smbShipment: smbShipmentReducer,
  flexportUser: flexportUserReducer
});