import { useSelector } from "react-redux";
import { getIsLoading } from "../../../common/components/WithLoader/LoadingSelectors";
import { useEstimationsStatus } from "../../../transfers/create/steps/common/useEstimationsStatus";
import { CreateWholesaleLoader } from "../CreateWholesaleLoader.types";
import { loadWholesaleOrderAction } from "../actions/loadWholesaleOrder";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
export const useWholesaleEstimationsStatus = () => {
  const {
    wholesaleOrder
  } = useSelector(selectWholesaleCreate);
  const isCreateOrderLoading = useSelector(getIsLoading)(CreateWholesaleLoader.CreateWholesaleOrderLoader);
  return {
    ...useEstimationsStatus(wholesaleOrder, CreateWholesaleLoader.CreateWholesaleOrderLoader, loadWholesaleOrderAction),
    isCreateOrderLoading
  };
};