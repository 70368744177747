export const getDefaultDimensions = products => products.reduce((dict, {
  dsku,
  length,
  width,
  height,
  weight
}) => ({
  ...dict,
  [dsku]: {
    length,
    width,
    height,
    weight
  }
}), {});