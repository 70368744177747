import { useEffect, useMemo } from "react";
import { useAsync } from "react-use";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { productClient } from "Clients";
import log from "Logger";
export const useFetchProduct = ({
  dsku
}) => {
  const {
    formatMessage
  } = useIntl();
  const ctx = useMemo(() => ({
    fn: "useFetchProduct",
    dsku
  }), [dsku]);
  const {
    value,
    loading: isLoading,
    error
  } = useAsync(async () => {
    if (!dsku) {
      return;
    }
    log.info({
      ...ctx
    }, "getting product");
    return await productClient.getProduct(dsku);
  }, [dsku]);
  useEffect(() => {
    if (error) {
      log.warn({
        ...ctx,
        error
      }, "error fetching product");
      toast.error(formatMessage({
        id: "product.fetchError",
        defaultMessage: "There was an issue fetching your product"
      }));
    }
  }, [ctx, error, formatMessage]);
  return {
    value,
    isLoading,
    error
  };
};