export const getEstimatedLabelCost = (returnItems, estimatedTotalCost) => {
  return returnItems.reduce((acc, item) => {
    acc -= (item.inspectionRate ?? 0) * item.quantity;
    return acc;
  }, estimatedTotalCost);
};
export const getNumberOfItems = returnItems => {
  return returnItems?.reduce((acc, returnItem) => {
    acc += returnItem.quantity;
    return acc;
  }, 0) ?? 0;
};