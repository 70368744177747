import styled from "@emotion/styled";
export const UnstyledButton = styled.button(({
  theme,
  highlightTitle
}) => `
    appearance: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    text-align: left;


    ${highlightTitle ? `&:hover .card-title {
        color: ${theme.colors.BLUE["300"]};
        transition: color 250ms linear; 
      }` : ""}
  `);