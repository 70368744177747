import { useModal } from "common/hooks/useModal";
import { useHistory } from "react-router";
import { fastTagPagePath } from "tags/model/path/fastTagPagePath";
import { processUnsyncPage } from "../ProcessUnsync/processUnsyncPage";
export const confirmUnsyncModalId = "confirm-unsync-facebook-ad-sets";
export const useConfirmUnsyncModal = () => {
  const {
    hideModal,
    showModal
  } = useModal(confirmUnsyncModalId);
  const history = useHistory();
  const onCancelClick = hideModal;
  const onContinueClick = () => {
    history.push(fastTagPagePath(processUnsyncPage));
  };
  return {
    modalId: confirmUnsyncModalId,
    onCancelClick,
    onContinueClick,
    showModal
  };
};