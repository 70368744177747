export const SIOC_DIMENSIONS_LIMITS = {
  weight: 20,
  length: 18,
  width: 14,
  height: 8
};
export const OVERSIZED_RELAXED_MEASUREMENT_LIMITS = {
  weight: 200,
  length: 100,
  width: 250,
  height: 100
};
export const BOX_MEASUREMENT_LIMITS = {
  weight: 50,
  length: 30,
  width: 40,
  height: 30
};