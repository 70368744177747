import { ProductPrepCategory } from "@deliverr/commons-clients/lib/product/ProductPreparation";
import { productCategoriesContent } from "common/content/productCategoriesContent";
import { sortBy } from "lodash/fp";
import { useIntl } from "react-intl";
export const useProductCategoryOptions = () => {
  const {
    formatMessage
  } = useIntl();
  const options = sortBy(
  // sort OTHER to the bottom
  [({
    value
  }) => value === ProductPrepCategory.OTHER], Object.keys(ProductPrepCategory).map(key => {
    const value = ProductPrepCategory[key];
    return {
      value,
      label: formatMessage(productCategoriesContent[value])
    };
  }));
  return {
    categorySelectOptions: options
  };
};