import { SharedConfirmedStep } from "common/wholesale-common/components/SharedConfirmedStep";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { createOrUpdateReplenishmentOrder } from "./actions/replenishmentOrder/createOrUpdateReplenishmentOrder";
import { ReplenishmentInvalidOrderItems } from "./steps/common/replenishment/details/invalid-items/ReplenishmentInvalidOrderItems";
import { submitReplenishmentOrder } from "./actions/replenishmentOrder/submitReplenishmentOrder";
import { ReplenishmentOutOfStockOrderItems } from "./steps/common/replenishment/details/out-of-stock-items/ReplenishmentOutOfStockOrderItems";
import { resolveOutOfStockOrderItems } from "./actions/resolveOutOfStockOrderItems";
export let SpsV2Steps;
(function (SpsV2Steps) {
  SpsV2Steps["InvalidItem"] = "sps/v2/invalidItem";
  SpsV2Steps["OutOfStockItems"] = "sps/v2/outOfStockItems";
  SpsV2Steps["Confirmed"] = "sps/v2/confirmed";
})(SpsV2Steps || (SpsV2Steps = {}));
export const spsV2Steps = (dispatch, getState) => ({
  [SpsV2Steps.InvalidItem]: {
    component: ReplenishmentInvalidOrderItems,
    next: async () => {
      if ((await createOrUpdateReplenishmentOrder(dispatch, getState, true)) && (await submitReplenishmentOrder(dispatch, getState))) {
        // update this to go to out of stock step
        return SpsV2Steps.Confirmed;
      }
      return undefined;
    }
  },
  [SpsV2Steps.OutOfStockItems]: {
    component: ReplenishmentOutOfStockOrderItems,
    next: async () => {
      if ((await resolveOutOfStockOrderItems(dispatch, getState)) && (await createOrUpdateReplenishmentOrder(dispatch, getState, true)) && (await submitReplenishmentOrder(dispatch, getState))) {
        return SpsV2Steps.Confirmed;
      }
      return undefined;
    }
  },
  [SpsV2Steps.Confirmed]: {
    component: SharedConfirmedStep(wholesaleCreateLabels),
    hasNoBack: true
  }
});