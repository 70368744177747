import { wholesaleClient } from "../../Clients";
import { logError } from "../../Logger";
import { WholesaleErrorType } from "./utils/WholesaleErrorTypes";
export const sharedSubmitWholesaleOrder = ({
  setError,
  selector
}) => async (dispatch, getState) => {
  const {
    wholesaleOrder
  } = selector(getState());
  if (!wholesaleOrder?.id) {
    return false;
  }
  try {
    await wholesaleClient.submitOrderAndShipments(wholesaleOrder.id, undefined, false);
    dispatch(setError(WholesaleErrorType.NONE));
    return true;
  } catch (err) {
    dispatch(setError(WholesaleErrorType.UNTYPED));
    logError({
      fn: "submitWholesaleOrder"
    }, err);
    return false;
  }
};