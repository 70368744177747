import { isNil } from "lodash/fp";
import { HazmatInformationStatus } from "@deliverr/commons-clients";
import { isDimsConfirmed } from "common/utils/product/isDimsConfirmed";
import { hasBarcodes } from "common/utils/product/hasBarcodes";
import { defineMessages } from "react-intl";
import { FBA_MAX_QUANTITY_IN_CASE } from "common/constants/fbaValidations.const";
export let ProductError;
(function (ProductError) {
  ProductError["AlreadySelected"] = "AlreadySelected";
  ProductError["OutOfStock"] = "OutOfStock";
  ProductError["InvalidDimensions"] = "InvalidDimensions";
  ProductError["Hazmat"] = "Hazmat";
  ProductError["NoBarcode"] = "NoBarcode";
  ProductError["MissingAsin"] = "MissingAsin";
  ProductError["MissingWalmartChannelId"] = "MissingWalmartChannelId";
  ProductError["LargeCasePack"] = "LargeCasePack";
  ProductError["PackVariantSelected"] = "PackVariantSelected";
  ProductError["MaximumSelectableCaseQty"] = "MaximumSelectableCaseQty";
})(ProductError || (ProductError = {}));
export const ProductErrorMessages = defineMessages({
  [ProductError.AlreadySelected]: {
    id: "ProductSearch.alreadySelected",
    defaultMessage: "Already Selected"
  },
  [ProductError.OutOfStock]: {
    id: "ProductSearch.zeroAvailable",
    defaultMessage: "Out of stock"
  },
  [ProductError.InvalidDimensions]: {
    id: "ProductSearch.invalidDimensions",
    defaultMessage: "Invalid Dimensions"
  },
  [ProductError.Hazmat]: {
    id: "ProductSearch.hazmat",
    defaultMessage: "Hazmat {status}"
  },
  [ProductError.NoBarcode]: {
    id: "ProductSearch.noBarcode",
    defaultMessage: "No Barcodes"
  },
  [ProductError.MissingAsin]: {
    id: "ProductSearch.missingAsin",
    defaultMessage: "Missing ASIN"
  },
  [ProductError.MissingWalmartChannelId]: {
    id: "ProductSearch.missingWalmartChannelId",
    defaultMessage: "Not synced with Walmart"
  },
  [ProductError.LargeCasePack]: {
    id: "ProductSearch.largeCasePack",
    defaultMessage: "Large Case Pack"
  },
  [ProductError.PackVariantSelected]: {
    id: "ProductSearch.packVariantSelected",
    defaultMessage: "Only one case pack configuration can be added per order"
  },
  [ProductError.MaximumSelectableCaseQty]: {
    id: "ProductSearch.maximumSelectableCaseQty",
    defaultMessage: "Case quantity of pack exceeds requested inventory"
  }
});
const ProductErrorPredicates = {
  [ProductError.AlreadySelected]: ({
    product,
    selectedSkus
  }) => !!selectedSkus?.includes(product.raw.dsku),
  [ProductError.LargeCasePack]: ({
    product,
    applyFbaConditions,
    isIntegratedFBA
  }) => !!applyFbaConditions && !!product.caseQty && !!isIntegratedFBA && product.caseQty > FBA_MAX_QUANTITY_IN_CASE,
  [ProductError.OutOfStock]: ({
    product,
    allowZeroInventorySelection,
    isReserveStorage
  }) => !allowZeroInventorySelection && (isReserveStorage ? !product.raw.storageOnHandQty || product.raw.storageOnHandQty <= 0 : !product.raw.onHandQty || product.raw.onHandQty <= 0),
  [ProductError.InvalidDimensions]: ({
    product,
    isDimsRequired
  }) => Boolean(isDimsRequired) && !isDimsConfirmed(product.raw.dimSourceType),
  [ProductError.Hazmat]: ({
    product
  }) => !!product.hazmatInformation?.status && product.hazmatInformation.status !== HazmatInformationStatus.APPROVED,
  [ProductError.NoBarcode]: ({
    product,
    isBarcodeRequired
  }) => Boolean(isBarcodeRequired) && !hasBarcodes(product.raw.barcodes),
  [ProductError.MissingWalmartChannelId]: ({
    product,
    withWalmartChannelIdOnly
  }) => !!withWalmartChannelIdOnly && !product?.productAliases?.find(productAlias => productAlias.channelId === "WALMARTDIRECT" && productAlias.channelProductId),
  [ProductError.MissingAsin]: ({
    product,
    applyFbaConditions,
    isIntegratedFBA
  }) => !!isIntegratedFBA && !!applyFbaConditions && !product.asin,
  [ProductError.PackVariantSelected]: ({
    product,
    selectedPackSkus,
    showOnlyPacks,
    excludedPackOfSkusForStorage
  }) => !!showOnlyPacks && (!!selectedPackSkus?.includes(product.raw.packOf) || !!excludedPackOfSkusForStorage?.includes(product.raw.packOf)),
  [ProductError.MaximumSelectableCaseQty]: ({
    product,
    showOnlyPacks,
    storageMaximumSelectableCaseQty
  }) => !!showOnlyPacks && !isNil(storageMaximumSelectableCaseQty) && !isNaN(storageMaximumSelectableCaseQty) && storageMaximumSelectableCaseQty < product.caseQty
};
export const getProductError = params => Object.keys(ProductErrorPredicates).find(err => ProductErrorPredicates[err](params));