/**
 * @generated SignedSource<<7abf3eafef0e1e1fe9425ad550c84a62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

const node = function () {
  var v0 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAddressOnly"
    },
    v1 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    v2 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    v3 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    v4 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    },
    v5 = [{
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "isAddressOnly",
        "variableName": "isAddressOnly"
      }, {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }],
      "concreteType": "OriginSearchResults",
      "kind": "LinkedField",
      "name": "originSearch",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "IpbCity",
        "kind": "LinkedField",
        "name": "cities",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetAddress",
          "storageKey": null
        }, v2 /*: any*/, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }, v3 /*: any*/, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zipCode",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        }, v4 /*: any*/],

        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "OriginNearbyPorts",
        "kind": "LinkedField",
        "name": "ports",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "IpbCity",
          "kind": "LinkedField",
          "name": "city",
          "plural": false,
          "selections": [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],

          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "concreteType": "OriginNearbyPort",
          "kind": "LinkedField",
          "name": "nearbyPorts",
          "plural": true,
          "selections": [{
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "abbr",
            "storageKey": null
          }, {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fid",
            "storageKey": null
          }],
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "distanceKm",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }];
  return {
    "fragment": {
      "argumentDefinitions": [v0 /*: any*/, v1 /*: any*/],

      "kind": "Fragment",
      "metadata": null,
      "name": "usePlaceSearchOriginQuery",
      "selections": v5 /*: any*/,
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": [v1 /*: any*/, v0 /*: any*/],

      "kind": "Operation",
      "name": "usePlaceSearchOriginQuery",
      "selections": v5 /*: any*/
    },

    "params": {
      "cacheID": "2fa90e534d9b91d7901501222bb768d4",
      "id": null,
      "metadata": {},
      "name": "usePlaceSearchOriginQuery",
      "operationKind": "query",
      "text": "query usePlaceSearchOriginQuery(\n  $query: String\n  $isAddressOnly: Boolean\n) {\n  originSearch(query: $query, isAddressOnly: $isAddressOnly) {\n    cities {\n      address\n      streetAddress\n      city\n      state\n      country\n      zipCode\n      latitude\n      longitude\n      countryCode\n    }\n    ports {\n      city {\n        city\n        country\n        countryCode\n      }\n      nearbyPorts {\n        abbr\n        fid\n      }\n      distanceKm\n    }\n  }\n}\n"
    }
  };
}();
node.hash = "4e612eec6307c62c9e657d405b1d4c28";
export default node;