// Method required during transition period from transportationClient to freightClient
export const makeQuoteRequest = (freightQuoteRequestOptions, createdBy) => {
  const {
    numberOfTrucks,
    plan,
    internalUserId,
    ...quoteRequestOptions
  } = freightQuoteRequestOptions;
  const serviceRequest = plan.map(({
    service
  }) => ({
    type: service,
    numberOfTrucks
  }));
  return {
    ...quoteRequestOptions,
    serviceRequest,
    createdBy
  };
};