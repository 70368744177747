import AlgoliaService from "common/list/AlgoliaService";
import { SearchService } from "../SearchService";
export class AlgoliaSearchService extends SearchService {
  constructor(config) {
    super();
    this.algoliaService = AlgoliaService.get({
      indexName: config.indexName,
      searchConfig: config.searchConfig,
      highlightMatches: config.highlightMatches
    });
  }
  clearCache() {
    this.algoliaService.clearCache();
    return this;
  }
  async execute(request) {
    if (request.clearCacheOnSearch) {
      this.algoliaService.clearCache();
    }
    return await this.algoliaService.search(request.searchTerm, request.page, request.sort, request.filters, request.numericFilter, request.pageSize);
  }
  async searchByIds(ids, query, idField, resultsSize) {
    return await this.algoliaService.searchByIds(ids, query, idField, resultsSize);
  }
  async getRecordByObjectId(objectId) {
    return await this.algoliaService.getRecordByObjectId(objectId);
  }
  async getAllRecordsByObjectIds(objectIds) {
    return await this.algoliaService.getAllRecordsByObjectIds(objectIds);
  }
}