import { getContentType } from "./getContentType";
export const uploadFile = async (url, file) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/pdf");
  return await fetch(url, {
    method: "PUT",
    headers,
    body: file,
    mode: "cors"
  });
};
export const uploadSheet = async (url, file, fileExtension) => {
  const headers = new Headers();
  const acceptedContentType = getContentType(fileExtension);
  if (acceptedContentType) {
    headers.append("Content-Type", acceptedContentType);
    return await fetch(url, {
      method: "PUT",
      headers,
      body: file,
      mode: "cors"
    });
  } else {
    throw new Error(`Invalid file extension: ${fileExtension}`);
  }
};