import { createActionCreator } from "common/ReduxUtils";
export let FastTagsActionTypes;
(function (FastTagsActionTypes) {
  FastTagsActionTypes["ENABLE_NEXT"] = "ENABLE_NEXT";
  FastTagsActionTypes["DISABLE_NEXT"] = "DISABLE_NEXT";
  FastTagsActionTypes["GET_STATUS"] = "GET_STATUS";
  FastTagsActionTypes["UPDATE_LOCAL_TAGS"] = "UPDATE_LOCAL_TAGS";
  FastTagsActionTypes["SHOP_CONNECTION_STATUS_RECEIVED"] = "SHOP_CONNECTION_STATUS_RECEIVED";
})(FastTagsActionTypes || (FastTagsActionTypes = {}));
export let Enable;
(function (Enable) {
  Enable["NextButton"] = "NextButton";
  Enable["Transition"] = "Transition";
})(Enable || (Enable = {}));
export const enableNext = createActionCreator(FastTagsActionTypes.ENABLE_NEXT, "enableType", "propName");
export const disableNext = createActionCreator(FastTagsActionTypes.DISABLE_NEXT, "enableType", "propName");
export const getStatus = createActionCreator(FastTagsActionTypes.GET_STATUS, "program", "programProps");
export const updateLocalTags = createActionCreator(FastTagsActionTypes.UPDATE_LOCAL_TAGS);
export const shopConnectionStatusReceived = createActionCreator(FastTagsActionTypes.SHOP_CONNECTION_STATUS_RECEIVED, "fastTag", "shops");