import { logStart, logError } from "Logger";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
import { inboundClient } from "Clients";
export const updateShipmentQuantity = (sellerId, shipmentId, dsku, quantity) => async dispatch => {
  const ctx = logStart({
    fn: "updateShipmentQuantity",
    shipmentId,
    dsku,
    quantity
  });
  try {
    await inboundClient.editShipmentExpectedQuantities(sellerId, shipmentId, {
      [dsku]: quantity
    });
    dispatch({
      shipmentId,
      dsku,
      quantity,
      type: InboundActionTypes.UPDATE_SHIPMENT_QUANTITY
    });
  } catch (err) {
    logError(ctx, err, "error updating shipment quantity");
    throw err;
  }
};