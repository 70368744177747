import { ApiClient } from "../core/ApiClient";
import { DeliverrError } from "@deliverr/commons-objects";
import { InventoryError } from "./InventoryError";
import { chunk } from "lodash/fp";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
const DEFAULT_HEADERS = {
  "X-Request-Caller": "seller-portal",
  "X-Direct-Caller": "seller-portal"
};
const INVENTORY_CLIENT_BATCH_LIMIT = 50;
export class InventoryClient {
  DEFAULT_POOL = "DEFAULT";
  constructor({
    baseURL,
    headers
  } = {
    baseURL: mustBeDefined(process.env.INVENTORY_SERVICE_URL),
    headers: DEFAULT_HEADERS
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/api/v1/inventory`,
      headers
    });
  }
  async getATP(dskus, getAgingData, poolName) {
    try {
      const atpMapArr = await Promise.all(chunk(INVENTORY_CLIENT_BATCH_LIMIT, dskus).map(async dskuChunk => await this.apiClient.get({
        url: "/stockkeepingunits/atp/",
        params: {
          dskus: dskuChunk.join(","),
          getAgingData,
          poolName: poolName ?? this.DEFAULT_POOL
        },
        authentication: isAuthenticated
      })));
      return atpMapArr.reduce((acc, atpMap) => ({
        ...acc,
        ...atpMap
      }), {});
    } catch (ex) {
      switch (ex.response.status) {
        case 404:
          return {};
        default:
          throw new Error(ex);
      }
    }
  }
  async getWarehouseATP(skus) {
    try {
      return await this.apiClient.post({
        url: "/stockkeepingunits/atp/warehouse",
        body: {
          skus
        },
        authentication: isAuthenticated
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 404:
          return {};
        default:
          throw new DeliverrError({
            code: ex.response.data.code,
            functionName: "getWarehouseATP",
            payload: ex.response.data
          });
      }
    }
  }

  /**
   * Returns number of available and unavailable skus for sale, given a seller id
   * @param sellerId - the sellerId to get inventory summary
   */
  async getInventorySummary(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/stockkeepingunits/summary/${sellerId}`,
        params: {},
        authentication: isAuthenticated
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 400:
          throw new DeliverrError(InventoryError.BAD_REQUEST);
        case 404:
          throw new DeliverrError(InventoryError.UNKNOWN_SELLER);
        default:
          throw new Error(ex);
      }
    }
  }
  async getAvailableSkusForSeller(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/stockkeepingunits/available/${sellerId}`,
        params: {},
        authentication: isAuthenticated
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 400:
          throw new DeliverrError(InventoryError.BAD_REQUEST);
        case 404:
          throw new DeliverrError(InventoryError.UNKNOWN_SELLER);
        default:
          throw new Error(ex);
      }
    }
  }
  async getATPAvailableForSeller(seller, poolName) {
    try {
      return await this.apiClient.get({
        url: `/stockkeepingunits/atp/available`,
        params: {
          seller,
          poolName: poolName ?? this.DEFAULT_POOL
        },
        authentication: isAuthenticated
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 400:
          throw new DeliverrError(InventoryError.BAD_REQUEST);
        case 404:
          throw new DeliverrError(InventoryError.UNKNOWN_SELLER);
        default:
          throw new Error(ex);
      }
    }
  }
}