import { isDebuggable } from "common/Config";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { initialState, rootReducer } from "RootReducer";
const middleware = [thunk];
if (process.env.ENV === "development") {
  middleware.push(createLogger({
    collapsed: true,
    diff: true,
    timestamp: false
  }));
}
const middlewareCallstack = applyMiddleware(...middleware);
const middlewareSetup = isDebuggable ? composeWithDevTools(middlewareCallstack) : middlewareCallstack;
export const makeStore = initState => createStore(rootReducer, initState, middlewareSetup);
const store = makeStore(initialState);
export default store;