import styled from "@emotion/styled";
import { isMobile } from "../shared/helpers/utils";
import { HeaderElement, TextAlign } from "../shared";
const setSize = ({
  displayAs,
  theme
}) => {
  const sizes = {
    [HeaderElement.h1]: `
      font-size: ${theme.font.size.F7};
      letter-spacing: ${theme.font.letterSpacing.LS4};
      line-height: ${theme.font.lineHeight.LH7};
    `,
    [HeaderElement.h2]: `
      font-size: ${theme.font.size.F6};
      letter-spacing: ${theme.font.letterSpacing.LS3};
      line-height: ${theme.font.lineHeight.LH6};
    `,
    [HeaderElement.h3]: `
      font-size: ${theme.font.size.F5};
      letter-spacing: ${theme.font.letterSpacing.LS0};
      line-height: ${theme.font.lineHeight.LH5};
    `,
    [HeaderElement.h4]: `
      font-size: ${theme.font.size.F4};
      letter-spacing: ${theme.font.letterSpacing.LS0};
      line-height: ${theme.font.lineHeight.LH4};
    `,
    [HeaderElement.h5]: `
      font-size: ${theme.font.size.F3};
      letter-spacing: ${theme.font.letterSpacing.LS4};
      line-height: ${theme.font.lineHeight.LH3};
      font-weight: ${isMobile(theme) ? theme.font.weight.BOLD : theme.font.weight.BLACK};
    `
  };
  return sizes[displayAs];
};
export const Title = styled.h1(({
  displayAs = HeaderElement.h1,
  uppercase,
  textAlign = TextAlign.start,
  theme
}) => `
  font-family: ${theme.font.family.TITLE};
  font-weight: ${theme.font.weight.BOLD};
  text-transform: ${uppercase ? `uppercase` : `none`};
  ${setSize({
  displayAs,
  theme
})}
  text-align: ${textAlign};
`);