import { productClient } from "Clients";
import { filter, isEmpty } from "lodash";
import { transferCreateGetUnifiedProduct } from "transfers/create/actions";

// move this to action
export const getUnifiedProducts = dskus => async (dispatch, getState) => {
  const cachedProductDetails = getState().transferCreate.unifiedProductResponseCache ?? {};
  const remainingDsku = filter(dskus, dsku => !cachedProductDetails[dsku]);
  if (!isEmpty(remainingDsku)) {
    const unifiedResponse = await productClient.getUnifiedProducts(remainingDsku, {
      includeProductCasePacks: true
    });
    const dskuProductDetails = buildResponse(dskus, cachedProductDetails, unifiedResponse);
    dispatch(transferCreateGetUnifiedProduct({
      ...cachedProductDetails,
      ...dskuProductDetails
    }));
    return dskuProductDetails;
  }
  return buildResponse(dskus, cachedProductDetails, {});
};
const buildResponse = (dskus, cachedProductDetails, unifiedResponse) => {
  return dskus.reduce((acc, dsku) => {
    if (!acc[dsku]) {
      acc[dsku] = cachedProductDetails[dsku];
    }
    return acc;
  }, unifiedResponse);
};