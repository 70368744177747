import { defineMessages } from "react-intl";
export const shipmentCreateLabels = {
  steps: {
    confirm: defineMessages({
      error: {
        id: "shipment.create.steps.confirm.error",
        defaultMessage: "We were unable to generate your quote."
      },
      contactSupportWithId: {
        id: "shipment.create.steps.confirm.errorSupportRequestId",
        defaultMessage: "Please {linkText} with Order ID: {orderId}"
      },
      contactSupportLinkText: {
        id: "shipment.create.steps.confirm.contactSupportLinkText",
        defaultMessage: "Contact Support"
      },
      contentsTitle: {
        id: "shipment.create.steps.confirm.contentsTitle",
        defaultMessage: "Contents"
      },
      contentsDescription: {
        id: "shipment.create.steps.confirm.contentsDescription",
        defaultMessage: "{numOfSkus, plural, one {# SKU} other {# SKUs}}{br}{numOfCases, plural, one {# box} other {# boxes}}{br}{numOfUnits, plural, one {# unit} other {# units}}"
      },
      contentsDescWithoutNumOfCases: {
        id: "shipment.create.steps.confirm.contentsDescWithoutNumOfCases",
        defaultMessage: "{numOfSkus, plural, one {# SKU} other {# SKUs}}{br}{numOfUnits, plural, one {# unit} other {# units}}"
      },
      fromTitle: {
        id: "shipment.create.steps.confirm.fromTitle",
        defaultMessage: "From"
      },
      toTitle: {
        id: "shipment.create.steps.confirm.toTitle",
        defaultMessage: "To"
      },
      shippingTitle: {
        id: "shipment.create.steps.confirm.shippingTitle",
        defaultMessage: "Shipping method"
      },
      shipByTitle: {
        id: "shipment.create.steps.confirm.shipByTitle",
        defaultMessage: "Shipping by"
      },
      carrierTitle: {
        id: "shipment.create.steps.confirm.fba.carrierTitle",
        defaultMessage: "Carrier"
      }
    }),
    labels: defineMessages({
      duplicateLabelsError: {
        id: "shipment.create.steps.duplicateLabels.error",
        defaultMessage: "Attachment cannot be uploaded. Attachment already exists."
      }
    })
  }
};