import { fromPairs } from "lodash/fp";
import { updateOrganizationClaims } from "../../organization/OrganizationActions";
export let OnboardingStage;
(function (OnboardingStage) {
  OnboardingStage["ListingTool"] = "listingTool";
  OnboardingStage["ImportingCatalog"] = "importingCatalog";
  OnboardingStage["AllSet"] = "allSet";
  OnboardingStage["CompletedInbound"] = "completedInbound";
  OnboardingStage["CreatedInbound"] = "createdInbound";
})(OnboardingStage || (OnboardingStage = {}));
export const ONBOARDING_STAGES = Object.values(OnboardingStage);
export const BASE_ONBOARDING_STAGE = JSON.stringify(fromPairs(ONBOARDING_STAGES.map(stage => [stage, false])));
export const createNewOnboardingStage = (stage, getState) => {
  const currentOnboardingStage = getState().organization.onboardingStage;
  return {
    ...currentOnboardingStage,
    [stage]: true
  };
};
export const updateOnboardingStage = newOnboardingStage => {
  return updateOrganizationClaims({
    onboardingStage: newOnboardingStage
  });
};