import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export class NotificationClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.NOTIFICATION_CLIENT_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getNotifications(sellerId, limit, offset, excludeBy, excludes) {
    return await this.apiClient.get({
      url: `/v2/notifications/sellers/${encodeURIComponent(sellerId)}`,
      params: {
        limit,
        offset,
        excludeBy,
        excludes
      },
      authentication: isAuthenticated
    });
  }
}