import { isEmpty } from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { wholesaleCreateAddProduct, wholesaleCreateRemoveProduct } from "../actions/WholesaleCreateActions";
import { FeatureName, isFeatureOn } from "common/Split";
import { selectWholesaleCreate } from "../store/WholesaleCreateSelectors";
import { getAvailableInventoryInEachStorageWarehouse } from "../store/getAvailableInventoryInEachStorageWarehouse";
export const useWholesalePickProductStep = () => {
  const dispatch = useDispatch();
  const {
    selectedProducts
  } = useSelector(selectWholesaleCreate);
  const dskuQuantities = Object.keys(selectedProducts).map(dsku => ({
    dsku
  }));
  const isMultiPackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isNextDisabled = isEmpty(selectedProducts);
  const addProduct = (dsku, product) => {
    const dskuList = [...dskuQuantities, {
      dsku
    }];
    dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiPackEnabled));
    return dispatch(wholesaleCreateAddProduct(dsku, product));
  };
  const removeProduct = dsku => {
    const dskuList = dskuQuantities.filter(dskuQuantity => dskuQuantity.dsku !== dsku);
    dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiPackEnabled));
    return dispatch(wholesaleCreateRemoveProduct(dsku));
  };
  return {
    addProduct,
    selectedProducts: Object.values(selectedProducts),
    removeProduct,
    isNextDisabled,
    isMultiPackEnabled,
    dskuQuantities
  };
};