import { isFeatureOn, FeatureName } from "common/Split";
import { BRANDED_PACKAGING_FILTER, MULTI_CASE_PACK_FILTER } from "./InventoryListActions";
import { InventoryLocationFilterOptions } from "./InventoryListTableControls/InventoryListFilters/InventoryLocationFilter";
import { constructOpenSearchFilters } from "common/search/services/InventorySearchUtils";
export const getInventoryListFilters = params => {
  if (isFeatureOn(FeatureName.LogisticsSearchInventoryListing)) {
    return getInventoryListOpenSearchFilters(params);
  }
  return getInventoryListAlgoliaFilters(params);
};
function getInventoryListAlgoliaFilters({
  isActive,
  inventoryLocation,
  currentTab
}) {
  const isActiveFilter = isActive ? "NOT isHiddenOnSellerPortal=1" : "isHiddenOnSellerPortal=1";
  const numericFilter = getNumericFilterByInventoryLocation(inventoryLocation);
  const filters = [isActiveFilter, BRANDED_PACKAGING_FILTER, MULTI_CASE_PACK_FILTER];
  if (isFeatureOn(FeatureName.PrepKittingEnabled) && (currentTab === "PRODUCTS" || currentTab === "KITS")) {
    filters.push(currentTab === "KITS" ? "isKit=1" : "NOT isKit=1");
  }
  const customizedOpenSearchFilters = [];
  if (currentTab === "ELIGIBLE_FOR_REMOVAL") {
    const removalFilters = getAlgoliaFiltersForRemoval();
    filters.push(...removalFilters);
  }
  if (inventoryLocation === InventoryLocationFilterOptions.RESERVE_STORAGE) {
    filters.push(`hasInventoryOnPacks:true`);
  }
  return {
    filters,
    numericFilter,
    customizedOpenSearchFilters
  };
}
function getNumericFilterByInventoryLocation(inventoryLocation) {
  if (inventoryLocation === InventoryLocationFilterOptions.ECOM_FULFILLMENT) {
    return ["onHandQty", ">=", 1];
  } else if (inventoryLocation === InventoryLocationFilterOptions.AMAZON_FBA) {
    return ["fbaInventory.fulfillableQuantity", ">=", 1];
  } else if (inventoryLocation === InventoryLocationFilterOptions.WALMART_WFS) {
    return ["wfsInventory.fulfillableQuantity", ">=", 1];
  }
  return undefined;
}
function getInventoryListOpenSearchFilters({
  searchTerm,
  isActive,
  inventoryLocation,
  currentTab
}) {
  const customizedOpenSearchFilters = constructOpenSearchFilters({
    searchTerm,
    isHiddenOnSellerPortal: !isActive,
    inventoryLocation,
    currentTab
  });
  const numericFilter = getNumericFilterByInventoryLocation(inventoryLocation);
  return {
    filters: [],
    numericFilter,
    customizedOpenSearchFilters
  };
}
function getAlgoliaFiltersForRemoval() {
  const VALID_DIMS_FILTER = "(dimSourceType:warehouse OR dimSourceType:manual OR dimSourceType:seller OR dimSourceType:averageregression OR dimSourceType:crossdock OR dimSourceType:amazonscraped)";
  const HAZMAT_INFORMATION_FILTER = "(NOT hazmatInformation.status:INCOMPLETE AND NOT hazmatInformation.status:PENDING AND NOT hazmatInformation.status:REJECTED)";
  return [VALID_DIMS_FILTER, HAZMAT_INFORMATION_FILTER];
}