export let ParcelListActionTypes;
(function (ParcelListActionTypes) {
  ParcelListActionTypes["SEARCH_PARCELS_SUCCESS"] = "SEARCH_PARCELS_SUCCESS";
  ParcelListActionTypes["SEARCH_PARCELS_ERROR"] = "SEARCH_PARCELS_ERROR";
  ParcelListActionTypes["SET_PARCELS_SORT"] = "SET_PARCELS_SORT";
  ParcelListActionTypes["SET_CURRENT_PARCEL_SEARCH_TERM"] = "SET_CURRENT_PARCEL_SEARCH_TERM";
  ParcelListActionTypes["LOAD_PARCEL_LIST"] = "LOAD_PARCEL_LIST";
  ParcelListActionTypes["SET_CURRENT_PARCEL_PAGE"] = "SET_CURRENT_PARCEL_PAGE";
  ParcelListActionTypes["SET_PARCEL_DATE_FILTER"] = "SET_PARCEL_DATE_FILTER";
  ParcelListActionTypes["SET_PARCEL_STATUS_FILTER"] = "SET_PARCEL_STATUS_FILTER";
  ParcelListActionTypes["SET_PARCEL_TAB"] = "SET_PARCEL_TAB";
  ParcelListActionTypes["SET_PARCEL_SELECTED_ROWS"] = "SET_PARCEL_SELECTED_ROWS";
  ParcelListActionTypes["SET_PARCEL_IS_PAGE_SELECTED"] = "SET_PARCEL_IS_PAGE_SELECTED";
  ParcelListActionTypes["SELECT_PARCEL_ROW"] = "SELECT_PARCEL_ROW";
  ParcelListActionTypes["UPDATE_FLEX_ID_FOR_PARCELIDS"] = "UPDATE_FLEX_ID_FOR_PARCELIDS";
  ParcelListActionTypes["REMOVE_PARCELS_FROM_LIST"] = "REMOVE_PARCELS_FROM_LIST";
  ParcelListActionTypes["REMOVE_CSV_PARCELS_FROM_LIST"] = "REMOVE_CSV_PARCELS_FROM_LIST";
})(ParcelListActionTypes || (ParcelListActionTypes = {}));