import { defineMessages } from "react-intl";
import { InventoryLocationFilterOptions } from "./InventoryLocationFilterOptions";
export const INVENTORY_LOCATION_DISPLAY_TEXT = defineMessages({
  [InventoryLocationFilterOptions.ALL_LOCATIONS]: {
    defaultMessage: "All locations",
    id: `inventoryLocationFilterOption.${InventoryLocationFilterOptions.ALL_LOCATIONS}`
  },
  [InventoryLocationFilterOptions.ECOM_FULFILLMENT]: {
    defaultMessage: "Ecom Fulfillment",
    id: `inventoryLocationFilterOption.${InventoryLocationFilterOptions.ECOM_FULFILLMENT}`
  },
  [InventoryLocationFilterOptions.RESERVE_STORAGE]: {
    defaultMessage: "Reserve Storage",
    id: `inventoryLocationFilterOption.${InventoryLocationFilterOptions.RESERVE_STORAGE}`
  },
  [InventoryLocationFilterOptions.AMAZON_FBA]: {
    defaultMessage: "Amazon (FBA)",
    id: `inventoryLocationFilterOption.${InventoryLocationFilterOptions.AMAZON_FBA}`
  },
  [InventoryLocationFilterOptions.WALMART_WFS]: {
    defaultMessage: "Walmart (WFS)",
    id: `inventoryLocationFilterOption.${InventoryLocationFilterOptions.WALMART_WFS}`
  }
});