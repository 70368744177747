import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ReturnsCancelTypes } from "../../cancel/store/ReturnsCancelStore.types";
import { ReturnsDetailTypes } from "./ReturnsDetailStore.types";
import { ReturnStatus } from "@deliverr/returns-client";
import { defineMessages } from "react-intl";
import { emptyAddress } from "common/AddressUtils";
export const errorMessage = defineMessages({
  returnCancelError: {
    id: "returns.cancel.failure",
    defaultMessage: "Unable to cancel the return"
  }
});
const returnsCancelInitialState = {
  error: undefined,
  returnCancelSuccess: false
};
export const returnsDetailInitialState = {
  returnItemDetails: {},
  returnsDetail: {
    id: 0,
    items: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    packages: [],
    deliverrSellerId: "",
    fulfilledBy: "",
    fulfillmentOrderId: "",
    channelId: "",
    channelOrderId: "",
    rmtId: "",
    rmtReturnId: "",
    from: emptyAddress,
    to: emptyAddress,
    status: ReturnStatus.CREATED,
    estimatedTotalCost: 0
  },
  isLoading: false,
  returnsCancel: {
    ...returnsCancelInitialState
  },
  returnsQcDetails: {
    returnsQcItems: [],
    isQcProcessed: false
  }
};
const reducers = {
  ...handleSimpleReducerUpdates([ReturnsDetailTypes.RETURN_GET_ITEM_DETAILS_SUCCESS]),
  [ReturnsDetailTypes.RETURNS_GET_DETAILS]: state => ({
    ...state,
    isLoading: true
  }),
  [ReturnsDetailTypes.RETURNS_GET_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    returnsDetail: {
      ...state.returnsDetail,
      ...action.returnsDetail
    },
    returnsQcDetails: {
      ...action.returnsQcDetails
    },
    returnsProblemDetails: {
      ...action.returnsProblemDetails
    },
    isLoading: false
  }),
  [ReturnsDetailTypes.RESET_RETURN_DETAILS]: state => ({
    ...state,
    ...returnsDetailInitialState
  }),
  [ReturnsDetailTypes.RETURNS_GET_DETAILS_ERROR]: state => ({
    ...state,
    isLoading: false
  }),
  [ReturnsCancelTypes.RETURNS_CANCEL_SUCCESS]: state => ({
    ...state,
    returnsDetail: {
      ...state.returnsDetail,
      status: ReturnStatus.CANCELLED
    },
    returnsCancel: {
      ...state.returnsCancel,
      returnCancelSuccess: true
    }
  }),
  [ReturnsCancelTypes.RETURNS_CANCEL_FAIL]: state => ({
    ...state,
    returnsCancel: {
      ...state.returnsCancel,
      error: errorMessage
    }
  }),
  [ReturnsCancelTypes.RETURNS_CANCEL_RESET]: state => ({
    ...state,
    returnsCancel: {
      ...returnsCancelInitialState
    }
  })
};
export const returnsDetailReducer = createReducer(returnsDetailInitialState, reducers);