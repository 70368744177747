export let ContentElement;
(function (ContentElement) {
  ContentElement["section"] = "section";
  ContentElement["article"] = "article";
  ContentElement["header"] = "header";
  ContentElement["footer"] = "footer";
  ContentElement["div"] = "div";
  ContentElement["span"] = "span";
  ContentElement["p"] = "p";
  ContentElement["li"] = "li";
})(ContentElement || (ContentElement = {}));
export let HeaderElement;
(function (HeaderElement) {
  HeaderElement["h1"] = "h1";
  HeaderElement["h2"] = "h2";
  HeaderElement["h3"] = "h3";
  HeaderElement["h4"] = "h4";
  HeaderElement["h5"] = "h5";
  HeaderElement["h6"] = "h6";
})(HeaderElement || (HeaderElement = {}));
export let ListElement;
(function (ListElement) {
  ListElement["ol"] = "ol";
  ListElement["li"] = "li";
})(ListElement || (ListElement = {}));