import isPast from "date-fns/isPast";
import { useIntl } from "react-intl";
export const useSimpleFieldValidators = () => {
  const {
    formatMessage
  } = useIntl();
  const requiredErrorMessage = formatMessage({
    id: "reactHookForm.validators.required",
    defaultMessage: "Required."
  });
  const validateRequired = customMessage => value => {
    if (value === null || value === undefined || typeof value === "string" && value.trim() === "") {
      return customMessage ?? requiredErrorMessage;
    }
    return undefined;
  };

  // if value can be zero, use validateRequired instead, or custom validator
  const validateRequiredNumber = customMessage => value => {
    if (!value) {
      return customMessage ?? requiredErrorMessage;
    }
    return undefined;
  };
  const validateGreaterThan = (min, customMessage) => value => {
    if (value < min) {
      return customMessage ?? formatMessage({
        id: "reactHookForm.validators.greaterThan",
        defaultMessage: "Cannot be less than {min}."
      }, {
        min
      });
    }
    return undefined;
  };
  const validateLessThan = (max, customMessage) => value => {
    if (value > max) {
      return customMessage ?? formatMessage({
        id: "reactHookForm.validators.lessThan",
        defaultMessage: "Cannot be greater than {max}."
      }, {
        max
      });
    }
    return undefined;
  };
  const validateFutureDate = customMessage => value => {
    if (isPast(new Date(value))) {
      return customMessage ?? formatMessage({
        id: "reactHookForm.validators.futureDate",
        defaultMessage: "Cannot be in the past."
      });
    }
    return undefined;
  };
  return {
    validateRequired,
    validateRequiredNumber,
    validateGreaterThan,
    validateLessThan,
    validateFutureDate
  };
};