import log from "Logger";
import { StorageInboundDetailActionTypes } from "../StorageInboundDetailStore.types";
import { isEmpty } from "lodash";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
export const getStorageItemDetails = storageItems => async dispatch => {
  try {
    const dskus = storageItems.map(storageItem => storageItem.dsku);
    if (isEmpty(dskus)) {
      return;
    }
    const storageItemDetails = await fetchMultiPackProductsByDskus(dskus, dispatch);
    dispatch({
      type: StorageInboundDetailActionTypes.STORAGE_GET_ITEM_DETAILS_SUCCESS,
      storageItemDetails
    });
  } catch (err) {
    log.error({
      err
    }, "error getting storage item details");
    dispatch({
      type: StorageInboundDetailActionTypes.STORAGE_GET_ITEM_DETAILS_ERROR,
      err
    });
  }
};