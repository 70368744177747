import axios from "axios";
import { stringify } from "qs";
import { partialRight } from "lodash";
import { withAuthentication } from "./withAuthentication";
export class ApiClient {
  constructor({
    baseURL,
    headers
  }) {
    this.axios = axios.create({
      baseURL,
      headers,
      paramsSerializer: partialRight(stringify, {
        arrayFormat: "repeat"
      })
    });
  }
  async get({
    url,
    params,
    authentication,
    responseType
  }) {
    const config = {
      url,
      params,
      responseType
    };
    const response = await this.axios.request(await withAuthentication({
      authentication
    })(config));
    return response.data;
  }
  async patch({
    url,
    body: data,
    params,
    authentication
  }) {
    const config = {
      method: "PATCH",
      url,
      params,
      data
    };
    const response = await this.axios.request(await withAuthentication({
      authentication
    })(config));
    return response.data;
  }
  async post({
    url,
    body: data,
    params,
    authentication
  }) {
    const config = {
      method: "POST",
      url,
      params,
      data
    };
    const response = await this.axios.request(await withAuthentication({
      authentication
    })(config));
    return response.data;
  }
  async delete({
    url,
    params,
    body: data,
    authentication
  }) {
    const config = {
      method: "DELETE",
      url,
      data,
      params
    };
    const response = await this.axios.request(await withAuthentication({
      authentication
    })(config));
    return response.data;
  }
  async put({
    url,
    body: data,
    params,
    authentication
  }) {
    const config = {
      method: "PUT",
      url,
      data,
      params
    };
    const response = await this.axios.request(await withAuthentication({
      authentication
    })(config));
    return response.data;
  }

  /**
   * For now, since the ApiClient axios http requests have some issues (return only data part but not the whole response, made the ApiResponse type not work properly),
   * we simply add this method to get the whole response. This is a temporary solution and should be replaced once the ApiClient is fixed.
   *
   */
  async getWithWholeResponse({
    url,
    params,
    authentication,
    responseType
  }) {
    const config = {
      url,
      params,
      responseType
    };
    const response = await this.axios.request(await withAuthentication({
      authentication
    })(config));
    return response;
  }
  async putWithWholeResponse({
    url,
    body: data,
    params,
    authentication
  }) {
    const config = {
      method: "PUT",
      url,
      data,
      params
    };
    const response = await this.axios.request(await withAuthentication({
      authentication
    })(config));
    return response;
  }
  async postWithWholeResponse({
    url,
    body: data,
    params,
    authentication
  }) {
    const config = {
      method: "POST",
      url,
      data,
      params
    };
    return await this.axios.request(await withAuthentication({
      authentication
    })(config));
  }
}