import { TransferShipmentDetailActionTypes } from "./TransferShipmentDetailActionTypes";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import produce from "immer";
import { TransferToEcomDetailActionTypes } from "./TransferToEcomDetailActionTypes";
export const transferShipmentInitialState = {
  transferToEcomOrder: {
    sellerId: "",
    estimatedForwardingFee: 0,
    estimatedRetrievalFee: 0,
    sourceWarehouseId: "",
    storageTransferItems: [],
    estimatedBoxLabellingFee: 0
  },
  isMutationError: false,
  isMutating: 0,
  isUploading: false
};
const reducers = {
  ...handleSimpleReducerUpdates([TransferShipmentDetailActionTypes.TRANSFER_GET_DETAILS_SUCCESS_OLD, TransferShipmentDetailActionTypes.TRANSFER_GET_DETAILS_SUCCESS, TransferShipmentDetailActionTypes.TRANSFER_DETAILS_UPLOADING]),
  [TransferShipmentDetailActionTypes.TRANSFER_MUTATING_CLEAR]: state => produce(state, draft => {
    draft.isMutating = Math.max(0, draft.isMutating - 1);
  }),
  [TransferShipmentDetailActionTypes.TRANSFER_MUTATING]: state => produce(state, draft => {
    draft.isMutating++;
  }),
  [TransferShipmentDetailActionTypes.TRANSFER_REDIRECT_TO_INBOUND_ERROR]: state => produce(state, draft => {}),
  [TransferToEcomDetailActionTypes.TRANSFER_TO_ECOM_GET_DETAILS_SUCCESS]: (state, {
    transferToEcomOrder
  }) => produce(state, draft => {
    draft.transferToEcomOrder = transferToEcomOrder;
  }),
  [TransferShipmentDetailActionTypes.TRANSFER_DETAILS_CLEAR_ERROR]: state => produce(state, draft => {
    draft.errorReason = undefined;
    draft.isMutationError = false;
  }),
  [TransferShipmentDetailActionTypes.TRANSFER_DETAILS_SET_ERROR]: (state, payload) => produce(state, draft => {
    draft.errorReason = payload.errorReason;
    draft.isMutationError = true;
  }),
  [TransferToEcomDetailActionTypes.RESET_TRANSFER_TO_ECOM_DETAILS]: state => ({
    ...state,
    ...transferShipmentInitialState
  })
};
export const transferShipmentDetailReducer = createReducer(transferShipmentInitialState, reducers);