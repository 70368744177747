import { getLocationType } from "./getLocationType";
import { getFbaTradingPartner } from "./getFbaTradingPartner";
export const formatQuoteRequest = ({
  sellerId,
  pickupAddress,
  pickupLocationType,
  pickupLiftgateRequired,
  deliveryAddress,
  deliveryLocationType,
  deliveryLiftgateRequired,
  numberOfPallets
}) => ({
  sellerId,
  pickupDetails: {
    zip: pickupAddress.zip,
    locationType: getLocationType(pickupLocationType) ?? undefined,
    liftGateRequired: pickupLiftgateRequired,
    address: pickupAddress
  },
  deliveryDetails: {
    zip: deliveryAddress.zip,
    locationType: getLocationType(deliveryLocationType) ?? undefined,
    liftGateRequired: deliveryLiftgateRequired,
    address: deliveryAddress,
    tradingPartner: getFbaTradingPartner(deliveryLocationType) ?? undefined
  },
  quantity: numberOfPallets
});