import { FeatureName, isFeatureOn } from "common/Split";
import { getIsAdmin, getSellerId } from "common/user/UserSelectors";
import { useSelector } from "react-redux";
import { getStorageInboundDetail } from "../store";
import { selectStorageBarcodePrintItems } from "./selectStorageBarcodePrintItems";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { ERROR_TOAST_AUTO_CLOSE_TIME } from "common/utils/toasts/toasts";
export const useStorageAdminBarcodeDownload = () => {
  const {
    formatMessage
  } = useIntl();
  const isAdmin = useSelector(getIsAdmin);
  const loggedInSellerId = useSelector(getSellerId);
  const {
    storageInboundOrder: {
      sellerId: storageSellerId,
      shippingPlanId
    }
  } = useSelector(getStorageInboundDetail);
  const printItems = useSelector(selectStorageBarcodePrintItems);
  const isSellerEnabled = isFeatureOn(FeatureName.StorageAdminBarcodeDownload);
  const shouldSeeBarcodeDownload = isAdmin && isSellerEnabled && storageSellerId === loggedInSellerId;
  const validateDownload = () => {
    if (printItems.some(({
      barcode
    }) => !barcode)) {
      toast.error(formatMessage({
        id: "inbounds.storage.storageAdminBarcodeDownload.fnskuError",
        defaultMessage: "One or more products in this inbound do not have a FNSKU or ASIN."
      }), {
        autoClose: ERROR_TOAST_AUTO_CLOSE_TIME
      });
      return false;
    }
    return true;
  };
  return {
    shouldSeeBarcodeDownload,
    printItems,
    filename: `${loggedInSellerId}-${shippingPlanId}-barcodes`,
    validateDownload
  };
};