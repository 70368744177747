import styled from "@emotion/styled";
import { colors } from "../shared/theme/color";
export let PillColors;
(function (PillColors) {
  PillColors["GRAY"] = "GRAY";
  PillColors["DARKGRAY"] = "DARKGRAY";
  PillColors["BLUE"] = "BLUE";
  PillColors["PURPLE"] = "PURPLE";
  PillColors["ORANGE"] = "ORANGE";
  PillColors["YELLOW"] = "YELLOW";
  PillColors["GREEN"] = "GREEN";
  PillColors["RED"] = "RED";
})(PillColors || (PillColors = {}));
const colorMap = {
  [PillColors.GRAY]: {
    text: colors.NEUTRAL["400"],
    bg: colors.NEUTRAL["40"]
  },
  [PillColors.DARKGRAY]: {
    text: colors.NEUTRAL["400"],
    bg: colors.NEUTRAL["60"]
  },
  [PillColors.BLUE]: {
    text: colors.BLUE["400"],
    bg: colors.BLUE["50"]
  },
  [PillColors.PURPLE]: {
    text: colors.PURPLE["400"],
    bg: colors.PURPLE["100"]
  },
  [PillColors.ORANGE]: {
    text: colors.ORANGE["400"],
    bg: colors.ORANGE["100"]
  },
  [PillColors.YELLOW]: {
    text: colors.YELLOW["500"],
    bg: colors.YELLOW["100"]
  },
  [PillColors.GREEN]: {
    text: colors.GREEN["400"],
    bg: colors.GREEN["100"]
  },
  [PillColors.RED]: {
    text: colors.RED["400"],
    bg: colors.RED["100"]
  }
};
const setColor = ({
  color
}) => {
  const selectedColor = colorMap[color];
  return `
    background-color: ${selectedColor.bg};
    border-color: ${selectedColor.bg};
    color: ${selectedColor.text};
  `;
};
export const Pill = styled.div(({
  color = "BLUE",
  theme
}) => `
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 12px;
  border: 1px solid;
  padding: ${theme.spacing.S1} ${theme.spacing.S2};
  font-size: ${theme.font.size.F1};
  font-family: ${theme.font.family.STANDARD};
  font-weight: ${theme.font.weight.BOLD};
  ${setColor({
  color
})}
`);