import { OrderTag } from "@deliverr/commons-objects";
import { pricerClient } from "common/clients/instances";
import { OrderError } from "@deliverr/fulfillment-order-client";
import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
import { RemovalMethods, shippingMethodsMap } from "./new/ShippingMethods";
export const DISPLAYABLE_ERRORS = [OrderError.STOCKOUT, OrderError.STOCKOUT_AT_INGESTION, OrderError.BAD_ADDRESS, OrderError.PARTIALLY_SHIPPED, OrderError.DSKU_NEVER_INBOUNDED];
export const isDisplayableError = error => DISPLAYABLE_ERRORS.includes(error);
export const isProcessing = (status, error) => status === OrderStatus.CREATED || status === OrderStatus.ERROR && error && !isDisplayableError(error);
export const getServiceLevelForOrder = async order => {
  if (order.tags?.includes(OrderTag.INVENTORY_REMOVAL)) {
    return shippingMethodsMap[RemovalMethods.REMOVAL].name;
  }
  const service = await pricerClient.getServiceLevelFromOrder(order);
  return service;
};
export const getServiceLevelsForOrders = async orders => {
  const serviceLevelsRes = await pricerClient.getServiceLevelFromOrders(orders);
  return orders.map((order, i) => order.tags?.includes(OrderTag.INVENTORY_REMOVAL) ? shippingMethodsMap[RemovalMethods.REMOVAL].name : serviceLevelsRes[i]);
};
/* Shopify marketplaceOrderIds will now be orderNumberDELIVERRSPLITorderId
   This function formats it so the orderNumber will be all that is visible
   on the SellerPortal.
*/
export const formatOrderId = marketplaceOrderId => {
  return marketplaceOrderId.includes("DELIVERRSPLIT") ? marketplaceOrderId.split("DELIVERRSPLIT")[0] : marketplaceOrderId;
};
export const orderIdForDisplay = marketplaceOrderId => {
  return marketplaceOrderId.includes("DELIVERRSPLIT") ? marketplaceOrderId.replace("DELIVERRSPLIT", "-") : marketplaceOrderId;
};