import { defineMessages } from "react-intl";
export let FreightQuoteSummaryErrorCodes;
(function (FreightQuoteSummaryErrorCodes) {
  FreightQuoteSummaryErrorCodes["NO_PLAN_FOR_GIVEN_ID"] = "NO_PLAN_FOR_GIVEN_ID";
  FreightQuoteSummaryErrorCodes["SYSTEM_ERROR"] = "SYSTEM_ERROR";
})(FreightQuoteSummaryErrorCodes || (FreightQuoteSummaryErrorCodes = {}));
export const freightQuoteSummaryErrorMessages = defineMessages({
  [FreightQuoteSummaryErrorCodes.NO_PLAN_FOR_GIVEN_ID]: {
    id: "freightQuote.freightQuoteSummary.quoteDoestNotExistError",
    defaultMessage: "Plan ID does not exist."
  },
  [FreightQuoteSummaryErrorCodes.SYSTEM_ERROR]: {
    id: "freightQuote.freightQuoteSummary.systemError",
    defaultMessage: "The system is down and can’t get quotes right now. Try again later."
  }
});