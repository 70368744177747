import { notifyUserOfError } from "common/ErrorToast";
import { logError } from "Logger";
export let FacebookError;
(function (FacebookError) {
  FacebookError["FACEBOOK_LOGIN_FAILED"] = "FACEBOOK_LOGIN_FAILED";
  FacebookError["FACEBOOK_INVALID_PERMISSIONS"] = "FACEBOOK_INVALID_PERMISSIONS";
  FacebookError["FACEBOOK_NO_PAGES"] = "FACEBOOK_NO_PAGES";
  FacebookError["FACEBOOK_NO_AD_ACCOUNTS"] = "FACEBOOK_NO_AD_ACCOUNTS";
  FacebookError["SHOPIFY_PRODUCT_CATALOG_NOT_FOUND"] = "SHOPIFY_PRODUCT_CATALOG_NOT_FOUND";
  FacebookError["MULTIPLE_SHOPIFY_PRODUCT_CATALOGS"] = "MULTIPLE_SHOPIFY_PRODUCT_CATALOGS";
})(FacebookError || (FacebookError = {}));
export const handleInvalidPermissions = () => {
  logError({
    fn: "FacebokFastTag.onLogin"
  }, new Error(FacebookError.FACEBOOK_INVALID_PERMISSIONS));
  notifyUserOfError({
    toastId: "facebookHasValidPermissions",
    translation: {
      id: "FacebookFastTag.HasValidPermissionsError",
      defaultMessage: "Unable to proceed due to a permissions error. Please logout and try again."
    }
  });
};
export const errorMessageMap = {
  SHOPIFY_PRODUCT_CATALOG_NOT_FOUND: {
    id: "FacebookFastTag.ProductCatalogueNotFoundError",
    defaultMessage: "Unable to find shopify product catalog."
  },
  MULTIPLE_SHOPIFY_PRODUCT_CATALOGS: {
    id: "FacebookFastTag.MultipleShopifyProductCatalogError",
    defaultMessage: "An error occurred. Multiple Shopify product catalogs were found."
  }
};