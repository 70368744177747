import { useState } from "react";
import { useSelector } from "react-redux";
import { selectWarehouseAddress } from "wholesale/detail/store/selectWholesaleOrderDetail";
function extractDestinationForSingleShipmentOrder(shipments) {
  return shipments?.[0].destinationWarehouseAddress ?? undefined;
}
export const useReplenishmentShipmentAddressDisplay = ({
  shipments,
  originWarehouseId
}) => {
  const isMultipleShipments = (shipments?.length ?? 0) > 1;
  const toAddress = isMultipleShipments ? undefined : extractDestinationForSingleShipmentOrder(shipments);
  const {
    originAddress
  } = useSelector(selectWarehouseAddress(originWarehouseId));
  const hideDestinationName = toAddress?.name === toAddress?.company;
  const [shouldShowShipmentsModal, setShowShipmentsModal] = useState(false);
  const onShipmentsModalClose = () => {
    setShowShipmentsModal(false);
  };
  const onViewMoreClickonShipmentsModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowShipmentsModal(true);
  };
  return {
    shouldShowShipmentsModal,
    onShipmentsModalClose,
    onViewMoreClickonShipmentsModal,
    toAddress,
    originAddress,
    hideDestinationName,
    isMultipleShipments
  };
};