import { defineMessages } from "react-intl";
import { BoxErrorType } from "./BoxError.types";
import { INBOUND_SPD_MIN_BOX_WEIGHT, INBOUND_SPD_MAX_BOX_WEIGHT, INBOUND_SPD_MIN_BOX_DIM, INBOUND_SPD_MAX_BOX_LONGEST, INBOUND_SPD_MAX_BOX_MIDDLE, INBOUND_SPD_MAX_BOX_SHORTEST } from "inbounds/constants/spdConstants";
import { INBOUND_PKG_MAX_SKUS_PER_BOX } from "inbounds/constants/packageConstants";

/**
 Documentation: https://www.notion.so/Box-Errors-3bd5f816bda54719a75364051223bfdb
 Update table if modifying errors/warnings.
 */

export const BOX_ERROR_LABELS = defineMessages({
  [BoxErrorType.TOO_MANY_BOXED]: {
    id: "inbounds.boxError.tooManyBoxed",
    defaultMessage: "Too many boxed."
  },
  [BoxErrorType.TOO_FEW_BOXED]: {
    id: "inbounds.boxError.tooFewBoxed",
    defaultMessage: "Too few boxed."
  },
  [BoxErrorType.UNDER_WEIGHT]: {
    id: "inbounds.boxError.underweight",
    defaultMessage: "Box weight must be at least {minPackageWeight} lbs.",
    values: {
      minPackageWeight: INBOUND_SPD_MIN_BOX_WEIGHT
    }
  },
  [BoxErrorType.OVER_WEIGHT]: {
    id: "inbounds.boxError.overweight",
    defaultMessage: "Boxes over {maxWeight} lbs cannot be inbounded.",
    values: {
      maxWeight: INBOUND_SPD_MAX_BOX_WEIGHT
    }
  },
  [BoxErrorType.UNDER_DIMENSIONS]: {
    id: "inbounds.boxError.invalidDimensions",
    defaultMessage: "All dimensions must be at least {minMeasurementInches} in.",
    values: {
      minMeasurementInches: INBOUND_SPD_MIN_BOX_DIM
    }
  },
  [BoxErrorType.OVER_DIMENSIONS]: {
    id: "inbounds.boxError.invalidDimensions",
    defaultMessage: `Boxes greater than {maxLength}" x {maxWidth}" x {maxHeight}" cannot be inbounded.`,
    values: {
      maxLength: INBOUND_SPD_MAX_BOX_LONGEST,
      maxWidth: INBOUND_SPD_MAX_BOX_MIDDLE,
      maxHeight: INBOUND_SPD_MAX_BOX_SHORTEST
    }
  },
  [BoxErrorType.EMPTY_FIELDS]: {
    id: "inbounds.boxError.emptyFields",
    defaultMessage: "Fill in all fields."
  },
  [BoxErrorType.ZERO_UNITS]: {
    id: "inbounds.boxError.zeroUnits",
    defaultMessage: "One or more boxes contain zero units"
  },
  [BoxErrorType.TOO_MANY_SKUS]: {
    id: "inbounds.boxError.maxSkusPerBox",
    defaultMessage: "Max {maxSkusPerBox} SKUs per box",
    values: {
      maxSkusPerBox: INBOUND_PKG_MAX_SKUS_PER_BOX
    }
  }
});