import history from "BrowserHistory";
import { getSalesChannels } from "channels/ChannelsActions";
import { onboardingClient, onboardingClientV2 } from "common/clients/instances";
import { JobStatusName } from "common/clients/onboarding/JobStatus/JobStatusName";
import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { notifyUserOfError } from "common/ErrorToast";
import { getItemFromLocalStorageAndParse, LocalStorageKey } from "common/LocalStorage";
import { createNewOnboardingStage, OnboardingStage, updateOnboardingStage } from "common/organization/OnboardingStage";
import { createActionCreator } from "common/ReduxUtils";
import updateUser from "common/user/UpdateUser";
import { getIsFederatedUser } from "common/user/UserSelectors";
import { track } from "common/utils/Analytics";
import { searchProducts } from "inventory/list/InventoryListActions";
import log from "Logger";
import { syncOnboardingStage, updateOrganization } from "organization/OrganizationActions";
import { Path } from "paths";
import { batch } from "react-redux";
import { generatePath } from "react-router-dom";
import { invalidApiPermissions } from "../tags/walmart/WalmartToastErrors";
import { selectCognitoUserId } from "./OnboardingSelectors";
import { getIntegrationSetupContextFromLocalStorage } from "./OnboardingUtils";
const INTEGRATION_SETUP_LOADER_NAME = "listingTool";
export let OnboardingActionTypes;
(function (OnboardingActionTypes) {
  OnboardingActionTypes["SIGNUP_START"] = "SIGNUP_START";
  OnboardingActionTypes["SIGNUP_SUCCESS"] = "SIGNUP_SUCCESS";
  OnboardingActionTypes["SIGNUP_ERROR"] = "SIGNUP_ERROR";
  OnboardingActionTypes["SIGNUP_SELECT"] = "SIGNUP_SELECT";
  OnboardingActionTypes["SET_IS_CHECKLIST_BLADE_OPEN"] = "SET_IS_CHECKLIST_BLADE_OPEN";
})(OnboardingActionTypes || (OnboardingActionTypes = {}));
export const setIsChecklistBladeOpen = createActionCreator(OnboardingActionTypes.SET_IS_CHECKLIST_BLADE_OPEN, "isChecklistBladeOpen");
export const updateListingTool = listingTool => async dispatch => {
  const ctx = {
    fn: "updateListingTool",
    listingTool
  };
  dispatch({
    type: OnboardingActionTypes.SIGNUP_SELECT,
    listingTool
  });
  log.info(ctx, "updateListingTool");
};
export const completeToolSetup = (listingSolution, slsUuid) => async (dispatch, getState) => {
  const {
    user
  } = getState();
  const {
    email,
    sellerId
  } = user;
  const ctx = {
    fn: "completeToolSetup",
    listingSolution,
    sellerId,
    email,
    slsUuid
  };
  log.info(ctx, "start");
  const newStage = createNewOnboardingStage(OnboardingStage.ListingTool, getState);
  batch(() => {
    dispatch(updateOnboardingStage(newStage));
    void dispatch(getSalesChannels());
  });
  log.info(ctx, "redirect to import page");
  history.push(generatePath(Path.onboardingImportingCatalog, {
    slsUuid
  }));
  log.info(ctx, "end");
};
const handleIntegrationError = (err, listingSolution, ctx) => {
  if (err.code === "INVALID_API_KEY") {
    log.info({
      ...ctx,
      err
    }, "invalid api key");
    notifyUserOfError({
      toastId: "invalidApiKeyError",
      translation: {
        defaultMessage: `
            Invalid credentials. Double check that your credentials are correct and reenter.
          `,
        id: "invalidApiKeyError"
      }
    });
  } else if (err.code === "INVALID_PERMISSIONS" && listingSolution === ListingSolution.WALMARTDIRECT) {
    invalidApiPermissions();
    log.info({
      ...ctx,
      err
    }, "invalid Walmart permissions");
  } else {
    log.error({
      ...ctx,
      err
    }, "error during listing tool submit");
    notifyUserOfError({
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toastId: `submitListingToolError-${err.code}`,
      translation: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        defaultMessage: `An error occurred while connecting your listing tool: ${err.code}`,
        id: "submitListingToolError"
      }
    });
  }
};
const startIntegrationSetupRequest = channelId => dispatch => {
  batch(() => {
    dispatch(addLoader(INTEGRATION_SETUP_LOADER_NAME));
    dispatch(updateListingTool(channelId));
  });
};
const finishIntegrationSetupRequest = () => dispatch => {
  dispatch(clearLoader(INTEGRATION_SETUP_LOADER_NAME));
};
const handleIntegrationSetupResponse = ({
  response,
  slsUuid,
  channelId,
  ctx
}) => async (dispatch, getState) => {
  if (response?.oauth_url) {
    log.info({
      ...ctx,
      oauth_url: response.oauth_url
    }, "redirecting to oauth url");
    location.assign(response.oauth_url);
  } else {
    await dispatch(completeToolSetup(channelId, slsUuid ?? response.sls_uuid));
  }
};
export const createIntegration = ({
  channelId,
  apiKey,
  redirectUrl,
  storeName,
  disableFbmFulfilment,
  fbaImport,
  temporaryKey
}) => async (dispatch, getState) => {
  dispatch(startIntegrationSetupRequest(channelId));
  const state = getState();
  const {
    user: {
      sellerId,
      email
    }
  } = state;
  const ctx = {
    fn: "createIntegration",
    sellerId,
    channelId
  };
  try {
    const params = {
      apiKey,
      email,
      redirectUrl,
      storeName,
      channelId,
      temporaryKey,
      disableFbmFulfilment,
      fbaImport
    };
    log.info({
      ...ctx,
      params
    }, "creating integration");
    const response = await onboardingClientV2.createChannel(channelId, sellerId, params);
    await dispatch(handleIntegrationSetupResponse({
      ctx,
      response,
      channelId
    }));
  } catch (error) {
    handleIntegrationError(error, channelId, ctx);
  } finally {
    dispatch(finishIntegrationSetupRequest());
  }
};
export const updateIntegration = ({
  slsUuid,
  channelId,
  apiKey,
  redirectUrl,
  storeName,
  temporaryKey
}) => async (dispatch, getState) => {
  dispatch(startIntegrationSetupRequest(channelId));
  const {
    user: {
      sellerId,
      email
    }
  } = getState();
  const ctx = {
    fn: "updateIntegration",
    sellerId,
    slsUuid,
    channelId
  };
  try {
    const params = {
      apiKey,
      email,
      redirectUrl,
      storeName,
      channelId,
      temporaryKey
    };
    log.info({
      ...ctx,
      params
    }, "updating integration");
    const response = await onboardingClientV2.updateChannel(slsUuid, params);
    await dispatch(handleIntegrationSetupResponse({
      ctx,
      response,
      channelId,
      slsUuid
    }));
  } catch (error) {
    handleIntegrationError(error, channelId, ctx);
  } finally {
    dispatch(finishIntegrationSetupRequest());
  }
};
export const listingToolSubmit = (listingSolution, data) => async (dispatch, getState) => {
  const ctx = {
    fn: "listingToolSubmit",
    sellerId: ""
  };
  const loaderName = "listingTool";
  const state = getState();
  const {
    user,
    cognito: {
      user: {
        username
      }
    },
    channels: {
      salesChannels
    }
  } = state;
  const {
    email,
    sellerId
  } = user;
  ctx.sellerId = sellerId;
  log.info(ctx, "submitting listing tool");
  batch(() => {
    dispatch(addLoader(loaderName));
    dispatch(updateListingTool(listingSolution));
  });
  try {
    const params = [listingSolution, sellerId,
    // federated users (Facebook/Google) query is by cognito username, not email
    {
      ...data,
      email: getIsFederatedUser(state) ? username : email
    }];
    const isFirstSignup = salesChannels.length === 0;
    const response = isFirstSignup ? await onboardingClient.connectListingSolution(...params) : await onboardingClient.addAndActivateSalesChannel(...params);
    if (isFirstSignup) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      updateUser({
        listingTool: listingSolution
      }).catch(err => log.error({
        ...ctx,
        err
      }, `Error updating user`));
    }
    if (response?.oauth_url) {
      log.info({
        ...ctx,
        oauth_url: response.oauth_url
      }, "redirecting to oauth url");
      location.assign(response.oauth_url);
    } else {
      await dispatch(completeToolSetup(listingSolution));
    }
  } catch (err) {
    if (err.code === "INVALID_API_KEY") {
      log.info({
        ...ctx,
        err
      }, "invalid api key");
      notifyUserOfError({
        toastId: "invalidApiKeyError",
        translation: {
          defaultMessage: `
            Invalid credentials. Double check that your credentials are correct and reenter.
          `,
          id: "invalidApiKeyError"
        }
      });
    } else if (err.code === "INVALID_PERMISSIONS" && listingSolution === ListingSolution.WALMARTDIRECT) {
      invalidApiPermissions();
      log.info({
        ...ctx,
        err
      }, "invalid Walmart permissions");
    } else {
      log.error({
        ...ctx,
        err
      }, "error during listing tool submit");
      notifyUserOfError({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        toastId: `submitListingToolError-${err.code}`,
        translation: {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          defaultMessage: `An error occurred while connecting your listing tool: ${err.code}`,
          id: "submitListingToolError"
        }
      });
    }
  } finally {
    dispatch(clearLoader(loaderName));
  }
};
export const importCatalog = slsUuid => async (dispatch, getState) => {
  const state = getState();
  const {
    user: {
      sellerId
    }
  } = state;
  const cognitoUserId = selectCognitoUserId(state);
  const ctx = {
    fn: "importCatalog",
    sellerId,
    email: cognitoUserId,
    slsUuid
  };
  log.info(ctx, "initializing catalog import");

  // use .catch here because don't want to await this request
  return await onboardingClientV2.importCatalog({
    sellerId,
    slsUuid,
    email: cognitoUserId
  }).catch(err => {
    log.error({
      ...ctx,
      err
    }, "error initializing catalog import");
    notifyUserOfError({
      err,
      explanation: "Oops, there was a problem starting your catalog import. " + "Please try refreshing your page to restart your catalog import. " + "If the problem persists, please file a support ticket using the help icon",
      toastId: "initializeCatalogImportError"
    });
  });
};

/**
 * Polls a seller's catalog import status by checking the value of the `isCatalogImported` field on the organization
 * record until it is true. Only to be used for seller's first catalog import as `isCatalogImported` will already be
 * true before subsequent imports happen.
 */
export const pollCatalogImportStatus = () => async (dispatch, getState) => {
  const onboardingListingTool = getItemFromLocalStorageAndParse(LocalStorageKey.OnboardingChannel);
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const intervalId = window.setInterval(async () => {
    const {
      user: {
        isAdmin
      },
      organization: {
        isCatalogImported
      }
    } = getState();
    if (isCatalogImported) {
      batch(() => {
        dispatch(syncOnboardingStage(OnboardingStage.ImportingCatalog));
        // Checks inventory for growth conversion
        dispatch(searchProducts());
        dispatch(getSalesChannels());
      });
      track("Imported Product", isAdmin);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions

      const next = onboardingListingTool === ListingSolution.SHOPIFY ? Path.onboardingShopifySettings : Path.onboardingAllSet;
      history.push(next);
      window.clearInterval(intervalId);
      return;
    }
    batch(() => {
      dispatch(updateOrganization());
    });
  }, 4000);
};
export const pollCatalogUpdateStatus = () => async (dispatch, getState) => {
  const {
    user: {
      isAdmin
    }
  } = getState();
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const intervalId = window.setInterval(async () => {
    const jobStatus = await onboardingClient.getCatalogUpdateStatus();
    const isComplete = jobStatus?.status === JobStatusName.COMPLETE;
    const hasError = jobStatus?.status === JobStatusName.ERROR;
    if (hasError) {
      notifyUserOfError({
        toastId: "importNewProductsError",
        translation: {
          defaultMessage: "Oops. There was a problem importing your new products. Please try again later.",
          id: "importNewProductsError"
        }
      });
    }
    if (isComplete || hasError) {
      track("Existing Seller Channel Integration", isAdmin);
      const channel = getItemFromLocalStorageAndParse(LocalStorageKey.OnboardingChannel);
      const integrationSetupContext = getIntegrationSetupContextFromLocalStorage();
      const flow = integrationSetupContext?.flow ?? "onboarding";
      const onboardingNext = channel === ListingSolution.SHOPIFY ? Path.onboardingShopifySettings : Path.onboardingAllSet;
      const next = flow === "channels" ? Path.channels : onboardingNext;
      dispatch(getSalesChannels());
      history.push(next);
      window.clearInterval(intervalId);
      return;
    }
  }, 4000);
};