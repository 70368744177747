import { createSelector } from "reselect";
import { getIsShipToOne } from "inbounds/steps/ship/view/ViewShipmentSelectors";
import { getLoadedShipment } from "./getLoadedShipment";
export const selectLoadedShipmentPoNumber = createSelector(getLoadedShipment, getIsShipToOne, state => state.inbound.asns, state => state.inbound.crossDockAsn, ({
  id: loadedShipmentId
}, isShipToOne, loadedAsns, crossDockAsn) => {
  const asns = loadedAsns[loadedShipmentId] || [];
  const poNumber = isShipToOne ? crossDockAsn?.id : asns[0]?.id;
  const adminViewPONumbers = asns.map(({
    id
  }) => id).filter(asnId => asnId !== poNumber);
  return {
    poNumber,
    adminViewPONumbers
  };
});