import { AttachmentType, ContentType, ShippingPartner } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { uploadFile } from "transfers/utils/uploadFile";
import { logError, logStart } from "Logger";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { transferCreateSetAttachments } from "transfers/create/actions";
import { useMount } from "react-use";
import { delayBy } from "common/DelayBy";
import { WholesaleShipmentType } from "@deliverr/wholesale-client";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { isInternationalDestination } from "../../utils/isInternationalDestination";
export const useReplenishmentShippingDetailsLabels = ({
  setIsUploadingLabel,
  isUploadingLabel
}) => {
  const {
    replenishmentOrder,
    attachments = {},
    shippingMethod,
    cargoType,
    destinationAddress,
    destinationType
  } = useSelector(selectTransferCreate);
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();
  const shipmentId = replenishmentOrder?.shipments?.[0].shipmentId;
  const isWholesaleOrder = destinationType === TransferCreationTypes.Wholesale;
  const boxLabelFilename = attachments?.[AttachmentType.CASE_LABELS]?.fileName;
  const palletLabelFilename = attachments?.[AttachmentType.PALLET_LABELS]?.fileName;
  const shippingLabelFilename = attachments?.[AttachmentType.SHIPPING_LABELS]?.fileName;
  const commercialInvoiceFileName = attachments?.[AttachmentType.COMMERCIAL_INVOICE]?.fileName;
  const isSellerCaseLabel = shippingMethod === ShippingPartner.SELLER && cargoType === WholesaleShipmentType.PARCEL;
  const destinationCountry = destinationAddress?.country ?? "";
  const isCommercialInvoiceRequired = isInternationalDestination(destinationCountry);
  useMount(() => {
    if (shipmentId) {
      const attachmentDetails = {
        ...attachments
      };
      const promises = [];
      if (!attachmentDetails[AttachmentType.CASE_LABELS]?.url) {
        promises.push(replenishmentClient.requestShipmentAttachmentUploadUrl(shipmentId, {
          attachmentType: AttachmentType.CASE_LABELS,
          contentType: ContentType.PDF
        }).then(shipmentAttachmentUrl => {
          const attachmentType = AttachmentType.CASE_LABELS;
          attachmentDetails[attachmentType] = {
            ...shipmentAttachmentUrl.value,
            attachmentType
          };
        }));
      }
      if (!attachmentDetails[AttachmentType.PALLET_LABELS]?.url && !isSellerCaseLabel) {
        promises.push(replenishmentClient.requestShipmentAttachmentUploadUrl(shipmentId, {
          attachmentType: AttachmentType.PALLET_LABELS,
          contentType: ContentType.PDF
        }).then(shipmentAttachmentUrl => {
          const attachmentType = AttachmentType.PALLET_LABELS;
          attachmentDetails[attachmentType] = {
            ...shipmentAttachmentUrl.value,
            attachmentType
          };
        }));
      }
      if (!attachmentDetails[AttachmentType.SHIPPING_LABELS]?.url && isSellerCaseLabel) {
        promises.push(replenishmentClient.requestShipmentAttachmentUploadUrl(shipmentId, {
          attachmentType: AttachmentType.SHIPPING_LABELS,
          contentType: ContentType.PDF
        }).then(shipmentAttachmentUrl => {
          const attachmentType = AttachmentType.SHIPPING_LABELS;
          attachmentDetails[attachmentType] = {
            ...shipmentAttachmentUrl.value,
            attachmentType
          };
        }));
      }
      if (!attachmentDetails[AttachmentType.COMMERCIAL_INVOICE]?.url && isCommercialInvoiceRequired) {
        promises.push(replenishmentClient.requestShipmentAttachmentUploadUrl(shipmentId, {
          attachmentType: AttachmentType.COMMERCIAL_INVOICE,
          contentType: ContentType.PDF
        }).then(shipmentAttachmentUrl => {
          const attachmentType = AttachmentType.COMMERCIAL_INVOICE;
          attachmentDetails[attachmentType] = {
            ...shipmentAttachmentUrl.value,
            attachmentType
          };
        }));
      }
      Promise.all(promises).catch(() => {
        dispatch(transferCreateSetAttachments(attachments));
      }).finally(() => {
        dispatch(transferCreateSetAttachments(attachmentDetails));
      });
    }
  });
  const onUploadLabel = useCallback(async (file, attachmentType, updatedAttachments, uploadingLabel) => {
    if (Object.values(uploadingLabel).some(isUploading => isUploading)) {
      // wait for previous upload to finish to avoid overlap in delete/upload of same file in different Label
      await delayBy(1000);
    }
    setIsUploadingLabel(state => ({
      ...state,
      [attachmentType]: true
    }));
    const newAttachments = {
      ...updatedAttachments
    };
    const attachmentDetails = {
      ...newAttachments[attachmentType]
    };
    const isDuplicateFile = Object.values(updatedAttachments).some(attachment => attachment?.fileName === file.name);
    if (isDuplicateFile) {
      toast.error(formatMessage({
        id: "replenishment.create.attachmentType.duplicateFile",
        defaultMessage: "File with this name already uploaded."
      }));
      setIsUploadingLabel(state => ({
        ...state,
        [attachmentType]: false
      }));
      return;
    }
    try {
      await uploadFile(attachmentDetails?.url ?? "", file);
      attachmentDetails.isUploadedToS3 = true;
      attachmentDetails.fileName = file.name;
      dispatch(transferCreateSetAttachments({
        ...newAttachments,
        [attachmentType]: {
          ...newAttachments[attachmentType],
          ...attachmentDetails
        }
      }));
    } catch (e) {
      logError({
        fn: "useReplenishmentShippingDetailsStep.onUploadLabel"
      }, e);
      toast.error(formatMessage({
        id: "replenishment.create.attachmentType.uploadError",
        defaultMessage: "Failed to upload file."
      }));
    }
    setIsUploadingLabel(state => ({
      ...state,
      [attachmentType]: false
    }));
  }, [dispatch, formatMessage, setIsUploadingLabel]);
  const onFileClear = useCallback(async (attachmentType, updatedAttachments) => {
    const ctx = logStart({
      fn: "useReplenishmentShippingDetailsStep.onFileClear"
    });
    const newAttachments = {
      ...updatedAttachments
    };
    const attachmentDetails = {
      ...newAttachments[attachmentType]
    };
    if (shipmentId) {
      setIsUploadingLabel(state => ({
        ...state,
        [attachmentType]: true
      }));
      try {
        await replenishmentClient.deleteShipmentAttachment(shipmentId, attachmentType);
        await replenishmentClient.requestShipmentAttachmentUploadUrl(shipmentId, {
          attachmentType,
          contentType: ContentType.PDF
        }).then(shipmentAttachmentUrl => {
          if (attachmentDetails?.attachmentType === attachmentType) {
            attachmentDetails.isUploadedToS3 = false;
            attachmentDetails.fileName = undefined;
            newAttachments[attachmentType] = {
              ...attachmentDetails,
              ...shipmentAttachmentUrl.value,
              attachmentType
            };
            dispatch(transferCreateSetAttachments(newAttachments));
          }
        });
      } catch (e) {
        logError(ctx, e);
        toast.error(formatMessage({
          id: "replenishment.create.attachmentType.deletionError",
          defaultMessage: "Failed to delete file."
        }));
      } finally {
        setIsUploadingLabel(state => ({
          ...state,
          [attachmentType]: false
        }));
      }
    }
  }, [dispatch, formatMessage, shipmentId, setIsUploadingLabel]);
  const onPalletFileChange = useCallback(async files => await onUploadLabel(files[0], AttachmentType.PALLET_LABELS, attachments, isUploadingLabel), [attachments, isUploadingLabel, onUploadLabel]);
  const onShippingFileChange = useCallback(async files => await onUploadLabel(files[0], AttachmentType.SHIPPING_LABELS, attachments, isUploadingLabel), [attachments, isUploadingLabel, onUploadLabel]);
  const onCommercialInvoiceFileChange = useCallback(async files => await onUploadLabel(files[0], AttachmentType.COMMERCIAL_INVOICE, attachments, isUploadingLabel), [attachments, isUploadingLabel, onUploadLabel]);
  const onBoxFileChange = useCallback(async files => await onUploadLabel(files[0], AttachmentType.CASE_LABELS, attachments, isUploadingLabel), [attachments, isUploadingLabel, onUploadLabel]);
  const onPalletFileClear = useCallback(async () => await onFileClear(AttachmentType.PALLET_LABELS, attachments), [attachments, onFileClear]);
  const onShippingFileClear = useCallback(async () => await onFileClear(AttachmentType.SHIPPING_LABELS, attachments), [attachments, onFileClear]);
  const onBoxFileClear = useCallback(async () => await onFileClear(AttachmentType.CASE_LABELS, attachments), [attachments, onFileClear]);
  const onCommercialInvoiceFileClear = useCallback(async () => await onFileClear(AttachmentType.COMMERCIAL_INVOICE, attachments), [attachments, onFileClear]);
  return {
    isSellerCaseLabel,
    isUploadingLabel,
    attachments,
    boxLabelFilename,
    palletLabelFilename,
    onPalletFileChange,
    onBoxFileChange,
    onPalletFileClear,
    onBoxFileClear,
    onShippingFileChange,
    onShippingFileClear,
    shippingLabelFilename,
    isCommercialInvoiceRequired,
    onCommercialInvoiceFileChange,
    onCommercialInvoiceFileClear,
    commercialInvoiceFileName,
    isWholesaleOrder
  };
};