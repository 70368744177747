import { SellerProblemType } from "@deliverr/legacy-inbound-client";
import { UnexpectedSkuCaseSubtype } from "@deliverr/commons-clients/lib/non-compliance/UnexpectedSkuCaseV2";
import { useIntl } from "react-intl";
export function useNonComplianceMessage(problem) {
  const {
    formatMessage
  } = useIntl();
  let nonComplianceMessage;
  if (problem) {
    switch (problem.type) {
      case SellerProblemType.UNEXPECTED_SKU_V2:
        if (problem.subType === UnexpectedSkuCaseSubtype.UNEXPECTED_SKU) {
          nonComplianceMessage = formatMessage({
            id: "inbounds.productDetail.nonCompliance.unexpectedSku",
            defaultMessage: "This product is unexpected."
          });
        } else {
          nonComplianceMessage = formatMessage({
            id: "inbounds.productDetail.nonCompliance.mismatchCaseQty",
            defaultMessage: "This product was received in an unexpected packing configuration."
          });
        }
    }
  }
  return nonComplianceMessage;
}