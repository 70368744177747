import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { NonComplianceListActionTypes } from "./actions/NonComplianceListActionTypes";
export const nonComplianceListInitialState = {
  ncProducts: [],
  missingBarcodeCases: [],
  unexpectedBarcodeCases: [],
  unexpectedSkuCases: []
};
const reducers = {
  ...handleSimpleReducerUpdates([NonComplianceListActionTypes.SET_UNKNOWN_BARCODE_NC_CASES, NonComplianceListActionTypes.SET_MISSING_BARCODE_NC_CASES, NonComplianceListActionTypes.SET_UNEXPECTED_BARCODE_NC_CASES, NonComplianceListActionTypes.SET_UNEXPECTED_SKU_NC_CASES])
};
export const nonComplianceListReducer = createReducer(nonComplianceListInitialState, reducers);