import { combineReducers } from "redux";
import { freightBookingReducer } from "./freightBooking/reducer/FreightBookingReducer";
import { freightBookingInitialState } from "./freightBooking/reducer/freightBookingInitialState";
import { freightOrderDetailInitialState, freightOrderReducer } from "./orderDetail/FreightOrderReducer";
import { freightListInitialState, freightListReducer } from "./freightList/reducer/FreightListReducer";
import { freightQuotingReducer } from "./freightQuoting/reducer/FreightQuotingReducer";
import { freightQuotingInitialState } from "./freightQuoting/reducer/freightQuotingInitialState";
export const freightInitialState = {
  list: freightListInitialState,
  order: freightOrderDetailInitialState,
  booking: freightBookingInitialState,
  quoting: freightQuotingInitialState
};
export const freightReducer = combineReducers({
  order: freightOrderReducer,
  booking: freightBookingReducer,
  list: freightListReducer,
  quoting: freightQuotingReducer
});