import { defineMessages } from "react-intl";
export const ProductCasePackSelectMessages = defineMessages({
  defaultCasePackName: {
    id: "productCasePack.options.defaultCasePackName",
    defaultMessage: `{units, plural,
      one {# unit}
      other {# units}
    } per box`
  },
  casePackSubtitle: {
    id: "productCasePack.options.casePackSubtitle",
    defaultMessage: `{units, plural,
      one {# unit}
      other {# units}
    } per box · {dimsInfo}`
  },
  casePackSubtitleWithInventory: {
    id: "productCasePack.options.casePackSubtitleWithInventory",
    defaultMessage: `{units, plural,
      one {# unit}
      other {# units}
    } per box · {dimsInfo} · {inventoryUnits, plural,
      one {# unit available}
      other {# units available}
      }`
  }
});