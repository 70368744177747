import { ListActionTypes } from "common/list/ListActions";
import { appendListType } from "common/list/ListType";
import { getSelectedListItemValues } from "common/list/ListUtils";
import { handleSimpleReducerUpdates } from "common/ReduxUtils";
import { compact, mapKeys } from "lodash";
export const listInitialState = {
  searchTerm: "",
  listItems: [],
  page: 0,
  listItemCount: 0,
  itemsPerPage: 25,
  totalPages: 0,
  pageChecked: false,
  selectedListItems: {},
  selectedListItemCount: 0
};
const countSelectedListItems = selectedListItems => getSelectedListItemValues(selectedListItems).length;
const reducers = {
  ...handleSimpleReducerUpdates([ListActionTypes.SEARCH_INDEX, ListActionTypes.SET_SORT, ListActionTypes.SET_PAGE, ListActionTypes.SET_SEARCH_TERM]),
  [ListActionTypes.SET_PAGE_CHECKED]: (state, {
    checked
  }) => {
    const newSelectedHits = {
      ...state.selectedListItems,
      [state.page]: checked ? state.listItems.slice() : []
    };
    return {
      ...state,
      selectedListItems: newSelectedHits,
      selectedListItemCount: countSelectedListItems(newSelectedHits),
      pageChecked: checked
    };
  },
  [ListActionTypes.SET_PAGE_CHECKED_PARTIALLY_REMOVAL_LIST]: (state, {
    checked
  }) => {
    const newSelectedHits = {
      ...state.selectedListItems,
      [state.page]: checked ? state.listItems.map(item => item.onHandQty > 0 && item.onHandQty - item.unfilledOrderQty - item.unavailableQty > 0 ? item : undefined) : []
    };
    return {
      ...state,
      selectedListItems: newSelectedHits,
      selectedListItemCount: countSelectedListItems(newSelectedHits),
      pageChecked: checked
    };
  },
  [ListActionTypes.SET_HIT_CHECKED]: (state, {
    checked,
    hitIndex
  }) => {
    const {
      page,
      listItems,
      selectedListItems
    } = state;
    const newSelectedHits = {
      ...selectedListItems,
      [page]: {
        ...selectedListItems[page],
        [hitIndex]: checked ? listItems[hitIndex] : undefined
      }
    };
    return {
      ...state,
      selectedListItems: newSelectedHits,
      selectedListItemCount: countSelectedListItems(newSelectedHits),
      pageChecked: compact(Object.values(newSelectedHits[page])).length === listItems.length
    };
  },
  [ListActionTypes.CLEAR_ALL_CHECKED]: state => ({
    ...state,
    selectedListItems: {},
    selectedListItemCount: 0,
    pageChecked: false
  })
};
export const createListReducers = listType => mapKeys(reducers, (_, actionType) => appendListType(actionType, listType));