import { FlexportShipmentStatus } from "../../FlexportShipmentStatus";
export const getFlexportShipmentStatusText = shipmentStatus => {
  switch (shipmentStatus) {
    case FlexportShipmentStatus.NEED_PACKAGE_DETAILS:
      return {
        defaultMessage: "In draft",
        id: "flexportShipmentStatus.needPackageDetails"
      };
    case FlexportShipmentStatus.READY_FOR_WAREHOUSE:
      return {
        defaultMessage: "Ready to ship",
        id: "flexportShipmentStatus.readyToShip"
      };
    case FlexportShipmentStatus.IN_TRANSIT:
      return {
        defaultMessage: "In transit",
        id: "flexportShipmentStatus.inTransit"
      };
    case FlexportShipmentStatus.IN_PREP:
      return {
        defaultMessage: "With prep",
        id: "flexportShipmentStatus.inPrep"
      };
    case FlexportShipmentStatus.RECEIVING:
      return {
        defaultMessage: "Receiving",
        id: "flexportShipmentStatus.receiving"
      };
    case FlexportShipmentStatus.COMPLETED:
      return {
        defaultMessage: "Completed",
        id: "flexportShipmentStatus.completed"
      };
    case FlexportShipmentStatus.CANCELED:
      return {
        defaultMessage: "Canceled",
        id: "flexportShipmentStatus.canceled"
      };
    case FlexportShipmentStatus.ACTION_NEEDED:
      return {
        defaultMessage: "Action needed",
        id: "flexportShipmentStatus.actionNeeded"
      };
    default:
      return {
        defaultMessage: "In progress",
        id: "flexportShipmentStatus.unknownBookingStatus"
      };
  }
};