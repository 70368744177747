import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { GoogleActionTypes } from "tags/google/GoogleActions";
export const googleInitialState = {
  activationError: undefined,
  supplementalFeedError: undefined,
  merchantIdError: undefined,
  isActive: false,
  merchantId: undefined,
  productFeedId: undefined,
  isActiveForAds: false,
  isActiveForShoppingActions: false,
  isEligibleForShoppingActions: false
};
const reducers = {
  ...handleSimpleReducerUpdates([GoogleActionTypes.UPDATE_GOOGLE_ACTIVATION_ERROR, GoogleActionTypes.UPDATE_GOOGLE_SUPPLEMENTAL_FEED_ERROR, GoogleActionTypes.UPDATE_GOOGLE_SELLER, GoogleActionTypes.GET_GOOGLE_SELLER_CONFIGS, GoogleActionTypes.UPDATE_GOOGLE_MERCHANT_ID_ERROR, GoogleActionTypes.GOOGLE_SERVICE_ACCOUNT_EMAIL_RECEIVED])
};
export const googleReducer = createReducer(googleInitialState, reducers);