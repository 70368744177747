export let InventoryError;
(function (InventoryError) {
  InventoryError["DSKU_ALREADY_EXISTS"] = "DSKU_ALREADY_EXISTS";
  InventoryError["UNKNOWN_SKU"] = "UNKNOWN_SKU";
  InventoryError["STOCKOUT"] = "STOCKOUT";
  InventoryError["INVENTORY_OUT_OF_SYNC"] = "INVENTORY_OUT_OF_SYNC";
  InventoryError["UNKNOWN_SELLER"] = "UNKNOWN_SELLER";
  InventoryError["INSUFFICIENT_INVENTORY_AVAILABLE"] = "INSUFFICIENT_INVENTORY_AVAILABLE";
  InventoryError["UNKNOWN_WAREHOUSE"] = "UNKNOWN_WAREHOUSE";
  InventoryError["BAD_REQUEST"] = "BAD_REQUEST";
})(InventoryError || (InventoryError = {}));