/**
 * Truncate a number to a specified number of decimal points.
 * Works for "flooring" the value at the specified decimal place as opposed
 * to toFixed which rounds.
 * Taken from: https://stackoverflow.com/a/4912870
 *
 * @param value number to truncate
 * @param numDecimals number of decimal places to truncate at (default: 2)
 */
export const truncateDecimal = (value, numDecimals = 2) => {
  if (value === undefined || value === null) {
    return;
  }
  const decimalRegex = new RegExp(`(\\d+\\.\\d{${numDecimals}})(\\d)`);
  const matched = value.toString().match(decimalRegex);
  return matched ? parseFloat(matched[1]) : value.valueOf();
};