import { generatePath } from "react-router-dom";
import { createSelector } from "reselect";
import { inArray, isTrue } from "common/utils/helpers";
import { all, filter, find, flatMap, identity, isUndefined, orderBy, pipe, prop, propEq, propOr, uniq, useWith, sortBy, includes } from "lodash/fp";
import { getSellerId, selectIsFederatedShopifyUser } from "common/user/UserSelectors";
import { listingConfigProp } from "common/ListingSolutionUtils";
import { salesChannelLoaderId } from "./ChannelsActions";
import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { FastTagState } from "common/clients/fastTag/FastTagState";
import { SalesChannelConnectionStatus } from "common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
import { SalesChannelType } from "common/clients/onboarding/SellerSalesChannels/SalesChannelType";
import { Path } from "paths";
const findChannel = channel => find(propEq("salesChannelId", channel));
const findWalmartChannel = findChannel(ListingSolution.WALMARTDIRECT);
export const isConnected = propEq("connectionStatus", SalesChannelConnectionStatus.CONNECTED);
export const isOnlyWalmart = createSelector(state => state.channels.salesChannels, salesChannels => {
  const getConnected = filter(isConnected, salesChannels);
  return getConnected.length === 1 && getConnected[0].salesChannelId === ListingSolution.WALMARTDIRECT;
});
export const isWalmartActive = createSelector(state => state.channels.salesChannels, pipe(findWalmartChannel, isConnected));
const allIsTrue = pipe(inArray, all(isTrue));
const isWalmartNum = num => pipe(findWalmartChannel, propEq("walmartVersion", num));
export const isWalmartVersion = num => createSelector(isWalmartActive, state => state.channels.salesChannels,
// eslint-disable-next-line react-hooks/rules-of-hooks
useWith(allIsTrue, [identity, isWalmartNum(num)]));
export const activeFastTagsByChannel = channel => createSelector(state => state.channels.salesChannels, salesChannels => {
  const fastTags = pipe(findChannel(channel), propOr([], "availableFastTags"))(salesChannels);
  return filter(propEq("state", FastTagState.ACTIVATED), fastTags);
});
export const activeFastTags = createSelector(state => state.channels.salesChannels, salesChannels => {
  const allActiveTags = salesChannels.reduce((acc, val) => {
    if (val.connectionStatus === SalesChannelConnectionStatus.CONNECTED) {
      val.availableFastTags.forEach(fastTag => {
        if (fastTag.state === FastTagState.ACTIVATED) {
          acc.push(fastTag.name);
        }
      });
    }
    return acc;
  }, []);
  return uniq(allActiveTags);
});
export const fastTagsForConnectedChannels = createSelector(state => state.channels.salesChannels, salesChannels => flatMap(({
  availableFastTags
}) => availableFastTags, salesChannels.filter(({
  connectionStatus
}) => connectionStatus === SalesChannelConnectionStatus.CONNECTED)));
export const doubleConnectPotential = integrationName => createSelector(state => state.channels, ({
  directChannels,
  indirectChannels,
  salesChannels,
  recommendedChannels
}) => {
  const allChannels = [...directChannels, ...indirectChannels, ...recommendedChannels];
  const isIncomingSalesChannelDirect = find(channel => channel.salesChannelId.toLowerCase() === integrationName, allChannels)?.type === SalesChannelType.DIRECT;
  const onlyHasDirectIntegrations = isUndefined(find(channel => channel.type !== SalesChannelType.DIRECT, salesChannels));
  return !(onlyHasDirectIntegrations && isIncomingSalesChannelDirect);
});
export const getConnectedChannels = createSelector(state => state.channels.salesChannels, salesChannels => salesChannels.filter(isConnected));
export const getLowerCaseChannelName = channel => listingConfigProp(channel.salesChannelId, "name").toLowerCase();
export const getAllAvailableChannels = createSelector(state => state.channels, getSellerId, ({
  directChannels,
  indirectChannels,
  recommendedChannels
}, sellerId) => {
  const sortedRecommendedChannels = orderBy([prop("predictedRevenue"), prop("freePromoOffer"), getLowerCaseChannelName], ["desc", "desc", "asc"], recommendedChannels);
  const sortedOtherChannels = sortBy(getLowerCaseChannelName, [...directChannels, ...indirectChannels]);
  return [...sortedRecommendedChannels, ...sortedOtherChannels];
});
export const getIntegrations = state => state.channels.salesChannels;
export const getIsPipe17Active = createSelector(state => state.channels.indirectChannels, pipe(findChannel(ListingSolution.PIPE17), isConnected));
export const getIsChannelsLoading = createSelector(state => state.loading.loaders, includes(salesChannelLoaderId));
export const findShopifyIntegrationV1 = createSelector(getIntegrations, findChannel(ListingSolution.SHOPIFY));
export const findShopifyIntegrationV2 = createSelector(getIntegrations, findChannel(ListingSolution.SHOPIFY_V2));
export const findShopifyIntegrationV3 = createSelector(getIntegrations, findChannel(ListingSolution.SHOPIFY_V3));
const selectShopifyListingSolutionToOnboard = createSelector(state => state.channels.directChannels, channels => {
  return channels.find(channel => channel.salesChannelId === ListingSolution.SHOPIFY_V3) ? ListingSolution.SHOPIFY_V3 : ListingSolution.SHOPIFY_V2;
});
export const selectCreateCatalogPath = createSelector(selectIsFederatedShopifyUser, selectShopifyListingSolutionToOnboard, (isFederatedShopifyUser, shopifyListingSolution) => isFederatedShopifyUser ? generatePath(Path.onboardingIntegrationSetup, {
  channel: shopifyListingSolution
}) : Path.onboardingCreateCatalog);
export const findWalmartIntegration = createSelector(getIntegrations, findChannel(ListingSolution.WALMARTDIRECT));
export const selectIntegrationBySlsUuid = createSelector(getIntegrations, (_, slsUuid) => slsUuid, (integrations, slsUuid) => integrations.find(integration => integration.slsUuid === slsUuid));
export const selectIntegrationByChannelId = createSelector(getIntegrations, (_, channelId) => channelId, (integrations, channelId) => integrations.find(integration => integration.salesChannelId === channelId));
export const getIntegrationName = (integration, doesChannelHaveMultipleIntegrations) => {
  return doesChannelHaveMultipleIntegrations && integration.storeName ? integration.storeName : listingConfigProp(integration.salesChannelId, "name");
};
export const getDoesChannelHaveMultipleIntegrations = (integrations, salesChannelId) => {
  const integrationsForChannel = integrations.filter(integration => salesChannelId === integration.salesChannelId);
  return integrationsForChannel.length > 1;
};
export const selectIntegrationNameBySlsUuid = createSelector(getIntegrations, (_, slsUuid) => slsUuid, (integrations, slsUuid) => {
  const integration = integrations.find(currIntegration => currIntegration.slsUuid === slsUuid);
  if (!integration) {
    return "";
  }
  const doesChannelHaveMultipleIntegrations = getDoesChannelHaveMultipleIntegrations(integrations, integration.salesChannelId);
  return getIntegrationName(integration, doesChannelHaveMultipleIntegrations);
});