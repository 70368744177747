var _FlexportUserNotificationQuery;
import { fetchQuery, graphql } from "react-relay";
import { NotificationTargetType } from "../common/clients/notifications/Notification/NotificationTargetType";
import { NotificationType } from "../common/clients/notifications/Notification/NotificationType";
import { environment } from "../common/relay/RelayEnvironment";
export const fetchNotificationsQuery = _FlexportUserNotificationQuery !== void 0 ? _FlexportUserNotificationQuery : (_FlexportUserNotificationQuery = require("../common/relay/__generated__/FlexportUserNotificationQuery.graphql"), _FlexportUserNotificationQuery.hash && _FlexportUserNotificationQuery.hash !== "0324e4702a2269d60e9763179383c176" && console.error("The definition of 'FlexportUserNotificationQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _FlexportUserNotificationQuery);
const ShipmentMessageLinkRegex = /\/inbounds\/[0-9]+\/shipment-details\/((problems\/[0-9]+)|messages)/;
const isSMBMessageNotification = fpNotification => {
  return (fpNotification.slug === "shipment_message_reply" || fpNotification.slug === "shipment_message" || fpNotification.slug === "shipment_message_mention") && fpNotification.link && ShipmentMessageLinkRegex.test(fpNotification.link);
};
export const smbNotificationTransformer = (fpNotification, sellerId) => {
  const commonNotificationDetails = {
    acknowledged: false,
    createdAt: fpNotification.createdAt,
    id: fpNotification.dbid,
    sellerId,
    updatedAt: fpNotification.createdAt,
    viewed: false,
    notificationTargetId: "problems",
    notificationTargetType: NotificationTargetType.InboundTargetType,
    type: NotificationType.SMBNotificationType
  };
  let overrideNotificationDetails = {};
  if (isSMBMessageNotification(fpNotification)) {
    overrideNotificationDetails = {
      notificationTargetType: NotificationTargetType.SMBProblemDetailsTargetType,
      notificationTargetId: fpNotification.link.match(ShipmentMessageLinkRegex)[0],
      notificationTargetName: fpNotification.referenceId ? `'FLEX-${fpNotification.referenceId}'` : "",
      type: NotificationType.SMBMessageNotificationType
    };
  }
  return {
    ...commonNotificationDetails,
    ...overrideNotificationDetails
  };
};
export const fetchSmbNotifications = async (sellerId, limit, lastId) => {
  const data = await fetchQuery(environment, fetchNotificationsQuery, {
    last: limit,
    before: lastId,
    after: lastId ? undefined : 0
  }).toPromise();
  return data?.notifications?.map(x => smbNotificationTransformer(x, sellerId)) ?? [];
};