import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { selectBookingRequestByStep, selectCurrentStep } from "../selectors";
export const updateBooking = (shippingPlanId, shouldSubmit) => async (dispatch, getState) => {
  const state = getState();
  const currentStep = selectCurrentStep(state);
  const bookingRequest = selectBookingRequestByStep(currentStep, shouldSubmit)(state);
  const ctx = logStart({
    fn: SmbBookingCreationTimedEvent.UpdateBooking,
    shippingPlanId,
    bookingRequest
  });
  try {
    const response = await inboundClient.updateBooking(shippingPlanId, bookingRequest);
    logSuccess(ctx, "successfully updated booking");
    return response;
  } catch (error) {
    logError(ctx, error);
    throw error;
  }
};