import { getFreightListActiveView, getFreightOrderItems, getFreightQuoteRequestItems } from "freight/FreightSelectors";
import { toggleListView } from "freight/store/freightList/actions/FreightListActions";
import { fetchAndLoadFreightQuoteRequest, setFreightQuotingBladeOpen } from "freight/store/freightQuoting/actions/FreightQuotingActions";
import { Path } from "paths";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { isItemAnOrder } from "./FreightBookingListItem";
export let ListType;
(function (ListType) {
  ListType["ORDERS"] = "Orders";
  ListType["QUOTES"] = "Quotes";
})(ListType || (ListType = {}));
export const useFreightBookingList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeListView = useSelector(getFreightListActiveView);
  const freightOrderItems = useSelector(getFreightOrderItems);
  const freightQuoteRequestItems = useSelector(getFreightQuoteRequestItems);
  useEffectOnce(() => {
    dispatch(toggleListView(freightOrderItems.length ? ListType.ORDERS : ListType.QUOTES));
  });
  const itemPath = ({
    id
  }) => generatePath(Path.freightOrderDetail, {
    orderId: id
  });
  const onOrderItemClick = item => {
    if (isItemAnOrder(item)) {
      history.push(itemPath(item));
    }
  };
  const onQuoteItemClick = item => {
    dispatch(fetchAndLoadFreightQuoteRequest(item.id));
  };
  const onQuoteOrderClick = () => {
    dispatch(setFreightQuotingBladeOpen(true));
  };
  const updateActiveListView = value => {
    dispatch(toggleListView(value));
  };
  return {
    freightOrderItems,
    freightQuoteRequestItems,
    onOrderItemClick,
    onQuoteItemClick,
    onQuoteOrderClick,
    activeListView,
    updateActiveListView
  };
};