import { getCrossDockWarehouse, getLoadedShipmentWarehouse } from "../../InboundStepSelectors";
import { getIsShipToOne } from "../view/ViewShipmentSelectors";
import { useSelector } from "react-redux";
export const useShipmentAddressDisplay = () => {
  const {
    address,
    provider
  } = useSelector(getLoadedShipmentWarehouse);
  const cdWarehouse = useSelector(getCrossDockWarehouse);
  const isShipToOne = useSelector(getIsShipToOne);
  const fromAddress = useSelector(state => state.inbound.fromAddress);
  return {
    fromAddress,
    toAddress: isShipToOne ? cdWarehouse.address : address,
    toProvider: isShipToOne ? cdWarehouse.provider : provider,
    isCrossdock: isShipToOne
  };
};