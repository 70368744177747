import { createSelector } from "reselect";
import { getStorageInboundCreate } from "./getStorageInboundCreate";
import { isEmpty, values } from "lodash";
export const getSelectedProductsWithUnsetCasePacks = createSelector(getStorageInboundCreate, storageInboundCreate => {
  const {
    selectedProductsCasePacks,
    selectedProducts
  } = storageInboundCreate;
  return values(selectedProducts).filter(({
    dsku
  }) => isEmpty(selectedProductsCasePacks[dsku]));
});