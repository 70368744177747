import get from "lodash/get";
import { sumBy } from "lodash/fp";
/**
 * Aggregates row data into a new object of the same type based on the options provided.
 *
 * @param data Rows to aggregate
 * @param aggregationOptions Options for how to aggregate rows
 */
export const getAggregatedRow = (data, {
  keys,
  aggregator,
  initialData
}) => keys.length === 0 ? undefined : keys.reduce((agg, curKey) => {
  // we must aggregate on initial data as well if it exists for this key
  const dataToAgg = initialData?.[curKey] !== undefined ? [...data, initialData] : data;
  return {
    ...agg,
    [curKey]: aggregator?.(curKey, dataToAgg) ?? sumBy(row => {
      const valToAgg = Number(get(row, curKey));
      // if we have non-parsable values, don't try to add them -- just use 0
      return Number.isNaN(valToAgg) ? 0 : valToAgg;
    }, dataToAgg)
  };
},
// eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
{
  ...initialData
});