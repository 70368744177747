import styled from "@emotion/styled";
import { Title } from "common/components/ui/Title";
export const Content = styled.div(({
  theme
}) => `
    padding: 0 ${theme.spacing.S8};
  `);
export const StepsContainer = styled.div(({
  theme
}) => `
    padding-top: ${theme.spacing.S5};
  `);
export const StyledTitle = styled(Title)(({
  theme
}) => `
    margin: 0;
    letter-spacing: normal;
  `);
export const AccordionContainer = styled.div(({
  theme
}) => `
    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.NEUTRAL["80"]};
    }
  `);