import styled from "@emotion/styled";
export const FlexDiv = styled.div(({
  theme,
  justifyContent = "space-between",
  gap
}) => `
  display: flex;
  align-items: center;
  justify-content: ${justifyContent};
  ${gap ? `gap: ${theme.spacing[gap]};` : ""}
`);