import { QcItemStatus, ReturnStatus } from "@deliverr/returns-client";
import { QcStatus } from "returns/detail/store/ReturnsDetailStore.types";
import { forEach } from "lodash";
export const isQcEnabled = qcId => (qcId ?? "Self") !== "Self";
export const isQcEnabledAndStarted = (qcId, status) => (qcId ?? "Self") !== "Self" && (status === ReturnStatus.RECEIVED_FOR_PROCESSING || status === ReturnStatus.PROCESSED);
export const isQcEnabledAndProcessed = (qcId, status) => (qcId ?? "Self") !== "Self" && status === ReturnStatus.PROCESSED;
export const isQcEnabledAndStatusCompleted = (qcId, status) => (qcId ?? "Self") !== "Self" && status === ReturnStatus.PROCESSED;
export const isQcEnabledAndStatusShipped = (qcId, status) => (qcId ?? "Self") !== "Self" && status === ReturnStatus.SHIPPED;
const getQcProcessedStatus = (approvedItems, totalItems) => approvedItems === totalItems ? QcStatus.PASSED_INSPECTION : QcStatus.SOME_UNITS_WILL_NOT_BE_RESTOCKED;
const getQcCompletedStatus = (approvedItems, totalItems) => {
  if (approvedItems === totalItems) {
    return QcStatus.RESTOCKED;
  } else if (approvedItems === 0) {
    return QcStatus.NOT_RESTOCKABLE;
  }
  return QcStatus.PARTIALLY_RESTOCKED;
};
export const getQcStatus = (status, inspectedItems) => {
  let totalItems = 0;
  let approvedItems = 0;
  forEach(inspectedItems, inspectedItem => {
    totalItems += inspectedItem.quantity;
    if (inspectedItem.status === QcItemStatus.INSPECTION_APPROVED) {
      approvedItems += inspectedItem.quantity;
    }
  });
  if (status === ReturnStatus.PROCESSED) {
    return getQcProcessedStatus(approvedItems, totalItems);
  }
  return getQcCompletedStatus(approvedItems, totalItems);
};
export const aggregateItemsByDskuAndInspectionStatus = returnsQcItems => {
  return Object.values(returnsQcItems.reduce((acc, item) => {
    const key = String(item.dsku) + String(item.status?.toString()) + String(item.isUnexpected);
    if (!acc[key]) {
      acc[key] = {
        ...item
      };
    } else {
      acc[key].quantity = Number(acc[key].quantity) + Number(item.quantity);
    }
    return acc;
  }, {}));
};
export const aggregateUnknownItemsByBarcodeAndStatus = qcItems => {
  const aggregatedOutput = qcItems.reduce((acc, qcItem) => {
    const key = `${qcItem.barcode}_${qcItem.status}`;
    if (key in acc) {
      acc[key].quantity = (acc[key].quantity ?? 0) + qcItem.quantity;
    } else {
      const outputItem = {
        dsku: qcItem.dsku,
        status: qcItem.status,
        quantity: qcItem.quantity,
        isUnknown: true,
        isUnexpected: true,
        barcode: qcItem?.barcode
      };
      acc[key] = outputItem;
    }
    return acc;
  }, {});
  const outputItems = Object.values(aggregatedOutput);
  return outputItems;
};