import { logError, logStart } from "Logger";
import { StorageInboundDetailActionTypes } from "../StorageInboundDetailStore.types";
import { inboundClient } from "Clients";
export const loadStorageReceivingInfo = (sellerId, shipmentId) => async dispatch => {
  const ctx = logStart({
    fn: "loadReceivingInfo",
    shipmentId
  });
  try {
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const receivingInfo = await inboundClient.getReceivingInfo(sellerId, (shipmentId ?? "").toString());
    dispatch({
      type: StorageInboundDetailActionTypes.STORAGE_FETCH_RECEIVE_INFO,
      receivingInfo
    });
  } catch (err) {
    logError(ctx, err);
  }
};