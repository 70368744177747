/* eslint-disable @typescript-eslint/return-await */
// Amplify deps
// do not import amplify entirely, it adds a lot to bundle
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { authCookieDomain, isDebugServer, isHeadlessLogin } from "common/Config";
import { initSplitClient } from "common/Split";
import { CognitoLegacyActions } from "common/user/CognitoReducer";
import { attachSellerToken, onSellerLogin } from "common/user/SellerActions";
import { fetchIsFlexportUserConnected, getSellerSettings, getUserFromOrganization, setSplitLoaded, syncOnboardingExperiments, updateUser } from "common/user/UserActions";
import { getIsFederatedUser } from "common/user/UserSelectors";
import * as Cookie from "js-cookie";
import log, { logError } from "Logger";
import { initializeHeap, updateOrganization } from "organization/OrganizationActions";
import { Path } from "paths";
import store from "store";
import AuthStorageConsumer from "./AuthStorageConsumer";
import { storeRedirectOnboardedUrl } from "./UrlIntegration";
import { addLoader } from "common/components/WithLoader/LoadingActions";
import { salesChannelLoaderId, setSalesChannels } from "channels/ChannelsActions";
import { salesChannelsInitialState } from "channels/ChannelsReducers";
import { cloneDeep } from "lodash";
// list of paths that should be excluded from the force redirect to loginRedirect after login.
export const ALLOWED_REDIRECTS_AFTER_LOGOUT = [Path.parcel];
const cognitoCookiePrefix = "CognitoIdentityServiceProvider";
const refreshSessionInterval = 30 * 60 * 1000; // 30min
let auth;
let userFromOrg;
let sessionKeepaliveInterval = null;
let currentUserCredentials;
function startSessionKeepaliveInterval() {
  if (sessionKeepaliveInterval) {
    window.clearInterval(sessionKeepaliveInterval);
    sessionKeepaliveInterval = null;
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  sessionKeepaliveInterval = window.setInterval(async () => {
    const ctx = {
      fn: "sessionKeepaliveInterval"
    };
    const onSessionExpire = _ => {
      log.info(ctx, `session expired`);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      signOut();
    };
    try {
      const session = await auth.currentSession();
      const currentUser = await auth.currentAuthenticatedUser();
      currentUser.refreshSession(session.getRefreshToken(), async (err, data) => {
        if (err) {
          onSessionExpire(err);
          return;
        }
        try {
          attachSellerToken(data.idToken.jwtToken);
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          log.info(ctx, `refreshed auth session for ${currentUser.attributes.email}`);
        } catch (e) {
          onSessionExpire(e);
        }
      });
    } catch (e) {
      onSessionExpire(e);
    }
  }, refreshSessionInterval);
}
export async function getUserCredentials() {
  return currentUserCredentials || (await auth.currentUserCredentials());
}
export async function getAuth() {
  if (!auth) {
    auth = new AuthClass({
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.COGNITO_IDENTITY_POOL,
      region: process.env.COGNITO_REGION,
      userPoolId: process.env.COGNITO_USER_POOL,
      userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
      storage: new AuthStorageConsumer(authCookieDomain, {}),
      oauth: isHeadlessLogin ? {
        domain: process.env.COGNITO_USER_POOL_DOMAIN_HTTP,
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${window.location.origin}/logout/`,
        responseType: "code"
      } : undefined
    });
  }
  return auth;
}
export async function loginUserDispatcher(user) {
  const {
    idToken
  } = user.signInUserSession;
  const groups = idToken.payload?.["cognito:groups"] ?? [];
  const dispatch = store.dispatch;
  attachSellerToken(idToken.jwtToken);
  await store.dispatch({
    type: CognitoLegacyActions.COGNITO_LOGIN,
    user,
    groups
  });
  userFromOrg = await getUserFromOrg();
  await store.dispatch(updateUser(userFromOrg.isOnWaitlist, userFromOrg.isLinkedToFlexport, userFromOrg.isPassEmailVerification));
  await initSplitClient(userFromOrg.userId).then(() => dispatch(setSplitLoaded(true)))?.catch(err => {
    log.error({
      fn: "initSplitClient",
      err
    }, "initSplitClient failed");
  });
}
export async function setupSeller() {
  userFromOrg = await getUserFromOrg();
  const dispatch = store.dispatch;
  // trigger a force re render of side bar
  dispatch(setSalesChannels(cloneDeep({
    ...salesChannelsInitialState
  })));
  void Promise.all([dispatch(getSellerSettings(userFromOrg.userId)), dispatch(updateOrganization(userFromOrg.userId))]).then(() => {
    dispatch(syncOnboardingExperiments());
    dispatch(initializeHeap());
  });
  startSessionKeepaliveInterval();
  await onSellerLogin();
}
async function getUserFromOrg() {
  if (!userFromOrg) {
    const user = await getUser();
    const {
      idToken
    } = user.signInUserSession;
    const groups = idToken.payload?.["cognito:groups"] ?? [];
    const isAdmin = groups.includes("admin");
    const effectiveEmail = user.attributes["custom:impersonatedEmail"] || user.attributes.email;
    const [userResponseResult, isLinkedToFlexportResult] = await Promise.allSettled([getUserFromOrganization(effectiveEmail), fetchIsFlexportUserConnected(effectiveEmail)]);
    const userResponse = userResponseResult.status === "fulfilled" ? userResponseResult.value : {
      sellerId: null,
      isOnWaitlist: false
    };
    const userId = isAdmin ? user.attributes["custom:sellerIds"] : userResponse.sellerId;
    const isOnWaitlist = userResponse.isOnWaitlist ?? false;
    let isLinkedToFlexport = false;
    let isPassEmailVerification;
    if (isLinkedToFlexportResult.status === "fulfilled") {
      isLinkedToFlexport = isLinkedToFlexportResult.value;
    } else {
      // In case of error, We can't determine the linking status.
      // Hence, we want to prevent the email verification page from being shown.
      isPassEmailVerification = true;
    }
    userFromOrg = {
      userId,
      isOnWaitlist,
      isLinkedToFlexport,
      isPassEmailVerification
    };
  }
  return userFromOrg;
}
function clearAuthStorage() {
  const filterAuthEntries = key => key.match(cognitoCookiePrefix);
  Object.keys(Cookie.getJSON()).filter(filterAuthEntries).forEach(key => Cookie.remove(key, {
    path: "/",
    domain: process.env.SSO_COOKIE_DOMAIN
  }));
  Object.keys(window.localStorage ?? {}).filter(filterAuthEntries).forEach(key => window.localStorage.removeItem(key));
}
export async function getUser(...args) {
  const currentAuth = await getAuth();
  return await currentAuth.currentAuthenticatedUser(...args);
}
const AMPLIFY_AUTH_LOG_LEVEL_KEY = "AmplifyAuthLogLevel";
export async function enforceSSO() {
  const ctx = {
    fn: "enforceSSO",
    step: ""
  };
  try {
    /**
     * Shopify redirects to the <Path.shopifyApp> path when the user clicks install on our app in the shopify app
     * store. Because shopify requires that there shouldn't be any actions for the seller between downloading the
     * app and seeing the Shopify consent screen, we cannot enforce SSO.
     */
    if (window.location.pathname === Path.shopifyApp) {
      return;
    }

    /**
     * A stripped version of the freight quote view has been exposed for non-sellers
     * to be able to interact with quotes generated by staff, so there cannot be any
     * SSO enforced.
     */
    if (window.location.pathname.startsWith(Path.publicFreightQuoteSummaryRoot)) {
      return;
    }

    // Setting window.LOG_LEVEL will cause amplify auth library to log to console at that level.
    const logLevel = localStorage.getItem(AMPLIFY_AUTH_LOG_LEVEL_KEY);
    if (logLevel) {
      window.LOG_LEVEL = logLevel;
    }
    ctx.step = "getAuth";
    const currentSession = await getAuth();
    // this is added to avoid showing onboarding survey if we are in loading state.
    // https://github.com/deliverr/seller-portal/blob/84f3b184f925ddbba282dba180f4314f9550a51d/src/app/login/LoginRedirect.tsx#LL22C1-L24C10
    // this will be removed in future PR.
    store.dispatch(addLoader(salesChannelLoaderId));
    ctx.step = "currentSession.currentUserCredentials";
    currentUserCredentials = await currentSession.currentUserCredentials();
    ctx.authenticated = currentUserCredentials?.authenticated;
    if (!currentUserCredentials?.authenticated) {
      ctx.step = "storeRedirectOnboardedUrl";
      storeRedirectOnboardedUrl();

      // make sure old login is signout
      if (store.getState().cognito?.user) {
        ctx.step = "currentSession.signOut";
        await currentSession.signOut();
      }
      ctx.step = "signOut";
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      signOut({
        redirectBackToCurrentPage: true
      });
      return;
    }
    ctx.step = "getUser";
    const user = await getUser();
    ctx.step = "loginUserDispatcher";
    user && (await loginUserDispatcher(user));

    // Stop sending amplify auth logs to console at DEBUG level if successful.
    localStorage.removeItem(AMPLIFY_AUTH_LOG_LEVEL_KEY);
  } catch (err) {
    // Debugging for this issue: https://app.asana.com/0/1185102429722378/1198565576261013/f
    if (err.toString().includes("not authenticated")) {
      ctx.notAuthenticated = true;
      // Set so that on next attempt to log in we will send amplify auth logs to console at DEBUG level.
      localStorage.setItem(AMPLIFY_AUTH_LOG_LEVEL_KEY, "DEBUG");
    }
    logError(ctx, err);

    // The presence of this key with an inappropriate value can cause an exception to be thrown,
    // even when the user has been authenticated successfully. We need to clear the value to allow
    // for the user to be able to access Seller Portal.
    localStorage.removeItem("aws-amplify-cacheCurSize");
    clearAuthStorage();
    showSSOLogin();
  }
}
export async function signOut(args) {
  clearAuthStorage();
  const authForSignOut = await getAuth();
  if (store.getState().cognito?.user) {
    await authForSignOut.signOut();
  }
  showSSOLogin(args);
}
export function showSSOLogin({
  redirectBackToCurrentPage = false
} = {}) {
  if (isHeadlessLogin) {
    return;
  }
  if (window.location.pathname.includes(Path.onboardingSignup)) {
    window.location.href = `${process.env.LOGIN_SSO_URL}/signup`;
    return;
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  let ssoEndpoint = `${!isDebugServer ? process.env.LOGIN_SSO_URL : `http://${window.location.hostname}:3000`}/logout/`;

  // Add redirect if either:
  // (1) The user is trying to open a specific page but the user was not logged in (redirectBackToCurrentPage = true)
  // (2) The user is logging out and the current window location pathname starts with any of the ALLOWED_REDIRECTS_AFTER_LOGOUT
  if (redirectBackToCurrentPage || ALLOWED_REDIRECTS_AFTER_LOGOUT.some(e => window.location.pathname.startsWith(e))) {
    const page = window.location.href.replace(window.location.origin, "");
    // If someone is being linked to or has bookmarked portal.flexport.com, after login they should be redirected to
    // the /login-redirect route, which is accomplished by not specifying a redirectUrl
    if (page !== "/") {
      const redirectUrl = encodeURIComponent(page);
      ssoEndpoint += `?redirectUrl=${redirectUrl}`;
    }
  }
  const state = store.getState();
  if (getIsFederatedUser(state)) {
    // make sure we also logout user in login.deliverr.com
    ssoEndpoint = [process.env.COGNITO_USER_POOL_DOMAIN_HTTPS, "/logout?client_id=", process.env.COGNITO_CLIENT_ID, "&logout_uri=", ssoEndpoint].join("");
  }
  window.location.href = ssoEndpoint;
}