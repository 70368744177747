import { useController } from "react-hook-form";
import { STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN, STORAGE_INBOUND_MIN_CASE_WEIGHT_LBS } from "storage/inbounds/CaseDimensionsConstants";
import { STORAGE_MAX_PACK_QTY, STORAGE_PACK_NAME_MAX_LENGTH } from "./CasePackConstants";
export const useNewProductCasePackFormControllers = control => ({
  packName: useController({
    name: "packName",
    rules: {
      required: true,
      minLength: 1,
      maxLength: STORAGE_PACK_NAME_MAX_LENGTH
    },
    control
  }),
  caseLength: useController({
    name: "caseLength",
    rules: {
      required: true,
      min: STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN
    },
    control
  }),
  caseWeight: useController({
    name: "caseWeight",
    rules: {
      required: true,
      min: STORAGE_INBOUND_MIN_CASE_WEIGHT_LBS
    },
    control
  }),
  caseWidth: useController({
    name: "caseWidth",
    rules: {
      required: true,
      min: STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN
    },
    control
  }),
  caseHeight: useController({
    name: "caseHeight",
    rules: {
      required: true,
      min: STORAGE_INBOUND_MIN_CASE_MEASUREMENT_IN
    },
    control
  }),
  caseQty: useController({
    name: "caseQty",
    rules: {
      required: true,
      min: 1,
      max: STORAGE_MAX_PACK_QTY
    },
    control
  })
});