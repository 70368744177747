import { createSelector } from "reselect";
import { selectTransferCreate } from "./TransferCreateSelectors";
import { filter, map } from "lodash";
import { InvalidOrderItemReason } from "@deliverr/replenishment-client";
export const selectAugmentedInvalidOrderItems = createSelector(selectTransferCreate, transferCreate => {
  const {
    replenishmentOrder,
    unifiedProductResponseCache
  } = transferCreate;
  return map(filter(replenishmentOrder?.invalidOrderItems, ({
    reason
  }) => reason !== InvalidOrderItemReason.OUT_OF_STOCK), item => {
    return {
      ...item,
      resolvedUnitsPerPack: item.resolvedDsku ? unifiedProductResponseCache?.[item.resolvedDsku]?.caseQty : undefined
    };
  });
});