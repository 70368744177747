export let OrderStatus;
(function (OrderStatus) {
  OrderStatus["BOOKED"] = "BOOKED";
  OrderStatus["PLANNED"] = "PLANNED";
  OrderStatus["TENDERED"] = "TENDERED";
  OrderStatus["IN_PROGRESS"] = "IN_PROGRESS";
  OrderStatus["COMPLETED"] = "COMPLETED";
  OrderStatus["CANCELLED"] = "CANCELLED";
  OrderStatus["DRAFT"] = "DRAFT";
  OrderStatus["QUOTED"] = "QUOTED";
  OrderStatus["ERROR"] = "ERROR";
})(OrderStatus || (OrderStatus = {}));