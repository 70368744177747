import { useRef, useState } from "react";
export const useInfoTooltip = timeout => {
  const [tooltipActive, setTooltipActive] = useState(false);
  const timer = useRef();
  const onMouseOut = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (timeout) {
      timer.current = setTimeout(() => setTooltipActive(false), timeout);
    } else {
      setTooltipActive(false);
    }
  };
  const onMouseOver = () => setTooltipActive(true);
  return {
    tooltipActive,
    onMouseOver,
    onMouseOut
  };
};