import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
const defaultHeaders = {
  "X-Request-Caller": "seller-portal"
};
export class StorageClient {
  constructor({
    baseURL,
    headers
  } = {
    baseURL: mustBeDefined(process.env.STORAGE_SERVICE_URL),
    headers: defaultHeaders
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`,
      headers
    });
  }
  async archiveStorageInbound(sellerId, shippingPlanId) {
    await this.apiClient.delete({
      url: `/storage/inbounds?sellerId=${sellerId}&shippingPlanId=${shippingPlanId}`,
      authentication: isAuthenticated
    });
  }
  async estimatePalletCount(estimatePalletRequest) {
    return await this.apiClient.post({
      url: `/storage/estimate/pallet-count`,
      body: estimatePalletRequest,
      authentication: isAuthenticated
    });
  }
  async createStorageInbound(storageInboundRequest) {
    return await this.apiClient.post({
      url: `/storage/inbounds`,
      body: storageInboundRequest,
      authentication: isAuthenticated
    });
  }
  async submitStorageInbound(storageInboundSubmitRequest) {
    return await this.apiClient.post({
      url: `/storage/inbounds/submit`,
      body: storageInboundSubmitRequest,
      authentication: isAuthenticated
    });
  }
  async updateStorageInbound(storageInboundOrderId, storageInboundRequest) {
    return await this.apiClient.put({
      url: `/storage/inbounds/${storageInboundOrderId}`,
      body: storageInboundRequest,
      authentication: isAuthenticated
    });
  }
  async getNearestStorageWarehouse(address) {
    return await this.apiClient.post({
      url: `/storage/warehouses/nearest`,
      body: address,
      authentication: isAuthenticated
    });
  }
  async getStorageWarehouses() {
    return await this.apiClient.get({
      url: `/storage/warehouses`,
      authentication: isAuthenticated
    });
  }
  async getStorageWarehousesForInbounds(sellerId) {
    return await this.apiClient.get({
      url: `/storage/warehouses/inbounds/${sellerId}`,
      authentication: isAuthenticated
    });
  }
  async getRankedStorageWarehousesForInbounds(sellerId, address) {
    return await this.apiClient.post({
      url: `/storage/warehouses/inbounds/${sellerId}/withNearestWarehouse`,
      body: address,
      authentication: isAuthenticated
    });
  }
  async patchStorageInbound(sellerId, storageInboundOrderId, storageInboundOrderRequest) {
    return await this.apiClient.patch({
      url: `/storage/inbounds?sellerId=${sellerId}&storageInboundOrderId=${storageInboundOrderId}`,
      body: storageInboundOrderRequest,
      authentication: isAuthenticated
    });
  }
  async generateLabels(generateLabelRequest) {
    return await this.apiClient.post({
      url: `/storage/labels`,
      body: generateLabelRequest,
      authentication: isAuthenticated
    });
  }
  async getStorageInboundByShippingPlanId(sellerId, shippingPlanId) {
    return await this.apiClient.get({
      url: `/storage/inbounds?sellerId=${sellerId}&shippingPlanId=${shippingPlanId}`,
      authentication: isAuthenticated
    });
  }
  async getRates() {
    return await this.apiClient.get({
      url: `/storage/rates`,
      authentication: isAuthenticated
    });
  }
  async validateAndReturnInventoryAvailableInEachStorageWarehouse(dskuQuantities) {
    return await this.apiClient.post({
      url: `/storage/inventory/validate`,
      body: dskuQuantities,
      authentication: isAuthenticated
    });
  }
  async getUnifiedAvailablePackInventoryByWarehouse(dskuQuantities) {
    return await this.apiClient.post({
      url: `/storage/inventory/unified`,
      body: dskuQuantities,
      authentication: isAuthenticated
    });
  }
  async createStorageTransferOrder(storageTransferOrderRequest) {
    return await this.apiClient.post({
      url: `/storage/transfer`,
      body: storageTransferOrderRequest,
      authentication: isAuthenticated
    });
  }
  async updateStorageTransferOrder(storageTransferOrderRequest, storageTransferOrderId) {
    return await this.apiClient.put({
      url: `/storage/transfer/${storageTransferOrderId}`,
      body: storageTransferOrderRequest,
      authentication: isAuthenticated
    });
  }
  async submitStorageTransferOrder(storageTransferOrderSubmitRequest) {
    return await this.apiClient.post({
      url: `/storage/transfer/submit`,
      body: storageTransferOrderSubmitRequest,
      authentication: isAuthenticated
    });
  }
  async getStorageTransferOrder(sellerId, storageTransferOrderId) {
    return await this.apiClient.get({
      url: `/storage/transfer?storageTransferOrderId=${storageTransferOrderId}&sellerId=${sellerId}`,
      authentication: isAuthenticated
    });
  }
  async getStorageTransferOrderByInboundShipmentId(sellerId, inboundShipmentId) {
    return await this.apiClient.get({
      url: `/storage/transfer?inboundShipmentId=${inboundShipmentId}&sellerId=${sellerId}`,
      authentication: isAuthenticated
    });
  }
  async archiveStorageTransferOrder(sellerId, storageTransferOrderId) {
    await this.apiClient.delete({
      url: `/storage/transfer?sellerId=${sellerId}&storageTransferOrderId=${storageTransferOrderId}`,
      authentication: isAuthenticated
    });
  }
  async cancelStorageTransferOrder(sellerId, storageTransferOrderId) {
    await this.apiClient.post({
      url: `/storage/transfer/cancel?sellerId=${sellerId}&storageTransferOrderId=${storageTransferOrderId}`,
      authentication: isAuthenticated
    });
  }
  async getStorageToEcomRates(storageToEcomRateRequest) {
    return await this.apiClient.post({
      url: `/storage/rates/transfer`,
      body: storageToEcomRateRequest ?? {},
      authentication: isAuthenticated
    });
  }
  async getCaseConfig(dsku) {
    const dskuParamValue = (dsku ?? []).map(orig => `"${orig}"`).join(",");
    return await this.apiClient.get({
      url: `/storage/product/case-config?dsku=[${dskuParamValue}]`,
      authentication: isAuthenticated
    });
  }
  async getSellerStorageConfig(sellerId) {
    return await this.apiClient.get({
      url: `/storage/seller/config/${sellerId}`,
      authentication: isAuthenticated
    });
  }
  async createOrUpdateStorageSellerConfig(sellerId, updateConfig) {
    return await this.apiClient.put({
      url: `/storage/seller/config/${sellerId}`,
      body: updateConfig,
      authentication: isAuthenticated
    });
  }
  async getStorageRecommendationStrategies() {
    return await this.apiClient.get({
      url: `/storage/jit/strategy`,
      authentication: isAuthenticated
    });
  }
  async generateFtlQuote(quoteRequest) {
    return await this.apiClient.post({
      url: `/storage/freight/ftl-quote`,
      body: {
        quoteRequest
      } ?? {},
      authentication: isAuthenticated
    });
  }
  async generateLtlQuote(quoteRequest) {
    return await this.apiClient.post({
      url: `/storage/freight/ltl-quote`,
      body: {
        quoteRequest
      } ?? {},
      authentication: isAuthenticated
    });
  }
  async requestForInboundAppointment(emailRequest, storageInboundOrderId) {
    return await this.apiClient.post({
      url: `/storage/inbounds/${storageInboundOrderId}/request-appointment`,
      body: emailRequest,
      authentication: isAuthenticated
    });
  }
  async processStorageInboundBulkUploads(storageInboundBulkUploadRequest) {
    return await this.apiClient.post({
      url: `/storage/inbounds/bulk-upload`,
      body: storageInboundBulkUploadRequest,
      authentication: isAuthenticated
    });
  }
  async createReserveStorageOrderBasedOnBookingType(createRSOrderRequest) {
    return await this.apiClient.post({
      url: "/storage/replenishment/order",
      body: createRSOrderRequest,
      authentication: isAuthenticated
    });
  }
}