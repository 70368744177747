import { useNavigation } from "inbounds/navigation/useNavigation";
import { fulfillmentInboundStepToPath } from "inbounds/navigation/fulfillment/fulfillmentInboundStepToPath";
import { fulfillmentInboundEnabledStepMap } from "inbounds/navigation/fulfillment/fulfillmentInboundEnabledStepMap";
import { useDispatch, useSelector } from "react-redux";
import { generateShipmentsTransitionAction } from "inbounds/navigation/fulfillment/generateShipmentsTransitionAction";
import { updateProductCache } from "inbounds/InboundActions";
import { goToInboundStep } from "inbounds/store/actions/navigation/goToInboundStep";
import { clearRates } from "inbounds/store/actions/shipment/clearRates";
import { logError, logStart, logSuccess } from "Logger";
import { toast } from "common/components/ui";
import { NavigationErrorMessage } from "inbounds/store/actions/navigation/NavigationErrorMessage";
import { saveInbound } from "inbounds/steps/InboundSaveActions";
import { InboundStep } from "inbounds/InboundTypes";
import { selectIpbFeatureOn } from "inbounds/createShipment/store/selectors/selectIpbFeatureOn";
import { goToCreateShipmentStep } from "inbounds/createShipment/routing/goToCreateShipmentStep";
export const useFulfillmentInboundNavigation = () => {
  const dispatch = useDispatch();
  const isIPB = useSelector(selectIpbFeatureOn);
  const {
    getNextStep,
    getPreviousStep
  } = useNavigation(InboundStep, fulfillmentInboundStepToPath, fulfillmentInboundEnabledStepMap);
  const goToNextStep = async ({
    replace,
    refreshProductCache
  } = {}) => {
    const ctx = logStart({
      fn: "goToNextStep"
    });
    if (refreshProductCache) {
      await dispatch(updateProductCache());
    }
    const nextStep = getNextStep();
    const isNextStepShip = nextStep === InboundStep.SHIP;
    if (nextStep === undefined) {
      logError(ctx, "Next inbound step does not exist");
      toast.error(NavigationErrorMessage);
      return;
    }
    try {
      if (isNextStepShip) {
        await dispatch(generateShipmentsTransitionAction());
      }
      dispatch(goToInboundStep(nextStep, replace));
      dispatch(saveInbound());
      logSuccess(ctx, "goToNextStep success");
    } catch (err) {
      logError(ctx, err);
    }
  };
  const goToPreviousStep = () => {
    const ctx = logStart({
      fn: "goToPreviousStep"
    });
    const previousStep = getPreviousStep();
    if (previousStep === undefined) {
      if (isIPB) {
        dispatch(goToCreateShipmentStep({
          shouldUseLastStep: true
        }));
        return;
      }
      logError(ctx, "Previous inbound step does not exist");
      toast.error(NavigationErrorMessage);
      return;
    }
    dispatch(clearRates());
    dispatch(goToInboundStep(previousStep));
    dispatch(saveInbound());
  };
  return {
    goToNextStep,
    goToPreviousStep
  };
};