import { Path } from "paths";
import { useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { createReturnsLabels } from "./createReturnsLabels";
export const useCreateReturnsButton = () => {
  const order = useSelector(state => state.orderDetail.order);
  const history = useHistory();
  const tooltipMessage = getDisabledMessage(order);
  const navigateToCreateReturns = () => history.push(generatePath(Path.returnsCreate, {
    orderId: order.id
  }));
  return {
    navigateToCreateReturns,
    tooltipMessage,
    showTooltip: !!tooltipMessage
  };
};
const getDisabledMessage = order => !(order.originalShippingAddress.country === "US" || order.originalShippingAddress.country === "USA" || order.cleanShippingAddress?.country === "US" || order.cleanShippingAddress?.country === "USA") ? createReturnsLabels.internationalShipmentWarning : undefined;