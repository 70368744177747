import styled from "@emotion/styled";
import { defaultTheme, Input } from "common/components/ui";
export const AddressInputContainer = styled.div(({
  theme
}) => `
  display: grid;
  grid-row-gap: ${theme.spacing.S4};
  margin-bottom: ${theme.spacing.S4};
  text-align: left;

  label {
    margin-bottom: 0;
  }
`);
export const StyledGrid = styled.div(({
  theme
}) => `
  display: grid;
  grid-template-areas: "city state zip";
  grid-gap: ${theme.spacing.S4};

  &.withCountry {
    grid-template-areas:
      "city state"
      "country zip";
  }
`);
export const CityInput = styled(Input)`
  grid-area: city;
`;
export const StateInput = styled(Input)`
  grid-area: state;
`;
export const ZipInput = styled(Input)`
  grid-area: zip;
`;
export const CountryInput = styled(Input)`
  grid-area: country;
`;
export const CountrySelectContainer = styled.div`
  grid-area: country;
`;
export const PlacesAutocompleteContainer = styled.div`
  position: relative;

  #PlacesAutocomplete__google-logo {
    display: none;
  }
`;
export const placesAutoCompleteComponentStyles = {
  autocompleteContainer: {
    position: "absolute",
    top: "100%",
    width: "100%",
    background: "white",
    marginTop: "-1px",
    border: `1px solid ${defaultTheme.colors.NEUTRAL[80]}`,
    borderRadius: defaultTheme.border.radius.R2,
    zIndex: 3
  },
  autocompleteItem: {
    lineHeight: "36px",
    cursor: "pointer",
    padding: "0 10px",
    overflow: "hidden"
  },
  autoCompleteItemActive: {
    background: defaultTheme.colors.NEUTRAL[200]
  }
};
export const LoadingSpinnerContainer = styled.div(({
  theme
}) => `
  position: absolute;
  z-index: 10;
  right: ${theme.spacing.S2};
  top: 50%;
  transform: translateY(-50%);
`);