export let LtlInputNames;
(function (LtlInputNames) {
  LtlInputNames["NUMBER_OF_PALLETS"] = "numberOfPallets";
  LtlInputNames["TOTAL_CARGO_WEIGHT"] = "totalCargoWeight";
  LtlInputNames["PALLET_LENGTH"] = "length";
  LtlInputNames["PALLET_WIDTH"] = "width";
  LtlInputNames["PALLET_HEIGHT"] = "height";
  LtlInputNames["PALLET_WEIGHT"] = "weight";
  LtlInputNames["IDENTICAL_PALLETS"] = "identicalPallets";
  LtlInputNames["READY_DATE"] = "pickupDate";
  LtlInputNames["DECLARED_VALUE"] = "declaredValue";
  LtlInputNames["CONTACT_NAME"] = "contactName";
  LtlInputNames["CONTACT_PHONE"] = "contactPhone";
  LtlInputNames["CONTACT_EMAIL"] = "contactEmail";
  LtlInputNames["WEIGHT_CLASS"] = "weightClass";
  LtlInputNames["CUSTOMER_REFERENCE_NUMBER"] = "customerReferenceNumber";
  LtlInputNames["PICKUP_WINDOW"] = "preferredPickupWindow";
  LtlInputNames["PICKUP_WINDOW_START"] = "pickupWindowStart";
  LtlInputNames["PICKUP_WINDOW_END"] = "pickupWindowEnd";
  LtlInputNames["OPERATING_HOURS"] = "facilityOperatingHours";
  LtlInputNames["OPERATING_HOURS_START"] = "operatingHoursStart";
  LtlInputNames["OPERATING_HOURS_END"] = "operatingHoursEnd";
})(LtlInputNames || (LtlInputNames = {}));