import { useDispatch, useSelector } from "react-redux";
import { setShippingPlanFromAddress } from "./AddressActions";
import { useCreateInboundQuote } from "inbounds/crossdock/hooks";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { useFulfillmentInboundNavigation } from "inbounds/navigation/fulfillment/useFulfillmentInboundNavigation";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { logError, logStart } from "Logger";
import { useInboundAddressForm } from "./InboundAddressForm/useInboundAddressForm";
export const useFromAddressStep = () => {
  const dispatch = useDispatch();
  const {
    addresses,
    isSelectLoading,
    fromAddress,
    handleAddressSelectChange,
    handleAddressInputChange,
    validate
  } = useInboundAddressForm();
  const shipments = useSelector(({
    inbound
  }) => inbound.shipments);
  const {
    createQuote
  } = useCreateInboundQuote();
  const {
    goToNextStep,
    goToPreviousStep
  } = useFulfillmentInboundNavigation();
  const onSubmit = async () => {
    const ctx = logStart({
      fn: "submitFromAddress"
    });
    const isValid = validate();
    if (!isValid) {
      return;
    }
    try {
      dispatch(addLoader(InboundLoaderId.transition));
      dispatch(setShippingPlanFromAddress());
      // creates a crossdock inbound quote if one does not already exist
      // and the base information (itemQty, dims, fromZip) are not the same
      await createQuote();
      await goToNextStep();
    } catch (err) {
      // TODO: provide error toast
      logError(ctx, err);
    } finally {
      dispatch(clearLoader(InboundLoaderId.transition));
    }
  };
  return {
    addresses,
    shipments,
    isSelectLoading,
    fromAddress,
    onNextClick: onSubmit,
    onPreviousClick: goToPreviousStep,
    handleAddressSelectChange,
    handleAddressInputChange
  };
};