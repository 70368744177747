import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { ApiError, DeliverrError } from "@deliverr/commons-objects";
export class OnboardingClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.ONBOARDING_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async getSellerHash(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/oauth/verifier/${sellerId}`,
        params: {},
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("getSellerHash", error);
    }
  }

  /**
   * Get a deejayOrderV2 record from DynamoDB querying the sellerId-sellerOrderId-index.
   * @returns OnboardingOrder
   */
  async getOnboardingOrderBySellerOrderId(sellerId, channel, sellerOrderId) {
    try {
      return await this.apiClient.get({
        url: `/ecommerce/order/statusBySellerId`,
        params: {
          sellerId,
          channel,
          sellerOrderId
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("getOnboardingOrderBySellerOrderId", error);
    }
  }
  async getPipe17ApiKey(code) {
    try {
      return await this.apiClient.get({
        url: `/pipe17/apikey`,
        params: {
          code
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("getPipe17ApiKey", error);
    }
  }
  async connectListingSolution(listingSolution, sellerId, data) {
    try {
      return await this.apiClient.post({
        url: `/${listingSolution}/setup`,
        body: {},
        params: {
          sellerId,
          ...data
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("connectListingSolution", error);
    }
  }
  async addAndActivateSalesChannel(salesChannelName, sellerId, data) {
    try {
      return await this.apiClient.post({
        url: `/${salesChannelName}/addchannel`,
        body: {},
        params: {
          sellerId,
          ...data
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("addAndActivateSalesChannel", error);
    }
  }

  // Requires jwtToken authentication
  async getCatalogUpdateStatus() {
    try {
      return await this.apiClient.get({
        url: `/jobs/product/sync`,
        params: {},
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("getCatalogUpdateStatus", error);
    }
  }
  async getOrderForCreation(sellerId, listingSolution, orderId) {
    try {
      return await this.apiClient.get({
        url: `${listingSolution}/order/creation/${orderId}`,
        params: {
          sellerId
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("getOrderForCreation", error);
    }
  }
  async deleteOnboardingOrder(sellerId, channel, sellerOrderId) {
    try {
      return await this.apiClient.delete({
        url: `/ecommerce/order`,
        params: {
          sellerId,
          channel,
          sellerOrderId
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("deleteOnboardingOrder", error);
    }
  }
  async createOrders(sellerId, listingSolution, orders) {
    try {
      /**
       * even if order fails to fully process - create a deliverrOrder in fulfillment - ProcessEntityResult.status can
       * equal "SUCCESS". In this case, that means we saw a known error that was handled appropriately.
       * "SUCCESS" should not be used to determine successful order creation, use getOnboardingOrderBySellerOrderId to
       * look up an OnboardingOrder and check for the presence of a deliverrOrderId.
       */
      return await this.apiClient.post({
        url: `${listingSolution}/orders`,
        body: orders,
        params: {
          sellerId
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      this.handleOnboardingError("createOrders", error);
    }
  }
  handleOnboardingError(name, err) {
    if (!err.response) {
      throw new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName: name,
        payload: err
      });
    }
    if (err.response?.status !== undefined && err.response.status === 504) {
      const networkError = new DeliverrError({
        code: ApiError.NETWORK_ERROR,
        functionName: name
      });
      throw networkError;
    }
    throw new DeliverrError({
      code: err.response.data ? err.response.data.code : ApiError.UNKNOWN_ERROR,
      subcode: String(err.response.status),
      // TODO use response.data.error.subcode when error handling is standardized
      functionName: name,
      payload: err.response.data
    });
  }
}