import produce from "immer";
import { noop } from "lodash/fp";
import { useLayoutEffect, useState } from "react";
import { getNextState, IndeterminateCheckboxState } from "../IndeterminateCheckbox/indeterminateCheckboxState";
export const useMultiSelect = ({
  onChange,
  filterOption,
  ...props
}) => {
  // Determines the state for the "toggle all" checkbox at the very top
  const getIndeterminateState = () => {
    // nothing selected
    if (props.value == null || props.value.length === 0) {
      return IndeterminateCheckboxState.NONE_SELECTED;
    }

    // everything selected
    if (props.value?.length === props.options?.length) {
      return IndeterminateCheckboxState.ALL_SELECTED;
    }

    // somewhere in between
    return IndeterminateCheckboxState.PARTIALLY_SELECTED;
  };

  // track the input value that's used to search for elements
  const [inputValue, setInputValue] = useState("");
  const [indeterminantState, setIndeterminantState] = useState(() => getIndeterminateState());
  const toggleAll = () => {
    if (onChange == null) {
      return;
    }

    // deep copy all options (ALL) or return an empty array (NONE)
    const nextState = getNextState(indeterminantState);
    if (nextState === IndeterminateCheckboxState.ALL_SELECTED) {
      const options = produce(props.options, noop);
      onChange(options);
    } else {
      onChange([]);
    }
  };

  // Track the input value. We care about this value for two reasons:
  // 1. We want to hide the "toggle all" checkbox unless the input is empty
  // 2. We want to prevent clearing the input when an option is selected. react-select
  // normally does this by clearing the inputValue internally on a `set-value` event.
  // Hence, we manually control the inputValue it receives and only update it on `input-change`
  const handleInputChange = (newInputValue, {
    action
  }) => {
    if (action === "input-change") {
      setInputValue(newInputValue);
    }
    if (action === "input-blur" || action === "menu-close") {
      setInputValue("");
    }
  };

  // push or splice the selected option to/from the values array
  const updateSelectedItem = item => {
    let newValue;
    if (props.value == null) {
      newValue = [item];
    } else {
      newValue = produce(props.value, draft => {
        const itemIdx = draft.findIndex(({
          value
        }) => item.value === value);
        if (itemIdx !== -1) {
          draft.splice(itemIdx, 1);
        } else {
          draft.push(item);
        }
      });
    }

    // We have to check this to avoid a null ref, but this component
    // is controlled and can't work without onChange. Maybe worthwhile to throw
    // an error to the developer if it's null.
    if (onChange) {
      onChange(newValue);
    }
  };

  // Recalculate the "toggle all" checkbox state when values/optons change.
  // This isn't the most efficient way of doing this, since it creates an extra
  // render after values/options update. But unless this becomes a performance issue,
  // it's not worth adding the "correct", but messier logic.
  useLayoutEffect(() => {
    setIndeterminantState(getIndeterminateState());
  }, [props.value, props.options]);
  return {
    inputValue,
    toggleAll,
    indeterminantState,
    handleInputChange,
    updateSelectedItem
  };
};