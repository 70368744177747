import { LTL_PALLET_INITIAL_LENGTH, LTL_PALLET_INITIAL_WIDTH } from "freight/constants/cargoPalletConstants";
export const newPalletItem = {
  totalPallets: 1,
  palletDimensions: {
    width: LTL_PALLET_INITIAL_WIDTH,
    length: LTL_PALLET_INITIAL_LENGTH,
    height: "",
    // prevent a default value
    weight: "" // prevent a default value
  }
};

export const cargoPalletDetailsInitialState = {
  totalPalletCount: undefined,
  totalPalletWeight: undefined,
  palletLineItems: []
};