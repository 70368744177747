import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { RmtId } from "@deliverr/returns-client";
import produce from "immer";
export let RmtIntegrationActionTypes;
(function (RmtIntegrationActionTypes) {
  RmtIntegrationActionTypes["SET_RMT_STATE"] = "SET_RMTS";
  RmtIntegrationActionTypes["GOTO_RMT_STEP"] = "GOTO_RMT_STEP";
  RmtIntegrationActionTypes["FETCH_RMTS_START"] = "FETCH_RMTS_START";
  RmtIntegrationActionTypes["FETCH_RMTS_SUCCESS"] = "FETCH_RMTS_SUCCESS";
  RmtIntegrationActionTypes["FETCH_RMTS_ERROR"] = "FETCH_RMTS_ERROR";
})(RmtIntegrationActionTypes || (RmtIntegrationActionTypes = {}));
export let RmtStepId;
(function (RmtStepId) {
  RmtStepId["CONNECTED_RMT"] = "CONNECTED_RMT";
  RmtStepId["MANAGE_RMT"] = "MANAGE_RMT";
  RmtStepId["SETUP_RMT"] = "SETUP_RMT";
})(RmtStepId || (RmtStepId = {}));
const reducers = {
  ...handleSimpleReducerUpdates([RmtIntegrationActionTypes.GOTO_RMT_STEP, RmtIntegrationActionTypes.SET_RMT_STATE]),
  [RmtIntegrationActionTypes.FETCH_RMTS_START]: state => produce(state, draft => {
    draft.sellerRmts.loading = true;
    draft.sellerRmts.loaded = false;
  }),
  [RmtIntegrationActionTypes.FETCH_RMTS_SUCCESS]: (state, {
    sellerRmts
  }) => produce(state, draft => {
    draft.sellerRmts.loading = false;
    draft.sellerRmts.error = false;
    draft.sellerRmts.loaded = true;
    draft.sellerRmts.data = sellerRmts;
  }),
  [RmtIntegrationActionTypes.FETCH_RMTS_ERROR]: state => produce(state, draft => {
    draft.sellerRmts.loading = false;
    draft.sellerRmts.error = true;
  })
};
export const returnsInitialRmtState = {
  allRmtIds: [RmtId.RETURNLY, RmtId.RETURNGO, RmtId.LOOP, RmtId.AMAZON],
  sellerRmts: {
    loading: false,
    loaded: false,
    error: false,
    data: []
  },
  stepId: undefined
};
export const returnsRmtReducer = createReducer(returnsInitialRmtState, reducers);