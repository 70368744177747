import { useIntl } from "react-intl";
import { ProductLotNumberSelectMessages } from "./ProductLotNumberSelectMessages";
export const useProductLotNumberSelect = ({
  productLotNumbers,
  selectedLotNumber,
  onSelectLotNumber
}) => {
  const {
    formatMessage
  } = useIntl();
  const fefoLotOption = {
    value: "",
    label: formatMessage(ProductLotNumberSelectMessages.fefoLabel),
    subtitle: ""
  };
  const selectionOptions = [fefoLotOption, ...(productLotNumbers?.filter(({
    totalPickableQty,
    lotNumber
  }) => totalPickableQty > 0 && lotNumber)?.map(atpDetails => ({
    value: atpDetails.lotNumber ?? "",
    label: atpDetails.lotNumber ?? "",
    subtitle: formatMessage(atpDetails.expirationDate ? ProductLotNumberSelectMessages.lotNumberWithExpiryDesc : ProductLotNumberSelectMessages.lotNumberWithoutExpiryDesc, {
      availableQty: atpDetails.totalPickableQty,
      expirationDate: atpDetails.expirationDate
    })
  })) ?? [])];
  const selectedOption = selectedLotNumber ? selectionOptions.find(option => option.value === selectedLotNumber.lotNumber) : fefoLotOption;
  const onLotNumberChange = option => {
    onSelectLotNumber?.(option?.value);
  };
  return {
    selectionOptions,
    selectedOption,
    onLotNumberChange
  };
};