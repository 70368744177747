import { freightApiAdapter } from "common/clients/instances";
import { logError, logStart } from "Logger";
import { FreightTrackingInfoExternalIdType } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoExternalIdType";
import { setShipmentFreightInfo } from "./setShipmentFreightInfo";
import { notifyUserOfError } from "common/ErrorToast";
import { fetchFreightTrackingInfo } from "./fetchFreightTrackingInfo";
import { createFreightTrackingInfo } from "./createFreightTrackingInfo";
import { getSellerId } from "common/user/UserSelectors";
import { extractFreightShipmentInfo } from "inbounds/steps/ship/InboundUtils";
export const updateFreightTrackingInfo = (shipmentId, updateData) => async (dispatch, getState) => {
  const rootState = getState();
  const sellerId = getSellerId(rootState);
  const ctx = logStart({
    fn: "updateFreightTrackingInfo",
    sellerId,
    shipmentId,
    ...updateData
  });
  if (!shipmentId || !updateData) {
    logError(ctx, new Error("ShipmentId and updateData must not be undefined"));
  }

  // Support older LTL shipments by creating new Freight Info if it doesn't already exist
  const hasExistingFreightInfo = await dispatch(fetchFreightTrackingInfo(shipmentId));

  // Create freight info if it doesn't already exist
  if (!hasExistingFreightInfo) {
    return dispatch(createFreightTrackingInfo(updateData));
  }
  const fullUpdateData = {
    externalId: String(shipmentId),
    externalIdType: FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT,
    ...extractFreightShipmentInfo(updateData) // ensure only expected fields are sent (without undefined values)
  };

  try {
    await freightApiAdapter.updateFreightTrackingInfo(sellerId, fullUpdateData);
    dispatch(setShipmentFreightInfo({
      ...fullUpdateData,
      saveSource: "UPDATE"
    }));
  } catch (err) {
    logError(ctx, err);
    notifyUserOfError({
      err,
      explanation: `An error occurred while updating freight information for this shipment.
       Please verify the information and try again.`,
      toastId: "updateFreightTrackingInfo"
    });
  }
};