/**
 * Converts legacy Algolia filters to an OpenSearch query.
 *
 * @param searchRequest Search request with filters
 * @returns Query string for OpenSearch
 */
export const convertAlgoliaFiltersToOpenSearch = searchRequest => {
  if (!searchRequest.filters && !searchRequest.numericFilter) {
    return {};
  }

  // Replace equals with colon for lucene
  const openSearchFilters = (searchRequest.filters ?? []).map(filter => filter.replace("=", ":"));

  // Add numeric filter to end of query
  if (searchRequest.numericFilter) {
    const [attribute, operator, value] = searchRequest.numericFilter;
    const filter = `${attribute}:${operator}${value}`;
    openSearchFilters.push(filter);
  }
  const rawQueryString = openSearchFilters.join(" AND ");
  const queryString = {
    bool: {
      filter: {
        query_string: {
          query: rawQueryString
        }
      }
    }
  };
  return queryString;
};
export const addOpenSearchFilters = (query, openSearchFilters) => {
  const combinedQuery = mergeOpenSearchFilters([query, ...openSearchFilters]);
  return combinedQuery;
};

/**
 * Merges multiple OpenSearch filters into a single object that's a valid OpenSearch query.
 *
 * @param filters List of filters to merge
 * @returns Combined OpenSearch query
 */
export const mergeOpenSearchFilters = filters => {
  const queryDslBoolQueryKeys = ["must", "should", "must_not", "filter", "minimum_should_match"];
  return filters.reduce((acc, filter) => {
    Object.keys(filter).forEach(key => {
      if (!acc[key]) {
        // Initialize the key with an empty array or object if it hasn't been seen yet
        acc[key] = Array.isArray(filter[key]) || queryDslBoolQueryKeys.includes(key) ? [] : {};
      }
      if (Array.isArray(filter[key])) {
        // Concatenate arrays
        acc[key] = acc[key].concat(filter[key]);
      } else if (typeof filter[key] === "object") {
        if (queryDslBoolQueryKeys.includes(key)) {
          // We don't want to merge bool queries, so we just concatenate them
          // Otherwise, we get an incorrectly shaped query with duplicated
          // properties inside an object.
          acc[key] = (acc[key] || []).concat(mergeOpenSearchFilters([filter[key]]));
        } else {
          // Otherwise, we recursively merge objects
          acc[key] = mergeOpenSearchFilters([acc[key], filter[key]]);
        }
      } else {
        acc[key] = filter[key];
      }
    });
    return acc;
  }, {});
};