export let ListingSolution;
(function (ListingSolution) {
  ListingSolution["ACENDA"] = "acenda";
  ListingSolution["AMAZON"] = "amazon";
  ListingSolution["ARTEZA"] = "arteza";
  ListingSolution["BIGCOMMERCE"] = "bigcommerce";
  ListingSolution["CHANNEL_ADVISOR"] = "channel_advisor";
  ListingSolution["DELIVERR_API"] = "deliverr_api";
  ListingSolution["EBAY"] = "ebay";
  ListingSolution["ECOMDASH"] = "ecomdash";
  ListingSolution["ETSY"] = "etsy";
  ListingSolution["FACEBOOK"] = "facebook";
  ListingSolution["FLIEBER"] = "flieber";
  ListingSolution["GEEKSELLER"] = "geekseller";
  ListingSolution["GOOGLE"] = "google";
  ListingSolution["JCPENNEY"] = "jcpenney";
  ListingSolution["LINNWORKS"] = "linnworks";
  ListingSolution["LISTINGMIRROR"] = "listingmirror";
  ListingSolution["MIRAKL"] = "mirakl";
  ListingSolution["MOBILITYECOMMERCE"] = "mobilityecommerce";
  ListingSolution["PIPE17"] = "pipe17";
  ListingSolution["SELLBRITE"] = "sellbrite";
  ListingSolution["SELLERACTIVE"] = "selleractive";
  ListingSolution["SELLERCHAMP"] = "sellerchamp";
  ListingSolution["SELLERCLOUD"] = "sellercloud";
  ListingSolution["SHEIN"] = "shein";
  ListingSolution["SHOPIFY"] = "shopify";
  ListingSolution["SHOPIFY_V2"] = "shopify_v2";
  ListingSolution["SHOPIFY_V3"] = "shopify_v3";
  ListingSolution["SHOPIFY_FULFILLMENT_NETWORK"] = "shopify_fulfillment_network";
  ListingSolution["SKUBANA"] = "skubana";
  ListingSolution["SKUPREME"] = "skupreme";
  ListingSolution["SOLIDCOMMERCE"] = "solidcommerce";
  ListingSolution["SPSCOMMERCE"] = "spscommerce";
  ListingSolution["STOREAUTOMATOR"] = "storeautomator";
  ListingSolution["WALMARTDIRECT"] = "walmartdirect";
  ListingSolution["WISH"] = "wish";
  ListingSolution["WOOCOMMERCE"] = "woocommerce";
  ListingSolution["ZENTAIL"] = "zentail";
})(ListingSolution || (ListingSolution = {}));