export let BulkUploadError;
(function (BulkUploadError) {
  BulkUploadError["FILE_BAD_EXTENSION"] = "FILE_BAD_EXTENSION";
  BulkUploadError["FILE_TOO_BIG"] = "FILE_TOO_BIG";
  BulkUploadError["SHEET_OVERFILLED"] = "SHEET_OVERFILLED";
  BulkUploadError["SHEET_NOT_FOUND"] = "SHEET_NOT_FOUND";
  BulkUploadError["SHEET_EMPTY"] = "SHEET_EMPTY";
  BulkUploadError["SHEET_COLUMN_EXTRANEOUS"] = "SHEET_COLUMN_EXTRANEOUS";
  BulkUploadError["SHEET_ROW_EXTRANEOUS_REPLENISHMENT"] = "SHEET_ROW_EXTRANEOUS_REPLENISHMENT";
  BulkUploadError["SHEET_ROW_EXTRANEOUS"] = "SHEET_ROW_EXTRANEOUS";
  BulkUploadError["VALUE_BAD_STRING"] = "VALUE_BAD_STRING";
  BulkUploadError["VALUE_BAD_WHOLE_NUMBER"] = "VALUE_BAD_WHOLE_NUMBER";
  BulkUploadError["VALUE_BAD_NUMBER"] = "VALUE_BAD_NUMBER";
  BulkUploadError["VALUE_MISSING"] = "VALUE_MISSING";
  BulkUploadError["PRODUCT_DIMS_OVERSIZED"] = "PRODUCT_DIMS_OVERSIZED";
  BulkUploadError["PRODUCT_DOES_NOT_EXIST"] = "PRODUCT_DOES_NOT_EXIST";
  BulkUploadError["MSKU_DUPLICATE"] = "MSKU_DUPLICATE";
  BulkUploadError["UNEXPECTED_ERROR"] = "UNEXPECTED_ERROR";
  BulkUploadError["UNABLE_TO_IMPORT_PRODUCTS"] = "UNABLE_TO_IMPORT_PRODUCTS";
  BulkUploadError["UNABLE_TO_IMPORT_PRODUCTS_TRY_AGAIN"] = "UNABLE_TO_IMPORT_PRODUCTS_TRY_AGAIN";
  BulkUploadError["UNABLE_TO_IMPORT_PRODUCTS_LINK"] = "UNABLE_TO_IMPORT_PRODUCTS_LINK";
  BulkUploadError["SHEET_TYPE_MISMATCH"] = "SHEET_TYPE_MISMATCH";
  BulkUploadError["CASE_PACKED_TEMPLATE"] = "CASE_PACKED_TEMPLATE";
  BulkUploadError["INDIVIDUAL_TEMPLATE"] = "INDIVIDUAL_TEMPLATE";
  BulkUploadError["MSKU_DSKU_MISMATCH"] = "MSKU_DSKU_MISMATCH";
  BulkUploadError["MSKU_CASE_PACK_MISMATCH"] = "MSKU_CASE_PACK_MISMATCH";
  BulkUploadError["DSKU_CASE_PACK_MISMATCH"] = "DSKU_CASE_PACK_MISMATCH";
  BulkUploadError["PRODUCT_ASIN_MISSING"] = "PRODUCT_ASIN_MISSING";
  BulkUploadError["LARGE_CASE_PACK"] = "LARGE_CASE_PACK";
  BulkUploadError["MISSING_IDENTIFIER"] = "MISSING_IDENTIFIER";
  BulkUploadError["INVENTORY_NOT_AVAILABLE"] = "INVENTORY_NOT_AVAILABLE";
  BulkUploadError["PRODUCT_INVENTORY_UNAVAILABLE"] = "PRODUCT_INVENTORY_UNAVAILABLE";
  BulkUploadError["BOX_BAD_WEIGHT"] = "BOX_BAD_WEIGHT";
  BulkUploadError["BOX_BAD_DIMENSIONS"] = "BOX_BAD_DIMENSIONS";
})(BulkUploadError || (BulkUploadError = {}));