export let ButtonMessage;
(function (ButtonMessage) {
  ButtonMessage["Dismiss"] = "button.dismiss";
  ButtonMessage["Update"] = "button.update";
})(ButtonMessage || (ButtonMessage = {}));
export let ErrorMessage;
(function (ErrorMessage) {
  ErrorMessage["CompanyTaken"] = "errors.companyTaken";
  ErrorMessage["Default"] = "errors.default";
  ErrorMessage["FillInFields"] = "errors.fillInFields";
  ErrorMessage["InvalidAccessCode"] = "error.invalidAccessCode";
  ErrorMessage["InvalidAddress"] = "error.invalidAddress";
  ErrorMessage["InvalidDateRange"] = "errors.invalidDateRange";
  ErrorMessage["InvalidDistributionCenter"] = "errors.invalidDistributionCenter";
  ErrorMessage["InvalidField"] = "errors.invalidField";
  ErrorMessage["InvalidLogin"] = "errors.invalidLogin";
  ErrorMessage["InvalidRateTables"] = "errors.invalidRateTables";
  ErrorMessage["InvalidSellbrite"] = "errors.invalidSellbrite";
  ErrorMessage["InvalidSignupCode"] = "errors.invalidSignupCode";
  ErrorMessage["InvalidShipFrom"] = "errors.invalidShipFrom";
  ErrorMessage["InvalidWalmartApiKeys"] = "errors.walmartInvalid";
  ErrorMessage["MissingAccount"] = "errors.missingAccount";
  ErrorMessage["DefaultErrorModalBody"] = "errors.modal.body.default";
  ErrorMessage["NewOrderStockout"] = "errors.newOrderStockout";
  ErrorMessage["UnableToCancelOrder"] = "errors.unableToCancelOrder";
})(ErrorMessage || (ErrorMessage = {}));
export let FieldMessage;
(function (FieldMessage) {
  FieldMessage["Name"] = "fields.name";
  FieldMessage["Company"] = "fields.company";
  FieldMessage["Email"] = "fields.email";
  FieldMessage["Phone"] = "fields.phone";
  FieldMessage["AccountToken"] = "fields.accountToken";
  FieldMessage["IntegrationKey"] = "fields.integrationKey";
  FieldMessage["RegisteredEmail"] = "fields.registeredEmail";
  FieldMessage["ApiKey"] = "fields.apiKey";
  FieldMessage["ApiSecret"] = "fields.apiSecret";
  FieldMessage["VerificationCode"] = "fields.verificationCode";
  FieldMessage["SignupCode"] = "fields.signupCode";
  FieldMessage["EstimatedDeliveryDate"] = "fields.estimatedDeliveryDate";
})(FieldMessage || (FieldMessage = {}));
export let Message;
(function (Message) {
  Message["DeactivateEgd"] = "messages.egd.deactivateEgd";
})(Message || (Message = {}));
export let PlaceholderMessage;
(function (PlaceholderMessage) {
  PlaceholderMessage["Company"] = "placeholders.company";
  PlaceholderMessage["Email"] = "placeholders.email";
  PlaceholderMessage["Name"] = "placeholders.name";
  PlaceholderMessage["Phone"] = "placeholders.phone";
  PlaceholderMessage["Password"] = "placeholders.password";
  PlaceholderMessage["ConfirmPassword"] = "placeholders.confirmPassword";
  PlaceholderMessage["Select"] = "placeholders.select";
  PlaceholderMessage["VerificationCode"] = "placeholders.verificationCode";
  PlaceholderMessage["SignupCode"] = "placeholders.signupCode";
})(PlaceholderMessage || (PlaceholderMessage = {}));
export const messages = {
  // buttons
  [ButtonMessage.Dismiss]: "Dismiss",
  [ButtonMessage.Update]: "Update",
  // errors
  [ErrorMessage.CompanyTaken]: "We already have an account with that email",
  [ErrorMessage.Default]: "Oops, something went wrong",
  [ErrorMessage.FillInFields]: "Fields must not be empty",
  [ErrorMessage.InvalidAccessCode]: "Invalid access code",
  [ErrorMessage.InvalidAddress]: "Please enter a valid address",
  [ErrorMessage.InvalidDateRange]: "Invalid date range",
  [ErrorMessage.InvalidDistributionCenter]: "Please verify that Flexport distribution center was added correctly",
  [ErrorMessage.InvalidField]: "Invalid field: {fieldName}",
  [ErrorMessage.InvalidLogin]: "Oops, wrong email or password",
  [ErrorMessage.InvalidRateTables]: "Not all rate tables found. Please try running the egd browser extension again.",
  [ErrorMessage.InvalidSellbrite]: "Please double check your account token and api secret",
  [ErrorMessage.InvalidSignupCode]: "Please enter a valid signup code",
  [ErrorMessage.InvalidShipFrom]: "Please check your ship from address.",
  [ErrorMessage.InvalidWalmartApiKeys]: "Please double check that your api keys are correct.",
  [ErrorMessage.MissingAccount]: "We don't have an account with that email",
  [ErrorMessage.DefaultErrorModalBody]: "Something went wrong. Please try your last action again.\n" + "If the problem persists, please file a support ticket using the help icon.",
  [ErrorMessage.NewOrderStockout]: "At least one selected product is out of stock.",
  [ErrorMessage.UnableToCancelOrder]: "Sorry, we could not cancel your order",
  // field names
  [FieldMessage.Name]: "Full Name",
  [FieldMessage.Company]: "Company",
  [FieldMessage.Email]: "Email",
  [FieldMessage.Phone]: "Business Phone Number",
  [FieldMessage.AccountToken]: "Account Token",
  [FieldMessage.IntegrationKey]: "Integration Key",
  [FieldMessage.RegisteredEmail]: "Registered Email",
  [FieldMessage.ApiKey]: "API Key",
  [FieldMessage.ApiSecret]: "API Secret",
  [FieldMessage.VerificationCode]: "Verification Code",
  [FieldMessage.SignupCode]: "Signup Code",
  [FieldMessage.EstimatedDeliveryDate]: "Estimated delivery date",
  // messages
  [Message.DeactivateEgd]: "Successfully deactivated eBay Guaranteed Delivery",
  // placeholders
  [PlaceholderMessage.Company]: "Acme, Inc",
  [PlaceholderMessage.Email]: "yourname@company.com",
  [PlaceholderMessage.Name]: "First & Last Name",
  [PlaceholderMessage.Phone]: "###-###-####",
  [PlaceholderMessage.Password]: "Must be 8 characters or more",
  [PlaceholderMessage.ConfirmPassword]: "Making sure it's the same",
  [PlaceholderMessage.Select]: "Select one...",
  [PlaceholderMessage.VerificationCode]: "######",
  [PlaceholderMessage.SignupCode]: "#########"
};