import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";
export const selectBookingItemRequest = () => createSelector(selectBookingRequest(false), bookingRequest => {
  const {
    bookingProducts,
    cargoReadyDate,
    cargoTotalBoxes,
    cargoTotalItems,
    cargoTotalVolume,
    cargoTotalWeight,
    cargoUnitSystem,
    dangerousGoods,
    lithiumBatteries,
    lithiumBatteryPackaging,
    nonLithiumBatteries,
    otherDangerousGoods,
    packages,
    palletCount
  } = bookingRequest;
  const bookingItemRequest = {
    bookingProducts,
    cargoReadyDate,
    cargoTotalBoxes,
    cargoTotalItems,
    cargoTotalVolume,
    cargoTotalWeight,
    cargoUnitSystem,
    dangerousGoods,
    lithiumBatteries,
    lithiumBatteryPackaging,
    nonLithiumBatteries,
    otherDangerousGoods,
    packages: packages ?? [],
    palletCount
  };
  return bookingItemRequest;
});