import { useDispatch, useSelector } from "react-redux";
import { getStorageInboundDetail } from "../store";
import { useEffect } from "react";
import { loadStorageReceivingInfo } from "../store/actions/loadStorageReceivingInfo";
export const useStorageShipmentDetail = () => {
  const dispatch = useDispatch();
  const {
    storageInboundOrder
  } = useSelector(getStorageInboundDetail);
  const {
    shipmentId,
    status,
    from,
    to,
    warehouseId,
    asnId,
    sellerId
  } = storageInboundOrder;
  const warehouses = useSelector(state => state.deliverr.warehouses);
  const storageWarehouse = warehouses[warehouseId];
  const shouldShowAvailableUnits = status === "RECEIVING";
  useEffect(() => {
    const fetchInfo = async () => {
      await dispatch(loadStorageReceivingInfo(sellerId, shipmentId));
    };
    if (shipmentId) {
      void fetchInfo();
    }
  }, [dispatch, shipmentId, sellerId]);
  return {
    asnId,
    shipmentId,
    status,
    from,
    to,
    storageWarehouse,
    shouldShowAvailableUnits
  };
};