import { sumBy } from "lodash";
export const getShipmentItemsSummary = shipment => {
  const numOfSkus = shipment.shipmentItems?.length ?? 0;
  const numOfCases = sumBy(shipment.shipmentItems ?? [], item => (item.totalUnits ?? 0) / (item.unitsPerPack ?? 1));
  const numOfUnits = sumBy(shipment.shipmentItems ?? [], item => item.totalUnits ?? 0);
  return {
    numOfCases,
    numOfSkus,
    numOfUnits
  };
};