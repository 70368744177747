import { reduce } from "lodash";
export const aggregateATP = (atp1, atp2) => {
  const inventoryKeys = ["onHandQty", "availableToPurchase", "inTransferQty", "nonPickableQty", "pickableQty", "unavailableQty", "unfilledOrderQty"];
  const aggregatedATP = reduce(inventoryKeys, (agg, key) => {
    agg[key] = (atp1?.[key] ?? 0) + (atp2?.[key] ?? 0);
    return agg;
  }, {});
  aggregatedATP.poolName = atp1?.poolName ?? atp2?.poolName ?? "";
  aggregatedATP.details = [...(atp1?.details ?? []), ...(atp2?.details ?? [])];
  return aggregatedATP;
};