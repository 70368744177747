import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";
import { selectCreateShipmentForm } from "inbounds/createShipment/store/selectors";
export const useCreateShipmentForm = () => {
  /** 1. grab our defaults from redux */
  const createShipmentFormState = useSelector(selectCreateShipmentForm);
  // we need to clone the defaults because useForm can mutate the defaultValues via outdated hooks like useFieldArray
  const defaultValues = useMemo(() => cloneDeep(createShipmentFormState), [createShipmentFormState]);

  /** 2. pass them to our form */
  const formHandler = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues
  });
  return formHandler;
};