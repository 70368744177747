import { FreightMode } from "@deliverr/freight-client";
export const freightQuoteFormInitialState = {
  form: {
    fromZip: "",
    toZip: "",
    sellerId: undefined,
    internalUserId: "",
    numberOfTrucks: 1,
    plan: [{
      service: FreightMode.FTL
    }]
  },
  isLoading: false
};