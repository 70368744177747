import { IngestionChannel, InvalidOrderItemReason, InvalidOrderItemResolution } from "@deliverr/replenishment-client";
import { every, filter, isEmpty, map } from "lodash";
import { find } from "lodash/fp";
export const getSkuQtiesForInvalidItemOrder = replenishmentOrder => {
  const orderItemSkuQties = map(replenishmentOrder?.orderItems, item => ({
    dsku: item.dsku,
    qty: item.totalUnits
  }));
  const invalidOrderItemSkuQties = filter(replenishmentOrder?.invalidOrderItems, item => item.resolution === InvalidOrderItemResolution.MODIFIED).map(item => ({
    dsku: item.resolvedDsku,
    qty: item.resolvedTotalUnits
  }));
  return [...orderItemSkuQties, ...invalidOrderItemSkuQties];
};

// The assumption here if OUT_OF_STOCK problem is present, all other problems are resolved
export const isInvalidItemOrder = replenishmentOrder => replenishmentOrder?.ingestionChannel === IngestionChannel.SPS && (replenishmentOrder.invalidOrderItems?.length ?? 0) > 0 && !find(a => a.reason === InvalidOrderItemReason.OUT_OF_STOCK, replenishmentOrder?.invalidOrderItems ?? []);
export const areAllItemsResolvedFromInvalidItemOrder = replenishmentOrder => every(replenishmentOrder?.invalidOrderItems, item => item.resolution);
export const areAllItemsRemovedFromInvalidItemOrder = replenishmentOrder => isEmpty(replenishmentOrder?.orderItems) && every(replenishmentOrder?.invalidOrderItems, item => item.resolution === InvalidOrderItemResolution.REMOVED);