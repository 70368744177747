import { isPast, isValid } from "date-fns";
import { isEmpty } from "lodash/fp";
import { FULL_DATE_DASH_DATE, MONTH_YEAR_DASH_DATE } from "common/date/DateRegexPatterns";
import { getConvertedExpirationDate } from "./getConvertedExpirationDate";
import { isDayOfMonthValid } from "./isDayOfMonthValid";
import { LotInformationError } from "inbounds/steps/lot-information/types/LotInformationError";

// exported for tests only
export const INVALID_ERROR = LotInformationError.EXPIRATION_DATE_INVALID;
export const validateExpirationDate = (expirationDate, hasFullExpirationDate) => {
  if (isEmpty(expirationDate)) {
    return INVALID_ERROR;
  }
  const pattern = hasFullExpirationDate ? FULL_DATE_DASH_DATE : MONTH_YEAR_DASH_DATE;
  if (!pattern.test(expirationDate)) {
    return INVALID_ERROR;
  }
  const convertedExpirationDate = getConvertedExpirationDate(expirationDate, hasFullExpirationDate);
  return isValid(convertedExpirationDate) && (hasFullExpirationDate ? isDayOfMonthValid(expirationDate) : true) && !isPast(convertedExpirationDate) ? undefined : INVALID_ERROR;
};