export let NotificationType;
(function (NotificationType) {
  NotificationType["OutboundInvalidAddressType"] = "OutboundInvalidAddressType";
  NotificationType["InboundNonCompliantProductsType"] = "InboundNonCompliantProductsType";
  NotificationType["InboundDiscrepanciesType"] = "InboundDiscrepanciesType";
  NotificationType["BulkUploadSuccessType"] = "BulkUploadSuccessType";
  NotificationType["BulkUploadFailedType"] = "BulkUploadFailedType";
  NotificationType["BulkUploadPartialType"] = "BulkUploadPartialType";
  NotificationType["SMBNotificationType"] = "SMBNotificationType";
  NotificationType["SMBMessageNotificationType"] = "SMBMessageNotificationType";
})(NotificationType || (NotificationType = {}));