import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { handleSellerServiceError } from "./handleSellerServiceError";
export class SfnClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/sfn`
    });
  }
  async searchSfnAccounts(q) {
    try {
      return await this.apiClient.get({
        url: "/account",
        params: {
          q
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("searchSfnAccounts", error);
    }
  }
}