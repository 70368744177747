import { addAllToById } from "common/ById";
export const getNormalizedShippingPlanItems = plan => {
  const dskus = plan.items.map(item => item.dsku);
  const normalizedItems = plan.items.map(item => {
    return {
      ...item,
      numberOfCases: item.qty / item.caseQty
    };
  });
  return addAllToById(dskus, normalizedItems);
};