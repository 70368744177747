import { defineMessages } from "react-intl";
import { PillColors } from "common/components/ui";
import { QcItemStatus } from "@deliverr/returns-client";
export let IssueType;
(function (IssueType) {
  IssueType["UNKNOWN_BARCODE"] = "UNKNOWN_BARCODE";
  IssueType["UNEXPECTED_PRODUCT"] = "UNEXPECTED_PRODUCT";
})(IssueType || (IssueType = {}));
export const returnsInspectionStatusPillColor = {
  [QcItemStatus.INSPECTION_APPROVED]: PillColors.GREEN,
  [QcItemStatus.INSPECTION_REJECTED]: PillColors.YELLOW,
  [QcItemStatus.LOST_BY_CARRIER]: PillColors.RED,
  [QcItemStatus.LOST_DURING_INSPECTION]: PillColors.RED,
  AWAITING: PillColors.BLUE,
  DESTROYED: PillColors.YELLOW,
  DONATED: PillColors.YELLOW,
  NOT_RECEIVED: PillColors.YELLOW,
  RETURN_TO_SELLER: PillColors.GREEN
};
const approvedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.approved",
  defaultMessage: "RESTOCKED"
};
const rejectedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.rejected",
  defaultMessage: "REJECTED"
};
const donatedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.donated",
  defaultMessage: "DONATED"
};
const destroyedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.destroyed",
  defaultMessage: "DESTROYED"
};
const liquidatedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.liquidated",
  defaultMessage: "LIQUIDATED"
};
const returnToSellerMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.returnToSeller",
  defaultMessage: "RETURN TO SELLER"
};
const lostMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.lost",
  defaultMessage: "LOST"
};
const awaitingMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.awaiting",
  defaultMessage: "AWAITING"
};
const notReceivedMessage = {
  id: "returns.returnsDetail.inspectionStatusTitle.notReceived",
  defaultMessage: "NOT RECEIVED"
};
const unexpectedItemMessage = defineMessages({
  issueMessage: {
    id: "returns.returnsDetail.unexpectedItemMessage",
    defaultMessage: "Unexpected product received"
  }
});
const unknownProductMessages = defineMessages({
  displayProductName: {
    id: "returns.returnsDetail.issue.unknownProduct.title",
    defaultMessage: "Unknown Product"
  },
  issueMessage: {
    id: "returns.returnsDetail.issue.unknownProduct.message",
    defaultMessage: "Unexpected product received."
  }
});
export const returnsDetailIssueMessages = {
  [IssueType.UNKNOWN_BARCODE]: unknownProductMessages,
  [IssueType.UNEXPECTED_PRODUCT]: unexpectedItemMessage
};
export const inspectionStatusTitles = {
  [QcItemStatus.INSPECTION_APPROVED]: approvedMessage,
  [QcItemStatus.INSPECTION_REJECTED]: rejectedMessage,
  [QcItemStatus.LOST_BY_CARRIER]: lostMessage,
  [QcItemStatus.LOST_DURING_INSPECTION]: lostMessage,
  AWAITING: awaitingMessage,
  DESTROYED: destroyedMessage,
  DONATED: donatedMessage,
  LIQUIDATED: liquidatedMessage,
  RETURN_TO_SELLER: returnToSellerMessage,
  NOT_RECEIVED: notReceivedMessage
};