import { defineMessages } from "react-intl";
export const assignCategoriesContent = defineMessages({
  title: {
    id: "inbounds.assignCategories.title",
    defaultMessage: "Assign Product Categories"
  },
  description: {
    id: "inbounds.assignCategories.description",
    defaultMessage: "Confirm your product category for the correct packaging requirements. Any required packaging must be applied to each sellable unit to ensure they can be fulfilled without non-compliance or damages. Learn more about {productCategoriesLink}."
  },
  productCategoriesLink: {
    id: "inbounds.assignCategories.productCategoriesLink",
    defaultMessage: "product categories"
  },
  products: {
    id: "products",
    defaultMessage: "Products"
  },
  productSelected: {
    id: "inbounds.assignCategories.productSelected",
    defaultMessage: "1 Product Selected"
  },
  productsSelected: {
    id: "inbounds.assignCategories.productsSelected",
    defaultMessage: "{productsSelected} Products Selected"
  },
  prep: {
    id: "prep",
    defaultMessage: "Prep"
  },
  eachUnitPrep: {
    id: "inbounds.assignCategories.eachUnitPackaging",
    defaultMessage: "Packaging for Each Unit"
  },
  multipleCategories: {
    id: "inbounds.assignCategories.multipleCategories",
    defaultMessage: "Multiple categories"
  },
  productPrepTooltip: {
    id: "inbounds.assignCategories.productPrepTooltip",
    defaultMessage: "Prep requirements vary for different categories. Prep is necessary to ensure the safety of Flexport staff and to prevent damages."
  },
  singleUnit: {
    id: "inbounds.assignCategories.singleUnit",
    defaultMessage: "1 unit"
  },
  unitsPlural: {
    id: "inbounds.assignCategories.unitsPlural",
    defaultMessage: "{qty} units"
  },
  productCategoryPlaceholder: {
    id: "inbounds.assignCategories.productCategoryPlaceholder",
    defaultMessage: "Select product category"
  },
  submitProductCategoriesSuccess: {
    id: "inbounds.assignCategories.submitProductCategoriesSuccess",
    defaultMessage: "Successfully updated product category."
  },
  submitProductCategoriesError: {
    id: "inbounds.assignCategories.submitProductCategoriesError",
    defaultMessage: "Failed to update product category. Try again later."
  },
  submitBatteryTypeSuccess: {
    id: "inbounds.assignCategories.submitBatteryTypeSuccess",
    defaultMessage: "Successfully updated battery type."
  },
  submitBatteryTypeError: {
    id: "inbounds.assignCategories.submitBatteryTypeError",
    defaultMessage: "Failed to update battery type. Try again later."
  }
});