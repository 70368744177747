import { ShippingPlanStatus } from "@deliverr/legacy-inbound-client";
import { format } from "date-fns";
import { ShortMonthDateYear } from "common/date/DateFormat";
export const useListViewShippingPlanName = ({
  ncProductsByShippingPlanId,
  row
}) => {
  const {
    shipmentId,
    shipmentIndex,
    shippingPlanId,
    shippingPlanName = "",
    isPromotional,
    shippingPlanCreatedAtUnix
  } = row;
  const isArchived = row.shippingPlanStatus === ShippingPlanStatus.ARCHIVED;
  const isNC = !!ncProductsByShippingPlanId[shippingPlanId];
  return {
    isArchived,
    isNC,
    isPromotional,
    rawShippingPlanName: shippingPlanName,
    shipmentId,
    shipmentIndex,
    shippingPlanCreatedAt: format(shippingPlanCreatedAtUnix, ShortMonthDateYear)
  };
};