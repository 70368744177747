import { useFbaLocations } from "inbounds/createShipment/steps/priceViewStep/selectShipping/selectShippingFlow/steps/SelectDistribution/AmazonDestination/useFbaLocations";
import { useMemo } from "react";
export const getFormattedName = fc => {
  return `Amazon ${fc.code}`;
};
export const getAddressString = fc => {
  const addressParts = [fc.street1, fc.street2, `${fc.city}, ${fc.state}, ${fc.zip}`].filter(Boolean);
  return addressParts.join(", ");
};
export const useAmazonAddressInput = (onChangeCallback, selectedAddress) => {
  const {
    data,
    error,
    isLoading
  } = useFbaLocations();
  const locations = data ? data.value : undefined;
  const getOptionLabel = fc => {
    const name = getFormattedName(fc);
    const addressString = getAddressString(fc);
    return `${name} - ${addressString}`;
  };
  const options = useMemo(() => locations?.map(fc => ({
    value: fc,
    label: getOptionLabel(fc)
  })) ?? [], [locations]);
  const onChange = ({
    value
  }) => {
    onChangeCallback(value);
  };
  const value = selectedAddress ? {
    value: selectedAddress,
    label: getOptionLabel(selectedAddress)
  } : undefined;
  const isOptionSelected = (option, selectedValue) => {
    return option?.code === selectedValue?.code;
  };
  return {
    options,
    onChange,
    value,
    hasError: Boolean(error),
    isOptionSelected,
    isLoading,
    hasServerError: Boolean(error)
  };
};