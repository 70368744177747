import { ModalActionTypes } from "common/components/Modal/ModalActions";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
export const useModal = id => {
  const dispatch = useDispatch();
  const showModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL,
      id
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const hideModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.HIDE_MODAL,
      id
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const isActive = useSelector(state => state.modal.modals[id]);
  return {
    showModal,
    hideModal,
    isActive
  };
};