import { ShippingPlanDispersalMethod } from "@deliverr/legacy-inbound-client";
import { compact, flatten, groupBy, head, map, uniq, omitBy } from "lodash/fp";
import { getOffsetDateWeekdays } from "common/date/getOffsetDateWeekdays";
import { BASE_SHIPPING_DAY_OFFSET } from "../distribute/DistributeSelectConstants";
export const separateAsnsByCrossDock = asns => {
  const {
    true: crossDockAsns,
    false: normalAsns
  } = groupBy(asn => Boolean(asn.isCrossDock), asns);
  return {
    normalAsns: normalAsns || [],
    crossDockAsn: head(crossDockAsns)
  };
};
export const getWarehouseIdsFromShipments = shipments => {
  return compact([...shipments.map(({
    warehouseId
  }) => warehouseId), shipments.length > 0 ? shipments[0].crossDockWarehouseId : undefined]);
};
export function getInboundWarehouseIds(shipments, packagesByShipment) {
  const shipmentWarehouseIds = getWarehouseIdsFromShipments(shipments);
  const packages = flatten(Object.values(packagesByShipment));
  const packageWarehouseIds = map("warehouseId", packages);
  const warehouseIds = shipmentWarehouseIds.concat(packageWarehouseIds);
  return uniq(warehouseIds);
}
export const getDispersalMethodFromFlag = isShipToOne => {
  if (isShipToOne === undefined) {
    return undefined;
  }
  return isShipToOne ? ShippingPlanDispersalMethod.DISTRIBUTION : ShippingPlanDispersalMethod.DIRECT;
};
export const isShipToOneDispersalMethod = dispersalMethod => {
  if (dispersalMethod === undefined) {
    return undefined;
  }
  return dispersalMethod === ShippingPlanDispersalMethod.FORWARDING || dispersalMethod === ShippingPlanDispersalMethod.DISTRIBUTION;
};
export const isShipToOnePlan = shippingPlan => isShipToOneDispersalMethod(shippingPlan.dispersalMethod);
export const getDefaultFreightInfoEstimatedShipDate = () => getOffsetDateWeekdays(BASE_SHIPPING_DAY_OFFSET.weekdays ?? 0);
export const extractFreightShipmentInfo = (info, cleanNullishValues = true) => {
  const {
    estimatedDeliveryDate,
    deliveredAt,
    estimatedShipDate,
    shippingProvider,
    bolId,
    proId
  } = info;
  const extractedInfo = {
    estimatedShipDate,
    estimatedDeliveryDate,
    deliveredAt,
    shippingProvider,
    bolId,
    proId
  };
  return cleanNullishValues ? {
    ...omitBy(val => val == null, extractedInfo)
  } : extractedInfo;
};