import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { useFieldArray } from "react-hook-form";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useStorageInboundProductChooser } from "../StorageInboundProductChooser/useStorageInboundProductChooser";
import { omit } from "lodash";
export const useIntlDtcInboundProductChooser = (isDtcMixedSkuBooking, index, singleSkuPackages) => {
  const connectedProps = useStorageInboundProductChooser();
  const {
    control,
    setValue,
    unregister
  } = useCreateShipmentFormContext();
  const {
    fields: mixedSkuPackage,
    append: appendMixedSkuPackage,
    remove: removeMixedSkuPackage
  } = useFieldArray({
    control,
    name: `${CreateShipmentInputName.MIXED_SKU_PACKAGES}.${index ?? 0}.items`
  });
  const addProduct = (dsku, product) => {
    if (isDtcMixedSkuBooking) {
      appendMixedSkuPackage({
        dsku
      });
    } else {
      setValue(CreateShipmentInputName.SINGLE_SKU_PACKAGES, {
        ...singleSkuPackages,
        [dsku]: []
      });
    }
    return connectedProps.addProduct(dsku, product);
  };
  const removeProduct = (dsku, itemIndex) => {
    if (isDtcMixedSkuBooking) {
      removeMixedSkuPackage(itemIndex);
    } else {
      unregister(`${CreateShipmentInputName.SINGLE_SKU_PACKAGES}.${dsku}`);
      setValue(CreateShipmentInputName.SINGLE_SKU_PACKAGES, omit(singleSkuPackages, dsku));
    }
  };
  const onCreateProductSuccess = async product => {
    await addProduct(product.dsku, product);
    window.open(`/inventory/${product.dsku}`, "_blank");
  };
  return {
    ...connectedProps,
    addProduct,
    removeProduct,
    onCreateProductSuccess,
    mixedSkuPackage,
    shouldShowFixCasePackWarning: connectedProps.shouldShowFixCasePackWarning && !isDtcMixedSkuBooking,
    showCasePackSelectionDropdown: connectedProps.showCasePackSelectionDropdown && !isDtcMixedSkuBooking
  };
};