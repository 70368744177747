import { CostEstimatorModalActionTypes } from "common/components/EstimatedCosts/CostEstimatorModalActions";
import { createListReducers, listInitialState, ListType } from "common/list";
import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { InventoryListActionTypes } from "inventory/list/InventoryListActionTypes";
import { WalmartSyncActionTypes } from "inventory/list/InventoryListTableControls/walmart-sync/WalmartSyncActions";
import { combineReducers } from "redux";
import { ServiceLevel } from "common/clients/pricer/ServiceLevel";
import { InventoryLocationFilterOptions } from "./InventoryListTableControls/InventoryListFilters/InventoryLocationFilter";
export const inventoryListInitialFilters = {
  estimatedCostsServiceLevel: ServiceLevel.StandardNonFastTag,
  countryCode: "CA",
  isActive: true
};
export const inventoryListInitialState = {
  ...inventoryListInitialFilters,
  searchTerm: "",
  currentTab: "PRODUCTS",
  productList: {
    ...listInitialState,
    sort: {
      fieldName: "onHandQty",
      direction: SortDir.DESC
    },
    hasInventory: false,
    showWalmartSync: false,
    walmartSyncIsActive: false,
    isPricesLoading: false,
    isStorageInventoryLoading: false
  },
  bundleList: {
    ...listInitialState,
    sort: {
      fieldName: "unitsSoldLast30Days",
      direction: SortDir.DESC
    },
    isPricesLoading: false,
    bskuToAvailableQty: {},
    bskuToFulfillmentFee: {}
  },
  productSyncInProgress: false,
  filtersBladeOpen: false,
  isActive: true,
  inventoryLocation: InventoryLocationFilterOptions.ALL_LOCATIONS
};
const productListReducer = createReducer(inventoryListInitialState.productList, {
  ...createListReducers(ListType.Inventory),
  ...handleSimpleReducerUpdates([InventoryListActionTypes.ESTIMATE_PRODUCT_LIST_COSTS_LOADING, InventoryListActionTypes.ESTIMATE_PRODUCT_LIST_COSTS, InventoryListActionTypes.CALCULATE_MULTI_PACK_STORAGE_INVENTORY_LOADING, InventoryListActionTypes.CALCULATE_MULTI_PACK_STORAGE_INVENTORY, WalmartSyncActionTypes.SHOW_WALMART_SYNC, WalmartSyncActionTypes.SET_WALMART_SYNC_IS_ACTIVE]),
  [CostEstimatorModalActionTypes.ESTIMATE_COSTS_LISTING]: (state, {
    dsku,
    dimensions,
    estimatedCosts
  }) => ({
    ...state,
    listItems: state.listItems.map(item => item.dsku === dsku ? {
      ...item,
      ...dimensions,
      estimatedCosts
    } : item)
  })
});
const bundleListReducer = createReducer(inventoryListInitialState.bundleList, {
  ...createListReducers(ListType.Bundle),
  ...handleSimpleReducerUpdates([InventoryListActionTypes.SET_BUNDLE_LIST_AVAILABLE_QTY, InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS, InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS_LOADING, InventoryListActionTypes.ESTIMATE_BUNDLE_LIST_COSTS_COMPLETED])
});
const listsReducer = combineReducers({
  productList: productListReducer,
  bundleList: bundleListReducer
});
const sharedReducer = createReducer(inventoryListInitialState, {
  ...handleSimpleReducerUpdates([InventoryListActionTypes.SET_ESTIMATED_COSTS_SERVICE_LEVEL, InventoryListActionTypes.SET_INVENTORY_LIST_COUNTRY_CODE, InventoryListActionTypes.SET_IS_ACTIVE, InventoryListActionTypes.PRODUCT_SYNC, InventoryListActionTypes.SET_FILTERS_BLADE_OPEN, InventoryListActionTypes.SET_TAB, InventoryListActionTypes.SET_SEARCH_TERM, InventoryListActionTypes.SET_INVENTORY_LOCATION_FILTER])
});
export const inventoryListReducer = (state = inventoryListInitialState, action) => {
  const intermediateState = {
    ...state,
    ...listsReducer({
      productList: state.productList,
      bundleList: state.bundleList
    }, action)
  };
  return sharedReducer(intermediateState, action);
};