export let Space;

/**
 * S1 = "0.25rem"
 *
 * S2 = "0.5rem"
 *
 * S3 = "0.75rem"
 *
 * S4 = "1rem"
 *
 * S5 = "1.5rem"
 *
 * S6 = "2rem"
 *
 * S7 = "2.5rem"
 *
 * S8 = "3rem"
 *
 * S9 = "4rem"
 */
(function (Space) {
  Space["S1"] = "S1";
  Space["S2"] = "S2";
  Space["S3"] = "S3";
  Space["S4"] = "S4";
  Space["S5"] = "S5";
  Space["S6"] = "S6";
  Space["S7"] = "S7";
  Space["S8"] = "S8";
  Space["S9"] = "S9";
})(Space || (Space = {}));
export const spacing = {
  [Space.S1]: "0.25rem",
  [Space.S2]: "0.5rem",
  [Space.S3]: "0.75rem",
  [Space.S4]: "1rem",
  [Space.S5]: "1.5rem",
  [Space.S6]: "2rem",
  [Space.S7]: "2.5rem",
  [Space.S8]: "3rem",
  [Space.S9]: "4rem"
};