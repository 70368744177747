export let InboundShipmentActionTypes;
(function (InboundShipmentActionTypes) {
  InboundShipmentActionTypes["SET_BOX_ARRANGEMENT"] = "SET_BOX_ARRANGEMENT";
  InboundShipmentActionTypes["ATTEMPT_CONFIRM_BOXES"] = "ATTEMPT_CONFIRM_BOXES";
  InboundShipmentActionTypes["CONFIRM_BOXES"] = "CONFIRM_BOXES";
  InboundShipmentActionTypes["ROLLBACK_CONFIRM_BOXES"] = "ROLLBACK_CONFIRM_BOXES";
  InboundShipmentActionTypes["SAVE_PACKAGES_SUCCESS"] = "SAVE_PACKAGES_SUCCESS";
  InboundShipmentActionTypes["COST_ESTIMATE_COMPLETE"] = "COST_ESTIMATE_COMPLETE";
  InboundShipmentActionTypes["COST_ESTIMATE_ERROR"] = "COST_ESTIMATE_ERROR";
  InboundShipmentActionTypes["LOAD_ASNS"] = "LOAD_ASNS";
  InboundShipmentActionTypes["SET_PACKAGES_FOR_SHIPMENT"] = "SET_PACKAGES_FOR_SHIPMENT";
  InboundShipmentActionTypes["UPDATE_SHIPMENT"] = "UPDATE_SHIPMENT";
  InboundShipmentActionTypes["UPDATE_SHIPMENT_ID"] = "UPDATE_SHIPMENT_ID";
  InboundShipmentActionTypes["CLEAR_RATES"] = "CLEAR_RATES";
  InboundShipmentActionTypes["REPLACE_SHIPMENT"] = "REPLACE_SHIPMENT";
  InboundShipmentActionTypes["SET_PALLET_COMPLIANCE"] = "SET_PALLET_COMPLIANCE";
  InboundShipmentActionTypes["CONFIRM_PALLETS"] = "CONFIRM_PALLETS";
  InboundShipmentActionTypes["ATTEMPT_SAVE_BOX_CONFIGURATIONS"] = "ATTEMPT_SAVE_BOX_CONFIGURATIONS";
  InboundShipmentActionTypes["SAVE_BOX_CONFIGURATIONS"] = "SAVE_BOX_CONFIGURATIONS";
  InboundShipmentActionTypes["ROLLBACK_SAVE_BOX_CONFIGURATIONS"] = "ROLLBACK_SAVE_BOX_CONFIGURATIONS";
})(InboundShipmentActionTypes || (InboundShipmentActionTypes = {}));