export let CurrencyFormat;
(function (CurrencyFormat) {
  CurrencyFormat["AED"] = "AED";
  CurrencyFormat["AFN"] = "AFN";
  CurrencyFormat["ALL"] = "ALL";
  CurrencyFormat["AMD"] = "AMD";
  CurrencyFormat["ANG"] = "ANG";
  CurrencyFormat["AOA"] = "AOA";
  CurrencyFormat["ARS"] = "ARS";
  CurrencyFormat["AUD"] = "AUD";
  CurrencyFormat["AWG"] = "AWG";
  CurrencyFormat["AZN"] = "AZN";
  CurrencyFormat["BAM"] = "BAM";
  CurrencyFormat["BBD"] = "BBD";
  CurrencyFormat["BDT"] = "BDT";
  CurrencyFormat["BGN"] = "BGN";
  CurrencyFormat["BHD"] = "BHD";
  CurrencyFormat["BIF"] = "BIF";
  CurrencyFormat["BMD"] = "BMD";
  CurrencyFormat["BND"] = "BND";
  CurrencyFormat["BOB"] = "BOB";
  CurrencyFormat["BOV"] = "BOV";
  CurrencyFormat["BRL"] = "BRL";
  CurrencyFormat["BSD"] = "BSD";
  CurrencyFormat["BTN"] = "BTN";
  CurrencyFormat["BWP"] = "BWP";
  CurrencyFormat["BYN"] = "BYN";
  CurrencyFormat["BZD"] = "BZD";
  CurrencyFormat["CAD"] = "CAD";
  CurrencyFormat["CDF"] = "CDF";
  CurrencyFormat["CHE"] = "CHE";
  CurrencyFormat["CHF"] = "CHF";
  CurrencyFormat["CHW"] = "CHW";
  CurrencyFormat["CLF"] = "CLF";
  CurrencyFormat["CLP"] = "CLP";
  CurrencyFormat["CNH"] = "CNH";
  CurrencyFormat["CNY"] = "CNY";
  CurrencyFormat["COP"] = "COP";
  CurrencyFormat["COU"] = "COU";
  CurrencyFormat["CRC"] = "CRC";
  CurrencyFormat["CUC"] = "CUC";
  CurrencyFormat["CUP"] = "CUP";
  CurrencyFormat["CVE"] = "CVE";
  CurrencyFormat["CZK"] = "CZK";
  CurrencyFormat["DJF"] = "DJF";
  CurrencyFormat["DKK"] = "DKK";
  CurrencyFormat["DOP"] = "DOP";
  CurrencyFormat["DZD"] = "DZD";
  CurrencyFormat["EGP"] = "EGP";
  CurrencyFormat["ERN"] = "ERN";
  CurrencyFormat["ETB"] = "ETB";
  CurrencyFormat["EUR"] = "EUR";
  CurrencyFormat["FJD"] = "FJD";
  CurrencyFormat["FKP"] = "FKP";
  CurrencyFormat["GBP"] = "GBP";
  CurrencyFormat["GEL"] = "GEL";
  CurrencyFormat["GHS"] = "GHS";
  CurrencyFormat["GIP"] = "GIP";
  CurrencyFormat["GMD"] = "GMD";
  CurrencyFormat["GNF"] = "GNF";
  CurrencyFormat["GTQ"] = "GTQ";
  CurrencyFormat["GYD"] = "GYD";
  CurrencyFormat["HKD"] = "HKD";
  CurrencyFormat["HNL"] = "HNL";
  CurrencyFormat["HRK"] = "HRK";
  CurrencyFormat["HTG"] = "HTG";
  CurrencyFormat["HUF"] = "HUF";
  CurrencyFormat["IDR"] = "IDR";
  CurrencyFormat["ILS"] = "ILS";
  CurrencyFormat["INR"] = "INR";
  CurrencyFormat["IQD"] = "IQD";
  CurrencyFormat["IRR"] = "IRR";
  CurrencyFormat["ISK"] = "ISK";
  CurrencyFormat["JMD"] = "JMD";
  CurrencyFormat["JOD"] = "JOD";
  CurrencyFormat["JPY"] = "JPY";
  CurrencyFormat["KES"] = "KES";
  CurrencyFormat["KGS"] = "KGS";
  CurrencyFormat["KHR"] = "KHR";
  CurrencyFormat["KMF"] = "KMF";
  CurrencyFormat["KPW"] = "KPW";
  CurrencyFormat["KRW"] = "KRW";
  CurrencyFormat["KWD"] = "KWD";
  CurrencyFormat["KYD"] = "KYD";
  CurrencyFormat["KZT"] = "KZT";
  CurrencyFormat["LAK"] = "LAK";
  CurrencyFormat["LBP"] = "LBP";
  CurrencyFormat["LKR"] = "LKR";
  CurrencyFormat["LRD"] = "LRD";
  CurrencyFormat["LSL"] = "LSL";
  CurrencyFormat["LYD"] = "LYD";
  CurrencyFormat["MAD"] = "MAD";
  CurrencyFormat["MDL"] = "MDL";
  CurrencyFormat["MGA"] = "MGA";
  CurrencyFormat["MKD"] = "MKD";
  CurrencyFormat["MMK"] = "MMK";
  CurrencyFormat["MNT"] = "MNT";
  CurrencyFormat["MOP"] = "MOP";
  CurrencyFormat["MRU"] = "MRU";
  CurrencyFormat["MUR"] = "MUR";
  CurrencyFormat["MVR"] = "MVR";
  CurrencyFormat["MWK"] = "MWK";
  CurrencyFormat["MXN"] = "MXN";
  CurrencyFormat["MXV"] = "MXV";
  CurrencyFormat["MYR"] = "MYR";
  CurrencyFormat["MZN"] = "MZN";
  CurrencyFormat["NAD"] = "NAD";
  CurrencyFormat["NGN"] = "NGN";
  CurrencyFormat["NIO"] = "NIO";
  CurrencyFormat["NOK"] = "NOK";
  CurrencyFormat["NPR"] = "NPR";
  CurrencyFormat["NZD"] = "NZD";
  CurrencyFormat["OMR"] = "OMR";
  CurrencyFormat["PAB"] = "PAB";
  CurrencyFormat["PEN"] = "PEN";
  CurrencyFormat["PGK"] = "PGK";
  CurrencyFormat["PHP"] = "PHP";
  CurrencyFormat["PKR"] = "PKR";
  CurrencyFormat["PLN"] = "PLN";
  CurrencyFormat["PYG"] = "PYG";
  CurrencyFormat["QAR"] = "QAR";
  CurrencyFormat["RON"] = "RON";
  CurrencyFormat["RSD"] = "RSD";
  CurrencyFormat["RUB"] = "RUB";
  CurrencyFormat["RWF"] = "RWF";
  CurrencyFormat["SAR"] = "SAR";
  CurrencyFormat["SBD"] = "SBD";
  CurrencyFormat["SCR"] = "SCR";
  CurrencyFormat["SDG"] = "SDG";
  CurrencyFormat["SEK"] = "SEK";
  CurrencyFormat["SGD"] = "SGD";
  CurrencyFormat["SHP"] = "SHP";
  CurrencyFormat["SLL"] = "SLL";
  CurrencyFormat["SOS"] = "SOS";
  CurrencyFormat["SRD"] = "SRD";
  CurrencyFormat["SSP"] = "SSP";
  CurrencyFormat["STN"] = "STN";
  CurrencyFormat["SVC"] = "SVC";
  CurrencyFormat["SYP"] = "SYP";
  CurrencyFormat["SZL"] = "SZL";
  CurrencyFormat["THB"] = "THB";
  CurrencyFormat["TJS"] = "TJS";
  CurrencyFormat["TMT"] = "TMT";
  CurrencyFormat["TND"] = "TND";
  CurrencyFormat["TOP"] = "TOP";
  CurrencyFormat["TRY"] = "TRY";
  CurrencyFormat["TTD"] = "TTD";
  CurrencyFormat["TWD"] = "TWD";
  CurrencyFormat["TZS"] = "TZS";
  CurrencyFormat["UAH"] = "UAH";
  CurrencyFormat["UGX"] = "UGX";
  CurrencyFormat["USD"] = "USD";
  CurrencyFormat["USN"] = "USN";
  CurrencyFormat["UYI"] = "UYI";
  CurrencyFormat["UYU"] = "UYU";
  CurrencyFormat["UYW"] = "UYW";
  CurrencyFormat["UZS"] = "UZS";
  CurrencyFormat["VED"] = "VED";
  CurrencyFormat["VES"] = "VES";
  CurrencyFormat["VND"] = "VND";
  CurrencyFormat["VUV"] = "VUV";
  CurrencyFormat["WST"] = "WST";
  CurrencyFormat["XAF"] = "XAF";
  CurrencyFormat["XAG"] = "XAG";
  CurrencyFormat["XAU"] = "XAU";
  CurrencyFormat["XBA"] = "XBA";
  CurrencyFormat["XBB"] = "XBB";
  CurrencyFormat["XBC"] = "XBC";
  CurrencyFormat["XBD"] = "XBD";
  CurrencyFormat["XCD"] = "XCD";
  CurrencyFormat["XDR"] = "XDR";
  CurrencyFormat["XOF"] = "XOF";
  CurrencyFormat["XPD"] = "XPD";
  CurrencyFormat["XPF"] = "XPF";
  CurrencyFormat["XPT"] = "XPT";
  CurrencyFormat["XSU"] = "XSU";
  CurrencyFormat["XTS"] = "XTS";
  CurrencyFormat["XUA"] = "XUA";
  CurrencyFormat["XXX"] = "XXX";
  CurrencyFormat["YER"] = "YER";
  CurrencyFormat["ZAR"] = "ZAR";
  CurrencyFormat["ZMW"] = "ZMW";
  CurrencyFormat["ZWL"] = "ZWL";
})(CurrencyFormat || (CurrencyFormat = {}));
export const DEFAULT_CURRENCY = CurrencyFormat.USD;