import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export class BillingClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.BILLING_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getPrimaryPaymentSource(sellerId) {
    return await this.apiClient.get({
      url: `/invoice/v1/payment/sources/primary/${sellerId}`,
      authentication: isAuthenticated
    });
  }
  async createInternalCredit(sellerIds, credit) {
    return await this.apiClient.post({
      url: "/v2/credits/internal",
      body: {
        sellerIds,
        credit
      },
      authentication: isAuthenticated
    });
  }
}