import { getNumberOrZeroFromString } from "common/utils/string/getNumberOrZeroFromString";
import { removeAllEmTags } from "common/StringUtils";
import { reduce } from "lodash";
export const aggregatePackInventory = (product, pack) => {
  const inventoryKeys = ["storageOnHandQty", "storagePlannedQty", "storageNonPickableQty", "storageUnfilledOrderQty", "storageInTransferQty", "storageUnavailableQty", "storageInboundUnits"];
  return removeAllEmTags(pack.packOf) === removeAllEmTags(product.dsku) && pack.isDefaultPack ? reduce(inventoryKeys, (agg, key) => {
    agg[key] = getNumberOrZeroFromString(product[key]?.toString()) + getNumberOrZeroFromString(pack[key]?.toString());
    return agg;
  }, {}) : reduce(inventoryKeys, (agg, key) => {
    agg[key] = getNumberOrZeroFromString(pack[key]?.toString());
    return agg;
  }, {});
};