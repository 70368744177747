import { isNil } from "lodash/fp";
import { matchPath } from "react-router-dom";
import history from "BrowserHistory";

// Whereas in useCurrentStep we subscribe to history with useHistory, we import history here to get a precise read whenever
// at the moment this function is called

// <T extends Enum> does not exist in Typescript,
// so we make do with <T extends string>
export const getCurrentStep = (flowStepType, stepToPathMap) => {
  const flowSteps = Object.values(flowStepType);
  const currentStep = flowSteps.find(
  // look up the step that matches the current path
  step => !isNil(matchPath(history.location.pathname, {
    path: stepToPathMap[step]
  })));
  return currentStep;
};