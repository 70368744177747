import { createSelector } from "reselect";
import { getStorageInboundCreate } from "./getStorageInboundCreate";
import { ShipmentType } from "@deliverr/storage-client";
export const getNumOfPallets = createSelector(getStorageInboundCreate, storageInboundCreate => {
  const {
    cargoType,
    numOfPallets,
    estimatedNumOfPallets
  } = storageInboundCreate;
  return (cargoType === ShipmentType.PALLETIZED ? numOfPallets : estimatedNumOfPallets) ?? 1;
});