import { ceilDecimal } from "common/utils/number";
export const calculateIntermediateValues = quote => {
  if (!quote) {
    return;
  }
  const {
    totalDiscount,
    itemQty,
    amountCrossdock,
    amountDirect,
    totalCost
  } = quote;
  const undiscountedTotal = totalCost + totalDiscount;
  const undiscountedUnitCost = ceilDecimal(undiscountedTotal / itemQty, 3);
  const totalWithEstimatedShipping = totalCost + amountCrossdock;
  const crossdockSavings = amountDirect - totalWithEstimatedShipping;
  return {
    undiscountedTotal,
    undiscountedUnitCost,
    totalWithEstimatedShipping,
    crossdockSavings
  };
};