import { productClient } from "Clients";
import { notifyUserOfError } from "common/ErrorToast";
import { createActionCreator } from "common/ReduxUtils";
import log from "Logger";
import { DimensionSource } from "common/utils/product/isDimsConfirmed";
import { getEstimatedCosts } from "common/ProductUtils";
export let CostEstimatorModalActionTypes;
(function (CostEstimatorModalActionTypes) {
  CostEstimatorModalActionTypes["SET_COST_ESTIMATOR_PRODUCT"] = "SET_COST_ESTIMATOR_PRODUCT";
  CostEstimatorModalActionTypes["ESTIMATE_COSTS_LISTING"] = "ESTIMATE_COSTS_LISTING";
  CostEstimatorModalActionTypes["ESTIMATE_COSTS_DETAIL"] = "ESTIMATE_COSTS_DETAIL";
  CostEstimatorModalActionTypes["SET_FIELD_VALUE"] = "SET_FIELD_VALUE";
  CostEstimatorModalActionTypes["SET_SHOW_NEW_PRICING"] = "SET_SHOW_NEW_PRICING";
})(CostEstimatorModalActionTypes || (CostEstimatorModalActionTypes = {}));
export const setCostEstimatorProduct = (dsku, productName, dimensions = {}, hazmatInfo, shippingSpec, serviceLevel, countryCode) => ({
  type: CostEstimatorModalActionTypes.SET_COST_ESTIMATOR_PRODUCT,
  dsku,
  productName,
  dimensions,
  hazmatInfo,
  shippingSpec,
  serviceLevel,
  countryCode
});

/* When all values are entered in the modal, generate estimates for all service levels */
export const estimateCosts = type => async (dispatch, getState) => {
  const {
    user: {
      sellerId
    },
    costEstimatorModal: {
      dsku,
      dimensions,
      showNewPricing,
      hazmatInfo,
      shippingSpec,
      serviceLevel,
      countryCode
    }
  } = getState();
  const ctx = {
    fn: "estimateCosts",
    dsku,
    dimensions,
    hazmatInfo,
    shippingSpec
  };
  log.info(ctx, "estimating costs");
  if (!dsku) {
    throw new Error(`Attempt to estimate costs with no product specified in modal`);
  }
  try {
    await productClient.update({
      dsku,
      ...dimensions,
      dimSourceType: DimensionSource.SELLER,
      dimSource: "sellerPopUpFlow"
    });
    dispatch({
      type: type === "DETAIL" ? CostEstimatorModalActionTypes.ESTIMATE_COSTS_DETAIL : CostEstimatorModalActionTypes.ESTIMATE_COSTS_LISTING,
      dsku,
      dimensions,
      estimatedCosts: await getEstimatedCosts({
        items: [{
          dsku,
          ...dimensions,
          hazmatIds: hazmatInfo,
          minimumPackagingType: shippingSpec
        }],
        showNewPrices: showNewPricing,
        serviceLevel,
        countryCode,
        sellerId
      })
    });
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error updating product dims");
    notifyUserOfError({
      err,
      explanation: "Oops. There was an issue updating your product dimensions. Please try again later.",
      toastId: "updateProductDimsError"
    });
  }
};
export const setFieldValue = createActionCreator(CostEstimatorModalActionTypes.SET_FIELD_VALUE, "field", "value");
export const setShowNewPricing = createActionCreator(CostEstimatorModalActionTypes.SET_SHOW_NEW_PRICING, "showNewPricing");