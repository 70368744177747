import { ServiceLevel } from "common/clients/pricer/ServiceLevel";
export let RemovalMethods;
(function (RemovalMethods) {
  RemovalMethods["REMOVAL"] = "Removal";
  RemovalMethods["DISPOSAL"] = "Disposal";
})(RemovalMethods || (RemovalMethods = {}));
export const shippingMethodsMap = {
  [ServiceLevel.StandardNonFastTag]: {
    name: ServiceLevel.StandardNonFastTag,
    requiredDeliveryInDays: 7,
    translationKey: "standard",
    defaultTranslation: "Standard"
  },
  [ServiceLevel.ThreeDayNonFastTag]: {
    name: ServiceLevel.ThreeDayNonFastTag,
    requiredDeliveryInDays: 3,
    translationKey: "threeDay",
    defaultTranslation: "3 day"
  },
  [ServiceLevel.TwoDayNonFastTag]: {
    name: ServiceLevel.TwoDayNonFastTag,
    requiredDeliveryInDays: 2,
    translationKey: "twoDay",
    defaultTranslation: "2 day"
  },
  [ServiceLevel.Standard]: {
    name: ServiceLevel.Standard,
    requiredDeliveryInDays: 7,
    translationKey: "standard",
    defaultTranslation: "Standard"
  },
  [ServiceLevel.PriorityThreeDay]: {
    name: ServiceLevel.PriorityThreeDay,
    requiredDeliveryInDays: 3,
    translationKey: "priorityThreeDay",
    defaultTranslation: "Expedited 3 day"
  },
  [ServiceLevel.PriorityTwoDay]: {
    name: ServiceLevel.PriorityTwoDay,
    requiredDeliveryInDays: 2,
    translationKey: "priorityTwoDay",
    defaultTranslation: "Expedited 2 day"
  },
  [ServiceLevel.AKHIOtherNonFastTag]: {
    name: ServiceLevel.AKHIOtherNonFastTag,
    requiredDeliveryInDays: 7,
    translationKey: "akHiTerritories",
    defaultTranslation: "AK/HI/US Territories"
  },
  [ServiceLevel.WorldwideStandardDeliveredDutyUnpaid]: {
    name: ServiceLevel.WorldwideStandardDeliveredDutyUnpaid,
    requiredDeliveryInDays: 15,
    translationKey: "worldwideStandardDDU",
    defaultTranslation: "Worldwide Standard Delivered Duty Unpaid"
  },
  [ServiceLevel.Military]: {
    name: ServiceLevel.Military,
    requiredDeliveryInDays: 15,
    translationKey: "military",
    defaultTranslation: "US Military"
  },
  [RemovalMethods.REMOVAL]: {
    name: RemovalMethods.REMOVAL,
    requiredDeliveryInDays: 21,
    translationKey: "removal",
    defaultTranslation: "Ship-to-address Removal"
  }
};
export const legacyContiguousUSShippingMethods = [shippingMethodsMap[ServiceLevel.StandardNonFastTag], shippingMethodsMap[ServiceLevel.ThreeDayNonFastTag], shippingMethodsMap[ServiceLevel.TwoDayNonFastTag]];
export const contiguousUSShippingMethods = [shippingMethodsMap[ServiceLevel.Standard], shippingMethodsMap[ServiceLevel.PriorityThreeDay], shippingMethodsMap[ServiceLevel.PriorityTwoDay]];
export const removalMethods = [shippingMethodsMap[RemovalMethods.REMOVAL]];