export let BorderWidth;
(function (BorderWidth) {
  BorderWidth["B0"] = "B0";
  BorderWidth["B1"] = "B1";
  BorderWidth["B2"] = "B2";
})(BorderWidth || (BorderWidth = {}));
export let BorderRadius;
(function (BorderRadius) {
  BorderRadius["R0"] = "R0";
  BorderRadius["R2"] = "R2";
  BorderRadius["R3"] = "R3";
  BorderRadius["R4"] = "R4";
  BorderRadius["R5"] = "R5";
  BorderRadius["R6"] = "R6";
})(BorderRadius || (BorderRadius = {}));
const WIDTH = {
  [BorderWidth.B0]: "0",
  [BorderWidth.B1]: "1px",
  [BorderWidth.B2]: "2px"
};
const RADIUS = {
  [BorderRadius.R0]: "0",
  [BorderRadius.R2]: "2px",
  [BorderRadius.R3]: "3px",
  [BorderRadius.R4]: "4px",
  [BorderRadius.R5]: "8px",
  [BorderRadius.R6]: "12px"
};
export const border = {
  /**
   * @constant `B1` 1px
   * @constant `B2` 2px
   */
  width: WIDTH,
  type: "solid",
  /**
   * @constant `R2` 2px
   * @constant `R3` 3px
   * @constant `R4` 4px
   * @constant `R5` 8px
   * @constant `R6` 12px
   */
  radius: RADIUS
};