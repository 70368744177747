import log from "Logger";
import history from "BrowserHistory";
import { selectCreateShipmentMeta } from "../store/selectors";
import { getNewStack, supplyStepPathParams } from "./utils";
import { createShipmentInitialStep, createShipmentStepToPath } from "./createShipmentStepToPath";
import { setStepStack } from "../store/actions";
export const goToCreateShipmentStep = ({
  shouldUseLastStep = false,
  ...options
} = {}) => (dispatch, getState) => {
  const state = getState();
  const meta = selectCreateShipmentMeta(state);
  const shippingPlanId = options.shippingPlanId ?? meta.shippingPlanId;
  const {
    stepStack
  } = meta;
  const lastStep = stepStack[stepStack.length - 1];

  // set target to provided step, last step or initial step
  const fallbackStep = shouldUseLastStep ? lastStep : createShipmentInitialStep;
  const step = options.step ?? fallbackStep;
  log.info({
    fn: "goToCreateShipmentStep",
    step,
    shippingPlanId
  }, `sending to ${step}`);
  dispatch(setStepStack(getNewStack(stepStack, step)));
  const stepPath = createShipmentStepToPath[step];
  const destinationPath = supplyStepPathParams(stepPath, shippingPlanId);

  // shouldSkipRedirect bypasses the route replacement used when first mounting the create shipment flow
  if (options.replace) {
    history.replace(destinationPath, {
      shouldSkipRedirect: shouldUseLastStep
    });
  } else {
    history.push(destinationPath, {
      shouldSkipRedirect: shouldUseLastStep
    });
  }
};