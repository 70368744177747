import { keyBy } from "lodash/fp";
import { DeliverrActionTypes } from "common/deliverr/DeliverrActions";
import { createReducer } from "common/ReduxUtils";
export const deliverrInitialState = {
  warehouses: {}
};
const reducers = {
  [DeliverrActionTypes.LOAD_WAREHOUSES]: (state, {
    warehouses
  }) => ({
    ...state,
    warehouses: {
      ...state.warehouses,
      ...keyBy("id", warehouses)
    }
  })
};
export const deliverrReducer = createReducer(deliverrInitialState, reducers);