import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import produce from "immer";
import { PrepListActionTypes } from "prep/types";
export const prepListInitialState = {
  currentSearchTerm: "",
  currentPageItems: []
};
export const reducers = {
  ...handleSimpleReducerUpdates([PrepListActionTypes.SEARCH_PREP_SUCCESS, PrepListActionTypes.SEARCH_PREP_ERROR, PrepListActionTypes.SET_CURRENT_PREP_SEARCH_TERM]),
  [PrepListActionTypes.SET_CURRENT_PREP_PAGE]: (state, {
    page
  }) => produce(state, draft => {
    if (draft.searchInfo) {
      draft.searchInfo.currentPage = page;
    }
  })
};
export const prepListReducer = createReducer(prepListInitialState, reducers);