import { defineMessages } from "react-intl";
export const MismatchCaseQtyBladeMessages = defineMessages({
  STATUS: {
    id: "inbounds.mismatchCaseQtyBlade.status",
    defaultMessage: "Units received"
  },
  STATUS_MESSAGE: {
    id: "inbounds.mismatchCaseQtyBlade.statusMessage",
    defaultMessage: "Flexport received boxes with an unexpected packing configuration in your shipping plan."
  },
  DETAILS_TITLE: {
    id: "inbounds.mismatchCaseQtyBlade.detailsTitle",
    defaultMessage: "Details"
  },
  PACKING_CONFIGURATION_RECEIVED: {
    id: "inbounds.mismatchCaseQtyBlade.received",
    defaultMessage: "Pack configuration received"
  },
  PACKING_CONFIGURATION_EXPECTED: {
    id: "inbounds.mismatchCaseQtyBlade.expected",
    defaultMessage: "Expected"
  },
  NONCOMPLIANT_PRODUCT: {
    id: "inbounds.mismatchCaseQtyBlade.nonCompliantProduct",
    defaultMessage: "Product"
  },
  CONTACT_SUPPORT: {
    id: "inbounds.mismatchCaseQtyBlade.contactSupport",
    defaultMessage: "Not the right SKU? {contactSupport}"
  },
  CONTACT_SUPPORT_LINK: {
    id: "inbounds.mismatchCaseQtyBlade.contactSupportLink",
    defaultMessage: "Contact support"
  },
  LEARN_MORE: {
    id: "inbounds.mismatchCaseQtyBlade.learnMore",
    defaultMessage: "Learn more about {unexpectedBarcodes}."
  },
  MISMATCH_CASE_QTY_LINK: {
    id: "inbounds.mismatchCaseQtyBlade.mismatchCaseQtyLink",
    defaultMessage: "unexpected packing configurations"
  },
  QUANTITY_RECEIVED: {
    id: "inbounds.mismatchCaseQtyBlade.quantityReceived",
    defaultMessage: "Quantity received"
  },
  QUANTITY_RECEIVED_WITH_UNITS: {
    id: "inbounds.mismatchCaseQtyBlade.quantityReceivedWithUnits",
    defaultMessage: "{quantityReceived} {unitCount} {units}"
  },
  CASE_PACK_CONFIGURATION: {
    id: "inbounds.mismatchCaseQtyBlade.casePackConfiguration",
    defaultMessage: "{height} x {width} x {length} in · {weight} lbs"
  },
  CASE_PACK_CONFIGURATION_WITH_UNITS: {
    id: "inbounds.mismatchCaseQtyBlade.casePackConfigurationWithUnits",
    defaultMessage: "{unitCount} {units} per box {configuration}"
  }
});