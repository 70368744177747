export let LocationType;
(function (LocationType) {
  LocationType["COMMERCIAL"] = "COMMERCIAL";
  LocationType["RESIDENTIAL"] = "RESIDENTIAL";
})(LocationType || (LocationType = {}));
export let LimitedAccessLocation;
(function (LimitedAccessLocation) {
  LimitedAccessLocation["NONE"] = "NONE";
  LimitedAccessLocation["AIRPORT"] = "AIRPORT";
  LimitedAccessLocation["MILITARY_BASE"] = "MILITARY_BASE";
})(LimitedAccessLocation || (LimitedAccessLocation = {}));
export let AddressInputNames;
(function (AddressInputNames) {
  AddressInputNames["LOCATION_TYPE"] = "LOCATION_TYPE";
  AddressInputNames["LIMITED_ACCESS_LOCATION"] = "LIMITED_ACCESS_LOCATION";
  AddressInputNames["CONTACT_FIRST_NAME"] = "CONTACT_FIRST_NAME";
  AddressInputNames["CONTACT_LAST_NAME"] = "CONTACT_LAST_NAME";
  AddressInputNames["CONTACT_EMAIL"] = "CONTACT_EMAIL";
  AddressInputNames["CONTACT_PHONE"] = "CONTACT_PHONE";
  AddressInputNames["CONTACT_PHONE_EXT"] = "CONTACT_PHONE_EXT";
  AddressInputNames["COMPANY_NAME"] = "COMPANY_NAME";
})(AddressInputNames || (AddressInputNames = {}));