import produce from "immer";
import { getBearerToken } from "./getBearerToken";
export const withAuthentication = ({
  authentication
}) => async config => {
  if (authentication) {
    const bearerToken = await getBearerToken();
    return produce(config, draft => {
      if (draft.headers === undefined) {
        draft.headers = {};
      }
      draft.headers.Authorization = `Bearer ${bearerToken}`;
    });
  }
  return config;
};