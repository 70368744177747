import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ParcelDetailActionTypes } from "parcel/screens/details/ParcelDetailsActionTypes";
import { getDefaultDeliverrAddress, getDefaultParcelDimensions } from "parcel/screens/details/helpers";
import produce from "immer";
export const parcelDetailInitialState = {
  parcelId: 0,
  destination: getDefaultDeliverrAddress(),
  shippingMethod: "",
  dimensions: getDefaultParcelDimensions(),
  late: false,
  trackingCode: "",
  serviceLevel: "",
  isLoading: false
};
const reducers = {
  ...handleSimpleReducerUpdates([ParcelDetailActionTypes.GET_PARCEL_DETAILS_SUCCESS]),
  [ParcelDetailActionTypes.GET_PARCEL_DETAILS]: state => produce(state, draft => {
    draft.isLoading = true;
  }),
  [ParcelDetailActionTypes.UPDATE_PARCEL_DETAILS_LOADING_STATE]: (state, {
    isLoading
  }) => produce(state, draft => {
    draft.isLoading = isLoading;
  })
};
export const parcelDetailsReducer = createReducer(parcelDetailInitialState, reducers);