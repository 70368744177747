import { crossdockClient } from "common/clients/instances";
import { setCrossdockInboundQuote } from ".";
import log from "Logger";
export const fetchCrossdockInboundQuote = (sellerId, shippingPlanId) => async dispatch => {
  const ctx = {
    fn: "fetchCrossdockInboundQuote",
    sellerId,
    shippingPlanId
  };
  log.info(ctx, "start");
  try {
    const quote = await crossdockClient.getActiveInboundQuoteByShippingPlan(sellerId, shippingPlanId);
    if (quote) {
      dispatch(setCrossdockInboundQuote(quote));
    }
    return quote;
  } catch (err) {
    log.info(ctx, "No quote found.");
    return;
  }
};