import { createActionCreator } from "common/ReduxUtils";
export let ErrorsActionTypes;
(function (ErrorsActionTypes) {
  ErrorsActionTypes["SET_ERROR"] = "ERRORS_SET_ERROR";
  ErrorsActionTypes["CLEAR_ERROR"] = "ERRORS_CLEAR_ERROR";
})(ErrorsActionTypes || (ErrorsActionTypes = {}));
export let BusinessErrorCode;
(function (BusinessErrorCode) {
  BusinessErrorCode["LABEL_UPLOAD_DUPLICATE_ERROR"] = "LABEL_UPLOAD_DUPLICATE_ERROR";
})(BusinessErrorCode || (BusinessErrorCode = {}));
export const clearGlobalError = createActionCreator(ErrorsActionTypes.CLEAR_ERROR, "code");
export const setGlobalError = createActionCreator(ErrorsActionTypes.SET_ERROR, "code", "data");