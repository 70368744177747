import { PrepType } from "@deliverr/prep-service-client";
import { selectHasAllSpecifiedPrepTypes } from "inbounds/store/selectors/boxLabels/selectHasAllSpecifiedPrepTypes";
import { selectLoadedShipmentHasConfirmedBoxingChoice } from "inbounds/store/selectors/shipments/selectLoadedShipmentHasConfirmedBoxingChoice";
import { createSelector } from "reselect";

/**
 * Determine if the loaded shipment has a status after which Prep could've been confirmed and that it includes BCL Prep
 */
export const selectHasConfirmedPrepBcl = createSelector(selectLoadedShipmentHasConfirmedBoxingChoice, selectHasAllSpecifiedPrepTypes, (hasPackageDetails, checkAllPrepTypes) => {
  return hasPackageDetails && checkAllPrepTypes(PrepType.BOX_CONTENT_LABELS);
});