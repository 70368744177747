import { useRenderMoneyCell } from "common/components/BasicTable";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getRenderMoneyPerUnitCellOptions } from "storage/inbounds/utils/getRenderMoneyPerUnitCellOptions";
export const usePricingSummaryColumns = (tableTitle, cargoType) => {
  const {
    formatMessage
  } = useIntl();
  const perPalletLabel = formatMessage({
    id: "storage.inbounds.create.confirmShipment.pricingSummaryTable.perPallet",
    defaultMessage: "/pallet"
  });
  const renderMoneyCell = useRenderMoneyCell();
  const renderMoneyPerUnitCell = useRenderMoneyCell(getRenderMoneyPerUnitCellOptions(cargoType, perPalletLabel));
  return useMemo(() => [{
    Header: tableTitle,
    accessor: "rowLabel"
  }, {
    Header: formatMessage({
      id: "storage.inbounds.create.confirmShipment.pricingSummaryTable.quantity",
      defaultMessage: "Quantity"
    }),
    accessor: "quantity"
  }, {
    Header: formatMessage({
      id: "storage.inbounds.create.confirmShipment.pricingSummaryTable.unitCost",
      defaultMessage: "Cost"
    }),
    accessor: "unitCost",
    Cell: renderMoneyPerUnitCell
  }, {
    Header: formatMessage({
      id: "storage.inbounds.create.confirmShipment.pricingSummaryTable.amount",
      defaultMessage: "Amount"
    }),
    accessor: "amount",
    Cell: renderMoneyCell
  }], [formatMessage, renderMoneyCell, renderMoneyPerUnitCell]);
};