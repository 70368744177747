import { addLocalTimeZoneOffset } from "common/date/addLocalTimeZoneOffset";
export const _parseTargetDeliveryDate = booking => {
  const {
    targetDeliveryDate,
    flexibleDeliveryDate
  } = booking;
  const isFlexibleDeliveryDate = flexibleDeliveryDate ?? false;
  const deliveryDate = targetDeliveryDate ? addLocalTimeZoneOffset(targetDeliveryDate) : null;
  return {
    date: isFlexibleDeliveryDate ? null : deliveryDate,
    isFlexible: isFlexibleDeliveryDate
  };
};