import { selectSalesChannels } from "transfers/wfs/store/WfsIntegrationSelectors";
import { useStore } from "react-redux";
import { SalesChannelConnectionStatus } from "common/clients/onboarding/SellerSalesChannels/SalesChannelConnectionStatus";
export const useWalmartAuth = () => {
  const {
    getState
  } = useStore();
  const walmartChannel = selectSalesChannels(getState()).find(salesChannel => salesChannel.channelName === "Walmart");
  const isAuthorized = walmartChannel?.connectionStatus === SalesChannelConnectionStatus.CONNECTED;
  const slsUuid = walmartChannel?.slsUuid;
  return {
    isAuthorized,
    slsUuid
  };
};