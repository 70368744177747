import { ApiError, DeliverrError } from "@deliverr/commons-objects";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mustBeDefined } from "common/utils/mustBeDefined";
export class OnboardingClientV2 {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.ONBOARDING_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v2`
    });
    this.v1ApiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async getSellerSalesChannels(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/channels`,
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getSellerSalesChannels", error);
    }
  }
  async deactivateSellerIntegration(slsUuid) {
    try {
      return await this.apiClient.post({
        url: `/channel/deactivate/${slsUuid}`,
        body: {},
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("deactivateSellerIntegration", error);
    }
  }
  async activateSellerIntegration(slsUuid) {
    try {
      return await this.apiClient.post({
        url: `/channel/activate/${slsUuid}`,
        body: {},
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("activateSellerIntegration", error);
    }
  }
  async startCatalogUpdate(sendSellerEmailWhenDone) {
    try {
      return await this.apiClient.post({
        url: `/jobs/product/sync`,
        body: {},
        params: {
          email: sendSellerEmailWhenDone ? "true" : ""
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("startCatalogUpdate", error);
    }
  }
  async getCatalogUpdateStatus() {
    try {
      return await this.apiClient.get({
        url: `/jobs/product/sync`,
        params: {},
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getCatalogUpdateStatus", error);
    }
  }

  /**
   * Formerly known as getShopifyOauthConsentUrlForAppInstallation
   */
  async getOauthConsentUrl(fullQueryString, channelId) {
    try {
      return await this.apiClient.get({
        url: `/setup/oauth/url`,
        params: {
          fullQueryString,
          channelId
        }
      });
    } catch (error) {
      throw this.newOnboardingError("getOauthConsentUrl", error);
    }
  }

  /**
   * Formerly known as addShopifyIntegrationFromAppInstallation
   *
   * TODO: It may be possible to change the code to not have this special purpose method
   * (using just createChannel with flexible parameters).
   *
   * @returns an object that includes slsUuid of the newly created channel
   */
  async createOrUpdateChannelFromAppStore(sellerId, shop, generatedUUID) {
    try {
      return await this.apiClient.post({
        url: `/channel/app`,
        params: {
          sellerId,
          shop,
          uuid: generatedUUID // uuid is a reserved keyword
        },

        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("createOrUpdateChannelFromAppStore", error);
    }
  }

  /**
   * Creates a new channel connection
   *
   * In the data dictionary that is passed as a parameter only apiKey is needed,
   * but keeping the method signature for now.
   *
   * @returns an object that includes slsUuid of the newly created channel
   */
  async createChannel(listingSolution, sellerId, data) {
    try {
      return await this.apiClient.post({
        url: `/channel`,
        params: {
          sellerId,
          name: listingSolution,
          ...data
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("createChannel", error);
    }
  }

  /**
   * Updates an existing channel
   *
   * In the data dictionary that is passed as a parameter only apiKey is needed,
   * but keeping the method signature for now.
   */
  async updateChannel(slsUuid, data) {
    try {
      return await this.apiClient.post({
        url: `/channel/${slsUuid}`,
        body: {},
        params: {
          ...data
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("updateChannel", error);
    }
  }
  async createShippingOptionZones(slsUuid) {
    try {
      return await this.apiClient.post({
        url: `/shipping-option-zones`,
        body: [],
        params: {
          slsUuid
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("createShippingOptionZones", error);
    }
  }
  async getShippingOptionZones(slsUuid) {
    try {
      return await this.apiClient.get({
        url: `/shipping-option-zones`,
        params: {
          slsUuid
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getShippingOptionZones", error);
    }
  }
  async updateShippingOptionZones(slsUuid, shippingOptionZones) {
    try {
      return await this.apiClient.put({
        url: `/shipping-option-zones`,
        body: shippingOptionZones,
        params: {
          slsUuid
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("updateShippingOptionZones", error);
    }
  }
  async getCredentials(slsUuid) {
    try {
      return await this.apiClient.get({
        url: `/credentials`,
        params: {
          slsUuid
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getCredentials", error);
    }
  }
  async importCatalog(params) {
    try {
      return await this.apiClient.post({
        url: `/product/import`,
        body: {},
        params,
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("importCatalog", error);
    }
  }
  async updateSellerIntegrationAttributes(slsUuid, listingSolution, attributes) {
    try {
      return await this.apiClient.post({
        url: `/${listingSolution}/updateAttributes/${slsUuid}`,
        body: attributes,
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("updateSellerIntegrationAttributes", error);
    }
  }
  async getSeller(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getSeller", error);
    }
  }
  async getShopifyIntegrationStatus(shopDomain) {
    try {
      return await this.v1ApiClient.get({
        url: `/shopify-integration-status`,
        params: {
          shopDomain
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      if (error?.response?.status === 404) {
        return null;
      }
      throw this.newOnboardingError("getShopifyIntegrationStatus", error);
    }
  }
  async setShopifyIntegrationStatus(params) {
    try {
      return await this.v1ApiClient.put({
        params,
        url: `/shopify-integration-status`,
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getShopifyIntegrationStatus", error);
    }
  }
  async getShopPromiseProgram(sellerId, shop) {
    try {
      return await this.apiClient.get({
        url: `/shopify/promise/seller/${sellerId}/shop/${shop}/enabled`,
        params: {},
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getShopPromiseProgram", error);
    }
  }
  async getDeliveryPromiseProvider(sellerId, shop) {
    try {
      return await this.apiClient.get({
        url: `/shopify/promise/seller/${sellerId}/shop/${shop}/provider/fetch`,
        params: {},
        authentication: isAuthenticated
      });
    } catch (error) {
      throw this.newOnboardingError("getDeliveryPromiseProvider", error);
    }
  }
  newOnboardingError(name, err) {
    if (!err.response) {
      return new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName: name,
        payload: err
      });
    }
    if (err.response?.status !== undefined && err.response.status === 504) {
      const networkError = new DeliverrError({
        code: ApiError.NETWORK_ERROR,
        functionName: name
      });
      return networkError;
    }
    return new DeliverrError({
      code: err.response.data ? err.response.data.code : ApiError.UNKNOWN_ERROR,
      subcode: String(err.response.status),
      // TODO use response.data.error.subcode when error handling is standardized
      functionName: name,
      payload: err.response.data
    });
  }
}