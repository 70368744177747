export let StorageInboundDetailActionTypes;
(function (StorageInboundDetailActionTypes) {
  StorageInboundDetailActionTypes["STORAGE_GET_DETAILS"] = "STORAGE_GET_DETAILS";
  StorageInboundDetailActionTypes["STORAGE_GET_DETAILS_SUCCESS"] = "STORAGE_GET_DETAILS_SUCCESS";
  StorageInboundDetailActionTypes["STORAGE_GET_DETAILS_ERROR"] = "STORAGE_GET_DETAILS_ERROR";
  StorageInboundDetailActionTypes["RESET_STORAGE_DETAILS"] = "RESET_STORAGE_DETAILS";
  StorageInboundDetailActionTypes["STORAGE_GET_ITEM_DETAILS_SUCCESS"] = "STORAGE_GET_ITEM_DETAILS_SUCCESS";
  StorageInboundDetailActionTypes["STORAGE_GET_ITEM_DETAILS_ERROR"] = "STORAGE_GET_ITEM_DETAILS_ERROR";
  StorageInboundDetailActionTypes["STORAGE_GET_RATES_SUCCESS"] = "STORAGE_GET_RATES_SUCCESS";
  StorageInboundDetailActionTypes["STORAGE_GET_RATES_ERROR"] = "STORAGE_GET_RATES_ERROR";
  StorageInboundDetailActionTypes["STORAGE_UPDATE_ITEM_DETAILS_SUCCESS"] = "STORAGE_UPDATE_ITEM_DETAILS_SUCCESS";
  StorageInboundDetailActionTypes["STORAGE_UPDATE_ITEM_DETAILS_ERROR"] = "STORAGE_UPDATE_ITEM_DETAILS_ERROR";
  StorageInboundDetailActionTypes["STORAGE_FETCH_RECEIVE_INFO"] = "STORAGE_FETCH_RECEIVE_INFO";
  StorageInboundDetailActionTypes["STORAGE_GET_PROBLEMS"] = "STORAGE_GET_PROBLEMS";
})(StorageInboundDetailActionTypes || (StorageInboundDetailActionTypes = {}));