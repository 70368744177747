import { createActionCreator } from "common/ReduxUtils";
import { TransferCreateActionTypes } from "../store";
export const transferCreateChooseDestinationType = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_DESTINATION_TYPE, "destinationType");
export const transferCreateChooseShippingConfig = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_SHIPPING_CONFIG, "shippingConfig");
export const transferCreateSetShipmentShippingMethod = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_SHIPMENT_SHIPPING_CONFIG, "shipmentId", "shippingMethod", "shipmentType");
export const transferCreateSetFbaShipmentId = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_FBA_SHIPMENT_ID, "fbaShipmentId");
export const transferCreateSetFbaReferenceId = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_FBA_REFERENCE_ID, "fbaReferenceId");
export const transferCreateSetAttachments = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_ATTACHMENTS, "attachments");
export const transferCreateSelectDestinationAddress = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SELECT_AMAZON_ADDRESS, "destinationAddress");
export const transferCreateGetUnifiedProduct = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_GET_UNIFIED_PRODUCT, "unifiedProductResponseCache");
export const transferCreateStoreSteps = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_STORE_STEPS, "stack");
export const transferCreateReset = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_RESET);
export const transferCreateAddProduct = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_ADD_PRODUCT, "dsku", "product");
export const transferCreateResetProductSelection = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_RESET_PRODUCT_SELECTION);
export const transferBulkAddProduct = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_BULK_ADD_PRODUCT, "selectedProducts");
export const transferCreateRemoveProduct = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_REMOVE_PRODUCT, "dsku");
export const transferCreateUpdateProductQty = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_TOTAL_QTY, "dsku", "totalQty");
export const transferCreateUpdateProductNumberOfCases = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_NUMBER_OF_CASES, "dsku", "numberOfCases");
export const transferCreateUpdateProductCaseQty = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_CASE_QUANTITY, "dsku", "caseQty");
export const transferCreateUpdateProductLotNumber = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_LOT_NUMBER, "dsku", "atpDetails");
export const transferCreateChooseOriginWarehouse = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE, "originStorageWarehouse");
export const transferCreateSetStorageTransferOrder = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_STORAGE_TRANSFER_ORDER, "storageTransferOrder", "selectedProducts", "selectedProductsCaseInfo");
export const transferCreateSetDraftFlag = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_DRAFT_FLAG, "isTransferToEcomDraft");
export const transferCreateSetWholesaleOrder = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_WHOLESALE_ORDER, "wholesaleOrder");
export const transferCreateUpdateStateFromWholesaleOrder = data => ({
  type: TransferCreateActionTypes.TRANSFER_CREATE_UPDATE_STATE_FROM_WHOLESALE_ORDER,
  payload: data
});
export const transferCreateSetError = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_ERROR, "errorType");
export const transferCreateSetUploading = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_UPLOADING, "attachmentType", "value");
export const transferCreateSetLabelFilename = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_LABEL_FILENAME, "label", "file");
export const transferCreateSetReplenishmentOrder = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ORDER, "replenishmentOrder");
export const transferCreateSetReplenishmentOrderQuotes = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ORDER_QUOTES, "replenishmentOrderEstimates");
export const transferCreateSetReplenishmentError = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_REPLENISHMENT_ERROR, "replenishmentError");
export const transferCreateSetPrepType = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_PREP_TYPE, "prepOption");
export const transferCreateSetValidShipBy = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_VALID_SHIP_BY, "validShipBy");
export const transferCreateSetPoNumber = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_PO_NUMBER, "poNumber");
export const transferCreateSetShipmentValidationError = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_SHIPMENT_VALIDATION_ERROR, "shippingPartner", "shippingType", "shipmentValidationError");
export const updateInvalidOrderItem = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_MUTATE_INVALID_ORDER_ITEM, "invalidOrderItem");
export const setInvalidOrderItems = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_INVALID_ORDER_ITEMS, "invalidOrderItems");
export const setPartialFill = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_PARTIAL_FILL, "partialFill");
export const transferCreateSetIsEachPickingOrderFlag = createActionCreator(TransferCreateActionTypes.TRANSFER_CREATE_SET_IS_EACH_PICKING_ORDER, "isEachPickingOrder");