import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLifecycles } from "react-use";
import { getReturnItem, resetReturnLabel } from "./store/ReturnsLabelAction";
export const useReturnsLabelOrchestration = () => {
  const returnItem = useSelector(state => state.returnsLabel.returnItem);
  const error = useSelector(state => state.returnsLabel.error);
  const shareLabelStatus = useSelector(state => state.returnsLabel.shareLabelStatus);
  const email = useSelector(state => state.returnsLabel.email);
  const dispatch = useDispatch();
  const {
    returnId
  } = useParams();
  useLifecycles(() => {
    dispatch(getReturnItem(returnId));
  }, () => {
    dispatch(resetReturnLabel());
  });
  return {
    dispatch,
    error,
    returnItem,
    shareLabelStatus,
    email
  };
};