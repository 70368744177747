import { createSelector } from "reselect";
import { selectTransferCreate } from "./TransferCreateSelectors";
import { keyBy, isEmpty, chain } from "lodash";
import { InvalidOrderItemInventoryStatus } from "../steps/common/replenishment/details/out-of-stock-items/AugmentedOutOfStockOrderItem";
import { isOutOfStockItemUnremoved } from "../steps/common/replenishment/details/out-of-stock-items/OutOfStockOrderUtils";
export const selectAugmentedOutOfStockOrderItems = createSelector(selectTransferCreate, transferCreate => {
  const {
    replenishmentOrder,
    originStorageWarehouse
  } = transferCreate;
  const warehouseDskuAvailableQty = keyBy(originStorageWarehouse?.dskuQuantities ?? [], ({
    dsku
  }) => dsku);
  return chain(replenishmentOrder?.invalidOrderItems ?? []).filter(item => isOutOfStockItemUnremoved(item)).map(item => {
    return {
      ...item,
      inventoryStatus: getDskuInventoryStatus(item, warehouseDskuAvailableQty),
      availableQty: item.resolvedDsku ? warehouseDskuAvailableQty?.[item.resolvedDsku]?.qty ?? 0 : 0
    };
  }).sortBy(item => item.inventoryStatus).value();
});
const getDskuInventoryStatus = (item, warehouseDskuAvailableQty) => {
  if (item.resolvedDsku && !isEmpty(warehouseDskuAvailableQty)) {
    const availableQuantity = warehouseDskuAvailableQty?.[item.resolvedDsku]?.qty ?? 0;
    if (!availableQuantity) {
      return InvalidOrderItemInventoryStatus.OUT_OF_STOCK;
    } else if (availableQuantity !== 0 && availableQuantity < item.requestedTotalUnits) {
      return InvalidOrderItemInventoryStatus.PARTIAL_STOCK;
    } else if (availableQuantity >= item.requestedTotalUnits) {
      return InvalidOrderItemInventoryStatus.IN_STOCK;
    }
  }
  return undefined;
};