import { productClient } from "Clients";
import { inventoryClient } from "common/clients/instances";
import algoliaService from "common/components/ProductSearch/algoliaService";
import { keyBy, uniq, values } from "lodash";
import { fetchMultiPackProductATP } from "storage/packUtils/fetchMultiPackProductATP";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { mapSkuCaseQtiesToSelectedProducts } from "transfers/create/store/mapSkuCaseQtiesToSelectedProducts";
export const getItemProductAndInventoryDetails = async (skuQties,
// expects skuCaseQties to only contain pack dskus if multi pack is enabled
dispatch, isMultiCasePackEnabled, fetchEcomInventory = false) => {
  let draftProductInventory;
  let draftProducts;
  const skus = skuQties.map(({
    dsku
  }) => dsku);
  if (isMultiCasePackEnabled) {
    // Fetch the product details ( including mapping child with parent details )
    draftProducts = await fetchMultiPackProductsByDskus(skus, dispatch);

    // Combine parent and default case pack DSKU inventory for displaying on UI.
    draftProductInventory = await fetchMultiPackProductATP(skus, draftProducts);
  } else {
    [draftProducts, draftProductInventory] = await Promise.all([productClient.getUnifiedProducts(skus), inventoryClient.getATP(skus, undefined, "STORAGE")]);
  }
  const productDskus = uniq(values(draftProducts).map(product => product.packOf ?? product.dsku));
  let draftProductEcomInventory;
  if (fetchEcomInventory) {
    const totalProductDskus = productDskus.length;
    const productIndexRecords = (await algoliaService.searchByIds(productDskus, "", "dsku", totalProductDskus)).hits;
    draftProductEcomInventory = keyBy(productIndexRecords, "dsku");
  }
  const selectedProducts = mapSkuCaseQtiesToSelectedProducts(skuQties, draftProducts, draftProductInventory, draftProductEcomInventory);
  return {
    selectedProducts,
    draftProducts,
    draftProductInventory,
    draftProductEcomInventory
  };
};