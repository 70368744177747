import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ReplenishmentOrderDetailsActionTypes } from "./ReplenishmentOrderDetailsActionTypes";
export const replenishmentOrderDetailsInitialState = {
  hasError: false
};
const reducers = {
  ...handleSimpleReducerUpdates([ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_REPLENISHMENT_ORDER, ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_PRODUCTS, ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_SHIPMENT_ATTACHMENTS, ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_SHIPMENT_HISTORY, ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_ORDER_COSTS, ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_SET_ORDER_NON_COMPLIANCES]),
  [ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_RESET_REPLENISHMENT_ORDER]: () => replenishmentOrderDetailsInitialState,
  [ReplenishmentOrderDetailsActionTypes.REPLENISHMENT_UPDATE_NON_COMPLIANCE]: (state, action) => {
    const updatedNonCompliances = state.nonCompliances?.map(nc => nc.id === action.nonCompliance.id ? action.nonCompliance : nc);
    return {
      ...state,
      nonCompliances: updatedNonCompliances
    };
  }
};
export const replenishmentOrderDetailsReducer = createReducer(replenishmentOrderDetailsInitialState, reducers);