export let FreightPalletValidations;
(function (FreightPalletValidations) {
  FreightPalletValidations["OVER_MAX_PALLET_QTY"] = "OVER_MAX_PALLET_QTY";
  FreightPalletValidations["OVER_MAX_PALLET_TOTAL_WEIGHT"] = "OVER_MAX_PALLET_TOTAL_WEIGHT";
  FreightPalletValidations["UNDER_MINIMUM_TOTAL_WEIGHT"] = "UNDER_MINIMUM_TOTAL_WEIGHT";
  FreightPalletValidations["PALLETS_OVER_MAX_WIDTH"] = "PALLETS_OVER_MAX_WIDTH";
  FreightPalletValidations["PALLETS_OVER_MAX_LENGTH"] = "PALLETS_OVER_MAX_LENGTH";
  FreightPalletValidations["PALLETS_OVER_MAX_HEIGHT"] = "PALLETS_OVER_MAX_HEIGHT";
  FreightPalletValidations["PALLETS_OVER_MAX_WEIGHT"] = "PALLETS_OVER_MAX_WEIGHT";
  FreightPalletValidations["MORE_PALLETS_THAN_BOXES"] = "MORE_PALLETS_THAN_BOXES";
  FreightPalletValidations["PALLET_COUNT_NOT_MATCHING"] = "PALLET_COUNT_NOT_MATCHING";
})(FreightPalletValidations || (FreightPalletValidations = {}));