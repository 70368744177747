import { getUnifiedProducts } from "common/wholesale-common/getUnifiedProducts";
export const getSelectedProductsWithPackOf = async (dispatch, selectedProductsQty) => {
  const dskus = selectedProductsQty.map(selectedProduct => selectedProduct.dsku);
  const products = await dispatch(getUnifiedProducts(dskus));
  const selectedProductsQtyWithPackOf = selectedProductsQty.map(selectedProduct => {
    return {
      packOf: products[selectedProduct.dsku].packOf ?? selectedProduct.dsku,
      ...selectedProduct
    };
  });
  return selectedProductsQtyWithPackOf;
};