import { createSelector } from "reselect";
import { selectPrepJob } from "./selectPrepJob";
import { getConsolidatedPrepTypesFromPrepJob } from "prep/util/getConsolidatedPrepTypesFromPrepJob";

/**
 * Get Combo-friendly prep types from the Prep source of truth
 * @returns Array of SuperPrepType
 */
export const selectConsolidatedPrepTypes = createSelector(selectPrepJob, prepJob => {
  return getConsolidatedPrepTypesFromPrepJob(prepJob) ?? [];
});