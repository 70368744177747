import { CargoInsuranceType, DistributionType } from "inbounds/createShipment/CreateShipmentTypes";
import { createShipmentInitialFormState as defaults } from "inbounds/createShipment/store/CreateShipmentState";
import { parseBookingType } from "inbounds/createShipment/store/utils/parseBookingType";
import { _parseAmazonAddress } from "./_parseAmazonAddress";
import { _parseDtcPrepOption } from "./_parseDtcPrepOption";
export const getCheckoutState = (booking, isSmbAccelerateFbaUnitPrepOn) => {
  const {
    distributionType,
    amazonDestinationType
  } = parseBookingType(booking.bookingType);
  const distributionTypeWithPrep = isSmbAccelerateFbaUnitPrepOn && distributionType === DistributionType.FORWARD_TO_AMAZON ? DistributionType.FORWARD_TO_AMAZON_WITH_UNIT_PREP : distributionType;
  return {
    freightService: null,
    hasAnnualCustomsBond: booking.includeCustomsBond ?? defaults.hasAnnualCustomsBond,
    hasCargoInsurance: booking.addFlexportInsurance ?? defaults.hasCargoInsurance,
    insuranceInfo: booking.addFlexportInsurance ? {
      ...defaults.insuranceInfo,
      totalCargoValue: booking.totalCommercialValue ?? defaults.insuranceInfo.totalCargoValue
    } : defaults.insuranceInfo,
    cargoInsuranceType: booking.insurancePremiumCents ? CargoInsuranceType.NO_RESTRICTED_PRODUCTS : null,
    distributionType: distributionTypeWithPrep ?? defaults.distributionType,
    amazonDestinationType: amazonDestinationType ?? defaults.amazonDestinationType,
    amazonAddress: _parseAmazonAddress(booking),
    flexportCapitalSelected: booking.applyForFlexportCapital ?? defaults.flexportCapitalSelected,
    amazonPrepType: booking.prepOption ?? defaults.amazonPrepType,
    airShipmentCargoDetails: booking.airShipmentCargoDetails ?? defaults.airShipmentCargoDetails,
    dtcPrepOption: _parseDtcPrepOption(booking.dtcPrepOption)
  };
};