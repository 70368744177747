export let ShopifyError;
(function (ShopifyError) {
  ShopifyError["ALREADY_HAS_SHOP"] = "ALREADY_HAS_SHOP";
  ShopifyError["APP_NOT_INSTALLED"] = "APP_NOT_INSTALLED";
  ShopifyError["DUPLICATE_MERCHANT_ID"] = "DUPLICATE_MERCHANT_ID";
  ShopifyError["DUPLICATE_SELLER"] = "DUPLICATE_SELLER";
  ShopifyError["INELIGIBLE_SHOPIFY_ACCOUNT"] = "INELIGIBLE_SHOPIFY_ACCOUNT";
  ShopifyError["INVALID_HMAC"] = "INVALID_HMAC";
  ShopifyError["INVALID_HOSTNAME"] = "INVALID_HOSTNAME";
  ShopifyError["INVALID_MAIN_ASSET"] = "INVALID_MAIN_ASSET";
  ShopifyError["INVALID_MERCHANT_ID"] = "INVALID_MERCHANT_ID";
  ShopifyError["INVALID_NONCE"] = "INVALID_NONCE";
  ShopifyError["INVALID_SETTINGS_ASSET"] = "INVALID_SETTINGS_ASSET";
  ShopifyError["INVALID_TEMPLATE_ASSET"] = "INVALID_TEMPLATE_ASSET";
  ShopifyError["INVALID_URL"] = "INVALID_URL";
  ShopifyError["MISSING_SCOPES"] = "MISSING_SCOPES";
  ShopifyError["MULTIPLE_VARIANTS"] = "MULTIPLE_VARIANTS";
  ShopifyError["NO_SHOP_FROM_CLIENT"] = "NO_SHOP_FROM_CLIENT";
  ShopifyError["NOT_AN_ADMIN"] = "NOT_AN_ADMIN";
  ShopifyError["NOT_FOUND"] = "NOT_FOUND";
  ShopifyError["NOT_FOUND_IN_MARKETPLACE"] = "NOT_FOUND_IN_MARKETPLACE";
  ShopifyError["REFRESH_ALREADY_IN_PROGRESS"] = "REFRESH_ALREADY_IN_PROGRESS";
  ShopifyError["UNAUTHORIZED"] = "UNAUTHORIZED";
})(ShopifyError || (ShopifyError = {}));