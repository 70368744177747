import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ReturnsTableActionTypes } from "./ReturnsListStore.types";
const ITEMS_PER_PAGE = 25;
export const returnsListInitialStateV2 = {
  currentPageItems: [],
  pagingInfo: {
    currentPage: 0,
    itemsPerPage: ITEMS_PER_PAGE,
    totalPages: 0
  },
  searchTerm: "",
  filter: {}
};
const reducers = {
  ...handleSimpleReducerUpdates([ReturnsTableActionTypes.SEARCH_RETURNS_FAIL, ReturnsTableActionTypes.SEARCH_RETURNS_SUCCESS, ReturnsTableActionTypes.SEARCH_RETURNS_UPDATE_TERM])
};
export const returnsListReducerV2 = createReducer(returnsListInitialStateV2, reducers);