import { inboundListFiltersInitialState } from "./inboundListFiltersInitialState";
export const inboundListInitialState = {
  currentPageItems: [],
  pageChecked: false,
  selectionCount: 0,
  selectedRows: {},
  searchLoading: false,
  filtersBladeOpen: false,
  searchFilters: {
    ...inboundListFiltersInitialState
  }
};