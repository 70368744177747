import styled from "@emotion/styled";
import { Text } from "common/components/ui/Text";
export const LightText = styled(Text)(({
  theme
}) => `
  color: ${theme.colors.NEUTRAL[300]};
`);
export const InspectionText = styled.span(({
  theme
}) => `
  margin-right: ${theme.spacing.S4};
`);
export const BoldText = styled(Text)(({
  theme
}) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-weight: ${theme.font.weight.BOLD};
`);
export const WarningText = styled(Text)(({
  theme
}) => `
  color: ${theme.colors.YELLOW[500]};
  margin-left: ${theme.spacing.S1};
  font-weight: ${theme.font.weight.BOLD};
`);