import { defineMessages } from "react-intl";
import { PrepUIErrorCode } from "../PrepUIErrorCode";
export const PREP_ERROR_CODE_LABELS = defineMessages({
  [PrepUIErrorCode.FAILED_TO_REQUEST_SIMPLE_PREP]: {
    id: "inbounds.prep.errorCode.failedToUpdateSimplePrep",
    defaultMessage: "An error occured while requesting Prep. Please refresh and try again."
  },
  [PrepUIErrorCode.FAILED_TO_REMOVE_SIMPLE_PREP]: {
    id: "inbounds.prep.errorCode.failedToRemoveSimplePrep",
    defaultMessage: "An error occured while updating your Prep selection. Please refresh and try again."
  }
});
export const UNEXPECTED_ERROR_LABEL = defineMessages({
  id: "inbounds.prep.unexpectedError",
  defaultMessage: "An unexpected error occured. Please try again or contact support."
});