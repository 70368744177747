import { createSelector } from "reselect";
import { selectCreateShipmentForm } from "./selectCreateShipmentForm";

/** return place name with regards to selected freight service */
export const selectPlaceNames = createSelector(selectCreateShipmentForm, ({
  origin,
  originFacilityName,
  isOriginPort,
  destination,
  destinationFacilityName,
  freightService
}) => {
  const originPortGrouping = origin.value.ports;
  const destinationPortGrouping = destination.value.ports;
  const originPort = originPortGrouping.find(port => port.fid === freightService?.originFid);
  const destinationPort = destinationPortGrouping.find(port => port.fid === freightService?.destinationFid);
  const {
    city: originCity,
    name: originAddressName
  } = origin.value.address;
  const originName = originFacilityName?.length ? originFacilityName : isOriginPort && originPort ? `Port ${originPort.abbr}, ${originCity}` : originAddressName.length ? originAddressName : origin.label;
  const {
    city: destinationCity
  } = destination.value.address;
  const destinationName = destinationFacilityName?.length ? destinationFacilityName : destinationPort ? `Port ${destinationPort.abbr}, ${destinationCity}` : destination.label;
  return {
    originName,
    destinationName
  };
});