import { FbaOAuthAuthorizationStep } from "./steps/fbaIntegration/FbaOAuthAuthorizationStep";
import { TransferCreateInitialStep } from "./steps/createTransferSteps";
import { FbaIntegrationShippingMethod } from "./steps/fbaIntegration/FbaIntegrationShippingMethod";
import { transferCreateChooseDestinationType } from "./actions";
import { TransferCreationTypes } from "./transferCreationTypes";
import { FbaWaitForShipmentCreation } from "./steps/fbaIntegration/FbaWaitForShipmentCreation";
import { FbaShipmentCreationError } from "./steps/fbaIntegration/FbaShipmentCreationError";
import { selectTransferCreate } from "./store/TransferCreateSelectors";
import { FbaIntegrationConfirmed } from "./steps/fbaIntegration/FbaIntegrationConfirmed";
import { FbaWaitForShipmentConfirmation } from "./steps/fbaIntegration/FbaWaitForShipmentConfirmation";
import { updateFbaReferenceIdForAllShipmentInOrder } from "../../wholesale/detail/updateFbaReferenceId";
import { FbaV2Steps } from "./fbaV2Steps";
export let FbaIntegrationV2Steps;
(function (FbaIntegrationV2Steps) {
  FbaIntegrationV2Steps["Authorize"] = "fbaIntegration/v2/authorize";
  FbaIntegrationV2Steps["WaitForShipmentCreation"] = "fbaIntegration/v2/waitForShipmentCreation";
  FbaIntegrationV2Steps["ShipmentCreationError"] = "fbaIntegration/v2/shipmentCreationError";
  FbaIntegrationV2Steps["SelectShippingMethod"] = "fbaIntegration/v2/shippingMethod";
  FbaIntegrationV2Steps["WaitForShipmentConfirmation"] = "fbaIntegration/v2/waitForShipmentConfirmation";
  FbaIntegrationV2Steps["Confirmed"] = "fbaIntegration/v2/confirmed";
})(FbaIntegrationV2Steps || (FbaIntegrationV2Steps = {}));
export const fbaIntegrationV2Steps = (dispatch, getState) => ({
  [FbaIntegrationV2Steps.Authorize]: {
    component: FbaOAuthAuthorizationStep,
    next: async (stepButton, {
      revert
    }) => {
      if (stepButton === 0) {
        return undefined;
      }
      dispatch(transferCreateChooseDestinationType(TransferCreationTypes.Fba));
      return [...revert(TransferCreateInitialStep), FbaV2Steps.SelectAmazonFacility];
    }
  },
  [FbaIntegrationV2Steps.WaitForShipmentCreation]: {
    component: FbaWaitForShipmentCreation,
    async next(value) {
      const {
        wholesaleOrder
      } = selectTransferCreate(getState());
      if (wholesaleOrder?.errors) {
        return FbaIntegrationV2Steps.ShipmentCreationError;
      } else if (value.isTimeout) {
        return FbaIntegrationV2Steps.ShipmentCreationError;
      } else {
        return FbaIntegrationV2Steps.SelectShippingMethod;
      }
    }
  },
  [FbaIntegrationV2Steps.ShipmentCreationError]: {
    component: FbaShipmentCreationError,
    hasNoBack: true
  },
  [FbaIntegrationV2Steps.SelectShippingMethod]: {
    component: FbaIntegrationShippingMethod,
    next: async () => {
      return FbaV2Steps.Confirm;
    }
  },
  [FbaIntegrationV2Steps.WaitForShipmentConfirmation]: {
    component: FbaWaitForShipmentConfirmation,
    next: async () => {
      return FbaIntegrationV2Steps.Confirmed;
    }
  },
  [FbaIntegrationV2Steps.Confirmed]: {
    component: FbaIntegrationConfirmed,
    hasNoBack: true,
    async next(value, _stepstack, handlers) {
      if (value.isDeliverrFreight) {
        const {
          wholesaleOrder
        } = selectTransferCreate(getState());
        dispatch(updateFbaReferenceIdForAllShipmentInOrder(value.fbaReferenceIdMap, wholesaleOrder));
      }
      handlers?.done?.();
      return undefined;
    }
  }
});