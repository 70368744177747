import { inboundClient } from "Clients";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { selectBookingSubmitRequest } from "../selectors/selectBookingSubmitRequest";
import { getSellerId } from "common/user/UserSelectors";
export const updateBookingCheckout = (shippingPlanId, shouldSubmit) => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const bookingRequest = selectBookingSubmitRequest()(state);
  const ctx = logStart({
    fn: SmbBookingCreationTimedEvent.UpdateBooking,
    shippingPlanId,
    bookingRequest
  });
  try {
    let response;
    if (!shouldSubmit) {
      response = await inboundClient.bookingSaveFreight(sellerId, shippingPlanId, bookingRequest);
      logSuccess(ctx, "successfully saved checkout");
    } else {
      response = await inboundClient.submitBooking(sellerId, shippingPlanId, bookingRequest);
      logSuccess(ctx, "successfully submitted booking");
    }
    return response;
  } catch (error) {
    logError(ctx, error);
    throw error;
  }
};