import styled from "@emotion/styled";
import { getResponsiveStyles } from "../shared";
import { getGap } from "./getGap";
import { getColumns } from "./getColumns";
export const Grid = styled.div(({
  columns,
  gap,
  alignItems = "initial",
  inline,
  fullWidth,
  theme
}) => {
  return `
      align-items: ${alignItems};
      display: ${inline ? "inline-grid" : "grid"};
      ${getResponsiveStyles("grid-gap", gap, getGap(theme))}
      ${getResponsiveStyles("grid-template-columns", columns, getColumns)}
      ${fullWidth ? `width: 100%` : ``};
    `;
});