import { defineMessages } from "react-intl";
export const shipmentSummaryLabels = defineMessages({
  skusAndUnits: {
    id: "transfers.detail.summary.skusAndUnits.label",
    defaultMessage: "{numSkus, plural, one {# SKU} other {# SKUs}} \u00b7 {numUnits, plural, one {# unit} other {# units}}"
  },
  orderId: {
    id: "transfers.detail.summary.orderId.label",
    defaultMessage: "Order ID: {orderId}"
  },
  transferId: {
    id: "transfers.detail.summary.transferId.label",
    defaultMessage: "Transfer ID: {orderId}"
  },
  shipmentId: {
    id: "transfers.detail.summary.shipmentId.label",
    defaultMessage: "Shipment ID: {shipmentId}"
  },
  poNumber: {
    id: "transfers.detail.summary.poNumber.label",
    defaultMessage: "PO# {poNumber}"
  },
  bolId: {
    id: "transfers.detail.summary.bolId.label",
    defaultMessage: "BOL# {bolId}"
  },
  fbaRefId: {
    id: "transfers.detail.summary.fbaRefId",
    defaultMessage: "FBA Reference ID: {fbaRefId}"
  },
  shippingMethod: {
    id: "transfers.detail.summary.shippingMethod.label",
    defaultMessage: "Shipping with {shippingMethod} ({shipmentType})"
  },
  shippingMethodShortDescription: {
    id: "transfers.detail.summary.shippingMethodShortDescription.label",
    defaultMessage: "{shippingMethod} ({shipmentType})"
  },
  placed: {
    id: "transfers.detail.summary.placedOn.label",
    defaultMessage: "Placed {submitTimestamp}"
  },
  methodDeliverr: {
    id: "transfers.detail.summary.shipmentMethod.deliverrFreight",
    defaultMessage: "Flexport"
  },
  methodYourCarrier: {
    id: "transfers.detail.summary.shipmentMethod.yourCarrier",
    defaultMessage: "Your Carrier"
  },
  methodFBA: {
    id: "transfers.detail.summary.shipmentMethod.fba",
    defaultMessage: "Amazon"
  },
  methodWFS: {
    id: "transfers.detail.summary.shipmentMethod.wfs",
    defaultMessage: "Walmart"
  },
  methodUnknown: {
    id: "transfers.detail.summary.shipmentMethod.unknown",
    defaultMessage: "Unknown"
  },
  methodCollect: {
    id: "transfers.detail.summary.shipmentMethod.collect",
    defaultMessage: "Collect method"
  },
  methodAmazonCarrier: {
    id: "transfers.detail.summary.shipmentMethod.methodAmazonCarrier",
    defaultMessage: "Amazon partner"
  },
  shipmentTypeParcel: {
    id: "transfers.detail.summary.shipmentTypeParcel.label",
    defaultMessage: "Parcel"
  },
  shipmentTypePalletized: {
    id: "transfers.detail.summary.shipmentTypePalletized.label",
    defaultMessage: "Palletized"
  },
  dpIds: {
    id: "transfers.detail.summary.dpIds.label",
    defaultMessage: "{numberOfDpIds, plural, one {DP ID} other {DP IDs}}: {dpIds}"
  },
  pickUpDate: {
    id: "transfers.detail.summary.pickUpDate.label",
    defaultMessage: "Freight pickup scheduled at {pickUpDate}"
  },
  shippingBySeller: {
    id: "transfers.shipment.header.shippingBySeller",
    defaultMessage: "Shipping by"
  },
  shipBy: {
    id: "transfers.shipment.header.shipBy",
    defaultMessage: "Ship by"
  },
  palletCount: {
    id: "transfers.detail.summary.palletCount",
    defaultMessage: "Actual Pallet:"
  },
  estimatedPalletCount: {
    id: "transfers.detail.summary.estimatedPalletCount.label",
    defaultMessage: "Estimated Pallet: {estimatedPalletCount}"
  }
});