import { set } from "date-fns";
export const EARLIEST_PICKUP_TIME = set(new Date(), {
  hours: 7,
  minutes: 0,
  seconds: 0
});
export const LATEST_PICKUP_TIME = set(new Date(), {
  hours: 19,
  minutes: 0,
  seconds: 0
});
export const MINIMUM_PICKUP_WINDOW_HOURS = 4;