import { defineMessages } from "react-intl";
export const printSizeLabels = defineMessages({
  THERMAL_4_X_6: {
    id: "storage.inbounds.printSizeOptions.thermal",
    defaultMessage: `Thermal Printing Paper (4" x 6")`
  },
  US_LETTER: {
    id: "storage.inbounds.printSizeOptions.usLetter",
    defaultMessage: `US Letter (8.5" x 11")`
  },
  A4_PAPER: {
    id: "storage.inbounds.printSizeOptions.a4",
    defaultMessage: `A4 Paper (8.25" x 11.75")`
  }
});