import { RmtIntegrationActionTypes } from "../RmtReducer";
import { createActionCreator } from "common/ReduxUtils";
import { defineMessages } from "react-intl";
import { getSellerId } from "common/user/UserSelectors";
import { returnsClient } from "Clients";
export const setRmtState = rmtState => ({
  type: RmtIntegrationActionTypes.SET_RMT_STATE,
  ...rmtState
});
export const fetchRmtsAction = () => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const isLoading = state.returnsRmt.sellerRmts.loading;
  if (isLoading) {
    return;
  }
  dispatch({
    type: RmtIntegrationActionTypes.FETCH_RMTS_START
  });
  try {
    const sellerRmts = (await returnsClient.getRmtsBySellerId(sellerId))?.value;
    dispatch({
      type: RmtIntegrationActionTypes.FETCH_RMTS_SUCCESS,
      sellerRmts
    });
  } catch (err) {
    dispatch({
      type: RmtIntegrationActionTypes.FETCH_RMTS_ERROR
    });
  }
};
export const goToRmtStep = createActionCreator(RmtIntegrationActionTypes.GOTO_RMT_STEP, "stepId");
export const resetRmtState = () => dispatch => {
  dispatch(setRmtState({
    stepId: undefined,
    sellerRmts: undefined
  }));
};
export const RmtErrorMessages = defineMessages({
  getRmts: {
    id: "returns.error.getRmts",
    defaultMessage: "There was an issue getting your return platform integrations."
  },
  disconnectRmt: {
    id: "returns.error.disconnectRmt",
    defaultMessage: "There was an issue disconnecting your return platform."
  },
  connectRmt: {
    id: "returns.error.connectRmt",
    defaultMessage: "There was an issue connecting your return platform."
  },
  fetchRmtFailed: {
    id: "returns.rmt.fetch.error",
    defaultMessage: "Failed to fetch your return platform details."
  }
});