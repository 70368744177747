import { useIntl } from "react-intl";
import { useCostTableColumns } from "./useCostTableColumns";
import { useDeliverrRateSummary } from "../useDeliverrRateSummary";
import { useWeightUnitLabel } from "../useWeightUnitLabel";
import { useMemo } from "react";
import { commonMsgs } from "inbounds/utils/commonMsgs";
export const useShippingCostTable = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    estimatedRatesAmount,
    totalPackageCount,
    totalWeightLbs,
    shippingProviders
  } = useDeliverrRateSummary();
  const getWeightLabel = useWeightUnitLabel("lb");

  // labels
  const labels = useMemo(() => {
    const tableTitle = formatMessage({
      id: "inbounds.shipmentSetup.costTable.shipping.title",
      defaultMessage: "{shippingProviders} Shipping Costs"
    }, {
      shippingProviders: shippingProviders.join(", ")
    });
    const boxCountLabel = formatMessage(commonMsgs.box, {
      boxesQty: totalPackageCount
    });
    const shippingRowLabel = `${boxCountLabel}, ${getWeightLabel(totalWeightLbs)}`;
    const totalRowLabel = formatMessage({
      id: "inbounds.shipmentSetup.costTable.forwarding.totalRowLabel",
      defaultMessage: "Total shipping costs"
    });
    return {
      tableTitle,
      shippingRowLabel,
      totalRowLabel
    };
  }, [shippingProviders, totalPackageCount, totalWeightLbs, getWeightLabel, formatMessage]);
  const columns = useCostTableColumns({
    tableTitle: labels.tableTitle
  });
  const data = [{
    rowLabel: labels.shippingRowLabel,
    amount: estimatedRatesAmount ?? 0
  }];

  // TODO: @Jesse - label for priority shipping row
  // const priorityRowLabel = formatMessage({
  //   id: "inbounds.shipmentSetup.costTable.shipping.priorityRowLabel",
  //   defaultMessage: "Priority shipping",
  // });
  // TODO: @Jesse - conditional logic will be needed to add priority cost row here
  // if (priorityShipping) {
  // data.push({
  //   rowLabel: priorityRowLabel,
  //   amount: priorityCost,
  // });
  // }

  return {
    columns,
    data,
    aggregatorOptions: {
      totalRowLabel: labels.totalRowLabel
    },
    hideHeaders: ["quantity", "unitCost"]
  };
};