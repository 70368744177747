import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export class AvailabilityClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.AVAILABILITY_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getFastTagDaysLeft(dsku, sellerId) {
    return await this.apiClient.get({
      url: `/v2/fast-tags/${dsku}/${sellerId}`,
      authentication: isAuthenticated
    });
  }
}