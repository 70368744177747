import { FloorLoadedContainerType } from "@deliverr/prep-service-client";
export const getContainerLengthInFeet = containerType => {
  switch (containerType) {
    case FloorLoadedContainerType.FORTY_FT:
      return 40;
    case FloorLoadedContainerType.TWENTY_FT:
      return 20;
    default:
      return 0;
  }
};