export let WalmartError;
(function (WalmartError) {
  WalmartError["API_KEYS_NOT_FOUND"] = "API_KEYS_NOT_FOUND";
  WalmartError["INVALID_API_KEY"] = "INVALID_API_KEY";
  WalmartError["INVALID_API_PERMISSIONS"] = "INVALID_API_PERMISSIONS";
  WalmartError["INVALID_ORDER_ID"] = "INVALID_ORDER_ID";
  WalmartError["INVALID_URL"] = "INVALID_URL";
  WalmartError["LISTING_IS_MISSING_SKU"] = "LISTING_IS_MISSING_SKU";
  WalmartError["MSKU_NOT_FOUND"] = "MSKU_NOT_FOUND";
  WalmartError["MULTIPLE_VARIANTS"] = "MULTIPLE_VARIANTS";
  WalmartError["NON_DELIVERR_CREDENTIALS"] = "NON_DELIVERR_CREDENTIALS";
  WalmartError["NOT_FOUND"] = "NOT_FOUND";
  WalmartError["NOT_FOUND_IN_MARKETPLACE"] = "NOT_FOUND_IN_MARKETPLACE";
  WalmartError["ORDER_NOT_FOUND"] = "ORDER_NOT_FOUND";
})(WalmartError || (WalmartError = {}));