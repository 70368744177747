import { RmtErrorMessages, fetchRmtsAction, goToRmtStep } from "returns/rmt/store/action/RmtActions";
import { RmtId } from "@deliverr/returns-client";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { useDispatch, useSelector } from "react-redux";
import { ListingSolution } from "common/clients/onboarding/ListingSolution";
import { ReturnLoader } from "returns/ReturnsLoader";
import { RmtStepId } from "returns/rmt/store/RmtReducer";
import log from "Logger";
import { returnsClient } from "Clients";
import { toast } from "common/components/ui";
import { useIntegrationOAuthCallback } from "./useIntegrationOAuthCallback";
import { useIntl } from "react-intl";
export const useSetupRmtCardAmazon = sellerRmt => {
  const dispatch = useDispatch();
  const {
    formatMessage
  } = useIntl();
  const deliverrSellerId = useSelector(state => state.user.sellerId);
  useIntegrationOAuthCallback({
    async handleCreateIntegration(token, rmt) {
      dispatch(addLoader(ReturnLoader.ReturnsRmtLoader));
      try {
        if (!rmt) {
          await returnsClient.addNewRmtBySellerId(deliverrSellerId, {
            accessKeys: {
              apiToken: token
            }
          }, RmtId.AMAZON);
        } else {
          await returnsClient.updateRmtInfoBySellerIdAndRmtId(deliverrSellerId, {
            accessKeys: {
              apiToken: token
            },
            isActive: true
          }, RmtId.AMAZON);
        }
        dispatch(fetchRmtsAction());
        dispatch(goToRmtStep(RmtStepId.CONNECTED_RMT));
      } catch (err) {
        const ctx = {
          fn: "connectRmtOnSetup"
        };
        log.error({
          ...ctx,
          err
        }, `error connecting rmt : ${RmtId.AMAZON}`);
        const errorMessage = formatMessage(RmtErrorMessages.connectRmt);
        toast.error(errorMessage, {
          autoClose: 5000,
          toastId: "connectRmtOnSetup"
        });
      } finally {
        dispatch(clearLoader(ReturnLoader.ReturnsRmtLoader));
      }
    },
    rmt: sellerRmt
  });
  const formProps = {
    channel: ListingSolution.AMAZON,
    onSubmit() {
      window.location.assign(process.env.CONNECT_RETURNS_AMAZON_URL);
    }
  };
  return {
    formProps
  };
};