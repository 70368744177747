import { fromPairs } from "lodash/fp";
import { generateHumanReadableId } from "common/utils/readable/GenerateHumanReadableId";
import log from "Logger";
import { InboundActionTypes } from "../../InboundActionTypes";
import { addProducts } from "../../../InboundActions";
import { goToCreateInbound } from "inbounds/createShipment/store/actions/goToCreateInbound";
import { clearInbound } from "inbounds/createShipment/store/actions";
/* Create in-memory draft plan that won't be saved to the backend until products are selected */
export const createEcommFulfillmentInbound = ({
  selectedProducts,
  name,
  useCasePack,
  dskuToQty,
  skipRedirect,
  skipClearInbound
} = {}) => async dispatch => {
  const ctx = {
    fn: "createEcommFulfillmentInbound"
  };
  log.info({
    ...ctx,
    selectedProducts,
    useCasePack,
    dskuToQty,
    name
  }, "creating new inbound");
  const plan = {
    name: name ?? generateHumanReadableId(),
    useCasePack: useCasePack !== false
  };
  if (!skipClearInbound) {
    dispatch(clearInbound());
  }
  await dispatch({
    type: InboundActionTypes.CREATE_SHIPPING_PLAN,
    plan,
    products: selectedProducts ? fromPairs(selectedProducts.map(product => [product.dsku, product])) : {}
  });
  if (selectedProducts) {
    await dispatch(addProducts(selectedProducts, dskuToQty ?? {}));
  }
  if (!skipRedirect) {
    dispatch(goToCreateInbound({
      routeToProducts: true
    }));
  }
};