import { Path } from "paths";
import { ShipmentSteps } from "../CreateShipmentTypes";
import { supplyStepPathParams } from "./utils";
export const createShipmentInitialStep = ShipmentSteps.OriginSearch;

// define paths for each IPB step here
export const createShipmentStepToPath = {
  [ShipmentSteps.OriginSearch]: Path.inboundsIPBOrigin,
  [ShipmentSteps.OwnTransportationAddressStep]: Path.inboundsIPBOrigin,
  [ShipmentSteps.SupplierSelect]: Path.inboundsIPBSupplier,
  [ShipmentSteps.DestinationSelect]: Path.inboundsIPBFulfillment,
  [ShipmentSteps.DestinationSearch]: Path.inboundsIPBDirectDestination,
  [ShipmentSteps.ReserveStorageFcSelect]: Path.inboundsIPBReserveStorageLocation,
  [ShipmentSteps.CargoDetails]: Path.inboundsIPBCargoDetails,
  [ShipmentSteps.PackagingRequirements]: Path.inboundsIPBPackagingRequirements,
  [ShipmentSteps.DomesticFulfillmentSelect]: Path.inboundsIPBFulfillment,
  [ShipmentSteps.DomesticDestinationSelect]: Path.inboundsIPBFulfillmentDestination,
  [ShipmentSteps.PriceView]: Path.inboundsIPBPriceView
};
export const createShipmentInitialStepPath = createShipmentStepToPath[createShipmentInitialStep];
export const createShipmentNewPath = supplyStepPathParams(createShipmentInitialStepPath, null);
export const createShipmentStepPaths = Object.values(createShipmentStepToPath);