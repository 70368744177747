export let InventoryDetailActionTypes;
(function (InventoryDetailActionTypes) {
  InventoryDetailActionTypes["GET_PRODUCT_DETAIL_SUCCESS"] = "GET_PRODUCT_DETAIL_SUCCESS";
  InventoryDetailActionTypes["GET_PRODUCT_DETAIL_ERROR"] = "GET_PRODUCT_DETAIL_ERROR";
  InventoryDetailActionTypes["GET_INVENTORY_BY_WAREHOUSE_START"] = "GET_INVENTORY_BY_WAREHOUSE_START";
  InventoryDetailActionTypes["GET_INVENTORY_BY_WAREHOUSE_SUCCESS"] = "GET_INVENTORY_BY_WAREHOUSE_SUCCESS";
  InventoryDetailActionTypes["GET_INVENTORY_BY_WAREHOUSE_ERROR"] = "GET_INVENTORY_BY_WAREHOUSE_ERROR";
  InventoryDetailActionTypes["GET_FEE_PREVIEW"] = "GET_FEE_PREVIEW";
  InventoryDetailActionTypes["UPDATE_ESTIMATED_COSTS"] = "UPDATE_ESTIMATED_COSTS";
  InventoryDetailActionTypes["CREATE_WAREHOUSE_MARKERS"] = "CREATE_WAREHOUSE_MARKERS";
  InventoryDetailActionTypes["RESET_INVENTORY_DETAIL"] = "RESET_INVENTORY_DETAIL";
  InventoryDetailActionTypes["SET_SELECTED_UNITS"] = "SET_SELECTED_UNITS";
  InventoryDetailActionTypes["UPDATE_MSKU_SUCCESS"] = "UPDATE_MSKU_SUCCESS";
  InventoryDetailActionTypes["UPDATE_HAZMAT_SUCCESS"] = "UPDATE_HAZMAT_SUCCESS";
  InventoryDetailActionTypes["SET_SELECTED_SERVICE_LEVEL"] = "SET_SELECTED_SERVICE_LEVEL";
  InventoryDetailActionTypes["SET_SELECTED_COUNTRY_CODE"] = "SET_SELECTED_COUNTRY_CODE";
  InventoryDetailActionTypes["UPDATE_IS_FEFO_ENABLED"] = "UPDATE_IS_FEFO_ENABLED";
  InventoryDetailActionTypes["UPDATE_IS_RECALL_MANAGEMENT_ENABLED"] = "UPDATE_IS_RECALL_MANAGEMENT_ENABLED";
  InventoryDetailActionTypes["SET_KIT_INSTRUCTIONS"] = "SET_KIT_INSTRUCTIONS";
  InventoryDetailActionTypes["SET_KIT_TEXT_INSTRUCTIONS"] = "SET_KIT_TEXT_INSTRUCTIONS";
  InventoryDetailActionTypes["KIT_INSTRUCTIONS_LOADING"] = "KIT_INSTRUCTIONS_LOADING";
})(InventoryDetailActionTypes || (InventoryDetailActionTypes = {}));