import { useNewProductCasePackFormControllers } from "./useNewProductCasePackFormControllers";
import { useForm } from "react-hook-form";
import log from "Logger";
import { getNumberOrZeroFromString } from "common/utils/string/getNumberOrZeroFromString";
import { DimensionsError } from "inbounds/steps/dimensions/DimensionsAndWeightsTable/DimensionsError";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { getCaseDimensionsError } from "storage/inbounds/utils/getCaseDimensionsError";
import { FeatureName, useFeatureOn } from "common/Split";
export const newProductCasePackLoader = "newProductCasePackLoader";
export const useNewProductCasePackForm = (dsku, defaultValues = {
  packName: "",
  caseQty: "",
  caseLength: "",
  caseWidth: "",
  caseHeight: "",
  caseWeight: ""
}, onNewCasePackFormSubmit) => {
  const {
    formatMessage
  } = useIntl();
  const {
    control,
    setValue,
    formState: {
      isValid: isValidFormInput
    },
    handleSubmit
  } = useForm({
    mode: "onChange",
    defaultValues
  });
  const {
    packName,
    caseLength,
    caseHeight,
    caseWidth,
    caseWeight,
    caseQty
  } = useNewProductCasePackFormControllers(control);
  const caseQtyValue = caseQty?.field?.value;

  // Set packName dynamically
  useEffect(() => {
    if (!isEmpty(caseQtyValue)) {
      setValue("packName", `${caseQtyValue} ${getNumberOrZeroFromString(caseQtyValue) > 1 ? "units" : "unit"} per box`, {
        shouldValidate: true
      });
    }
  }, [setValue, caseQtyValue, formatMessage]);
  const isDimensionOverrideFeatureEnabled = useFeatureOn(FeatureName.InboundRsDimRelaxation);
  const dimensionsError = getCaseDimensionsError({
    length: getNumberOrZeroFromString(caseLength.field.value),
    width: getNumberOrZeroFromString(caseHeight.field.value),
    height: getNumberOrZeroFromString(caseWidth.field.value),
    weight: getNumberOrZeroFromString(caseWeight.field.value)
  }, isDimensionOverrideFeatureEnabled);
  const inapplicableErrors = [DimensionsError.INCOMPLETE];
  const packDimensionsError = dimensionsError && !inapplicableErrors.includes(dimensionsError) ? dimensionsError : undefined;
  const onFormSubmit = handleSubmit(async data => {
    const ctx = {
      fn: "useNewProductCasePackForm.onFormSubmit",
      ...data
    };
    log.info(ctx, "Submitting new case pack form");
    onNewCasePackFormSubmit?.({
      packOf: dsku,
      height: getNumberOrZeroFromString(data.caseHeight),
      length: getNumberOrZeroFromString(data.caseLength),
      width: getNumberOrZeroFromString(data.caseWidth),
      weight: getNumberOrZeroFromString(data.caseWeight),
      packName: data.packName,
      quantity: getNumberOrZeroFromString(data.caseQty),
      lengthUnit: "in",
      weightUnit: "lb"
    });
  });
  const isSubmitDisabled = !isValidFormInput || !!packDimensionsError;
  return {
    packName,
    caseLength,
    caseHeight,
    caseWidth,
    caseWeight,
    caseQty,
    isSubmitDisabled,
    onFormSubmit,
    newProductCasePackLoader,
    packDimensionsError
  };
};