import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { selectTransferSelectedProductsQty } from "transfers/create/store/selectTransferSelectedProductsQty";
import { getShipmentItems } from "./utils/getShipmentItems";
import { replenishmentClient } from "Clients";
import { isInvalidItemOrder } from "transfers/create/steps/common/replenishment/details/invalid-items/InvalidItemOrderUtils";
export const updateReplenishmentOrder = (orderId, submitDraft) => async (dispatch, getState) => {
  const state = getState();
  const {
    originStorageWarehouse,
    prepOption,
    selectedProductsLotNumbers,
    replenishmentOrder
  } = selectTransferCreate(state);
  let orderItems = [];
  if (isInvalidItemOrder(replenishmentOrder)) {
    orderItems = replenishmentOrder?.orderItems ?? [];
  } else {
    const selectedProductsQty = selectTransferSelectedProductsQty(state);
    orderItems = await dispatch(getShipmentItems(selectedProductsQty, originStorageWarehouse, selectedProductsLotNumbers));
  }
  const updateOrderRequest = {
    fulfillStrategy: replenishmentOrder?.fulfillStrategy,
    originWarehouseId: originStorageWarehouse?.warehouse?.id,
    orderItems,
    submitDraft,
    prepOption
  };
  await replenishmentClient.updateOrder(orderId, updateOrderRequest);
  const orderResponse = (await replenishmentClient.getOrder(orderId, {
    includeShipments: true,
    includeInvalidOrderItems: true
  })).value;
  return orderResponse;
};