import { createActionCreator } from "common/ReduxUtils";
import RouteParser from "common/RouteParser";
import { Path } from "paths";
import log from "Logger";
import { freightApiAdapter } from "common/clients/instances";
import { makeQuoteRequest } from "../utils/makeQuoteRequest";
import { freightClient } from "Clients";
export let FreightQuoteFormActionTypes;
(function (FreightQuoteFormActionTypes) {
  FreightQuoteFormActionTypes["UPDATE_FREIGHT_QUOTE_FORM"] = "UPDATE_FREIGHT_QUOTE_FORM";
  FreightQuoteFormActionTypes["REQUEST_FREIGHT_QUOTE"] = "REQUEST_FREIGHT_QUOTE";
  FreightQuoteFormActionTypes["REQUEST_FREIGHT_QUOTE_ERROR"] = "REQUEST_FREIGHT_QUOTE_ERROR";
  FreightQuoteFormActionTypes["REQUEST_FREIGHT_QUOTE_SUCCESS"] = "REQUEST_FREIGHT_QUOTE_SUCCESS";
})(FreightQuoteFormActionTypes || (FreightQuoteFormActionTypes = {}));
export const updateFreightQuoteForm = createActionCreator(FreightQuoteFormActionTypes.UPDATE_FREIGHT_QUOTE_FORM, "name", "value");
export const submitFreightQuoteRequest = () => async (dispatch, getState) => {
  dispatch({
    type: FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE
  });
  const state = getState();
  const internalUserId = state.user.email;
  const freightQuoteFormState = state.freightQuote.freightQuoteForm.form;
  const freightQuoteRequest = {
    ...freightQuoteFormState,
    internalUserId
  };
  const quoteRequest = makeQuoteRequest(freightQuoteFormState, internalUserId);
  const ctx = {
    fn: "requestFreightQuote",
    freightQuoteRequest
  };
  try {
    const {
      id: freightQuoteId
    } = await freightApiAdapter.generateFreightQuote(freightQuoteRequest);
    if (freightQuoteId) {
      log.info(ctx, "received requested freight quote successfuly");
      dispatch({
        type: FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_SUCCESS,
        freightQuoteId
      });
      setTimeout(() => window.open(new RouteParser(Path.freightQuoteSummary).parse({
        freightQuoteId
      }), "_blank"), 3000);
    }

    // Generate quotes in both freight-service and transportation-service during the transition stage
    await freightClient.generateFtlQuote(quoteRequest);
  } catch (err) {
    log.warn({
      ...ctx,
      err
    }, "error receiving requested freight quote");
    dispatch({
      type: FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_ERROR,
      reason: err.payload.data.payload.code
    });
  }
};
export const requestFreightQuoteSuccess = createActionCreator(FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_SUCCESS, "freightQuoteId");
export const requestFreightQuoteError = createActionCreator(FreightQuoteFormActionTypes.REQUEST_FREIGHT_QUOTE_ERROR, "reason");