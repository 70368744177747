import { ProblemType } from "common/problems/ProblemType";
import { getMappedSkuPairs } from "inbounds/steps/ship/view/cards/ShipmentProblems/spvUtils/getMappedSkuPairs";
export const getNonComplianceRowsFromNonComplianceProducts = (ncProducts, productCollection) => {
  const {
    sellerProblemId,
    status,
    barcode,
    updatedAt,
    shippingPlanId,
    shippingPlanName,
    reportedUnits,
    chargedUnits,
    costPerUnit,
    shipmentsCreatedAt,
    dsku,
    issueId,
    isLegacyCase,
    rawImages,
    affectedCdskus,
    createdAt
  } = ncProducts;
  return {
    id: sellerProblemId?.toString() ?? issueId,
    isLegacyCase,
    problemType: ProblemType.UNKNOWN_BARCODE,
    barcode: barcode,
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    shipmentsCreatedAt,
    status,
    units: chargedUnits ?? reportedUnits,
    mappedSkus: productCollection ? getMappedSkuPairs(ncProducts, productCollection) : dsku ? [{
      dsku: dsku,
      msku: "",
      productName: ""
    }] : [],
    perUnitCharge: costPerUnit ?? 0,
    affectedCdskus,
    // TODO: acknowledgedAt is not currently available, but we plan to begin setting this in a future iteration
    acknowledgedAt: new Date(),
    photos: rawImages ?? []
  };
};