import styled from "@emotion/styled";
import { LocalizedText } from "common/components/LocalizedText";
import { Box } from "common/components/ui";
const SMALL_FONT = "0.625rem";
export const StyledContainer = styled(Box)(({
  theme
}) => `
  padding: ${theme.spacing.S5} ${theme.spacing.S5} ${theme.spacing.S5} 0;
`);
export const Description = styled(Box)`
  max-width: 80%;
`;
export const Content = styled.div(({
  theme
}) => `
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.S4};
`);
export const DarkLocalizedText = styled(LocalizedText)(({
  theme
}) => `
  color: ${theme.colors.NEUTRAL[500]};
`);
export const PriceDescription = styled(LocalizedText)`
  font-size: ${SMALL_FONT};
`;
export const PriceContainer = styled.div(({
  theme
}) => `
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: ${theme.font.size.F2};
  max-width: 4.688rem;
`);
export const UndiscountedPrice = styled.span(({
  theme
}) => `
  text-decoration: line-through;
  color: ${theme.colors.NEUTRAL[300]};
`);
export const Tile = styled.div(({
  theme
}) => `
  border-radius: ${theme.border.radius.R4};
  border: 2px solid ${theme.colors.BLUE[300]};
  padding: ${theme.spacing.S5};
`);
export const PriceColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;
export const FlexportPlusMessage = styled.div(({
  theme
}) => `
  display: flex;
  align-items: center;
  color: ${theme.colors.LOGO_BLUE};
  gap: ${theme.spacing.S2};
  font-size: ${theme.font.size.F1};
  margin-top: ${theme.spacing.S2};
`);
export const CustomPriceLabel = styled(LocalizedText)`
  flex-shrink: 0;
  white-space: nowrap;
`;