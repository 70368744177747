import { castArray, find, flowRight, get, map } from "lodash/fp";
import { isShipToOneDispersalMethod } from "../InboundUtils";
export const getDisplayCdskus = packageId => ({
  inbound: {
    packages,
    loadedShipmentId
  }
}) => {
  const loadedPackages = packages[loadedShipmentId];
  if (packageId) {
    return flowRight(castArray, get("cdsku"), find(({
      id
    }) => id === packageId))(loadedPackages);
  } else {
    return map(get("cdsku"), loadedPackages);
  }
};
export const getReceivingInfo = state => state.inbound.receivingInfo[state.inbound.loadedShipmentId];
export const getDispersalMethod = state => state.inbound.plan.dispersalMethod;
export const getIsShipToOne = state => {
  const dispersalMethod = getDispersalMethod(state);
  return Boolean(dispersalMethod ? isShipToOneDispersalMethod(dispersalMethod) : state.inbound.plan.isForwarding);
};
export const getShipmentPackages = state => state.inbound.loadedShipmentId ? state.inbound.packages[state.inbound.loadedShipmentId] : [];