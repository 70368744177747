export let FacebookConnectActionTypes;
(function (FacebookConnectActionTypes) {
  FacebookConnectActionTypes["FACEBOOK_ACTIVATION_ERROR"] = "FACEBOOK_ACTIVATION_ERROR";
  FacebookConnectActionTypes["FACEBOOK_AD_ACCOUNTS_RECEIVED"] = "FACEBOOK_AD_ACCOUNTS_RECEIVED";
  FacebookConnectActionTypes["FACEBOOK_AD_ACCOUNT_SELECTED"] = "FACEBOOK_AD_ACCOUNT_SELECTED";
  FacebookConnectActionTypes["FACEBOOK_LOGIN_STATUS_RECEIVED"] = "FACEBOOK_LOGIN_STATUS_RECEIVED";
  FacebookConnectActionTypes["FACEBOOK_PAGES_RECEIVED"] = "FACEBOOK_PAGES_RECEIVED";
  FacebookConnectActionTypes["FACEBOOK_PAGE_SELECTED"] = "FACEBOOK_PAGE_SELECTED";
  FacebookConnectActionTypes["FACEBOOK_PERMISSIONS_CHECKED"] = "FACEBOOK_PERMISSIONS_CHECKED";
  FacebookConnectActionTypes["FACEBOOK_SELLER_REGISTRATION_COMPLETE"] = "FACEBOOK_SELLER_REGISTRATION_COMPLETE";
  FacebookConnectActionTypes["FACEBOOK_INSTAGRAM_ADS_TOGGLE_SET"] = "FACEBOOK_INSTAGRAM_ADS_SET";
  FacebookConnectActionTypes["FACEBOOK_INSTAGRAM_ACCOUNTS_RECEIVED"] = "FACEBOOK_INSTAGRAM_ACCOUNTS_RECEIVED";
  FacebookConnectActionTypes["FACEBOOK_INSTAGRAM_ACCOUNT_SELECTED"] = "FACEBOOK_INSTAGRAM_ACCOUNT_SELECTED";
  FacebookConnectActionTypes["FACEBOOK_PROFILE_INFO_RECIEVED"] = "FACEBOOK_PROFILE_INFO_RECIEVED";
  FacebookConnectActionTypes["FACEBOOK_PIXELS_RECEIVED"] = "FACEBOOK_PIXELS_RECEIVED";
  FacebookConnectActionTypes["FACEBOOK_PIXEL_SELECTED"] = "FACEBOOK_PIXEL_SELECTED";
  FacebookConnectActionTypes["FACEBOOK_AD_SETS_CONFIRMATION_TOGGLE"] = "FACEBOOK_AD_SETS_CONFIRMATION_TOGGLE";
  FacebookConnectActionTypes["FACEBOOK_AD_SET_CHECK_COMPLETED"] = "FACEBOOK_AD_SET_CHECK_COMPLETED";
  FacebookConnectActionTypes["FACEBOOK_GENERATING_AD_SETS"] = "FACEBOOK_GENERATING_AD_SETS";
})(FacebookConnectActionTypes || (FacebookConnectActionTypes = {}));