import log from "Logger";

// from https://developers.heap.io/reference/adduserproperties
export const addHeapUserProperties = properties => {
  const addUserProperties = window.heap?.addUserProperties?.bind(window.heap);
  if (!addUserProperties) {
    log.warn({
      fn: "addHeapUserProperties"
    }, "heap addUserProperties not available");
    return;
  }
  addUserProperties(properties);
};