import { toast } from "common/components/ui";
import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useMount } from "react-use";
const OnboardingErrorMessages = defineMessages({
  DEFAULT_CONNECTION_ERROR: {
    id: "returns.oauth.errorDEFAULT_CONNECTION_ERROR",
    defaultMessage: "We were unable to complete this integration. Please verify your credentials and the integration requirements and try again."
  }
});
export const useAmazonOAuthError = () => {
  const {
    formatMessage
  } = useIntl();
  const queryParams = new URLSearchParams(useLocation().search);
  const error = queryParams.get("err");
  useMount(() => {
    if (error) {
      const message = formatMessage(OnboardingErrorMessages.DEFAULT_CONNECTION_ERROR);
      toast.error(message, {
        toastId: "oauth.error",
        autoClose: false
      });
    }
  });
};