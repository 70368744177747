import { inventoryClient } from "common/clients/instances";
import { getBundleAvailableQty } from "./getBundleAvailableQty";
const getUniqueProductIds = bundles => {
  const set = bundles.reduce((acc, {
    bundleItems = []
  }) => {
    for (const {
      dsku
    } of bundleItems) {
      acc.add(dsku);
    }
    return acc;
  }, new Set());
  return Array.from(set);
};
/**
 * Given a list of bundles, fetches a map of each bsku to its available quantity.
 */

export const fetchBskuToAvailableQty = async bundles => {
  const productIds = getUniqueProductIds(bundles);
  const productATPs = await inventoryClient.getATP(productIds);
  const bskuToAvailableQty = bundles.reduce((acc, bundle) => {
    acc[bundle.bsku] = getBundleAvailableQty(bundle, productATPs);
    return acc;
  }, {});
  return bskuToAvailableQty;
};