import { BarcodeFormat, BarcodeFormatErrors } from "common/components/ui/utils/BarcodeUtils";
import { barcodeFormatToDisplay, unknownBarcodeDisplay } from "common/BarcodeOptions";
import { find } from "lodash";
import { isEmpty, map, pipe, reject } from "lodash/fp";
import { useEffect, useMemo, useReducer } from "react";
import { getBarcodesDraft, updateBarcodesDraft } from "./BarcodeDraft";
import { BarcodeActionTypes, barcodeDefaultState, barcodeEditReducer } from "./BarcodeEditReducer";
import { createDuplicatedBarcodeResponse } from "./serializer";
import { useBarcodeValidateService } from "./useBarcodeValidateService";
import { FeatureName, useFeatureOn } from "common/Split";
import { useMount } from "react-use";
export default function useBarcodeEdit(props) {
  const allowsCustomBarcodes = useFeatureOn(FeatureName.CustomBarcodes);
  const [state, dispatch] = useReducer(barcodeEditReducer, barcodeDefaultState);
  const hasSelectedDSKU = state.barcodesCollection && find(state.barcodesCollection, ["format", BarcodeFormat.DSKU]);
  const {
    validationResult,
    runServiceValidation
  } = useBarcodeValidateService();
  const barcodeOptions = useMemo(() => {
    const options = Object.values(BarcodeFormat).filter(format => !isEmpty(format) && format !== BarcodeFormat.PONUM).map(format => ({
      value: format,
      label: barcodeFormatToDisplay[format]
    }));
    if (allowsCustomBarcodes) {
      options.push({
        value: BarcodeFormatErrors.UNKNOWN,
        label: unknownBarcodeDisplay
      });
    }
    return options;
  }, [allowsCustomBarcodes]);
  const validateBarcode = index => dispatch({
    type: BarcodeActionTypes.VALIDATE_BARCODE,
    index
  });
  const handleAddBarcode = event => {
    event.preventDefault();
    dispatch({
      type: BarcodeActionTypes.ADD_NEW_BARCODE
    });
  };
  const handleRemoveBarcode = index => event => {
    event.preventDefault();
    dispatch({
      type: BarcodeActionTypes.REMOVE_BARCODE,
      index
    });
  };
  const checkDuplicatesAcrossProducts = async () => {
    // check for duplicates across products
    await runServiceValidation(pipe(reject("locked"), map("value"))(state.barcodesCollection));
  };
  const handleBarcodeValidation = index => async event => {
    // we just ignore empty fields and add filled ones for better usability
    // error message for this case doesn't make sense since block users from adding barcodes
    validateBarcode(index);
    await checkDuplicatesAcrossProducts();
  };
  const handleBarcodeChange = index => event => {
    const {
      value: barcode
    } = event.currentTarget;
    dispatch({
      type: BarcodeActionTypes.UPDATE_BARCODE,
      barcode,
      index
    });
  };
  const handleBarcodeFormatChange = index => async ({
    value
  }) => {
    dispatch({
      type: BarcodeActionTypes.UPDATE_BARCODE_FORMAT,
      barcodeFormat: value,
      dsku: props.product.dsku,
      index
    });
    if (value === BarcodeFormat.DSKU) {
      // set DSKU as valid since it always has value
      validateBarcode(index);
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    } else if (state.barcodesCollection && state.barcodesCollection[index].value.length) {
      validateBarcode(index);
      await checkDuplicatesAcrossProducts();
    }
  };
  useEffect(() => {
    const {
      barcodes
    } = props.product;
    if (barcodes) {
      dispatch({
        type: BarcodeActionTypes.LOAD_BARCODES,
        barcodes
      });
    }
    const barcodeDraft = getBarcodesDraft(props.product.dsku);
    const draftActionPayload = {
      type: BarcodeActionTypes.LOAD_BARCODES_DRAFT,
      barcodeDraft
    };
    dispatch(draftActionPayload);
    if (!barcodes && isEmpty(barcodeDraft) || props.preAddBarcode) {
      dispatch({
        type: BarcodeActionTypes.ADD_NEW_BARCODE
      });
    }
  }, [props.product, props.preAddBarcode]);
  useMount(() => {
    props.plannedBarcodes?.forEach(plannedBarcode => {
      dispatch({
        type: BarcodeActionTypes.ADD_PLANNED_BARCODE,
        barcode: plannedBarcode
      });
      dispatch({
        type: BarcodeActionTypes.VALIDATE_BARCODE,
        barcode: plannedBarcode.barcode
      });
    });
  });
  useEffect(() => {
    if (state.barcodesCollection) {
      props.onChange({
        [props.product.dsku]: state.barcodesCollection
      });
      updateBarcodesDraft(props.product.dsku, state.barcodesCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.barcodesCollection]);
  useEffect(() => {
    if (props.serviceError) {
      dispatch({
        type: BarcodeActionTypes.BARCODE_SERVICE_ERROR,
        response: props.serviceError
      });
    }
  }, [props.serviceError]);
  useEffect(() => {
    if (validationResult?.value?.length) {
      dispatch({
        type: BarcodeActionTypes.BARCODE_SERVICE_ERROR,
        response: createDuplicatedBarcodeResponse(validationResult.value)
      });
    }
  }, [validationResult]);
  return {
    barcodeOptions,
    hasSelectedDSKU,
    handleBarcodeChange,
    handleRemoveBarcode,
    handleBarcodeValidation,
    handleAddBarcode,
    handleBarcodeFormatChange,
    ...state
  };
}