import { BarcodeResponseCode, BarcodeResponseSubcode } from "@deliverr/commons-clients";
import { removeAllEmTags } from "common/StringUtils";
import { filter, find, keys } from "lodash";
export const serializeBarcodes = (dsku, barcodesCollection) => barcodesCollection ? {
  [dsku]: barcodesCollection.filter(({
    locked,
    value
  }) => !locked && value !== "").map(({
    value
  }) => removeAllEmTags(value))
} : null;
export const bulkSerializeBarcodes = barcodeCollections => Object.keys(barcodeCollections).reduce((accumulator, dsku) => {
  const serializedBarcodeList = serializeBarcodes(dsku, barcodeCollections[dsku]) ?? {};
  return serializedBarcodeList[dsku]?.length ? {
    ...accumulator,
    ...serializedBarcodeList
  } : accumulator;
}, {});
const barcodeSaveDefaultValue = {};
export const serializeBarcodeServiceErrors = barcodeSubmitResult => {
  const {
    value = barcodeSaveDefaultValue,
    loading: isLoading
  } = barcodeSubmitResult;
  if (isLoading || value === true) {
    return barcodeSaveDefaultValue;
  }
  const listOfKeys = keys(value);
  return filter(listOfKeys, dsku => find(value[dsku], ["code", BarcodeResponseCode.ERROR])).reduce((response, dsku) => {
    response[dsku] = value[dsku];
    return response;
  }, {});
};
export const createDuplicatedBarcodeResponse = serviceResponse => serviceResponse.map(response => ({
  barcode: response.barcode,
  code: BarcodeResponseCode.ERROR,
  subcode: BarcodeResponseSubcode.DUPLICATE
}));