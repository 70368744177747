import { ProblemType } from "common/problems/ProblemType";
import { getIsDamagedProductDisposed } from "./getIsDamagedProductDisposed";
import { getDamagedProductStatus } from "./getDamagedProductStatus";
import { getMappedSkuPairs } from "inbounds/steps/ship/view/cards/ShipmentProblems/spvUtils/getMappedSkuPairs";
export const getNonComplianceRowsFromDamagedProductCases = (damageCase, productCollection) => {
  const {
    sellerProblemId,
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    dsku,
    reportedUnits,
    isDisposed,
    damageFault,
    retailUnitPrice,
    reimbursementUnitCost,
    disputeTicketCount,
    disputeOpenedAt,
    disputeClosedAt,
    disputeAutoResolvedAt,
    damageType,
    rawImages,
    issueId,
    isLegacyCase,
    affectedCdskus
  } = damageCase;
  return {
    id: sellerProblemId?.toString() ?? issueId,
    isLegacyCase,
    problemType: ProblemType.DAMAGED_PRODUCT,
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    status: getDamagedProductStatus(damageCase),
    units: reportedUnits,
    mappedSkus: productCollection ? getMappedSkuPairs(damageCase, productCollection) : [{
      dsku: dsku,
      msku: "",
      productName: ""
    }],
    isDisposed: getIsDamagedProductDisposed(isDisposed, createdAt),
    damageFault: damageFault,
    retailUnitPrice,
    reimbursementUnitCost: reimbursementUnitCost ?? 0,
    disputeTicketCount: disputeTicketCount ?? 0,
    disputeOpenedAt,
    disputeClosedAt,
    disputeAutoResolvedAt: disputeAutoResolvedAt ?? undefined,
    damageType: damageType,
    damagedQty: reportedUnits ?? 0,
    photos: rawImages,
    affectedCdskus,
    // TODO: acknowledgedAt is not currently available, but we plan to begin setting this in a future iteration
    acknowledgedAt: new Date()
  };
};