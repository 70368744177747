/**
 * @generated SignedSource<<9f4dba76ec08a581f9d453e2a693228c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

const node = function () {
  var v0 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbid",
      "storageKey": null
    },
    v1 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    v2 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    v3 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    v4 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    v5 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "admin",
      "storageKey": null
    },
    v6 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fid",
      "storageKey": null
    },
    v7 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display_smb_onboarding",
      "storageKey": null
    },
    v8 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed_smb_company_details_onboarding",
      "storageKey": null
    },
    v9 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitted_smb_company_details_onboarding",
      "storageKey": null
    },
    v10 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed_smb_authorization_onboarding",
      "storageKey": null
    },
    v11 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitted_smb_authorization_onboarding",
      "storageKey": null
    },
    v12 = {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "Client",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [v6 /*: any*/],

        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "CompanyEntity",
        "kind": "LinkedField",
        "name": "primary_entity",
        "plural": false,
        "selections": [v0 /*: any*/, v7 /*: any*/, v8 /*: any*/, v9 /*: any*/, v10 /*: any*/, v11 /*: any*/],

        "storageKey": null
      }],
      "storageKey": null
    },
    v13 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    v14 = {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "Client",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [v6 /*: any*/, v13 /*: any*/],

        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "CompanyEntity",
        "kind": "LinkedField",
        "name": "primary_entity",
        "plural": false,
        "selections": [v0 /*: any*/, v7 /*: any*/, v8 /*: any*/, v9 /*: any*/, v10 /*: any*/, v11 /*: any*/, v13 /*: any*/],

        "storageKey": null
      }, v13 /*: any*/],

      "storageKey": null
    };
  return {
    "fragment": {
      "argumentDefinitions": [],
      "kind": "Fragment",
      "metadata": null,
      "name": "useFetchFlexportUserQuery",
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/, v12 /*: any*/, {
          "alias": null,
          "args": null,
          "concreteType": "ImpersonatedUser",
          "kind": "LinkedField",
          "name": "impersonatedUser",
          "plural": false,
          "selections": [v6 /*: any*/, v1 /*: any*/, v4 /*: any*/, v12 /*: any*/],

          "storageKey": null
        }],
        "storageKey": null
      }],
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": [],
      "kind": "Operation",
      "name": "useFetchFlexportUserQuery",
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/, v14 /*: any*/, {
          "alias": null,
          "args": null,
          "concreteType": "ImpersonatedUser",
          "kind": "LinkedField",
          "name": "impersonatedUser",
          "plural": false,
          "selections": [v6 /*: any*/, v1 /*: any*/, v4 /*: any*/, v14 /*: any*/],

          "storageKey": null
        }, v13 /*: any*/],

        "storageKey": null
      }]
    },
    "params": {
      "cacheID": "9a8bf11c180fcc82753384dff800dee5",
      "id": null,
      "metadata": {},
      "name": "useFetchFlexportUserQuery",
      "operationKind": "query",
      "text": "query useFetchFlexportUserQuery {\n  current_user {\n    dbid\n    name\n    first_name\n    last_name\n    email\n    admin\n    company {\n      __typename\n      client {\n        fid\n        id\n      }\n      primary_entity {\n        dbid\n        display_smb_onboarding\n        completed_smb_company_details_onboarding\n        submitted_smb_company_details_onboarding\n        completed_smb_authorization_onboarding\n        submitted_smb_authorization_onboarding\n        id\n      }\n      id\n    }\n    impersonatedUser {\n      fid\n      name\n      email\n      company {\n        __typename\n        client {\n          fid\n          id\n        }\n        primary_entity {\n          dbid\n          display_smb_onboarding\n          completed_smb_company_details_onboarding\n          submitted_smb_company_details_onboarding\n          completed_smb_authorization_onboarding\n          submitted_smb_authorization_onboarding\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
    }
  };
}();
node.hash = "94dd9c3f45d6760f8679ad0e1f4f048a";
export default node;