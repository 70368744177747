import { checkIfPrepRequestHasAllSpecifiedPrepTypes } from "prep/util/checkIfPrepRequestHasAllSpecifiedPrepTypes";
import { createSelector } from "reselect";
import { selectHasPrep } from "prep/store/selectors/selectHasPrep";
import { selectPrepRequest } from "prep/store/selectors/selectPrepRequest";

/**
 * Check if the plan has Prep, including all specified PrepTypes
 */
export const selectHasAllSpecifiedPrepTypes = createSelector(selectHasPrep, selectPrepRequest, (hasPrep, prepRequest) => (...prepTypes) => {
  return hasPrep && checkIfPrepRequestHasAllSpecifiedPrepTypes(prepRequest, ...prepTypes);
});