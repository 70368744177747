import { useController } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { useEffectOnce } from "react-use";
import { useSimpleFieldValidators } from "common/hooks/reactHookForm/useSimpleFieldValidators";
const INPUT_NAME = CreateShipmentInputName.HAS_DANGEROUS_GOODS;
export let DangerousGoodsError;
(function (DangerousGoodsError) {
  DangerousGoodsError["NOT_DECLARED"] = "NOT_DECLARED";
  DangerousGoodsError["NOT_ALLOWED"] = "NOT_ALLOWED";
})(DangerousGoodsError || (DangerousGoodsError = {}));
const DANGEROUS_GOODS_RADIO_LABELS = defineMessages({
  hasDangerousGoods: {
    id: "inbounds.createShipment.dangerousGoods.hasDangerousGoods",
    defaultMessage: "Yes, my shipment contains Dangerous Goods"
  },
  noDangerousGoods: {
    id: "inbounds.createShipment.dangerousGoods.noDangerousGoods",
    defaultMessage: "No, my shipment does not contain Dangerous Goods"
  }
});
export const useDangerousGoodsToggle = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    trigger
  } = useCreateShipmentFormContext();
  const {
    validateRequired
  } = useSimpleFieldValidators();
  const validateAllowed = value => {
    return !value;
  };

  // register field
  const {
    field,
    fieldState
  } = useController({
    name: INPUT_NAME,
    rules: {
      validate: {
        // using the built-in "required" rule would return an error when the value is false
        [DangerousGoodsError.NOT_DECLARED]: validateRequired(formatMessage({
          id: "reactHookForm.validators.required",
          defaultMessage: "Please declare whether or not your shipment contains dangerous goods."
        })),
        [DangerousGoodsError.NOT_ALLOWED]: validateAllowed
      }
    }
  });

  // trigger validation if value is true on mount in case the user has gone back and selected an invalid fulfillment type
  useEffectOnce(() => {
    if (field.value) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger(INPUT_NAME);
    }
  });
  const options = [["hasDangerousGoods", true], ["noDangerousGoods", false]].map(([id, value]) => {
    return {
      id,
      label: formatMessage(DANGEROUS_GOODS_RADIO_LABELS[id]),
      name: field.name,
      onChange: () => field.onChange(value),
      checked: field.value === value
    };
  });
  return {
    options,
    error: fieldState.error,
    hasDangerousGoods: field.value
  };
};