import { combineReducers } from "redux";
import { internationalShippingInitialState, internationalShippingReducer } from "settings/InternationalShipping/InternationalShippingReducer";
import { shippingOptionInitialState, shippingOptionReducer } from "settings/shipping-options/ServiceOptionReducer";
import { initialInventoryState, inventoryReducer } from "settings/inventory/InventoryReducer";
import { referralReducer, initialReferralState } from "./Referrals/ReferralReducers";
export const settingsReducer = combineReducers({
  shippingOptionMapping: shippingOptionReducer,
  internationalShipping: internationalShippingReducer,
  referrals: referralReducer,
  inventory: inventoryReducer
});
export const initialSettingsData = {
  shippingOptionMapping: shippingOptionInitialState,
  internationalShipping: internationalShippingInitialState,
  referrals: initialReferralState,
  inventory: initialInventoryState
};