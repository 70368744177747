import { getCountries, getCountryCallingCode, parsePhoneNumber } from "libphonenumber-js";
import { isEmpty, memoize, uniq } from "lodash/fp";

// Country Code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
const parseAndFormatPhone = (countryCode, format, phoneNumber) => {
  if (phoneNumber === "") {
    return "";
  }
  if (!phoneNumber) {
    return undefined;
  }
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber, countryCode);
    return parsedNumber.format(format);
  } catch (e) {
    return phoneNumber;
  }
};

/**
 * Attempts to format phone number in national format, e.g. "(555) 123-4567".
 * @param phoneNumber Phone number, e.g. "+1 (555) 123-4567". Ideally, you'll have validated this first.
 * @param countryCode Country code (optional)
 * @returns Formatted phone number, when input is parsable. Original unformatted value, otherwise.
 */
export const formatPhoneNational = (phoneNumber, countryCode) => parseAndFormatPhone(countryCode, "NATIONAL", phoneNumber);

/**
 * Formats phone number in E.164 format (e.g. "+15551234567") that is expected
 * by Cognito and should be preferred for our own services and databases.
 * @param phoneNumber Phone number, e.g. "+1 (555) 123-4567". Ideally, you'll have validated this first.
 * @param countryCode Country code (optional)
 * @returns Phone number in E164 format, when input is parsable.
 */
export const formatPhoneE164 = (phoneNumber, countryCode) => parseAndFormatPhone(countryCode, "E.164", phoneNumber);
/**
 * Attempts to format phone number in international format, e.g. "+1 (555) 123-4567".
 * @param phoneNumber Phone number, e.g. "+1 (555) 123-4567". Ideally, you'll have validated this first.
 * @param countryCode Country code (optional)
 * @returns Formatted phone number, when input is parsable. Original unformatted value, otherwise.
 */
export const formatPhoneInternational = (phoneNumber, countryCode) => {
  const formattedNumber = parseAndFormatPhone(countryCode, "INTERNATIONAL", phoneNumber);
  if (formattedNumber?.startsWith("+1 ")) {
    // libphonenumber-js formats all international phone numbers without hyphens even if the country code is US
    // https://gitlab.com/catamphetamine/libphonenumber-js#difference-from-googles-libphonenumber
    return `+1 ${formattedNumber.slice(3).split(" ").join("-")}`;
  }
  return formattedNumber;
};
const getCountryCodes = memoize(() => uniq(getCountries().map(country => `+${getCountryCallingCode(country)}`)));

/**
 * Returns whether a phone number is empty. This definition of empty includes an empty string, but also
 * values such as "+1" where a country code has been selected the phone number was left empty.
 */
export const isEmptyPhoneNumber = phoneNumber => {
  return isEmpty(phoneNumber) || getCountryCodes().includes(phoneNumber);
};

/**
 * Returns whether a phone number is valid.
 * @param phoneNumber Parsable phone number, e.g. "+1 (555) 123-4567"
 * @param options Options, such as whether to perform strict validation
 * @returns Boolean indicating whether phone number is valid
 */
export const isValidPhoneNumber = (phoneNumber, options) => {
  const {
    countryCode,
    isStrict = false,
    shouldAllowEmpty = false
  } = options ?? {};
  if (shouldAllowEmpty && isEmptyPhoneNumber(phoneNumber)) {
    return true;
  }
  try {
    const number = parsePhoneNumber(phoneNumber, countryCode);
    const isTooShortForUS = number.countryCallingCode === "1" && number.nationalNumber.length < 10;
    const isAll8s = number.countryCallingCode === "1" && number.nationalNumber === "8888888888";
    return (isStrict ? number.isValid() && !isAll8s : number.isPossible()) && !isTooShortForUS;
  } catch {
    return false;
  }
};

/**
 * Returns whether a phone number is valid.
 * @param phoneNumber Parsable phone number, e.g. "+1 (555) 123-4567", "(555) 123-4567"
 * @param options Options, such as whether to perform strict validation
 * @returns Boolean indicating whether phone number is valid
 */
export const isValidFreightPhoneNumber = (phoneNumber, regionCode = "US") => {
  const parsedNumber = parsePhoneNumber(phoneNumber, regionCode);
  return parsedNumber.isValid();
};