import styled from "@emotion/styled";
import { Divider, Text } from "common/components/ui";
export const ReplenishmentErrorContainer = styled.div(({
  theme
}) => `
    text-align: left;
    width: 100%;
    padding-left: ${theme.spacing.S4};
    padding-bottom: ${theme.spacing.S5};
`);
export const ReplenishmentErrorTitle = styled(Text)(({
  theme
}) => `
    font-size: ${theme.font.size.F3};
    font-weight: ${theme.font.weight.BOLD};
    margin-bottom: ${theme.spacing.S3};
`);
export const LightDivider = styled(Divider)(({
  theme
}) => `
    border-color: ${theme.colors.NEUTRAL["60"]};
    margin: ${theme.spacing.S4} 0;
    border-width: ${theme.border.width.B1} 0 0 0;
`);