export const mapSkuCaseQtiesToSelectedProducts = (skuCaseQties, productDetails, storageProductsInventory, productEcomInventory) => {
  return (skuCaseQties ?? []).reduce((map, {
    dsku,
    qty,
    caseQty
  }) => {
    const safeCaseQty = caseQty ?? productDetails?.[dsku]?.caseQty ?? 1;
    const packOf = productDetails?.[dsku]?.packOf ?? dsku;
    map[dsku] = {
      ...productDetails?.[dsku],
      qty: qty || 0,
      caseQty: safeCaseQty,
      numberOfCases: Number(qty || 0) / safeCaseQty,
      storageOnHandQty: storageProductsInventory?.[dsku]?.onHandQty,
      storageNonPickableQty: storageProductsInventory?.[dsku]?.nonPickableQty,
      storageUnfilledOrderQty: storageProductsInventory?.[dsku]?.unfilledOrderQty,
      storageInTransferQty: storageProductsInventory?.[dsku]?.inTransferQty,
      storageUnavailableQty: storageProductsInventory?.[dsku]?.unavailableQty,
      ...(!!packOf && {
        onHandQty: productEcomInventory?.[packOf]?.onHandQty,
        nonPickableQty: productEcomInventory?.[packOf]?.nonPickableQty,
        unfilledOrderQty: productEcomInventory?.[packOf]?.unfilledOrderQty,
        inTransferQty: productEcomInventory?.[packOf]?.inTransferQty,
        unavailableQty: productEcomInventory?.[packOf]?.unavailableQty,
        unitsSoldLast30Days: productEcomInventory?.[packOf]?.unitsSoldLast30Days
      })
    };
    return map;
  }, {});
};