import { ApiError, DeliverrError } from "@deliverr/commons-objects";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export class S3FileHandlerClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.NON_COMPLIANCE_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async getS3File(filePath) {
    try {
      return await this.apiClient.get({
        url: "/non-compliance/s3/download",
        params: {
          filePath
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw await this.handleError("getS3File", err);
    }
  }
  handleError(functionName, err) {
    if (!err.response) {
      throw new DeliverrError({
        code: ApiError.UNKNOWN_ERROR,
        functionName,
        payload: err
      });
    }
    if (err.response?.status === 504) {
      throw new DeliverrError({
        code: ApiError.NETWORK_ERROR,
        functionName,
        payload: err
      });
    }
    throw new DeliverrError({
      code: err.response?.data?.code,
      subcode: err.response?.data?.error?.subcode,
      functionName,
      payload: err
    });
  }
}