import { getAndSetReplenishmentOrderHistoryState } from "transfers/create/actions/replenishmentOrder/getAndSetReplenishmentOrderHistoryState";
import { getAndSetReplenishmentOrderState } from "transfers/create/actions/replenishmentOrder/getAndSetReplenishmentOrderState";
import { getAndSetReplenishmentOrderShipmentAttachmentState } from "transfers/create/actions/replenishmentOrder/getAndSetReplenishmentOrderShipmentAttachmentState";
import { fetchAndSetOrderCosts } from "transfers/detail/store/replenishment/fetchAndSetOrderCosts";
import { OrderStatus } from "@deliverr/replenishment-client";
export const getReplenishmentOrder = orderId => async dispatch => {
  const replenishmentOrder = await getAndSetReplenishmentOrderState(orderId, dispatch);
  ![OrderStatus.DRAFT, OrderStatus.CREATED].includes(replenishmentOrder.orderStatus) && (await dispatch(fetchAndSetOrderCosts(orderId)));
  /* eslint-disable  @typescript-eslint/no-floating-promises */
  getAndSetReplenishmentOrderHistoryState(replenishmentOrder.orderId, dispatch);
  if (replenishmentOrder?.shipments) {
    getAndSetReplenishmentOrderShipmentAttachmentState(replenishmentOrder?.shipments, dispatch);
  }
};