import { OpenSearchService } from "common/search/services/OpenSearchService";
export class ClaimUploadsSearchService extends OpenSearchService {
  buildSearchOptions(request) {
    const searchOptions = super.buildSearchOptions(request);
    const shouldQueries = [];
    if (request.searchTerm) {
      shouldQueries.push({
        query_string: {
          query: `filename: *${request.searchTerm}*`,
          analyze_wildcard: true
        }
      });
    }
    return {
      ...searchOptions,
      query: {
        bool: {
          should: shouldQueries,
          minimum_should_match: 1
        }
      },
      hydrate: true
    };
  }
}