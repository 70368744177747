export let PickupDetailsActionTypes;
(function (PickupDetailsActionTypes) {
  PickupDetailsActionTypes["SET_PICKUP_APPOINTMENT_REQUIRED"] = "SET_PICKUP_APPOINTMENT_REQUIRED";
  PickupDetailsActionTypes["SET_CARGO_READY_DATE"] = "SET_CARGO_READY_DATE";
  PickupDetailsActionTypes["SET_PICKUP_REFERENCE_NUMBER"] = "SET_PICKUP_REFERENCE_NUMBER";
  PickupDetailsActionTypes["SET_CUSTOMER_REFERENCE_NUMBER"] = "SET_PICKUP_CUSTOMER_REFERENCE_NUMBER";
  PickupDetailsActionTypes["SET_PICKUP_FACILITY_OPERATING_HOURS"] = "SET_PICKUP_FACILITY_OPERATING_HOURS";
  PickupDetailsActionTypes["SET_PICKUP_ADDRESS"] = "SET_PICKUP_ADDRESS";
  PickupDetailsActionTypes["SET_PICKUP_INSTRUCTIONS"] = "SET_PICKUP_INSTRUCTIONS";
  PickupDetailsActionTypes["SET_PICKUP_COMPANY_NAME"] = "SET_PICKUP_COMPANY_NAME";
  PickupDetailsActionTypes["SET_PICKUP_CONTACT"] = "SET_PICKUP_CONTACT";
})(PickupDetailsActionTypes || (PickupDetailsActionTypes = {}));