import { addDays } from "date-fns";

// DATES
export const EARLIEST_PICKUP_OFFSET = 1;
export const LATEST_PICKUP_OFFSET = 14;
export const EARLIEST_PICKUP_DATE = addDays(new Date(), EARLIEST_PICKUP_OFFSET);
export const LATEST_PICKUP_DATE = addDays(new Date(), LATEST_PICKUP_OFFSET);

// PALLETS
export const LTL_PALLET_INITIAL_WIDTH = 48;
export const LTL_PALLET_INITIAL_LENGTH = 40;

// CONFIGS
export const LTL_TOAST_CONFIG = {
  autoClose: 5000
};
export let LtlInstructionsCard;
(function (LtlInstructionsCard) {
  LtlInstructionsCard["PACKING_LIST"] = "PACKING_LIST";
  LtlInstructionsCard["FREIGHT_PALLETS"] = "FREIGHT_PALLETS";
  LtlInstructionsCard["PICKUP_DETAILS"] = "PICKUP_DETAILS";
  LtlInstructionsCard["SHIPPING_OPTION"] = "SHIPPING_OPTION";
  LtlInstructionsCard["REVIEW_QUOTE"] = "REVIEW_QUOTE";
  LtlInstructionsCard["BILL_OF_LADING"] = "BILL_OF_LADING";
  LtlInstructionsCard["SHIPMENT_CONFIRMATION"] = "SHIPMENT_CONFIRMATION";
  LtlInstructionsCard["FREIGHT_INSTRUCTIONS"] = "FREIGHT_INSTRUCTIONS";
})(LtlInstructionsCard || (LtlInstructionsCard = {}));