import _ from "lodash";
import { useMedia } from "react-use";
import { defaultTheme } from "../theme";
const xsMin = defaultTheme.breakpoints.XS;
const smMin = defaultTheme.breakpoints.SM;
const mdMin = defaultTheme.breakpoints.MD;
const lgMin = defaultTheme.breakpoints.LG;
const xlMin = defaultTheme.breakpoints.XL;
export const isMobile = theme => theme.name === "mobile";

/** @deprecated Use `useAboveSmall` instead, which is mobile-first */
export const useMobile = () => useMedia(`(max-width: 479px)`);

/** (min-width: 320px) */
export const useAboveExtraSmall = () => useMedia(`(min-width: ${xsMin})`);

/** (min-width: 480px) */
export const useAboveSmall = () => useMedia(`(min-width: ${smMin})`);

/** (min-width: 768px) */
export const useAboveMedium = () => useMedia(`(min-width: ${mdMin})`);

/** (min-width: 1024px) */
export const useAboveLarge = () => useMedia(`(min-width: ${lgMin})`);

/** (min-width: 1280px) */
export const useAboveExtraLarge = () => useMedia(`(min-width: ${xlMin})`);
export const containsNonNullValues = obj => {
  return _.some(obj, value => value !== null);
};