import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { ShopifyError } from "./ShopifyError";
export class ShopifyClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.SHOPIFY_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async disableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/fast-tag/disabled`,
        authentication: isAuthenticated,
        body: [dsku]
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async enableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/fast-tag/enabled`,
        body: [dsku],
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async getDskuStateCoverage(dsku) {
    return await this.apiClient.get({
      url: `/dsku/${dsku}/coverage/twoday`,
      authentication: isAuthenticated
    });
  }
  async getStateCoverageByServiceLevel(dsku) {
    try {
      return await this.apiClient.get({
        url: `/coverage/servicelevel/${dsku}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async isFastTagEnabled(sellerId, dsku) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/dsku/${dsku}/active`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async getLinkForDsku(dsku) {
    try {
      return await this.apiClient.get({
        url: `/dsku/${dsku}/link`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND, ShopifyError.NOT_FOUND_IN_MARKETPLACE]);
    }
  }
  async updateLinkForDsku(sellerId, dsku, listingUrl, productIdentifier, shop) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/dsku/${dsku}/variant`,
        body: {
          shopifyVariantUrl: listingUrl,
          shopifyVariantId: productIdentifier,
          shop
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.INVALID_URL, ShopifyError.MULTIPLE_VARIANTS, ShopifyError.NOT_FOUND]);
    }
  }
  async getSellerConnectionStatus(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/connectionstatus`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async activateSeller(sellerId, shop) {
    try {
      await this.apiClient.post({
        url: `/seller/${sellerId}/shop/${shop}/activate`,
        body: undefined,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.APP_NOT_INSTALLED, ShopifyError.INELIGIBLE_SHOPIFY_ACCOUNT, ShopifyError.NOT_FOUND]);
    }
  }
  async deactivateSeller(sellerId, shop) {
    try {
      await this.apiClient.delete({
        url: `/seller/${sellerId}/shop/${shop}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async registerSeller(sellerId, authParams) {
    try {
      await this.apiClient.post({
        url: `/auth/seller/${sellerId}`,
        body: authParams,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.ALREADY_HAS_SHOP, ShopifyError.DUPLICATE_SELLER, ShopifyError.INVALID_HMAC, ShopifyError.INVALID_HOSTNAME, ShopifyError.INVALID_NONCE, ShopifyError.MISSING_SCOPES]);
    }
  }
  async updateConfigurationForAllPages(sellerId, shop, config) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/shop/${shop}/config`,
        body: config,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async updatePageConfiguration(sellerId, shop, pageType, config) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/shop/${shop}/config/${pageType}`,
        body: config,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async getSeller(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async getStoreConfiguration(sellerId, storeName) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/store/${storeName}/configuration`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async setStorePrices(sellerId, storeName, cartMinimum, paidFastTagCost) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/store/${storeName}/prices`,
        body: {
          cartMinimum,
          paidFastTagCost
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async getConfiguration(shop) {
    try {
      return await this.apiClient.get({
        url: `/config/${shop}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async isDeliverrAppEmbedEnabledForActiveTheme(sellerId, shop) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/shop/${shop}/themes/templates/deliverr-app-embed`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND, ShopifyError.INVALID_SETTINGS_ASSET]);
    }
  }
  async getHomepageLink(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/homepage/link`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async getHomepageLinks(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/homepage/links`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async getProductLinks(sellerId, shop, limit = 1) {
    try {
      if (shop) {
        return await this.apiClient.get({
          url: `/seller/${sellerId}/shop/${shop}/products/links`,
          params: {
            limit
          },
          authentication: isAuthenticated
        });
      }
      return await this.apiClient.get({
        url: `/seller/${sellerId}/products/links`,
        params: {
          limit
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND]);
    }
  }
  async getTemplatesWithAppBlockSupport(sellerId, shop) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/shop/${shop}/themes/templates/app-block-support`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.INVALID_MAIN_ASSET, ShopifyError.INVALID_TEMPLATE_ASSET]);
    }
  }
  async generateShopifyAuthUrl(sellerId, shop) {
    try {
      return await this.apiClient.post({
        url: `/auth/seller/${sellerId}/shop/${shop}/url`,
        body: undefined,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async setStoreShippingSetting(sellerId, storeName = sellerId, settingName, settingData) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/store/${storeName}/shipping-setting/${settingName}`,
        body: settingData,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async setStoreStandardBadgeStatus(sellerId, storeName = sellerId, standardBadgeStatus) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/store/${storeName}/standard`,
        body: {
          standardBadgeStatus
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }

  /**
   * Gets an array of Shopify listings for a DSKU
   *
   * @throws NOT_FOUND                 if the DSKU isn't found in Deliverr's product service
   * @throws NOT_FOUND_IN_MARKETPLACE  if the DSKU is mapped to an MSKU or variant Id that isn't found in Shopify
   */
  async getDskuListings(sellerId, dsku, shop) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/dsku/${dsku}/listings`,
        params: {
          shop
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [ShopifyError.NOT_FOUND, ShopifyError.NOT_FOUND_IN_MARKETPLACE]);
    }
  }
  async getShopFastTagAppInstallationStatus(sellerId, shop) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/shop/${shop}/app-installed`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
}