import { createReducer } from "common/ReduxUtils";
import { ErrorsActionTypes } from "./ErrorsActions";
export const errorsInitialState = {
  errors: []
};
const reducers = {
  [ErrorsActionTypes.SET_ERROR]: (state, {
    code,
    data
  }) => ({
    errors: [...state.errors.filter(err => err.code !== code), {
      code,
      data
    }]
  }),
  [ErrorsActionTypes.CLEAR_ERROR]: (state, {
    code
  }) => ({
    errors: state.errors.filter(err => err.code !== code)
  })
};
export const errorsReducer = createReducer(errorsInitialState, reducers);