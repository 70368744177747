import produce from "immer";
export const updateShipmentQuantityReducer = (state, {
  shipmentId,
  dsku,
  quantity
}) => {
  return produce(state, draft => {
    const items = draft.receivingInfo[shipmentId].items;
    const itemIndex = items.findIndex(item => item.dsku === dsku);
    items[itemIndex].expectedQty = quantity;
  });
};