import { ReturnStatus } from "@deliverr/returns-client";
import { ReturnsDetailTypes } from "./ReturnsDetailStore.types";
import { createActionCreator } from "common/ReduxUtils";
import { aggregateItemsByDskuAndInspectionStatus, aggregateUnknownItemsByBarcodeAndStatus, getQcStatus, isQcEnabledAndProcessed, isQcEnabledAndStarted } from "returns/utils/qcDetails";
import { forEach, isEmpty, keyBy, partition, reduce, values } from "lodash";
import { productClient, returnsClient } from "Clients";
import { Path } from "paths";
import { generatePath } from "react-router";
import history from "BrowserHistory";
import log from "Logger";
const UNKNOWN_DSKU = "DSKUUNKNOWN";
export const getReturn = (returnId, orderId) => async dispatch => {
  try {
    dispatch({
      type: ReturnsDetailTypes.RETURNS_GET_DETAILS
    });
    const response = await returnsClient.getReturnById(returnId);
    const returnOrder = response?.value;
    const {
      qcId,
      items,
      status
    } = returnOrder;
    let {
      inspectedItems = []
    } = returnOrder;
    let returnsQcItems = [];
    let nullishDskuQcItems = [];
    [inspectedItems, nullishDskuQcItems] = partition(inspectedItems, item => !isEmpty(item.dsku) && item?.dsku !== UNKNOWN_DSKU);
    let unknownReturnsQcItems = aggregateUnknownItemsByBarcodeAndStatus(nullishDskuQcItems) ?? [];
    unknownReturnsQcItems = unknownReturnsQcItems.filter(item => item?.quantity && item.quantity > 0);
    inspectedItems = values(reduce(inspectedItems, (acc, inspectedItem) => {
      const key = inspectedItem.dsku + inspectedItem.status;
      if (acc[key]) {
        acc[key].quantity += inspectedItem.quantity;
      } else {
        acc[key] = inspectedItem;
      }
      return acc;
    }, {}));
    const returnsItems = keyBy(items, item => item.dsku);
    if (isQcEnabledAndStarted(qcId, status)) {
      forEach(inspectedItems, inspectedItem => {
        returnsItems[inspectedItem.dsku] = {
          ...(returnsItems[inspectedItem.dsku] || {
            ...inspectedItem,
            isUnexpected: true
          })
        };
        const returnItem = returnsItems[inspectedItem.dsku];
        returnsQcItems.push({
          status: inspectedItem.status,
          dsku: inspectedItem.dsku,
          isUnexpected: returnItem.isUnexpected,
          quantity: Math.min(inspectedItem.quantity, returnItem.quantity)
        });
        if (returnItem.quantity - inspectedItem.quantity < 0) {
          returnsQcItems.push({
            status: inspectedItem.status,
            dsku: inspectedItem.dsku,
            quantity: inspectedItem.quantity - returnItem.quantity,
            isUnexpected: true
          });
          returnItem.quantity = 0;
        } else {
          returnItem.quantity = returnItem.quantity - inspectedItem.quantity;
        }
      });
      forEach(returnsItems, (value, key) => {
        if (value.quantity > 0) {
          returnsQcItems.push({
            status: status === ReturnStatus.RECEIVED_FOR_PROCESSING ? "AWAITING" : "NOT_RECEIVED",
            dsku: key,
            quantity: value.quantity
          });
        }
      });
    }
    returnsQcItems = aggregateItemsByDskuAndInspectionStatus(returnsQcItems);
    returnsQcItems = returnsQcItems.filter(item => item?.quantity && item.quantity > 0);
    returnsQcItems.sort((a, b) => {
      return a.dsku.localeCompare(b.dsku);
    });
    returnsQcItems = returnsQcItems.concat(unknownReturnsQcItems);
    let qcStatus;
    const isQcProcessed = isQcEnabledAndProcessed(qcId, status);
    if (isQcProcessed) {
      qcStatus = getQcStatus(status, inspectedItems ?? []);
    }
    dispatch({
      type: ReturnsDetailTypes.RETURNS_GET_DETAILS_SUCCESS,
      returnsDetail: {
        ...response?.value,
        status
      },
      returnsQcDetails: {
        returnsQcItems,
        isQcProcessed,
        qcStatus
      }
    });
    dispatch(getReturnItemDetails(values(returnsItems) ?? []));
  } catch (err) {
    log.error({
      err,
      returnId,
      orderId
    }, "error getting return details for returnId, orderId");
    dispatch({
      type: ReturnsDetailTypes.RETURNS_GET_DETAILS_ERROR,
      err
    });
    history.push(generatePath(Path.orderDetail, {
      orderId
    }));
  }
};
export const getReturnItemDetails = returnItems => async dispatch => {
  try {
    const dskus = returnItems.map(orderItem => orderItem.dsku);
    const returnItemDetails = await productClient.getUnifiedProducts(dskus);
    dispatch({
      type: ReturnsDetailTypes.RETURN_GET_ITEM_DETAILS_SUCCESS,
      returnItemDetails
    });
  } catch (err) {
    log.error({
      err
    }, "error getting return item details");
    dispatch({
      type: ReturnsDetailTypes.RETURN_GET_ITEM_DETAILS_ERROR,
      err
    });
  }
};
export const resetReturn = createActionCreator(ReturnsDetailTypes.RESET_RETURN_DETAILS);