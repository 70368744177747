import { createSelector } from "reselect";
import { selectCreateShipmentForm } from "./selectCreateShipmentForm";
export const selectDestination = createSelector(selectCreateShipmentForm, createShipment => createShipment.destination);
export const selectDestinationValue = createSelector(selectDestination, destination => {
  if (destination.value.ports) {
    return destination.label;
  } else {
    const {
      city,
      state,
      zip
    } = destination.value.address;
    return [city, state, zip].filter(Boolean).join(", ");
  }
});