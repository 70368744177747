import produce from "immer";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { FacebookConnectActionTypes } from "../actions/FacebookConnectActionTypes";
import { isLoginSuccessful, LoginStatus } from "../../model/Auth";
import { getProfileInfo } from "../../model/Profile";
import { FacebookActionTypes } from "tags/facebook/common/actions/FacebookActionTypes";
export const facebookLoginStatusUnknown = null;
export const facebookNotLoggedIn = {
  authResponse: null,
  status: "unknown"
};
export const facebookInitialState = {
  loginStatus: LoginStatus.Unknown,
  auth: facebookNotLoggedIn,
  adAccounts: null,
  activeSetupMethod: null,
  pages: null,
  hasValidPermissions: null,
  sellerRegistration: "incomplete",
  pixels: null,
  adSets: null
};
export const reducers = {
  ...handleSimpleReducerUpdates([FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNTS_RECEIVED, FacebookConnectActionTypes.FACEBOOK_PAGES_RECEIVED, FacebookConnectActionTypes.FACEBOOK_PERMISSIONS_CHECKED, FacebookConnectActionTypes.FACEBOOK_PIXELS_RECEIVED]),
  [FacebookConnectActionTypes.FACEBOOK_LOGIN_STATUS_RECEIVED]: (state, {
    auth,
    newLogin
  }) => produce(state, draftState => {
    if (isLoginSuccessful(auth)) {
      if (newLogin) {
        draftState = Object.assign(draftState, facebookInitialState);
      }
      draftState.loginStatus = LoginStatus.LoggedIn;
    } else {
      draftState.loginStatus = LoginStatus.LoggedOut;
      draftState.profile = undefined;
      draftState.hasValidPermissions = null;
    }
    draftState.auth = auth;
  }),
  [FacebookConnectActionTypes.FACEBOOK_PAGE_SELECTED]: (state, {
    pageId
  }) => produce(state, draftState => {
    draftState.activePageId = pageId;
  }),
  [FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNT_SELECTED]: (state, {
    adAccountId
  }) => produce(state, draftState => {
    draftState.activeAdAccountId = adAccountId;
  }),
  [FacebookConnectActionTypes.FACEBOOK_SELLER_REGISTRATION_COMPLETE]: state => produce(state, draftState => {
    draftState.sellerRegistration = "complete";
  }),
  [FacebookConnectActionTypes.FACEBOOK_PROFILE_INFO_RECIEVED]: (state, {
    profileInfo: profileInfoResponse
  }) => produce(state, draftState => {
    draftState.profile = getProfileInfo(profileInfoResponse);
  }),
  [FacebookConnectActionTypes.FACEBOOK_PIXEL_SELECTED]: (state, {
    pixelId
  }) => produce(state, draftState => {
    draftState.activePixelId = pixelId;
  }),
  [FacebookActionTypes.FACEBOOK_ERROR_SYNCING_AD_SETS]: state => produce(state, draftState => {
    draftState.adSets = "error";
  }),
  [FacebookActionTypes.FACEBOOK_START_SYNC_AD_SETS]: state => produce(state, draftstate => {
    draftstate.adSets = null;
  }),
  [FacebookActionTypes.FACEBOOK_REFRESH_SELLER_COMPLETED]: state => produce(state, draftstate => {
    draftstate.adSets = "syncing";
  }),
  [FacebookActionTypes.FACEBOOK_AD_SETS_RECEIVED]: (state, {
    adSets
  }) => produce(state, draftState => {
    draftState.adSets = adSets;
  })
};
export const facebookReducer = createReducer(facebookInitialState, reducers);