import { toast } from "common/components/ui";
import { freightClient } from "Clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { createActionCreator } from "common/ReduxUtils";
import { getSellerId } from "common/user/UserSelectors";
import { logStart, logError } from "Logger";
import { FreightBookingActionErrors } from "./FreightActionErrors";
import { FreightListActionTypes, freightOrdersLoaderId, freightQuotesLoaderId } from "./FreightListActionTypes";
export const setFreightOrders = createActionCreator(FreightListActionTypes.FREIGHT_ORDERS_RECEIVED, "orders");
export const setFreightQuoteRequests = createActionCreator(FreightListActionTypes.FREIGHT_QUOTE_REQUESTS_RECEIVED, "quoteRequests");
export const setGeneratedFreightQuoteRequests = createActionCreator(FreightListActionTypes.FREIGHT_QUOTE_REQUESTS_GENERATED, "quoteRequests");
export const requestFreightBookings = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "requestFreightBookings"
  });
  const sellerId = getSellerId(getState());
  try {
    dispatch(addLoader(freightOrdersLoaderId));
    const orders = await freightClient.getOrders(sellerId);
    dispatch(setFreightOrders(orders));
    dispatch(clearLoader(freightOrdersLoaderId));
  } catch (err) {
    logError(ctx, err, "error request freight bookings");
    toast.error(FreightBookingActionErrors.REQUEST_FREIGHT_BOOKING_ERROR);
  }
};
export const requestFreightQuoteRequests = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "requestFreightQuoteRequests"
  });
  const sellerId = getSellerId(getState());
  try {
    dispatch(addLoader(freightQuotesLoaderId));
    const quoteRequests = await freightClient.getQuoteRequests(sellerId);
    dispatch(setFreightQuoteRequests(quoteRequests));
    dispatch(clearLoader(freightQuotesLoaderId));
  } catch (err) {
    logError(ctx, err, "error requesting freight quote requests");
    toast.error(FreightBookingActionErrors.REQUEST_FREIGHT_QUOTE_REQUEST_ERROR);
  }
};
export const toggleListView = createActionCreator(FreightListActionTypes.TOGGLE_LIST_VIEW, "listType");