import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { fetchNotifications, fetchBothNotifications } from "../NotificationsActions";
import { getNotifications, isNotificationsError, isNotificationsSuccess } from "../NotificationsSelectors";
import { useSmbAccelerateFeatureOn } from "../../common/featureFlags";
import { FeatureName } from "../../common/Split";
import { getIsLinkedToFlexport } from "../../common/user/UserSelectors";
export const useNotificationsBlade = () => {
  const dispatch = useDispatch();
  const isSMBNotificationsFeatureOn = useSmbAccelerateFeatureOn(FeatureName.SmbAccelerateNotifications);
  const isSuccess = useSelector(isNotificationsSuccess);
  const isError = useSelector(isNotificationsError);
  const notifications = useSelector(getNotifications);
  const isLinkedToFlexport = useSelector(getIsLinkedToFlexport);
  let fetch;
  if (isSMBNotificationsFeatureOn && isLinkedToFlexport) {
    fetch = () => dispatch(fetchBothNotifications());
  } else {
    fetch = () => dispatch(fetchNotifications());
  }
  useMount(() => {
    if (notifications?.length === 0) {
      fetch();
    }
  });
  return {
    notifications,
    isError,
    isSuccess,
    fetchNotifications: fetch
  };
};