import { logError } from "Logger";
import { setStorageWarehouses } from "./setStorageWarehouses";
import { inboundClient } from "Clients";
export const getRankedStorageWarehousesForInbounds = (isInternational, customAddress) => async (dispatch, getState) => {
  const address = customAddress ?? getState().inbound.fromAddress;
  const ctx = {
    fn: "getRankedStorageWarehousesForInbounds"
  };
  const sellerId = getState().user.sellerId;
  try {
    const storageWarehouses = (await inboundClient.getRankedStorageWarehousesForInbounds(sellerId, {
      address,
      isInternational
    }))?.warehouses ?? [];
    dispatch(setStorageWarehouses(storageWarehouses.map(warehouse => warehouse)));
  } catch (error) {
    logError(ctx, error);
  }
};