import { InboundShipmentStatus } from "@deliverr/legacy-inbound-client";
import { getIsInternationalFreightInbound } from "inbounds/utils/shippingPlan/getIsInternationalFreightInbound";
import { isInboundShipmentDraftStatus } from "transfers/utils/isInboundShipmentDraftStatus";

// This is a temporary hacky solution to show In progress status for all flexport bookings
// In progress is not a real status but something we show in the short term until we can support status updates from flexport
export const getShouldShowInProgressStatus = (status, bookingSubmitted, bookingType) => {
  // if booking not submitted yet, we want to show draft
  if (!bookingSubmitted) {
    return false;
  }

  // if not a flexport booking, we never want to show in progress status
  if (!getIsInternationalFreightInbound(bookingType, 0)) {
    return false;
  }

  // for the draft case: backend is draft status but seller has "submitted" the booking
  // so they should not see draft status anymore
  if (status === undefined || isInboundShipmentDraftStatus(status)) {
    return true;
  }

  // READY FOR WAREHOUSE is only non draft status that should show in progress
  return status === InboundShipmentStatus.READY_FOR_WAREHOUSE;
};