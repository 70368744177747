import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
export let SalesChannelsActionTypes;
(function (SalesChannelsActionTypes) {
  SalesChannelsActionTypes["SET_SALES_CHANNELS"] = "SET_SALES_CHANNELS";
  SalesChannelsActionTypes["GET_SALES_CHANNEL_ID"] = "GET_SALES_CHANNEL_ID";
  SalesChannelsActionTypes["DEACTIVATE_SALES_CHANNEL"] = "DEACTIVATE_SALES_CHANNEL";
  SalesChannelsActionTypes["ACTIVATE_SALES_CHANNEL"] = "ACTIVATE_SALES_CHANNEL";
})(SalesChannelsActionTypes || (SalesChannelsActionTypes = {}));
export const salesChannelsInitialState = {
  salesChannels: [],
  recommendedChannels: [],
  directChannels: [],
  indirectChannels: []
};
const findAndUpdateSalesChannel = (currentSalesChannels, salesChannel) => {
  return currentSalesChannels.map(channel => channel.slsUuid === salesChannel.slsUuid ? salesChannel : channel);
};
const reducers = {
  ...handleSimpleReducerUpdates([SalesChannelsActionTypes.SET_SALES_CHANNELS]),
  [SalesChannelsActionTypes.DEACTIVATE_SALES_CHANNEL]: (state, {
    salesChannel
  }) => {
    return {
      ...state,
      salesChannels: findAndUpdateSalesChannel(state.salesChannels, salesChannel)
    };
  },
  [SalesChannelsActionTypes.ACTIVATE_SALES_CHANNEL]: (state, {
    salesChannel
  }) => {
    return {
      ...state,
      salesChannels: findAndUpdateSalesChannel(state.salesChannels, salesChannel)
    };
  }
};
export const salesChannelsReducer = createReducer(salesChannelsInitialState, reducers);