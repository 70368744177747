import { calculateAvailableQuantity } from "common/utils/product/calculateAvailableQuantity";
import { getProductListItemsByDsku } from "inventory/BundleDetail/getProductListItemsByDsku";
import { isEmpty } from "lodash";
export async function calculateECOMReplenishmentRecommendation(dsku) {
  const data = await getProductListItemsByDsku([dsku]);
  const productListItem = data[dsku];
  if (isEmpty(productListItem) || !productListItem.unitsSoldLast30Days) {
    return;
  }
  const inventoryRequiredInLast3Months = 3 * productListItem.unitsSoldLast30Days;
  const qtyInEcom = calculateAvailableQuantity(productListItem) + (productListItem.inboundUnits ?? 0);
  return Math.max(0, inventoryRequiredInLast3Months - qtyInEcom);
}