import { toCognito } from "common/user/CognitoConverter";
import { getAuth, getUser } from "common/utils/SSO";
import store from "store";
import { CognitoLegacyActions } from "./CognitoReducer";
const updateUser = async (userData, bypassCache = false) => {
  const session = await getAuth();
  const user = await getUser();
  const result = await session.updateUserAttributes(user, toCognito({
    ...userData,
    updatedAt: String(Date.now())
  }));
  store.dispatch({
    type: CognitoLegacyActions.COGNITO_UPDATE_USER_ATTRIBUTES,
    user: await getUser({
      bypassCache
    })
  });
  return result;
};
export default updateUser;