export let WishError;
(function (WishError) {
  WishError["ACCESS_TOKEN_ERROR"] = "ACCESS_TOKEN_ERROR";
  WishError["DUPLICATE_MERCHANT_ID"] = "DUPLICATE_MERCHANT_ID";
  WishError["DUPLICATE_SELLER_ID"] = "DUPLICATE_SELLER_ID";
  WishError["DUPLICATE_TOKEN"] = "DUPLICATE_TOKEN";
  WishError["REFRESH_ALREADY_IN_PROGRESS"] = "REFRESH_ALREADY_IN_PROGRESS";
  WishError["SELLER_NOT_FOUND"] = "SELLER_NOT_FOUND";
  WishError["NO_WISH_EXPRESS_WAREHOUSE"] = "NO_WISH_EXPRESS_WAREHOUSE";
  WishError["WISH_PRODUCT_IDENTIFIER_NOT_FOUND"] = "WISH_PRODUCT_IDENTIFIER_NOT_FOUND";
  WishError["VARIATION_NOT_FOUND"] = "VARIATION_NOT_FOUND";
})(WishError || (WishError = {}));