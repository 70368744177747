import { StorageTransferStatus } from "@deliverr/storage-client";
import { ApiError } from "@deliverr/commons-objects";
import { ExternalDestinationType, WholesaleOrderStatus, Channel } from "@deliverr/wholesale-client";
import { createActionCreator } from "common/ReduxUtils";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { SortDir } from "common/models";
import { ShipmentStatusGrouping, SHIPMENT_STATUS_BY_GROUPING } from "inbounds/ShipmentStatus";
import { difference, isEmpty } from "lodash";
import { batch } from "react-redux";
import log from "Logger";
import { algoliaWholesaleShipmentsService } from "../algoliaWholesaleShipmentsService";
import { selectTransferList, selectTransferListPageSize } from "../store";
import { TransferDestinationType } from "../store/TransferDestinationType";
import { ReplenishmentOrderListTabFilter } from "../store/TransferListState";
import { TransferListActionTypes } from "./TransferListActionTypes";
import { TransferListAlgoliaField } from "./TransferListAlgoliaField";
import { NonComplianceStatus, OrderStatus } from "@deliverr/replenishment-client";
import { WarehouseOrderType } from "@deliverr/business-types";
const BOOKING_ID_ATTRIBUTE_NAMES = ["bookingID", "bookingId"];
const transfersListError = createActionCreator(TransferListActionTypes.TRANSFER_LIST_GET_ERROR, "isError");
const getListItemsSuccessAction = createActionCreator(TransferListActionTypes.TRANSFER_LIST_GET_SUCCESS, "listData");
export const getTransferShipments = () => async (dispatch, getState) => {
  batch(() => {
    dispatch(addLoader(TransferListActionTypes.TRANSFER_LIST_GET));
    dispatch(transfersListError(false));
  });
  const ctx = {
    fn: "getTransferShipments"
  };
  try {
    const {
      searchTerm,
      pageNum,
      searchFilters
    } = selectTransferList(getState());
    const pageSize = selectTransferListPageSize(getState());
    const algoliaResult = await algoliaWholesaleShipmentsService.search(searchTerm, pageNum, {
      fieldName: "createdAt",
      direction: SortDir.DESC
    }, [`(orderType:${WarehouseOrderType.TRANSFER} OR orderType:${WarehouseOrderType.ECOM_PREP} OR externalDestinationType:${ExternalDestinationType.FBA} OR version:v2) AND (NOT status:${WholesaleOrderStatus.DRAFT} OR createdAutomatically:true OR channel:${Channel.JIT_FBA_RECOMMENDATIONS} OR version:v2) AND NOT status:ERROR AND NOT internalStatus:${StorageTransferStatus.ARCHIVED} AND NOT isArchived:true AND (NOT externalDestinationType:${ExternalDestinationType.WHOLESALE})`, ...createTransferListFilterClause(searchFilters)], undefined, pageSize);
    const shipments = algoliaResult.hits;
    const nbPages = algoliaResult.response.nbPages;
    dispatch(getListItemsSuccessAction({
      shipments: filterWholesaleOrdersWithBookingId(shipments),
      nbPages,
      pageNum
    }));
  } catch (err) {
    if (err.message !== ApiError.UNKNOWN_ERROR) {
      dispatch(transfersListError(true));
      log.error({
        ctx,
        err
      }, "error getting shipments list");
    }
  } finally {
    dispatch(clearLoader(TransferListActionTypes.TRANSFER_LIST_GET));
  }
};
const createTransferListFilterClause = searchFilters => {
  const {
    destinationType,
    shipmentStatuses,
    tabFilter
  } = searchFilters;
  const filters = [];
  filters.push(createTransferDestinationTypeFilter(destinationType));
  filters.push(createTransferShipmentStatusesFilter(shipmentStatuses));
  filters.push(createReplenishmentTabFilter(tabFilter));
  return difference(filters, [null]);
};
export const createReplenishmentTabFilter = tabFilter => {
  switch (tabFilter) {
    case ReplenishmentOrderListTabFilter.HAS_PROBLEM:
      return `${TransferListAlgoliaField.NON_COMPLIANCE_STATUS}:${NonComplianceStatus.CREATED} OR ${TransferListAlgoliaField.NON_COMPLIANCE_STATUS}:${NonComplianceStatus.PENDING_VERIFICATION}`;
  }
  return null;
};
const createTransferDestinationTypeFilter = destinationType => {
  switch (destinationType) {
    case TransferDestinationType.FBA:
      return `${TransferListAlgoliaField.EXTERNAL_DESTINATION_TYPE}:${ExternalDestinationType.FBA}`;
    case TransferDestinationType.WFS:
      return `${TransferListAlgoliaField.EXTERNAL_DESTINATION_TYPE}:${ExternalDestinationType.WALMART}`;
    case TransferDestinationType.DELIVERR_ECOM:
      return `(${TransferListAlgoliaField.ORDER_TYPE}:${WarehouseOrderType.TRANSFER} OR ${TransferListAlgoliaField.ORDER_TYPE}:${WarehouseOrderType.ECOM_PREP})`;
  }
  return null;
};
const createTransferShipmentStatusesFilter = shipmentStatuses => {
  const inboundStatuses = shipmentStatuses.filter(status => ShipmentStatusGrouping[status]).map(status => SHIPMENT_STATUS_BY_GROUPING[status] ?? []).flat().map(status => `${TransferListAlgoliaField.INBOUND_STATUS}:${status}`);
  const wholesaleOrderStatuses = shipmentStatuses.filter(status => WholesaleOrderStatus[status] || OrderStatus[status]).map(status => `${TransferListAlgoliaField.STATUS}:${status}`);
  const joinedStatuses = [...inboundStatuses, ...wholesaleOrderStatuses];
  return joinedStatuses.length ? `(${joinedStatuses.join(" OR ")})` : null;
};
const filterWholesaleOrdersWithBookingId = wholesaleOrders => {
  return wholesaleOrders.filter(order => {
    const shipments = Object.values(order?.shipments ?? {});
    return !shipments.some(shipment => shipment?.externalAttributes?.some(attr => BOOKING_ID_ATTRIBUTE_NAMES.includes(attr?.attributeName) && !isEmpty(attr?.attributeValue)));
  });
};