import { defineMessages } from "react-intl";
export const internationalShippingOptionsMessages = defineMessages({
  shopifyMarketsProLabel: {
    id: "settings.internationalShippingOptions.shopifyMarketsProLabel",
    defaultMessage: "Shopify Markets Pro"
  },
  shopifyMarketsProContent: {
    id: "settings.internationalShippingOptions.shopifyMarketsProContent",
    defaultMessage: "Your account will be automatically connected when we receive the first international order from Markets Pro."
  },
  globalELabel: {
    id: "settings.internationalShippingOptions.globalELabel",
    defaultMessage: "Global-E"
  },
  globalEContent: {
    id: "settings.internationalShippingOptions.globalEContent",
    defaultMessage: "You will need to provide your Merchant GUID to connect your account."
  },
  noIntegrationLabel: {
    id: "settings.internationalShippingOptions.noIntegrationLabel",
    defaultMessage: "I do not have an integration"
  },
  noIntegrationContent: {
    id: "settings.internationalShippingOptions.noIntegrationContent",
    defaultMessage: "By default your buyers will pay duties upon delivery. You can connect an integration at a later time if you want to offer delivery duties prepaid in the future."
  }
});
export const internationalShippingIntegrationMessages = defineMessages({
  globalEDDPTitle: {
    id: "settings.internationalShippingIntegration.globalEIntegrationTitle",
    defaultMessage: "Global-E integration"
  },
  globalEDesc: {
    id: "settings.internationalShippingIntegration.globalEDesc",
    defaultMessage: "International shipping is enabled. Customs information is handled through your Global-E account."
  },
  globalEDDPDesc: {
    id: "settings.internationalShippingIntegration.globalEIntegrationDesc",
    defaultMessage: "Delivery duties paid at checkout is enabled. Customers will pay guaranteed duties at checkout and have international orders delivered in 2-8 days with no unexpected fees."
  },
  marketsProDDPTitle: {
    id: "settings.internationalShippingIntegration.marketsProIntegrationTitle",
    defaultMessage: "Markets Pro integration"
  },
  marketsProDesc: {
    id: "settings.internationalShippingIntegration.marketsProDesc",
    defaultMessage: "International shipping is enabled. Customs information is handled through your Markets Pro account."
  },
  marketsProDDPDesc: {
    id: "settings.internationalShippingIntegration.marketsProIntegrationDesc",
    defaultMessage: "Delivery duties paid at checkout is enabled. Customers will pay guaranteed duties at checkout and have international orders delivered in 2-8 days with no unexpected fees."
  },
  noIntegrationDesc: {
    id: "settings.internationalShippingIntegration.noIntegrationDesc",
    defaultMessage: "International Shipping is enabled. You can edit customs information in the inventory details of the product. You will be asked to enter customs information on new products during inbounds. Hazmat items are currently not supported yet."
  },
  integrationError: {
    id: "internationalShippingTab.getInternationalSellerSettings.saveError",
    defaultMessage: "There was an error getting your international integration"
  }
});