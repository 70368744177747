import { useDispatch, useSelector } from "react-redux";
import { useAsyncFn } from "react-use";
import { logError } from "Logger";
import { loginStatusReceived } from "../connect/actions/FacebookActions";
import { facebookAccessToken, facebookUserId, isLoggedIntoFacebook } from "../connect/state/FacebookSelectors";
export const useFacebookLogin = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedIntoFacebook);
  const userId = useSelector(facebookUserId);
  const accessToken = useSelector(facebookAccessToken);
  const FB = window.FB;
  const getLoginStatus = async () => await new Promise((resolve, reject) => {
    FB.getLoginStatus(response => {
      try {
        dispatch(loginStatusReceived(response));
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  });
  const [, login] = useAsyncFn(async options => await new Promise((resolve, reject) => {
    try {
      FB.login(response => {
        dispatch(loginStatusReceived(response, true));
        resolve(response);
      }, options);
    } catch (err) {
      reject(err);
    }
  }));
  const [, logout] = useAsyncFn(async () => await new Promise((resolve, reject) => {
    try {
      FB.logout(response => {
        dispatch(loginStatusReceived(response));
        resolve(response);
      });
    } catch (err) {
      logError({
        fn: "useFacebookLogin.logout"
      }, err);
      reject(err);
    }
  }));
  return {
    accessToken,
    isLoggedIn,
    login,
    logout,
    userId,
    getLoginStatus
  };
};