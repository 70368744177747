import { ProblemType } from "common/problems/ProblemType";
import { getMappedSkuPairs } from "inbounds/steps/ship/view/cards/ShipmentProblems/spvUtils/getMappedSkuPairs";
export const getNonComplianceRowsFromMissingBarcodeCases = (missingBarcodeCase, productCollection) => {
  const {
    sellerProblemId,
    status,
    barcode,
    updatedAt,
    dsku,
    shippingPlanId,
    shippingPlanName,
    reportedUnits,
    rawImages,
    issueId,
    isLegacyCase,
    affectedCdskus,
    createdAt
  } = missingBarcodeCase;
  return {
    id: sellerProblemId?.toString() ?? issueId,
    isLegacyCase,
    problemType: ProblemType.MISSING_BARCODE,
    barcode,
    shippingPlanId,
    shippingPlanName,
    createdAt,
    updatedAt,
    status,
    units: reportedUnits,
    mappedSkus: productCollection ? getMappedSkuPairs(missingBarcodeCase, productCollection) : dsku ? [{
      dsku,
      msku: "",
      productName: ""
    }] : [],
    photos: rawImages,
    affectedCdskus,
    // TODO: acknowledgedAt is not currently available, but we plan to begin setting this in a future iteration
    acknowledgedAt: new Date()
  };
};