import { defineMessages } from "react-intl";
export const shipmentConfirmedHeadingLabels = defineMessages({
  shipmentConfirmedCaption: {
    id: "inbounds.storage.shipmentConfirmed.caption",
    defaultMessage: "You're almost done. Complete your shipment on the next page"
  },
  shipmentConfirmedTitle: {
    id: "inbounds.storage.shipmentConfirmed.title",
    defaultMessage: "Shipment confirmed"
  }
});