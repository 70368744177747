import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import log from "Logger";
import { batch } from "react-redux";
import { algoliaService } from "../algoliaConfig";
import { OrderListActionTypes } from "../OrderListActionTypes";
import { searchOrdersSuccess } from "./searchOrdersSuccess";
import { getAlgoliaFilters } from "../helpers/getAlgoliaFilters";
export const searchOrders = ({
  page
} = {}) => async (dispatch, getState) => {
  const ctx = {
    fn: "searchOrders"
  };
  log.info({
    ...ctx
  }, "searching orders");
  dispatch(addLoader(OrderListActionTypes.LOAD_ORDER_LIST));
  const {
    orderListV2: {
      currentSearchTerm,
      sort,
      searchInfo,
      filters: {
        status,
        serviceLevel,
        date
      }
    },
    user: {
      resultsPerPage,
      isAdmin
    }
  } = getState();
  const ordersFilters = getAlgoliaFilters({
    status,
    serviceLevel,
    date,
    isAdmin
  });
  try {
    const searchResult = await algoliaService.search(currentSearchTerm, searchInfo?.currentPage ?? page ?? 0, sort, ordersFilters.length > 0 ? ordersFilters : undefined, undefined, resultsPerPage.orderList);
    log.info(ctx, "successfully retrieved orders");
    const {
      response,
      hits
    } = searchResult;
    batch(() => {
      dispatch(searchOrdersSuccess(response, hits));
      dispatch(clearLoader(OrderListActionTypes.LOAD_ORDER_LIST));
    });
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error searching for products");
    return dispatch({
      type: OrderListActionTypes.SEARCH_ORDERS_ERROR
    });
  }
};