export let DimensionSource;
(function (DimensionSource) {
  DimensionSource["AMAZONSCRAPED"] = "amazonscraped";
  DimensionSource["AVERAGEREGRESSION"] = "averageregression";
  DimensionSource["CROSSDOCK"] = "crossdock";
  DimensionSource["FCDIMSANDWEIGHT"] = "fc-dims-and-weight";
  DimensionSource["MANUAL"] = "manual";
  DimensionSource["SELLER"] = "seller";
  DimensionSource["WAREHOUSE"] = "warehouse";
})(DimensionSource || (DimensionSource = {}));
export const isDimsConfirmed = (dimSourceType = "") => [DimensionSource.AMAZONSCRAPED, DimensionSource.AVERAGEREGRESSION, DimensionSource.CROSSDOCK, DimensionSource.FCDIMSANDWEIGHT, DimensionSource.MANUAL, DimensionSource.SELLER, DimensionSource.WAREHOUSE].includes(dimSourceType);
export const isDimsEditable = (dimSourceType = "") => ![DimensionSource.AMAZONSCRAPED, DimensionSource.AVERAGEREGRESSION, DimensionSource.CROSSDOCK, DimensionSource.FCDIMSANDWEIGHT, DimensionSource.MANUAL, DimensionSource.WAREHOUSE].includes(dimSourceType);