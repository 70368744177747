import { fromPairs, omit, zip } from "lodash/fp";
export const emptyById = {
  byId: {},
  ids: []
};
export const getItemsFromById = original => original.ids.map(id => original.byId[id]);
export const addToById = (id, item, original) => {
  const {
    ids,
    byId
  } = original;
  return {
    ids: [id, ...ids],
    byId: {
      ...byId,
      [id]: item
    }
  };
};
export const addAllToById = (ids, items, original = emptyById) => ({
  byId: {
    ...original.byId,
    ...fromPairs(zip(ids, items))
  },
  ids: [...original.ids, ...ids]
});
export const updateInById = (updateItem, id, original) => {
  const {
    ids,
    byId
  } = original;
  return {
    ids,
    byId: {
      ...byId,
      [id]: updateItem(original.byId[id])
    }
  };
};
export const removeFromById = (id, original) => {
  const {
    ids,
    byId
  } = original;
  return {
    ids: ids.filter(currentId => currentId !== id),
    byId: omit(id, byId)
  };
};