import { useMemo } from "react";
import { getAggregatedRow } from "./getAggregatedRow";
/**
 * Hook for aggregating row data based on the options provided and returning all rows.
 */
export function useTableAggregator({
  rows,
  options
}) {
  const aggregateRow = useMemo(() => getAggregatedRow(rows, options), [rows, options]);
  return {
    rows: aggregateRow ? [...rows, aggregateRow] : rows
  };
}
export function useMultiTableAggregator(aggregators, cumulativeAggregationKey) {
  const aggRows = [];
  const aggregatedTableData = useMemo(() => aggregators.map(({
    rows,
    options
  }) => {
    const aggRow = getAggregatedRow(rows, options);

    // Add the aggregated row (if any) from each sub table
    if (aggRow) {
      aggRows.push(aggRow);
    }
    return {
      rows: aggRow ? [...rows, aggRow] : rows
    };
  }), [aggregators, aggRows]);
  let totalAggregation;
  if (aggRows.length > 0 && !!cumulativeAggregationKey) {
    const calculatedValue = getAggregatedRow(aggRows, {
      keys: [cumulativeAggregationKey]
    })?.[cumulativeAggregationKey];
    totalAggregation = typeof calculatedValue === "number" ? calculatedValue : undefined;
  }
  return {
    aggregatedTableData,
    totalAggregation
  };
}