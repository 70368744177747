import { useAsync } from "react-use";
import { useIntegrationOAuthToken } from "onboarding/ListingTool/tools/common/useIntegrationOAuthToken";
export const useIntegrationOAuthCallback = ({
  handleCreateIntegration,
  rmt
}) => {
  const {
    token
  } = useIntegrationOAuthToken();
  useAsync(async () => {
    if (!token) {
      return;
    }
    await handleCreateIntegration(token, rmt);
  });
};