import styled from "@emotion/styled";
import { ProblemBladeTable } from "../ProblemBladeTable";
export const EstimatedTotalChargeTable = styled(ProblemBladeTable)(({
  theme
}) => `
    color: ${theme.colors.NEUTRAL[300]};

    th {
      padding-bottom: ${theme.spacing.S2};
      color: ${theme.colors.NEUTRAL[300]};
    }

    td {
      padding-top: ${theme.spacing.S2};
    }
`);