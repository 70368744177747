/*
appends the hasConfirmedBatteriesCompliance boolean to all shipments in plannedShipments.byId on creation and loading of inbounds
used for confirming batteries compliance during inbound completion

intended use is to reassign its result back to plannedShipments.byId
*/

export const appendBatteriesToShipments = shipmentsById => Object.keys(shipmentsById).reduce((modifiedShipments, shipmentId) => {
  modifiedShipments[shipmentId] = {
    ...shipmentsById[shipmentId],
    hasConfirmedBatteriesCompliance: false // used for requiring batteries compliance on hazmat inbounds
  };

  return modifiedShipments;
}, {});