import { createSelector } from "reselect";
import { find } from "lodash/fp";
import { Role } from "common/clients/seller/User/Role";
import { getUserStatus } from "common/user/status/getUserStatus";
export const ENGAGEMENT_STAGES = ["base", "strategic", "enterprise", "trial", "churned", "inactive"];
export const getIsOrganizationLoading = state => !state?.organization?.sellerId;
export const getHasPaymentInformation = createSelector(getIsOrganizationLoading, state => state?.organization?.hasChargebeeAccount, (isOrganizationLoading, hasChargebeeAccount) => isOrganizationLoading ? undefined : hasChargebeeAccount);
export const getOrgUsers = state => state?.organization?.users;
export const getOrgOnboardingStage = state => state?.organization?.onboardingStage;
export const getOrgDealStage = state => state?.organization?.dealStage;
export const getOrgHasFirstOrder = createSelector(getOrgDealStage, dealStage => ENGAGEMENT_STAGES.filter(stage => dealStage?.toLowerCase().includes(stage)).length > 0);
export const getOrgCompletedInbound = createSelector(getOrgOnboardingStage, onboardingStage => onboardingStage?.completedInbound);
export const getOrgCreatedInbound = createSelector(getOrgOnboardingStage, onboardingStage => onboardingStage?.createdInbound);
export const getOrgAdmin = createSelector(getOrgUsers, users => {
  if (!users) {
    return undefined;
  }
  const activeAdmins = users.filter(user => user.role === Role.ADMIN && getUserStatus(user) === "ACTIVE");
  return activeAdmins.length > 0 ? activeAdmins[0] : users.find(user => user.role === Role.ADMIN);
});
export const getCurrentOrganizationUser = createSelector(state => state.user.email, state => state.organization.users, (email, organizationUsers) => find(["email", email], organizationUsers));