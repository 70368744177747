import asyncRetry from "async-retry";
const RETRY_DEFAULTS = {
  retries: RETRIES,
  factor: RETRY_FACTOR,
  minTimeout: RETRY_MIN_TIMEOUT
};

// specify shared defaults and handle tests without timing out
export const retry = async (fn, options) => await asyncRetry(fn, {
  ...RETRY_DEFAULTS,
  ...options
});