import { defineMessages } from "react-intl";
export const perUnitTypeLabels = defineMessages({
  perBox: {
    id: "common.labels.perUnitType.perBox",
    defaultMessage: "/box"
  },
  perPallet: {
    id: "common.labels.perUnitType.perPallet",
    defaultMessage: "/pallet"
  },
  perUnit: {
    id: "common.labels.perUnitType.perUnit",
    defaultMessage: "/unit"
  },
  perShipment: {
    id: "common.labels.perUnitType.perShipment",
    defaultMessage: "/shipment"
  },
  perOrder: {
    id: "common.labels.perUnitType.perOrder",
    defaultMessage: "/order"
  }
});