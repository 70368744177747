import { useScript } from "common/hooks/useScript";
import { useMemo } from "react";
import { useSelector } from "react-redux";
export const useZendeskChatLauncher = onLaunch => {
  const {
    email
  } = useSelector(state => state.user);
  const sellerId = useSelector(state => state.organization.sellerId);
  const attributes = useMemo(() => ({
    id: "ze-snippet",
    type: "application/javascript",
    hide: "true",
    "data-builder-preview": "false",
    "data-ft-Embed-Script-Language": "en",
    "data-ft-Email": email,
    "data-ft-Seller-ID": sellerId,
    "data-ft-URL": "sellerportal.deliverr.com"
  }), [email, sellerId]);
  const ZENDESK_SCRIPT_URL = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_API_KEY}`;
  useScript(ZENDESK_SCRIPT_URL, true, attributes);
  const onClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onLaunch?.();
    window.zE("messenger", "open");
  };
  return {
    onClick
  };
};