import { useController, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { validateExpirationDate } from "../../utils/validateExpirationDate";
import { getProductIsFefoEnabled } from "common/utils/product/getProductIsFefoEnabled";
import { getProductIsLotTrackingEnabled } from "common/utils/product/getProductIsLotTrackingEnabled";
import { get } from "lodash/fp";
import { LotInformationError } from "../../types/LotInformationError";
import { getErrorsForDsku } from "../../utils/getErrorsForDsku";
import { validateLotNumber } from "../../utils/validateLotNumber";
export const useLotInformationTableRow = ({
  product
}) => {
  const {
    register,
    getValues,
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const {
    formatMessage
  } = useIntl();
  const {
    dsku
  } = product;
  const {
    [dsku]: rowValues
  } = getValues();
  const isFefoEnabled = getProductIsFefoEnabled(product);
  const isLotTrackingEnabled = getProductIsLotTrackingEnabled(product);
  const getHasError = fieldKey => !!get(fieldKey, errors);

  // lot number input props
  const lotNumberKey = `${dsku}.lotNumber`;
  const lotNumberField = register(lotNumberKey, {
    required: isLotTrackingEnabled && LotInformationError.LOT_NUMBER_REQUIRED,
    validate: isLotTrackingEnabled ? lotNumber => validateLotNumber(lotNumber) : undefined
  });
  const lotNumberFieldProps = {
    ...lotNumberField,
    hasError: getHasError(lotNumberKey)
  };

  // date format select props
  const hasFullExpirationDate = rowValues?.hasFullExpirationDate?.value;
  const hasFullExpirationDateFieldKey = `${dsku}.hasFullExpirationDate`;
  const hasFullExpirationDateField = useController({
    name: hasFullExpirationDateFieldKey,
    control
  });
  const {
    onChange,
    name,
    ref
  } = hasFullExpirationDateField?.field;
  const hasFullExpirationDateOptions = [{
    label: formatMessage({
      id: "inbounds.expirationDate.fullDatePlaceholder",
      defaultMessage: "yyyy-mm-dd"
    }),
    value: true
  }, {
    label: formatMessage({
      id: "inbounds.expirationDate.monthYearPlaceholder",
      defaultMessage: "yyyy-mm"
    }),
    value: false
  }];
  const hasFullExpirationDateValue = hasFullExpirationDateOptions.find(({
    value: _value
  }) => _value === hasFullExpirationDate);
  const hasFullExpirationDateFieldProps = {
    name,
    onChange,
    forwardRef: ref,
    value: hasFullExpirationDateValue,
    options: hasFullExpirationDateOptions,
    searchable: false
  };

  // expiration date input props
  const expirationDateKey = `${dsku}.expirationDate`;
  const expirationDateField = register(expirationDateKey, {
    required: isFefoEnabled && LotInformationError.EXPIRATION_DATE_REQUIRED,
    // since expirationDateKey is a single string that refers to a nested field, the type of expirationDate is inferred
    // as ProductLotInformationFields instead of string, hence why we need this type assertion
    validate: isFefoEnabled ? expirationDate => validateExpirationDate(expirationDate, hasFullExpirationDate) : undefined
  });
  const expirationDatePlaceholder = hasFullExpirationDateOptions.find(({
    value
  }) => value === hasFullExpirationDate)?.label;
  const expirationDateFieldProps = {
    ...expirationDateField,
    placeholder: expirationDatePlaceholder,
    hasError: getHasError(expirationDateKey)
  };
  const errorsForDsku = getErrorsForDsku(dsku, errors);
  return {
    hasFullExpirationDateFieldProps,
    expirationDateFieldProps,
    lotNumberFieldProps,
    isFefoEnabled,
    isLotTrackingEnabled,
    errors: errorsForDsku
  };
};