/* eslint-disable no-sequences */
/* eslint-disable no-console */
import { all, anyPass, complement, curry, equals, includes, isBoolean, isEmpty, isNil, isNumber, pipe, values } from "lodash/fp";
import { uniq } from "lodash";
export const anyTrue = pipe(values, includes(true));
export const hasValue = complement(isNil);
export const isTrue = equals(true);
export const notEmpty = anyPass([isBoolean, isNumber, complement(isEmpty)]);
export const either = (fn1, fn2) => val => fn1(val) || fn2(val);
export const objHasAllValues = pipe(values, all(notEmpty));

// This is different from lodash pickAll. Where lodash pickAll and pick are equal.
// This pickAll is a copy of ramda's pickAll where properties that don't exist return undefined.
export const pickAll = curry((names, obj) => {
  const result = {};
  let idx = 0;
  const len = names.length;
  while (idx < len) {
    const name = names[idx];
    result[name] = obj[name];
    idx += 1;
  }
  return result;
});
export const hasDuplicates = list => uniq(list).length !== list.length;
export const pipeDebug = x => (console.debug(x), x);
export const inArray = (...args) => args;
export const wrapValue = (min, max) => value => value > max ? min : value < min ? max : value;