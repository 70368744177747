import { flatten, flowRight, fromPairs, groupBy, isEmpty, isUndefined, map, orderBy } from "lodash/fp";
import { inboundClient, productClient, sellerProblemsViewClient } from "Clients";
import { storageClient } from "common/clients/instances";
import { createActionCreator } from "common/ReduxUtils";
import { createEcommFulfillmentInbound } from "inbounds/store/actions/shippingPlan/createEcommFulfillmentInbound";
import log from "Logger";
import { isPreShipmentStatus } from "inbounds/ShipmentStatus";
import { getOrgHasFirstOrder } from "organization/OrganizationSelectors";
import { sendGTMData } from "common/utils/Analytics";
import produce from "immer";
import { algoliaShipmentsService, createInboundListFilterClause, createSearchTerm } from "./InboundListFilters/InboundListSearchUtils";
import { selectActionNeededShipments } from "./InboundListSelectors";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { InboundSource } from "@deliverr/legacy-inbound-client";
import { toast } from "common/components/ui";
import { deleteShippingPlansError } from "inbounds/error/DeleteShippingPlansError";
import { setDomesticEcommIPBState } from "inbounds/createShipment/store/actions/setDomesticEcommIPBState";
import { setFromAddress } from "inbounds/steps/address/AddressActions";
import { clearInbound } from "inbounds/createShipment/store/actions";
export let InboundListActionTypes;
(function (InboundListActionTypes) {
  InboundListActionTypes["SEARCH_INBOUNDS_START"] = "SEARCH_INBOUNDS_START";
  InboundListActionTypes["SEARCH_INBOUNDS_SUCCESS"] = "SEARCH_INBOUNDS_SUCCESS";
  InboundListActionTypes["SEARCH_INBOUNDS_ERROR"] = "SEARCH_INBOUNDS_ERROR";
  InboundListActionTypes["LOAD_INBOUNDS"] = "LOAD_INBOUNDS";
  InboundListActionTypes["SET_SELECTED_ROW"] = "SET_SELECTED_ROW";
  InboundListActionTypes["SET_PAGE_SELECTED"] = "SET_PAGE_SELECTED";
  InboundListActionTypes["DELETE_SHIPPING_PLANS_SUCCESS"] = "DELETE_SHIPPING_PLANS";
  InboundListActionTypes["DUPLICATE_SHIPPING_PLANS_SUCCESS"] = "DUPLICATE_SHIPPING_PLANS";
  InboundListActionTypes["DUPLICATE_SHIPPING_PLANS_ERROR"] = "DUPLICATE_SHIPPING_PLANS";
  InboundListActionTypes["RESET_INBOUNDS_FILTERS"] = "RESET_INBOUNDS_FILTERS";
  InboundListActionTypes["SET_ACTION_NEEDED_SHIPMENTS"] = "SET_ACTION_NEEDED_IDS";
  InboundListActionTypes["SET_ACTION_NEEDED_SHIPPING_PLANS"] = "SET_ACTION_NEEDED_SHIPPING_PLANS";
  InboundListActionTypes["SET_FILTERS_BLADE_OPEN"] = "SET_FILTERS_BLADE_OPEN";
})(InboundListActionTypes || (InboundListActionTypes = {}));
const shippingPlanHasShipments = (hits, shippingPlanId) => hits.some(hit => hit.shippingPlanId === shippingPlanId && !isUndefined(hit.shipmentId));
const filterShippingPlansWithShipments = hits => hits.filter(({
  shipmentId,
  shippingPlanId
}) => shipmentId || !shippingPlanHasShipments(hits, shippingPlanId));
const indexPlanShipments = map(planShipments => planShipments.reverse().map((shipment, shipmentIndex) => ({
  ...shipment,
  shipmentIndex
})));
const addShipmentIndex = flowRight(flatten, indexPlanShipments, Object.values, groupBy("shippingPlanId"));
export const clearSearchCache = () => async () => {
  const ctx = {
    fn: "clearSearchCache"
  };
  log.info(ctx, "clearing search cache");
  algoliaShipmentsService.clearCache();
};
export const searchInbounds = (searchTerm, searchFilters = {}, pageNum) => async (dispatch, getState) => {
  const ctx = {
    fn: "searchInbounds",
    searchTerm,
    searchFilters
  };
  log.info({
    ...ctx,
    pageNum
  }, "searching for inbounds");
  const state = getState();

  // We want to allow entering a single param (either searchTerm or searchFilters)
  // and take the null param from state. That way, we can update by term or filter
  // separately, as would normally be done, without having to grab the other value
  // from state each time.
  const updatedSearchTerm = searchTerm ?? state.inboundList.searchTerm ?? "";
  const updatedFilters = produce(state.inboundList.searchFilters, draft => ({
    ...draft,
    ...searchFilters
  }));
  dispatch({
    type: InboundListActionTypes.SEARCH_INBOUNDS_START,
    searchTerm,
    searchFilters: updatedFilters,
    searchLoading: true
  });
  const hasFirstOrder = getOrgHasFirstOrder(state);
  const {
    admin: {
      showArchivedShippingPlans
    },
    user: {
      resultsPerPage
    }
  } = state;
  let searchResult;
  const term = createSearchTerm(updatedSearchTerm, showArchivedShippingPlans);
  const actionNeededShipments = selectActionNeededShipments(state);
  const filterClauses = createInboundListFilterClause(updatedFilters, actionNeededShipments);
  dispatch(addLoader(InboundListActionTypes.LOAD_INBOUNDS));
  try {
    searchResult = await algoliaShipmentsService.search(term, pageNum, undefined, filterClauses, undefined, resultsPerPage.inboundList);
    log.info({
      ...ctx
    }, "successfully retrieved inbound results");
  } catch (err) {
    log.error({
      ...ctx,
      err,
      searchConfig: {
        term,
        filterClauses,
        pageNum,
        resultsPerPage: resultsPerPage.inboundList
      }
    }, "error searching for inbounds");
    dispatch(clearLoader(InboundListActionTypes.LOAD_INBOUNDS));
    return dispatch({
      type: InboundListActionTypes.SEARCH_INBOUNDS_ERROR,
      searchLoading: false
    });
  }
  const {
    response,
    hits
  } = searchResult;
  const currentPageItems = orderBy(["shippingPlanCreatedAt"], ["desc"], addShipmentIndex(filterShippingPlansWithShipments(hits.map(hit => ({
    ...hit,
    shippingPlanId: hit.raw.shippingPlanId,
    shipmentId: hit.raw.shipmentId
  })))));
  if (!hasFirstOrder) {
    const hasShippedInbounds = currentPageItems.some(item => !isPreShipmentStatus(item.shipmentStatus));
    if (hasShippedInbounds) {
      sendGTMData({
        event: "hasShippedInbounds",
        hasShippedInbounds
      });
    }
  }
  dispatch({
    type: InboundListActionTypes.SEARCH_INBOUNDS_SUCCESS,
    currentPageItems,
    searchInfo: {
      totalPages: response.nbPages,
      currentPage: response.page,
      totalHits: response.nbHits,
      itemsPerPage: response.hitsPerPage
    }
  });
  dispatch(clearLoader(InboundListActionTypes.LOAD_INBOUNDS));
  return;
};
export const searchInboundsByTerm = (searchTerm, pageNum) => async dispatch => dispatch(searchInbounds(searchTerm, undefined, pageNum));
export const searchInboundsByFilters = (filters, pageNum) => async dispatch => dispatch(searchInbounds(undefined, filters, pageNum));
export const resetInboundsFilters = createActionCreator(InboundListActionTypes.RESET_INBOUNDS_FILTERS);
export const getActionNeededShipments = async sellerId => {
  try {
    const response = await sellerProblemsViewClient.getActionNeededShipmentsBySellerId(sellerId);
    return response.data;
  } catch (err) {
    log.error({
      fn: "getActionNeededShipments",
      sellerId
    }, err, "Unable to retrieve non-compliant shipments.");
    return [];
  }
};
export const loadInboundsList = (page, search) => async (dispatch, getState) => {
  const state = getState();
  const actionNeededShipments = await getActionNeededShipments(state.user.sellerId);
  const shippingPlanTasksForSeller = await inboundClient.getIncompleteShippingPlanTasksForSeller();
  const shippingPlansWithIncompleteTasks = shippingPlanTasksForSeller.map(task => task.shippingPlanId);
  dispatch({
    type: InboundListActionTypes.SET_ACTION_NEEDED_SHIPPING_PLANS,
    actionNeededShippingPlans: new Set(shippingPlansWithIncompleteTasks)
  });
  dispatch({
    type: InboundListActionTypes.SET_ACTION_NEEDED_SHIPMENTS,
    actionNeededShipments: new Set(actionNeededShipments)
  });
  clearSearchCache();

  // page - 1 because pagination is tracked with one-based indexing, but
  // Algolia uses zero-based.
  dispatch(searchInboundsByTerm(search ?? "", page && page - 1));
};
export const setSelectedRow = createActionCreator(InboundListActionTypes.SET_SELECTED_ROW, "rowIx", "isSelected");
export const setFiltersBladeOpen = createActionCreator(InboundListActionTypes.SET_FILTERS_BLADE_OPEN, "filtersBladeOpen");
export const setPageSelected = createActionCreator(InboundListActionTypes.SET_PAGE_SELECTED, "isSelected");
export const deleteShippingPlans = () => async (dispatch, getState) => {
  const {
    inboundList,
    user: {
      sellerId
    }
  } = getState();
  const shippingPlanIdsWithInboundSource = getSelectedShippingPlanIdsWithInboundSource(inboundList);
  const ctx = {
    fn: "deleteShippingPlans",
    sellerId,
    shippingPlanIdsWithInboundSource
  };
  log.info(ctx, "deleting shipping plans");
  try {
    await Promise.all(
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    shippingPlanIdsWithInboundSource.map(shippingPlan => shippingPlan.inboundSource === InboundSource.STORAGE ? storageClient.archiveStorageInbound(sellerId, shippingPlan.shippingPlanId) : inboundClient.archiveShippingPlan(sellerId, shippingPlan.shippingPlanId)));
    dispatch(setPageSelected(false));
    dispatch({
      shippingPlanIds: shippingPlanIdsWithInboundSource.map(shippingPlan => shippingPlan.shippingPlanId),
      type: InboundListActionTypes.DELETE_SHIPPING_PLANS_SUCCESS
    });
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error deleting shipping plans");
    toast.error(deleteShippingPlansError);
  }
};
export const duplicateShippingPlan = () => async (dispatch, getState) => {
  const {
    inboundList,
    user: {
      sellerId
    }
  } = getState();
  const [{
    shippingPlanId
  }] = getSelectedShippingPlanIdsWithInboundSource(inboundList);
  const ctx = {
    fn: "duplicateShippingPlan",
    sellerId,
    shippingPlanId
  };
  log.info(ctx, "duplicating shipping plan");
  try {
    log.info(ctx, "getting shipping plan");
    const {
      items: shippingPlanItems,
      useCasePack,
      fromAddress
    } = await inboundClient.getShippingPlan(sellerId, shippingPlanId);
    const shippingPlanItemDskus = shippingPlanItems.map(shippingPlanItem => shippingPlanItem.dsku);
    log.info({
      ...ctx,
      dskus: shippingPlanItemDskus
    }, "getting shipping plan products");
    const productMap = await productClient.getUnifiedProducts(shippingPlanItemDskus);
    const products = Object.values(productMap);
    if (isEmpty(products)) {
      throw new Error("no products found");
    }
    const dskuToQty = fromPairs(shippingPlanItems.map(({
      dsku,
      qty,
      caseQty
    }) => [dsku, {
      qty,
      caseQty
    }]));
    log.info(ctx, "creating new inbound with retrieved products");

    // set state for IPB steps
    dispatch(clearInbound());
    dispatch(setDomesticEcommIPBState());
    fromAddress && dispatch(setFromAddress(fromAddress));
    dispatch(createEcommFulfillmentInbound({
      selectedProducts: products,
      useCasePack,
      dskuToQty,
      skipClearInbound: true
    }));
    dispatch({
      type: InboundListActionTypes.DUPLICATE_SHIPPING_PLANS_SUCCESS
    });
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error while duplicating shipping plan");
    dispatch({
      type: InboundListActionTypes.DUPLICATE_SHIPPING_PLANS_ERROR
    });
  }
};
const getSelectedShippingPlanIdsWithInboundSource = inboundsListState => Object.entries(inboundsListState.selectedRows).filter(([i, isSelected]) => isSelected).map(([i, isSelected]) => ({
  shippingPlanId: inboundsListState.currentPageItems[i].shippingPlanId,
  inboundSource: inboundsListState.currentPageItems[i].inboundSource
}));