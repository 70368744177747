import { emptyAddress } from "common/AddressUtils";
export const freightQuotingInitialState = {
  freightQuotingBladeOpen: false,
  pickupAddress: {
    ...emptyAddress,
    country: ""
  },
  pickupAddressMetadata: {
    isDeliverrWarehouse: false
  },
  pickupLocationType: null,
  pickupLiftgateRequired: false,
  deliveryAddress: {
    ...emptyAddress,
    country: ""
  },
  deliveryAddressMetadata: {
    isDeliverrWarehouse: false
  },
  deliveryLocationType: null,
  deliveryLiftgateRequired: false,
  numberOfPallets: null,
  isLoadedQuote: false,
  quoteRequest: null,
  quoteId: null
};