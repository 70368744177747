import { useIntl } from "react-intl";
import { NotificationTargetType } from "common/clients/notifications/Notification/NotificationTargetType";
import { useDateInWords } from "common/hooks/useDateInWords";
import { Path } from "paths";
import { callToActionMessages, messages } from "./NotificationDetailMessages";
const getCallToActionPath = (type, id) => {
  switch (type) {
    case NotificationTargetType.InboundTargetType:
      return Path.inboundsShippingPlanRedirect.replace(":shippingPlanId", id);
    case NotificationTargetType.MarketplaceOrderTargetType:
      return Path.orderDetail.replace(":orderId", id);
    case NotificationTargetType.BulkUploadTargetType:
      return Path.orderList;
    case NotificationTargetType.SMBProblemDetailsTargetType:
      return id;
  }
};
export const useNotificationDetail = notification => {
  const date = useDateInWords(notification.createdAt);
  const {
    formatMessage
  } = useIntl();
  const messageDescriptor = messages[notification.type];
  const message = messageDescriptor ? formatMessage(messageDescriptor, {
    name: notification.notificationTargetName
  }) : undefined;
  const callToActionMessageDescriptor = callToActionMessages[notification.type];
  const callToActionMessage = callToActionMessageDescriptor ? formatMessage(callToActionMessageDescriptor) : undefined;
  const callToActionPath = getCallToActionPath(notification.notificationTargetType, notification.notificationTargetId);
  return {
    date,
    message,
    callToActionMessage,
    callToActionPath
  };
};