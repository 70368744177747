import { removeAllEmTags } from "common/StringUtils";
import formatCost from "order/FormatCost";
import { OrderListActionTypes } from "../OrderListActionTypes";
export const searchOrdersSuccess = (response, hits) => dispatch => dispatch({
  type: OrderListActionTypes.SEARCH_ORDERS_SUCCESS,
  searchInfo: {
    totalPages: response.nbPages,
    currentPage: response.page,
    totalHits: response.nbHits,
    itemsPerPage: response.hitsPerPage
  },
  currentPageItems: hits.map(hit => ({
    ...hit,
    cost: formatCost(hit.cost, removeAllEmTags(hit.status)),
    items: hit.raw.items
  }))
});