export let Shadow;
(function (Shadow) {
  Shadow["S1"] = "S1";
  Shadow["S2"] = "S2";
  Shadow["S3"] = "S3";
  Shadow["S4"] = "S4";
  Shadow["S5"] = "S5";
})(Shadow || (Shadow = {}));
export const shadow = {
  [Shadow.S1]: "0 1px 2px rgba(0, 1, 0, 0.13)",
  [Shadow.S2]: "0 1px 4px rgba(0, 1, 0, 0.13)",
  [Shadow.S3]: "0 2px 6px rgba(20, 48, 84, 0.2)",
  [Shadow.S4]: "0px 2px 4px 0px rgba(0, 0, 0, 0.05)",
  [Shadow.S5]: "0px 0px 2px 0px rgba(0, 0, 0, 0.05)"
};