import { useSelector } from "react-redux";
import { getShowAdminFeatures } from "common/user/UserSelectors";
import { sumBy } from "lodash/fp";
import { ShippingMethod } from "inbounds/ShippingMethod";
import { getUniqueStorageItemSkus } from "storage/utils/getUniqueStorageItemSkus";
import { FreightType } from "@deliverr/storage-client";
export const useStorageShipmentSummary = () => {
  const {
    storageInboundDetail
  } = useSelector(state => state);
  const {
    storageItems,
    shipmentId,
    createdAt,
    asnId,
    freightOrderDetails
  } = storageInboundDetail.storageInboundOrder;
  const {
    storageItemDetails
  } = storageInboundDetail;
  const getShippingMethod = () => {
    switch (freightOrderDetails?.type) {
      case FreightType.LTL:
        return ShippingMethod.LTL_DELIVERR;
      case FreightType.FTL:
        return ShippingMethod.FTL_DELIVERR;
      default:
        return ShippingMethod.LTL_EXTERNAL;
    }
  };
  const viewShipmentSummaryProps = {
    numOfUnits: sumBy("expectedQty", storageItems),
    numOfSkus: getUniqueStorageItemSkus(storageItems, storageItemDetails).length,
    poNumber: Number(asnId),
    adminViewPONumbers: [],
    createdAt,
    loadedShipmentId: Number(shipmentId),
    shouldShowAdminFeatures: useSelector(getShowAdminFeatures),
    shippingMethod: getShippingMethod()
  };
  return {
    ...viewShipmentSummaryProps
  };
};