import { FeatureName, isFeatureOn } from "common/Split";
import { selectHasPrep } from "prep/store/selectors/selectHasPrep";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getIsFreightExternal } from "inbounds/utils/shippingMethodUtils";
import { createSelector } from "reselect";
import { selectIsForwardingPlan } from "../plan/selectIsForwardingPlan";
import { selectFloorLoadedContainerDetails } from "prep/store/selectors/selectFloorLoadedContainerDetails";
export const selectCanDownloadPrepPalletLabels = createSelector(selectIsForwardingPlan, getLoadedPlannedShipment, selectHasPrep, selectFloorLoadedContainerDetails, (isForwarding, {
  shippingMethod
}, hasPrep, flcDetails) => {
  return isForwarding && getIsFreightExternal(shippingMethod) && Boolean(hasPrep || flcDetails) && isFeatureOn(FeatureName.PrepPalletLabelsEnabled);
});