import { FeatureName } from "common/Split";
import { isFeatureOn } from "common/Split/isFeatureOn";
import { InventoryLocationFilterOptions } from "inventory/list/InventoryListTableControls/InventoryListFilters/InventoryLocationFilter/InventoryLocationFilterOptions";
export const constructOpenSearchFilters = input => {
  const searchTermFilters = getSearchTermFilters(input);
  const productBooleanFilters = getProductBooleanFilters(input);
  const kitFilters = getKitFilters(input);
  const removalFilters = getRemovalFilters(input);
  const reserveStorageFilters = getReserveStorageFilters(input);
  return [...searchTermFilters, ...productBooleanFilters, ...kitFilters, ...removalFilters, ...reserveStorageFilters];
};
export const getSearchTermFilters = ({
  currentTab,
  searchTerm
}) => {
  if (!searchTerm) {
    return [];
  }
  const skuKey = currentTab === "BUNDLES" ? "bsku" : "dsku";
  return [{
    bool: {
      minimum_should_match: "40%",
      must: {
        dis_max: {
          queries: [{
            prefix: {
              name: searchTerm
            }
          }, {
            match: {
              name: {
                query: searchTerm,
                fuzziness: "AUTO"
              }
            }
          }, {
            match: {
              [skuKey]: {
                query: searchTerm,
                fuzziness: "AUTO"
              }
            }
          }, {
            match: {
              msku: {
                query: searchTerm,
                fuzziness: "AUTO"
              }
            }
          }]
        }
      }
    }
  }];
};
const getProductBooleanFilters = ({
  currentTab,
  isHiddenOnSellerPortal
}) => {
  if (currentTab === "BUNDLES") {
    return [];
  }
  return [{
    bool: {
      must_not: [{
        term: {
          _tags: "BRANDED_PACKAGING_DSKU"
        }
      }]
    }
  }, {
    bool: {
      must_not: [{
        term: {
          _tags: "PACK"
        }
      }]
    }
  }, {
    bool: {
      must: [{
        term: {
          isHiddenOnSellerPortal: isHiddenOnSellerPortal ? 1 : 0
        }
      }]
    }
  }];
};
const getKitFilters = ({
  currentTab
}) => {
  if (currentTab !== "PRODUCTS" && currentTab !== "KITS") {
    return [];
  }
  if (!isFeatureOn(FeatureName.PrepKittingEnabled)) {
    return [];
  }
  return [{
    bool: {
      must: {
        term: {
          isKit: currentTab === "KITS" ? 1 : 0
        }
      }
    }
  }];
};
const getRemovalFilters = ({
  currentTab
}) => {
  if (currentTab !== "ELIGIBLE_FOR_REMOVAL") {
    return [];
  }
  const customizedOpenSearchFilters = [];

  // Check products that are NOT OutOfStock : onHandQty > 0
  customizedOpenSearchFilters.push({
    range: {
      onHandQty: {
        gt: 0
      }
    }
  });
  // Check products that are NOT OutOfStock : onHandQty - unfilledOrderQty - unavailableQty > 0
  customizedOpenSearchFilters.push({
    script: {
      script: {
        source: "doc['onHandQty'].value - doc['unfilledOrderQty'].value - doc['unavailableQty'].value > 0",
        lang: "painless"
      }
    }
  });
  // Check products that are NOT InvalidDimensions
  customizedOpenSearchFilters.push({
    bool: {
      must: [{
        exists: {
          field: "dimSourceType"
        }
      }, {
        terms: {
          dimSourceType: ["warehouse", "manual", "seller", "averageregression", "crossdock", "amazonscraped"]
        }
      }]
    }
  });
  // Check Hazmat : hazmatInformation / hazmatInformation.status field is not exist or hazmatInformation.status is APPROVED
  customizedOpenSearchFilters.push({
    bool: {
      should: [{
        bool: {
          must_not: [{
            nested: {
              path: "hazmatInformation",
              query: {
                exists: {
                  field: "hazmatInformation"
                }
              }
            }
          }]
        }
      }, {
        bool: {
          must_not: [{
            nested: {
              path: "hazmatInformation",
              query: {
                exists: {
                  field: "hazmatInformation.status"
                }
              }
            }
          }]
        }
      }, {
        nested: {
          path: "hazmatInformation",
          query: {
            term: {
              "hazmatInformation.status": "APPROVED"
            }
          }
        }
      }]
    }
  });
  return customizedOpenSearchFilters;
};
const getReserveStorageFilters = ({
  inventoryLocation
}) => {
  if (inventoryLocation !== InventoryLocationFilterOptions.RESERVE_STORAGE) {
    return [];
  }
  return [{
    bool: {
      must: {
        term: {
          hasInventoryOnPacks: true
        }
      }
    }
  }];
};