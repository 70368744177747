export let ReturnsOnboardTypes;
(function (ReturnsOnboardTypes) {
  ReturnsOnboardTypes["RESET_RETURNS_ONBOARD"] = "RESET_RETURNS_ONBOARD";
  ReturnsOnboardTypes["RETURNS_ONBOARD_ADD_ADDRESS"] = "RETURNS_ONBOARD_ADD_ADDRESS";
  ReturnsOnboardTypes["RETURNS_GOTO_ONBOARD_STEP"] = "RETURNS_GOTO_ONBOARD_STEP";
  ReturnsOnboardTypes["RETURNS_ONBOARD_SET_DESTINATION_TYPE"] = "RETURNS_DESTINATION_TYPE";
  ReturnsOnboardTypes["RETURNS_ONBOARD_SET_SOP"] = "RETURNS_SET_SOP";
  ReturnsOnboardTypes["RETURNS_SOP_UPDATE_SUCCESS"] = "RETURNS_SOP_UPDATE_SUCCESS";
  ReturnsOnboardTypes["RETURNS_SOP_UPDATE_FAILURE"] = "RETURNS_SOP_UPDATE_FAILURE";
  ReturnsOnboardTypes["RETURNS_ONBOARD_QC_ONLY"] = "RETURNS_ONBOARD_QC_ONLY";
})(ReturnsOnboardTypes || (ReturnsOnboardTypes = {}));
export let ReturnsDestinationType;
(function (ReturnsDestinationType) {
  ReturnsDestinationType["SELF"] = "SELF";
  ReturnsDestinationType["DELIVERR"] = "DELIVERR";
})(ReturnsDestinationType || (ReturnsDestinationType = {}));