var InboundLoaderId;
(function (InboundLoaderId) {
  InboundLoaderId["boxLabelsButton"] = "inboundsBoxLabelsButton";
  InboundLoaderId["confirmEstimate"] = "inboundsConfirmEstimate";
  InboundLoaderId["fetchingQuotes"] = "fetchingQuotes";
  InboundLoaderId["finalizingShipment"] = "finalizingShipment";
  InboundLoaderId["fromAddressSelect"] = "inboundsFromAddressSelect";
  InboundLoaderId["loadingInbound"] = "loadingInbound";
  InboundLoaderId["printBarcodesButton"] = "inboundsPrintBarcodesButton";
  InboundLoaderId["rowButton"] = "inboundRowButton";
  InboundLoaderId["shipmentProblemsTable"] = "inboundsShipmentProblemsTable";
  InboundLoaderId["transition"] = "inboundsTransition";
  InboundLoaderId["updateShipmentFreightAppointmentInfoButton"] = "inboundsUpdateShipmentFreightAppointmentInfoButton";
  InboundLoaderId["updateShipmentFreightDeliveryInfoButton"] = "inboundsUpdateShipmentFreightDeliveryInfoButton";
  InboundLoaderId["updateTrackingButton"] = "updateTrackingButton";
  InboundLoaderId["updateShippingOption"] = "inboundUpdateShippingOption";
  InboundLoaderId["saveBoxPackingConfiguration"] = "inboundSaveBoxPackingConfiguration";
})(InboundLoaderId || (InboundLoaderId = {}));
export default InboundLoaderId;