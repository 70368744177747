import { unknownPage } from "./UnknownPage";
export const getPreviousPageFromLinear = pages => page => {
  const currentPageIndex = pages.findIndex(({
    id
  }) => page.id === id);
  if (currentPageIndex < 1) {
    return unknownPage(page.fastTag);
  } else {
    return pages[currentPageIndex - 1];
  }
};