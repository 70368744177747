import { FacebookEditActionTypes } from "../actions/FacebookEditActionTypes";
export const initialUnsyncStatus = null;
export const unsyncStatusReducer = (state = initialUnsyncStatus, action) => {
  switch (action.type) {
    case FacebookEditActionTypes.FACEBOOK_AD_UNSYNC_COMPLETED:
      return "completed";
    case FacebookEditActionTypes.FACEBOOK_AD_UNSYNC_ERRORED:
      return "errored";
    case FacebookEditActionTypes.FACEBOOK_AD_UNSYNC_REQUESTED:
      return null;
    default:
      return state;
  }
};