import { InboundBookingType } from "@deliverr/legacy-inbound-client";
import { DistributionType, AmazonDestinationType } from "inbounds/createShipment/CreateShipmentTypes";
export const getBookingTypeFromSelections = ({
  isP2P,
  isByAmazon,
  isByFlexport,
  isDTCNetwork,
  distributionType,
  amazonDestinationType
}) => {
  const isAmazonOnly = isByAmazon && !isByFlexport;

  // direct to destination
  if (isP2P) {
    return InboundBookingType.INTL_DIRECT_P2P;
  }
  if (isDTCNetwork) {
    return InboundBookingType.INTL_DTC;
  }
  // fulfillment by flexport
  if (isByFlexport && !isByAmazon) {
    return InboundBookingType.INTL_STORAGE_DTCSTORAGE;
  }
  // fulfillment by flexport & Amazon
  if (isByFlexport && isByAmazon) {
    return InboundBookingType.INTL_STORAGE_STORAGE;
  }
  // fulfillment by flexport and/or amazon
  if (isByAmazon && distributionType === DistributionType.REPLENISH_ON_DEMAND) {
    return InboundBookingType.INTL_STORAGE_FBASTORAGE;
  }
  // fulfillment by amazon only with forwarding, determine based on destination type
  if (isAmazonOnly && (distributionType === DistributionType.FORWARD_TO_AMAZON || distributionType === DistributionType.FORWARD_TO_AMAZON_WITH_UNIT_PREP)) {
    return amazonDestinationType ? {
      [AmazonDestinationType.SINGLE]: InboundBookingType.INTL_STORAGE_FBASINGLE,
      [AmazonDestinationType.MULTIPLE]: InboundBookingType.INTL_STORAGE_FBAMULTI,
      [AmazonDestinationType.UNKNOWN]: InboundBookingType.INTL_STORAGE_FBAUNKOWN,
      [AmazonDestinationType.AUTOMATED]: InboundBookingType.INTL_STORAGE_FBAAUTOMATED
    }[amazonDestinationType] : InboundBookingType.INTL_STORAGE_FBAUNKOWN; // typo in enum
  }
  // fulfillment by amazon only with direct, determine based on destination type
  if (isAmazonOnly && distributionType === DistributionType.DIRECT_TO_AMAZON) {
    return amazonDestinationType ? {
      [AmazonDestinationType.SINGLE]: InboundBookingType.INTL_DIRECT_FBASINGLE,
      [AmazonDestinationType.MULTIPLE]: InboundBookingType.INTL_DIRECT_FBAMULTI,
      [AmazonDestinationType.UNKNOWN]: InboundBookingType.INTL_DIRECT_FBAUNKNOWN
    }[amazonDestinationType] : InboundBookingType.INTL_DIRECT_FBAUNKNOWN;
  }
  return InboundBookingType.INTL_STORAGE_STORAGE;
};