import { useParams } from "react-router-dom";
import { useLocationQuery } from "../../../../common/utils/useLocationQuery";
export const useIntegrationOAuthToken = () => {
  const query = useLocationQuery();
  const {
    token
  } = useParams();
  const stateStr = query.get("state");
  return {
    token,
    state: stateStr ?? undefined
  };
};