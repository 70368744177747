/**
 * @generated SignedSource<<69615d8acfcac3f254d9460f8cabaffa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

const node = function () {
  var v0 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    v1 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    v2 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    v3 = [{
      "kind": "Variable",
      "name": "after",
      "variableName": "after"
    }, {
      "kind": "Variable",
      "name": "before",
      "variableName": "before"
    }, {
      "kind": "Literal",
      "name": "initialFetch",
      "value": false
    }, {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    }],
    v4 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dbid",
      "storageKey": null
    },
    v5 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    v6 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceId",
      "storageKey": null
    },
    v7 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    v8 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    v9 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectName",
      "storageKey": null
    };
  return {
    "fragment": {
      "argumentDefinitions": [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/],

      "kind": "Fragment",
      "metadata": null,
      "name": "FlexportUserNotificationQuery",
      "selections": [{
        "alias": "notifications",
        "args": v3 /*: any*/,
        "concreteType": "UserNotification",
        "kind": "LinkedField",
        "name": "userNotifications",
        "plural": true,
        "selections": [v4 /*: any*/, v5 /*: any*/, v6 /*: any*/, v7 /*: any*/, v8 /*: any*/, v9 /*: any*/],

        "storageKey": null
      }],
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": [v1 /*: any*/, v0 /*: any*/, v2 /*: any*/],

      "kind": "Operation",
      "name": "FlexportUserNotificationQuery",
      "selections": [{
        "alias": "notifications",
        "args": v3 /*: any*/,
        "concreteType": "UserNotification",
        "kind": "LinkedField",
        "name": "userNotifications",
        "plural": true,
        "selections": [v4 /*: any*/, v5 /*: any*/, v6 /*: any*/, v7 /*: any*/, v8 /*: any*/, v9 /*: any*/, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }],
        "storageKey": null
      }]
    },
    "params": {
      "cacheID": "c7202d08f1d0d383e445f13b6ed4865a",
      "id": null,
      "metadata": {},
      "name": "FlexportUserNotificationQuery",
      "operationKind": "query",
      "text": "query FlexportUserNotificationQuery(\n  $before: Int\n  $after: Int\n  $last: Int\n) {\n  notifications: userNotifications(initialFetch: false, before: $before, last: $last, after: $after) {\n    dbid\n    slug\n    referenceId\n    createdAt\n    link\n    objectName\n    id\n  }\n}\n"
    }
  };
}();
node.hash = "0324e4702a2269d60e9763179383c176";
export default node;