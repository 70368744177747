import { createActionCreator } from "common/ReduxUtils";
export let LoadingActionTypes;
(function (LoadingActionTypes) {
  LoadingActionTypes["ADD_LOADER"] = "ADD_LOADER";
  LoadingActionTypes["CLEAR_LOADER"] = "CLEAR_LOADER";
})(LoadingActionTypes || (LoadingActionTypes = {}));
export const addLoader = createActionCreator(LoadingActionTypes.ADD_LOADER, "name");
export const clearLoader = createActionCreator(LoadingActionTypes.CLEAR_LOADER, "name");
export const withLoader = (name, action) => (...params) => async dispatch => {
  dispatch(addLoader(name));
  await dispatch(action(...params));
  dispatch(clearLoader(name));
};