export let ServiceLevel;
(function (ServiceLevel) {
  ServiceLevel["OneDayNonFastTag"] = "1 Day (Non Fast Tag)";
  ServiceLevel["TwoDayNonFastTag"] = "2 Day (Non Fast Tag)";
  ServiceLevel["ThreeDayNonFastTag"] = "3 Day (Non Fast Tag)";
  ServiceLevel["StandardNonFastTag"] = "Standard (Non Fast Tag)";
  ServiceLevel["AKHIOtherNonFastTag"] = "AK/HI/Other (Non Fast Tag)";
  ServiceLevel["WalmartFastTagStandard"] = "Walmart Fast Tag / Standard";
  ServiceLevel["WebsiteFastTag"] = "Website Fast Tag";
  ServiceLevel["Military"] = "US Military (Non Fast Tag)";
  ServiceLevel["eBayFastTagStandard"] = "eBay Fast Tag / Standard";
  ServiceLevel["WishFastTagStandard"] = "Wish Fast Tag / Standard";
  ServiceLevel["GoogleFastTagStandard"] = "Google Fast Tag / Standard";
  ServiceLevel["TargetStandard"] = "Target Standard";
  ServiceLevel["WorldwidePartnerDeliveredDutyPaid"] = "Worldwide Partner Delivered Duty Paid";
  ServiceLevel["WorldwidePartnerDeliveredDutyUnpaid"] = "Worldwide Partner Delivered Duty Unpaid";
  ServiceLevel["WorldwideStandardDeliveredDutyUnpaid"] = "Worldwide Standard Delivered Duty Unpaid";
  ServiceLevel["PriorityOneDay"] = "Expedited 1 Day";
  ServiceLevel["PriorityTwoDay"] = "Expedited 2 Day";
  ServiceLevel["PriorityThreeDay"] = "Expedited 3 Day";
  ServiceLevel["Flexpedited"] = "Flexpedited";
  ServiceLevel["Standard"] = "Standard";
  ServiceLevel["NotSet"] = "Not Set";
  ServiceLevel["WorldwideStandard"] = "Worldwide Standard";
  ServiceLevel["GoogleFastTag"] = "Google Fast Tag";
  ServiceLevel["WishFastTag"] = "Wish Fast Tag";
  ServiceLevel["eBayFastTag"] = "eBay Fast Tag";
  ServiceLevel["CanadaStandard"] = "Canada Standard";
  ServiceLevel["ThreeDay"] = "3 Day";
  ServiceLevel["TwoDay"] = "2 Day";
  ServiceLevel["WalmartTwoDay"] = "Walmart 2 Day";
  ServiceLevel["WalmartThreeDay"] = "Walmart 3 Day";
  ServiceLevel["WalmartStandard"] = "Walmart Standard";
  ServiceLevel["AmazonPrime"] = "Amazon Prime";
  ServiceLevel["eBayThreeDay"] = "eBay 3 Day Pilot";
  ServiceLevel["OneDay"] = "1 Day";
  ServiceLevel["AKHIOther"] = "AK/HI/Other";
  ServiceLevel["ShopifyTwoDay"] = "Shopify 2 Day";
  ServiceLevel["WebsiteTwoDay"] = "Website 2 Day";
  ServiceLevel["WishExpress"] = "Wish Express";
  ServiceLevel["WishTwoDay"] = "Wish 2 Day";
  ServiceLevel["GoogleTwoDay"] = "Google 2 Day";
})(ServiceLevel || (ServiceLevel = {}));