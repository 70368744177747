import { defineMessages } from "react-intl";
import { isNaN, isNil } from "lodash";
import { calculateReserveStorageAvailableQuantity } from "common/utils/product/calculateReserveStorageAvailableQuantity";
export let ProductCasePackError;
(function (ProductCasePackError) {
  ProductCasePackError["LargeCasePack"] = "LargeCasePack";
  ProductCasePackError["OutOfStock"] = "OutOfStock";
})(ProductCasePackError || (ProductCasePackError = {}));
export const ProductCasePackErrorMessages = defineMessages({
  [ProductCasePackError.LargeCasePack]: {
    id: "ProductSearch.largeCasePack",
    defaultMessage: "Case quantity of pack exceeds requested inventory"
  },
  [ProductCasePackError.OutOfStock]: {
    id: "ProductSearch.outOfStock",
    defaultMessage: "Out of stock"
  }
});
const ProductCasePackErrorPredicates = {
  [ProductCasePackError.LargeCasePack]: ({
    productCasePack,
    maximumSelectableCaseQty
  }) => !isNaN(maximumSelectableCaseQty) && productCasePack.quantity > maximumSelectableCaseQty,
  [ProductCasePackError.OutOfStock]: ({
    productCasePack
  }) => calculateReserveStorageAvailableQuantity(productCasePack) === 0
};
export const getProductCasePackErrorMessage = params => {
  const error = Object.keys(ProductCasePackErrorPredicates).find(err => ProductCasePackErrorPredicates[err](params));
  return !isNil(error) ? ProductCasePackErrorMessages[error] : undefined;
};