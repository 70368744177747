import React from "react";
import { useDispatch } from "react-redux";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { values } from "lodash";
import { productIsValidForFBASmallParcel } from "../fba/validations/productIsValidForFBASmallParcel";
export const useParcelValidation = dskus => {
  const [showParcelEstimationError, setShowParcelEstimationError] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    // move this to hook and rename
    const checkParcelValidation = async () => {
      if (dskus?.length) {
        const unifiedProducts = await fetchMultiPackProductsByDskus(dskus, dispatch);
        setShowParcelEstimationError(!values(unifiedProducts).every(product => productIsValidForFBASmallParcel(product)));
      }
    };
    checkParcelValidation().catch(() => setShowParcelEstimationError(false));
  }, [...(dskus ?? []), dispatch]);
  return {
    showParcelEstimationError
  };
};