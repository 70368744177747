import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { searchCurrentInventoryList, setInventoryLocationFilter } from "../../../InventoryListActions";
import { INVENTORY_LOCATION_DISPLAY_TEXT } from "./inventoryLocationFilterDisplayText";
import { InventoryLocationFilterOptions } from "./InventoryLocationFilterOptions";
import { getSelectedInventoryLocation } from "inventory/list/InventoryListSelectors";
import { useAmazonChannelIntegrationStatus } from "transfers/create/steps/fbaIntegration/useAmazonChannelIntegrationStatus";
import { useWalmartChannelIntegrationStatus } from "transfers/create/steps/wfs/useWalmartChannelIntegrationStatus";
export const useInventoryLocationFilter = () => {
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();
  const selectedValue = useSelector(getSelectedInventoryLocation);
  const {
    isFbaCatalogSynced: shouldShowFBAInventory
  } = useAmazonChannelIntegrationStatus();
  const {
    isWalmartConnected: shouldShowWFSInventory
  } = useWalmartChannelIntegrationStatus();
  const filterOptions = Object.entries(INVENTORY_LOCATION_DISPLAY_TEXT).filter(([value]) => {
    return value !== InventoryLocationFilterOptions.AMAZON_FBA || shouldShowFBAInventory;
  }).filter(([value]) => {
    return value !== InventoryLocationFilterOptions.WALMART_WFS || shouldShowWFSInventory;
  }).map(([value, descriptor]) => ({
    value,
    label: formatMessage(descriptor)
  }));
  const handleOnSelect = async ({
    value
  }) => {
    await dispatch(setInventoryLocationFilter(value));
    dispatch(searchCurrentInventoryList());
  };
  return {
    filterOptions,
    selectedValue,
    handleOnSelect
  };
};