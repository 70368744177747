import { CarrierType } from "@deliverr/storage-client";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedQuoteDetails } from "../../selectShipping/actions/resetSelectedQuoteDetails";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { setNumOfPallets } from "../actions/setNumOfPallets";
import { STORAGE_MAX_FTL_PALLETS } from "../CargoTypeConstants";
export const useStoragePalletizedInput = () => {
  const dispatch = useDispatch();
  const {
    numOfPallets,
    carrierType
  } = useSelector(getStorageInboundCreate);
  const [isNumOfPalletsError, setIsNumOfPalletsError] = useState(false);
  const onNumOfPalletsUpdate = ev => {
    dispatch(resetSelectedQuoteDetails());
    dispatch(setNumOfPallets(parseInt(ev.target.value)));
    setIsNumOfPalletsError(carrierType === CarrierType.DELIVERR && parseInt(ev.target.value) > STORAGE_MAX_FTL_PALLETS);
  };
  return {
    numOfPallets,
    isNumOfPalletsError,
    onNumOfPalletsUpdate
  };
};