export const NONE = "NONE";
export const SYNCING = "SYNCING";
export const ERRORED_WHILE_SYNCING = "ERRORED_WHILE_SYNCING";
export const SUCCEEDED = "SUCCEEDED";
export const adSetSyncStatus = ({
  adSets
}) => {
  if (adSets === null) {
    return NONE;
  } else if (adSets === "syncing") {
    return SYNCING;
  } else if (adSets === "error") {
    return ERRORED_WHILE_SYNCING;
  }
  return "SUCCEEDED";
};