import { createReducer, handleSimpleReducerUpdates } from "../../../common/ReduxUtils";
import produce from "immer";
import { WholesaleOrderDetailActionTypes } from "./wholesaleOrderDetailActionTypes";
export const wholesaleOrderDetailInitialState = {
  isMutating: 0,
  isMutationError: false
};
const reducers = {
  ...handleSimpleReducerUpdates([WholesaleOrderDetailActionTypes.WHOLESALE_ORDER_DETAIL_SUCCESS]),
  [WholesaleOrderDetailActionTypes.WHOLESALE_ORDER_DETAIL_CLEAR_LOADER]: state => produce(state, draft => {
    draft.isMutating = Math.max(0, draft.isMutating - 1);
  }),
  [WholesaleOrderDetailActionTypes.WHOLESALE_ORDER_DETAIL_LOAD]: state => produce(state, draft => {
    draft.isMutating++;
  }),
  [WholesaleOrderDetailActionTypes.WHOLESALE_ORDER_DETAIL_ERROR]: state => produce(state, draft => {
    draft.isMutationError = true;
  }),
  [WholesaleOrderDetailActionTypes.WHOLESALE_ORDER_DETAIL_CLEAR_ERROR]: state => produce(state, draft => {
    draft.errorReason = undefined;
    draft.isMutationError = false;
  })
};
export const wholesaleOrderDetailReducer = createReducer(wholesaleOrderDetailInitialState, reducers);