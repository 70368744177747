export let MediaActionTypes;
(function (MediaActionTypes) {
  MediaActionTypes["MEDIA_FETCHED"] = "MEDIA_FETCHED";
  MediaActionTypes["MEDIA_LOADED"] = "MEDIA_LOADED";
  MediaActionTypes["MEDIA_LOADING_ERRORED"] = "MEDIA_LOADING_ERRORED";
})(MediaActionTypes || (MediaActionTypes = {}));
export const mediaHasLoaded = url => ({
  type: MediaActionTypes.MEDIA_LOADED,
  url
});
export const mediaHasBeenFetched = url => ({
  type: MediaActionTypes.MEDIA_FETCHED,
  url
});
export const mediaLoadingFailed = (url, error) => ({
  type: MediaActionTypes.MEDIA_LOADING_ERRORED,
  url,
  error
});