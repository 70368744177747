import { useSelector, useDispatch } from "react-redux";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { sumBy, keysIn } from "lodash/fp";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { storageClient } from "common/clients/instances";
import { getSellerId } from "common/user/UserSelectors";
import history from "BrowserHistory";
import { generatePath } from "react-router-dom";
import { Path } from "paths";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import { logError, logStart } from "Logger";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { updateStorageProductCaseQty } from "./action/updateStorageProductCaseQty";
import { addDays, format } from "date-fns";
import { CommaDateNoYear } from "common/date/DateFormat";
import { FeatureName, isFeatureOn } from "common/Split";
import { track } from "common/utils/Analytics";
import { useIsTestSeller } from "common/hooks/useIsTestSeller";
export const useConfirmShipment = () => {
  const isTestSeller = useIsTestSeller({
    isLoose: true
  }); // will match email domains
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();
  const {
    goToPreviousStep
  } = useStorageInboundNavigation();
  const {
    numOfPallets,
    cargoType,
    selectedProducts,
    containerSize,
    destinationStorageWarehouse,
    freightOrderDetails,
    storageInboundOrderId,
    selectedQuoteDetails,
    carrierType
  } = useSelector(getStorageInboundCreate);
  const isStorageMultiCasePackOn = isFeatureOn(FeatureName.StorageMultiCasePack);
  const {
    fromAddress
  } = useSelector(state => state.inbound);
  const sellerId = useSelector(getSellerId);
  const selectedProductsValues = Object.values(selectedProducts);
  const skuCount = keysIn(selectedProducts).length;
  const unitCount = sumBy("qty", selectedProductsValues);
  const caseCount = sumBy("numberOfCases", selectedProductsValues);
  const doCreateStorageInboundOrder = async () => {
    const ctx = logStart({
      fn: "doCreateStorageInboundOrder"
    });
    try {
      const storageInboundResponse = await storageClient.submitStorageInbound({
        sellerId,
        storageInboundOrderId
      });

      // TODO handled via goToNext
      history.push(generatePath(Path.inboundsStorageShipmentConfirmed, {
        shippingPlanId: storageInboundResponse.value.shippingPlanId
      }));
    } catch (error) {
      logError(ctx, error);
      toast.error(formatMessage({
        id: "storage.inbounds.create.confirmShipment.createStorageInboundOrder.error",
        defaultMessage: "Create shipment failed"
      }));
    }
  };
  const onConfirmShipmentClick = async () => {
    dispatch(addLoader(InboundLoaderId.transition));
    await doCreateStorageInboundOrder();
    if (!isStorageMultiCasePackOn) {
      await dispatch(updateStorageProductCaseQty());
    }
    dispatch(clearLoader(InboundLoaderId.transition));
    if (!isTestSeller) {
      track("ShipmentCreation_Confirmation_Page");
    }
  };
  const onPreviousClick = () => {
    goToPreviousStep();
  };
  const convertToDate = date => {
    if (typeof date === "string") {
      return new Date(date);
    }
    return date;
  };
  return {
    onConfirmShipmentClick,
    onPreviousClick,
    skuCount,
    unitCount,
    caseCount,
    numOfPallets,
    cargoType,
    containerSize,
    toAddress: destinationStorageWarehouse?.address,
    fromAddress,
    cargoReadyDate: freightOrderDetails?.pickupDetails?.appointment?.startTime && format(convertToDate(freightOrderDetails?.pickupDetails?.appointment?.startTime), CommaDateNoYear),
    estimatedArrivalDate: selectedQuoteDetails?.serviceCosts?.[0].transitDays ? format(addDays(new Date(), selectedQuoteDetails?.serviceCosts?.[0]?.transitDays), CommaDateNoYear) : undefined,
    warehouseProvider: destinationStorageWarehouse?.provider,
    carrierType
  };
};