import { PrepOption } from "@deliverr/business-types";
import { transferCreateChooseOriginWarehouse, transferCreateSelectDestinationAddress } from "./actions";
import { createOrUpdateReplenishmentOrder } from "./actions/replenishmentOrder/createOrUpdateReplenishmentOrder";
import { submitReplenishmentOrder } from "./actions/replenishmentOrder/submitReplenishmentOrder";
import { updateShipmentExternalIds } from "./actions/replenishmentOrder/updateShipmentExternalIds";
import { AmazonPickFacilityStep } from "./steps/common/replenishment/AmazonPickFacilityStep";
import { ReplenishmentOrderShippingStep } from "./steps/common/replenishment/ReplenishmentOrderShippingStep";
import { ReplenishmentOrderConfirmation } from "./steps/common/replenishment/confirm/ReplenishmentOrderConfirmation";
import { ReplenishmentShippingDetailsStep } from "./steps/common/replenishment/shipment-details/ReplenishmentShippingDetailsStep";
import { TransferPickProductStep } from "./steps/fba";
import { TransferPickStorageWarehouseStep } from "./steps/fba/TransferPickStorageWarehouseStep";
import { TransferSelectProductQuantityStep } from "./steps/fba/TransferSelectProductQuantityStep";
import { selectTransferCreate } from "./store/TransferCreateSelectors";
import { fetchStorageProductsCaseInfo } from "./store/fetchStorageProductsCaseInfo";
import { TransferCreationTypes } from "./transferCreationTypes";
import { ReplenishmentShipmentCreationError } from "./steps/common/replenishment/ReplenishmentShipmentCreationError";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { CreateTransferLoader } from "./CreateTransferLoader.types";
import { TransferSelectPrepTypeStep } from "prep/create/ReplenishmentPrep/TransferSelectPrepTypeStep";
import { TransferAddAmazonBarcodesForUnitPrep } from "prep/create/ReplenishmentPrep/TransferAddAmazonBarcodesForUnitPrep";
import { isFNSKUOrASINBarcode } from "transfers/utils/isFNSKUBarcode";
import { ReplenishmentOrderCreationError } from "./steps/common/replenishment/errors/ReplenishmentOrderCreationError";
import { compact, isEmpty, keys } from "lodash";
import { SharedConfirmedStep } from "../../common/wholesale-common/components/SharedConfirmedStep";
import { transfersShipmentCreateLabels } from "./transfersShipmentCreate.labels";

// Only enable unit prep for Perez and test storage warehouse
const RS_UNIT_PREP_WAREHOUSES = ["STO_IRM_PER", "STO_TST_LOG", "EWR1RS", "STO_CUS_LAX", "LAX1RS", "DFW1RS"];
export let FbaV2Steps;
(function (FbaV2Steps) {
  FbaV2Steps["SelectAmazonFacility"] = "fba/v2/selectAmazonFacilty";
  FbaV2Steps["SelectProduct"] = "fba/v2/selectProduct";
  FbaV2Steps["ShipmentCreationError"] = "fba/v2/ShipmentCreationError";
  FbaV2Steps["SelectShippingMethod"] = "fba/v2/selectShippingMethod";
  FbaV2Steps["SelectStorageWarehouse"] = "fba/v2/selectStorageWarehouse";
  FbaV2Steps["SelectQuantities"] = "fba/v2/SelectQuantities";
  FbaV2Steps["SelectPrepType"] = "fba/v2/SelectPrepType";
  FbaV2Steps["AddAmazonMissingBarcodesUnitPrep"] = "fba/v2/AddAmazonMissingBarcodesUnitPrep";
  FbaV2Steps["ShippingDetails"] = "fba/v2/addShippingDetails";
  FbaV2Steps["Confirm"] = "fba/v2/confirm";
  FbaV2Steps["Confirmed"] = "fba/v2/confirmed";
  FbaV2Steps["OrderCreationError"] = "fba/v2/orderCreationError";
})(FbaV2Steps || (FbaV2Steps = {}));
export const fbaV2Steps = (dispatch, getState, isFbaUnitPrepOn) => ({
  [FbaV2Steps.SelectAmazonFacility]: {
    component: AmazonPickFacilityStep,
    async next(address) {
      dispatch(transferCreateSelectDestinationAddress(address));
      return FbaV2Steps.SelectProduct;
    }
  },
  [FbaV2Steps.SelectProduct]: {
    component: TransferPickProductStep,
    async next() {
      const {
        availableInventory,
        selectedProducts
      } = selectTransferCreate(getState());
      await dispatch(fetchStorageProductsCaseInfo(Object.keys(selectedProducts)));
      if (availableInventory.length === 1) {
        dispatch(transferCreateChooseOriginWarehouse(availableInventory[0]));
        return FbaV2Steps.SelectQuantities;
      }
      return FbaV2Steps.SelectStorageWarehouse;
    }
  },
  [FbaV2Steps.SelectStorageWarehouse]: {
    component: TransferPickStorageWarehouseStep,
    next: async () => FbaV2Steps.SelectQuantities
  },
  [FbaV2Steps.SelectQuantities]: {
    component: TransferSelectProductQuantityStep,
    next: async () => {
      const {
        originStorageWarehouse
      } = selectTransferCreate(getState());
      // If unit prep is on, create replenishment order after prep selection
      if (isFbaUnitPrepOn && originStorageWarehouse?.warehouse?.id && !!RS_UNIT_PREP_WAREHOUSES.includes(originStorageWarehouse?.warehouse.id)) {
        return FbaV2Steps.SelectPrepType;
      }
      if (await createOrUpdateReplenishmentOrder(dispatch, getState, true)) {
        return FbaV2Steps.SelectShippingMethod;
      } else {
        return FbaV2Steps.OrderCreationError;
      }
    },
    reassess: () => {
      const {
        selectedProducts
      } = selectTransferCreate(getState());
      if (Object.keys(selectedProducts).length === 0) {
        return FbaV2Steps.SelectProduct;
      }
      return;
    }
  },
  [FbaV2Steps.OrderCreationError]: {
    component: ReplenishmentOrderCreationError,
    next: async () => {
      const {
        selectedProducts,
        prepOption
      } = selectTransferCreate(getState());
      if (isEmpty(compact(keys(selectedProducts)))) {
        return FbaV2Steps.SelectProduct;
      }
      if (await createOrUpdateReplenishmentOrder(dispatch, getState, true)) {
        return prepOption === PrepOption.UNIT_PREP && Object.values(selectedProducts).some(({
          barcodes
        }) => !barcodes?.find(isFNSKUOrASINBarcode)) ? FbaV2Steps.AddAmazonMissingBarcodesUnitPrep : FbaV2Steps.SelectShippingMethod;
      }
      return;
    }
  },
  [FbaV2Steps.SelectPrepType]: {
    component: TransferSelectPrepTypeStep,
    next: async () => {
      const {
        selectedProducts,
        prepOption
      } = selectTransferCreate(getState());
      // call API to update FBA prep option
      if (await createOrUpdateReplenishmentOrder(dispatch, getState, true)) {
        return prepOption === PrepOption.UNIT_PREP && Object.values(selectedProducts).some(({
          barcodes
        }) => !barcodes?.find(isFNSKUOrASINBarcode)) ? FbaV2Steps.AddAmazonMissingBarcodesUnitPrep : FbaV2Steps.SelectShippingMethod;
      } else {
        return FbaV2Steps.OrderCreationError;
      }
    }
  },
  [FbaV2Steps.AddAmazonMissingBarcodesUnitPrep]: {
    component: TransferAddAmazonBarcodesForUnitPrep,
    hasNoBack: true,
    next: async () => {
      return FbaV2Steps.SelectShippingMethod;
    }
  },
  [FbaV2Steps.ShipmentCreationError]: {
    component: ReplenishmentShipmentCreationError,
    hasNoBack: true
  },
  [FbaV2Steps.SelectShippingMethod]: {
    hasNoBack: true,
    component: ReplenishmentOrderShippingStep,
    async next() {
      const {
        destinationType
      } = selectTransferCreate(getState());
      if (destinationType === TransferCreationTypes.Fba) {
        return FbaV2Steps.ShippingDetails;
      }
      return FbaV2Steps.Confirm;
    }
  },
  [FbaV2Steps.Confirm]: {
    component: ReplenishmentOrderConfirmation,
    next: async () => {
      if (await submitReplenishmentOrder(dispatch, getState)) {
        return FbaV2Steps.Confirmed;
      }
      return undefined;
    }
  },
  [FbaV2Steps.ShippingDetails]: {
    component: ReplenishmentShippingDetailsStep,
    async next(value) {
      dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
      const {
        replenishmentOrder
      } = selectTransferCreate(getState());
      const shipmentId = replenishmentOrder?.shipments?.[0]?.shipmentId ?? "";
      await dispatch(updateShipmentExternalIds(shipmentId, value.attachmentTypes));
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
      return FbaV2Steps.Confirm;
    }
  },
  [FbaV2Steps.Confirmed]: {
    component: SharedConfirmedStep(transfersShipmentCreateLabels),
    hasNoBack: true
  }
});