/** @module common/date/DateFormat */

export const SlashDate = "M/d/yyyy";
export const FullSlashDate = "MM/dd/yyyy";
export const CommaDate = "MMM d, yyyy";

// E.g. Jan 1
export const CommaDateNoYear = "MMM d";
export const FullCommonDate = "MMMM d, yyyy";
export const TimeWithTz = "h:mm aaa z";
export const TimeWithoutTz = "h:mm aaa";
export const TimeWithTzCap = "h:mm a z";
export const VanillaTime = "h:mm a";
export const MilitaryTime = "HH:mm";
export const ShortDate = "M/d";
export const ShortDateTimeWithTz = "M/d - haaa z";
export const ISODate = "yyyy-MM-dd";
export const DayOfWeekAndMonth = "EEEE, MMMM do";
export const FileNameTimestamp = "yyyyMMdd'T'HHmmss";
export const ShortDayMonth = "EEE, MMM d";

// E.g. Jan 1, 2021
export const ShortMonthDateYear = "MMM d, yyyy";

// E.g. 06-15-2021
export const DashFullDate = "MM-dd-yyyy";

// E.g. Jan 2021
export const MonthAndYear = "MMM yyyy";

// E.g EST
export const TimeZone = "z";

// E.g. January 1, 2022, 12:01 AM
export const FullMonthDateAndTime = "PPp";

// I.e. Thursday, Jul 4, 1776 at 12:30pm
export const TimelineDate = "EEEE, LLLL d, yyyy 'at' hh:mm aaa";