import { createActionCreator } from "common/ReduxUtils";
import { ReturnsCancelTypes } from "./ReturnsCancelStore.types";
import { returnsClient } from "Clients";
export const resetReturnCancel = createActionCreator(ReturnsCancelTypes.RETURNS_CANCEL_RESET);
export const cancelReturnOrder = returnId => async dispatch => {
  try {
    await returnsClient.cancelReturn(returnId);
    dispatch({
      type: ReturnsCancelTypes.RETURNS_CANCEL_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: ReturnsCancelTypes.RETURNS_CANCEL_FAIL
    });
  }
};