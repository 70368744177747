import { storageClient } from "common/clients/instances";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { logError } from "Logger";
import { CreateTransferLoader } from "../CreateTransferLoader.types";
import { setAvailableInventoryInEachStorageWarehouse } from "./setAvailableInventoryInEachStorageWarehouse";
import { transferCreateSetReplenishmentError } from "../actions";
import { batch } from "react-redux";
import { ReplenishmentPickProductErrorCodes } from "../steps/common/replenishment/errors/pick-products-errors/ReplenishmentPickProductErrorCodes";
import { isEmpty } from "lodash";
export const getAvailableInventoryInEachStorageWarehouse = (dskuQuantities, includeInventoryBreakdown) => async dispatch => {
  batch(() => {
    dispatch(transferCreateSetReplenishmentError({
      errors: []
    }));
    dispatch(addLoader(CreateTransferLoader.FetchStorageInventoryLoader));
  });
  let availableInventoryInEachStorageWarehouse;
  try {
    availableInventoryInEachStorageWarehouse = includeInventoryBreakdown ? (await storageClient.getUnifiedAvailablePackInventoryByWarehouse(dskuQuantities))?.value : (await storageClient.validateAndReturnInventoryAvailableInEachStorageWarehouse(dskuQuantities))?.value;
    if (!isEmpty(availableInventoryInEachStorageWarehouse)) {
      dispatch(setAvailableInventoryInEachStorageWarehouse(availableInventoryInEachStorageWarehouse));
    } else {
      if (dskuQuantities.length === 1) {
        dispatch(transferCreateSetReplenishmentError({
          errors: [{
            code: ReplenishmentPickProductErrorCodes.PRODUCT_NOT_AVAILABLE_IN_ANY_WAREHOUSE,
            message: "Product not available in any warehouse",
            name: "SingleProductNotAvailableInAnyWarehouse"
          }]
        }));
      } else {
        dispatch(transferCreateSetReplenishmentError({
          errors: [{
            code: ReplenishmentPickProductErrorCodes.PRODUCTS_NOT_AVAILABLE_AT_SAME_WAREHOUSE,
            message: "Products are not available at the same warehouse",
            name: "ProductsNotAvailableAtSameWarehouse"
          }]
        }));
      }
    }
  } catch (err) {
    logError({
      fn: "getAvailableInventoryInEachStorageWarehouse"
    }, err);
    dispatch(transferCreateSetReplenishmentError({
      errors: err?.response?.data?.error ? [err?.response?.data?.error] : []
    }));
  } finally {
    dispatch(clearLoader(CreateTransferLoader.FetchStorageInventoryLoader));
  }
  return availableInventoryInEachStorageWarehouse;
};