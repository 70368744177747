import { unknownPage } from "./UnknownPage";
export const getNextPageFromLinear = pages => page => {
  const currentPageIndex = pages.findIndex(({
    id
  }) => page.id === id);
  if (currentPageIndex < 0 || currentPageIndex === pages.length) {
    return unknownPage(page.fastTag);
  } else {
    return pages[currentPageIndex + 1];
  }
};