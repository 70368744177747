import { userClient } from "common/clients/instances";
import { useScript } from "common/hooks/useScript";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
export const useIntercomChatLauncher = onLaunch => {
  const {
    email,
    name,
    isAdmin
  } = useSelector(state => state.user);
  const organizationId = useSelector(state => state.organization.sellerId);
  const status = useScript("https://widget.intercom.io/widget/ly1fz3zd");
  const intercomHash = useMemo(async () => (await userClient.getUserIntercomHash(email))?.data?.hash, [email]);
  const bootIntercom = useCallback(async () => window.Intercom?.("boot", {
    app_id: "ly1fz3zd",
    email,
    hide_default_launcher: true,
    user_id: email,
    name,
    api_base: `https://ly1fz3zd.intercom-messenger.com`,
    user_hash: await intercomHash,
    company: {
      id: organizationId,
      name: organizationId
    }
  }), [email, name, organizationId, intercomHash]);
  useEffect(() => {
    status === "ready" && !isAdmin && bootIntercom();
    return () => !isAdmin && window.Intercom?.("shutdown");
  }, [status, isAdmin, bootIntercom]);
  const onClick = event => {
    event.preventDefault();
    event.stopPropagation();
    isAdmin && bootIntercom();
    window.Intercom("show");
    onLaunch?.();
  };
  return {
    onClick,
    bootIntercom
  };
};