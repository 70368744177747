import produce from "immer";
import { isEmpty } from "lodash/fp";
import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { WholesaleSourceType } from "./WholesaleListState";
import { WholesaleListActionTypes } from "../actions/WholesaleListActionTypes";
import { shipmentListItemResponseToWholesaleListItem } from "../AlgoliaWholesaleRow";
import { WHOLESALE_LIST_STATUSES } from "../WholesaleListStatuses";
import { ReplenishmentOrderListTabFilter } from "transfers/list/store/TransferListState";
export const wholesaleListInitialState = {
  searchTerm: "",
  filters: {},
  sort: {
    fieldName: "",
    direction: SortDir.ASC
  },
  pageNum: 0,
  searchFilters: {
    wholesaleListStatuses: [...WHOLESALE_LIST_STATUSES],
    sourceChannel: WholesaleSourceType.ALL,
    tabFilter: ReplenishmentOrderListTabFilter.ALL_ORDERS
  }
};
const reducers = {
  ...handleSimpleReducerUpdates([WholesaleListActionTypes.WHOLESALE_LIST_GET_ERROR]),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_SEARCH_TERM]: (state, {
    searchTerm
  }) => produce(state, draft => {
    draft.searchTerm = searchTerm;
    if (isEmpty(searchTerm)) {
      draft.pageItems = undefined;
    }
    draft.pageNum = 0;
  }),
  [WholesaleListActionTypes.WHOLESALE_LIST_GET_SUCCESS]: (state, {
    listData: {
      shipments,
      nbPages,
      pageNum
    }
  }) => produce(state, draft => {
    draft.pageItems = shipments.map(shipmentListItemResponseToWholesaleListItem);
    draft.nbPages = nbPages;
    draft.pageNum = pageNum;
    draft.isError = false;
  }),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_PAGE_NUM]: (state, {
    pageNum: num
  }) => produce(state, draft => {
    draft.pageNum = num;
    draft.isError = false;
  }),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_STATUS_SEARCH_FILTERS]: (state, {
    wholesaleListStatuses
  }) => produce(state, draft => {
    draft.searchFilters.wholesaleListStatuses = wholesaleListStatuses;
  }),
  [WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_SOURCE_CHANNEL_SEARCH_FILTERS]: (state, {
    souceChannel
  }) => produce(state, draft => {
    draft.searchFilters.sourceChannel = souceChannel;
  }),
  [WholesaleListActionTypes.WHOLESALE_LIST_SET_TAB_FILTER]: (state, {
    tabFilter
  }) => produce(state, draft => {
    draft.searchFilters.tabFilter = tabFilter;
  })
};
export const wholesaleListReducer = createReducer(wholesaleListInitialState, reducers);