import { compact, flatten, flowRight, fromPairs, get, groupBy, isEqual, map, mapValues, sortBy, sumBy, uniqBy, flow, values, some } from "lodash/fp";
import { createSelector } from "reselect";
import { ProductPrepCategory } from "@deliverr/commons-clients/lib/product/ProductPreparation";
import { getItemsFromById } from "common/ById";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { MAX_SKU_QTY, packageHasZeroUnits, tooManySkusInPackage } from "inbounds/InboundValidationUtils";
import { isConfirmedShipmentStatus } from "inbounds/ShipmentStatus";
import BoxArrangement from "inbounds/steps/ship/BoxArrangement";
import { getDispersalMethodFromFlag, isShipToOneDispersalMethod } from "./ship/InboundUtils";
import { getLoadedShipment } from "inbounds/store/selectors/shipments/getLoadedShipment";
import { getProductDetailsCache } from "inbounds/store/selectors/productDetailsCache";
import { getIsSpdDeliverr, getIsSpdExternal } from "inbounds/utils/shippingMethodUtils";
import { getIsMetro } from "inbounds/utils/shippingMethodUtils/getIsMetro";
import { INBOUND_PKG_MAX_SKUS_PER_BOX } from "inbounds/constants/packageConstants";
import { selectLoadedShipmentItems } from "inbounds/store/selectors/shipments/selectLoadedShipmentItems";
import { selectPlanItems } from "inbounds/store/selectors/plan/selectPlanItems";
import { getNonEmptyShipmentItems } from "../store/util/getNonEmptyShipmentItems";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { BoxErrorType } from "./ship/BoxPackingArrangement/table/BoxError";
export const getInboundState = ({
  inbound
}) => inbound;
export const getLoadedPlannedShipment = createSelector(state => state.inbound.loadedShipmentId, state => state.inbound.plannedShipments, (loadedShipmentId, plannedShipments) => plannedShipments.byId[loadedShipmentId]);
export const getShippingPlan = createSelector(get("inbound"), inbound => inbound.plan);
export const getShippingPlanId = createSelector(getShippingPlan, shippingPlan => shippingPlan.id);
const getShipmentItemDetails = (shipmentItems, productDetailsCache) => shipmentItems.map(({
  shipmentId,
  dsku,
  qty
}) => {
  const {
    name,
    msku
  } = productDetailsCache[dsku];
  return {
    shipmentId,
    dsku,
    name,
    msku,
    qty
  };
});
export const getPlanItemDetails = createSelector(selectPlanItems, getProductDetailsCache, (planItems, productDetailsCache) => {
  const itemsDetails = map(({
    qty,
    dsku
  }) => {
    const {
      name,
      msku,
      productPreparation,
      customsInformation,
      brandedPackaging
    } = productDetailsCache[dsku];
    return {
      dsku,
      name,
      msku,
      qty,
      customsInformation,
      brandedPackaging,
      // prevent BP triggering category assignment by providing default of OTHER
      category: brandedPackaging ? ProductPrepCategory.OTHER : productPreparation?.category,
      packagingType: productPreparation?.packagingType
    };
  }, planItems);
  return sortBy("dsku", itemsDetails);
});
export const getShippingPlanBulkUploadSessionId = state => state.inbound.plan.sessionUuid;
export const getBulkUploadSessionId = state => state.inbound.bulkUploadSessionId;
export const getProductCategories = createSelector(getPlanItemDetails, itemDetails => itemDetails.map(({
  category
}) => category));
export const getPlanPackagingTypes = createSelector(getPlanItemDetails, itemDetails => {
  const packagingTypes = {};
  itemDetails.forEach(({
    packagingType
  }) => {
    if (packagingType) {
      packagingTypes[packagingType] = true;
    }
  });
  return packagingTypes;
});
export const getLoadedShipmentItemDetails = createSelector(selectLoadedShipmentItems, get("inbound.productDetailsCache"), getShipmentItemDetails);
export const getAllShipmentItemDetails = createSelector(get("inbound.shipments"), get("inbound.productDetailsCache"), (shipments, productDetailsCache) => mapValues(shipment => getShipmentItemDetails(getNonEmptyShipmentItems(shipment), productDetailsCache), shipments.byId));
export const getLoadedShipmentSkuCount = createSelector(selectLoadedShipmentItems, flowRight(get("length"), uniqBy("dsku")));

/**
 * based on the number of SKUs in the shipment and the max number of unique SKUs per box,
 * return the minimum number of boxes to use
 */
export const getMinMultiSkuBoxCount = createSelector(getLoadedShipmentSkuCount, skuCount => Math.ceil(skuCount / INBOUND_PKG_MAX_SKUS_PER_BOX));

// When they reduce the # of packages, we keep the truncated packages so they can be restored
export const getLoadedShipmentPackages = createSelector(getLoadedPlannedShipment, ({
  packages,
  packageCount
}) => packages.slice(0, packageCount));
export const getBoxWeights = createSelector(getLoadedShipmentPackages, packages => packages.map(({
  weight
}) => weight));
export const getBoxErrors = createSelector(getLoadedPlannedShipment, getLoadedShipmentPackages, ({
  boxConfirmAttempted
}, packages) => compact(packages.map(pkg => {
  if (boxConfirmAttempted) {
    if (tooManySkusInPackage(pkg)) {
      return BoxErrorType.TOO_MANY_SKUS;
    }
    if (packageHasZeroUnits(pkg)) {
      return BoxErrorType.ZERO_UNITS;
    }
  }
  return undefined;
})));
export const getLoadedShipmentBoxTotalBySku = createSelector(getLoadedPlannedShipment, ({
  packages,
  packageCount,
  identicalPackageCounts
}) => {
  const dskuToPackageItems = groupBy("dsku", flatten(packages.slice(0, packageCount).map(({
    items
  }, index) => {
    return items.map(item => {
      return {
        ...item,
        qty: item.qty * identicalPackageCounts[index]
      };
    });
  })));
  return mapValues(sumBy("qty"), dskuToPackageItems);
});
export const getQtyByShipmentItemPerBox = createSelector(getLoadedShipmentPackages, packages => {
  const quantities = {};
  packages.forEach((box, boxIndex) => {
    box.items.forEach(({
      dsku,
      qty
    }) => {
      if (!quantities[dsku]) {
        quantities[dsku] = {};
      }
      quantities[dsku][boxIndex] = qty;
    });
  });
  return quantities;
});
export const getIsPlannedShipmentIndividualDeliverr = createSelector(getLoadedPlannedShipment, ({
  shippingMethod
}) => getIsSpdDeliverr(shippingMethod));
export const getIsPlannedShipmentIndividualPersonal = createSelector(getLoadedPlannedShipment, ({
  shippingMethod
}) => getIsSpdExternal(shippingMethod));
export const getIsPlannedShipmentMetroDeliverr = createSelector(getLoadedPlannedShipment, ({
  shippingMethod
}) => getIsMetro(shippingMethod));
export const getShippingPlanExists = createSelector(get("inbound.plan.id"), Boolean);
export const getInboundIsLoaded = createSelector(get("inbound.plan.name"), name => name !== "");
export const getLoadedShipmentWarehouse = createSelector(getLoadedShipment, get("deliverr.warehouses"), ({
  warehouseId
}, warehouses) => warehouses[warehouseId]);
export const getUseCasePackModified = state => {
  if (state.inbound.oldState === undefined) {
    return false;
  }
  return state.inbound.oldState.plan.useCasePack !== state.inbound.plan.useCasePack;
};
export const getPlanItemsHaveBeenModified = state => {
  if (state.inbound.oldState === undefined) {
    return false;
  }
  return !isEqual(state.inbound.oldState.planItems.byId, state.inbound.planItems.byId);
};
export const getShipmentIdToShipmentNumber = createSelector(get("inbound.shipments.ids"), shipmentIds => fromPairs(shipmentIds.slice().map((id, i) => [id, i + 1])));
export const getShipmentIdToWarehouse = createSelector(get("inbound.shipments"), get("deliverr.warehouses"), (shipments, warehouses) => mapValues(shipment => warehouses[shipment.warehouseId], shipments.byId));
export const getLoadedAsns = createSelector(get("inbound.loadedShipmentId"), get("inbound.asns"), (shipmentId, asns) => asns[shipmentId]);
export const shipmentContainsOneUnitPerBox = createSelector(getLoadedPlannedShipment, ({
  boxArrangement,
  packages
}) => boxArrangement === BoxArrangement.OneSKUPerBox && packages.some(pkg => pkg.items[0].qty === 1));
export const getSortedShipments = createSelector(state => state.inbound.shipments, shipments => sortBy(({
  warehouseId,
  crossDockWarehouseId
}) => !(crossDockWarehouseId && warehouseId === crossDockWarehouseId), getItemsFromById(shipments)));
export const getLoadedShipmentNumber = createSelector(getSortedShipments, state => state.inbound.loadedShipmentId, (shipments, loadedShipmentId) => shipments.findIndex(({
  id
}) => id === loadedShipmentId) + 1);
export const getShipmentsConfirmed = createSelector(state => state.inbound.shipments.byId, shipments => mapValues(({
  status
}) => isConfirmedShipmentStatus(status), shipments));
export const getLoadedShipmentConfirmed = createSelector(state => state.inbound.loadedShipmentId, getShipmentsConfirmed, (loadedShipmentId, shipmentIdToShipmentConfirmed) => Boolean(loadedShipmentId && shipmentIdToShipmentConfirmed[loadedShipmentId]));
export const getPreviousStepsNoLongerModifiable = createSelector(getShipmentsConfirmed, shipmentsConfirmed => Object.values(shipmentsConfirmed).some(shipmentConfirmed => shipmentConfirmed));
export const getPlannedPackages = createSelector(getInboundState, ({
  plannedPackages
}) => plannedPackages);
export const getPlannedBarcodes = createSelector(getInboundState, ({
  plannedBarcodes
}) => plannedBarcodes);
export const getBoxesLocked = createSelector(getLoadedShipmentConfirmed, getLoadedPlannedShipment, getLoadedShipment, (loadedShipmentConfirmed, {
  boxesConfirmed
}, {
  status
}) => loadedShipmentConfirmed || boxesConfirmed || status === InboundShipmentStatus.PACKAGES_ADDED);
export const getBoxesEditable = createSelector(getBoxesLocked, getPlannedPackages, getSortedShipments, (boxesLocked, plannedPackages, shipments) => !boxesLocked && (shipments.length > 1 || plannedPackages.length <= 1));
export const getProductsLocked = createSelector(getPlannedPackages, plannedPackages => plannedPackages.length > 0);
export const getShouldShowBulkUploadBanner = createSelector(getBoxesLocked, getSortedShipments, (boxesLocked, shipments) => shipments.length === 1 && !boxesLocked);
export const getIsTransitioning = createSelector(state => state.loading.loaders, loaders => loaders.includes(InboundLoaderId.transition));
export const getCrossDockWarehouse = createSelector(state => state.deliverr.warehouses, getLoadedShipment, (warehousesById, {
  crossDockWarehouseId
}) => warehousesById[crossDockWarehouseId]);
export const getShipmentsConfirmedCount = createSelector(state => state.inbound.shipments.ids, getShipmentsConfirmed, (shipmentIds, shipmentIdToShipmentConfirmed) => sumBy(shipmentId => {
  const isConfirmed = shipmentIdToShipmentConfirmed[shipmentId];
  return isConfirmed ? 1 : 0;
}, shipmentIds));
export const getInboundDispersalMethod = createSelector(state => state.inbound.dispersalMethod, state => state.inbound.isRedistributions, (dispersalMethod, isRedistributions) => dispersalMethod ?? getDispersalMethodFromFlag(isRedistributions));
export const getInboundIsShipToOne = state => isShipToOneDispersalMethod(getInboundDispersalMethod(state));

/**
 * Determine if a Shipping Plan has at least one PlanItem with a SKU count over the
 * permissible limit for a Direct Shipping Plan.
 * @param state
 */
export const getIsOverSkuPerDskuLimit = state => {
  const itemIds = state.inbound.planItems.ids;
  return itemIds.some(id => state.inbound.planItems.byId[id]?.qty > MAX_SKU_QTY);
};

/**
 * Get the total quantity of all units on a ShippingPlan
 * @param state
 */
export const getTotalUnitQuantity = state => {
  const itemIds = state.inbound.planItems.ids;
  return itemIds.reduce((sum, id) => sum + (state.inbound.planItems.byId[id]?.qty ?? 0), 0);
};
export const getIsFinalUnconfirmedShipment = createSelector(state => state.inbound.shipments.ids.length, getShipmentsConfirmedCount, getLoadedShipmentConfirmed, (numOfShipments, numOfConfirmedShipments, loadedShipmentConfirmed) => numOfConfirmedShipments === numOfShipments - 1 && !loadedShipmentConfirmed || numOfConfirmedShipments === numOfShipments);
export const getNextIncompleteShipmentId = createSelector(state => state.inbound.shipments.ids, getShipmentsConfirmed, (shipmentIds, shipmentIdToShipmentConfirmed) => shipmentIds.find(shipmentId => !shipmentIdToShipmentConfirmed[shipmentId]));
export const getAllShipmentsConfirmed = createSelector(state => state.inbound.shipments.ids.length, getShipmentsConfirmedCount, (shipmentsConfirmedCount, shipmentsCount) => shipmentsConfirmedCount === shipmentsCount);
export const getIsKittedProductSelected = createSelector(getProductDetailsCache, productDetailsCache => flow(values, some(product => product.isKit ?? false))(productDetailsCache));
export const getIsNonKittedProductSelected = createSelector(getProductDetailsCache, productDetailsCache => flow(values, some(product => !product.isKit))(productDetailsCache));