import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { toast } from "common/components/ui";
import { getStorageInboundDetail } from "../../../store";
import isEmail from "validator/lib/isEmail";
import React from "react";
import { storageClient } from "common/clients/instances";
import log from "Logger";
export const useAdvancedAppointmentSchedulingDetail = () => {
  const {
    formatMessage
  } = useIntl();
  const [isCarrierEmailValid, setIsCarrierEmailValid] = React.useState(true);
  const [notifyCarrierDisabled, setNotifyCarrierDisabled] = React.useState(true);
  const {
    storageInboundOrder
  } = useSelector(getStorageInboundDetail);
  const [carrierEmail, setCarrierEmail] = React.useState(storageInboundOrder?.carrierEmailAddress ?? "");
  const [showAppointmentEmailInputScreen, setShowAppointmentEmailInputScreen] = React.useState(!storageInboundOrder?.carrierEmailAddress);
  const [isError, setIsError] = React.useState(false);
  const validateEmail = inputEmail => {
    if (isEmail(inputEmail)) {
      setIsCarrierEmailValid(true);
      setNotifyCarrierDisabled(false);
    } else {
      setIsCarrierEmailValid(false);
      setNotifyCarrierDisabled(true);
    }
  };
  const handleBlur = () => {
    if (!isCarrierEmailValid) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };
  const handleFocus = () => {
    setIsError(false);
  };
  const onSubmit = async () => {
    try {
      await storageClient.requestForInboundAppointment({
        carrierEmailAddress: carrierEmail
      }, storageInboundOrder.id);
      setShowAppointmentEmailInputScreen(false);
      setCarrierEmail(carrierEmail ?? "");
      toast.success(formatMessage({
        id: "storage.inbounds.details.schedule.appointment.success",
        defaultMessage: "An email was sent to your carrier"
      }));
    } catch (e) {
      const ctx = {
        fn: "onNotifyCarrierError"
      };
      log.error({
        ...ctx,
        e
      }, "Failed to send an email notification to your carrier");
      toast.error(formatMessage({
        id: "storage.inbounds.details.schedule.appointment.error",
        defaultMessage: "Failed to send an email notification to your carrier"
      }));
    }
  };
  const emailInputPlaceHolder = formatMessage({
    id: "storage.inbounds.detail.storage.inbounds.detail.emailInputPlaceholder",
    defaultMessage: "Enter email"
  });
  const sendToAnotherEmailClick = () => {
    setCarrierEmail("");
    setShowAppointmentEmailInputScreen(true);
    setNotifyCarrierDisabled(true);
  };
  const onCarrierEmailChange = e => {
    setCarrierEmail(e.target.value);
    validateEmail(e.target.value);
  };
  return {
    onSubmit,
    showAppointmentEmailInputScreen,
    carrierEmail,
    notifyCarrierDisabled,
    sendToAnotherEmailClick,
    emailInputPlaceHolder,
    onCarrierEmailChange,
    handleBlur,
    isError,
    handleFocus
  };
};