import { toast } from "common/components/ui";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { getLotInformationDefaultValues } from "inbounds/steps/lot-information/utils/getLotInformationDefaultValues";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { updateStorageInboundOrder } from "../store/actions/updateStorageInboundOrder";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { setStorageInboundLotInformation } from "./actions/setStorageInboundLotInformation";
import { useStorageLotInformationProducts } from "./useStorageLotInformationProducts";
export const useStorageLotInformation = () => {
  const dispatch = useDispatch();
  const {
    formatMessage
  } = useIntl();
  const {
    goToNextStep,
    goToPreviousStep
  } = useStorageInboundNavigation();
  const {
    products
  } = useStorageLotInformationProducts();
  const {
    selectedProductsLotInformation
  } = useSelector(getStorageInboundCreate);
  const formHandler = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: products.reduce((acc, {
      dsku
    }) => {
      return {
        ...acc,
        [dsku]: selectedProductsLotInformation[dsku] ?? getLotInformationDefaultValues()
      };
    }, {})
  });
  const onSubmit = formHandler.handleSubmit(async data => {
    try {
      dispatch(addLoader(InboundLoaderId.transition));
      await dispatch(setStorageInboundLotInformation(data));
      if (await dispatch(updateStorageInboundOrder())) {
        await goToNextStep();
      }
    } catch (err) {
      toast.error(formatMessage({
        id: "storage.inbounds.lotInformation.submissionErrorMessage",
        defaultMessage: "There was an error updating product information. Please try again or contact support."
      }));
    } finally {
      dispatch(clearLoader(InboundLoaderId.transition));
    }
  });
  return {
    onNextClick: onSubmit,
    onPreviousClick: goToPreviousStep,
    formHandler
  };
};