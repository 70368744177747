import { storageClient } from "common/clients/instances";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { setNumOfPallets } from "./setNumOfPallets";
import { logError, logStart } from "Logger";
import { setEstimatedNumOfPallets } from "./setEstimatedNumOfPallets";
import { resetSelectedQuoteDetails } from "../../selectShipping/actions/resetSelectedQuoteDetails";
export const getEstimatedNumberOfPallets = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "getEstimatedNumberOfPallets"
  });
  const {
    selectedProducts,
    selectedProductsCaseDimensions,
    numOfPallets
  } = getStorageInboundCreate(getState());
  const caseInfo = Object.keys(selectedProducts).map(dsku => ({
    ...selectedProductsCaseDimensions[dsku],
    qty: selectedProducts[dsku].numberOfCases
  }));
  try {
    const estimatedPalletCount = (await storageClient.estimatePalletCount({
      itemsInfo: caseInfo
    })).value;
    dispatch(setEstimatedNumOfPallets(estimatedPalletCount));
    if (numOfPallets !== estimatedPalletCount) {
      dispatch(resetSelectedQuoteDetails());
    }
    dispatch(setNumOfPallets(estimatedPalletCount));
  } catch (err) {
    logError(ctx, err, "error estimating number of pallets, defaulting to 1");
    dispatch(setEstimatedNumOfPallets(1));
    dispatch(setNumOfPallets(1));
  }
};