export let OrderExceptions;
(function (OrderExceptions) {
  OrderExceptions["NOT_FOUND"] = "NOT_FOUND";
  OrderExceptions["STOCKOUT"] = "STOCKOUT";
  OrderExceptions["INVALID_ADDRESS"] = "INVALID_ADDRESS";
  OrderExceptions["INVALID_ORDER"] = "INVALID_ORDER";
  OrderExceptions["DUPLICATE_ORDER"] = "DUPLICATE_ORDER";
  OrderExceptions["UNKNOWN_SKU"] = "UNKNOWN_SKU";
  OrderExceptions["CANNOT_MEET_GUARANTEE"] = "CANNOT_MEET_GUARANTEE";
  OrderExceptions["INVALID_ORDER_TRACKING_ID"] = "INVALID_ORDER_TRACKING_ID";
  OrderExceptions["INVALID_QUERY_PARAMETER"] = "INVALID_QUERY_PARAMETER";
  OrderExceptions["ORDER_SHIPPED"] = "ORDER_SHIPPED";
  OrderExceptions["FAILED_CANCEL_AT_WAREHOUSE"] = "FAILED_CANCEL_AT_WAREHOUSE";
})(OrderExceptions || (OrderExceptions = {}));