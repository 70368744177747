import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";
const LB_TO_KG = 0.453592;
export const calcPackageTotalWeight = (product, unitSystem) => {
  const {
    weight,
    numberOfPkgs
  } = product;
  const caseWeightLb = weight ?? 0; // pounds
  const caseWeight = unitSystem === UnitSystem.CBM_KG ? caseWeightLb * LB_TO_KG : caseWeightLb;
  return Math.round(caseWeight * numberOfPkgs * 100) / 100;
};