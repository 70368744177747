import { defineMessages } from "react-intl";
export const SIMPLE_PREP_PAGE_LABELS = defineMessages({
  title: {
    id: "prep.prepPage.title",
    defaultMessage: "Try Flexport Prep"
  },
  cardHeader: {
    id: "prep.prepPage.cardHeader",
    defaultMessage: "Avoid lost cycles from constrained, rejected, or damaged inventory. Get your products packed right and prepared the way you need it to save costs and make customers happy."
  },
  description: {
    id: "prep.prepPage.description",
    defaultMessage: "Flexport Prep will make your inventory sales-ready within 3 days. This is only available through Forwarding. Learn more about {prepServicesLink}."
  },
  quoteLabel: {
    id: "prep.prepPage.quoteLabel",
    defaultMessage: "Estimated Prep quote"
  },
  prepServicesLink: {
    id: "prep.prepPage.prepServicesLink",
    defaultMessage: "Flexport Prep services"
  },
  selectServicesHeading: {
    id: "prep.prepPage.selectServicesHeading",
    defaultMessage: "Select service"
  },
  prepServicesClarification: {
    id: "prep.prepPage.prepServicesClarification",
    defaultMessage: "Prep services will apply to all {unitCount} units on this shipping plan unless you request additional Prep below."
  },
  customizedServicesTitle: {
    id: "prep.prepPage.customizedServicesTitle",
    defaultMessage: "Need more customized Prep services?"
  },
  customizedServicesDescription: {
    id: "prep.prepPage.customizedServicesDescription",
    defaultMessage: "Additional services like kitting, preparing specific units, and more custom requests are available below."
  },
  formLink: {
    id: "prep.prepPage.formLink",
    defaultMessage: "Request additional Prep"
  },
  finalInvoiceNote: {
    id: "prep.prepPage.finalInvoiceNote",
    defaultMessage: "Final invoice amount may vary due to verification of quantities, weights, and dimensions."
  },
  selectKittingHeading: {
    id: "prep.prepPage.selectKittingHeading",
    defaultMessage: "Select Kitting"
  },
  kittingServicesClarification: {
    id: "prep.prepPage.kittingServiceClarification",
    defaultMessage: "Kitting services will apply to all {unitCount} units on this shipping plan"
  },
  kittingNotificationTitle: {
    id: "inbounds.prep.prepPage.kittingAdditionalFeesNotification.title",
    defaultMessage: "Additional fees will apply to pack kits for distribution"
  },
  kittingNotificationDescription: {
    id: "inbounds.prep.prepPage.kittingAdditionalFeesNotification.description",
    defaultMessage: "Not included in the kitting prep fees are the material costs to pack the kits for distribution within our network"
  },
  kittingPrepInfoTitle: {
    id: "prep.prepPage.kittingPrepInfoTitle",
    defaultMessage: "Interested in kitting prep?"
  },
  kittingPrepInfoDescription: {
    id: "prep.prepPage.kittingPrepInfoDescription",
    defaultMessage: "Reduce time by selecting kitting prep. To select kitting prep, add only kitted products to the shipping plan. Only add kitted products that require kitting prep."
  },
  kittingPrepInfoArticleLink: {
    id: "prep.prepPage.kittingPrepInfoArticleLink",
    defaultMessage: "Learn about kitting prep"
  }
});