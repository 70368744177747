import { defineMessages } from "react-intl";
export const PREP_SLA_STATUS_LABELS = defineMessages({
  ON_TIME: {
    id: "inbounds.prep.prepStatusIndicator.ON_TIME",
    defaultMessage: "On time"
  },
  DELAYED: {
    id: "inbounds.prep.prepStatusIndicator.DELAYED",
    defaultMessage: "Late"
  },
  NC_PENDING: {
    id: "inbounds.prep.prepStatusIndicator.NC_PENDING",
    defaultMessage: "Non-compliance found, pending resolution"
  },
  NC_RESOLVED: {
    id: "inbounds.prep.prepStatusIndicator.NC_RESOLVED",
    defaultMessage: "Non-compliance resolved"
  }
});