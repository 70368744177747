import { useCallback, useState } from "react";
export const useLightboxState = () => {
  const [lightboxState, setLightboxState] = useState({
    slideIdx: 0,
    toggler: false
  });
  const toggleLightbox = useCallback((slideIdx = 0) => {
    setLightboxState(prevState => ({
      slideIdx,
      toggler: !prevState.toggler
    }));
  }, []);
  return {
    lightboxState,
    toggleLightbox
  };
};