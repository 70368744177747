import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { emptyAddress } from "common/AddressUtils";
import { StorageInboundDetailActionTypes } from "./StorageInboundDetailStore.types";
import { InboundStatus, ShipmentType } from "@deliverr/storage-client";
export const storageInboundDetailInitialState = {
  storageItemDetails: {},
  storageInboundOrderProblems: [],
  storageInboundOrder: {
    id: 0,
    asnId: "",
    warehouseId: "",
    from: emptyAddress,
    to: emptyAddress,
    status: InboundShipmentStatus.READY_FOR_WAREHOUSE,
    shipmentType: ShipmentType.FLOOR_LOADED_CONTAINER,
    sellerId: "",
    bolLink: undefined,
    estimatedInboundFee: 0,
    chargedInboundFee: 0,
    currency: "",
    isArchived: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    arrivalExpectedAt: new Date(),
    shippingPlanId: 0,
    shipmentId: 0,
    shippingPlanName: "",
    palletCount: 0,
    containerId: "",
    storageItems: [],
    internalStatus: InboundStatus.IN_DRAFT
  },
  isLoading: false,
  storageRates: {},
  receivingInfo: undefined
};
const reducers = {
  ...handleSimpleReducerUpdates([StorageInboundDetailActionTypes.STORAGE_GET_ITEM_DETAILS_SUCCESS, StorageInboundDetailActionTypes.STORAGE_GET_RATES_SUCCESS, StorageInboundDetailActionTypes.STORAGE_GET_PROBLEMS]),
  [StorageInboundDetailActionTypes.STORAGE_GET_DETAILS]: state => ({
    ...state,
    isLoading: true
  }),
  [StorageInboundDetailActionTypes.STORAGE_GET_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    storageInboundOrder: {
      ...state.storageInboundOrder,
      ...action.storageInboundOrder
    },
    isLoading: false
  }),
  [StorageInboundDetailActionTypes.RESET_STORAGE_DETAILS]: state => ({
    ...state,
    ...storageInboundDetailInitialState
  }),
  [StorageInboundDetailActionTypes.STORAGE_GET_DETAILS_ERROR]: state => ({
    ...state,
    isLoading: false
  }),
  [StorageInboundDetailActionTypes.STORAGE_UPDATE_ITEM_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    storageInboundOrder: {
      ...state.storageInboundOrder,
      ...action.storageInboundOrder
    },
    isLoading: false
  }),
  [StorageInboundDetailActionTypes.STORAGE_UPDATE_ITEM_DETAILS_ERROR]: state => ({
    ...state,
    isLoading: false
  }),
  [StorageInboundDetailActionTypes.STORAGE_FETCH_RECEIVE_INFO]: (state, {
    receivingInfo
  }) => ({
    ...state,
    receivingInfo
  })
};
export const storageInboundDetailReducer = createReducer(storageInboundDetailInitialState, reducers);