import { createSelector } from "reselect";
import { isLoginSuccessful } from "../../model/Auth";
export const facebookState = root => root.fastTagFlows.facebook.CONNECT;
export const facebookAuth = createSelector(facebookState, ({
  auth
}) => auth);
export const facebookUserId = createSelector(facebookAuth, ({
  authResponse
}) => authResponse?.userID);
export const facebookAccessToken = createSelector(facebookAuth, ({
  authResponse
}) => authResponse?.accessToken);
export const isLoggedIntoFacebook = createSelector(facebookAuth, isLoginSuccessful);
export const adAccounts = createSelector(facebookState, ({
  adAccounts: accounts
}) => accounts);
export const activeAdAccountId = createSelector(facebookState, ({
  activeAdAccountId: accountId
}) => accountId);
export const activeAdAccount = createSelector(facebookState, activeAdAccountId, (facebook, adAccountIdActive) => adAccountIdActive && facebook.adAccounts?.find(({
  adAccountId
}) => adAccountId === adAccountIdActive));
export const pages = createSelector(facebookState, ({
  pages: pagesCollection
}) => pagesCollection);
export const activePageId = createSelector(facebookState, ({
  activePageId: pageId
}) => pageId);
export const hasValidPermissions = createSelector(facebookState, ({
  hasValidPermissions: hasValidPerm
}) => hasValidPerm);
export const facebookProfileInfo = createSelector(facebookState, ({
  profile
}) => profile);
export const isSellerRegistrationComplete = createSelector(facebookState, ({
  sellerRegistration
}) => sellerRegistration === "complete");
export const loginStatus = createSelector(facebookState, ({
  loginStatus: status
}) => status);
export const pixels = createSelector(facebookState, ({
  pixels: pixelsCollection
}) => pixelsCollection);
export const activePixelId = createSelector(facebookState, ({
  activePixelId: pixelId
}) => pixelId);
export const facebookSellerRegistrationData = createSelector(activeAdAccount, isLoggedIntoFacebook, facebookUserId, facebookAccessToken, (adAccount, isLoggedIn, userId, userAccessToken) => {
  if (isLoggedIn && adAccount) {
    return {
      userId: userId,
      fbBusinessId: adAccount.businessId,
      fbAdAccountId: adAccount.adAccountId,
      userAccessToken: userAccessToken
    };
  } else {
    return undefined;
  }
});
export const areFacebookAccountsSelected = createSelector(activeAdAccount, isLoggedIntoFacebook, (adAccount, isLoggedIntoFB) => adAccount !== undefined && isLoggedIntoFB);