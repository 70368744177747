import { toast } from "common/components/ui";
import { logError } from "Logger";
import { useState } from "react";
import { useIntl } from "react-intl";
import { MissingBoxesAlertStatus } from "@deliverr/commons-clients";
import { nonComplianceClient } from "Clients";
import { isCreatedShortShip } from "../../utils";
import { useShortShipPackagesTable } from "./useShortShipPackagesTable";
import { useShortShipDskusTable } from "./useShortShipDskusTable";
import { ShortShipConfirmationOption } from "./types";
import { SHORT_SHIP_BUTTON_LABELS, SHORT_SHIP_RADIO_TILE_LABELS, SHORT_SHIP_VALIDATION_MESSAGES } from "./labels";
export const useShipmentProblemShortShipBlade = (shipmentProblem, refetch, hideShipmentProblemBlade, setSelectedProblem) => {
  const {
    formatMessage
  } = useIntl();
  const packageTableProps = useShortShipPackagesTable(shipmentProblem);
  const dskuTableProps = useShortShipDskusTable(shipmentProblem);
  const [shortShipOption, setShortShipOption] = useState();
  const showRadioSelectors = isCreatedShortShip(shipmentProblem.summary.status);
  const showDefaultBladeButton = !(showRadioSelectors && !!shortShipOption);
  const radioOptions = Object.entries(SHORT_SHIP_RADIO_TILE_LABELS).map(([value, descriptor]) => ({
    value,
    label: formatMessage(descriptor)
  }));
  const handleSelect = event => {
    setShortShipOption(event.target.value);
  };
  const resetBladeState = () => {
    setShortShipOption(undefined);
    setSelectedProblem(undefined);
    hideShipmentProblemBlade();
  };
  const handleSubmitClick = async () => {
    const ctx = {
      fn: "useShipmentProblemShortShipBlade.handleSubmitClick",
      shipmentProblem
    };
    if (!shortShipOption) {
      const errMsg = formatMessage(SHORT_SHIP_VALIDATION_MESSAGES.noOptionSelected);
      logError(ctx, errMsg);
      toast.error(errMsg, {
        toastId: "inbounds.shipmentProblemShortShipBlade.toast.noOptionSelected"
      });
      return;
    }
    if (shipmentProblem.summary.status !== MissingBoxesAlertStatus.CREATED) {
      const errMsg = formatMessage(SHORT_SHIP_VALIDATION_MESSAGES.illegalStatusChange);
      logError(ctx, errMsg);
      toast.error(errMsg, {
        toastId: "inbounds.shipmentProblemShortShipBlade.toast.invalidStatusChange"
      });
      return;
    }
    const targetStatus = shortShipOption === ShortShipConfirmationOption.CONFIRM ? MissingBoxesAlertStatus.SHORT_SHIP_CONFIRMED : MissingBoxesAlertStatus.WITH_SUPPORT;
    resetBladeState();
    await nonComplianceClient.updateMissingBoxesAlertStatus(shipmentProblem.summary.id, targetStatus);
    await refetch();
  };
  const activeButtonLabels = [SHORT_SHIP_BUTTON_LABELS.contact, shortShipOption === ShortShipConfirmationOption.INVESTIGATE ? SHORT_SHIP_BUTTON_LABELS.contact : SHORT_SHIP_BUTTON_LABELS.confirm].map(descriptor => formatMessage(descriptor));
  const shouldShowFooter = [MissingBoxesAlertStatus.CREATED].includes(shipmentProblem.summary.status);
  return {
    packageTableProps,
    dskuTableProps,
    shortShipOption,
    showRadioSelectors,
    showDefaultBladeButton,
    radioOptions,
    handleSelect,
    activeButtonLabels,
    handleSubmitClick,
    resetBladeState,
    shouldShowFooter
  };
};