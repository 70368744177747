import { populateReplenishmentOrderWithPacks } from "storage/packUtils/populateReplenishmentOrderWithPacks";
import { getReplenishmentOrder } from "./getReplenishmentOrder";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { loadWarehouses } from "common/deliverr/DeliverrActions";
import { replenishmentOrderDeatilsSetProducts, replenishmentOrderDeatilsSetReplenishmentOrder, setReplenishmentOrderNonCompliances } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsActions";
import { chain } from "lodash";
export const getAndSetReplenishmentOrderState = async (orderId, dispatch) => {
  const replenishmentOrderResponse = await getReplenishmentOrder(orderId);
  const replenishmentOrderWithPacks = await dispatch(populateReplenishmentOrderWithPacks(replenishmentOrderResponse));
  const dskus = replenishmentOrderWithPacks.orderItems?.map(item => item.dsku) ?? [];
  if (replenishmentOrderWithPacks.originWarehouseId) {
    dispatch(loadWarehouses([replenishmentOrderWithPacks.originWarehouseId]));
  }
  const products = await fetchMultiPackProductsByDskus(dskus, dispatch); // why this is needed
  await dispatch(replenishmentOrderDeatilsSetReplenishmentOrder(replenishmentOrderWithPacks));
  await dispatch(replenishmentOrderDeatilsSetProducts(products));
  const nonCompliances = chain(replenishmentOrderResponse.shipments).map(shipment => shipment.nonCompliances ?? []).flatten().compact().value();
  await dispatch(setReplenishmentOrderNonCompliances(nonCompliances));
  return replenishmentOrderWithPacks;
};