export let LtlActionTypes;
(function (LtlActionTypes) {
  LtlActionTypes["LTL_SET_NUMBER_OF_PALLETS"] = "LTL_SET_NUMBER_OF_PALLETS";
  LtlActionTypes["LTL_SET_TOTAL_CARGO_WEIGHT"] = "LTL_SET_TOTAL_CARGO_WEIGHT";
  LtlActionTypes["LTL_SET_PALLET_CONFIG"] = "LTL_SET_PALLET_CONFIG";
  LtlActionTypes["LTL_ADD_PALLET_CONFIG"] = "LTL_ADD_PALLET_CONFIG";
  LtlActionTypes["LTL_REMOVE_PALLET_CONFIG"] = "LTL_REMOVE_PALLET_CONFIG";
  LtlActionTypes["LTL_REPLACE_PALLET_CONFIGS"] = "LTL_REPLACE_PALLET_CONFIGS";
  LtlActionTypes["LTL_CONFIRM_PALLETS"] = "LTL_CONFIRM_PALLETS";
  LtlActionTypes["LTL_SET_PICKUP_DETAILS"] = "LTL_SET_PICKUP_DETAILS";
  LtlActionTypes["LTL_SET_READY_DATE"] = "LTL_SET_READY_DATE";
  LtlActionTypes["LTL_SET_IS_LIFT_GATE"] = "LTL_SET_IS_LIFT_GATE";
  LtlActionTypes["LTL_CONFIRM_PICKUP_DETAILS"] = "LTL_CONFIRM_PICKUP_DETAILS";
  LtlActionTypes["LTL_CONFIRM_SHIPPER_DETAILS"] = "LTL_CONFIRM_SHIPPER_DETAILS";
  LtlActionTypes["LTL_ROLLBACK_QUOTES"] = "LTL_ROLLBACK_QUOTES";
  LtlActionTypes["LTL_SET_QUOTES"] = "LTL_SET_QUOTES";
  LtlActionTypes["LTL_SET_SELECTED_QUOTE_ID"] = "LTL_SET_SELECTED_QUOTE_ID";
  LtlActionTypes["LTL_CONFIRM_RATE_QUOTE"] = "LTL_CONFIRM_RATE_QUOTE";
  LtlActionTypes["LTL_CONFIRM_REVIEW_SHIPMENT"] = "LTL_CONFIRM_REVIEW_SHIPMENT";
  LtlActionTypes["LTL_SET_BILL_OF_LADING"] = "LTL_SET_BILL_OF_LADING";
  LtlActionTypes["LTL_SET_HAS_DOWNLOADED_BOL"] = "LTL_SET_HAS_DOWNLOADED_BOL";
  LtlActionTypes["LTL_LOAD_LTL"] = "LTL_LOAD_LTL";
  LtlActionTypes["FREIGHT_SET_PICKUP_DETAILS"] = "FREIGHT_SET_PICKUP_DETAILS";
  LtlActionTypes["LTL_CONFIRM_SHIPMENT_SUMMARY"] = "LTL_CONFIRM_SHIPMENT_SUMMARY";
})(LtlActionTypes || (LtlActionTypes = {}));