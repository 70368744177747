// These are seller facing order errors.
export let OrderError;
(function (OrderError) {
  OrderError["BAD_ADDRESS"] = "BAD_ADDRESS";
  OrderError["BAD_ADDRESS_DDP"] = "BAD_ADDRESS_DDP";
  OrderError["BAD_PRODUCT_INFO"] = "BAD_PRODUCT_INFO";
  OrderError["CANNOT_SHIP_HAZMAT"] = "CANNOT_SHIP_HAZMAT";
  OrderError["DDU_NOT_ALLOWED"] = "DDU_NOT_ALLOWED";
  OrderError["DSKU_NEVER_INBOUNDED"] = "DSKU_NEVER_INBOUNDED";
  OrderError["INTERNATIONAL_ADDRESS"] = "INTERNATIONAL_ADDRESS";
  OrderError["MISSING_CUSTOMS_INFO"] = "MISSING_CUSTOMS_INFO";
  OrderError["NON_PLT_ORDER_NOT_SUPPORTED"] = "NON_PLT_ORDER_NOT_SUPPORTED";
  OrderError["OVERSIZED_DIMS"] = "OVERSIZED_DIMS";
  OrderError["PARTIALLY_SHIPPED"] = "PARTIALLY_SHIPPED";
  OrderError["PARTIAL_FULFILL_FAILED"] = "PARTIAL_FULFILL_FAILED";
  OrderError["PROMISE_TIME_EXPIRED"] = "PROMISE_TIME_EXPIRED";
  OrderError["SHIPPING_METHOD_NOT_SUPPORTED_DDP"] = "SHIPPING_METHOD_NOT_SUPPORTED_DDP";
  OrderError["STOCKOUT"] = "STOCKOUT";
  OrderError["STOCKOUT_AT_INGESTION"] = "STOCKOUT_AT_INGESTION";
  OrderError["UNKNOWN"] = "UNKNOWN";
  OrderError["UNSUPPORTED_DESTINATION"] = "UNSUPPORTED_DESTINATION";
  OrderError["WAREHOUSE_ERR"] = "WAREHOUSE_ERR";
})(OrderError || (OrderError = {}));