import { getAuth } from "common/utils/SSO";
import log from "Logger";
import pify from "pify";
let user;
export const getBearerToken = async () => {
  if (!user) {
    const currentAuth = await getAuth();
    user = await currentAuth.currentAuthenticatedUser();
  }
  const ctx = {
    fn: "getBearerToken"
  };
  if (user) {
    const getSession = pify(user.getSession.bind(user));
    const session = await getSession();
    const bearerToken = session.getIdToken().getJwtToken();
    if (bearerToken === undefined) {
      log.error({
        ...ctx,
        user
      }, "Unable to find bearer token");
      throw new Error("Unable to find bearer token!");
    } else {
      return bearerToken;
    }
  } else {
    log.error({
      ...ctx,
      user
    }, "Unable to find bearer token");
    throw new Error("Unable to find bearer token!");
  }
};