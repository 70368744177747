import { createSelector } from "reselect";
import { map, filter } from "lodash/fp";
import { getProductDetailsCache } from "../productDetailsCache";
import { hasDimensionsAndWeight } from "common/utils/product/hasDimensionsAndWeight";
import { selectPlanItems } from "./selectPlanItems";
export const getPlanItemsWithMissingDims = createSelector(selectPlanItems, getProductDetailsCache, (planItems, productDetailsCache) => {
  const planItemDetails = map(({
    dsku
  }) => productDetailsCache[dsku], planItems);
  return filter(product => !hasDimensionsAndWeight(product), planItemDetails);
});