export let InventoryListActionTypes;
(function (InventoryListActionTypes) {
  InventoryListActionTypes["SET_ESTIMATED_COSTS_SERVICE_LEVEL"] = "SET_ESTIMATED_COSTS_SERVICE_LEVEL";
  InventoryListActionTypes["SET_INVENTORY_LIST_COUNTRY_CODE"] = "SET_INVENTORY_LIST_COUNTRY_CODE";
  InventoryListActionTypes["SET_IS_ACTIVE"] = "SET_IS_ACTIVE";
  InventoryListActionTypes["PRODUCT_SYNC"] = "PRODUCT_SYNC";
  InventoryListActionTypes["ESTIMATE_PRODUCT_LIST_COSTS_LOADING"] = "ESTIMATE_PRODUCT_LIST_COSTS_LOADING";
  InventoryListActionTypes["ESTIMATE_PRODUCT_LIST_COSTS"] = "ESTIMATE_PRODUCT_LIST_COSTS";
  InventoryListActionTypes["INVENTORY_LIST_LOADER"] = "INVENTORY_LIST_LOADER";
  InventoryListActionTypes["SET_FILTERS_BLADE_OPEN"] = "SET_FILTERS_BLADE_OPEN";
  InventoryListActionTypes["SET_TAB"] = "SET_TAB";
  InventoryListActionTypes["SET_SEARCH_TERM"] = "SET_SEARCH_TERM";
  InventoryListActionTypes["SET_BUNDLE_LIST_AVAILABLE_QTY"] = "SET_BUNDLE_LIST_AVAILABLE_QTY";
  InventoryListActionTypes["ESTIMATE_BUNDLE_LIST_COSTS"] = "ESTIMATE_BUNDLE_LIST_COSTS";
  InventoryListActionTypes["ESTIMATE_BUNDLE_LIST_COSTS_LOADING"] = "ESTIMATE_BUNDLE_LIST_COSTS_LOADING";
  InventoryListActionTypes["ESTIMATE_BUNDLE_LIST_COSTS_COMPLETED"] = "ESTIMATE_BUNDLE_LIST_COSTS_COMPLETED";
  InventoryListActionTypes["SET_INVENTORY_LOCATION_FILTER"] = "SET_INVENTORY_LOCATION_FILTER";
  InventoryListActionTypes["CALCULATE_MULTI_PACK_STORAGE_INVENTORY_LOADING"] = "CALCULATE_MULTI_PACK_STORAGE_INVENTORY_LOADING";
  InventoryListActionTypes["CALCULATE_MULTI_PACK_STORAGE_INVENTORY"] = "CALCULATE_MULTI_PACK_STORAGE_INVENTORY";
})(InventoryListActionTypes || (InventoryListActionTypes = {}));