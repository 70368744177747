import styled from "@emotion/styled";
export const Divider = styled.hr`
  ${({
  rotate,
  length = "100%",
  theme
}) => `
    border: ${theme?.border.width.B1} ${theme?.border.type} ${theme.colors.NEUTRAL[100]};
    width: ${length};
    ${rotate ? "transform: rotate(90deg);" : ""}
  `}
`;