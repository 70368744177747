import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { WalmartError } from "./WalmartError";
export class WalmartClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.WALMART_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async disableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/item/${dsku}`,
        body: {
          isTwoDayEnabled: false
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WalmartError.NOT_FOUND]);
    }
  }
  async enableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/item/${dsku}`,
        body: {
          isTwoDayEnabled: true
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WalmartError.NOT_FOUND]);
    }
  }
  async getDskuStateCoverage(dsku) {
    return await this.apiClient.get({
      url: `/coverage/${dsku}`,
      authentication: isAuthenticated
    });
  }
  async getStateCoverageByServiceLevel(dsku) {
    try {
      return await this.apiClient.get({
        url: `/coverage/servicelevel/${dsku}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async isFastTagEnabled(sellerId, dsku) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/item/${dsku}/active`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WalmartError.NOT_FOUND]);
    }
  }
  async getLinkForDsku(sellerId, dsku) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/item/${dsku}/link`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WalmartError.INVALID_API_KEY, WalmartError.NOT_FOUND, WalmartError.NOT_FOUND_IN_MARKETPLACE]);
    }
  }
  async updateLinkForDsku(sellerId, dsku, listingUrl, productIdentifier) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/item/${dsku}/listing`,
        body: {
          listingUrl,
          msku: productIdentifier
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WalmartError.INVALID_URL, WalmartError.LISTING_IS_MISSING_SKU, WalmartError.MSKU_NOT_FOUND, WalmartError.MULTIPLE_VARIANTS]);
    }
  }
  async submitApiKeys(sellerId, consumerId, privateKey) {
    try {
      await this.apiClient.put({
        url: `/api-key`,
        body: {
          sellerId,
          consumerId,
          privateKey
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WalmartError.INVALID_API_KEY, WalmartError.INVALID_API_PERMISSIONS, WalmartError.NON_DELIVERR_CREDENTIALS]);
    }
  }
  async deactivateSeller(sellerId) {
    return await this.apiClient.put({
      url: `/api-key/${sellerId}/deactivate`,
      body: {},
      authentication: isAuthenticated
    });
  }
  async activateSeller(sellerId) {
    return await this.apiClient.put({
      url: `/api-key/${sellerId}/activate`,
      body: {},
      authentication: isAuthenticated
    });
  }
}