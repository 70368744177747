import { createSelector } from "reselect";
import { getStorageInboundCreate } from "./getStorageInboundCreate";
export const getSelectedProductsCaseDimensions = createSelector(getStorageInboundCreate, storageInboundCreate => {
  const {
    selectedProductsCaseDimensions,
    productDetails,
    selectedProducts
  } = storageInboundCreate;
  return Object.keys(selectedProductsCaseDimensions).map(dsku => ({
    ...productDetails[dsku],
    ...selectedProductsCaseDimensions[dsku],
    ...selectedProducts[dsku]
  }));
});