import { Card, Title, Text, RadioTileGroup } from "common/components/ui";
import styled from "@emotion/styled";
export const NonComplianceCard = styled(Card)`
  padding: 0;
`;
export const NonComplianceCaseSummary = styled.div(({
  theme
}) => `
      padding: ${theme.spacing.S5};
      @media (min-width: ${theme.breakpoints.SM}){
        /* accordion side padding plus the width of the number icon plus the icon right margin */
        padding: ${theme.spacing.S7} calc(${theme.spacing.S7} + ${theme.spacing.S6} + ${theme.spacing.S4});
      }
    `);
export const NonComplianceSection = styled.div(({
  theme
}) => `
      &:not(:last-of-type) {
        margin-bottom: ${theme.spacing.S5};
      }
    `);
export const NonComplianceTitle = styled(Title)(({
  theme
}) => `
      margin: 0;
      letter-spacing: ${theme.font.letterSpacing.LS1};
    `);
export const NonComplianceRadioTileGroup = styled(RadioTileGroup)(({
  theme
}) => `
      & > label {
        @media (max-width: ${theme.breakpoints.SM}){
          flex: 1;
        }
      }
    `);
export const TextWithBottomMargin = styled(Text)(({
  margin,
  theme
}) => `
  margin-bottom: ${margin ?? theme.spacing.S1};
  `);
export const NonComplianceStepContainer = styled.div(({
  theme
}) => `
      border-top: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["80"]};
    `);
export const NonComplianceStepContent = styled.div(({
  theme
}) => `
    padding: 0 ${theme.spacing.S2} ${theme.spacing.S2};
    @media (min-width: ${theme.breakpoints.SM}){
      padding: 0 ${theme.spacing.S8} ${theme.spacing.S2};
    }
  `);
export const NonComplianceSuccessContainer = styled.div(({
  theme
}) => `
    padding: ${theme.spacing.S5};
    @media (min-width: ${theme.breakpoints.SM}){
      width: 640px;
      padding-top: ${theme.spacing.S7};
      padding-bottom: ${theme.spacing.S7};
      margin-left: auto;
      margin-right: auto;
    }
  `);