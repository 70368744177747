import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { handleSellerServiceError } from "./handleSellerServiceError";
export class UserClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async getUsersFromPartialMatch(partialString) {
    try {
      return await this.apiClient.get({
        url: "/user/partial_match",
        params: {
          q: partialString
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("getUserFromPartialMatch", err);
    }
  }
  async getUserFromOrganization(email) {
    try {
      return await this.apiClient.get({
        url: "/user",
        params: {
          email
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("getUserFromOrganization", err);
    }
  }
  async getUserIntercomHash(email) {
    try {
      return await this.apiClient.get({
        url: "/user/intercomHash",
        params: {
          email
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("getUserIntercomHash", err);
    }
  }
  async updateUser(email, update) {
    try {
      return await this.apiClient.put({
        url: `/user/update/${email}`,
        body: update,
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("updateUser", err);
    }
  }
  async setSurveyResponse(surveyName, surveyVersion, questions) {
    try {
      return await this.apiClient.put({
        url: `/survey/${surveyName}/${surveyVersion}/response`,
        body: {
          questions
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("setSurveyResponse", err);
    }
  }
  async getSurveyResponse(surveyName, surveyVersion) {
    try {
      return await this.apiClient.get({
        url: `/survey/${surveyName}/${surveyVersion}/response`,
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("getSurveyResponse", err);
    }
  }
  async deactivateUser(email) {
    try {
      return await this.apiClient.delete({
        url: "/user/deactivate",
        params: {
          email
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("deactivateUser", err);
    }
  }
  async activateUser(email) {
    try {
      return await this.apiClient.put({
        url: "/user/activate",
        params: {
          email
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("activateUser", error);
      return;
    }
  }
  async updateContactArea(contactArea, updates) {
    try {
      return await this.apiClient.patch({
        url: `/user/contactArea/${contactArea}`,
        body: updates,
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("updateContactArea", error);
    }
  }
  async deletePendingUser(email) {
    try {
      return await this.apiClient.delete({
        url: "/user/delete",
        params: {
          email
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("deletePendingUser", error);
    }
  }
  async getMfa() {
    try {
      return await this.apiClient.get({
        url: `/user/mfa`,
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("getMfa", error);
    }
  }

  /**
   * For now, since the ApiClient axios http requests have some issues (return only data part but not the whole response, made the ApiResponse type not work properly),
   * we use axios directly here. It's a temporary solution and should be replaced once the ApiClient is fixed.
   *
   */
  async sendEmailVerification(email) {
    try {
      return await this.apiClient.putWithWholeResponse({
        url: `/user/${email}/sendEmailVerification`,
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("sendEmailVerification", error);
    }
  }
  async sendAutoEmailVerification(email) {
    try {
      return await this.apiClient.postWithWholeResponse({
        url: `/user/${email}/sendAutoEmailVerification`,
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("sendAutoEmailVerification", error);
    }
  }
  async getFlexportUser(email) {
    try {
      return await this.apiClient.getWithWholeResponse({
        url: `/flexportUser`,
        params: {
          email
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("getFlexportUser", error);
    }
  }
  async setMfa(mfaMethods, preferredMfa) {
    try {
      return await this.apiClient.put({
        url: `/user/mfa`,
        body: {
          mfaMethods,
          preferredMfa
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("setMfa", error);
    }
  }
}