import { combineReducers } from "redux";
import { OrderStatus } from "freight/types";
import { cargoPalletDetailsReducer } from "freight/store/cargoPalletDetails/reducer/CargoPalletDetailsReducer";
import { freightBookingAccordionStepsReducer } from "freight/store/accordionSteps/reducer/FreightBookingAccordionStepsReducer";
import { createReducer } from "common/ReduxUtils";
import { FreightBookingActionTypes } from "../actions/FreightBookingActionTypes";
import { freightOrderIdReducer } from "freight/store/confirmShipment/reducer/OrderIdReducer";
import { freightGeneratedOrdersReducer } from "./freightGeneratedOrderReducer";
import { pickupDetailsReducer } from "freight/store/pickupDetails/reducer/FreightPickupDetailsReducer";
import { deliveryDetailsReducer } from "freight/store/deliveryDetails/reducer/FreightDeliveryDetailsReducer";
import { drayageDetailsReducer } from "freight/store/drayageDetails/reducer/DrayageDetailsReducer";
import { drayageCargoDetailsReducer } from "freight/store/drayageCargoDetails/reducer/DrayageCargoDetailsReducer";
import { fbaDeliveryDetailsReducer } from "freight/store/fbaDeliveryDetails/reducer/FreightFbaDeliveryDetailsReducer";
const statusReducer = {
  [FreightBookingActionTypes.SET_BOOKING_STATUS]: (state, action) => {
    return action.status;
  },
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => OrderStatus.DRAFT
};
const quoteReducer = {
  [FreightBookingActionTypes.SET_FREIGHT_BOOKING_QUOTE]: (state, action) => {
    return action.quote;
  },
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: () => null,
  [FreightBookingActionTypes.FREIGHT_BOOKING_COMPLETED]: () => null
};
export const freightBookingReducer = combineReducers({
  freightPickupDetails: pickupDetailsReducer,
  freightDeliveryDetails: deliveryDetailsReducer,
  cargoPalletDetails: cargoPalletDetailsReducer,
  drayageDetails: drayageDetailsReducer,
  drayageCargoDetails: drayageCargoDetailsReducer,
  freightFbaDeliveryDetails: fbaDeliveryDetailsReducer,
  bookingSteps: freightBookingAccordionStepsReducer,
  status: createReducer(OrderStatus.DRAFT, statusReducer),
  quote: createReducer(null, quoteReducer),
  freightOrderId: freightOrderIdReducer,
  generatedOrders: freightGeneratedOrdersReducer
});