import { defineMessages } from "react-intl";
export const sharedShipmentHeaderLabels = defineMessages({
  actions: {
    title: {
      id: "transfers.shipment.header.actions.title",
      defaultMessage: "Actions"
    },
    downloadBoxLabel: {
      id: "transfers.shipment.header.actions.downloadBoxLabel",
      defaultMessage: "Download Box Label"
    },
    downloadPalletLabel: {
      id: "transfers.shipment.header.actions.downloadPalletLabel",
      defaultMessage: "Download Pallet Label"
    },
    downloadShippingLabel: {
      id: "wholesaleOrder.detail.header.actions.downloadShippingLabel",
      defaultMessage: "Download Shipping Labels"
    },
    downloadSignedBOL: {
      id: "wholesaleOrder.detail.header.actions.downloadSignedBOL",
      defaultMessage: "Download Signed BOL"
    },
    downloadBOL: {
      id: "wholesaleOrder.detail.header.actions.downloadBOL",
      defaultMessage: "Download BOL"
    },
    downloadPackingList: {
      id: "wholesaleOrder.detail.header.actions.downloadPackingList",
      defaultMessage: "Download Packing List"
    },
    downloadCommercialInvoice: {
      id: "wholesaleOrder.detail.header.actions.downloadCommercialInvoice",
      defaultMessage: "Download Commercial Invoice"
    }
  }
});