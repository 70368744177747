var _useFetchFlexportUserQuery;
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logError } from "../../Logger";
import { useSPSelector } from "../ReduxUtils";
import { getFlexportUser } from "./FlexportUserSelectors";
import { getIsLinkedToFlexport } from "common/user/UserSelectors";
import { useIsFlexportCompanyExisting } from "./useIsFlexportCompanyExisting";
import { useIsUserConnectedWithFlexport } from "./useIsUserConnectedWithFlexport";
const flexportUserQuery = _useFetchFlexportUserQuery !== void 0 ? _useFetchFlexportUserQuery : (_useFetchFlexportUserQuery = require("../relay/__generated__/useFetchFlexportUserQuery.graphql"), _useFetchFlexportUserQuery.hash && _useFetchFlexportUserQuery.hash !== "94dd9c3f45d6760f8679ad0e1f4f048a" && console.error("The definition of 'useFetchFlexportUserQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _useFetchFlexportUserQuery);
const flattenFlexportUser = flexportUserQueryResult => {
  const user = flexportUserQueryResult.current_user;
  const impersonatedUser = flexportUserQueryResult.current_user.impersonatedUser;
  const company = impersonatedUser ? impersonatedUser.company : user.company;
  return {
    dbid: user.dbid,
    name: user.name,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    admin: user.admin,
    // Read impersonated data if it exists
    companyEntityId: company.primary_entity.dbid,
    clientFid: company.client?.fid,
    displaySmbOnboarding: company.primary_entity.display_smb_onboarding,
    completedSmbCompanyDetailsOnboarding: company.primary_entity.completed_smb_company_details_onboarding,
    submittedSmbCompanyDetailsOnboarding: company.primary_entity.submitted_smb_company_details_onboarding,
    completedSmbAuthorizationOnboarding: company.primary_entity.completed_smb_authorization_onboarding,
    submittedSmbAuthorizationOnboarding: company.primary_entity.submitted_smb_authorization_onboarding
  };
};
export const useFetchFlexportUser = () => {
  const [subscription, setSubscription] = useState();
  const dispatch = useDispatch();
  const environment = useRelayEnvironment();
  const isLinkedToFlexport = useSelector(getIsLinkedToFlexport);
  const flexportUser = useSPSelector(getFlexportUser);
  const {
    isFlexportCompanyExisting
  } = useIsFlexportCompanyExisting();
  const {
    isUserConnectedWithFlexport
  } = useIsUserConnectedWithFlexport();
  useEffect(() => {
    if ((!flexportUser.dbid || !flexportUser.name || !flexportUser.email || !flexportUser.companyEntityId) && !subscription && isLinkedToFlexport) {
      setSubscription(fetchQuery(environment, flexportUserQuery, {}).subscribe({
        next: data => {
          dispatch({
            type: "SET_FLEXPORT_USER",
            ...flattenFlexportUser(data)
          });
        },
        error: error => {
          logError({
            fn: "useFetchFlexportUser"
          }, error, "Error fetching flexportUser");
        }
      }));
    }
    return () => {
      subscription?.unsubscribe();
      setSubscription(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flexportUser, isLinkedToFlexport]);
  return {
    dbid: flexportUser.dbid,
    name: flexportUser.name,
    firstName: flexportUser.firstName,
    lastName: flexportUser.lastName,
    email: flexportUser.email,
    admin: flexportUser.admin,
    companyEntityId: flexportUser.companyEntityId,
    clientFid: flexportUser.clientFid,
    displaySmbOnboarding: flexportUser.displaySmbOnboarding,
    completedSmbCompanyDetailsOnboarding: flexportUser.completedSmbCompanyDetailsOnboarding,
    submittedSmbCompanyDetailsOnboarding: flexportUser.submittedSmbCompanyDetailsOnboarding,
    completedSmbAuthorizationOnboarding: flexportUser.completedSmbAuthorizationOnboarding,
    submittedSmbAuthorizationOnboarding: flexportUser.submittedSmbAuthorizationOnboarding,
    isFlexportCompanyExisting: isFlexportCompanyExisting ?? flexportUser.isFlexportCompanyExisting ?? undefined,
    isUserConnectedWithFlexport: isUserConnectedWithFlexport ?? flexportUser.isUserConnectedWithFlexport ?? undefined
  };
};