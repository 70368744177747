export let FreightQuoteServiceLabel;
(function (FreightQuoteServiceLabel) {
  FreightQuoteServiceLabel["DRAYAGE"] = "Drayage";
  FreightQuoteServiceLabel["FTL"] = "Full-truckload service";
  FreightQuoteServiceLabel["TRANSLOAD"] = "Transload";
})(FreightQuoteServiceLabel || (FreightQuoteServiceLabel = {}));
export const freightQuoteSample = {
  id: "123",
  hashId: "7N1aMj3bWm",
  sellerId: "gweentoysinc",
  internalUserId: "mshaked@deliverr.com",
  to: {
    name: "",
    street1: "8 Milburn Park",
    city: "Evanston",
    zip: "60201",
    state: "IL",
    country: "United States"
  },
  from: {
    name: "",
    street1: "910 Union Street",
    city: "Brooklyn",
    zip: "11215",
    state: "NY",
    country: "United States"
  },
  numberOfTrucks: 2,
  createdAt: new Date().toString(),
  plan: [{
    service: FreightQuoteServiceLabel.FTL,
    totalCost: 16000,
    unitCost: 8000,
    quantity: 2
  }, {
    service: FreightQuoteServiceLabel.DRAYAGE,
    totalCost: 4000,
    unitCost: 2000,
    quantity: 2
  }, {
    service: FreightQuoteServiceLabel.TRANSLOAD,
    totalCost: 4000,
    unitCost: 2000,
    quantity: 2
  }]
};
export const freightQuoteSummaryInitialState = {
  quoteId: "",
  quote: {
    id: "",
    sellerId: "",
    internalUserId: "",
    to: {
      name: "",
      street1: "",
      city: "",
      zip: "",
      state: "",
      country: "United States"
    },
    from: {
      name: "",
      street1: "",
      city: "",
      zip: "",
      state: "",
      country: "United States"
    },
    numberOfTrucks: 0,
    createdAt: "",
    plan: []
  },
  isLoading: false
};