import { defineMessages } from "react-intl";
import { AmazonErrorCode } from "@deliverr/replenishment-client";
export const AmazonErrorLabels = {
  default: {
    id: "AmazonErrorLabels.default",
    defaultMessage: "an error has occurred. {tryAgain}"
  },
  tryAgain: {
    id: "AmazonErrorCodeErrorsLabels.tryAgain",
    defaultMessage: "Try again or contact support if you're still having issues."
  }
};
export const AmazonErrorCodeErrorsLabels = defineMessages({
  [AmazonErrorCode.CAPACITY_EXCEEDED]: {
    id: "AmazonErrorCodeErrorsLabels.CAPACITY_EXCEEDED",
    defaultMessage: "you’ve reached your inventory limit set by Amazon. {tryAgain}"
  },
  [AmazonErrorCode.INVALID_PRODUCTS]: {
    id: "AmazonErrorCodeErrorsLabels.INVALID_PRODUCTS",
    defaultMessage: "SKU(s) not found using Amazon's API: {additionalInfo}. {tryAgain}"
  },
  [AmazonErrorCode.NOT_IN_PRODUCT_CATALOG]: {
    id: "AmazonErrorCodeErrorsLabels.NOT_IN_PRODUCT_CATALOG",
    defaultMessage: "one or more of the items in this transfer are not currently active in your Amazon catalog: {additionalInfo}. Please visit Amazon Seller Central and confirm that all products are active and sellable through FBA, or remove the product(s) listed above and continue with this transfer. If this error continues, please contact our Support team."
  },
  [AmazonErrorCode.MISSING_DIMENSIONS]: {
    id: "AmazonErrorCodeErrorsLabels.MISSING_DIMENSIONS",
    defaultMessage: "One or more of the items in this transfer are missing dimensions in Amazon: {additionalInfo}. Please visit Amazon Seller Central and update the dims following the steps <a>here</a>. If the dims are updated and the error continues, please contact our Support team."
  },
  [AmazonErrorCode.MISSING_ASIN]: {
    id: "AmazonErrorCodeErrorsLabels.MISSING_ASIN",
    defaultMessage: "one of your items is missing an ASIN: {additionalInfo}. Please contact our Support team and provide them the ASIN so we can update our catalog."
  },
  [AmazonErrorCode.EXCLUDED_FROM_COMMINGLING]: {
    id: "AmazonErrorCodeErrorsLabels.EXCLUDED_FROM_COMMINGLING",
    defaultMessage: "one of your items can no longer ship to Amazon as Stickerless Commingled Inventory: {additionalInfo}. Please recreate the order without this item or relist it in Seller Central with a different MSKU."
  },
  [AmazonErrorCode.ITEM_STRANDED]: {
    id: "AmazonErrorCodeErrorsLabels.ITEM_STRANDED",
    defaultMessage: "one or more of the items in this transfer are defined as 'stranded' in Amazon: {additionalInfo}. Please visit Amazon Seller Central and <a>handle them</a>. If the products are updated and the error continues, please contact our Support team."
  },
  [AmazonErrorCode.ITEM_MISMATCH]: AmazonErrorLabels.default,
  [AmazonErrorCode.LTSF_RESTRICTION]: {
    id: "AmazonErrorCodeErrorsLabels.LTSF_RESTRICTION",
    defaultMessage: "Amazon restricts sending more units due to recent cleanup of aged inventory for item(s): {additionalInfo}. Please recreate the order without those items."
  },
  [AmazonErrorCode.MELTABLE_PRODUCT_RESTRICTION]: {
    id: "AmazonErrorCodeErrorsLabels.MELTABLE_PRODUCT_RESTRICTION",
    defaultMessage: "Amazon restricts sending 'melatable' units at this time of year, item(s): {additionalInfo}. Please recreate the order without those items."
  },
  [AmazonErrorCode.EXP_DATED_ITEM_HAS_EXISTING_STICKERLESS_MAP]: {
    id: "AmazonErrorCodeErrorsLabels.EXP_DATED_ITEM_HAS_EXISTING_STICKERLESS_MAP",
    defaultMessage: "The expiration-dated item has an existing stickerless map. {tryAgain}"
  },
  [AmazonErrorCode.EXTERNAL_ID_MAPS_MULTIPLE_IAIDS]: {
    id: "AmazonErrorCodeErrorsLabels.EXTERNAL_ID_MAPS_MULTIPLE_IAIDS",
    defaultMessage: "The external ID maps to multiple IAIDs. {tryAgain}"
  },
  [AmazonErrorCode.EXTERNAL_ID_MAPS_MULTIPLE_IAIDS_FOR_EXISTING_MAP]: {
    id: "AmazonErrorCodeErrorsLabels.EXTERNAL_ID_MAPS_MULTIPLE_IAIDS_FOR_EXISTING_MAP",
    defaultMessage: "The external ID maps to multiple IAIDs for an existing map. {tryAgain}"
  },
  [AmazonErrorCode.FAILED_TO_REVIEW_PACKAGE_REASON]: {
    id: "AmazonErrorCodeErrorsLabels.FAILED_TO_REVIEW_PACKAGE_REASON",
    defaultMessage: "Failed to review the package for the reason: {additionalInfo}. {tryAgain}"
  },
  [AmazonErrorCode.INVALID_MSKU_REMAP]: {
    id: "AmazonErrorCodeErrorsLabels.INVALID_MSKU_REMAP",
    defaultMessage: "The MSKU remap is invalid. {tryAgain}"
  },
  [AmazonErrorCode.ITEM_BLOCKED_BY_GATING]: {
    id: "AmazonErrorCodeErrorsLabels.ITEM_BLOCKED_BY_GATING",
    defaultMessage: "The item is blocked by gating. {tryAgain}"
  },
  [AmazonErrorCode.ITEM_UNDER_HAZMAT_REVIEW]: {
    id: "AmazonErrorCodeErrorsLabels.ITEM_UNDER_HAZMAT_REVIEW",
    defaultMessage: "The item is under hazmat review. {tryAgain}"
  },
  [AmazonErrorCode.ITEM_WITH_NO_VALID_FC]: {
    id: "AmazonErrorCodeErrorsLabels.ITEM_WITH_NO_VALID_FC",
    defaultMessage: "The item has no valid FC. {tryAgain}"
  },
  [AmazonErrorCode.LABEL_PREP_OWNER_INCOMPATIBILITY_ERROR]: {
    id: "AmazonErrorCodeErrorsLabels.LABEL_PREP_OWNER_INCOMPATIBILITY_ERROR",
    defaultMessage: "Label prep owner incompatibility error. {tryAgain}"
  },
  [AmazonErrorCode.NO_EXTERNAL_IDENTIFIER_FOR_EXISTING_MAP]: {
    id: "AmazonErrorCodeErrorsLabels.NO_EXTERNAL_IDENTIFIER_FOR_EXISTING_MAP",
    defaultMessage: "No external identifier for the existing map. {tryAgain}"
  },
  [AmazonErrorCode.OVER_ASIN_GUIDANCE]: {
    id: "AmazonErrorCodeErrorsLabels.OVER_ASIN_GUIDANCE",
    defaultMessage: "The ASIN guidance is exceeded. {tryAgain}"
  },
  [AmazonErrorCode.QUANTITY_IS_NOT_DIVISIBLE_BY_QTY_IN_CASE]: {
    id: "AmazonErrorCodeErrorsLabels.QUANTITY_IS_NOT_DIVISIBLE_BY_QTY_IN_CASE",
    defaultMessage: "The quantity is not divisible by the quantity in case. {tryAgain}"
  },
  [AmazonErrorCode.SUPPLY_CATEGORY_UNAVAILABLE]: {
    id: "AmazonErrorCodeErrorsLabels.SUPPLY_CATEGORY_UNAVAILABLE",
    defaultMessage: "The supply category is unavailable. {tryAgain}"
  },
  [AmazonErrorCode.UNKNOWN_SKU]: {
    id: "AmazonErrorCodeErrorsLabels.UNKNOWN_SKU",
    defaultMessage: "The SKU is unknown. {tryAgain}"
  },
  [AmazonErrorCode.EXTERNAL_ID_MAPS_WRONG_IAID_FOR_EXISTING_MAP]: {
    id: "AmazonErrorCodeErrorsLabels.EXTERNAL_ID_MAPS_WRONG_IAID_FOR_EXISTING_MAP",
    defaultMessage: "The external ID maps to the wrong IAID for the existing map. {tryAgain}"
  },
  [AmazonErrorCode.HAZMAT_ITEM_INELIGIBLE]: {
    id: "AmazonErrorCodeErrorsLabels.HAZMAT_ITEM_INELIGIBLE",
    defaultMessage: "The hazmat item is ineligible. {tryAgain}"
  },
  [AmazonErrorCode.EXCEEDED_UNITS_PER_ITEM_LIMIT]: {
    id: "AmazonErrorCodeErrorsLabels.EXCEEDED_UNITS_PER_ITEM_LIMIT",
    defaultMessage: "The units per item limit is exceeded. {tryAgain}"
  },
  [AmazonErrorCode.BRAND_RETAIL_ONLY_MERCHANT]: {
    id: "AmazonErrorCodeErrorsLabels.BRAND_RETAIL_ONLY_MERCHANT",
    defaultMessage: "The brand is retail-only for this merchant. {tryAgain}"
  },
  [AmazonErrorCode.UNITS_PER_CASE_EXCEEDED]: {
    id: "AmazonErrorCodeErrorsLabels.UNITS_PER_CASE_EXCEEDED",
    defaultMessage: "The units per case are exceeded. {tryAgain}"
  },
  [AmazonErrorCode.AMAZON_CONTRACT_NOT_SIGNED]: {
    id: "AmazonErrorCodeErrorsLabels.AMAZON_CONTRACT_NOT_SIGNED",
    defaultMessage: "The Amazon contract is not signed. {tryAgain}"
  },
  [AmazonErrorCode.CANNOT_CONFIRM_TRANSPORT_CONTENT]: {
    id: "AmazonErrorCodeErrorsLabels.CANNOT_CONFIRM_TRANSPORT_CONTENT",
    defaultMessage: "Cannot confirm the transport content. {tryAgain}"
  },
  [AmazonErrorCode.CANNOT_ESTIMATE_TRANSPORT_CONTENT]: {
    id: "AmazonErrorCodeErrorsLabels.CANNOT_ESTIMATE_TRANSPORT_CONTENT",
    defaultMessage: "Cannot estimate the transport content. {tryAgain}"
  },
  [AmazonErrorCode.CANNOT_VOID_TRANSPORT_CONTENT]: {
    id: "AmazonErrorCodeErrorsLabels.CANNOT_VOID_TRANSPORT_CONTENT",
    defaultMessage: "Cannot void the transport content. {tryAgain}"
  },
  [AmazonErrorCode.CARRIER_CONTRACT_NOT_SIGNED]: {
    id: "AmazonErrorCodeErrorsLabels.CARRIER_CONTRACT_NOT_SIGNED",
    defaultMessage: "The carrier contract is not signed. {tryAgain}"
  },
  [AmazonErrorCode.CARRIER_INVALID_FOR_MARKETPLACE]: {
    id: "AmazonErrorCodeErrorsLabels.CARRIER_INVALID_FOR_MARKETPLACE",
    defaultMessage: "The carrier is invalid for this marketplace. {tryAgain}"
  },
  [AmazonErrorCode.ERROR_ESTIMATING]: {
    id: "AmazonErrorCodeErrorsLabels.ERROR_ESTIMATING",
    defaultMessage: "Error estimating. {tryAgain}"
  },
  [AmazonErrorCode.INVALID_COUNTRY_SUBDIVISION_CODE]: {
    id: "AmazonErrorCodeErrorsLabels.INVALID_COUNTRY_SUBDIVISION_CODE",
    defaultMessage: "Invalid country subdivision code. {tryAgain}"
  },
  [AmazonErrorCode.MISSING_CARRIER_TYPE]: {
    id: "AmazonErrorCodeErrorsLabels.MISSING_CARRIER_TYPE",
    defaultMessage: "Missing carrier type. {tryAgain}"
  },
  [AmazonErrorCode.NO_PACKAGE_OR_PALLETS]: {
    id: "AmazonErrorCodeErrorsLabels.NO_PACKAGE_OR_PALLETS",
    defaultMessage: "No package or pallets. {tryAgain}"
  },
  [AmazonErrorCode.SELLER_IS_BLOCKED_BY_CORD_MANAGAER]: {
    id: "AmazonErrorCodeErrorsLabels.SELLER_IS_BLOCKED_BY_CORD_MANAGAER",
    defaultMessage: "The seller is blocked by the cord manager. {tryAgain}"
  },
  [AmazonErrorCode.SELLER_IS_BLOCKED_ON_INBOUNDING_SHIPMENTS]: {
    id: "AmazonErrorCodeErrorsLabels.SELLER_IS_BLOCKED_ON_INBOUNDING_SHIPMENTS",
    defaultMessage: "The seller is blocked on inbounding shipments. {tryAgain}"
  },
  [AmazonErrorCode.TRANSPORT_CONTENT_NOT_FOUND]: {
    id: "AmazonErrorCodeErrorsLabels.TRANSPORT_CONTENT_NOT_FOUND",
    defaultMessage: "Transport content not found. {tryAgain}"
  },
  [AmazonErrorCode.UNKNOWN_CARRIER_TYPE]: {
    id: "AmazonErrorCodeErrorsLabels.UNKNOWN_CARRIER_TYPE",
    defaultMessage: "Unknown carrier type. {tryAgain}"
  },
  [AmazonErrorCode.SHIPMENT_UPDATE_NOT_ALLOWED]: {
    id: "AmazonErrorCodeErrorsLabels.SHIPMENT_UPDATE_NOT_ALLOWED",
    defaultMessage: "Shipment update not allowed. {tryAgain}"
  },
  [AmazonErrorCode.SHIPMENT_STATUS_CHANGE_NOT_ALLOWED]: {
    id: "AmazonErrorCodeErrorsLabels.SHIPMENT_STATUS_CHANGE_NOT_ALLOWED",
    defaultMessage: "Shipment status change not allowed. {tryAgain}"
  },
  [AmazonErrorCode.ANDON_PULL_STRIKE_ONE]: {
    id: "replenishment.fba.orderItem.andonPullStrikeOne",
    defaultMessage: "The ASIN has been temporarily suspended due to a possible violation of FBA policies during delivery. Please check for an Andon Cord notification for the product on Seller Central that requires investigation."
  },
  [AmazonErrorCode.MULTI_OPTIONS_NOT_ALLOWED]: {
    id: "replenishment.fba.orderItem.multiOptionsNotAllowed",
    defaultMessage: "Received a MULTI_OPTIONS_NOT_ALLOWED error for this product from Amazon"
  },
  [AmazonErrorCode.DUPLICATE_FNSKU]: {
    id: "replenishment.fba.orderItem.duplicateFnsku",
    defaultMessage: "The FNSKU is appears to be a duplicate of an existing product FNSKU in Amazon"
  }
});