import { ContentType } from "@deliverr/replenishment-client";
export const getContentType = fileExtension => {
  switch (fileExtension) {
    case "pdf":
      return ContentType.PDF;
    case "xlsx":
      return ContentType.XLSX;
    case "csv":
      return ContentType.CSV;
    default:
      return null;
  }
};