import { useEffect, useState } from "react";
import { MonitorStatus } from "./MonitorStatus";
import { useTransferEstimationsStatus } from "../fba/useTransferEstimationsStatus";
const ORDER_CREATE_TIMEOUT = 60000; // 1 minute
export const useFBACreateStatusMonitor = () => {
  const [monitorStatus, setMonitorStatus] = useState(MonitorStatus.POLLING);
  const {
    isAutomatedFBAReady,
    hasError
  } = useTransferEstimationsStatus();
  useEffect(() => {
    const timer = setTimeout(() => {
      setMonitorStatus(MonitorStatus.TIMEOUT);
    }, ORDER_CREATE_TIMEOUT);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    if (isAutomatedFBAReady || hasError) {
      setMonitorStatus(MonitorStatus.COMPLETE);
    }
  }, [isAutomatedFBAReady, hasError]);
  return {
    monitorStatus
  };
};