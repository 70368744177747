import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { compositeStream, conditionalStream, consoleStream, datadogErrorTrackingStream, datadogLoggerStream, logger, minimumLevelStream, setGlobalContext, setStream } from "@deliverr/ui-logging";
import { isDevEnv } from "common/Config";
import { getIsValidDataDogEnv } from "common/utils/thirdParty/DataDog/DataDog";
import { DatadogFnLogTimer } from "common/utils/thirdParty/DataDog/DatadogFnLogTimer";
import { loadFullStoryIfNotLoaded } from "common/utils/thirdParty/FullStory";
import store from "store";

// Somehow or another Seller Portal is reliant on importing store in this file.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const localStore = store;
export const setLogUser = user => {
  setGlobalContext({
    user: {
      ...user,
      get fullStorySession() {
        // https://app.fullstory.com/ui/C8DBK/session/<logUser.fullStory>
        loadFullStoryIfNotLoaded();
        return window?.FS?.getCurrentSession && encodeURIComponent(window.FS.getCurrentSession() || "") || "";
      }
    }
  });
};
setStream(compositeStream(conditionalStream(() => isDevEnv)(minimumLevelStream("info")(consoleStream)), conditionalStream(getIsValidDataDogEnv)(compositeStream(datadogLoggerStream(datadogLogs.logger), datadogErrorTrackingStream(datadogRum)))));

// send custom actions to DataDog
const datadogFnTimer = new DatadogFnLogTimer();
const logStart = ctx => {
  datadogFnTimer.start(ctx);
  logger.info(ctx, `starting ${ctx.fn}`);
  return ctx;
};
const logSuccess = (ctx, message) => {
  datadogFnTimer.success(ctx);
  logger.info(ctx, message);
};
const logError = (ctx, err, message) => {
  datadogFnTimer.error(ctx);
  logger.error(ctx, err, message);
  // eslint-disable-next-line no-console
  console.error(err);
};
export { logStart, logSuccess, logError };
export default logger;