import { logError, logStart, logSuccess } from "Logger";
import { loadGoogleMaps } from "common/LoadGoogleMaps";
import { useEffect, useState } from "react";
import { lookupAddress, lookupCity } from "common/utils/GoogleGeoCoder";
import { geocodeByAddress } from "react-places-autocomplete";
import { CountryListMap } from "../CountrySelect";
import { emptyAddress, emptyAddressWithPhone } from "common/AddressUtils";
import { filterOutState } from "common/StringUtils";
export const useAddressInput = (address, countryOptions, onChange, countryAsSelect, showPhone) => {
  const [isGoogleAPILoaded, setIsGoogleAPILoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    const loadGoogleAPIs = async () => {
      const ctx = logStart({
        fn: "loadGoogleMapsAPI"
      });
      try {
        await loadGoogleMaps();
        // window.google should either be defined or loadGoogleMaps will throw an error here,
        // but checking for it just to be safe
        if (window.google !== undefined) {
          logSuccess(ctx, "Successfully loaded Google Maps API");
          setIsGoogleAPILoaded(true);
        }
      } catch (err) {
        logError(ctx, err, "Error loading Google Maps API");
      }
    };

    // eslint-disable-next-line no-void
    void loadGoogleAPIs();
  }, []);
  const handleSelect = async result => {
    setIsFetching(true);
    let geocode;
    try {
      geocode = await geocodeByAddress(result);
    } catch (err) {
      return;
    } finally {
      setIsFetching(false);
    }
    if (!geocode.length) {
      return;
    }
    const lookupAddressForCountry = components => {
      if (countryAsSelect) {
        const countryFromGeocode = lookupAddress(components, "country", "short_name");
        return Object.keys(CountryListMap[countryOptions]).find(option => option === countryFromGeocode);
      }
      return lookupAddress(components, "country", "long_name");
    };
    const components = geocode[0].address_components;
    const parsedAddress = {
      name: address?.name ?? "",
      company: address?.company ?? "",
      street1: [lookupAddress(components, "street_number"), lookupAddress(components, "route")].join(" "),
      street2: lookupAddress(components, "subpremise"),
      state: lookupAddress(components, "administrative_area_level_1"),
      zip: lookupAddress(components, "postal_code"),
      country: lookupAddressForCountry(components),
      city: lookupCity(components)
    };
    const addressProps = showPhone ? {
      ...parsedAddress,
      phone: address?.phone ?? ""
    } : parsedAddress;
    onChange(addressProps);
  };
  const handleAddressSearch = async result => {
    if (!result) {
      return;
    }
    const parsedAddress = {
      name: "",
      company: result?.company ?? result?.name ?? "",
      street1: result?.street1 ?? "",
      street2: result?.street2 ?? "",
      state: result?.state ?? "",
      zip: result?.zip ?? "",
      country: result?.country ?? "",
      city: result?.city ?? ""
    };
    const addressProps = showPhone ? {
      ...parsedAddress,
      phone: result?.phone ?? ""
    } : parsedAddress;
    onChange(addressProps);
  };
  const updateAddress = (key, val) => {
    const fallbackAddress = showPhone ? emptyAddressWithPhone : emptyAddress;
    onChange({
      ...(address ?? fallbackAddress),
      [key]: key === "state" ? filterOutState(val) : val
    });
  };
  const onStreetChange = e => updateAddress("street1", e.currentTarget.value);
  return {
    isGoogleAPILoaded,
    isFetching,
    handleSelect,
    updateAddress,
    onStreetChange,
    handleAddressSearch
  };
};