import styled from "@emotion/styled";
import HtmlToReact from "common/components/HtmlToReact";
export const ShippingPlanNameContainer = styled.div`
  display: inline-flex;
  align-items: center;

  &.editable {
    cursor: pointer;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }
`;
export const ShippingPlanName = styled.span(({
  theme
}) => `
    color: ${theme.colors.NEUTRAL[500]};
    font-family: ${theme.font.family.TITLE};
    font-size: ${theme.font.size.F4};
    font-weight: ${theme.font.weight.BOLD};
  `);
export const PromoIcon = styled(HtmlToReact)(({
  theme
}) => `
    width: 20px;
    height: 20px;
    margin-left: ${theme.spacing.S2};
  `);