import { useEffect, useRef, useState } from "react";
import { toast } from "common/components/ui";
import { useAsync } from "react-use";
import { useIntl } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import { isEmpty, isNil } from "lodash";
import { inboundClient } from "Clients";
import { getSellerId } from "common/user/UserSelectors";
import { addProducts, removeProduct, updateBarcodes, updateCaseQty, updateNumberOfCases, updateQty } from "inbounds/InboundActions";
import { BulkUploadError } from "./BulkUploadError";
import { BULK_UPLOAD_ERROR_MESSAGES } from "./BulkUploadErrorMessages";
import { BULK_UPLOAD_CONTENT_MESSAGES } from "./BulkUploadContentMessages";
import { getCsvDownloadUrl } from "common/utils/getCsvDownloadUrl";
import { getPlanHasProducts, getProductDskus } from "inbounds/InboundProductSelectors";
import { trackHeapEvent } from "common/utils/heap/trackHeapEvent";
import log, { logError, logStart, logSuccess } from "Logger";
import { getBulkUploadSessionId } from "inbounds/steps/InboundStepSelectors";
import { datadogRum } from "@datadog/browser-rum";
import { ShipmentFileUploadType } from "./ShipmentFileUploadType";
import { BulkUploadTemplateType } from "./BulkUploadTemplateType";
import { MAX_FILE_SIZE, SEP_CHARACTER, TIMEOUT_MS } from "./BulkUploadConstants";
import { uploadAndFetchReplenishmentProductDetails } from "./uploadAndFetchReplenishmentProductDetails";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
export const extractBulkUploadValidationErrors = errors => {
  return Object.entries(errors).map(([error, {
    cells,
    isWholeSheetError
  }]) => ({
    error: error,
    cells,
    isWholeSheetError
  }));
};
export const useBulkUploadModal = ({
  closeModal,
  showModal,
  casePacked,
  templateType,
  distributionChannel,
  isEachesOrder,
  handleBulkUploadSuccess,
  handleProductImportFailure
}) => {
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();
  const planHasProducts = useSelector(getPlanHasProducts);
  const productDskus = useSelector(getProductDskus);
  const sellerId = useSelector(getSellerId);
  const bulkUploadSessionId = useSelector(getBulkUploadSessionId);
  const [suppressWarningNotification, setSuppressWarningNotification] = useState(false);
  const [isFileTooBig, setIsFileTooBig] = useState(false);
  const [file, setFile] = useState();
  const [validationErrors, setValidationErrors] = useState();
  const [hasRequestError, setHasRequestError] = useState(false);
  const [scrollableErrors, setScrollableErrors] = useState(false);
  const [displayShowAllLink, setDisplayShowAllLink] = useState(false);
  const containerRef = useRef();
  const initialContainerHeight = useRef();
  const updateDisplayShowAllLink = container => {
    if (container) {
      const shouldDisplay = container.offsetHeight < container.scrollHeight;
      setDisplayShowAllLink(shouldDisplay);
    }
  };
  const prepareFile = (accepted, rejected, {
    target: {
      files
    }
  }) => {
    let fileSource = accepted;
    if (isNil(fileSource)) {
      fileSource = files;
    }
    if (isNil(fileSource)) {
      return;
    }
    const firstFile = fileSource[0];
    const fileTooBig = firstFile.size > MAX_FILE_SIZE;
    setIsFileTooBig(fileTooBig);
    setFile(!fileTooBig ? firstFile : undefined);
  };
  const clearFile = async () => {
    setFile(undefined);
  };
  const getDownloadErrorsUrl = () => {
    const headers = [formatMessage({
      id: "error",
      defaultMessage: "Error"
    }), formatMessage({
      id: "cells",
      defaultMessage: "Cells"
    })];
    const content = validationErrors.map(err => {
      return [formatMessage(BULK_UPLOAD_ERROR_MESSAGES[err.error]), err?.cells?.join("; ")];
    });
    return getCsvDownloadUrl(headers, content, SEP_CHARACTER);
  };
  const handleSuccessfulImport = async (products, dimensions, lotTracking, packages) => {
    if (isNil(products)) {
      return;
    }
    setSuppressWarningNotification(true);
    if (handleBulkUploadSuccess) {
      await handleBulkUploadSuccess?.(products, dimensions, lotTracking);
    } else {
      batch(() => {
        productDskus.forEach(dsku => dispatch(removeProduct(dsku)));
      });
      await dispatch(addProducts(products.filter(product => product.qty > 0)));
      dispatch({
        type: InboundActionTypes.ADD_PACKAGES,
        packages: packages?.filter(packageSummary => packageSummary.items.length > 0)
      });
      batch(() => {
        for (const product of products) {
          datadogRum.addAction("Add Product - Bulk Upload", {
            sellerId,
            product
          });
          if (casePacked && !isNil(product.qty) && product.qty > 0) {
            dispatch(updateNumberOfCases(product.dsku, product.qty / product.caseQty));
            dispatch(updateCaseQty(product.dsku, product.caseQty));
          } else if (!casePacked) {
            dispatch(updateQty(product.dsku, product.qty));
          }
        }
      });
    }
    const barcodes = {};
    for (const product of products) {
      if (product.plannedBarcodes) {
        barcodes[product.dsku] = product.plannedBarcodes;
      }
    }

    // TODO: auto save on bulk upload
    // dispatch(saveBoxesWithLoader());

    dispatch(updateBarcodes(barcodes));
    trackHeapEvent("inbounds.csvUploadSuccess");
    closeModal();
    toast.success(formatMessage(BULK_UPLOAD_CONTENT_MESSAGES.PRODUCTS_IMPORTED_SUCCESSFULLY, {
      productCount: products.length
    }));
  };
  const uploadFileState = useAsync(async () => {
    if (isNil(file)) {
      return;
    }
    setScrollableErrors(false);
    const timeoutPromise = new Promise(resolve => {
      setTimeout(() => {
        resolve(null);
      }, TIMEOUT_MS);
    });
    const ctx = logStart({
      fn: "bulkUpload.uploadFileState",
      sellerId,
      fileName: file.name,
      casePacked
    });
    if (isEmpty(bulkUploadSessionId)) {
      log.warn({
        sellerId,
        fileName: file.name,
        casePacked
      }, "bulkUploadSessionId is empty");
    }
    try {
      let response;
      if (templateType && [BulkUploadTemplateType.REPLENISHMENT, BulkUploadTemplateType.REPLENISHMENT_EACHES].includes(templateType)) {
        response = await uploadAndFetchReplenishmentProductDetails(sellerId, file, distributionChannel, isEachesOrder, ctx);
      } else {
        const type = templateType === BulkUploadTemplateType.STORAGE ? ShipmentFileUploadType.STORAGE : ShipmentFileUploadType.DEFAULT;
        const uploadPromise = inboundClient.shippingPlanSpreadsheetUpload(sellerId, file, +casePacked === 1, bulkUploadSessionId ?? "",
        // bulkUploadSessionId should always be defined here, but just in case...
        type);
        response = await Promise.race([uploadPromise, timeoutPromise]);
      }
      if (isNil(response) || response.status && response.status !== 200) {
        setHasRequestError(true);
        await clearFile();
        setValidationErrors(undefined);
        return;
      }
      setHasRequestError(false);
      const {
        data: {
          errors,
          items,
          dims,
          lotTracking,
          packages
        }
      } = response;
      if (errors) {
        logError(ctx, errors, "Error uploading file");
        if (errors[BulkUploadError.FILE_TOO_BIG]) {
          setIsFileTooBig(true);
          delete errors.FILE_TOO_BIG;
        }
        await clearFile();
        setValidationErrors(extractBulkUploadValidationErrors(errors));
        return;
      }
      logSuccess(ctx, "successfully uploaded file");
      try {
        await handleSuccessfulImport(items, dims, lotTracking, packages);
      } catch (err) {
        logError(ctx, errors, "Error processing uploaded file");
        await clearFile();
        handleProductImportFailure?.(err, setValidationErrors);
      }
    } catch (error) {
      logError(ctx, error, "Error uploading file");
      setHasRequestError(true);
      await clearFile();
    }
  }, [file]);
  const resetModal = () => {
    setHasRequestError(false);
    setIsFileTooBig(false);
    setValidationErrors(undefined);
    setFile(undefined);
    setScrollableErrors(false);
    setSuppressWarningNotification(false);
  };
  useEffect(() => {
    if (showModal) {
      resetModal();
    }
  }, [showModal]);
  useEffect(() => {
    if (!isNil(containerRef.current) && !scrollableErrors) {
      initialContainerHeight.current = containerRef.current.offsetHeight;
    }
  });
  return {
    isFileTooBig,
    uploadFileState,
    file,
    validationErrors,
    hasRequestError,
    planHasProducts,
    prepareFile,
    clearFile,
    scrollableErrors,
    setScrollableErrors,
    containerRef,
    updateDisplayShowAllLink,
    initialContainerHeight,
    displayShowAllLink,
    suppressWarningNotification,
    getDownloadErrorsUrl
  };
};