import { sumBy } from "lodash/fp";
export const useStorageShipmentUnitData = receivingInfo => {
  const expectedQty = sumBy("expectedQty", receivingInfo?.items);
  const receivedQty = sumBy("receivedQty", receivingInfo?.items);
  const damagedQty = sumBy("damagedQty", receivingInfo?.items);
  const discrepancyQty = expectedQty - receivedQty - damagedQty;
  return {
    expectedQty,
    receivedQty,
    damagedQty,
    discrepancyQty
  };
};