import { OpenSearchService } from "common/search/services/OpenSearchService";
export class ParcelSearchService extends OpenSearchService {
  buildSearchOptions(request) {
    const searchOptions = super.buildSearchOptions(request);
    const shouldQueries = [];
    const filterQueries = [];
    const mustNotQueries = [];
    if (request.searchTerm) {
      shouldQueries.push({
        query_string: {
          query: ["parcelId", "customerParcelId", "trackingCode", "customerName", "packageTrackingUrl", "flexId"].map(f => `${f}:"*${request.searchTerm}*"`).join(" OR "),
          analyze_wildcard: true
        }
      });
      shouldQueries.push({
        multi_match: {
          query: request.searchTerm,
          fields: ["shippingMethod", "lengthUnit", "weightUnit", "serviceLevel", "status"]
        }
      });
    }
    if (request?.filters) {
      for (const filter of request.filters) {
        const [key, value] = filter.split("=");
        if (key === "createdAt") {
          const parts = value.split("_");
          const daysAgo = parseInt(parts[1], 10);
          if (!isNaN(daysAgo)) {
            const createdAtValue = new Date();
            createdAtValue.setDate(createdAtValue.getDate() - daysAgo);
            filterQueries.push({
              range: {
                createdAt: {
                  gte: createdAtValue.getTime()
                }
              }
            });
          }
        } else {
          filterQueries.push({
            term: {
              [key]: value
            }
          });
        }
      }
    }
    mustNotQueries.push({
      match: {
        status: "CANCELLED"
      }
    });
    return {
      ...searchOptions,
      query: {
        bool: {
          should: shouldQueries,
          minimum_should_match: shouldQueries.length > 1 ? 1 : 0,
          filter: filterQueries,
          must_not: mustNotQueries
        }
      }
    };
  }
}