import { useSelector } from "react-redux";
import { getShippingPlan } from "inbounds/steps/InboundStepSelectors";
export const useShipmentProblemUnexpectedBarcodeBlade = (shipmentProblem, hideShipmentProblemBlade, setSelectedProblem) => {
  const {
    mappedSkus
  } = shipmentProblem;
  const {
    name: shippingPlanName,
    id: shippingPlanId
  } = useSelector(getShippingPlan);
  const resetBladeState = () => {
    hideShipmentProblemBlade();
    setSelectedProblem(undefined);
  };
  return {
    resetBladeState,
    shippingPlanId,
    shippingPlanName,
    mappedSkus
  };
};