import { logError, logStart } from "Logger";
import { batch } from "react-redux";
import { LabelLanguage } from "common/clients/inbound/LabelLanguage";
import { inboundClient } from "Clients";
import { getLoadedShipment } from "inbounds/store/selectors/shipments/getLoadedShipment";
import { setBoxLabelUrl } from "./setBoxLabelUrl";
export const fetchBoxLabelUrls = () => async (dispatch, getState) => {
  const state = getState();
  const {
    inbound: {
      plan: {
        id: shippingPlanId
      },
      loadedShipmentId
    },
    user: {
      sellerId
    }
  } = state;

  // may not exist if shipments have not been generated yet
  const loadedShipment = getLoadedShipment(state);
  if (!loadedShipment) {
    return;
  }
  const {
    status
  } = loadedShipment;
  const ctx = logStart({
    fn: "fetchBoxLabelUrls",
    shipmentStatus: status,
    shippingPlanId,
    loadedShipmentId
  });
  try {
    // fetch all existing labels for both shipments and plans
    const [shippingPlanUrls = {}, shipmentUrls = {}] = await Promise.all([inboundClient.getAllBoxLabelsUrls(sellerId, shippingPlanId), inboundClient.getAllBoxLabelsUrls(sellerId, shippingPlanId, loadedShipmentId)]);
    batch(() => {
      // store any valid plan-level labels
      Object.entries(shippingPlanUrls).forEach(([format, url]) => {
        if (!format || url?.length < 3) {
          return;
        }
        dispatch(setBoxLabelUrl({
          format,
          labelLanguage: LabelLanguage.ENGLISH,
          shippingPlanId,
          shipmentId: undefined,
          url
        }));
      });

      // store any valid shipment-level labels
      Object.entries(shipmentUrls).forEach(([format, url]) => {
        if (!format || url?.length < 3) {
          return;
        }
        dispatch(setBoxLabelUrl({
          format,
          labelLanguage: LabelLanguage.ENGLISH,
          shippingPlanId,
          shipmentId: loadedShipmentId,
          url
        }));
      });
    });
  } catch (err) {
    logError(ctx, err);
  }
};