export const USER_DEACTIVATED_MESSAGE = "User has been deactivated";
export default class GraphQLError extends Error {
  response = null;
  sentryEventId = null;
  constructor(level, errors, operation, variables,
  // eslint-disable-next-line default-param-last
  requestId = null, extra) {
    let messages = errors.map(stringifyErrorIsh);
    if (level === "Transport") {
      if (errors.length === 1 && errors[0] instanceof TypeError) {
        messages = ["Please check if you are connected to the internet"];
      }
    }
    super(messages.join("\n"));
    this.level = level;
    this.errors = errors;
    this.operation = operation;
    this.variables = variables;
    this.requestId = requestId;
    this.extra = extra;
    this.messages = messages;
  }
  debugMessage() {
    let errorInfo;
    if (this.errors.length === 1) {
      errorInfo = stringifyErrorIsh(this.errors[0]);
    } else {
      errorInfo = `${this.operation.name} ${this.errors.length} errors`;
    }
    return `GraphQL ${this.level} Error ${errorInfo}`;
  }
}
function stringifyErrorIsh(error) {
  if (typeof error === "string") {
    return error;
  }
  if ("field" in error && typeof error.field === "string" && error.field.length > 0) {
    return `${error.field} ${error.message}`;
  }
  return error.message;
}