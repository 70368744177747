import { createSelector } from "reselect";
import { selectLtlPalletDimsViolations } from "./selectLtlPalletDimsViolations";
import { selectFtlPalletDimsViolations } from "./selectFtlPalletDimsViolations";
import { selectIsLtlPallets } from "./selectIsLtlPallets";
/**
 * Checks for dimensions compliance on each individual pallet and on cumulative totals for weight, height and qty
 */
export const selectPalletDimsCompliance = createSelector(selectIsLtlPallets, selectLtlPalletDimsViolations, selectFtlPalletDimsViolations, (isLtlPallets, ltlPalletDimsViolations, ftlPalletDimsViolations) => {
  const palletDimsViolations = isLtlPallets ? ltlPalletDimsViolations : ftlPalletDimsViolations;
  return {
    palletDimsViolations,
    isValid: palletDimsViolations.length === 0
  };
});