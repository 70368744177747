import { trackHeapEvent } from "common/utils/heap/trackHeapEvent";
import { useCallback, useRef, useState } from "react";
export const useStorageRecommendationAlert = () => {
  const showStorageRecommendationAlertRef = useRef(false);
  const [isStorageRecommendationAlert, setIsShowStorageRecommendationAlert] = useState(false);
  const showStorageRecommendationAlert = useCallback(() => {
    if (!showStorageRecommendationAlertRef.current) {
      showStorageRecommendationAlertRef.current = true;
      setIsShowStorageRecommendationAlert(true);
      trackHeapEvent("storage.recommendation.banner.show");
    }
  }, []);
  const hideStorageRecommendationAlert = () => {
    setIsShowStorageRecommendationAlert(false);
  };
  return {
    isStorageRecommendationAlertVisible: isStorageRecommendationAlert,
    showStorageRecommendationAlert,
    hideStorageRecommendationAlert
  };
};