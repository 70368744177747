export let ReturnLoader;
(function (ReturnLoader) {
  ReturnLoader["ReturnsCreateLoader"] = "ReturnsCreateLoader";
  ReturnLoader["ReturnsLabelLoader"] = "ReturnsLabelLoader";
  ReturnLoader["ReturnsListLoader"] = "ReturnsListLoader";
  ReturnLoader["ReturnsSellerLoader"] = "ReturnsSellerLoader";
  ReturnLoader["ReturnsSellerUpdateAddressLoader"] = "ReturnsSellerUpdateAddressLoader";
  ReturnLoader["ReturnsSellerUpdateCarrierLoader"] = "ReturnsSellerUpdateCarrierLoader";
  ReturnLoader["ReturnsOnboardLoader"] = "ReturnsOnboardLoader";
  ReturnLoader["ReturnsRmtLoader"] = "ReturnsRmtLoader";
  ReturnLoader["ReturnsSopLoader"] = "ReturnsSopLoader";
  ReturnLoader["ReturnsSellerDispositionUpdateLoader"] = "ReturnsSellerDispositionUpdateLoader";
})(ReturnLoader || (ReturnLoader = {}));