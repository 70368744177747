export let InboundShipmentStatus;
(function (InboundShipmentStatus) {
  InboundShipmentStatus["NEED_PACKAGE_DETAILS"] = "NEED_PACKAGE_DETAILS";
  InboundShipmentStatus["PACKAGES_ADDED"] = "PACKAGES_ADDED";
  InboundShipmentStatus["PACKAGES_FINAL"] = "PACKAGES_FINAL";
  InboundShipmentStatus["READY_FOR_WAREHOUSE"] = "READY_FOR_WAREHOUSE";
  InboundShipmentStatus["IN_TRANSIT"] = "IN_TRANSIT";
  InboundShipmentStatus["ARRIVED"] = "ARRIVED";
  InboundShipmentStatus["CROSS_DOCKING"] = "CROSS_DOCKING";
  InboundShipmentStatus["IN_PREP"] = "IN_PREP";
  InboundShipmentStatus["FORWARDING"] = "FORWARDING";
  InboundShipmentStatus["RECEIVING"] = "RECEIVING";
  InboundShipmentStatus["COMPLETED"] = "COMPLETED";
})(InboundShipmentStatus || (InboundShipmentStatus = {}));