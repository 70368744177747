import { selectCreateShipmentForm, selectCreateShipmentMeta } from "inbounds/createShipment/store/selectors";
import { createSelector } from "reselect";
import pickBy from "lodash/pickBy";
import isNil from "lodash/isNil";
import { getFlexportUser } from "common/flexportUser/FlexportUserSelectors";
import { OriginType } from "inbounds/createShipment/CreateShipmentTypes";
const getIsInternationalOrigin = originType => originType ? originType === OriginType.INTERNATIONAL : false;
export const selectSmbBookingCreationContext = isRevampedOriginFeatureOn => createSelector(getFlexportUser, selectCreateShipmentForm, selectCreateShipmentMeta, (flexportUser, createShipmentForm, meta) => {
  const attributes = {
    clientFid: flexportUser.clientFid,
    shippingPlanId: meta.shippingPlanId ?? undefined,
    isReturningUser: meta.isReturningUser,
    isInternationalOrigin: getIsInternationalOrigin(createShipmentForm.originType),
    isFlexportCapitalSelected: createShipmentForm.flexportCapitalSelected,
    isCustomsBondSelected: createShipmentForm.hasAnnualCustomsBond,
    isInsuranceSelected: createShipmentForm.hasCargoInsurance,
    distributionType: createShipmentForm.distributionType ?? undefined,
    amazonDesinationType: createShipmentForm.amazonDestinationType ?? undefined,
    amazonFacilityCode: createShipmentForm.amazonAddress?.name ?? undefined,
    amazonPrepType: createShipmentForm.amazonPrepType ?? undefined,
    freightServiceTitle: createShipmentForm.freightService?.title ?? undefined,
    isRevampedOriginFeatureOn: isRevampedOriginFeatureOn ?? undefined
  };
  // filter out falsy values for a more readable context
  return pickBy(attributes, attr => !isNil(attr) && attr !== "" && attr !== false);
});