import { defineMessages } from "react-intl";
export const PREP_CHARGE_GRANULARITY_LABELS = defineMessages({
  PER_BOX: {
    id: "inbounds.prep.chargeGranularity.perBox",
    defaultMessage: "{costPerItem}{costSpecialHandling}/box"
  },
  PER_UNIT: {
    id: "inbounds.prep.chargeGranularity.perUnit",
    defaultMessage: "{costPerItem}{costSpecialHandling}/unit"
  },
  PER_CONTAINER: {
    id: "inbounds.prep.chargeGranularity.perContainer",
    defaultMessage: "{costPerItem}/container"
  }
});