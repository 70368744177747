import { batch } from "react-redux";
import { addLoader, clearLoader } from "../components/WithLoader/LoadingActions";
import { wholesaleClient } from "../../Clients";
import { Channel } from "@deliverr/wholesale-client";
import { getWholesaleOrderWithPacks } from "storage/packUtils/getWholesaleorderWithPacks";
import { WholesaleErrorType } from "./utils/WholesaleErrorTypes";
import { FeatureName, isFeatureOn } from "common/Split";
import { createWholesaleOrderShipment } from "./createWholesaleOrderShipments";
export const sharedUpdateWholesaleOrder = ({
  createStateSelector,
  productSelector,
  loaderId,
  setError,
  setValue
}) => async (dispatch, getState) => {
  const state = getState();
  const {
    wholesaleOrder,
    shippingMethod,
    cargoType,
    fbaShipmentId,
    fbaReferenceId,
    originStorageWarehouse
  } = createStateSelector(state);
  if (!wholesaleOrder?.id) {
    return false;
  }
  const isMultiPackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);
  const selectedProductsQty = productSelector(state);
  batch(() => {
    dispatch(addLoader(loaderId));
    dispatch(setError(WholesaleErrorType.NONE));
  });
  const response = await dispatch(getWholesaleOrderWithPacks(wholesaleOrder?.id));
  dispatch(setValue(response));
  const request = {
    externalId: response.externalId ?? fbaShipmentId,
    createdAutomatically: response.createdAutomatically === true || response.channel === Channel.JIT_FBA_RECOMMENDATIONS,
    shipments: [createWholesaleOrderShipment(response, selectedProductsQty, shippingMethod, cargoType, fbaShipmentId, fbaReferenceId, originStorageWarehouse, isMultiPackEnabled)],
    channel: response.channel
  };
  await wholesaleClient.updateOrderAndShipments(wholesaleOrder.id, "", request);
  dispatch(clearLoader(loaderId));
  return true;
};