import { mustBeDefined } from "common/utils/mustBeDefined";
import { pickBy } from "lodash";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { handleSellerServiceError } from "./handleSellerServiceError";
export class OrganizationClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.SELLER_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async getOrganizationWithUsers(organizationId) {
    try {
      return await this.apiClient.get({
        url: "/organization",
        params: {
          organizationId,
          includeUsers: true,
          includeSfn: true
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("getOrganizationWithUsers", error);
    }
  }
  async updateSellerClaims(sellerId, isCatalogImported, onboardingStage, dealStage, hasChargebeeAccount, showChecklist) {
    const bodyObject = pickBy({
      dealStage,
      hasChargebeeAccount,
      isCatalogImported,
      onboardingStage,
      showChecklist
    }, value => typeof value !== "undefined");
    try {
      return await this.apiClient.put({
        url: `organization/${sellerId}/claims/update`,
        body: bodyObject,
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("updateSellerClaims", error);
    }
  }
  async updateOrganization(organizationId, attributes) {
    try {
      return await this.apiClient.put({
        url: `/organization/${organizationId}`,
        body: attributes,
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("updateOrganizationActiveStatus", err);
    }
  }
  async addNewUserToExistingSellerGroup(organizationId, firstName, lastName, email, entities, role, contactAreas, phoneNumber) {
    try {
      return await this.apiClient.post({
        url: `/organization/${organizationId}/user`,
        body: {
          firstName,
          lastName,
          email,
          entities,
          role,
          contactAreas,
          phoneNumber
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("addNewUserToExistingSellerGroup", error);
    }
  }
  async resendSignUpEmailInvitation(organizationId, email) {
    try {
      return await this.apiClient.post({
        url: `/organization/${organizationId}/user/${email}/resend_email_invite`,
        body: null,
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("resendSignUpEmailInvitation", error);
    }
  }
  async changeUserRoleInOrganization(organizationId, email, isUpgraded) {
    try {
      return await this.apiClient.put({
        url: `/organization/${organizationId}/user/${email}/`,
        body: null,
        params: {
          upgrade: isUpgraded
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      handleSellerServiceError("changeUserRoleInOrganization", error);
    }
  }
  async getAlgoliaSearchKey(organizationId) {
    try {
      return await this.apiClient.get({
        url: `/organization/${organizationId}/algoliaSearchKey`,
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("getAlgoliaSearchKey", err);
    }
  }
  async getIsFlexportAccountConnected(organizationId, logisticsAccountId) {
    const redirectUrl = window.location.href;
    try {
      return await this.apiClient.get({
        url: `/organization/${organizationId}/isFlexportAccountConnected`,
        params: {
          logisticsAccountId,
          redirectUrl
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      handleSellerServiceError("getIsFlexportAccountConnected", err);
    }
  }
}