import { wishClient } from "common/clients/instances";
import { notifyUserOfError } from "common/ErrorToast";
import { logError, logStart } from "Logger";
export let WishActionTypes;
(function (WishActionTypes) {
  WishActionTypes["WISH_CODE_RECEIVED"] = "WISH_CODE_RECEIVED";
  WishActionTypes["WISH_SELLER_VALIDATED"] = "WISH_SELLER_VALIDATED";
  WishActionTypes["WISH_FAST_TAGS_ACTIVE_CHANGED"] = "WISH_FAST_TAGS_ACTIVATED";
  WishActionTypes["WISH_SELLER_RECEIVED"] = "WISH_SELLER_RECEIVED";
  WishActionTypes["WISH_SELLER_UPDATED"] = "WISH_SELLER_UPDATED";
})(WishActionTypes || (WishActionTypes = {}));
export const wishCodeRecieved = code => ({
  type: WishActionTypes.WISH_CODE_RECEIVED,
  code
});
export const wishSellerValidated = success => ({
  type: WishActionTypes.WISH_SELLER_VALIDATED,
  success
});
export const fastTagActiveChanged = active => ({
  type: WishActionTypes.WISH_FAST_TAGS_ACTIVE_CHANGED,
  active
});
export const wishSellerUpdated = success => ({
  type: WishActionTypes.WISH_SELLER_UPDATED,
  success
});
export const wishSellerReceived = seller => ({
  type: WishActionTypes.WISH_SELLER_RECEIVED,
  seller
});
export const deactivateWish = () => async (_, getState) => {
  const ctx = logStart({
    fn: "deactivateWish"
  });
  const {
    user: {
      sellerId
    }
  } = getState();
  try {
    await wishClient.updateSeller(sellerId, {
      isActive: false
    });
    location.reload();
  } catch (err) {
    notifyUserOfError({
      err,
      toastId: "wishDeactivationError"
    });
    logError(ctx, err);
  }
};