import { ProductPrepCategory } from "@deliverr/commons-clients/lib/product/ProductPreparation";
import { sortBy } from "lodash/fp";
import { useAssignProductCategories } from "inbounds/steps/categories/useAssignProductCategories";
const getPlanItemDetails = (bookingProductsWithNoCategories, productDetails) => {
  const itemsDetails = bookingProductsWithNoCategories.map(({
    dsku
  }) => {
    const {
      name,
      msku,
      productPreparation,
      customsInformation,
      brandedPackaging
    } = productDetails[dsku];
    return {
      dsku,
      name,
      msku,
      customsInformation,
      brandedPackaging,
      // prevent BP triggering category assignment by providing default of OTHER
      category: brandedPackaging ? ProductPrepCategory.OTHER : productPreparation?.category,
      packagingType: productPreparation?.packagingType
    };
  });
  return sortBy("dsku", itemsDetails);
};
export const useAssignProductCategoriesModal = (productsWithNoCategory, productDetails, setShowModal) => {
  const planItemDetails = getPlanItemDetails(productsWithNoCategory, productDetails);
  const onClose = () => {
    setShowModal(false);
  };
  const {
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    hasMissingCategories,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    toggleAllRows,
    toggleRow,
    onNextClick
  } = useAssignProductCategories(planItemDetails, onClose);
  return {
    allRowsSelected,
    anyRowsSelected,
    categorySelectOptions,
    hasMissingCategories,
    itemDetails,
    productsSelected,
    selectedRows,
    setCategoryForSelectedItems,
    setItemCategory,
    onSave: onNextClick,
    onClose,
    toggleAllRows,
    toggleRow
  };
};