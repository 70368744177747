import { toast } from "common/components/ui";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchRmtsAction, RmtErrorMessages } from "./action/RmtActions";
export const useRmtInfo = () => {
  const {
    sellerRmts,
    stepId,
    allRmtIds
  } = useSelector(state => state.returnsRmt);
  const dispatch = useDispatch();
  const {
    formatMessage
  } = useIntl();
  const {
    error: hasError,
    loaded: isLoaded,
    loading: isLoading
  } = sellerRmts;
  useEffect(() => {
    if (!isLoaded && !isLoading && !hasError) {
      dispatch(fetchRmtsAction());
    }
  }, [dispatch, isLoaded, isLoading, hasError]);
  useEffect(() => {
    if (hasError) {
      const errorMessage = formatMessage(RmtErrorMessages.fetchRmtFailed);
      toast.error(errorMessage, {
        toastId: "returns.rmt.fetch.toast.error"
      });
    }
  }, [hasError, formatMessage]);
  return {
    sellerRmts,
    stepId,
    allRmtIds
  };
};