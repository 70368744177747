import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { toast } from "common/components/ui";
import { useIntl } from "react-intl";
import history from "BrowserHistory";
import { useStepMetadata } from "inbounds/hooks/useStepMetadata";
import { UnknownBarcodeSteps, UNKNOWN_BARCODE_STEPS } from "../UnknownBarcodeCase/UnknownBarcodeSteps";
import { useFetchNCProduct, useFetchShippingPlan } from "inbounds/hooks";
import { sellerProblemsViewClient } from "Clients";
import { removeAllEmTags } from "common/StringUtils";
import log from "Logger";
import RouteParser from "common/RouteParser";
import { nonComplianceInternalRoutes as routes } from "inbounds/non-compliance";
export const useUnknownBarcodeCaseContextProvider = () => {
  const {
    nonCompliantId
  } = useParams();
  const {
    formatMessage
  } = useIntl();
  const [isRecognizedProduct, setIsRecognizedProduct] = useState(true);
  const [foundProduct, setFoundProduct] = useState();
  const stepMetadata = useStepMetadata(UNKNOWN_BARCODE_STEPS);
  const {
    value: ncProduct,
    isLoading: isNCProductLoading
  } = useFetchNCProduct({
    sellerProblemId: nonCompliantId
  });
  const {
    value: shippingPlan
  } = useFetchShippingPlan({
    shippingPlanId: ncProduct?.shippingPlanId
  });
  const {
    setStepIsValid
  } = stepMetadata;
  const onSelectIsRecognizedProduct = ({
    target: {
      value
    }
  }) => setIsRecognizedProduct(value === "true");
  const onProductSelect = selectedProduct => {
    setFoundProduct(selectedProduct);
    setStepIsValid({
      stepKey: UnknownBarcodeSteps.IDENTIFY_PRODUCT,
      isValid: false
    });
  };
  const onContinue = () => {
    setStepIsValid({
      stepKey: UnknownBarcodeSteps.IDENTIFY_PRODUCT,
      isValid: true
    });
  };
  const [{
    loading: isOnSubmitLoading
  }, onSubmit] = useAsyncFn(async () => {
    const ctx = {
      ncProduct,
      foundProduct
    };
    if (!ncProduct) {
      log.error({
        ...ctx
      }, "resolution called without NC product");
      return;
    } else if (!foundProduct) {
      return;
    } else {
      log.info({
        ...ctx
      }, "resolving NC product");
      try {
        await sellerProblemsViewClient.completeUnknownBarcodeProductIdentification({
          sellerProblemId: ncProduct.sellerProblemId,
          dsku: removeAllEmTags(foundProduct.dsku)
        });
        history.push(new RouteParser(routes.unknownBarcodeSuccess).parse({
          nonCompliantId: ncProduct.sellerProblemId
        }));
      } catch (error) {
        log.error({
          ...ctx,
          error
        }, "error resolving NC product");
        toast.error(formatMessage({
          id: "nonCompliance.product.resolutionError",
          defaultMessage: "There was an issue resolving your shipment problem"
        }));
      }
    }
  }, [ncProduct, foundProduct]);
  const [{
    loading: isOnDismissLoading
  }, onDismiss] = useAsyncFn(async () => {
    const ctx = {
      ncProduct
    };
    if (!ncProduct) {
      log.error({
        ...ctx
      }, "resolution called without NC product");
    } else {
      log.info({
        ...ctx
      }, "dismissing NC product");
      try {
        await sellerProblemsViewClient.failedToIdentifyProductForUnknownBarcode(ncProduct.sellerProblemId);
        history.push(new RouteParser(routes.unknownBarcodeSuccess).parse({
          nonCompliantId: ncProduct.sellerProblemId
        }));
      } catch (error) {
        log.error({
          ...ctx,
          error
        }, "error dismissing NC product");
        toast.error(formatMessage({
          id: "nonCompliance.product.resolutionError",
          defaultMessage: "There was an issue resolving your shipment problem"
        }));
      }
    }
  }, [ncProduct]);
  return {
    ncProduct,
    isNCProductLoading,
    shippingPlan,
    foundProduct,
    onProductSelect,
    isRecognizedProduct,
    setIsRecognizedProduct: onSelectIsRecognizedProduct,
    onDismiss,
    isOnDismissLoading,
    onContinue,
    onSubmit,
    isOnSubmitLoading,
    stepMetadata
  };
};