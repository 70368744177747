import { InvalidOrderItemReason, InvalidOrderItemResolution } from "@deliverr/replenishment-client";
import { defineMessages } from "react-intl";
const invalidOrderItemReasonDescription = {
  [InvalidOrderItemReason.INVALID_CASE_CONFIG]: {
    id: "replenishment.invalidItemFlow.invalidCaseConfig",
    defaultMessage: "Invalid Case Configuration"
  },
  [InvalidOrderItemReason.INVALID_ITEM]: {
    id: "replenishment.invalidItemFlow.invalidItem",
    defaultMessage: "Unknown Product"
  },
  [InvalidOrderItemReason.OUT_OF_STOCK]: {
    id: "replenishment.invalidItemFlow.outOfStock",
    defaultMessage: "Item out of stock"
  }
};
export const UNRESOLVED_INVALID_ITEM_RESOLUTION = "UNRESOLVED_INVALID_ITEM_RESOLUTION";
const invalidOrderItemResolutionDescription = {
  [InvalidOrderItemResolution.REMOVED]: {
    id: "replenishment.invalidItemFlow.resolution.removed",
    defaultMessage: "Removed"
  },
  [InvalidOrderItemResolution.MODIFIED]: {
    id: "replenishment.invalidItemFlow.resolution.modified",
    defaultMessage: "Resolved"
  },
  [UNRESOLVED_INVALID_ITEM_RESOLUTION]: {
    id: "replenishment.invalidItemFlow.resolution.actionRequired",
    defaultMessage: "Action Required"
  }
};
export const invalidOrderItemLabel = {
  reasons: {
    ...defineMessages(invalidOrderItemReasonDescription)
  },
  resolutions: {
    ...defineMessages(invalidOrderItemResolutionDescription)
  },
  step: {
    ...defineMessages({
      title: {
        id: "transfers.create.steps.origin.title",
        defaultMessage: "Fix invalid items"
      },
      subtitle: {
        id: "transfers.create.steps.origin.title",
        defaultMessage: "Resolve invalid items to move to next step"
      }
    })
  },
  blade: {
    ...defineMessages({
      title: {
        id: "replenishment.nonCompliance.blade.header",
        defaultMessage: "Problem"
      },
      requestedUnitsPerPack: {
        id: "replenishment.nonCompliance.blade.requestedUnitsPerPack",
        defaultMessage: "Requested Units Per Pack: {requestedUnitsPerPack}"
      },
      requestedTotalUnits: {
        id: "replenishment.nonCompliance.blade.requestedTotalUnits",
        defaultMessage: "Requested Total Units: {requestedTotalUnits}"
      },
      removeProduct: {
        id: "replenishment.nonCompliance.removeProductHeader",
        defaultMessage: "Remove this product from order?"
      },
      yes: {
        id: "replenishment.nonCompliance.remove.product.yes",
        defaultMessage: "Yes"
      },
      no: {
        id: "replenishment.nonCompliance.remove.product.no",
        defaultMessage: "No"
      },
      continue: {
        id: "replenishment.nonCompliance.product.continue",
        defaultMessage: "Continue"
      },
      unknownItem: {
        id: "replenishment.nonCompliance.unknownItem",
        defaultMessage: "Select product to replace with"
      },
      unknownItemCaseConfig: {
        id: "replenishment.nonCompliance.unknownItemCaseConfig",
        defaultMessage: "Select case configuration for product"
      }
    })
  },
  resolvedItems: {
    ...defineMessages({
      title: {
        id: "inbounds.ProblemType.relabeledBox",
        defaultMessage: "Resolved order Items"
      }
    })
  },
  invalidItems: {
    ...defineMessages({
      title: {
        id: "transfers.invalid.order.items.contents.title",
        defaultMessage: "Invalid order Items"
      }
    })
  }
};