import { BrowserStorageCacheClass } from "@aws-amplify/cache/lib/BrowserStorageCache";
import { CookieStorage } from "amazon-cognito-identity-js";
export default class AuthStorageConsumer extends BrowserStorageCacheClass {
  constructor(domain, config) {
    super(config);
    this.cookieStorage = new CookieStorage({
      domain,
      path: "/",
      expires: 30,
      // cross-domain auth doesn't work with option secure = true :(
      secure: false
    });
  }
  getItem(key, options) {
    const cookieData = this.cookieStorage.getItem(key);
    if (cookieData) {
      super.setItem(key, cookieData, options);

      // remove cookie since it was transfered to localstorage
      this.cookieStorage.removeItem(key);
    }
    return super.getItem(key, options);
  }
}