import { useSelector } from "react-redux";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import { getSelectedProductsCaseDimensions } from "storage/inbounds/create/store/selector/getSelectedProductsCaseDimensions";
import { useMemo } from "react";
import { calcCasePackTotalVolume } from "./utils/calcCasePackTotalVolume";
import { calcCasePackTotalWeight } from "./utils/calcCasePackTotalWeight";
import { getSelectedProductCasePacks } from "storage/inbounds/create/store/selector/getSelectedCasePacks";
import { sum } from "lodash";
import { calcPackageTotalVolume } from "./utils/calcPackageTotalVolume";
import { calcPackageTotalWeight } from "./utils/calcPackageTotalWeight";
export const useSelectedProductTotals = (isDtcNetworkBooking, packages) => {
  const {
    watch
  } = useCreateShipmentFormContext();
  const selectedProducts = useSelector(getSelectedProductsCaseDimensions);
  const selectedProductCasePacks = useSelector(getSelectedProductCasePacks);
  const unitSystem = watch(CreateShipmentInputName.UNIT_SYSTEM);

  // case dims and weights are in inches and pounds
  // calc cubic feet and convert to metric if needed
  const totals = useMemo(() => {
    const shouldExcludeProduct = product => !selectedProductCasePacks[product.dsku] || product.arePacksLoading;
    return selectedProducts.reduce((acc, product) => shouldExcludeProduct(product) ? acc : {
      totalVolume: acc.totalVolume + calcCasePackTotalVolume(product, unitSystem),
      totalWeight: acc.totalWeight + calcCasePackTotalWeight(product, unitSystem),
      totalUnits: acc.totalUnits + (product.qty ?? 0),
      totalBoxes: acc.totalBoxes + product.numberOfCases
    }, {
      totalVolume: 0,
      totalWeight: 0,
      totalUnits: 0,
      totalBoxes: 0
    });
  }, [selectedProducts, selectedProductCasePacks, unitSystem]);
  const dtcPackageTotal = useMemo(() => {
    return packages?.map(pkg => {
      return {
        totalVolume: calcPackageTotalVolume(pkg, unitSystem),
        totalWeight: calcPackageTotalWeight(pkg, unitSystem),
        totalUnits: (sum(pkg?.items?.map(item => item.qty)) ?? 0) * (pkg?.numberOfPkgs ?? 0),
        totalBoxes: pkg?.numberOfPkgs
      };
    }, {
      totalVolume: 0,
      totalWeight: 0,
      totalUnits: 0,
      totalBoxes: 0
    }) ?? [];
  }, [JSON.stringify(packages), unitSystem]);
  const dtcSingleSkuTotals = {
    totalVolume: sum(dtcPackageTotal.map(total => total.totalVolume)),
    totalWeight: sum(dtcPackageTotal.map(total => total.totalWeight)),
    totalUnits: sum(dtcPackageTotal.map(total => total.totalUnits)),
    totalBoxes: sum(dtcPackageTotal.map(total => total.totalBoxes))
  };
  return {
    totals: isDtcNetworkBooking ? dtcSingleSkuTotals : totals,
    totalSkus: selectedProducts.length,
    hasSelectedProducts: selectedProducts.length > 0
  };
};