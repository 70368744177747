import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import log from "Logger";
export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    locationKey: undefined
  };
  componentDidUpdate() {
    if (this.state.hasError && this.state.locationKey !== window.location.pathname) {
      this.setState({
        hasError: false,
        locationKey: undefined
      });
    }
  }
  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      locationKey: window.location.pathname
    });
    datadogRum.addError(error, {
      scope: "error-boundary",
      domain: window.location.pathname.split("/")[1]
    });
    log.error({
      fn: "ErrorBoundary.componentDidCatch",
      err: error,
      info
    }, "Uncaught error in component");
  }
  render() {
    return this.state.hasError ? this.props.errorDisplay : this.props.children;
  }
}