import { F as toFalse, T as toTrue, mapValues, uniq } from "lodash/fp";
import { createReducer } from "common/ReduxUtils";
import { ProductComplianceActionTypes } from "inbounds/steps/compliance/ProductComplianceActions";
import { LOAD_INBOUND } from "inbounds/steps/InboundLoadActions";
import produce from "immer";
export const requirementText = {
  requirements: "Is packaged following ",
  dims: "Weighs less than 50 lbs. The longest side is less than 40”, median side is less than 30”, and shortest side is less than 15”.",
  hazmat: "Is not classified as a 'dangerous good' by the ",
  illegal: "Does not violate any other ",
  noAdditional: 'Is prepared ready-to-ship with no additional boxing if the SKU weighs more than 20 lbs or does not fit in an 18"x14"x8" box.'
};
export const batteryRequirementText = {
  msds: "Does not have, or require a Material Safety Data Sheet (MSDS) except for small batteries. "
};
export const productComplianceInitialState = {
  requirementText,
  hasAllConditionsMet: false,
  compliantWithRequirements: mapValues(toFalse, requirementText),
  completedShippingPlanIds: [],
  isBatteriesEnabled: false
};
function hasAllConditionsMet(state) {
  return {
    ...state,
    hasAllConditionsMet: Object.values(state.compliantWithRequirements).every(x => Boolean(x))
  };
}
const reducers = {
  [ProductComplianceActionTypes.TOGGLE_COMPLIANCE_CRITERIA]: (state, {
    criteriaName
  }) => hasAllConditionsMet({
    ...state,
    compliantWithRequirements: {
      ...state.compliantWithRequirements,
      [criteriaName]: !state.compliantWithRequirements[criteriaName]
    }
  }),
  [ProductComplianceActionTypes.ACCEPT_ALL_COMPLIANCE_CRITERIA]: (state, {
    criteriaName
  }) => hasAllConditionsMet({
    ...state,
    compliantWithRequirements: mapValues(toTrue, state.compliantWithRequirements)
  }),
  [ProductComplianceActionTypes.COMPLETE_COMPLIANCE]: (state, {
    shippingPlanId
  }) => ({
    ...state,
    completedShippingPlanIds: [...state.completedShippingPlanIds, shippingPlanId]
  }),
  [LOAD_INBOUND]: (state, {
    completedShippingPlanIds = []
  }) => ({
    ...state,
    completedShippingPlanIds: uniq([...state.completedShippingPlanIds, ...completedShippingPlanIds])
  }),
  [ProductComplianceActionTypes.CLEAR_PRODUCT_COMPLIANCE]: state => ({
    ...productComplianceInitialState,
    completedShippingPlanIds: state.completedShippingPlanIds
  }),
  [ProductComplianceActionTypes.BATTERY_COMPLIANCE]: state => produce(state, draft => {
    const newRequirementText = {
      ...requirementText,
      ...batteryRequirementText
    };
    draft.requirementText = newRequirementText;
    draft.compliantWithRequirements = mapValues(toFalse, newRequirementText);
    draft.isBatteriesEnabled = true;
  })
};
export const productComplianceReducer = createReducer(productComplianceInitialState, reducers);