export let ProblemType;
(function (ProblemType) {
  ProblemType["UNKNOWN_BARCODE"] = "UNKNOWN_BARCODE";
  ProblemType["MISSING_BARCODE"] = "MISSING_BARCODE";
  ProblemType["UNEXPECTED_BARCODE"] = "UNEXPECTED_BARCODE";
  ProblemType["DAMAGED_PRODUCT"] = "DAMAGED_PRODUCT";
  ProblemType["RELABELED_BOX"] = "RELABELED_BOX";
  ProblemType["SHORT_SHIP_BOXES"] = "SHORT_SHIP_BOXES";
  ProblemType["SHORT_SHIP_UNITS"] = "SHORT_SHIP_UNITS";
  ProblemType["UNEXPECTED_SKU"] = "UNEXPECTED_SKU";
  ProblemType["FLEXPORT_SHIP"] = "FLEXPORT_SHIP";
  ProblemType["MISMATCH_CASE_QTY"] = "MISMATCH_CASE_QTY";
  ProblemType["MISSING_CONTAINER_LABEL"] = "MISSING_CONTAINER_LABEL";
  ProblemType["ILLEGIBLE_LOT"] = "ILLEGIBLE_LOT";
  ProblemType["ILLEGIBLE_EXPIRATION"] = "ILLEGIBLE_EXPIRATION";
  ProblemType["EXPIRED_PRODUCT"] = "EXPIRED_PRODUCT";
  ProblemType["OTHER_NON_COMPLIANCE"] = "OTHER_NON_COMPLIANCE";
  ProblemType["OVER_RECEIVED_UNITS"] = "OVER_RECEIVED_UNITS";
})(ProblemType || (ProblemType = {}));