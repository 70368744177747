export const shipmentListItemResponseToWholesaleListItem = record => {
  const firstShipment = record.shipments?.[Object.keys(record.shipments)[0]];
  return {
    id: record.id,
    shipmentId: record.id,
    externalDestinationId: record.externalId,
    origin: {
      id: record.originWarehouseId ?? firstShipment?.originWarehouseId ?? "",
      address: record.originWarehouseAddress ?? firstShipment?.originWarehouseAddress
    },
    shipments: Object.values(record.shipments ?? {}).map(shipment => ({
      status: shipment.status,
      shipBy: shipment.shipBy
    })),
    destination: record.inputDestination ?? undefined,
    status: record.status,
    expectedUnits: record.numberOfItems ?? 0,
    createdTime: new Date(record.createdAt),
    updatedTime: new Date(record.updatedAt),
    orderType: record.orderType,
    items: record.orderItems ?? [],
    numberOfBoxes: record.numberOfBoxes ?? 0,
    numberOfPallets: record.numberOfPallets ?? 0,
    numberOfSkus: record.numberOfSkus ?? 0,
    name: record.name,
    errors: record.errors,
    channel: record.channel,
    invalidOrderItems: record.invalidOrderItems ?? [],
    nonComplianceStatus: record.nonComplianceStatus,
    nonComplianceTypeAndStatus: record.nonComplianceTypeAndStatus
  };
};