export let Appearance;
(function (Appearance) {
  Appearance["Default"] = "Default";
  Appearance["Success"] = "Success";
  Appearance["Danger"] = "Danger";
  Appearance["Warning"] = "Warning";
  Appearance["Admin"] = "Admin";
})(Appearance || (Appearance = {}));
export let Size;
(function (Size) {
  Size["sm"] = "sm";
  Size["md"] = "md";
  Size["lg"] = "lg";
})(Size || (Size = {}));
export let Containers;
(function (Containers) {
  Containers["div"] = "div";
  Containers["section"] = "section";
  Containers["article"] = "article";
})(Containers || (Containers = {}));