export const sidebarTheme = {
  transitionDuration: "0.25s",
  sectionMargin: "0.5rem",
  modalOverlay: {
    opacity: "0.4"
  },
  menu: {
    width: "15rem",
    padding: "0 0.75rem 1rem 0.75rem",
    background: "#052439",
    // midnight blue from Flexport design
    hoverOpen: {
      margin: "1.25rem 0",
      borderRadius: "0 10px 10px 0"
    }
  },
  openTarget: {
    height: "4.5rem",
    width: "4.5rem"
  },
  toggleButton: {
    height: "2.5rem",
    width: "2.5rem"
  },
  logo: {
    height: "1.25rem"
  },
  item: {
    borderRadius: "6px",
    hoverBackgroundColor: "rgba(255, 255, 255, 0.1)",
    iconSize: "1.25rem",
    height: {
      MOBILE: "2.5rem",
      DESKTOP: "2rem"
    },
    margin: {
      MOBILE: "0.5rem 0",
      DESKTOP: "0.125rem 0"
    }
  }
};