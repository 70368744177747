import { defineMessages } from "react-intl";
export const replenishmentShipmentCreateLabels = {
  shipmentDetails: defineMessages({
    multipleLocation: {
      id: "replenishment.shipmentDetails.multipleLocation",
      defaultMessage: "Multiple Locations"
    },
    viewDetails: {
      id: "replenishment.shipmentDetails.viewDetails",
      defaultMessage: "View Details"
    }
  }),
  steps: {
    destination: {
      ...defineMessages({
        title: {
          id: "replenishment.create.steps.destination.title",
          defaultMessage: "Select a destination"
        },
        subtitle: {
          id: "replenishment.create.steps.destination.subtitle",
          defaultMessage: "Select one."
        }
      }),
      options: {
        ecom: defineMessages({
          label: {
            id: "replenishment.create.steps.destination.option.ecom.label",
            defaultMessage: "Flexport Ecom Fulfillment Network"
          },
          description: {
            id: "replenishment.create.steps.destination.option.ecom.description",
            defaultMessage: "Make inventory sellable through your sales channels"
          }
        }),
        fbaIntegration: defineMessages({
          label: {
            id: "replenishment.create.steps.destination.option.fbaIntegration.label",
            defaultMessage: "Amazon FBA"
          },
          description: {
            id: "replenishment.create.steps.destination.option.fbaIntegration.description",
            defaultMessage: "Send inventory to Amazon’s Prime-ready fulfillment centers"
          },
          comment: {
            id: "replenishment.create.steps.destination.option.fbaIntegration.comment",
            defaultMessage: "Note: Amazon Freight is not currently supported from the integration. To use Amazon Freight, <manualFbaStepLink>create a transfer manually instead</manualFbaStepLink>."
          },
          notRecommended: {
            id: "replenishment.create.steps.destination.option.fbaIntegration.notRecommended",
            defaultMessage: "<manualFbaStepLink>Create an Amazon FBA transfer manually instead (not recommended)</manualFbaStepLink>."
          }
        }),
        wfs: defineMessages({
          label: {
            id: "replenishment.create.steps.destination.option.wfs.label",
            defaultMessage: "Walmart WFS"
          },
          description: {
            id: "replenishment.create.steps.destination.option.wfs.description",
            defaultMessage: "Fulfillment through Walmart's Fulfillment Services (WFS)."
          }
        })
      }
    },
    facility: defineMessages({
      title: {
        id: "replenishment.create.steps.facility.title",
        defaultMessage: "Select Amazon Facility"
      }
    }),
    product: defineMessages({
      title: {
        id: "replenishment.create.steps.product.title",
        defaultMessage: "Select products"
      },
      subtitle: {
        id: "replenishment.create.steps.product.subtitle",
        defaultMessage: "Select products with available inventory in Flexport Storage."
      },
      subtitleLearnMore: {
        id: "replenishment.create.steps.product.subtitleLearnMore",
        defaultMessage: "{learnMore} about Amazon's prep requirements."
      },
      errorNotificationTitle: {
        id: "replenishment.create.steps.product.errorNotification",
        defaultMessage: "All products are not available at the same location"
      },
      errorNotificationDescription: {
        id: "replenishment.create.steps.product.errorNotificationDescription",
        defaultMessage: "To create replenishment for products available at different locations, please create separate replenishment."
      }
    }),
    origin: defineMessages({
      title: {
        id: "replenishment.create.steps.origin.title",
        defaultMessage: "Select origin"
      },
      subtitle: {
        id: "replenishment.create.steps.origin.subtitle",
        defaultMessage: "Select a Reserve Storage location with available inventory for your selected products."
      },
      options: {
        productAvailable: {
          id: "replenishment.create.steps.origin.option.available",
          defaultMessage: "{qty} units available for selected products"
        },
        productUnavailable: {
          id: "replenishment.create.steps.origin.option.available",
          defaultMessage: "All selected products are not available at this location"
        }
      }
    }),
    productQuantity: defineMessages({
      title: {
        id: "replenishment.create.steps.productQuantity.title",
        defaultMessage: "Select cases"
      },
      subtitle: {
        id: "replenishment.create.steps.productQuantity.subtitle",
        defaultMessage: "Select from available inventory in Reserve Storage in {storageWarehouseLocation}"
      },
      subtitleWithAddress: {
        id: "replenishment.create.steps.productQuantity.subtitleWithAddress",
        defaultMessage: "The selected products will ship from:{br}{fullAddress}"
      }
    }),
    shipping: {
      ...defineMessages({
        title: {
          id: "replenishment.create.steps.shipping.title",
          defaultMessage: "Select a shipping method"
        },
        shipTo: {
          id: "replenishment.create.steps.shipping.shipTo",
          defaultMessage: "Ship to:"
        },
        subtitle: {
          id: "replenishment.create.steps.shipping.subtitle",
          defaultMessage: "Select one."
        },
        selectCarrier: {
          id: "transfersShipmentCreateLabels.steps.shipping.selectCarrier",
          defaultMessage: "Select carrier"
        },
        selectCargoType: {
          id: "transfersShipmentCreateLabels.steps.shipping.selectCargoType",
          defaultMessage: "Select cargo type"
        },
        selectShippingMethod: {
          id: "replenishment.create.steps.shipping.selectShippingMethod",
          defaultMessage: "Select shipping method"
        },
        amazonEstimationError: {
          id: "replenishment.create.steps.shipping.amazonEstimationError",
          defaultMessage: "A quote could not be generated. Please try creating a transfer to FBA manually."
        },
        deliverrEstimationError: {
          id: "replenishment.create.steps.shipping.deliverrEstimationError",
          defaultMessage: "Our support team will contact you with a final quote once you confirm your transfer."
        },
        referenceIdNotificationTitle: {
          id: "replenishment.create.steps.shipping.referenceIdNotificationTitle",
          defaultMessage: "Important"
        },
        referenceIdNotification: {
          id: "replenishment.create.steps.shipping.referenceIdNotification",
          defaultMessage: "After confirming the transfer, you will need to go to your Seller Central account and share the shipment’s “Amazon reference ID” before Flexport can process the shipment."
        },
        learnMore: {
          id: "replenishment.create.steps.shipping.learnMore",
          defaultMessage: "Learn more"
        },
        estimationInProgress: {
          id: "replenishment.create.steps.shipping.estimationInProgress",
          defaultMessage: "Estimation in progress..."
        },
        palletNoEstimate: {
          id: "replenishment.create.steps.shipping.palletNoEstimate",
          defaultMessage: "Our support team will contact you with a final quote once you confirm the order."
        },
        parcelNotAvailable: {
          id: "replenishment.create.steps.shipping.parcelNotAvailable",
          defaultMessage: "Small parcel option is not available for your selected dims and weights."
        },
        parcelSizeEstimationError: {
          id: "replenishment.create.steps.shipping.cargoOptions.parcel.sizeEstimationError",
          defaultMessage: "Some selected products do not meet Amazon's requirements for small parcel. {learnMore}"
        }
      }),
      options: {
        carrier: defineMessages({
          label: {
            id: "replenishment.create.steps.shipping.options.carrier.label",
            defaultMessage: "{shippingType} with {shippingPartner} partner"
          }
        }),
        deliverr: defineMessages({
          label: {
            id: "replenishment.create.steps.shipping.options.deliverr.label",
            defaultMessage: "Flexport partner"
          },
          description: {
            id: "replenishment.create.steps.shipping.options.deliverr.description",
            defaultMessage: "We'll handle everything for you. {learnMore}{br}{amount} estimated total"
          },
          descriptionLoading: {
            id: "replenishment.create.steps.shipping.options.deliverr.descriptionLoading",
            defaultMessage: "We'll handle everything for you. {learnMore}{br}{amount} estimation in progress..."
          },
          descriptionWithoutEstimate: {
            id: "replenishment.create.steps.shipping.options.deliverr.descriptionWithoutEstimate",
            defaultMessage: "We'll handle everything for you. {learnMore}"
          },
          fbaIntegratedDescription: {
            id: "replenishment.create.steps.shipping.options.deliverr.fbaIntegratedDescription",
            defaultMessage: "Hassle free, transparent transportation"
          }
        }),
        yourCarrier: defineMessages({
          label: {
            id: "replenishment.create.steps.shipping.options.yourCarrier.label",
            defaultMessage: "Your carrier"
          },
          description: {
            id: "replenishment.create.steps.shipping.options.yourCarrier.description",
            defaultMessage: "Use your own Amazon-approved carrier."
          }
        }),
        amazon: defineMessages({
          label: {
            id: "replenishment.create.steps.shipping.options.amazon.label",
            defaultMessage: "Amazon partner"
          },
          description: {
            id: "replenishment.create.steps.shipping.options.amazon.description",
            defaultMessage: "Use Amazon's transportation"
          }
        }),
        fbaWithOptions: defineMessages({
          deliverr: {
            id: "replenishment.create.steps.shipping.options.fbaWithOptions.deliverr",
            defaultMessage: "Flexport"
          },
          amazon: {
            id: "replenishment.create.steps.shipping.options.fbaWithOptions.amazon",
            defaultMessage: "Amazon"
          }
        })
      },
      cargoOptions: defineMessages({
        generatingQuote: {
          id: "replenishment.create.steps.shipping.cargoOptions.generatingQuote",
          defaultMessage: "Generating your quote..."
        },
        errorGeneratingEstimation: {
          id: "replenishment.create.steps.shipping.estimationError",
          defaultMessage: "A estimation could not be generated."
        },
        pallet: {
          label: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.label",
            defaultMessage: "Palletized"
          },
          description: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.description",
            defaultMessage: "LTL/FTL"
          },
          fbaAutoLabel: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.fbaAutoLabel",
            defaultMessage: "LTL/FTL (Palletized)"
          },
          comingSoon: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.comingSoon",
            defaultMessage: "Coming soon"
          },
          descriptionXEstimatedTotal: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.descriptionXEstimatedTotal",
            defaultMessage: "{estimatedTotal} estimated total"
          },
          amazonFreightEstimatedDescriptionMessage: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.amazonFreightEstimatedDescriptionMessage",
            defaultMessage: "You'll be able to see an estimated cost in Seller Central after your shipment is prepared."
          },
          amazonFreightEstimatedCaptionMessage: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.amazonFreightEstimatedCaptionMessage",
            defaultMessage: "You will be billed by Amazon for shipping. Your final invoice may change due to additional carrier pickup fees or unexpected cargo quantities, weights, and dimensions."
          },
          invalidNumberOfBoxesForAmazon: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.invalidNumberOfBoxesForAmazon",
            defaultMessage: "The maximum number of boxes allowed by Amazon for Freight Shipment is {numOfBoxes}"
          },
          internationaShippingNotSupported: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.internationaShippingNotSupported",
            defaultMessage: "International Destination not supported"
          },
          invalidEstimatedPalletCountForAmazon: {
            id: "replenishment.create.steps.shipping.cargoOptions.pallet.invalidEstimatedPalletCountForAmazon",
            defaultMessage: "The maximum number of pallets allowed by Amazon for Freight Shipment is {numOfPallets}"
          }
        },
        parcel: {
          label: {
            id: "replenishment.create.steps.shipping.cargoOptions.parcel.label",
            defaultMessage: "Small Parcel"
          },
          description: {
            id: "replenishment.create.steps.shipping.cargoOptions.parcel.description",
            defaultMessage: "Individual boxes"
          },
          fbaAutoLabel: {
            id: "replenishment.create.steps.shipping.cargoOptions.parcel.fbaAutoLabel",
            defaultMessage: "Small parcel (Individual boxes)"
          },
          descriptionXEstimatedTotal: {
            id: "replenishment.create.steps.shipping.cargoOptions.parcel.descriptionXEstimatedTotal",
            defaultMessage: "{estimatedTotal} estimated total"
          },
          invalidNumberOfBoxesForAmazon: {
            id: "replenishment.create.steps.shipping.cargoOptions.parcel.invalidNumberOfBoxesForAmazon",
            defaultMessage: "The maximum number of boxes allowed by Amazon for Parcel Shipment is {numOfBoxes}"
          },
          internationaShippingNotSupported: {
            id: "replenishment.create.steps.shipping.cargoOptions.parcel.internationaShippingNotSupported",
            defaultMessage: "International Destination not supported"
          }
        },
        estimatedShippingCost: {
          id: "replenishment.create.steps.shipping.cargoOptions.estimatedShippingCost",
          defaultMessage: "{formattedAmount} estimated shipping cost"
        }
      }),
      contactInfo: defineMessages({
        title: {
          id: "replenishment.create.steps.shipping.contactInfo.title",
          defaultMessage: "Confirm your contact information"
        },
        subtitle: {
          id: "replenishment.create.steps.shipping.contactInfo.subtitle",
          defaultMessage: "We'll contact you when the shipment is ready for pickup."
        },
        email: {
          id: "replenishment.create.steps.shipping.contactInfo.email",
          defaultMessage: "Email"
        },
        phoneNumber: {
          id: "replenishment.create.steps.shipping.contactInfo.phoneNumber",
          defaultMessage: "Phone number"
        }
      })
    },
    shipmentDetails: defineMessages({
      title: {
        id: "replenishment.create.steps.shipmentDetails.title",
        defaultMessage: "Add your shipment details"
      },
      subtitle: {
        id: "replenishment.create.steps.shipmentDetails.subtitle",
        defaultMessage: "Add the details from the shipment you created in Seller Central."
      },
      shipmentId: {
        label: {
          id: "replenishment.create.steps.shipmentDetails.shipmentId.label",
          defaultMessage: "FBA Shipment ID"
        },
        placeholder: {
          id: "replenishment.create.steps.shipmentDetails.shipmentId.placeholder",
          defaultMessage: "FBA0000000000"
        },
        validateAttribute: {
          id: "replenishment.create.steps.shipmentDetails.shipmentId.validateAttribute",
          defaultMessage: "Shipment ID should start with either 'FBA' followed by exactly 9 characters or 'STAR' followed by exactly 13 characters"
        }
      },
      referenceId: {
        label: {
          id: "replenishment.create.steps.shipmentDetails.referenceId.label",
          defaultMessage: "FBA Reference ID"
        },
        validateAttribute: {
          id: "replenishment.create.steps.shipmentDetails.referenceId.validateAttribute",
          defaultMessage: "FBA Reference ID should include exactly 8 characters of only numbers and letters"
        },
        placeholder: {
          id: "replenishment.create.steps.shipmentDetails.referenceId.placeholder",
          defaultMessage: "0000000000"
        }
      },
      boxFile: {
        label: {
          id: "replenishment.create.steps.shipmentDetails.boxFile.label",
          defaultMessage: "Box label PDF"
        },
        requiredHint: {
          id: "replenishment.create.steps.shipmentDetails.boxFile.requiredHint",
          defaultMessage: "Box label PDF is required"
        }
      },
      palletFile: {
        label: {
          id: "replenishment.create.steps.shipmentDetails.palletFile.label",
          defaultMessage: "Pallet label PDF"
        }
      },
      labelFormat: {
        id: "replenishment.create.steps.shipmentDetails.labelsFormat",
        defaultMessage: "Upload labels in 4x6 format only"
      },
      amazonAddThisLater: {
        id: "replenishment.create.steps.shipmentDetails.amazonAddThisLater",
        defaultMessage: "If you are using Amazon's rates, you can add this later."
      }
    }),
    confirm: defineMessages({
      title: {
        id: "replenishment.create.steps.confirm.title",
        defaultMessage: "Confirm your transfer"
      },
      nextButtonCaption: {
        id: "replenishment.create.steps.confirm.nextButtonCaption",
        defaultMessage: "Confirm"
      },
      nonCDConfirmationText: {
        id: "replenishment.create.steps.confirm.nonCDConfirmationText",
        defaultMessage: "I understand that I must arrange my own transportation for this transfer by emailing {freightEmail}."
      },
      pricingSummary: {
        id: "replenishment.create.steps.confirm.pricingSummary",
        defaultMessage: "Pricing summary"
      },
      boxLabelling: {
        id: "replenishment.create.steps.confirm.boxLabelling",
        defaultMessage: "Box labelling"
      },
      boxLabellingFeeTooltip: {
        id: "replenishment.create.steps.confirm.boxLabellingFeeTooltip",
        defaultMessage: "This includes the cost of labels and labor"
      },
      forwarding: {
        id: "replenishment.create.steps.confirm.forwarding",
        defaultMessage: "Forwarding"
      },
      forwardingFeeTooltip: {
        id: "replenishment.create.steps.confirm.forwardingFeeTooltip",
        defaultMessage: "This includes processing and handling the shipment"
      },
      freight: {
        id: "replenishment.create.steps.confirm.freight",
        defaultMessage: "Flexport transportation"
      },
      tbd: {
        id: "replenishment.create.steps.confirm.tbd",
        defaultMessage: "TBD"
      },
      noEstimationTotalDesc: {
        id: "replenishment.create.steps.confirm.noEstimationTotalDesc",
        defaultMessage: "Our support team will contact you shortly with a final quote.{br}You will not be charged until your order is shipped"
      },
      totalRowLabel: {
        id: "replenishment.create.steps.confirm.totalRowLabel",
        defaultMessage: "Estimated total"
      },
      fromDescription: {
        id: "replenishment.create.steps.confirm.ecom.fromDescription",
        defaultMessage: "Flexport Reserve Storage"
      },
      toDescription: {
        id: "replenishment.create.steps.confirm.ecom.toDescription",
        defaultMessage: "Flexport Ecom"
      },
      fromWarehouseDescription: {
        id: "replenishment.create.steps.confirm.fba.fromDescription",
        defaultMessage: "Reserve Storage"
      },
      aggregationDescription: {
        id: "replenishment.create.steps.confirm.fba.aggregationDescription",
        defaultMessage: "This includes all shipments in this transfer."
      },
      aggregationDescriptionOneShipment: {
        id: "replenishment.create.steps.confirm.fba.aggregationDescription",
        defaultMessage: "This includes current shipment only."
      },
      eachPickTransportationText: {
        id: "replenishment.create.steps.confirm.eachPick.transportationText",
        defaultMessage: "*Transportation Fee will be shown after order is Shipped"
      }
    }),
    confirmed: defineMessages({
      title: {
        id: "replenishment.create.steps.confirmed.title",
        defaultMessage: "Transfer Confirmed"
      },
      subtitle: {
        id: "replenishment.create.steps.confirmed.subtitle",
        defaultMessage: "You're all set. We'll keep you updated on the status of your transfer."
      }
    }),
    fbaIntegration: {
      oauth: defineMessages({
        title: {
          id: "replenishment.create.steps.fbaIntegration.oauth.title",
          defaultMessage: "Connect Amazon"
        },
        subtitle1: {
          id: "replenishment.create.steps.fbaIntegration.oauth.subtitle1",
          defaultMessage: "Flexport will create inbound shipments to FBA in Seller Central on your behalf. {learnMore}{br}"
        },
        subtitle2: {
          id: "replenishment.create.steps.fbaIntegration.oauth.subtitle2",
          defaultMessage: "You will be redirected to Amazon to grant permissions."
        },
        amazonFreightWarningTitle: {
          id: "replenishment.create.steps.fbaIntegration.oauth.amazonFreightWarningTitle",
          defaultMessage: "Note: Amazon Freight is not currently supported from the integration."
        },
        amazonFreightWarningSubTitle: {
          id: "replenishment.create.steps.fbaIntegration.oauth.amazonFreightWarningSubTitle",
          defaultMessage: "To use Amazon Freight, <manualFbaStepLink>create a transfer manually instead</manualFbaStepLink>."
        },
        catalogWarningTitle: {
          id: "replenishment.create.steps.fbaIntegration.oauth.amazonFreightWarningTitle",
          defaultMessage: "You will be redirected to Amazon to grant permissions. After connecting to Amazon, visit Integrations > Amazon in Seller Portal to enable syncing your FBA catalog"
        }
      }),
      skip: {
        id: "replenishment.create.steps.fbaIntegration.skip",
        defaultMessage: "Skip"
      },
      connect: {
        id: "replenishment.create.steps.fbaIntegration.connect",
        defaultMessage: "Connect"
      },
      oauthComplete: defineMessages({
        titleSyncComplete: {
          id: "replenishment.create.steps.fbaIntegration.oauthComplete.titleSyncComplete",
          defaultMessage: "You're connected to Amazon"
        },
        titleSyncIncomplete: {
          id: "replenishment.create.steps.fbaIntegration.oauthComplete.titleSyncIncomplete",
          defaultMessage: "You're almost ready to send to Amazon"
        },
        subtitle: {
          id: "replenishment.create.steps.fbaIntegration.oauthComplete.subtitle",
          defaultMessage: "Flexport will create inbound shipments to FBA in Seller Central on your behalf.{br}{learnMore}"
        },
        catalogWarning: {
          id: "replenishment.create.steps.fbaIntegration.oauthComplete.catalogWarning",
          defaultMessage: "To allow Flexport to add your products to an inbound shipment on your behalf in Amazon, visit <a> Integrations > Amazon </a> and sync your FBA catalog."
        }
      }),
      waitForShipmentCreation: defineMessages({
        title: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentCreation.title",
          defaultMessage: "Generating your shipping plan in Amazon"
        },
        subtitle: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentCreation.subtitle",
          defaultMessage: "It may take up to one minute"
        }
      }),
      shipmentCreationErrors: defineMessages({
        title: {
          id: "replenishment.create.steps.fbaIntegration.shipmentCreationError.title",
          defaultMessage: "Shipment Generation Error"
        },
        subtitle: {
          id: "replenishment.create.steps.fbaIntegration.shipmentCreationError.subtitle",
          defaultMessage: "A shipment could not be generated by Amazon because {errorMessage}"
        }
      }),
      waitForShipmentConfirmation: defineMessages({
        title: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentConfirmation.title",
          defaultMessage: "Transfer confirmed. Syncing with Amazon"
        },
        subtitle: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentConfirmation.subtitle",
          defaultMessage: "This could take up to two minutes"
        },
        warningTitle: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentConfirmation.warningTitle",
          defaultMessage: "Important"
        },
        warningSubtitleParcel: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentConfirmation.warningSubtitleParcel",
          defaultMessage: "Do not try to view the shipment on Seller Central for at least two minutes or until the sync with Amazon is completed."
        },
        warningSubtitle1Freight: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentConfirmation.warningSubtitle1Freight",
          defaultMessage: "Before Flexport can prepare the shipment, you must provide the Amazon reference ID for the shipment. This can be found on the shipment details page in your Seller Central dashboard."
        },
        warningSubtitle2Freight: {
          id: "replenishment.create.steps.fbaIntegration.waitForShipmentConfirmation.warningSubtitle2Freight",
          defaultMessage: "Do not try to view the shipment on Seller Central until the sync with Amazon is completed. You can leave this page and come back later to check on the status of the sync."
        }
      }),
      confirmed: defineMessages({
        title: {
          id: "replenishment.create.steps.fbaIntegration.confirmed.title",
          defaultMessage: "Transfer confirmed & synced with Amazon"
        },
        subtitleSingleShipment: {
          id: "replenishment.create.steps.fbaIntegration.confirmed.subtitleSingleShipment",
          defaultMessage: "Your FBA Shipment ID is {fbaShipmentId}."
        },
        subtitleMultiShipment: {
          id: "replenishment.create.steps.fbaIntegration.confirmed.subtitleMultiShipment",
          defaultMessage: "Your FBA Shipment IDs are {shipmentIdList}."
        },
        confirmButtonCaption: {
          id: "replenishment.create.steps.fbaIntegration.confirmed.confirmButtonCaption",
          defaultMessage: "Done"
        }
      })
    },
    wfsIntegration: {
      oauth: defineMessages({
        title: {
          id: "replenishment.create.steps.wfsIntegration.oauth.title",
          defaultMessage: "Connect Walmart"
        },
        subtitle1: {
          id: "replenishment.create.steps.wfsIntegration.oauth.subtitle1",
          defaultMessage: "Flexport will create inbound shipments to Walmart in Seller Center on your behalf. You will be redirected to Walmart to grant permissions. {learnMore}{br}"
        }
      }),
      skip: {
        id: "replenishment.create.steps.wfsIntegration.skip",
        defaultMessage: "Skip"
      },
      connect: {
        id: "replenishment.create.steps.wfsIntegration.connect",
        defaultMessage: "Connect"
      },
      next: {
        id: "replenishment.create.steps.wfsIntegration.next",
        defaultMessage: "Next"
      },
      oauthComplete: defineMessages({
        titleSyncComplete: {
          id: "replenishment.create.steps.wfsIntegration.oauthComplete.titleSyncComplete",
          defaultMessage: "You're connected to Walmart"
        },
        titleSyncIncomplete: {
          id: "replenishment.create.steps.wfsIntegration.oauthComplete.titleSyncIncomplete",
          defaultMessage: "You're almost ready to send to Walmart"
        },
        subtitle: {
          id: "replenishment.create.steps.wfsIntegration.oauthComplete.subtitle",
          defaultMessage: "Deliverr will create inbound shipments to WFS in Seller Central on your behalf.{br}{learnMore}"
        },
        catalogWarning: {
          id: "replenishment.create.steps.wfsIntegration.oauthComplete.catalogWarning",
          defaultMessage: "To allow Deliverr to add your products to an inbound shipment on your behalf in Walmart, visit <a> Integrations > Walmart </a> and sync your WFS catalog."
        }
      }),
      waitForShipmentCreation: defineMessages({
        title: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentCreation.title",
          defaultMessage: "Generating your shipping plan in Walmart"
        },
        subtitle: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentCreation.subtitle",
          defaultMessage: "It may take up to one minute"
        }
      }),
      shipmentCreationErrors: defineMessages({
        title: {
          id: "replenishment.create.steps.wfsIntegration.shipmentCreationError.title",
          defaultMessage: "Shipment Generation Error"
        },
        subtitle: {
          id: "replenishment.create.steps.wfsIntegration.shipmentCreationError.subtitle",
          defaultMessage: "A shipment could not be generated by Walmart because {errorMessage}"
        }
      }),
      waitForShipmentConfirmation: defineMessages({
        title: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentConfirmation.title",
          defaultMessage: "Transfer confirmed. Syncing with Walmart"
        },
        subtitle: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentConfirmation.subtitle",
          defaultMessage: "This could take up to two minutes"
        },
        warningTitle: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentConfirmation.warningTitle",
          defaultMessage: "Important"
        },
        warningSubtitleParcel: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentConfirmation.warningSubtitleParcel",
          defaultMessage: "Do not try to view the shipment on Seller Central for at least two minutes or until the sync with Walmart is completed."
        },
        warningSubtitle1Freight: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentConfirmation.warningSubtitle1Freight",
          defaultMessage: "Before Deliverr can prepare the shipment, you must provide the Walmart reference ID for the shipment. This can be found on the shipment details page in your Seller Central dashboard."
        },
        warningSubtitle2Freight: {
          id: "replenishment.create.steps.wfsIntegration.waitForShipmentConfirmation.warningSubtitle2Freight",
          defaultMessage: "Do not try to view the shipment on Seller Central until the sync with Walmart is completed. You can leave this page and come back later to check on the status of the sync."
        }
      }),
      confirmed: defineMessages({
        title: {
          id: "replenishment.create.steps.wfsIntegration.confirmed.title",
          defaultMessage: "Transfer confirmed & synced with Walmart"
        },
        subtitleSingleShipment: {
          id: "replenishment.create.steps.wfsIntegration.confirmed.subtitleSingleShipment",
          defaultMessage: "Your WFS Shipment ID is {wfsShipmentId}."
        },
        subtitleMultiShipment: {
          id: "replenishment.create.steps.wfsIntegration.confirmed.subtitleMultiShipment",
          defaultMessage: "Your WFS Shipment IDs are {shipmentIdList}."
        },
        confirmButtonCaption: {
          id: "replenishment.create.steps.wfsIntegration.confirmed.confirmButtonCaption",
          defaultMessage: "Done"
        }
      })
    }
  }
};