const dateFields = ["labelTime", "sortationArrivalTime", "facilityTime", "attemptedDeliveryTime", "deliveryTime", "estimatedDeliveryTime", "promisedDeliveryTime", "lastTrackingEventTime", "lastTrackingIngestionTime"];
export const updateParcelDataWithDateObjects = parcelData => {
  const updatedParcelData = {
    ...parcelData
  };
  dateFields.forEach(field => {
    if (updatedParcelData[field] && typeof updatedParcelData[field] === "string") {
      updatedParcelData[field] = new Date(updatedParcelData[field]);
    }
  });
  return updatedParcelData;
};