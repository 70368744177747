import { CREDIT_CARD_FEE_MESSAGES, CREDIT_CARD_FEE_PERCENT } from "inbounds/createShipment/steps/priceViewStep/chargesSummary/constants";
import { useMemo } from "react";
import { useIntl } from "react-intl";
export const usePricingSummaryLabels = (containerSize, freightType) => {
  const {
    formatMessage
  } = useIntl();
  return useMemo(() => {
    const tableTitle = formatMessage({
      id: "storage.inbounds.confirmShipment.pricingSummaryTable.tableTitle",
      defaultMessage: "Fees"
    });
    const inboundHandlingRowLabel = formatMessage({
      id: "storage.inbounds.confirmShipment.pricingSummaryTable.inboundHandlingRowLabel",
      defaultMessage: "Inbound pallet handling"
    });
    const palletStorageRowLabel = formatMessage({
      id: "storage.inbounds.confirmShipment.pricingSummaryTable.palletStorageRowLabel",
      defaultMessage: "First month of pallet storage"
    });
    const creditCardFeeRowLabel = formatMessage(CREDIT_CARD_FEE_MESSAGES.creditCardFee);
    const creditCardCostLabel = formatMessage({
      id: "storage.inbounds.confirmShipment.pricingSummaryTable.creditCardFeeLabel",
      defaultMessage: "{percent}%"
    }, {
      percent: CREDIT_CARD_FEE_PERCENT
    });
    const totalRowLabel = formatMessage({
      id: "storage.inbounds.confirmShipment.pricingSummaryTable.totalRowLabel",
      defaultMessage: "Estimated upon receiving"
    });
    const inboundHandlingFloorLoadRowLabel = containerSize ? formatMessage({
      id: "storage.inbounds.confirmShipment.pricingSummaryTable.inboundHandlingRowLabel",
      defaultMessage: "Inbound handling for {containerSize}' floor load"
    }, {
      containerSize
    }) : undefined;
    const deliverrFreightLabel = freightType ? formatMessage({
      id: "storage.inbounds.confirmShipment.pricingSummaryTable.deliverrFreightLabel",
      defaultMessage: "Flexport Freight ({freightType})"
    }, {
      freightType
    }) : undefined;
    return {
      tableTitle,
      inboundHandlingRowLabel,
      palletStorageRowLabel,
      creditCardFeeRowLabel,
      creditCardCostLabel,
      totalRowLabel,
      inboundHandlingFloorLoadRowLabel,
      deliverrFreightLabel
    };
  }, [formatMessage, containerSize]);
};