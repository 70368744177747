import { defineMessages } from "react-intl";
export const shipmentInstructionStepLabels = defineMessages({
  downloadPalletLabel: {
    id: "storage.inbounds.detail.shipment.instruction.downloadPalletLabel",
    defaultMessage: "Download pallet labels"
  },
  scheduleAppointment: {
    id: "storage.inbounds.detail.shipment.instruction.scheduleAppointment",
    defaultMessage: "Schedule an appointment"
  },
  advancedScheduleAppointment: {
    id: "storage.inbounds.detail.shipment.instruction.advancedScheduleAppointment",
    defaultMessage: "Request a delivery appointment"
  },
  sendShipment: {
    id: "storage.inbounds.detail.shipment.instruction.sendShipment",
    defaultMessage: "Send your shipment"
  },
  reviewPacking: {
    id: "storage.inbounds.detail.shipment.instruction.reviewPacking",
    defaultMessage: "Review packing instructions"
  },
  addContainerId: {
    id: "storage.inbounds.detail.shipment.instruction.addContainerId",
    defaultMessage: "Add your container ID"
  },
  prepareForPickup: {
    id: "storage.inbounds.detail.shipment.instruction.prepareForPickup",
    defaultMessage: "Prepare for pickup"
  },
  downloadBOL: {
    id: "storage.inbounds.detail.shipment.instruction.downloadBOL",
    defaultMessage: "Provide bill of lading to driver"
  },
  sendAndTrackShipment: {
    id: "storage.inbounds.detail.shipment.instruction.sendAndTrackShipment",
    defaultMessage: "Send and track your shipment"
  }
});