import { createActionCreator } from "common/ReduxUtils";
import { WholesaleCreateActionTypes } from "../store/WholesaleCreateActionTypes";
export const wholesaleCreateAddProduct = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_ADD_PRODUCT, "dsku", "product");
export const wholesaleCreateRemoveProduct = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_REMOVE_PRODUCT, "dsku");
export const wholesaleSelectDestinationAddress = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_SELECT_DESTINATION_ADDRESS, "destinationAddress");
export const wholesaleSetPoNumber = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_SET_PO_NUMBER, "poNumber");
export const wholesaleCreateReset = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_RESET);
export const wholesaleCreateUpdateProductNumberOfCases = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_UPDATE_NUMBER_OF_CASES, "dsku", "numberOfCases");
export const wholesaleCreateChooseOriginWarehouse = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_CHOOSE_ORIGIN_STORAGE_WAREHOUSE, "originStorageWarehouse");
export const wholesaleCreateChooseShippingConfig = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_CHOOSE_SHIPPING_CONFIG, "shippingConfig");
export const wholesaleCreateAddNotes = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_NOTES, "notes");
export const setAvailableInventoryInEachStorageWarehouse = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_SET_AVAILABLE_INVENTORY, "availableInventory");
export const wholesaleCreateSetWholesaleOrder = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_WHOLESALE_ORDER, "wholesaleOrder");
export const wholesaleCreateSetError = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_ERROR, "errorType");
export const wholesaleCreateStoreSteps = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_STORE_STEPS, "stack");
export const wholesaleCreateSetUploading = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_UPLOADING, "attachmentType", "value");
export const wholesaleCreateSetClearingLabel = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_UPLOADING, "attachmentType", "value");
export const wholesaleCreateSetLabelFilename = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_LABEL_FILENAME, "label", "file");
export const wholesaleCreateSetValidShipBy = createActionCreator(WholesaleCreateActionTypes.WHOLESALE_CREATE_SET_VALID_SHIP_BY, "validShipBy");