export const useAccordionStepValidator = ({
  steps,
  stepCompletionMap
}) => {
  return steps.reduce((validationMap, {
    stepType
  }, index) => {
    const isValid = stepCompletionMap[stepType];
    validationMap[stepType] = {
      index,
      isValid
    };
    return validationMap;
  }, {});
};