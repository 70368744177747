import { MAX_TOTAL_VOLUME_CBM, MAX_TOTAL_VOLUME_CU_FT, MAX_TOTAL_WEIGHT_KG, MAX_TOTAL_WEIGHT_LB } from "inbounds/constants/cargoConstantsIntl";
import { UnitSystem } from "inbounds/createShipment/CreateShipmentTypes";
import { CreateShipmentInputName } from "inbounds/createShipment/useCreateShipmentForm";
import { useCreateShipmentFormContext } from "inbounds/createShipment/useCreateShipmentFormContext";
import omit from "lodash/omit";
import { defineMessages, useIntl } from "react-intl";
const ERROR_MESSAGES = defineMessages({
  required: {
    defaultMessage: "Please enter all shipment totals.",
    id: "inbounds.createShipment.cargoDetails.missingTotals"
  }
});
export const useShipmentTotalsValidation = ({
  omitPallets
} = {}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    watch,
    formState
  } = useCreateShipmentFormContext();
  const unitSystem = watch(CreateShipmentInputName.UNIT_SYSTEM);
  const totalWeight = watch(CreateShipmentInputName.TOTAL_WEIGHT);
  const totalVolume = watch(CreateShipmentInputName.TOTAL_VOLUME);
  const isMetric = unitSystem === UnitSystem.CBM_KG;
  const maxVolume = isMetric ? MAX_TOTAL_VOLUME_CBM : MAX_TOTAL_VOLUME_CU_FT;
  const maxWeight = isMetric ? MAX_TOTAL_WEIGHT_KG : MAX_TOTAL_WEIGHT_LB;
  const isOverVolume = totalVolume > maxVolume;
  const isOverWeight = totalWeight > maxWeight;

  // we check the error states rather than the values to not prematurely validate minimums
  // under volume does not result in an error or warning, but will inform the type of service required
  const shipmentTotalsErrors = Object.values(omit(formState.errors?.shipmentTotals, omitPallets ? ["pallets"] : []) ?? {});
  const missingTotalsError = shipmentTotalsErrors.some(err => err.type === "required") ? formatMessage(ERROR_MESSAGES.required) : undefined;
  return {
    isOverVolume,
    isOverWeight,
    missingTotalsError
  };
};