import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
export let ReturnsInspectionConfigActionTypes;
(function (ReturnsInspectionConfigActionTypes) {
  ReturnsInspectionConfigActionTypes["FETCH_CONFIG_SUCCESS"] = "FETCH_CONFIG_SUCCESS";
  ReturnsInspectionConfigActionTypes["FETCH_CONFIG_FAILURE"] = "FETCH_CONFIG_FAILURE";
  ReturnsInspectionConfigActionTypes["RESET_CONFIG"] = "RESET_CONFIG";
})(ReturnsInspectionConfigActionTypes || (ReturnsInspectionConfigActionTypes = {}));
export const returnsInspectionConfigInitialState = {
  sop: undefined
};
const reducers = {
  ...handleSimpleReducerUpdates([ReturnsInspectionConfigActionTypes.FETCH_CONFIG_SUCCESS, ReturnsInspectionConfigActionTypes.FETCH_CONFIG_FAILURE]),
  [ReturnsInspectionConfigActionTypes.RESET_CONFIG]: () => ({
    ...returnsInspectionConfigInitialState
  })
};
export const returnsInspectionConfigReducer = createReducer(returnsInspectionConfigInitialState, reducers);