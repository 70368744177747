import { replenishmentClient } from "Clients";
import { keyBy } from "lodash";
import { replenishmentOrderDeatilsSetShipmentAttachmentDataMap } from "transfers/detail/store/replenishment/ReplenishmentOrderDetailsActions";
export const getAndSetReplenishmentOrderShipmentAttachmentState = async (shipments, dispatch) => {
  const shipmentAttachmentsList = await Promise.all((shipments ?? [])?.map(async shipment => {
    const shipmentAttachment = (await replenishmentClient.getDownloadUrlsForShipmentAttachments(shipment.shipmentId)).value;
    return {
      shipmentId: shipment.shipmentId,
      shipmentAttachment: keyBy(shipmentAttachment, "attachmentType")
    };
  }));
  const shipmentAttachmentDataMap = keyBy(shipmentAttachmentsList, "shipmentId");
  dispatch(replenishmentOrderDeatilsSetShipmentAttachmentDataMap(shipmentAttachmentDataMap));
};