import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { mapError } from "../core/mapError";
import { isAuthenticated } from "../core/authentication";
import { FacebookError } from "./FacebookError";
export class FacebookClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.FACEBOOK_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/`
    });
  }
  async syncSellerCreatedAdSets(sellerId) {
    try {
      return await this.apiClient.post({
        url: `/sync-seller-created-ad-sets/seller/${sellerId}`,
        body: undefined,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND]);
    }
  }
  async getDskuStateCoverage(dsku) {
    try {
      return await this.apiClient.get({
        url: `/availability/dsku/${dsku}/coverage/twoday`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async getStateCoverageByServiceLevel(dsku) {
    const coverage = await this.getDskuStateCoverage(dsku);
    return {
      2: coverage
    };
  }
  async refreshSeller(sellerId) {
    try {
      await this.apiClient.post({
        url: `refresh/seller/${sellerId}`,
        body: undefined,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND, FacebookError.REFRESH_ALREADY_IN_PROGRESS]);
    }
  }
  async activateSeller(sellerId) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}/activate`,
        body: undefined,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND]);
    }
  }
  async deactivateSeller(sellerId) {
    try {
      return await this.apiClient.delete({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.SELLER_NOT_FOUND]);
    }
  }
  async getAdAccounts(sellerId, userId, accessToken) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/user/${userId}/token/${accessToken}/ad-accounts`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }
  async getPixels(sellerId, adAccountId, accessToken) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/ad-account/${adAccountId}/token/${accessToken}/pixels`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }
  async getPages(sellerId, userId, accessToken) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/user/${userId}/token/${accessToken}/pages`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }
  async hasValidPermissions(sellerId, userId, accessToken) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/user/${userId}/token/${accessToken}/permissions`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }
  async getInstagramAccounts(sellerId, businessId, accessToken) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}/business/${businessId}/token/${accessToken}/instagram-accounts`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.INVALID_OAUTH_ACCESS_TOKEN]);
    }
  }
  async registerSeller(sellerId, sellerRegistrationData) {
    try {
      return await this.apiClient.post({
        url: `/seller/${sellerId}`,
        body: sellerRegistrationData,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [FacebookError.BUSINESS_USER_NOT_FOUND, FacebookError.FB_USER_IS_NOT_ADMIN, FacebookError.INVALID_OAUTH_ACCESS_TOKEN, FacebookError.MULTIPLE_SHOPIFY_PRODUCT_CATALOGS, FacebookError.SHOPIFY_PRODUCT_CATALOG_NOT_FOUND]);
    }
  }
}