export let ReplenishmentOrderDetailsActionTypes;
(function (ReplenishmentOrderDetailsActionTypes) {
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_SET_REPLENISHMENT_ORDER"] = "REPLENISHMENT_SET_REPLENISHMENT_ORDER";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_SET_SHIPMENT_HISTORY"] = "REPLENISHMENT_SET_SHIPMENT_HISTORY";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_SET_PRODUCTS"] = "REPLENISHMENT_SET_PRODUCTS";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_SET_SHIPMENT_ATTACHMENTS"] = "REPLENISHMENT_SET_SHIPMENT_ATTACHMENTS";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_SET_ORDER_COSTS"] = "REPLENISHMENT_SET_ORDER_COSTS";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_RESET_REPLENISHMENT_ORDER"] = "REPLENISHMENT_RESET_REPLENISHMENT_ORDER";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_SET_ORDER_NON_COMPLIANCES"] = "REPLENISHMENT_SET_ORDER_NON_COMPLIANCES";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_UPDATE_NON_COMPLIANCE"] = "REPLENISHMENT_UPDATE_NON_COMPLIANCE";
  ReplenishmentOrderDetailsActionTypes["REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT"] = "REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT";
})(ReplenishmentOrderDetailsActionTypes || (ReplenishmentOrderDetailsActionTypes = {}));