export let ReturnsDetailTypes;
(function (ReturnsDetailTypes) {
  ReturnsDetailTypes["RETURNS_GET_DETAILS"] = "RETURNS_GET_DETAILS";
  ReturnsDetailTypes["RETURNS_GET_DETAILS_SUCCESS"] = "RETURNS_GET_DETAILS_SUCCESS";
  ReturnsDetailTypes["RETURNS_GET_DETAILS_ERROR"] = "RETURNS_GET_DETAILS_ERROR";
  ReturnsDetailTypes["RESET_RETURN_DETAILS"] = "RESET_RETURN_DETAILS";
  ReturnsDetailTypes["RETURN_GET_ITEM_DETAILS_SUCCESS"] = "RETURN_GET_ITEM_DETAILS_SUCCESS";
  ReturnsDetailTypes["RETURN_GET_ITEM_DETAILS_ERROR"] = "RETURN_GET_ITEM_DETAILS_ERROR";
})(ReturnsDetailTypes || (ReturnsDetailTypes = {}));
export let QcStatus;
(function (QcStatus) {
  QcStatus["PASSED_INSPECTION"] = "PASSED_INSPECTION";
  QcStatus["SOME_UNITS_WILL_NOT_BE_RESTOCKED"] = "SOME_UNITS_WILL_NOT_BE_RESTOCKED";
  QcStatus["RESTOCKED"] = "RESTOCKED";
  QcStatus["NOT_RESTOCKABLE"] = "NOT_RESTOCKABLE";
  QcStatus["PARTIALLY_RESTOCKED"] = "PARTIALLY_RESTOCKED";
})(QcStatus || (QcStatus = {}));