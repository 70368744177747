import { useDispatch, useSelector } from "react-redux";
import { ReturnStatus } from "@deliverr/returns-client";
import { resetReturnCancel } from "../../cancel/store/ReturnsCancelAction";
import { useUnmount } from "react-use";
export const useReturnsCancelState = () => {
  const {
    returnsDetail
  } = useSelector(state => state.returnsDetail);
  const disableCancel = ReturnStatus.CREATED !== returnsDetail.status;
  const error = useSelector(state => state.returnsDetail.returnsCancel.error);
  const returnCancelSuccess = useSelector(state => state.returnsDetail.returnsCancel.returnCancelSuccess);
  const dispatch = useDispatch();
  useUnmount(() => {
    dispatch(resetReturnCancel());
  });
  return {
    error,
    returnCancelSuccess,
    disableCancel
  };
};