import styled from "@emotion/styled";
import HtmlToReact from "common/components/HtmlToReact";
export const SubText = styled.div(({
  theme
}) => `
    font-size: ${theme.font.size.F1};
    color: ${theme.colors.NEUTRAL[300]};
    line-height: ${theme.font.lineHeight.LH2};
`);
export const IconContainer = styled.div`
  margin-right: 10px;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
`;
export const PromoIcon = styled(HtmlToReact)`
  svg {
    height: 20px;
    width: 20px;
  }
`;
export const ShippingPlanNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const ShippingPlanNameContainer = styled.div(({
  theme
}) => `
  border-radius: ${theme.border.radius.R2};
  border: 1px solid transparent;
  position: relative;
  word-break: break-all;
  
  &.isArchived {
    color: ${theme.colors.ORANGE[400]};
  }
`);