import { any, isNil } from "lodash/fp";
export const verifyMultipleProductProps = dimensions => dimensions.reduce((acc, dimension) => {
  const {
    dsku,
    length,
    width,
    height,
    weight,
    hazmatIds,
    minimumPackagingType,
    qty = 1
  } = dimension;
  if (any(isNil)([length, width, height, weight])) {
    acc.push(undefined);
    return acc;
  }
  const item = {
    dsku,
    length,
    width,
    height,
    weight,
    hazmatIds,
    minimumPackagingType,
    qty
  };
  acc?.push(item);
  return acc;
}, []);