import { OrderError } from "common/clients/orders/DeliverrOrder/OrderError";
import { OrderStatus } from "common/clients/orders/DeliverrOrder/OrderStatus";
import { SortDir } from "common/models";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import produce from "immer";
import { OrderListActionTypes } from "./OrderListActionTypes";
export const orderListInitialState = {
  currentPageItems: [],
  currentSearchTerm: "",
  sort: {
    fieldName: "originalOrderCreationTimeUnix",
    direction: SortDir.DESC
  },
  filters: {
    status: "ALL_STATUS",
    serviceLevel: "ALL_SERVICE",
    date: "ALL_DATE",
    summary: "ALL_SUMMARY"
  },
  importErrors: {
    currentPageItems: []
  },
  intlOrders: {
    hits: []
  }
};
const STATUS_SUMMARY_INTERSECTION = [OrderStatus.CANCELLED, OrderError.BAD_ADDRESS, "LATE"];
export const reducers = {
  ...handleSimpleReducerUpdates([OrderListActionTypes.SET_CURRENT_SEARCH_TERM, OrderListActionTypes.SEARCH_ORDERS_SUCCESS, OrderListActionTypes.SEARCH_ORDERS_PREVIEW, OrderListActionTypes.SET_ORDERS_SORT, OrderListActionTypes.SEARCH_IMPORT_ERRORS_SUCCESS]),
  [OrderListActionTypes.SET_CURRENT_PAGE]: (state, {
    page
  }) => produce(state, draft => {
    if (draft.searchInfo) {
      draft.searchInfo.currentPage = page;
    }
  }),
  [OrderListActionTypes.SET_SUMMARY_FILTER]: (state, {
    summary
  }) => produce(state, draft => {
    draft.filters.summary = summary;
    if (STATUS_SUMMARY_INTERSECTION.includes(summary)) {
      draft.filters.status = summary;
    } else {
      draft.filters.status = "ALL_STATUS";
    }
  }),
  [OrderListActionTypes.SET_ORDERS_FILTER]: (state, {
    status,
    serviceLevel,
    date
  }) => produce(state, draft => {
    draft.filters.status = status;
    draft.filters.serviceLevel = serviceLevel;
    draft.filters.date = date;
    if (STATUS_SUMMARY_INTERSECTION.includes(status)) {
      draft.filters.summary = status;
    } else {
      draft.filters.summary = "ALL_SUMMARY";
    }
  }),
  [OrderListActionTypes.CLEAR_FILTER_PREVIEW]: state => produce(state, draft => {
    draft.searchOrderPreview = undefined;
  }),
  [OrderListActionTypes.RESET_IMPORT_ERRORS]: state => produce(state, draft => {
    draft.importErrors = orderListInitialState.importErrors;
  })
};
export const orderListReducer = createReducer(orderListInitialState, reducers);