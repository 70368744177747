import { defineMessages } from "react-intl";
export const productCategoriesContent = defineMessages({
  ADULT: {
    id: "productCategories.adult",
    defaultMessage: "Adult"
  },
  APPAREL_FABRIC_PLUSH_TEXTILES: {
    id: "productCategories.apparelFabricPlushTextiles",
    defaultMessage: "Apparel, fabric, plush, and textiles"
  },
  BABY: {
    id: "productCategories.baby",
    defaultMessage: "Baby products"
  },
  FRAGILE_GLASS: {
    id: "productCategories.fragileGlass",
    defaultMessage: "Fragile or glass"
  },
  NON_GLASS_LIQUIDS: {
    id: "productCategories.nonGlassLiquids",
    defaultMessage: "Liquids (non-glass)"
  },
  PERFORATED_PACKAGING: {
    id: "productCategories.perforatedPackaging",
    defaultMessage: "Perforated packaging"
  },
  POWDER_PELLETS_GRANULAR: {
    id: "productCategories.powderPelletsGranular",
    defaultMessage: "Powder, pellets, granular"
  },
  SHARP: {
    id: "productCategories.sharp",
    defaultMessage: "Sharp"
  },
  SMALL: {
    id: "productCategories.small",
    defaultMessage: "Small"
  },
  OTHER: {
    id: "productCategories.other",
    defaultMessage: "Other (no special packaging)"
  }
});