import { wishAuthenticated, wishNotAuthenticated } from "./authentication";
import { createReducer } from "common/ReduxUtils";
import { WishSellerValidation } from "./validation";
import { WishActionTypes } from "./WishActions";
export const wishInitialState = {
  auth: wishNotAuthenticated,
  validation: WishSellerValidation.None,
  fastTagActive: false
};
export const reducers = {
  [WishActionTypes.WISH_CODE_RECEIVED]: (state, {
    code
  }) => ({
    ...state,
    auth: wishAuthenticated(code)
  }),
  [WishActionTypes.WISH_SELLER_VALIDATED]: (state, {
    success
  }) => ({
    ...state,
    validation: success ? WishSellerValidation.Successful : WishSellerValidation.Failed
  }),
  [WishActionTypes.WISH_FAST_TAGS_ACTIVE_CHANGED]: (state, {
    active
  }) => ({
    ...state,
    fastTagActive: active
  }),
  [WishActionTypes.WISH_SELLER_RECEIVED]: (state, {
    seller
  }) => ({
    ...state,
    seller,
    fastTagActive: seller.isTwoDay
  })
};
export const wishReducer = createReducer(wishInitialState, reducers);