import { uniqBy } from "lodash";
import { SortDir } from "common/models";
import { algoliaWholesaleShipmentsService } from "wholesale/list/algoliaWholesaleOrdersService";
export const fetchAddress = async (query, pageNum = 0) => {
  const res = await algoliaWholesaleShipmentsService.search(query, pageNum, {
    fieldName: "updatedAt",
    direction: SortDir.DESC
  }, undefined, undefined, undefined, ["validatedDestinationAddress"], ["validatedDestinationAddress"]);
  const addresses = res?.hits?.map(order => order?.validatedDestinationAddress).filter(address => !!address);
  return {
    uniqueAddresses: uniqBy(addresses, address => `${address?.street1}-${address?.street2}-${address?.city}-${address?.state}-${address?.zip}-${address?.phone}`),
    totalPages: res?.response?.nbPages
  };
};