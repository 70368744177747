import { createSelector } from "reselect";
import { selectBookingRequest } from "./selectBookingRequest";
export const selectBookingSubmitRequest = () => createSelector(selectBookingRequest(false), bookingRequest => {
  const {
    addFlexportInsurance,
    airShipmentCargoDetails,
    applyForFlexportCapital,
    bookingType,
    cargoReadyDate,
    containers,
    destinationPortFid,
    includeCustomsBond,
    incoterm,
    insuranceCargoValueCents,
    insurancePremiumCents,
    offeringTitle,
    offerings,
    originPortFid,
    serviceModes,
    totalCommercialValue,
    arrivalExpectedAt,
    cargoContainerSize,
    palletCount,
    reserveWarehouseId,
    storageShipmentType,
    prepOption,
    to,
    dtcPrepOption
  } = bookingRequest;
  const submitBookingRequest = {
    addFlexportInsurance,
    airShipmentCargoDetails,
    applyForFlexportCapital,
    bookingType,
    cargoReadyDate,
    containers,
    destinationPortFid,
    includeCustomsBond,
    incoterm,
    insuranceCargoValueCents,
    insurancePremiumCents,
    offeringTitle,
    offerings,
    originPortFid,
    serviceModes,
    totalCommercialValue,
    arrivalExpectedAt,
    cargoContainerSize,
    palletCount,
    reserveWarehouseId,
    storageShipmentType,
    replenishmentPrepOption: prepOption,
    to,
    dtcPrepOption
  };
  return submitBookingRequest;
});