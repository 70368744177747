import { getItemFromLocalStorageAndParse } from "common/LocalStorage";
const getInboundCacheKey = planId => `plan-${planId}`;
const getPlannedShipmentCacheKey = shipmentId => `plannedShipment-${shipmentId}`;
const getPlannedPackagesCacheKey = planId => `plannedPackages-${planId}`;
export const saveInboundLocally = (inbound, shippingPlanId) => {
  localStorage.setItem(getInboundCacheKey(shippingPlanId), JSON.stringify(inbound));
};
export const savePlannedShipmentLocally = plannedShipment => {
  localStorage.setItem(getPlannedShipmentCacheKey(plannedShipment.id), JSON.stringify(plannedShipment));
};
export const savePlannedPackagesLocally = (plannedPackages, shippingPlanId) => {
  localStorage.setItem(getPlannedPackagesCacheKey(shippingPlanId), JSON.stringify(plannedPackages));
};
export const getLocalInbound = planId => getItemFromLocalStorageAndParse(getInboundCacheKey(planId));
export const getLocalPlannedShipment = shipmentId => getItemFromLocalStorageAndParse(getPlannedShipmentCacheKey(shipmentId));
export const getLocalPlannedPackages = planId => getItemFromLocalStorageAndParse(getPlannedPackagesCacheKey(planId));
export const removeLocalPlannedShipment = shipmentId => localStorage.removeItem(getPlannedShipmentCacheKey(shipmentId));