import { STORAGE_INBOUNDS_RATE } from "../StorageInboundRatesConstant";

// TODO: STORAGE_INBOUNDS_RATE will eventually be fetched using an API
export const getStorageInboundRates = () => {
  const {
    storageFeePerMonth,
    palletInboundFee,
    containerUnloadingFee
  } = STORAGE_INBOUNDS_RATE;
  const containerUnloadingFeeBySize = containerUnloadingFee.reduce((map, item) => {
    map[item.size] = item;
    return map;
  }, {});
  const palletInboundFeeBySize = palletInboundFee.reduce((map, item) => {
    map[item.size] = item;
    return map;
  }, {});
  return {
    storageFeePerMonth,
    containerUnloadingFeeBySize,
    palletInboundFeeBySize
  };
};