import { sumBy } from "lodash/fp";
import { useIntl } from "react-intl";
import { transferCostsLabels } from "../../../transfers/create/steps/common/transferCosts.labels";
import { wholesaleCreateLabels } from "../wholesaleCreate.labels";
import { useWholesaleConfirmCostsColumns } from "./useWholesaleConfirmCostsColumns";
export const useWholesalePricingSummary = wholesaleOrder => {
  const {
    formatMessage
  } = useIntl();
  const aggregatorOptions = {
    totalRowLabel: formatMessage(wholesaleCreateLabels.steps.confirm.totalRowLabel)
  };
  const columns = useWholesaleConfirmCostsColumns();
  const resolveLabel = ({
    breakdownKey,
    details
  }) => transferCostsLabels.breakdown[breakdownKey] ? formatMessage(transferCostsLabels.breakdown[breakdownKey]) : details;
  const data = [...Object.entries(wholesaleOrder?.estimations?.computedPriceBreakdown ?? {}).filter(([_key, costEntry]) => !costEntry.chargedByExternalPartner).map(([breakdownKey, costEntry]) => ({
    rowLabel: resolveLabel({
      breakdownKey,
      details: costEntry.details
    }),
    quantity: costEntry.multiplier,
    unitLabel: costEntry.category,
    unitCost: costEntry.price,
    amount: costEntry.calculatedPrice
  })), {}];
  return {
    subTables: [{
      data: data,
      columns,
      aggregatorOptions
    }],
    totalAggregation: sumBy("amount", data)
  };
};