import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { OrganizationActionTypes } from "organization/OrganizationActions";
export const organizationInitialState = {
  sellerId: "",
  createdBy: "",
  createdAt: "",
  isCatalogImported: false,
  hasChargebeeAccount: false,
  dealStage: "",
  onboardingStage: {},
  showChecklist: true,
  tags: "",
  isArchived: false,
  users: [],
  businessName: undefined,
  senderEmail: undefined,
  logisticsAccountId: undefined,
  shopDomain: undefined,
  isSfnSupportAccount: false,
  isTestAccount: false
};
const reducers = {
  ...handleSimpleReducerUpdates([OrganizationActionTypes.UPDATE_ORGANIZATION])
};
export const organizationReducer = createReducer(organizationInitialState, reducers);