/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { BoxLabelsFormat } from "common/clients/inbound/BoxLabelsFormat";
import { LabelLanguage } from "common/clients/inbound/LabelLanguage";
import { initiateDownloadFromUrl } from "common/InitiateDownload";

/**
 * @deprecated This function does not properly parse the file name as expected,
 * but it does not appear to actually be required either way, because the labels
 * we download are already properly named.
 */
const getLabelFilename = (shippingPlanId, shipmentId) =>
// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
`plan-${shippingPlanId}` + shipmentId ? `ship-${shipmentId}` : "" + "-box-labels.pdf";
export const downloadBoxLabel = (url, shippingPlanId, shipmentId) => initiateDownloadFromUrl(url, getLabelFilename(shippingPlanId, shipmentId));

/**
 * {
 *   "ENGLISH": labelUrl
 *   "MANDARIN": ""
 *   "SPANISH": ""
 * }
 */

// Returns an object with all languages set to an empty string
export const boxLabelsLanguagesState = Object.assign({}, ...Object.values(LabelLanguage).map(language => ({
  [language]: ""
})));

/**
 * {
 *   "A4_PAPER": {
 *     "ENGLISH": labelUrl
 *     "MANDARIN": ""
 *     "SPANISH": ""
 *   },
 *   "THERMAL_4_X_6": {
 *     ...
 *   },
 *   "US_LETTER": {
 *     ...
 *   }
 * }
 */

export const boxLabelsFormatsState = Object.values(BoxLabelsFormat).reduce((formatObj, format) => {
  formatObj[format] = boxLabelsLanguagesState;
  return formatObj;
},
// eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
{});
export const getPrintSizeOptions = formatMessage => [{
  label: formatMessage({
    id: "inbounds.printSizeOptions.thermal",
    defaultMessage: 'Thermal Printing Paper (4" x 6")'
  }),
  value: BoxLabelsFormat.THERMAL_4_X_6
}, {
  label: formatMessage({
    id: "inbounds.printSizeOptions.usLetter",
    defaultMessage: 'US Letter (8.5" x 11")'
  }),
  value: BoxLabelsFormat.US_LETTER
}, {
  label: formatMessage({
    id: "inbounds.printSizeOptions.a4",
    defaultMessage: 'A4 Paper (8.25" x 11.75")'
  }),
  value: BoxLabelsFormat.A4_PAPER
}];