import { ModalActionTypes } from "common/components/Modal/ModalActions";
import { createReducer } from "common/ReduxUtils";
export const modalInitialState = {
  modals: {}
};
const modalReducerFunc = (state, action) => ({
  ...state,
  modals: {
    ...state.modals,
    [action.id]: action.type === ModalActionTypes.SHOW_MODAL
  }
});
const reducers = {
  [ModalActionTypes.SHOW_MODAL]: modalReducerFunc,
  [ModalActionTypes.HIDE_MODAL]: modalReducerFunc
};
export const modalReducer = createReducer(modalInitialState, reducers);