export const stepManagerFactory = (stepStack, handlers) => {
  const {
    currentStep,
    pushStep,
    popStep,
    size,
    revert,
    replace,
    stepStack: steps
  } = stepStack;
  return {
    currentStep,
    hasBack() {
      return size === 1 ? !!currentStep.back : !currentStep.hasNoBack;
    },
    isDone() {
      return !currentStep.next;
    },
    async next(value) {
      if (currentStep.next) {
        const nextStep = await currentStep.next(value, stepStack, handlers);
        if (nextStep) {
          handlers?.beforeStackChange?.(nextStep);
          const newStack = pushStep(nextStep);
          handlers?.onStackChanged?.(newStack);
        }
      } else {
        await handlers?.done?.();
      }
    },
    reassess(newNextStep) {
      const reassessedStep = currentStep.reassess?.();
      if (reassessedStep) {
        revert(reassessedStep);
      } else if (newNextStep) {
        handlers?.beforeStackChange?.(newNextStep);
        const newStack = pushStep(newNextStep);
        handlers?.onStackChanged?.(newStack);
      }
    },
    back() {
      if (size === 1) {
        currentStep.back?.();
        return;
      }
      handlers?.beforeStackChange?.(steps[size - 2]);
      const newStack = popStep();
      handlers?.onStackChanged?.(newStack);
    },
    replace(newStep) {
      const newStack = replace(newStep);
      handlers?.onStackChanged?.(newStack);
    }
  };
};