import produce from "immer";
export const setShippingPlanDetailsReducer = (state, action) => produce(state, draft => {
  const {
    shippingPlanId,
    flexId,
    shipmentId
  } = action;
  const existingEntry = state.shippingPlanMap[shippingPlanId] ?? {};
  draft.shippingPlanMap[shippingPlanId] = {
    flexId: flexId ?? existingEntry.flexId,
    shipmentId: shipmentId ?? existingEntry.shipmentId
  };
});