import { inboundClient } from "Clients";
import { logError, logStart } from "Logger";
import { InboundActionTypes } from "inbounds/store/InboundActionTypes";
export const getShipmentReceivingInfo = () => async (dispatch, getState) => {
  const state = getState();
  const {
    inbound: {
      loadedShipmentId: shipmentId,
      receivingInfo,
      plan: {
        id: shippingPlanId
      }
    },
    user: {
      sellerId
    }
  } = state;
  const ctx = logStart({
    fn: "getShipmentReceivingInfo",
    shippingPlanId,
    shipmentId
  });
  if (!shipmentId) {
    logError(ctx, "Missing shipmentId");
    throw new Error("Missing shipmentId");
  }
  try {
    const shipmentReceivingInfo = await inboundClient.getReceivingInfo(sellerId, shipmentId.toString());
    dispatch({
      type: InboundActionTypes.LOAD_RECEIVING_INFO,
      receivingInfo: {
        ...receivingInfo,
        [shipmentId]: shipmentReceivingInfo
      }
    });
  } catch (err) {
    logError(ctx, err);
  }
};