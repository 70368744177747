import { InboundBookingCargoTotalUnit } from "@deliverr/legacy-inbound-client";
export let ShipmentSteps;
(function (ShipmentSteps) {
  ShipmentSteps["OriginSearch"] = "originSearch";
  ShipmentSteps["SupplierSelect"] = "supplierSelect";
  ShipmentSteps["DestinationSelect"] = "destinationSelect";
  ShipmentSteps["ReserveStorageFcSelect"] = "reserveStorageFcSelect";
  ShipmentSteps["DestinationSearch"] = "destinationSearch";
  ShipmentSteps["CargoDetails"] = "cargoDetails";
  ShipmentSteps["PackagingRequirements"] = "packagingRequirements";
  ShipmentSteps["PriceView"] = "priceView";
  ShipmentSteps["OwnTransportationAddressStep"] = "ownTransportationAddressStep";
  ShipmentSteps["DomesticFulfillmentSelect"] = "domesticFulfillmentSelect";
  ShipmentSteps["DomesticDestinationSelect"] = "domesticDestinationSelect";
})(ShipmentSteps || (ShipmentSteps = {}));
export const INTERNATIONAL_RATE_STEPS = [ShipmentSteps.OriginSearch, ShipmentSteps.SupplierSelect, ShipmentSteps.DestinationSelect, ShipmentSteps.ReserveStorageFcSelect, ShipmentSteps.DestinationSearch, ShipmentSteps.CargoDetails, ShipmentSteps.PackagingRequirements, ShipmentSteps.PriceView];
export { InboundBookingCargoTotalUnit as UnitSystem };
export let DistributionType;
(function (DistributionType) {
  DistributionType["REPLENISH_ON_DEMAND"] = "REPLENISH_ON_DEMAND";
  DistributionType["FORWARD_TO_AMAZON"] = "FORWARD_TO_AMAZON";
  DistributionType["FORWARD_TO_AMAZON_WITH_UNIT_PREP"] = "FORWARD_TO_AMAZON_WITH_UNIT_PREP";
  DistributionType["DIRECT_TO_AMAZON"] = "DIRECT_TO_AMAZON";
})(DistributionType || (DistributionType = {}));
export let UnitPrepService;
(function (UnitPrepService) {
  UnitPrepService["UNIT_BARCODING"] = "UNIT_BARCODING";
  UnitPrepService["BAGGING_PROTECTION"] = "BAGGING_PROTECTION";
  UnitPrepService["DAMAGE_PROTECTION"] = "DAMAGE_PROTECTION";
})(UnitPrepService || (UnitPrepService = {}));
export let OriginType;
(function (OriginType) {
  OriginType["DOMESTIC"] = "DOMESTIC";
  OriginType["INTERNATIONAL"] = "INTERNATIONAL";
})(OriginType || (OriginType = {}));
export let DomesticDestinationType;
(function (DomesticDestinationType) {
  DomesticDestinationType["RESERVE_STORAGE"] = "RESERVE_STORAGE";
  DomesticDestinationType["DTC"] = "DTC";
})(DomesticDestinationType || (DomesticDestinationType = {}));
export let AmazonDestinationType;
(function (AmazonDestinationType) {
  AmazonDestinationType["SINGLE"] = "SINGLE";
  AmazonDestinationType["MULTIPLE"] = "MULTIPLE";
  AmazonDestinationType["AUTOMATED"] = "AUTOMATED";
  AmazonDestinationType["UNKNOWN"] = "UNKNOWN";
})(AmazonDestinationType || (AmazonDestinationType = {}));
export let CargoInsuranceType;
(function (CargoInsuranceType) {
  CargoInsuranceType["RESTRICTED_PRODUCTS"] = "RESTRICTED_PRODUCTS";
  CargoInsuranceType["NO_RESTRICTED_PRODUCTS"] = "NO_RESTRICTED_PRODUCTS";
})(CargoInsuranceType || (CargoInsuranceType = {}));