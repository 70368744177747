import { storageClient } from "common/clients/instances";
import { StorageInboundDetailActionTypes } from "../StorageInboundDetailStore.types";
import log from "Logger";
import { isEmpty } from "lodash/fp";
export const getStorageRates = () => async dispatch => {
  try {
    const storageRates = (await storageClient.getRates())?.value?.reduce((map, rate) => {
      map[rate.warehouseId] = rate;
      return map;
    }, {});
    if (isEmpty(storageRates)) {
      throw new Error(`Storage rates response is empty`);
    }
    dispatch({
      type: StorageInboundDetailActionTypes.STORAGE_GET_RATES_SUCCESS,
      storageRates
    });
  } catch (err) {
    log.error({
      err
    }, "error getting storage rates");
    dispatch({
      type: StorageInboundDetailActionTypes.STORAGE_GET_RATES_ERROR,
      err
    });
  }
};