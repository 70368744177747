export let LoginStatus;
(function (LoginStatus) {
  LoginStatus["LoggedIn"] = "LoggedIn";
  LoginStatus["LoggedOut"] = "LoggedOut";
  LoginStatus["Unknown"] = "Unknown";
})(LoginStatus || (LoginStatus = {}));
export function isLoginSuccessful(response) {
  return response.status === "connected";
}
export function isLoggedOut(response) {
  return response.status === "unknown";
}
export const FastTagsLoginOptions = {
  scope: "ads_management, business_management, catalog_management, pages_read_engagement"
};
export const getAuthCredentials = ({
  authResponse: {
    userID,
    accessToken
  }
}) => ({
  userId: userID,
  accessToken
});