import { trimExpirationDate } from "./trimExpirationDate";
export const getLotInformationDefaultValues = planItem => {
  const defaultFieldValues = {
    lotNumber: planItem?.lotNumber ?? undefined,
    hasFullExpirationDate: {
      value: true
    }
  };
  const existingExpirationDate = planItem?.expirationDate;
  const hasFullExpirationDate = Boolean(planItem?.expirationDateHasDay);
  if (existingExpirationDate) {
    defaultFieldValues.hasFullExpirationDate.value = hasFullExpirationDate;
    defaultFieldValues.expirationDate = trimExpirationDate(existingExpirationDate.toString(), hasFullExpirationDate);
  }
  return defaultFieldValues;
};