export const shipmentListItemResponseToTransferListItem = record => {
  const firstShipment = record.shipments?.[Object.keys(record.shipments)[0]];
  return {
    shipmentId: record.id,
    externalDestinationId: record.externalId,
    createdAutomatically: record.createdAutomatically,
    channel: record.channel,
    externalDestinationType: record.externalDestinationType,
    origin: {
      id: record.originWarehouseId ?? firstShipment?.originWarehouseId ?? "",
      address: record.originWarehouseAddress ?? firstShipment?.originWarehouseAddress
    },
    shipments: Object.values(record.shipments ?? {}).map(shipment => ({
      status: shipment.status,
      destinationAddress: shipment.validatedDestinationAddress ?? shipment.destinationWarehouseAddress,
      shipBy: shipment.shipBy,
      externalAttributes: shipment.externalAttributes
    })),
    destination: record.validatedDestinationAddress,
    status: record.status,
    source: record.source,
    expectedUnits: record.numberOfItems ?? 0,
    createdTime: new Date(record.createdAt),
    orderType: record.orderType,
    items: record.orderItems,
    numberOfBoxes: record.numberOfBoxes ?? 0,
    numberOfPallets: record.numberOfPallets ?? 0,
    numberOfSkus: record.numberOfSkus ?? 0,
    inboundStatus: record.inboundStatus,
    name: record.name,
    inboundShippingPlanId: record.inboundShippingPlanId,
    inboundShipmentId: record.inboundShipmentId,
    inboundAsnId: record.inboundAsnId,
    errors: record.errors,
    transferSource: record.transferSource,
    version: record.version,
    updatedTime: new Date(record.updatedAt),
    distributionChannel: record.distributionChannel,
    nonComplianceStatus: record.nonComplianceStatus,
    nonComplianceTypeAndStatus: record.nonComplianceTypeAndStatus
  };
};