import { DeliverrError } from "@deliverr/commons-objects";
import { inboundClient } from "Clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { getSellerId } from "common/user/UserSelectors";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { PrepUIErrorCode } from "prep/PrepUIErrorCode";
import { setPrepJob, setPrepRequest } from "prep/store";
import { mapPrepJobResponseToPrepRequest } from "prep/util";
import { getShippingPlanId } from "inbounds/steps/InboundStepSelectors";
import { logError, logStart, logSuccess } from "Logger";
import { PREP_TYPE_TO_SELF_SERVE_TYPE } from "./PrepTypeToSelfServeType";
/**
 * Upserts only Self-serve Prep Types for the loaded Shipping Plan.
 * If prepTypes are not passed, it will remove the existing types.
 */
export const updateSimplePrepV2 = (prepTypes = []) => async (dispatch, getState) => {
  const sellerId = getSellerId(getState());
  const shippingPlanId = getShippingPlanId(getState());
  const ctx = logStart({
    fn: updateSimplePrepV2.name,
    sellerId,
    shippingPlanId,
    prepTypes
  });
  try {
    dispatch(addLoader(InboundLoaderId.transition));
    const updatedPrepJob = await inboundClient.updateSelfServePrepRequestsForShippingPlan({
      sellerId,
      shippingPlanId,
      shippingPlanSelfServePrepOptionsRequest: {
        options: prepTypes.map(prepType => PREP_TYPE_TO_SELF_SERVE_TYPE[prepType])
      }
    }); // TODO: PREP-3874 Remove use of InboundClient response
    if (updatedPrepJob) {
      dispatch(setPrepJob(updatedPrepJob));
      // TODO: remove this once we are only using PrepJob
      // https://shopifylogistics.atlassian.net/browse/PREP-3874
      dispatch(setPrepRequest(mapPrepJobResponseToPrepRequest(updatedPrepJob)));
    } else {
      dispatch(setPrepJob(undefined));
      dispatch(setPrepRequest(undefined));
    }
    logSuccess(ctx, "Updated Self Serve Prep successfully.");
  } catch (err) {
    logError(ctx, err);
    throw new DeliverrError({
      code: prepTypes.length > 0 ? PrepUIErrorCode.FAILED_TO_REQUEST_SIMPLE_PREP : PrepUIErrorCode.FAILED_TO_REMOVE_SIMPLE_PREP
    });
  } finally {
    dispatch(clearLoader(InboundLoaderId.transition));
  }
};