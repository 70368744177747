import { ServiceLevel } from "common/clients/pricer/ServiceLevel";
import { invert } from "lodash";
export const OLD_SERVICE_LEVELS = [ServiceLevel.StandardNonFastTag, ServiceLevel.WebsiteFastTag, ServiceLevel.WalmartFastTagStandard, ServiceLevel.eBayFastTagStandard, ServiceLevel.GoogleFastTagStandard, ServiceLevel.WishFastTagStandard, ServiceLevel.TargetStandard, ServiceLevel.ThreeDayNonFastTag, ServiceLevel.TwoDayNonFastTag, ServiceLevel.OneDayNonFastTag, ServiceLevel.AKHIOtherNonFastTag, ServiceLevel.Military, ServiceLevel.WorldwidePartnerDeliveredDutyPaid, ServiceLevel.WorldwidePartnerDeliveredDutyUnpaid, ServiceLevel.WorldwideStandardDeliveredDutyUnpaid];
export const NEW_SERVICE_LEVELS = [ServiceLevel.Standard, ServiceLevel.Flexpedited, ServiceLevel.PriorityOneDay, ServiceLevel.PriorityTwoDay, ServiceLevel.PriorityThreeDay, ServiceLevel.Military, ServiceLevel.AKHIOtherNonFastTag, ServiceLevel.WorldwidePartnerDeliveredDutyPaid, ServiceLevel.WorldwidePartnerDeliveredDutyUnpaid, ServiceLevel.WorldwideStandardDeliveredDutyUnpaid];
export const FAST_TAG_SERVICE_LEVELS = [ServiceLevel.WebsiteFastTag, ServiceLevel.WalmartFastTagStandard, ServiceLevel.eBayFastTagStandard, ServiceLevel.GoogleFastTagStandard, ServiceLevel.WishFastTagStandard];
export const oldToNewServiceLevelMap = {
  [ServiceLevel.StandardNonFastTag]: ServiceLevel.StandardNonFastTag,
  [ServiceLevel.WebsiteFastTag]: ServiceLevel.WebsiteFastTag,
  [ServiceLevel.WalmartFastTagStandard]: ServiceLevel.WalmartFastTagStandard,
  [ServiceLevel.eBayFastTag]: ServiceLevel.eBayFastTagStandard,
  [ServiceLevel.GoogleFastTag]: ServiceLevel.GoogleFastTagStandard,
  [ServiceLevel.WishFastTag]: ServiceLevel.WishFastTagStandard,
  [ServiceLevel.ThreeDayNonFastTag]: ServiceLevel.ThreeDayNonFastTag,
  [ServiceLevel.TwoDayNonFastTag]: ServiceLevel.TwoDayNonFastTag,
  [ServiceLevel.OneDayNonFastTag]: ServiceLevel.OneDayNonFastTag,
  [ServiceLevel.AKHIOtherNonFastTag]: ServiceLevel.AKHIOtherNonFastTag,
  [ServiceLevel.Military]: ServiceLevel.Military,
  [ServiceLevel.WorldwideStandard]: ServiceLevel.WorldwideStandardDeliveredDutyUnpaid
};
export const newToOldServiceLevelMap = {
  ...invert(oldToNewServiceLevelMap),
  [ServiceLevel.WorldwidePartnerDeliveredDutyPaid]: ServiceLevel.WorldwideStandard,
  [ServiceLevel.WorldwidePartnerDeliveredDutyUnpaid]: ServiceLevel.WorldwideStandard,
  [ServiceLevel.TargetStandard]: ServiceLevel.StandardNonFastTag
};
export const INTERNATIONAL_SERVICE_LEVELS = [ServiceLevel.WorldwidePartnerDeliveredDutyPaid, ServiceLevel.WorldwidePartnerDeliveredDutyUnpaid, ServiceLevel.WorldwideStandardDeliveredDutyUnpaid, ServiceLevel.WorldwideStandard];
export const isInternationalServiceLevel = serviceLevel => INTERNATIONAL_SERVICE_LEVELS.includes(serviceLevel);
export const SELLER_IDS_ON_2023_PRICING = ["jamesgrandefeldballchaincom"];
export const PRICING_GO_LIVE_DATE_2024 = new Date("2024-02-08T00:00:00Z");