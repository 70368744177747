import { logError } from "Logger";
import { chain, sumBy } from "lodash";
import { replenishmentClient } from "Clients";
import { feeDescriptionMessages } from "transfers/create/actions/replenishmentOrder/shipping/feeDescriptionMessages";
import { replenishmentOrderDetailsSetCosts } from "./ReplenishmentOrderDetailsActions";
const convertToChargeRow = charges => {
  return chain(charges).flatMap(quotes => quotes.chargeBreakdown).filter(quotes => !quotes.isExcluded).groupBy(breakdown => breakdown.chargeType).map((val, key) => {
    const totalCost = sumBy(val, chargesRecord => chargesRecord.totalCost);
    const row = {
      feeDescription: feeDescriptionMessages[key],
      chargeType: val[0].chargeType,
      unitCost: val[0].unitCost,
      unitOfMeasure: val[0].unitOfMeasure,
      unitCount: sumBy(val, chargesRecord => chargesRecord.unitCount),
      totalCost
    };
    return row ?? [];
  }).value();
};
export const fetchAndSetOrderCosts = orderId => async dispatch => {
  try {
    const {
      costActuals,
      costEstimates
    } = (await replenishmentClient.getOrderCosts(orderId)).value;
    const actualCharges = convertToChargeRow(costActuals);
    const estimatedCharges = convertToChargeRow(costEstimates);
    dispatch(replenishmentOrderDetailsSetCosts({
      actualCharges,
      estimatedCharges
    }));
  } catch (err) {
    logError({
      fn: "fetchAndSetOrderCosts"
    }, orderId, err);
  }
};