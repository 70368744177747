import { BarcodeResponseCode, BarcodeResponseSubcode } from "@deliverr/commons-clients";
import { BarcodeFormat, getBarcodeFormat, validateBarcode } from "common/components/ui/utils/BarcodeUtils";
import { BARCODE_LIMIT } from "common/BarcodeOptions";
import { createReducerHook } from "common/Hooks";
import produce from "immer";
import { find, isEmpty, omit, some } from "lodash";
export let BarcodeEditErrors;
(function (BarcodeEditErrors) {
  BarcodeEditErrors["FORMAT"] = "invalid_format";
})(BarcodeEditErrors || (BarcodeEditErrors = {}));
export let BarcodeActionTypes;
(function (BarcodeActionTypes) {
  BarcodeActionTypes["LOAD_BARCODES"] = "LOAD_BARCODES";
  BarcodeActionTypes["LOAD_BARCODES_DRAFT"] = "LOAD_BARCODES_DRAFT";
  BarcodeActionTypes["ADD_NEW_BARCODE"] = "ADD_NEW_BARCODE";
  BarcodeActionTypes["ADD_PLANNED_BARCODE"] = "ADD_PLANNED_BARCODE";
  BarcodeActionTypes["REMOVE_BARCODE"] = "REMOVE_BARCODE";
  BarcodeActionTypes["UPDATE_BARCODE_FORMAT"] = "UPDATE_BARCODE_FORMAT";
  BarcodeActionTypes["UPDATE_BARCODE"] = "UPDATE_BARCODE";
  BarcodeActionTypes["VALIDATE_BARCODE"] = "VALIDATE_BARCODE";
  BarcodeActionTypes["BARCODE_SERVICE_ERROR"] = "BARCODE_SERVICE_ERROR";
})(BarcodeActionTypes || (BarcodeActionTypes = {}));
export const barcodeDefaultState = {
  barcodesCollection: []
};
export const newBarcodeItem = {
  value: "",
  locked: false
};
const BarcodeActionMap = {
  [BarcodeActionTypes.LOAD_BARCODES]: (state, action) => ({
    ...state,
    barcodesCollection: action.barcodes.map(value => ({
      value,
      format: getBarcodeFormat(value),
      locked: true,
      valid: true
    }))
  }),
  [BarcodeActionTypes.LOAD_BARCODES_DRAFT]: (state, action) => produce(state, draftState => {
    if (action.barcodeDraft) {
      const barcodeDraft = action.barcodeDraft.filter(barcode => !draftState.barcodesCollection.some(({
        value
      }) => value === barcode.value)).slice(0, BARCODE_LIMIT - draftState.barcodesCollection.length);
      draftState.barcodesCollection.push(...barcodeDraft);
    }
  }),
  [BarcodeActionTypes.ADD_NEW_BARCODE]: state => state.barcodesCollection ? {
    ...state,
    barcodesCollection: state.barcodesCollection.length < BARCODE_LIMIT ? [...state.barcodesCollection, {
      ...newBarcodeItem
    }] : state.barcodesCollection
  } : state,
  [BarcodeActionTypes.ADD_PLANNED_BARCODE]: (state, action) => state.barcodesCollection && !state.barcodesCollection.find(item => item.value === action.barcode.barcode && item.format === action.barcode.format) ? {
    ...state,
    barcodesCollection: state.barcodesCollection.length < BARCODE_LIMIT ? [...state.barcodesCollection, {
      ...newBarcodeItem,
      value: action.barcode.barcode,
      format: action.barcode.format
    }] : state.barcodesCollection
  } : state,
  [BarcodeActionTypes.REMOVE_BARCODE]: (state, action) => state.barcodesCollection ? {
    ...state,
    barcodesCollection: !state.barcodesCollection[action.index].locked ? state.barcodesCollection.filter((val, index) => index !== action.index) : state.barcodesCollection
  } : state,
  [BarcodeActionTypes.UPDATE_BARCODE_FORMAT]: (state, action) => state.barcodesCollection ? {
    ...state,
    barcodesCollection: !state.barcodesCollection[action.index].locked ? state.barcodesCollection.map((val, index) => {
      if (index === action.index) {
        return action.barcodeFormat === BarcodeFormat.DSKU ? {
          ...val,
          format: BarcodeFormat.DSKU,
          valid: true,
          value: action.dsku
        } : {
          ...val,
          format: action.barcodeFormat,
          value: val.format !== BarcodeFormat.DSKU ? val.value : ""
        };
      }
      return val;
    }) : state.barcodesCollection
  } : state,
  [BarcodeActionTypes.UPDATE_BARCODE]: (state, action) => state.barcodesCollection ? {
    ...state,
    barcodesCollection: !state.barcodesCollection[action.index].locked ? state.barcodesCollection.map((barcodeItem, index) => {
      if (index === action.index) {
        return {
          // we check for barcode minimum length to avoid expensive array calls
          ...(action.barcode.length >= 10 && some(state.barcodesCollection, ["value", action.barcode]) ? {
            ...barcodeItem,
            error: {
              code: BarcodeResponseSubcode.DUPLICATE
            },
            valid: false
          } : omit(barcodeItem, ["valid", "error"])),
          value: action.barcode
        };
      }
      return barcodeItem;
    }) : state.barcodesCollection
  } : state,
  [BarcodeActionTypes.VALIDATE_BARCODE]: (state, action) => state.barcodesCollection ? {
    ...state,
    barcodesCollection: !(action.index !== undefined ? state.barcodesCollection[action.index] : state.barcodesCollection.find(item => item.value === action.barcode))?.locked ? state.barcodesCollection.map((barcode, index) => {
      if (index === action.index || barcode.value === action.barcode) {
        const valid = barcode.format === BarcodeFormat.DSKU ||
        // eslint-disable-next-line no-prototype-builtins
        barcode.hasOwnProperty("format") && validateBarcode(barcode.format, barcode.value);
        const newBarcodeState = omit(barcode, ["error"]);
        return isEmpty(barcode.value) ? omit(newBarcodeState, ["valid"]) : valid ? {
          ...newBarcodeState,
          error: false,
          valid
        } : {
          ...newBarcodeState,
          valid: BarcodeEditErrors.FORMAT
        };
      }
      return barcode;
    }) : state.barcodesCollection
  } : state,
  [BarcodeActionTypes.BARCODE_SERVICE_ERROR]: (state, action) => state.barcodesCollection ? {
    ...state,
    barcodesCollection: state.barcodesCollection.map(barcode => {
      const response = action.response;
      const barcodeServiceStatus = !barcode.locked && find(response, ["barcode", barcode.value]);
      if (barcodeServiceStatus) {
        return barcodeServiceStatus.code === BarcodeResponseCode.CREATED ? {
          ...barcode,
          locked: true
        } : {
          ...barcode,
          valid: false,
          error: {
            code: barcodeServiceStatus.subcode
          }
        };
      }
      return barcode;
    })
  } : state
};
export const barcodeEditReducer = createReducerHook(BarcodeActionMap);