import { datadogRum } from "@datadog/browser-rum";
export class DatadogFnLogTimer {
  constructor() {
    this.fnStartTimes = {};
  }
  start = ({
    fn
  }) => {
    this.fnStartTimes[fn] = window.performance.now();
  };
  clearStartTime = ({
    fn
  }) => {
    this.fnStartTimes[fn] = undefined;
  };
  error = this.clearStartTime;

  // send successful function timings to DataDog as custom actions
  success = ctx => {
    const {
      fn,
      ...context
    } = ctx;
    const startTime = this.fnStartTimes[fn];
    if (typeof startTime !== "undefined") {
      const endTime = window.performance.now();
      const duration = endTime - startTime;
      // get the view attributes from the initial start log
      const startContext = datadogRum.getInternalContext(startTime);
      const startContextView = startContext?.view;
      const composedContext = {
        duration,
        ...context,
        overrides: {
          view: startContextView
        }
      };
      datadogRum.addAction(fn, composedContext);
      this.clearStartTime(ctx);
    }
  };
}