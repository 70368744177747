import { multiply, sumBy } from "lodash/fp";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getStorageInboundRates } from "storage/inbounds/utils/getStorageInboundRates";
import { getNumOfPallets } from "../../store/selector/getNumOfPallets";
import { getStorageInboundCreate } from "../../store/selector/getStorageInboundCreate";
import { ContainerSizeToDisplayValue } from "../ContainerSizeToDisplayValue";
import { usePricingSummaryColumns } from "./usePricingSummaryColumns";
import { usePricingSummaryLabels } from "./usePricingSummaryLabels";
import { usePrimaryPaymentSource } from "billing/PrimaryPaymentSource/usePrimaryPaymentSource";
import { CREDIT_CARD_FEE_RATE } from "inbounds/createShipment/steps/priceViewStep/chargesSummary/constants";
import { PalletSize, ShipmentType } from "@deliverr/storage-client";
export const usePricingSummary = isDeliverrFreight => {
  const {
    cargoType,
    containerSize,
    freightOrderDetails
  } = useSelector(getStorageInboundCreate);
  const numOfPallets = useSelector(getNumOfPallets);
  const {
    storageFeePerMonth,
    containerUnloadingFeeBySize,
    palletInboundFeeBySize
  } = getStorageInboundRates();
  const containerSizeValue = containerSize && ContainerSizeToDisplayValue[containerSize];
  const labels = usePricingSummaryLabels(containerSizeValue, freightOrderDetails?.type);
  const columns = usePricingSummaryColumns(labels.tableTitle, cargoType);
  const {
    hasCreditCardFee
  } = usePrimaryPaymentSource();
  const subTables = [];
  const data = useMemo(() => {
    let rows;
    if (cargoType === ShipmentType.FLOOR_LOADED_CONTAINER) {
      rows = [{
        rowLabel: labels.inboundHandlingFloorLoadRowLabel,
        quantity: 1,
        unitCost: containerUnloadingFeeBySize[containerSize]?.fee,
        amount: containerUnloadingFeeBySize[containerSize]?.fee
      }];
    } else {
      rows = [{
        rowLabel: labels.inboundHandlingRowLabel,
        quantity: numOfPallets,
        unitCost: palletInboundFeeBySize[PalletSize.STANDARD_60IN]?.fee,
        amount: multiply(numOfPallets, palletInboundFeeBySize[PalletSize.STANDARD_60IN]?.fee ?? 0)
      }];
    }
    isDeliverrFreight && rows.unshift({
      rowLabel: labels.deliverrFreightLabel,
      quantity: "-",
      unitCost: undefined,
      amount: freightOrderDetails?.estimatedCost
    });
    rows.push({
      rowLabel: labels.palletStorageRowLabel,
      quantity: numOfPallets,
      unitCost: storageFeePerMonth,
      amount: multiply(numOfPallets, storageFeePerMonth)
    });
    if (hasCreditCardFee) {
      const storageTotal = sumBy("amount", rows);
      const creditCardFee = storageTotal * CREDIT_CARD_FEE_RATE;
      rows.push({
        rowLabel: labels.creditCardFeeRowLabel,
        quantity: labels.creditCardCostLabel,
        amount: creditCardFee
      });
    }
    rows.push({});
    return rows;
  }, [labels.inboundHandlingRowLabel, labels.inboundHandlingFloorLoadRowLabel, labels.palletStorageRowLabel, labels.creditCardCostLabel, labels.creditCardFeeRowLabel, containerSize, numOfPallets, cargoType, palletInboundFeeBySize, containerUnloadingFeeBySize, storageFeePerMonth, hasCreditCardFee]);

  // This is added so that existing flow doesn't break
  const aggregatorOptions = {
    totalRowLabel: labels.totalRowLabel
  };
  subTables.push({
    data,
    columns,
    aggregatorOptions
  });
  const storageTotalAggregation = sumBy("amount", data);
  return {
    costTableProps: {
      subTables,
      storageTotalAggregation
    }
  };
};