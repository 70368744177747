import { Space } from "../shared";
export const getGap = theme => gap => {
  if (gap === undefined) {
    return theme.spacing[Space.S4];
  }

  // 0rem is better than 0 since the CSS calc() function only accepts lengths
  // with length units: https://developer.mozilla.org/en-US/docs/Web/CSS/calc#notes

  if (gap === null) {
    return "0rem";
  }
  return theme.spacing[gap] ?? "0rem";
};