export class ConditionalPricingRegistry {
  constructor() {
    this.pricings = [];
    this.activePricing = undefined;
  }
  getPricings() {
    return this.pricings;
  }

  /**
   * Registers a new Conditional Pricing into the registry. Uses builder pattern for convenience.
   * The most recently added pricing is the first in the list.
   *
   * @param pricing
   */
  registerPricing(pricing) {
    // unshift because we want the newest pricings first to simplify determining which is active
    this.pricings.unshift(pricing);

    // Update activePricing on register
    if (pricing.isConditionMet) {
      this.activePricing = pricing;
    }
    return this;
  }
  getActivePricing() {
    return this.activePricing;
  }
  hasActivePricing() {
    return Boolean(this.activePricing);
  }
  getActivePricingReferenceDate() {
    return this.activePricing?.baseDate;
  }
  getActivePricingComparisonDate() {
    return this.activePricing?.comparisonDate;
  }
}