import { nonComplianceClient } from "Clients";
import logger, { logError } from "Logger";
import { ProblemType } from "common/problems/ProblemType";
export const acknowledgeProblemRow = async (row, sellerId) => {
  const ctx = {
    fn: "acknowledgeProblemRow",
    row
  };
  try {
    switch (row.problemType) {
      case ProblemType.RELABELED_BOX:
        await nonComplianceClient.acknowledgeMultipleBoxLabelIncident(sellerId, row.boxLabelIncidentIds);
        return;
      case ProblemType.SHORT_SHIP_BOXES:
        await nonComplianceClient.acknowledgeMissingBoxesAlert(row.summary.id);
        return;
      default:
        logger.warn(ctx, "Attempting to acknowledge an unknown row type");
        return;
    }
  } catch (err) {
    logError(ctx, err, `Unable to acknowledge ${row.problemType}`);
  }
};