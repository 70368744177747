import { useEffect, useLayoutEffect, useState } from "react";
export const useBlade = trigger => {
  const [showSection, setShowSection] = useState(false);
  const [displayPortal, setDisplayPortal] = useState(false);
  const hidePortalAfterDelay = () => {
    const timer = setTimeout(() => {
      setDisplayPortal(false);
      clearTimeout(timer);
    }, 250);
  };
  useLayoutEffect(() => {
    if (trigger) {
      setDisplayPortal(true);
    }
  }, [trigger]);
  useEffect(() => {
    if (displayPortal) {
      setShowSection(true);
    }
  }, [displayPortal]);
  useLayoutEffect(() => {
    if (!trigger && showSection) {
      setShowSection(false);
      hidePortalAfterDelay();
    }
  }, [trigger, showSection]);
  return {
    showSection,
    displayPortal
  };
};