import { handleSimpleReducerUpdates, createReducer } from "common/ReduxUtils";
import { FreightQuotingActionTypes } from "../actions/FreightQuotingActionTypes";
import { freightQuotingInitialState } from "./freightQuotingInitialState";
import produce from "immer";
import { emptyAddress } from "common/AddressUtils";
import { getLowestQuoteRateForQuoteRequest } from "freight/common/utils/getLowestQuoteRateForQuoteRequest";
import { LocationType } from "@deliverr/freight-client";
export const reducer = {
  ...handleSimpleReducerUpdates([FreightQuotingActionTypes.SET_FREIGHT_QUOTING_BLADE_OPEN, FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_ADDRESS, FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_LIFTGATE_REQUIRED, FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_ADDRESS, FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_LOCATION_TYPE, FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_LIFTGATE_REQUIRED, FreightQuotingActionTypes.SET_FREIGHT_QUOTING_NUMBER_OF_PALLETS, FreightQuotingActionTypes.SET_FREIGHT_QUOTE]),
  [FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_LOCATION_TYPE]: (state, {
    pickupLocationType
  }) => produce(state, draft => {
    draft.pickupLocationType = pickupLocationType;
    if (pickupLocationType === LocationType.MARITIME_PORT) {
      draft.numberOfPallets = 1;
    }
  }),
  [FreightQuotingActionTypes.SET_FREIGHT_QUOTING_QUOTE_REQUEST]: (state, {
    quoteRequest
  }) => produce(state, draft => {
    draft.quoteRequest = quoteRequest;
    draft.quoteId = getLowestQuoteRateForQuoteRequest(quoteRequest.quotes)?.quoteId ?? null;
  }),
  [FreightQuotingActionTypes.RESET_FREIGHT_QUOTE_REQUEST]: state => produce(state, draft => {
    if (draft.quoteRequest) {
      draft.quoteRequest = null;
      draft.quoteId = null;
    }
  }),
  [FreightQuotingActionTypes.LOAD_FREIGHT_QUOTE_REQUEST]: (state, {
    quoteRequest
  }) => produce(state, draft => {
    draft.isLoadedQuote = true;
    draft.pickupAddress = quoteRequest.pickupDetails.address ?? {
      ...emptyAddress,
      zip: quoteRequest.pickupDetails.zip
    };
    draft.pickupLocationType = quoteRequest.pickupDetails.locationType ?? null;
    draft.pickupLiftgateRequired = quoteRequest.pickupDetails.liftGateRequired ?? false;
    draft.deliveryAddress = quoteRequest.deliveryDetails.address ?? {
      ...emptyAddress,
      zip: quoteRequest.deliveryDetails.zip
    };
    draft.deliveryLocationType = quoteRequest.deliveryDetails.locationType ?? null;
    draft.deliveryLiftgateRequired = quoteRequest.deliveryDetails.liftGateRequired ?? false;
    draft.numberOfPallets = quoteRequest.quantity;
    draft.quoteRequest = quoteRequest;
    draft.quoteId = getLowestQuoteRateForQuoteRequest(quoteRequest.quotes)?.quoteId ?? null;
    draft.freightQuotingBladeOpen = true;
  }),
  [FreightQuotingActionTypes.SET_FREIGHT_QUOTING_PICKUP_ADDRESS_IS_DELIVERR_WAREHOUSE]: (state, {
    isDeliverrWarehouse
  }) => produce(state, draftState => {
    draftState.pickupAddressMetadata.isDeliverrWarehouse = isDeliverrWarehouse;
  }),
  [FreightQuotingActionTypes.SET_FREIGHT_QUOTING_DELIVERY_ADDRESS_IS_DELIVERR_WAREHOUSE]: (state, {
    isDeliverrWarehouse
  }) => produce(state, draftState => {
    draftState.deliveryAddressMetadata.isDeliverrWarehouse = isDeliverrWarehouse;
  }),
  [FreightQuotingActionTypes.RESET_FREIGHT_QUOTING_STATE]: () => freightQuotingInitialState
};
export const freightQuotingReducer = createReducer(freightQuotingInitialState, reducer);