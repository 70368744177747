import { MilitaryTime } from "common/date/DateFormat";
import { format } from "date-fns";
export const convertTimeWindowTo24Hours = dates => ({
  startTime: dates.startTime ? format(dates.startTime, MilitaryTime) : "",
  endTime: dates.endTime ? format(dates.endTime, MilitaryTime) : ""
});
export const convertTimeWindowTo12Hours = times => {
  const options = {
    hour: "numeric",
    hour12: true
  };
  const newStartTime = new Date();
  const newEndTime = new Date();
  newStartTime.setHours(parseInt(times.startTime), 0);
  newEndTime.setHours(parseInt(times.endTime), 0);
  return {
    startTime: newStartTime.toLocaleString("en-US", options),
    endTime: newEndTime.toLocaleString("en-US", options)
  };
};