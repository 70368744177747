import { isToday, isYesterday } from "date-fns";
import { useIntl } from "react-intl";
export const useDateInWords = time => {
  const {
    formatMessage,
    formatDate
  } = useIntl();
  if (isToday(time)) {
    return formatMessage({
      id: "today",
      defaultMessage: "Today"
    });
  }
  if (isYesterday(time)) {
    return formatMessage({
      id: "yesterday",
      defaultMessage: "Yesterday"
    });
  }
  return formatDate(time, {
    month: "long",
    day: "numeric",
    year: undefined
  });
};