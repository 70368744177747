import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { BoxLabelsFormat } from "common/clients/inbound/BoxLabelsFormat";
import { LabelLanguage } from "common/clients/inbound/LabelLanguage";
import { LabelType } from "common/clients/storage/StorageLabelRequest/LabelType";
import { initiateDownloadFromUrl } from "common/InitiateDownload";
import { toast } from "common/components/ui";
import { getStorageInboundDetail } from "../../../store";
import { useState } from "react";
import { storageClient } from "common/clients/instances";
import { printSizeLabels } from "./printSize.labels";
export const useDownloadPalletLabel = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    storageInboundOrder
  } = useSelector(getStorageInboundDetail);
  const [labelSize, setLabelSize] = useState();
  const handleOptionSelect = label => {
    setLabelSize(label.value);
  };
  const onDownloadClick = async () => {
    const storageLabelsResponse = await storageClient.generateLabels({
      storageInboundOrderId: storageInboundOrder.id,
      size: labelSize ?? BoxLabelsFormat.A4_PAPER,
      labelType: LabelType.PALLET_DYNAMIC,
      language: LabelLanguage.ENGLISH,
      asnId: storageInboundOrder.asnId
    });
    const storageLabels = storageLabelsResponse.value?.labels;
    if (storageLabels) {
      const labelUrl = storageLabels[0].link;
      initiateDownloadFromUrl(labelUrl, `FlexportPalletLabels-${storageInboundOrder.shipmentId}.pdf`);
      toast.success(formatMessage({
        id: "storage.inbounds.details.label.download.success",
        defaultMessage: "Pallet label downloaded"
      }));
    } else {
      toast.error(formatMessage({
        id: "storage.inbounds.details.label.download.error",
        defaultMessage: "Pallet label download failed"
      }));
    }
  };
  const printSizeOptions = Object.values(BoxLabelsFormat).map(boxLabelsValue => ({
    label: formatMessage(printSizeLabels[boxLabelsValue]),
    value: boxLabelsValue
  }));
  return {
    printSizeOptions,
    onDownloadClick,
    handleOptionSelect,
    labelSize
  };
};