import { inboundClient } from "Clients";
import { setShippingPlanId } from "./simpleUpdateActions";
import { logError, logStart, logSuccess } from "Logger";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
import { selectBookingCreateRequest } from "../selectors/selectBookingCreateRequest";
import { getSellerId } from "common/user/UserSelectors";
export const createBookingV2 = () => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const bookingRequest = selectBookingCreateRequest()(state);
  const ctx = logStart({
    fn: SmbBookingCreationTimedEvent.CreateBooking,
    bookingRequest
  });
  try {
    const response = await inboundClient.createBookingV2(sellerId, bookingRequest);
    logSuccess(ctx, "successfully created booking");
    const {
      shippingPlanId
    } = response;
    dispatch(setShippingPlanId(shippingPlanId));
    return response;
  } catch (error) {
    logError(ctx, error);
    throw error;
  }
};