import { OpenSearchService } from "common/search/services/OpenSearchService";
export class InventorySearchService extends OpenSearchService {
  buildSearchOptions(request) {
    const searchOptions = super.buildSearchOptions(request);
    if (this.config.searchConfig.highlightMatches && request.highlightMatches !== false) {
      return {
        ...searchOptions,
        // By default, the seller portal search for inventory
        // targets name, msku, dsku
        highlight: {
          fields: {
            name: {},
            msku: {},
            dsku: {}
          }
        },
        hydrate: true
      };
    }
    return searchOptions;
  }
}