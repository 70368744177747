import { getListingLogo } from "common/ListingSolutionLogos";
import { getRoundedLogo } from "common/RoundedLogos";
import { getSidebarLogo } from "common/SidebarLogos";
import { __, camelCase, defaultTo, pipe, prop, toUpper } from "lodash/fp";
import { ElevioArticleId } from "./elevio";
export let ListingSolutionType;
(function (ListingSolutionType) {
  ListingSolutionType["SalesChannel"] = "salesChannel";
  ListingSolutionType["ListingTools"] = "listingTools";
})(ListingSolutionType || (ListingSolutionType = {}));
const DELIVERR_URL = "https://deliverr.com";
const INTEGRATIONS_URL = `${DELIVERR_URL}/integrations`;
export const listingSolutionConfig = {
  CHANNELADVISOR: {
    name: "ChannelAdvisor",
    integrationPage: `${INTEGRATIONS_URL}/channeladvisor`,
    logo: getListingLogo("CHANNELADVISOR"),
    roundedLogo: getRoundedLogo("CHANNELADVISOR"),
    sidebarLogo: getSidebarLogo("CHANNELADVISOR"),
    helpArticleId: ElevioArticleId.ChannelAdvisor
  },
  ECOMDASH: {
    name: "Ecomdash",
    integrationPage: `${INTEGRATIONS_URL}/ecomdash`,
    logo: getListingLogo("ECOMDASH"),
    roundedLogo: getRoundedLogo("ECOMDASH"),
    sidebarLogo: getSidebarLogo("ECOMDASH"),
    helpArticleId: ElevioArticleId.Ecomdash
  },
  ETSY: {
    name: "Etsy",
    integrationPage: `${INTEGRATIONS_URL}/etsy`,
    logo: getListingLogo("ETSY"),
    roundedLogo: getRoundedLogo("ETSY"),
    sidebarLogo: getSidebarLogo("ETSY"),
    helpArticleId: ElevioArticleId.Etsy
  },
  GEEKSELLER: {
    name: "GeekSeller",
    integrationPage: `${INTEGRATIONS_URL}/geekseller`,
    logo: getListingLogo("GEEKSELLER"),
    roundedLogo: getRoundedLogo("GEEKSELLER"),
    sidebarLogo: getSidebarLogo("GEEKSELLER"),
    helpArticleId: ElevioArticleId.GeekSeller
  },
  LINNWORKS: {
    name: "Linnworks",
    integrationPage: `${INTEGRATIONS_URL}/linnworks`,
    logo: getListingLogo("LINNWORKS"),
    roundedLogo: getRoundedLogo("LINNWORKS"),
    sidebarLogo: getSidebarLogo("LINNWORKS"),
    helpArticleId: ElevioArticleId.linnworks
  },
  LISTINGMIRROR: {
    name: "Listing Mirror",
    integrationPage: `${INTEGRATIONS_URL}/listingmirror`,
    logo: getListingLogo("LISTINGMIRROR"),
    roundedLogo: getRoundedLogo("LISTINGMIRROR"),
    sidebarLogo: getSidebarLogo("LISTINGMIRROR"),
    helpArticleId: ElevioArticleId.ListingMirror
  },
  SELLBRITE: {
    name: "Sellbrite",
    integrationPage: `${INTEGRATIONS_URL}/sellbrite`,
    logo: getListingLogo("SELLBRITE"),
    roundedLogo: getRoundedLogo("SELLBRITE"),
    sidebarLogo: getSidebarLogo("SELLBRITE"),
    helpArticleId: ElevioArticleId.Sellbrite
  },
  SELLERACTIVE: {
    name: "SellerActive",
    integrationPage: `${INTEGRATIONS_URL}/selleractive`,
    logo: getListingLogo("SELLERACTIVE"),
    roundedLogo: getRoundedLogo("SELLERACTIVE"),
    sidebarLogo: getSidebarLogo("SELLERACTIVE"),
    helpArticleId: ElevioArticleId.SellerActive
  },
  SELLERCLOUD: {
    name: "SellerCloud",
    integrationPage: `${INTEGRATIONS_URL}/sellercloud`,
    logo: getListingLogo("SELLERCLOUD"),
    roundedLogo: getRoundedLogo("SELLERCLOUD"),
    sidebarLogo: getSidebarLogo("SELLERCLOUD"),
    helpArticleId: ElevioArticleId.SellerCloud
  },
  SHOPIFY: {
    name: "Shopify",
    integrationPage: `${DELIVERR_URL}/shopify-solution`,
    logo: getListingLogo("SHOPIFY"),
    roundedLogo: getRoundedLogo("SHOPIFY"),
    sidebarLogo: getSidebarLogo("SHOPIFY"),
    helpArticleId: ElevioArticleId.Shopify
  },
  SHOPIFYV2: {
    name: "Shopify",
    integrationPage: `${DELIVERR_URL}/shopify-solution`,
    logo: getListingLogo("SHOPIFY"),
    roundedLogo: getRoundedLogo("SHOPIFY"),
    sidebarLogo: getSidebarLogo("SHOPIFY"),
    helpArticleId: ElevioArticleId.Shopify
  },
  SHOPIFYV3: {
    name: "Shopify",
    integrationPage: `${DELIVERR_URL}/shopify-solution`,
    logo: getListingLogo("SHOPIFY"),
    roundedLogo: getRoundedLogo("SHOPIFY"),
    sidebarLogo: getSidebarLogo("SHOPIFY"),
    helpArticleId: ElevioArticleId.Shopify
  },
  SHOPIFYFULFILLMENTNETWORK: {
    name: "SFN",
    integrationPage: `${DELIVERR_URL}/shopify-solution`,
    logo: getListingLogo("SHOPIFY"),
    roundedLogo: getRoundedLogo("SHOPIFY"),
    sidebarLogo: getSidebarLogo("SHOPIFY"),
    helpArticleId: ElevioArticleId.Shopify
  },
  SKUBANA: {
    name: "Skubana",
    integrationPage: `${INTEGRATIONS_URL}/skubana`,
    logo: getListingLogo("SKUBANA"),
    roundedLogo: getRoundedLogo("SKUBANA"),
    sidebarLogo: getSidebarLogo("SKUBANA"),
    helpArticleId: ElevioArticleId.Skubana
  },
  SPSCOMMERCE: {
    name: "SPS Commerce",
    logo: getRoundedLogo("SPSCOMMERCE"),
    roundedLogo: getRoundedLogo("SPSCOMMERCE"),
    sidebarLogo: getSidebarLogo("SPSCOMMERCE"),
    integrationPage: `${INTEGRATIONS_URL}/sps-commerce`
  },
  STOREAUTOMATOR: {
    name: "StoreAutomator",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("STOREAUTOMATOR"),
    roundedLogo: getRoundedLogo("STOREAUTOMATOR"),
    sidebarLogo: getSidebarLogo("STOREAUTOMATOR"),
    helpArticleId: ElevioArticleId.StoreAutomator
  },
  WALMARTDIRECT: {
    name: "Walmart",
    integrationPage: `${DELIVERR_URL}/walmart`,
    logo: getListingLogo("WALMARTDIRECT"),
    roundedLogo: getRoundedLogo("WALMART"),
    sidebarLogo: getSidebarLogo("WALMART"),
    helpArticleId: ElevioArticleId.WalmartDirect
  },
  ZENTAIL: {
    name: "Zentail",
    integrationPage: `${INTEGRATIONS_URL}/zentail`,
    logo: getListingLogo("ZENTAIL"),
    roundedLogo: getRoundedLogo("ZENTAIL"),
    sidebarLogo: getSidebarLogo("ZENTAIL"),
    helpArticleId: ElevioArticleId.Zentail
  },
  EBAY: {
    name: "eBay",
    integrationPage: `${DELIVERR_URL}/ebay`,
    logo: getListingLogo("EBAY"),
    roundedLogo: getRoundedLogo("EBAY"),
    sidebarLogo: getSidebarLogo("EBAY"),
    helpArticleId: ElevioArticleId.Ebay
  },
  WISH: {
    name: "Wish",
    integrationPage: `${DELIVERR_URL}/wish`,
    logo: getListingLogo("WISH"),
    roundedLogo: getRoundedLogo("WISH"),
    sidebarLogo: getSidebarLogo("WISH"),
    helpArticleId: ElevioArticleId.Wish
  },
  ACENDA: {
    name: "Acenda",
    integrationPage: `${INTEGRATIONS_URL}/acenda`,
    logo: getListingLogo("ACENDA"),
    roundedLogo: getRoundedLogo("ACENDA"),
    sidebarLogo: getSidebarLogo("ACENDA"),
    helpArticleId: ElevioArticleId.Acenda
  },
  PIPE17: {
    name: "Pipe17",
    integrationPage: `${INTEGRATIONS_URL}/pipe17`,
    logo: getListingLogo("PIPE17"),
    roundedLogo: getRoundedLogo("PIPE17"),
    sidebarLogo: getSidebarLogo("PIPE17"),
    helpArticleId: ElevioArticleId.Pipe17
  },
  SELLERCHAMP: {
    name: "SellerChamp",
    integrationPage: `${INTEGRATIONS_URL}/sellerchamp`,
    logo: getListingLogo("SELLERCHAMP"),
    roundedLogo: getRoundedLogo("SELLERCHAMP"),
    sidebarLogo: getSidebarLogo("SELLERCHAMP"),
    helpArticleId: ElevioArticleId.SellerChamp
  },
  BIGCOMMERCE: {
    name: "BigCommerce",
    integrationPage: `${DELIVERR_URL}/bigcommerce`,
    logo: getListingLogo("BIGCOMMERCE"),
    roundedLogo: getRoundedLogo("BIGCOMMERCE"),
    sidebarLogo: getSidebarLogo("BIGCOMMERCE"),
    helpArticleId: ElevioArticleId.BigCommerce
  },
  AMAZON: {
    name: "Amazon",
    integrationPage: `${DELIVERR_URL}/amazon`,
    logo: getListingLogo("AMAZON"),
    roundedLogo: getRoundedLogo("AMAZON"),
    sidebarLogo: getSidebarLogo("AMAZON"),
    helpArticleId: ElevioArticleId.Amazon
  },
  ARTEZA: {
    name: "Arteza",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("ARTEZA"),
    roundedLogo: getRoundedLogo("ARTEZA"),
    sidebarLogo: getSidebarLogo("ARTEZA")
  },
  GOOGLE: {
    name: "Google",
    integrationPage: `${DELIVERR_URL}/google`,
    logo: getListingLogo("GOOGLE"),
    roundedLogo: getRoundedLogo("GOOGLE"),
    sidebarLogo: getSidebarLogo("GOOGLE"),
    helpArticleId: ElevioArticleId.GoogleShopping
  },
  JCPENNEY: {
    name: "JCPenney",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("JCPENNEY"),
    roundedLogo: getRoundedLogo("JCPENNEY"),
    sidebarLogo: getSidebarLogo("JCPENNEY")
  },
  MOBILITYECOMMERCE: {
    name: "MobilityeCommerce",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("MOBILITYECOMMERCE"),
    roundedLogo: getRoundedLogo("MOBILITYECOMMERCE"),
    sidebarLogo: getSidebarLogo("MOBILITYECOMMERCE"),
    helpArticleId: ElevioArticleId.MobilityeCommerce
  },
  FACEBOOK: {
    name: "Facebook",
    integrationPage: `${DELIVERR_URL}/facebook`,
    logo: getListingLogo("FACEBOOK"),
    roundedLogo: getRoundedLogo("FACEBOOK"),
    sidebarLogo: getSidebarLogo("FACEBOOK"),
    helpArticleId: ElevioArticleId.Facebook
  },
  WOOCOMMERCE: {
    name: "WooCommerce",
    integrationPage: `${INTEGRATIONS_URL}/woocommerce`,
    logo: getListingLogo("WOOCOMMERCE"),
    roundedLogo: getRoundedLogo("WOOCOMMERCE"),
    sidebarLogo: getSidebarLogo("WOOCOMMERCE"),
    helpArticleId: ElevioArticleId.WooCommerce
  },
  SKUPREME: {
    name: "Skupreme",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("SKUPREME"),
    roundedLogo: getRoundedLogo("SKUPREME"),
    sidebarLogo: getSidebarLogo("SKUPREME"),
    helpArticleId: ElevioArticleId.Skupreme
  },
  OTHER: {
    name: "Other",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("OTHER"),
    roundedLogo: getRoundedLogo("UNKNOWN"),
    sidebarLogo: getSidebarLogo("UNKNOWN")
  },
  FLIEBER: {
    name: "Flieber",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("FLIEBER"),
    roundedLogo: getRoundedLogo("FLIEBER"),
    sidebarLogo: getSidebarLogo("FLIEBER"),
    helpArticleId: ElevioArticleId.Flieber
  },
  SHEIN: {
    name: "Shein",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("SHEIN"),
    roundedLogo: getRoundedLogo("SHEIN"),
    sidebarLogo: getSidebarLogo("SHEIN")
  },
  MIRAKL: {
    name: "Mirakl",
    integrationPage: INTEGRATIONS_URL,
    logo: getListingLogo("DELIVERR"),
    roundedLogo: getRoundedLogo("DELIVERR"),
    sidebarLogo: getSidebarLogo("DELIVERR"),
    helpArticleId: ElevioArticleId.Mirakl
  },
  DELIVERRAPI: {
    name: "Deliverr API",
    integrationPage: "https://api.deliverr.com/documentation/v1/spec",
    logo: getListingLogo("DELIVERRAPI"),
    roundedLogo: getRoundedLogo("DELIVERRAPI"),
    sidebarLogo: getSidebarLogo("DELIVERRAPI")
  }
};
export const standarizeListing = pipe(camelCase, toUpper);
export const listingConfig = pipe(standarizeListing, prop(__, listingSolutionConfig), defaultTo(listingSolutionConfig.OTHER));
export const listingConfigProp = (listing, property) => pipe(listingConfig, prop(property))(listing);