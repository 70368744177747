export let IconSize;
(function (IconSize) {
  IconSize["I1"] = "I1";
  IconSize["I2"] = "I2";
  IconSize["I3"] = "I3";
  IconSize["I4"] = "I4";
  IconSize["I5"] = "I5";
  IconSize["I6"] = "I6";
  IconSize["I7"] = "I7";
  IconSize["I8"] = "I8";
})(IconSize || (IconSize = {}));
export const iconSize = {
  [IconSize.I1]: "0.5rem",
  [IconSize.I2]: "0.625rem",
  [IconSize.I3]: "0.75rem",
  [IconSize.I4]: "0.875rem",
  [IconSize.I5]: "1rem",
  [IconSize.I6]: "1.375rem",
  [IconSize.I7]: "1.625rem",
  [IconSize.I8]: "1.875rem"
};