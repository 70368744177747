import { productClient } from "Clients";
import log, { logError } from "Logger";
import { useAsyncFn } from "react-use";
import { removeBarcodesDraft } from "./BarcodeDraft";
import { getBarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import isEmpty from "lodash/isEmpty";
import { FeatureName, useFeatureOn } from "common/Split";
export function useBarcodeService() {
  const allowsCustomBarcodes = useFeatureOn(FeatureName.CustomBarcodes);
  const [barcodeSubmitResult, submitBarcodes] = useAsyncFn(async serializedBarcodes => {
    const ctx = {
      fn: "useBarcodeSubmit"
    };
    const barcodeDSKUS = Object.keys(serializedBarcodes);
    if (!barcodeDSKUS.length) {
      return true;
    }

    // clear barcodes draft from session storage since its already saved
    barcodeDSKUS.forEach(dsku => {
      removeBarcodesDraft(dsku);
    });
    try {
      log.info({
        ...ctx,
        serializedBarcodes
      }, "setting barcodes");
      const barcodeCreateRequests = [];
      if (allowsCustomBarcodes) {
        const barcodesWithFormat = {};
        const barcodesWithoutFormat = {};

        // separate serializedBarcodes into those with a format and those without, using getBarcodeFormat
        Object.entries(serializedBarcodes).forEach(([dsku, barcodes]) => {
          barcodes.forEach(barcode => {
            const format = getBarcodeFormat(barcode);
            const hasFormat = format !== undefined;
            const barcodesMap = hasFormat ? barcodesWithFormat : barcodesWithoutFormat;
            barcodesMap[dsku] = barcodesMap[dsku] ?? [];
            barcodesMap[dsku].push(barcode);
          });
        });
        if (!isEmpty(barcodesWithFormat)) {
          barcodeCreateRequests.push(productClient.createBarcodes(barcodesWithFormat));
        }
        if (!isEmpty(barcodesWithoutFormat)) {
          barcodeCreateRequests.push(productClient.createCustomBarcodes(barcodesWithoutFormat));
        }

        // TODO: how do we handle when only one out of two requests succeeds? If we try to re-submit, it will try to re-create the same barcodes
        // for the one that previously succeeded, which will fail due to duplicate barcodes
      } else {
        barcodeCreateRequests.push(productClient.createBarcodes(serializedBarcodes));
      }
      const createBarcodesResults = await Promise.all(barcodeCreateRequests);
      return createBarcodesResults.reduce((acc, curr) => ({
        ...acc,
        ...curr
      }), {});
    } catch (err) {
      logError(ctx, err);
      throw err;
    }
  }, [allowsCustomBarcodes]);
  return {
    barcodeSubmitResult,
    submitBarcodes
  };
}