import { getSellerId } from "common/user/UserSelectors";
import { Path } from "paths";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAuthCredentials, isLoginSuccessful } from "tags/facebook/model/Auth";
import { useCheckFacebookLoginStatus } from "tags/facebook/util/useCheckFacebookLoginStatus";
import { useFacebookOnLogin } from "tags/facebook/util/useFacebookOnLogin";
import { registerSeller } from "../../actions/registerSeller";
import { useAdAccountsSetting } from "./useAdAccountsSetting";
export const useAdsSettings = () => {
  const sellerId = useSelector(getSellerId);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    onSuccessfulLogin
  } = useFacebookOnLogin();
  const {
    activeAdAccountId,
    adAccountOptions,
    getAdAccounts,
    onAdAccountSelected,
    selectedAdAccount
  } = useAdAccountsSetting();
  const isSettingsComplete = useMemo(() => activeAdAccountId !== undefined, [activeAdAccountId]);
  const {
    loginStatus
  } = useCheckFacebookLoginStatus(async authResponse => {
    if (isLoginSuccessful(authResponse)) {
      const {
        userId,
        accessToken
      } = getAuthCredentials(authResponse);
      await onSuccessfulLogin(authResponse);
      await getAdAccounts(userId, accessToken);
    }
  });
  const onNextClick = () => {
    dispatch(registerSeller(sellerId));
    history.push(Path.facebookSavedAudienceWarning);
  };
  return {
    adAccountOptions,
    loginStatus,
    onAdAccountSelected,
    onNextClick,
    selectedAdAccount,
    isSettingsComplete
  };
};