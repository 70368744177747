import { logError } from "Logger";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export const defaultWarehouseClientConfig = () => {
  if (process.env.WAREHOUSE_SERVICE_URL) {
    return {
      baseURL: `${process.env.WAREHOUSE_SERVICE_URL}`
    };
  } else {
    throw new Error(`Unable to get default orders client config, environment variable FULFILLMENT_SERVICE_URL could not be found`);
  }
};
export class WarehouseClient {
  constructor({
    baseURL
  } = defaultWarehouseClientConfig()) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getWarehouses(ids) {
    try {
      return await Promise.all(ids.map(async id =>
      // Disabled this here since awaiting would prevent parallelization of calls
      // eslint-disable-next-line @typescript-eslint/return-await
      this.apiClient.get({
        url: `/PUBLIC_DATA/v1/warehouses/${id}`,
        authentication: isAuthenticated
      })));
    } catch (err) {
      logError({
        fn: "warehouseClient.getWarehouses",
        ids
      }, err);
      return undefined;
    }
  }
  async getWarehouse(id) {
    try {
      return await this.apiClient.get({
        url: `PUBLIC_DATA/v1/warehouses/${id}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      logError({
        fn: "warehouseClient.getWarehouse",
        id
      }, err);
      return undefined;
    }
  }
}