import { createReducer } from "common/ReduxUtils";
import { ReplenishmentNonComplianceActionTypes } from "./ReplenishmentNonComplianceAction";
export const replenishmentNonComplianceInitialState = {
  replenishmentNonCompliantOrdersCount: 0,
  wholesaleNonCompliantOrdersCount: 0
};
const reducers = {
  [ReplenishmentNonComplianceActionTypes.REPLENISHMENT_SET_NON_COMPLIANT_ORDERS_COUNT]: (state, action) => ({
    replenishmentNonCompliantOrdersCount: action.unresolvedNonCompliancesCount.replenishmentOrderCount,
    wholesaleNonCompliantOrdersCount: action.unresolvedNonCompliancesCount.wholesaleOrderCount
  })
};
export const replenishmentNonComplianceReducer = createReducer(replenishmentNonComplianceInitialState, reducers);