import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import log from "Logger";
import { toast } from "common/components/ui";
import { useIntl, defineMessages } from "react-intl";
import { cancelReturnOrder, resetReturnCancel } from "../../cancel/store/ReturnsCancelAction";
import { useReturnsCancelState } from "./useReturnsCancelState";
export const returnCancelMessages = defineMessages({
  returnCancelSuccess: {
    id: "returns.returnsDetails.useCancelReturn.success",
    defaultMessage: "Return canceled"
  },
  returnCancelFailure: {
    id: "returns.returnsDetails.useCancelReturn.failure",
    defaultMessage: "Unable to cancel"
  }
});
export const useReturnsCancel = () => {
  const [showReturnsCancelModal, setShowReturnsCancelModal] = useState(false);
  const {
    error,
    returnCancelSuccess,
    disableCancel
  } = useReturnsCancelState();
  const {
    returnsDetail
  } = useSelector(state => state.returnsDetail);
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    if (returnCancelSuccess) {
      toast.success(formatMessage(returnCancelMessages.returnCancelSuccess));
    }
    if (error) {
      toast.error(formatMessage(returnCancelMessages.returnCancelFailure));
    }
  }, [error, returnCancelSuccess, formatMessage]);
  const handleCancel = () => {
    const ctx = {
      fn: "cancelReturn",
      orderId: returnsDetail.id
    };
    log.info(ctx, "cancelling return");
    hideReturnsCancelModal();
    dispatch(resetReturnCancel());
    dispatch(cancelReturnOrder(returnsDetail.id.toString()));
  };
  const showModal = () => {
    setShowReturnsCancelModal(true);
  };
  const hideReturnsCancelModal = () => {
    setShowReturnsCancelModal(false);
  };
  return {
    showReturnsCancelModal,
    handleCancel,
    showModal,
    hideReturnsCancelModal,
    disableCancel
  };
};