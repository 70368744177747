import { LoadingActionTypes } from "common/components/WithLoader/LoadingActions";
import { createReducer } from "common/ReduxUtils";
export const loadingInitialState = {
  loaders: []
};
const reducers = {
  [LoadingActionTypes.ADD_LOADER]: (state, action) => ({
    ...state,
    loaders: [...state.loaders, action.name]
  }),
  [LoadingActionTypes.CLEAR_LOADER]: (state, action) => ({
    ...state,
    loaders: state.loaders.filter(loader => loader !== action.name)
  })
};
export const loadingReducer = createReducer(loadingInitialState, reducers);