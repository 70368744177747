import { isEmpty, reduce, uniq, values } from "lodash";
import { getDefaultPackFromProduct } from "./getDefaultPackFromProduct";

// maps and aggregates order items with a mix of product and pack skus to only pack skus
export const mapWholesaleItemsToPackSkus = (orderItems, unifiedProducts) => {
  if (isEmpty(orderItems)) {
    return [];
  }
  const dskus = uniq(orderItems?.map(({
    dsku
  }) => dsku) ?? []);
  const productSkuToDefaultPackSku = values(dskus).reduce((agg, dsku) => {
    const product = unifiedProducts[dsku];
    if (product && !product.packOf) {
      agg[product.dsku] = getDefaultPackFromProduct(product)?.dsku;
    }
    return agg;
  }, {});
  const orderItemsWithPackSkus = orderItems?.map(item => ({
    ...item,
    dsku: productSkuToDefaultPackSku[item.dsku] ?? item.dsku,
    qty: item.qty
  })) ?? [];
  return values(reduce(orderItemsWithPackSkus, (orderItemsByPackSkus, item) => {
    orderItemsByPackSkus[item.dsku] = {
      dsku: item.dsku,
      qty: item.qty + (orderItemsByPackSkus[item.dsku]?.qty ?? 0),
      expectedQty: item.expectedQty ? item.expectedQty + (orderItemsByPackSkus[item.dsku]?.expectedQty ?? 0) : undefined,
      fulfilledQty: item.fulfilledQty ? item.fulfilledQty + (orderItemsByPackSkus[item.dsku]?.fulfilledQty ?? 0) : undefined
    };
    return orderItemsByPackSkus;
  }, {}));
};