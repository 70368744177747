import produce from "immer";
import { createReducer } from "common/ReduxUtils";
import { FreightOrderActionTypes } from "../actions/FreightOrderActionTypes";
import { FreightBookingActionTypes } from "freight/store/freightBooking/actions/FreightBookingActionTypes";
const reducer = {
  [FreightOrderActionTypes.SET_FREIGHT_BOOKING_ID]: (_, action) => action.id,
  [FreightBookingActionTypes.RESET_FREIGHT_BOOKING_STATE]: state => {
    return produce(state, draft => {
      draft = null;
    });
  }
};
export const freightOrderIdReducer = createReducer(null, reducer);