/**
 * IMPORTANT: Changing any of the values in this file will affect our dashboards
 * You can ADD events or context values
 */

export const EVENT_PREFIX = "smb_create_booking";

/**
 * This context should hold values persisted in Redux only and they should not be nested objects
 */

/**
 * The following utilities are used to track the start and end of a function
 * The scope ensures only expected functions are tracked and prefixes them for consistency
 * Do not call trackSmbBookingCreationEvent and logStart with the same fn name or it will log twice
 * should use logStart AND logSuccess AND logError
 **/
export const SmbBookingCreationTimedEvent = {
  CreateBooking: `${EVENT_PREFIX}.timed.create_booking`,
  UpdateBooking: `${EVENT_PREFIX}.timed.update_booking`,
  SubmitBooking: `${EVENT_PREFIX}.timed.submit_booking`,
  LoadBooking: `${EVENT_PREFIX}.timed.load_booking`,
  FetchQuote: `${EVENT_PREFIX}.timed.fetch_booking_quote`
};

/**
 * Most actions are tracked in DataDog automatically and do not need custom triggers
 * For tracked click events, you can provide custom names to clickable elements
 * https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/
 */