import { emptyAddress } from "common/AddressUtils";
export const pickupDetailsInitialState = {
  address: {
    ...emptyAddress,
    country: ""
  },
  cargoReadyDate: null,
  facilityOperatingHours: undefined,
  pickupReferenceNumber: null,
  customerReferenceNumber: null,
  pickupInstructions: null,
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneExt: ""
  },
  isAppointmentRequired: undefined
};