export let Marketplace;
(function (Marketplace) {
  Marketplace["DELIVERR"] = "DELIVERR";
  Marketplace["AMAZON"] = "AMAZON";
  Marketplace["GOOGLE"] = "GOOGLE";
  Marketplace["SHOPIFY"] = "SHOPIFY";
  Marketplace["WALMART"] = "WALMART";
  Marketplace["WISH"] = "WISH";
  Marketplace["EBAY"] = "EBAY";
  Marketplace["FACEBOOK"] = "FACEBOOK";
  Marketplace["BIGCOMMERCE"] = "BIGCOMMERCE";
  Marketplace["WOOCOMMERCE"] = "WOOCOMMERCE";
  Marketplace["ETSY"] = "ETSY";
  Marketplace["MAGENTO"] = "MAGENTO";
  Marketplace["TARGET"] = "TARGET";
  Marketplace["NEWEGG"] = "NEWEGG";
  Marketplace["INSTAGRAM"] = "INSTAGRAM";
  Marketplace["ARTEZA"] = "ARTEZA";
  Marketplace["OVERSTOCK"] = "OVERSTOCK";
  Marketplace["JCPENNEY"] = "JCPENNEY";
  Marketplace["JANE"] = "JANE";
  Marketplace["AIRSHOP"] = "AIRSHOP";
  Marketplace["KROGER"] = "KROGER";
  Marketplace["SKUPREME"] = "SKUPREME";
  Marketplace["SHEIN"] = "SHEIN";
  Marketplace["UNKNOWN"] = "UNKNOWN";
})(Marketplace || (Marketplace = {}));