import { SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT } from "inbounds/ShipmentStatus";
import { createSelector } from "reselect";
export const getShippingPlanType = state => state.inboundList.searchFilters.shippingPlanType;
export const getDiscrepancyType = state => state.inboundList.searchFilters.discrepancyStatus;
export const getShipmentStatuses = createSelector(state => state.inboundList.searchFilters.shipmentStatuses, shipmentStatuses => shipmentStatuses.map(status => ({
  value: status,
  label: SHIPMENT_STATUS_GROUPING_DISPLAY_TEXT[status]
})));
export const selectActionNeededShipments = createSelector(state => state.inboundList.actionNeededShipments, shipments => shipments == null ? [] : Array.from(shipments));
export const selectTotalSearchHits = createSelector(state => state.inboundList.searchInfo?.totalHits, hits => hits ?? 0);
export const getResultsPerPage = state => state.user.resultsPerPage.inboundList;
export const getSearchFilters = state => state.inboundList.searchFilters;
export const getFiltersBladeOpen = state => state.inboundList.filtersBladeOpen;