import { batch } from "react-redux";
import { wholesaleClient } from "../../Clients";
import { uploadFile } from "../../transfers/utils/uploadFile";
import { WholesaleErrorType } from "./utils/WholesaleErrorTypes";
import { useIntl } from "react-intl";
import { shipmentCreateLabels } from "./shipmentCreate.labels";
export const sharedCreateUploadWholesaleLabelFile = ({
  selector,
  setError,
  setFilename,
  setUploading
}) => (file, attachmentType) => async (dispatch, getState) => {
  const {
    wholesaleOrder
  } = selector(getState());
  let isDuplicateLabelsError = false;
  batch(() => {
    dispatch(setUploading(attachmentType, true));
    dispatch(setError(WholesaleErrorType.NONE));
  });
  try {
    if (!wholesaleOrder?.shipments) {
      dispatch(setError(WholesaleErrorType.UNTYPED));
    } else {
      const attachmentMetaDate = {
        fileName: file.name,
        fileSize: file.size
      };
      let url;
      try {
        url = (await wholesaleClient.requestLabelUpload(wholesaleOrder.id, wholesaleOrder?.shipments[0].id, attachmentType, undefined, attachmentMetaDate)).data.url;
      } catch (e) {
        if (e.response.status === 400) {
          dispatch(setError(WholesaleErrorType.DUPLICATE_LABELS));
          isDuplicateLabelsError = true;
        }
        throw e;
      }
      await uploadFile(url, file);
      dispatch(setFilename(attachmentType, file.name));
    }
  } catch (e) {
    if (!isDuplicateLabelsError) {
      dispatch(setError(WholesaleErrorType.UNTYPED));
    }
  } finally {
    dispatch(setUploading(attachmentType, false));
  }
};
export const useCheckDuplicationLabelsError = () => {
  const {
    formatMessage
  } = useIntl();
  return errorType => {
    const msg = checkDuplicationLabelsError(errorType);
    return msg && formatMessage(msg);
  };
};
export const checkDuplicationLabelsError = errorType => {
  return errorType === WholesaleErrorType.DUPLICATE_LABELS ? shipmentCreateLabels.steps.labels.duplicateLabelsError : undefined;
};