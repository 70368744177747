export const verifyProductProps = dimensions => dimensions.reduce((acc, dimension) => {
  const {
    dsku,
    length,
    width,
    height,
    weight,
    hazmatIds,
    minimumPackagingType,
    qty = 1
  } = dimension;
  if (!length || !width || !weight || !height) {
    acc = undefined;
  }
  const item = {
    dsku,
    length,
    width,
    height,
    weight,
    hazmatIds,
    minimumPackagingType,
    qty
  };
  acc?.push(item);
  return acc;
}, []);