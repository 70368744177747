import { Channel, ExternalDestinationType, WholesaleOrderStatus, WholesaleShipmentStatus } from "@deliverr/wholesale-client";
import { SortDir } from "common/models/SortDir";
import { batch } from "react-redux";
import difference from "lodash/difference";
import { selectWholesaleList } from "../store";
import log from "Logger";
import { createActionCreator } from "common/ReduxUtils";
import { WholesaleSourceType } from "../store/WholesaleListState";
import { WholesaleListActionTypes } from "./WholesaleListActionTypes";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { ApiError } from "@deliverr/commons-objects";
import { algoliaWholesaleShipmentsService } from "../algoliaWholesaleOrdersService";
import { selectWholesaleListPageSize } from "../store/selectWholesaleListPageSize";
import { FeatureName, getFeatureSelector } from "common/Split";
import { TransferListAlgoliaField } from "transfers/list/actions/TransferListAlgoliaField";
import { getShowAdminFeatures } from "common/user/UserSelectors";
import { WarehouseOrderType } from "@deliverr/business-types";
import { createReplenishmentTabFilter } from "transfers/list/actions/getTransferShipments";
const getListItemsSuccessAction = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_GET_SUCCESS, "listData");
const wholesaleListError = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_GET_ERROR, "isError");
export const wholesaleListChangePageNum = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_PAGE_NUM, "pageNum");
export const setWholesaleListTabFilter = tabFilter => async dispatch => batch(() => {
  dispatch({
    type: WholesaleListActionTypes.WHOLESALE_LIST_SET_TAB_FILTER,
    tabFilter
  });
  dispatch(wholesaleListChangePageNum(0));
});
export const wholesalesListChangeSearchTerm = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_SEARCH_TERM, "searchTerm");
export const wholesaleListChangeStatusFilter = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_STATUS_SEARCH_FILTERS, "wholesaleListStatuses");
export const wholesaleListChangeSourceTypeFilter = createActionCreator(WholesaleListActionTypes.WHOLESALE_LIST_CHANGE_SOURCE_CHANNEL_SEARCH_FILTERS, "souceChannel");
const createWholesaleListFilterClause = (searchFilters, showAdminFeatures) => {
  const {
    sourceChannel,
    wholesaleListStatuses,
    tabFilter
  } = searchFilters;
  const filters = [];
  filters.push(createWholesaleSourceChannelFilter(sourceChannel));
  filters.push(createWholesaleShipmentStatusFilter(wholesaleListStatuses));
  filters.push(createReplenishmentTabFilter(tabFilter));
  if (!showAdminFeatures) {
    filters.push(`NOT ${TransferListAlgoliaField.CHANNEL}:${Channel.SPS_TEST}`);
  }
  return difference(filters, [null]);
};
const createWholesaleSourceChannelFilter = sourceChannel => {
  switch (sourceChannel) {
    case WholesaleSourceType.SPS:
      return `(${TransferListAlgoliaField.CHANNEL}:${Channel.SPS} OR ${TransferListAlgoliaField.CHANNEL}:${Channel.SPS_TEST})`;
    case WholesaleSourceType.OTHERS:
      return `(NOT ${TransferListAlgoliaField.CHANNEL}:${Channel.SPS} AND NOT ${TransferListAlgoliaField.CHANNEL}:${Channel.SPS_TEST})`;
  }
  return null;
};
const createWholesaleShipmentStatusFilter = shipmentStatus => {
  const wholesaleShipmentStatuses = shipmentStatus.filter(status => WholesaleShipmentStatus[status] ?? WholesaleOrderStatus[status]).map(status => `${TransferListAlgoliaField.STATUS}:${status}`);
  return wholesaleShipmentStatuses.length ? `(${wholesaleShipmentStatuses.join(" OR ")})` : null;
};
export const getWholesaleOrders = () => async (dispatch, getState) => {
  batch(() => {
    dispatch(addLoader(WholesaleListActionTypes.WHOLESALE_LIST_GET));
    dispatch(wholesaleListError(false));
  });
  const showAdminFeatures = getShowAdminFeatures(getState());
  const ctx = {
    fn: "getOrders"
  };
  try {
    const {
      searchTerm,
      pageNum,
      searchFilters
    } = selectWholesaleList(getState());
    const pageSize = selectWholesaleListPageSize(getState());
    const isWholesaleOrderHeldStateOn = getFeatureSelector(FeatureName.WholesaleOrderHeldState)(getState());
    const algoliaResult = await algoliaWholesaleShipmentsService.search(searchTerm, pageNum, {
      fieldName: "updatedAt",
      direction: SortDir.DESC
    }, [!isWholesaleOrderHeldStateOn ? `NOT orderType:${WarehouseOrderType.TRANSFER} AND NOT externalDestinationType:${ExternalDestinationType.FBA} AND NOT externalDestinationType:${ExternalDestinationType.WALMART} AND NOT externalDestinationType:${ExternalDestinationType.TRANSFER_ECOM} AND NOT status:${WholesaleOrderStatus.DRAFT} AND NOT status:ERROR` : `NOT orderType:${WarehouseOrderType.TRANSFER} AND NOT externalDestinationType:${ExternalDestinationType.FBA} AND NOT externalDestinationType:${ExternalDestinationType.WALMART} AND NOT externalDestinationType:${ExternalDestinationType.TRANSFER_ECOM} AND NOT status:ERROR`, ...createWholesaleListFilterClause(searchFilters, showAdminFeatures)], undefined, pageSize);
    const shipments = algoliaResult.hits;
    const nbPages = algoliaResult.response.nbPages;
    dispatch(getListItemsSuccessAction({
      shipments,
      nbPages,
      pageNum
    }));
  } catch (err) {
    if (err.message !== ApiError.UNKNOWN_ERROR) {
      dispatch(wholesaleListError(true));
      log.error({
        ctx,
        err
      }, "error getting shipments list");
    }
  } finally {
    dispatch(clearLoader(WholesaleListActionTypes.WHOLESALE_LIST_GET));
  }
};