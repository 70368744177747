import { ApiError, DeliverrError, HttpErrorCodes } from "@deliverr/commons-objects";
export function mapError(err, validErrorCodes = []) {
  if (!err.response) {
    return new DeliverrError({
      code: ApiError.UNKNOWN_ERROR,
      payload: err
    });
  }
  if (validErrorCodes.includes(err.response.data)) {
    return new DeliverrError({
      code: err.response.data
    });
  }
  if (validErrorCodes.includes(err.response.data?.code)) {
    return new DeliverrError({
      code: err.response.data.code
    });
  }
  const {
    status,
    data
  } = err.response;
  const code = status >= 400 ? HttpErrorCodes[status] || ApiError.UNKNOWN_ERROR : ApiError.UNKNOWN_ERROR;
  return new DeliverrError({
    code,
    payload: {
      status,
      data
    }
  });
}