import { selectCreateShipmentMeta, selectCurrentStep } from "../selectors";
import { selectIpbDraftsFeatureOn } from "../selectors/selectIpbDraftsFeatureOn";
import { createBooking } from "./createBooking";
import { updateBooking } from "./updateBooking";
import { FeatureName, getFeatureSelector } from "common/Split";
import { createBookingV2 } from "./createBookingV2";
import { ShipmentSteps } from "inbounds/createShipment/CreateShipmentTypes";
import { updateBookingSupplier } from "./updateBookingSupplier";
import { updateBookingDestination } from "./updateBookingDestination";
import { updateBookingItem } from "./updateBookingItem";
import { updateBookingCheckout } from "./updateBookingCheckout";
import { updateBookingOrigin } from "./updateBookingOrigin";
import { selectCreatedByV1 } from "../selectors/selectCreatedByV1";
export const saveBooking = shouldSubmit => async (dispatch, getState) => {
  const state = getState();
  const isIpbDraftsFeatureOn = selectIpbDraftsFeatureOn(state);
  const currentStep = selectCurrentStep(state);
  const {
    shippingPlanId
  } = selectCreateShipmentMeta(state);
  const shouldUpdateBooking = isIpbDraftsFeatureOn && !!shippingPlanId;
  const isIpbBookingV2FeatureOn = getFeatureSelector(FeatureName.SmbIpbBookingV2)(state);
  const isCreatedByV1 = selectCreatedByV1(state);
  const shouldUseBookingV2 = isIpbBookingV2FeatureOn && !isCreatedByV1;
  let response;
  if (shouldUpdateBooking) {
    if (!shouldUseBookingV2) {
      response = await dispatch(updateBooking(shippingPlanId, shouldSubmit));
    } else {
      switch (currentStep) {
        case ShipmentSteps.OriginSearch:
          response = await dispatch(updateBookingOrigin(shippingPlanId));
          break;
        case ShipmentSteps.SupplierSelect:
          response = await dispatch(updateBookingSupplier(shippingPlanId));
          break;
        case ShipmentSteps.DestinationSelect:
        case ShipmentSteps.ReserveStorageFcSelect:
        case ShipmentSteps.DestinationSearch:
          response = await dispatch(updateBookingDestination(shippingPlanId));
          break;
        case ShipmentSteps.CargoDetails:
          response = await dispatch(updateBookingItem(shippingPlanId));
          break;
        case ShipmentSteps.PriceView:
          response = await dispatch(updateBookingCheckout(shippingPlanId, shouldSubmit));
          break;
        default:
          response = await dispatch(updateBooking(shippingPlanId, shouldSubmit));
          break;
      }
    }
    return response;
  }
  if (!shouldUseBookingV2) {
    response = await dispatch(createBooking(shouldSubmit));
  } else {
    response = await dispatch(createBookingV2());
  }
  return response;
};