import { default as search } from "algoliasearch";
import helper from "algoliasearch-helper";
import { fromPairs, isArray, isEmpty, isPlainObject, keyBy, memoize } from "lodash/fp";
import { isProdEnv } from "common/Config";
import { retry } from "common/utils/Retry";
import store from "store";
import { algoliaSearchKeyManager } from "./AlgoliaSearchKeyManager";
import { getSortByIndex } from "./SortByIndex";
const algoliaRetryCalls = 3;
const getNestedValues = (obj, propName) => {
  if (Object.prototype.hasOwnProperty.call(obj, propName)) {
    return obj[propName];
  }
  if (isPlainObject(obj)) {
    return fromPairs(Object.entries(obj).map(([key, val]) => [key, getNestedValues(val, propName)]));
  } else if (isArray(obj)) {
    return obj.map(arrayElement => {
      return getNestedValues(arrayElement, propName);
    });
  }
};
class AlgoliaService {
  static get = memoize.convert({
    fixed: false
  })(cfg => new AlgoliaService(cfg), JSON.stringify);
  highlightMatches = false;
  defaultSearchConfig = {
    hitsPerPage: 5
  };
  constructor(config) {
    this.config = config;
    this.highlightMatches = config.highlightMatches ?? false;
    this.baseIndex = config.indexName;
  }
  clearCache() {
    this.helper?.clearCache();
    return this;
  }
  async search(query, pageNum = 0, sortBy, filters = [], numericFilter, resultsSize, restrictSearchableAttributes, attributesToRetrieve) {
    let targetIndex = this.baseIndex;
    const credentials = await this.getCredentials();
    if (sortBy) {
      targetIndex = getSortByIndex(sortBy, targetIndex);
    }
    if (!credentials.searchKey) {
      throw new Error(`Search key missing for ${credentials.sellerId}`);
    }
    this.createClient(credentials.searchKey, credentials);
    if (!this.helper) {
      throw new Error(`Search client not created for ${credentials.sellerId}`);
    }
    if (numericFilter) {
      const [attribute, operator, value] = numericFilter;
      if (!operator || !value) {
        this.helper.removeNumericRefinement(attribute);
      } else {
        this.helper.addNumericRefinement(attribute, operator, value);
      }
    }
    const searchHelper = this.helper.setIndex(targetIndex).setQuery(query).setQueryParameter("filters", filters.join(" AND ")).setPage(pageNum);
    return await retry(async () => await this.onSearchResults(await searchHelper.searchOnce({
      attributesToRetrieve,
      restrictSearchableAttributes,
      hitsPerPage: resultsSize
    })), {
      retries: algoliaRetryCalls
    });
  }
  async searchByIds(ids, query = "", idField = "id", resultsSize) {
    const filter = isEmpty(ids) ? "" : `(${ids.map(id => typeof id === "number" ? `${idField}=${id}` : `${idField}:"${id}"`).join(" OR ")})`;
    return await this.search(query, 0, undefined, [filter], undefined, resultsSize);
  }
  processResults(hits) {
    return hits.map(hit => ({
      ...hit,
      raw: {
        ...hit
      }
    }));
  }
  async onSearchResults(response) {
    const {
      content,
      state
    } = response;
    // Important to clone hits here because Algolia will cache objects
    let hits = this.processResults(content.hits);
    if (state.query && this.highlightMatches) {
      // Copy highlighted HTML out into primary values
      hits = hits.filter(hit => hit._highlightResult).map(hit => {
        return {
          ...hit,
          ...getNestedValues(hit._highlightResult, "value")
        };
      });
    }
    return await Promise.resolve({
      response: content,
      hits,
      params: state
    });
  }
  async ensureClientInstantiated() {
    const credentials = await this.getCredentials();
    const isCurrentSearchKeyValid = this.currentSearchKey === credentials.searchKey;
    if (this.client && isCurrentSearchKeyValid) {
      return;
    }
    if (!credentials.searchKey) {
      throw new Error(`Search key missing for ${credentials.sellerId}`);
    }
    this.createClient(credentials.searchKey, credentials);
  }
  async getRecordByObjectId(objectId) {
    await this.ensureClientInstantiated();
    return await this.client.initIndex(this.config.indexName).getObject(objectId);
  }
  async getAllRecordsByObjectIds(objectIds) {
    await this.ensureClientInstantiated();
    const {
      results
    } = await this.client.initIndex(this.config.indexName).getObjects(objectIds);
    return keyBy("objectID", results);
  }
  async getCredentials() {
    const {
      user: {
        sellerId
      }
    } = store.getState();
    const envPrefix = isProdEnv ? "prod" : "staging";
    const algoliaUser = `${envPrefix}-${sellerId}`;
    const searchKey = await algoliaSearchKeyManager.getAlgoliaSearchKey(sellerId);
    return {
      sellerId,
      searchKey,
      algoliaUser
    };
  }
  createClient(searchKey, credentials) {
    const headers = {
      "X-Algolia-User-ID": credentials.algoliaUser
    };
    const appId = process.env.ALGOLIA_MCM_APP_ID;
    this.client = search(appId ?? "MISSING_APP_ID", searchKey ?? "MISSING_API_KEY", {
      headers
    });
    this.currentSearchKey = searchKey;
    this.helper = helper(this.client, this.config.indexName, {
      ...this.defaultSearchConfig,
      ...this.config.searchConfig
    });
  }
}
export default AlgoliaService;