import styled from "@emotion/styled";
import { BorderIcon } from "../../common/components/ui";
export const StyledCloseButton = styled(BorderIcon)`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 0;
  appearance: none;
  border: 0;
  background: none;

  :focus {
    outline: 0;
    box-shadow: ${({
  theme
}) => theme.input.focus.BLUE};
  }
`;