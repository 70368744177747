import { defineMessages } from "react-intl";
export const UNRESOLVED_INVALID_ITEM_RESOLUTION = "UNRESOLVED_INVALID_ITEM_RESOLUTION";
export const outOfStockOrderItemLabel = {
  step: {
    ...defineMessages({
      title: {
        id: "transfers.problems.out_of_stock.title",
        defaultMessage: "Resolve out of stock items"
      },
      subtitle: {
        id: "transfers.problems.out_of_stock.subtitle",
        defaultMessage: "Remove out of stock items to move to next step"
      }
    })
  },
  status: {
    ...defineMessages({
      outOfStock: {
        id: "transfers.outOfStockOrderItem.outOfStockStatus",
        defaultMessage: "OUT OF STOCK"
      },
      lowStock: {
        id: "transfers.outOfStockOrderItem.partialStockStatus",
        defaultMessage: "LOW STOCK"
      }
    })
  }
};