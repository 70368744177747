import { createShipmentInitialFormState as defaults } from "inbounds/createShipment/store/CreateShipmentState";
export const getWarehouseSelectState = booking => {
  return {
    reserveStorage: {
      address: booking.to?.reserveStorageWarehouseAddress ?? defaults.reserveStorage.address,
      warehouseId: booking.to?.warehouseId ?? null
    },
    crossdockWarehouse: {
      address: booking.to?.crossDockWarehouseAddress ?? defaults.crossdockWarehouse.address,
      warehouseId: booking.to?.warehouseId ?? null
    }
  };
};