export const hasProfileInfo = response => response.status === 200;
export const getProfileInfo = ({
  name,
  picture: {
    data: {
      url: picture
    }
  }
}) => ({
  name,
  picture
});