import { getResponsiveStyles } from "../shared";
import { kebabCase } from "lodash/fp";
export const isSpacingProp = {
  margin: true,
  marginTop: true,
  marginBottom: true,
  marginLeft: true,
  marginRight: true,
  padding: true,
  paddingTop: true,
  paddingBottom: true,
  paddingLeft: true,
  paddingRight: true
};
export const getBoxStylesHelper = theme => gap => gap ? theme.spacing[gap] : "0";
export const getBoxStyles = (theme, styles) => Object.entries(styles).filter(([_, value]) => value !== undefined).map(([styleProp, value]) => getResponsiveStyles(kebabCase(styleProp), value, isSpacingProp[styleProp] ? getBoxStylesHelper(theme) : undefined)).join(" ");