import { DestinationType, PrepJobResponseStatus, PrepJobSourceType, PrepType } from "@deliverr/prep-service-client";
import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { PrepDetailsActionTypes } from "prep/types/details/PrepDetailsActionTypes";
export const prepDetailsInitialState = {
  details: {
    dpId: "",
    status: PrepJobResponseStatus.INITIALIZED,
    sellerId: "",
    sourceType: PrepJobSourceType.EXTERNAL_TASK,
    sourceId: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    prepTypes: [PrepType.BOX_CONTENT_LABELS],
    tasks: [],
    destinationType: DestinationType.SL_NETWORK
  }
};
export const reducers = {
  ...handleSimpleReducerUpdates([PrepDetailsActionTypes.SET_PREP_DETAILS, PrepDetailsActionTypes.SET_SOURCE_PLAN_DETAILS, PrepDetailsActionTypes.SET_SOURCE_ORDER_DETAILS, PrepDetailsActionTypes.SET_SELECTED_JOB_DETAILS, PrepDetailsActionTypes.SET_PRICING_ERROR]),
  [PrepDetailsActionTypes.RESET_DETAILS]: () => prepDetailsInitialState,
  [PrepDetailsActionTypes.RESET_DETAILS_WITH_ARCHIVED]: () => ({
    ...prepDetailsInitialState,
    isArchivedJob: true
  })
};
export const prepDetailsReducer = createReducer(prepDetailsInitialState, reducers);