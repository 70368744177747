import { FeatureName, getFeatureSelector } from "common/Split";
import { getProductCasePacks } from "../../selectProductCasePacks/actions/getProductCasePacks";
import { addStorageProduct } from "../../selectProducts/SelectStorageProductsActions";
export const addStorageProductWithCaseDetails = (dsku, product) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isStorageMultiCasePackOn = !!getFeatureSelector(FeatureName.StorageMultiCasePack)(state);
    dispatch(addStorageProduct(dsku, product));
    return dispatch(getProductCasePacks(dsku, isStorageMultiCasePackOn));
  };
};