import log from "Logger";
import store from "store";
import { toast } from "common/components/ui";
import { updateOrganizationClaims } from "organization/OrganizationActions";
import { retry } from "common/utils/Retry";
// registers elements generated after page load w/ chargebee
// so we can open checkout from react
export const registerChargebee = () => {
  log.info({
    fn: "registerChargebee"
  }, "registering chargebee");
  window.Chargebee.registerAgain();
};
export const setCustomerDetails = () => {
  log.info({
    fn: "setCustomerDetails"
  }, "setting customer details");
  const {
    user: {
      sellerId
    }
  } = store.getState();
  const cart = window.Chargebee.getInstance().getCart();
  cart.setCustomer({
    id: sellerId
  });
};
export const setCheckoutCallbacks = (onSuccess, onClose) => dispatch => {
  log.info({
    fn: "setCheckoutCallbacks"
  }, "setting chargebee checkout callbacks");
  window.Chargebee.getInstance().setCheckoutCallbacks(() => ({
    success: async () => {
      const ctx = {
        fn: "onCheckoutSuccess"
      };
      log.info(ctx, "checkout successful");
      try {
        await retry(async () => dispatch(updateOrganizationClaims({
          hasChargebeeAccount: true
        })), {
          retries: 5
        });
        log.info(ctx, "successfully updated user hasChargebeeAccount");
        await onSuccess();
      } catch (err) {
        toast.error("Error to setup payment, please try again!");
        log.error({
          ...ctx,
          err
        }, "error updating user hasChargebeeAccount");
      }
    },
    error(err) {
      const ctx = {
        fn: "onCheckoutError"
      };
      log.error({
        ...ctx,
        err
      }, "error on chargeebee checkout");
    },
    close: onClose
  }));
};