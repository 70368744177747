export let InboundActionTypes;
(function (InboundActionTypes) {
  InboundActionTypes["ADD_PRODUCTS"] = "ADD_PRODUCTS";
  InboundActionTypes["REMOVE_PRODUCT"] = "REMOVE_PRODUCT";
  InboundActionTypes["UPDATE_QTY"] = "UPDATE_QTY";
  InboundActionTypes["UPDATE_QTY_PER_CASE"] = "UPDATE_QTY_PER_CASE";
  InboundActionTypes["CREATE_SHIPPING_PLAN"] = "CREATE_SHIPPING_PLAN";
  InboundActionTypes["SAVE_NEW_SHIPPING_PLAN_SUCCESS"] = "SAVE_NEW_SHIPPING_PLAN_SUCCESS";
  InboundActionTypes["ADD_BOX_SIZE"] = "ADD_BOX_SIZE";
  InboundActionTypes["REMOVE_BOX_SIZE"] = "REMOVE_BOX_SIZE";
  InboundActionTypes["ADD_PACKAGES"] = "ADD_PACKAGES";
  InboundActionTypes["UPDATE_BARCODES"] = "UPDATE_BARCODES";
  InboundActionTypes["SET_IS_UPLOADING_PACKAGES"] = "SET_IS_UPLOADING_PACKAGES";
  InboundActionTypes["SET_PACKAGE_COUNT"] = "SET_PACKAGE_COUNT";
  InboundActionTypes["SET_BOX_QTY"] = "SET_BOX_QTY";
  InboundActionTypes["SET_BOX_SIZE"] = "SET_BOX_SIZE";
  InboundActionTypes["SET_BOX_DIMENSIONS"] = "SET_BOX_DIMENSIONS";
  InboundActionTypes["SET_BOX_WEIGHT"] = "SET_BOX_WEIGHT";
  InboundActionTypes["SET_NUM_BOXES"] = "SET_NUM_BOXES";
  InboundActionTypes["ACCEPT_CHARGES_START"] = "ACCEPT_CHARGES_START";
  InboundActionTypes["ACCEPT_CHARGES_SUCCESS"] = "ACCEPT_CHARGES_SUCCESS";
  InboundActionTypes["CONFIRM_PRICING_AND_FEES"] = "CONFIRM_PRICING_AND_FEES";
  InboundActionTypes["SET_SHIPPING_METHOD"] = "SET_SHIPPING_METHOD";
  InboundActionTypes["CREATE_SHIPMENTS_SUCCESS"] = "CREATE_SHIPMENTS_SUCCESS";
  InboundActionTypes["COMPLETE_SHIPMENT"] = "COMPLETE_SHIPMENT";
  InboundActionTypes["SET_USE_CASE_PACKS"] = "SET_USE_CASE_PACKS";
  InboundActionTypes["UPDATE_NUMBER_OF_CASES"] = "UPDATE_NUMBER_OF_CASES";
  InboundActionTypes["ADD_ONE_SKU_BOX_CONFIG"] = "ADD_ONE_SKU_BOX_CONFIG";
  InboundActionTypes["REMOVE_PACKAGE"] = "REMOVE_PACKAGE";
  InboundActionTypes["SET_NUMBER_OF_BOXES"] = "SET_NUMBER_OF_BOXES";
  InboundActionTypes["DUPLICATE_PACKAGE"] = "DUPLICATE_PACKAGE";
  InboundActionTypes["UPDATE_PRODUCT_CASE_QTY_SUCCESS"] = "UPDATE_PRODUCT_CASE_QTY_SUCCESS";
  InboundActionTypes["UPDATE_PRODUCT_CASE_QTY_ERROR"] = "UPDATE_PRODUCT_CASE_QTY_ERROR";
  InboundActionTypes["UPDATE_TRACKING_NUMBER"] = "UPDATE_TRACKING_NUMBER";
  InboundActionTypes["SET_IS_FIRST_INBOUND"] = "SET_IS_FIRST_INBOUND";
  InboundActionTypes["SAVE_OLD_INBOUND_STATE"] = "SAVE_OLD_INBOUND_STATE";
  InboundActionTypes["SET_IS_REDISTRIBUTIONS"] = "SET_IS_REDISTRIBUTIONS";
  InboundActionTypes["SET_DISPERSAL_METHOD"] = "SET_DISPERSAL_METHOD";
  InboundActionTypes["UPDATE_PLAN"] = "UPDATE_PLAN";
  InboundActionTypes["UPDATE_PRODUCT_CACHE"] = "UPDATE_PRODUCT_CACHE";
  InboundActionTypes["LOAD_SHIPMENTS"] = "LOAD_SHIPMENTS";
  InboundActionTypes["LOAD_RECEIVING_INFO"] = "LOAD_RECEIVING_INFO";
  InboundActionTypes["SHOW_MAX_UNITS_EXCEEDED"] = "SHOW_MAX_UNITS_EXCEEDED";
  InboundActionTypes["SHOW_BLACKLISTED"] = "SHOW_BLACKLISTED";
  InboundActionTypes["TOGGLE_BATTERY_COMPLIANCE"] = "TOGGLE_BATTERY_COMPLIANCE";
  InboundActionTypes["TOGGLE_PACKING_LIST_DOWNLOADED"] = "TOGGLE_PACKING_LIST_DOWNLOADED";
  InboundActionTypes["SET_DELIVERR_PREP_SELECTED"] = "SET_DELIVERR_PREP_SELECTED";
  InboundActionTypes["SET_CASE_PACK_DEFAULTS"] = "SET_CASE_PACK_DEFAULTS";
  InboundActionTypes["SET_BULK_UPLOAD_SESSION_ID"] = "SET_BULK_UPLOAD_SESSION_ID";
  InboundActionTypes["SET_CURRENT_STEP"] = "SET_CURRENT_STEP";
  InboundActionTypes["SET_BOX_LABEL_URL"] = "SET_BOX_LABEL_URL";
  InboundActionTypes["SET_HAS_DOWNLOADED_LABELS"] = "SET_HAS_DOWNLOADED_LABELS";
  InboundActionTypes["SET_HAS_DOWNLOADED_SHIPMENT_BOX_LABELS"] = "SET_HAS_DOWNLOADED_SHIPMENT_BOX_LABELS";
  InboundActionTypes["SET_SHIPMENT_FREIGHT_INFO"] = "SET_SHIPMENT_FREIGHT_INFO";
  InboundActionTypes["UPDATE_SHIPMENT_QUANTITY"] = "UPDATE_SHIPMENT_QUANTITY";
  InboundActionTypes["TOGGLE_INBOUND_SHIPMENT_METADATA"] = "TOGGLE_INBOUND_SHIPMENT_METADATA";
  InboundActionTypes["SET_IS_GENERATING_SHIPMENTS"] = "SET_IS_GENERATING_SHIPMENTS";
  InboundActionTypes["SET_HAS_INACTIVE_LABELS"] = "SET_HAS_INACTIVE_LABELS";
  InboundActionTypes["SET_CARGO_TYPE"] = "SET_CARGO_TYPE";
})(InboundActionTypes || (InboundActionTypes = {}));