import { range } from "lodash/fp";
import { defineMessages } from "react-intl";
export const leadTimeWeeksSelectValues = range(2, 21);
export const replenishMonthsSelectValues = range(2, 13);
export const defaultLeadTimeWeeks = 8;
export const defaultReplenishMonths = 6;
export const jitRecommendationsConfigOptionMessages = defineMessages({
  label: {
    id: "setting.inventory.leadTimeWeeksLabel",
    defaultMessage: "{LEAD_TIME} weeks"
  },
  value: {
    id: "setting.inventory.replenishMonths",
    defaultMessage: "{REPLENISH_MONTHS} months"
  }
});
export const advanceNotificationHourMessage = defineMessages({
  immediately: {
    id: "setting.inventory.jit.advance.notification.jit.immediately",
    defaultMessage: "immediately"
  },
  after24Hours: {
    id: "setting.inventory.jit.advance.notification.jit.24",
    defaultMessage: "after 24 hours"
  }
});
export const autoConfirmImmediatelyValue = 0;
export const autoConfirmAfter24HoursValue = 24;