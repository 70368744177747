import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
const defaultHeaders = {
  "X-Request-Caller": "seller-portal"
};
export class RSIPClient {
  constructor({
    baseURL,
    headers
  } = {
    baseURL: mustBeDefined(process.env.RSIP_SERVICE_URL),
    headers: defaultHeaders
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}`,
      headers
    });
  }
  async getSellerRecommendationConfig(sellerId, distributionChannel) {
    return await this.apiClient.get({
      url: `v2/rsip/seller/config/${sellerId}?distributionChannel=${distributionChannel}`,
      authentication: isAuthenticated
    });
  }
  async createOrUpdateSellerRecommendationConfig(sellerId, config) {
    return await this.apiClient.put({
      url: `v2/rsip/seller/config/${sellerId}`,
      body: config,
      authentication: isAuthenticated
    });
  }
  async getStorageRecommendationSellerConfig(sellerId) {
    return await this.apiClient.get({
      url: `v1/rsip/seller/config/storage/${sellerId}`,
      authentication: isAuthenticated
    });
  }
  async createOrUpdateStorageRecommendationSellerConfig(sellerId, updateConfig) {
    return await this.apiClient.put({
      url: `v1/rsip/seller/config/storage/${sellerId}`,
      body: updateConfig,
      authentication: isAuthenticated
    });
  }
}