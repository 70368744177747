import { Appearance } from "common/components/ui/shared";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeatureName, isFeatureOn } from "common/Split";
import { areAnyUnviewedNotifications } from "../NotificationsSelectors";
import { NotificationsActionTypes } from "../NotificationsActions";
import { isEmpty } from "lodash";
export const useNotificationsButton = () => {
  const isSplitLoaded = useSelector(state => state.splitIo.isReady);
  const treatments = useSelector(state => state.splitIo.treatments);
  // check for Split SDK ready, treatments are loaded, and notifications feature enabled
  const isEnabled = isSplitLoaded && !isEmpty(treatments) && isFeatureOn(FeatureName.Notifications);
  const indicator = useSelector(areAnyUnviewedNotifications) ? Appearance.DANGER : undefined;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    setIsOpen(true);
    dispatch({
      type: NotificationsActionTypes.VIEW_NOTIFICATIONS
    });
  };
  const onClose = () => setIsOpen(false);
  return {
    isEnabled,
    indicator,
    isOpen,
    onOpen,
    onClose
  };
};