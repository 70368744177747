/**
 * In order for a plan / shipment to be charging eligible, the following conditions must be satisfied:
 * - The Shipping Plan must be a Ship-to-One plan
 * - A CrossdockInboundQuote must already have been generated if shipments are already generated
 *
 * @param isShipToOne is the Shipping Plan a Forwarding plan
 * @param shipmentId the id of the Shipment that may or may not have been generated
 *  (i.e. loadedShipmentId will be undefined if shipments are not generated)
 * @param crossdockQuote  the CrossdockInboundQuote for the plan (if one exists)
 * @returns boolean if the Shipping Plan, Shipment, and Seller are eligible for Forwarding charging
 */
export const isCrossdockChargeablePlanAndShipment = (isShipToOne, shipmentId, crossdockQuote) => {
  const arePlanAndSellerChargeable = isShipToOne;
  const hasGeneratedShipmentsWithoutQuote = shipmentId != null && !crossdockQuote;
  return arePlanAndSellerChargeable && !hasGeneratedShipmentsWithoutQuote;
};