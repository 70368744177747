import { facebookClient } from "common/clients/instances";
import { notifyUserOfError } from "common/ErrorToast";
import log, { logError, logStart } from "Logger";
import { hasProfileInfo } from "../../model/Profile";
import { FacebookConnectActionTypes } from "./FacebookConnectActionTypes";
export const activateFacebook = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "activateFacebook"
  });
  const {
    user: {
      sellerId
    },
    facebook: {
      activationError
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "activating facebook");
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(activationError)) {
      dispatch({
        type: FacebookConnectActionTypes.FACEBOOK_ACTIVATION_ERROR,
        activationError: undefined
      });
    }
    await facebookClient.activateSeller(sellerId);
  } catch (err) {
    notifyUserOfError({
      err,
      toastId: "facebookActivationError"
    });
    logError(ctx, err);
  }
};
export const deactivateFacebook = () => async (_, getState) => {
  const ctx = logStart({
    fn: "deactivateFacebok"
  });
  const {
    user: {
      sellerId
    }
  } = getState();
  try {
    await facebookClient.deactivateSeller(sellerId);
    location.reload();
  } catch (err) {
    notifyUserOfError({
      err,
      toastId: "facebookDeactivationError"
    });
    logError(ctx, err);
  }
};
export const adAccountsReceived = adAccounts => ({
  type: FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNTS_RECEIVED,
  adAccounts
});
export const adAccountSelected = adAccountId => ({
  type: FacebookConnectActionTypes.FACEBOOK_AD_ACCOUNT_SELECTED,
  adAccountId
});
export const getAdAccounts = (sellerId, userId, accessToken) => async dispatch => {
  const adAccountsResponse = await facebookClient.getAdAccounts(sellerId, userId, accessToken);
  dispatch(adAccountsReceived(adAccountsResponse));
  return adAccountsResponse;
};
export const pagesReceived = pages => ({
  type: FacebookConnectActionTypes.FACEBOOK_PAGES_RECEIVED,
  pages
});
export const pageSelected = pageId => ({
  type: FacebookConnectActionTypes.FACEBOOK_PAGE_SELECTED,
  pageId
});
export const getPages = (sellerId, userId, accessToken) => async dispatch => {
  const pagesResponse = await facebookClient.getPages(sellerId, userId, accessToken);
  dispatch(pagesReceived(pagesResponse));
  return pagesResponse;
};
export const pixelsReceived = pixels => ({
  type: FacebookConnectActionTypes.FACEBOOK_PIXELS_RECEIVED,
  pixels
});
export const permissionsChecked = hasValidPermissions => ({
  type: FacebookConnectActionTypes.FACEBOOK_PERMISSIONS_CHECKED,
  hasValidPermissions
});
export const checkPermissions = (sellerId, userId, accessToken) => async dispatch => {
  const hasValidPermissions = await facebookClient.hasValidPermissions(sellerId, userId, accessToken);
  dispatch(permissionsChecked(hasValidPermissions));
  return hasValidPermissions;
};
export const sellerRegistrationComplete = () => ({
  type: FacebookConnectActionTypes.FACEBOOK_SELLER_REGISTRATION_COMPLETE
});
export const getProfile = (userId, accessToken) => async dispatch => {
  const getProfileResponse = await fetch(`https://graph.facebook.com/${userId}?access_token=${accessToken}&fields=name,picture`);
  if (hasProfileInfo(getProfileResponse)) {
    dispatch(profileInfoReceived(await getProfileResponse.json()));
  }
  return getProfileResponse;
};
export const getInstagramAccounts = (sellerId, businessId, accessToken) => async dispatch => {
  const instagramAccountsResponse = await facebookClient.getInstagramAccounts(sellerId, businessId, accessToken);
  dispatch(instagramAccountsReceived(instagramAccountsResponse));
  return instagramAccountsResponse;
};
export const instagramAccountsReceived = instagramAccounts => ({
  type: FacebookConnectActionTypes.FACEBOOK_INSTAGRAM_ACCOUNTS_RECEIVED,
  instagramAccounts
});
export const instagramAccountSelected = instagramAccountId => ({
  type: FacebookConnectActionTypes.FACEBOOK_INSTAGRAM_ACCOUNT_SELECTED,
  instagramAccountId
});
export const pixelSelected = pixelId => ({
  type: FacebookConnectActionTypes.FACEBOOK_PIXEL_SELECTED,
  pixelId
});
export const profileInfoReceived = profileInfo => ({
  type: FacebookConnectActionTypes.FACEBOOK_PROFILE_INFO_RECIEVED,
  profileInfo
});
export const instagramAdsToggleSet = active => ({
  type: FacebookConnectActionTypes.FACEBOOK_INSTAGRAM_ADS_TOGGLE_SET,
  active
});
export const loginStatusReceived = (auth, newLogin = false) => ({
  type: FacebookConnectActionTypes.FACEBOOK_LOGIN_STATUS_RECEIVED,
  auth,
  newLogin
});