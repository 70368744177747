export let BoxErrorType;
(function (BoxErrorType) {
  BoxErrorType["TOO_MANY_BOXED"] = "TOO_MANY_BOXED";
  BoxErrorType["TOO_FEW_BOXED"] = "TOO_FEW_BOXED";
  BoxErrorType["UNDER_WEIGHT"] = "UNDER_WEIGHT";
  BoxErrorType["OVER_WEIGHT"] = "OVER_WEIGHT";
  BoxErrorType["UNDER_DIMENSIONS"] = "UNDER_DIMENSIONS";
  BoxErrorType["OVER_DIMENSIONS"] = "OVER_DIMENSIONS";
  BoxErrorType["EMPTY_FIELDS"] = "EMPTY_FIELDS";
  BoxErrorType["ZERO_UNITS"] = "ZERO_UNITS";
  BoxErrorType["TOO_MANY_SKUS"] = "TOO_MANY_SKUS";
})(BoxErrorType || (BoxErrorType = {}));