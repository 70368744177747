import { compact } from "lodash/fp";
import { ElevioModuleId } from "common/elevio/ElevioId";
import { coaleseBool } from "common/StringUtils";
import { loadElevioIfNotLoaded } from "common/utils/thirdParty/Elevio";
import log, { logStart } from "Logger";
import { fetchElevioEmailHash } from "./fetchElevioEmailHash";
const elevio = () => {
  loadElevioIfNotLoaded();
  return window?._elev;
};
export const openArticle = articleId => elevio()?.openArticle(articleId);
export const openModule = moduleId => elevio()?.openModule(moduleId);
export const openSupportTicket = () => openModule(ElevioModuleId.SupportTicket);
export const setUser = user => {
  elevio()?.on("load", async elev => {
    const ctx = logStart({
      fn: "setUser",
      user
    });
    try {
      const {
        hash: userHash
      } = await fetchElevioEmailHash(user.email);
      const groups = compact([user.listingTool, user.dealStage, user.source]);
      if (coaleseBool(user.activatedFastTag)) {
        groups.push("activatedFastTag");
      }
      elev.setUser({
        // hashing function removes + in email addresses.
        // user.email won't match without the replace('+','') below
        email: user.email.replace(/\+/g, " "),
        user_hash: userHash,
        first_name: user.name,
        groups,
        traits: user
      });
    } catch (err) {
      // error from email hash lambda, or type error on window._elev
      log.warn({
        ...ctx,
        err
      }, "Error setting Elevio user");
    }
  });
};