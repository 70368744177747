import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ReportsActionTypes } from "report/ReportsActions";
export const reportsInitialState = {
  reportTypes: [],
  reports: [],
  pendingReports: []
};
const reducers = {
  ...handleSimpleReducerUpdates([ReportsActionTypes.UPDATE_DATE_RANGE, ReportsActionTypes.UPDATE_LOGISTICS_SKU, ReportsActionTypes.UPDATE_REPORT_TYPE, ReportsActionTypes.UPDATE_SELECTED_PRODUCT]),
  [ReportsActionTypes.CLEAR_EMPTY_REPORT]: state => ({
    ...state,
    pendingReports: state.pendingReports?.slice(0, -1)
  }),
  [ReportsActionTypes.CLEAR_REPORT_FIELDS]: state => ({
    ...reportsInitialState,
    reportTypes: state.reportTypes,
    reports: state.reports,
    pendingReports: state.pendingReports
  }),
  [ReportsActionTypes.GENERATE_REPORT]: (state, {
    report
  }) => ({
    ...state,
    pendingReports: [report, ...state.pendingReports]
  }),
  [ReportsActionTypes.GET_REPORTS]: (state, {
    reports,
    reportTypes,
    newReportIds
  }) => ({
    ...state,
    reports,
    reportTypes,
    pendingReports: state.pendingReports?.slice(0, -1 * newReportIds.length)
  })
};
export const reportsReducer = createReducer(reportsInitialState, reducers);