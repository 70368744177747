import { InternationalRateCountryList } from "common/components/CountrySelect/Countries";
import { createShipmentInitialFormState } from "inbounds/createShipment/store/CreateShipmentState";
export const _parseBookingAddress = (address, portGrouping) => {
  if (address) {
    Object.keys(address).forEach(key => {
      if (address[key] === "N/A") {
        address[key] = "";
      }
    });
  }
  return {
    value: {
      address: address ? {
        ...address,
        country: Object.keys(InternationalRateCountryList).find(k => InternationalRateCountryList[k] === address.country) ?? address.country ?? ""
      } : createShipmentInitialFormState.origin.value.address,
      ports: portGrouping ?? [],
      distanceKm: undefined // only used in options list
    },

    label: address?.optionLabel ?? address?.name ?? ""
  };
};