import { defineMessages } from "react-intl";
import { BulkUploadContent } from "./BulkUploadContent";
export const BULK_UPLOAD_CONTENT_MESSAGES = defineMessages({
  [BulkUploadContent.IMPORT_PRODUCTS]: {
    id: "inbounds.bulkUpload.importProducts",
    defaultMessage: "Import products"
  },
  [BulkUploadContent.UPLOAD_FILE]: {
    id: "inbounds.bulkUpload.uploadFile",
    defaultMessage: "Upload file"
  },
  [BulkUploadContent.FILE_TYPE]: {
    id: "inbounds.bulkUpload.fileType",
    defaultMessage: "File type must be .xlsx or .csv"
  },
  [BulkUploadContent.DOWNLOAD_TEMPLATE]: {
    id: "inbounds.bulkUpload.downloadTemplate",
    defaultMessage: "{link} and fill out the required fields."
  },
  [BulkUploadContent.NOTE]: {
    id: "inbounds.bulkUpload.note",
    defaultMessage: "Note: Products must already be imported to Seller Portal. We currently only support up to {supportedNumberOfSKUs} SKUs."
  },
  [BulkUploadContent.DOWNLOAD_TEMPLATE_LINK]: {
    id: "inbounds.bulkUpload.downloadTemplateLink",
    defaultMessage: "Download the template"
  },
  [BulkUploadContent.GET_HELP]: {
    id: "getHelp",
    defaultMessage: "Get help"
  },
  [BulkUploadContent.PRODUCTS_IMPORTED_SUCCESSFULLY]: {
    id: "inbounds.bulkUpload.importedSuccessfully",
    defaultMessage: "{productCount} products imported successfully"
  },
  [BulkUploadContent.PRODUCT_REPLACEMENT_WARNING]: {
    id: "inbounds.bulkUpload.productReplacementWarning",
    defaultMessage: "Importing a new file will replace any products already added to the shipping plan."
  },
  [BulkUploadContent.MAKE_FOLLOWING_CHANGES]: {
    id: "inbounds.bulkUpload.makeFollowingChanges",
    defaultMessage: "To import these products, make the following changes:"
  },
  [BulkUploadContent.SHOW_ALL]: {
    id: "inbounds.bulkUpload.showAll",
    defaultMessage: "Show all {errorCount} errors"
  },
  [BulkUploadContent.DOWNLOAD_REPORT]: {
    id: "downloadReport",
    defaultMessage: "Download error report"
  },
  [BulkUploadContent.IMPORTING_PRODUCTS]: {
    id: "inbounds.bulkUpload.importingProducts",
    defaultMessage: "Importing products"
  }
});