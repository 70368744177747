import { logError } from "Logger";
import RouteParserModule from "route-parser";
export default class RouteParser {
  // jest fails a bunch of ispecs if we type this as RouteParserModule

  constructor(path) {
    this.parserModule = new RouteParserModule(path);
    this.path = path;
  }
  parse(params) {
    const parsedRoute = this.parserModule.reverse(params);
    if (parsedRoute) {
      return parsedRoute;
    }
    logError({
      fn: "RouteParser#parse"
    }, new Error(`Error parsing route ${this.path} with ${JSON.stringify(params)}`));

    // return anchor if something wrong happens with link
    return "#";
  }
}