import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";
import { useParams } from "react-router-dom";
import { toast } from "common/components/ui";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { createStorageInbound } from "../store/actions/createStorageInbound";
import { loadStorageInboundCreateDraft } from "../store/actions/loadStorageInboundCreateDraft";
import { useIntl } from "react-intl";
import { useSmbAccelerateFeatureOn } from "common/featureFlags";
import { FeatureName } from "common/Split";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { goToCreateInbound } from "inbounds/createShipment/store/actions";
export const useSelectStorageInboundProductsStep = () => {
  const dispatch = useDispatch();
  const {
    goToNextStep,
    goToPreviousStep
  } = useStorageInboundNavigation();
  const {
    shippingPlanId
  } = useParams();
  const {
    formatMessage
  } = useIntl();
  const isSmbAccelerateFlexPlusFeatureOn = useSmbAccelerateFeatureOn(FeatureName.SmbAccelerateFlexportPlus);
  const loadedShippingPlanId = useSelector(getStorageInboundCreate)?.shippingPlanId;
  const hasLoadedShippingPlan = loadedShippingPlanId && loadedShippingPlanId !== 0;

  // keeping this preserves being able to link to inbounds/:shippingPlanId/storage to load a draft
  // may be better to load in a step container
  useMount(async () => {
    if (shippingPlanId && !hasLoadedShippingPlan) {
      await dispatch(loadStorageInboundCreateDraft(Number(shippingPlanId)));
      dispatch(goToCreateInbound());
    }
  });
  const onNextClick = async () => {
    try {
      dispatch(addLoader(InboundLoaderId.transition));
      await dispatch(createStorageInbound(isSmbAccelerateFlexPlusFeatureOn));
      goToNextStep();
    } catch (err) {
      toast.error(formatMessage({
        id: "storage.inbounds.create.createStorageInboundFailed",
        defaultMessage: "Creating draft inbound failed."
      }), {
        autoClose: 5000,
        toastId: "createStorageInboundFailed"
      });
    } finally {
      dispatch(clearLoader(InboundLoaderId.transition));
    }
  };
  const onPreviousClick = () => {
    goToPreviousStep();
  };
  return {
    onNextClick,
    onPreviousClick
  };
};