export let OrderListActionTypes;
(function (OrderListActionTypes) {
  OrderListActionTypes["SEARCH_ORDERS_SUCCESS"] = "SEARCH_ORDERS_SUCCESS";
  OrderListActionTypes["SEARCH_ORDERS_PREVIEW"] = "SEARCH_ORDERS_PREVIEW";
  OrderListActionTypes["SEARCH_ORDERS_ERROR"] = "SEARCH_ORDERS_ERROR";
  OrderListActionTypes["SET_ORDERS_SORT"] = "SET_ORDERS_SORT";
  OrderListActionTypes["SET_CURRENT_SEARCH_TERM"] = "SET_CURRENT_SEARCH_TERM";
  OrderListActionTypes["LOAD_ORDER_LIST"] = "LOAD_ORDER_LIST";
  OrderListActionTypes["SET_ORDER_SUMMARY"] = "SET_ORDER_SUMMARY";
  OrderListActionTypes["SET_CURRENT_PAGE"] = "SET_CURRENT_PAGE";
  OrderListActionTypes["SET_SUMMARY_FILTER"] = "SET_SUMMARY_FILTER";
  OrderListActionTypes["SET_ORDERS_FILTER"] = "SET_ORDERS_FILTER";
  OrderListActionTypes["CLEAR_FILTER_PREVIEW"] = "CLEAR_FILTER_PREVIEW";
  OrderListActionTypes["LOAD_IMPORT_ERRORS_TABLE"] = "LOAD_IMPORT_ERRORS_TABLE";
  OrderListActionTypes["SEARCH_IMPORT_ERRORS_START"] = "SEARCH_IMPORT_ERRORS_START";
  OrderListActionTypes["SEARCH_IMPORT_ERRORS_SUCCESS"] = "SEARCH_IMPORT_ERRORS_SUCCESS";
  OrderListActionTypes["SEARCH_IMPORT_ERRORS_ERROR"] = "SEARCH_IMPORT_ERRORS_ERROR";
  OrderListActionTypes["RESET_IMPORT_ERRORS"] = "RESET_IMPORT_ERRORS";
})(OrderListActionTypes || (OrderListActionTypes = {}));