import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { AdminActionTypes } from "./AdminActions";
export const adminInitialState = {
  currentSearchTerm: "",
  currentPageItems: [],
  showAdminFeatures: true,
  showArchivedShippingPlans: false
};
const reducers = {
  ...handleSimpleReducerUpdates([AdminActionTypes.SET_USER_PROPS, AdminActionTypes.SET_SHOW_ADMIN_TEXT, AdminActionTypes.SET_SHOW_ARCHIVED_SHIPPING_PLANS]),
  [AdminActionTypes.SEARCH_SELLER_SUCCESS]: (state, action) => {
    const {
      topOrganizations,
      topUsers
    } = action;
    const organizationItems = topOrganizations.map(({
      sellerId,
      logisticsAccountId,
      shopDomain
    }) => ({
      key: logisticsAccountId,
      sellerId,
      line1: shopDomain,
      line2: logisticsAccountId
    }));
    const userItems = topUsers.map(({
      sellerId,
      userId,
      email
    }) => ({
      key: userId,
      sellerId,
      email,
      line1: sellerId,
      line2: email
    }));
    return {
      ...state,
      currentPageItems: [...organizationItems, ...userItems]
    };
  },
  [AdminActionTypes.RESET_USER_PROPS]: state => adminInitialState
};
export const adminReducer = createReducer(adminInitialState, reducers);