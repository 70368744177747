import { FacebookActionTypes } from "../actions/FacebookActionTypes";
export const initialAdSets = null;
export const adSetsReducer = (state = initialAdSets, action) => {
  switch (action.type) {
    case FacebookActionTypes.FACEBOOK_ERROR_SYNCING_AD_SETS:
      return "error";
    case FacebookActionTypes.FACEBOOK_START_SYNC_AD_SETS:
      return null;
    case FacebookActionTypes.FACEBOOK_REFRESH_SELLER_COMPLETED:
      return "syncing";
    case FacebookActionTypes.FACEBOOK_AD_SETS_RECEIVED:
      return action.adSets;
    default:
      return state;
  }
};