import { ApiError } from "@deliverr/commons-objects/lib/ApiError";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
export const defaultBulkUploadClientConfig = () => {
  if (process.env.BULK_UPLOAD_SERVICE_URL) {
    return {
      baseURL: `${process.env.BULK_UPLOAD_SERVICE_URL}`
    };
  } else {
    throw new Error(`Unable to get default orders client config, environment variable BULK_UPLOAD_SERVICE_URL could not be found`);
  }
};
export class BulkUploadClient {
  constructor({
    baseURL
  } = defaultBulkUploadClientConfig()) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getPreview(sellerId, objectUrl) {
    try {
      const response = await this.apiClient.post({
        url: `/validate`,
        body: {
          sellerId,
          objectUrl
        },
        authentication: isAuthenticated
      });
      return response;
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          return undefined;
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }
  async createOrders(sellerId, objectUrl) {
    try {
      return await this.apiClient.post({
        url: "/orders",
        body: {
          sellerId,
          objectUrl
        },
        authentication: isAuthenticated
      });
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          return undefined;
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }
}