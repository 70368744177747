import { useDispatch, useSelector } from "react-redux";
import { facebookEditSelectedAction } from "../../state/facebookEditSelectedAction";
import { selectAction } from "../../state/selectAction";
export const useSelectAction = () => {
  const dispatch = useDispatch();
  const selectedAction = useSelector(facebookEditSelectedAction);
  const onSelectAction = action => () => {
    dispatch(selectAction(action));
  };
  return {
    selectedAction,
    onSelectAction
  };
};