import styled from "@emotion/styled";
import { Direction } from "../shared";
import { getVerticalStyles } from "./getVerticalStyles";
import { getHorizontalStyles } from "./getHorizontalStyles";
export const Stack = styled.div(({
  direction = Direction.VERTICAL,
  gap,
  center = false,
  theme,
  inline,
  justifyContent = "normal",
  alignItems = "normal"
}) => {
  return `
      display: ${inline ? "inline-flex" : "flex"};

      ${!center ? `
            justify-content: ${justifyContent};
            align-items: ${alignItems};
          ` : ""}

      ${direction === Direction.VERTICAL ? getVerticalStyles(theme, center, gap) : getHorizontalStyles(theme, center, gap)}
    `;
});