import { createActionCreator } from "common/ReduxUtils";
import { ReturnsListActionTypes } from "./OrderReturnsListStore.types";
import log from "Logger";
import { returnsClient } from "Clients";
export const getReturnsForOrder = orderId => async dispatch => {
  try {
    const orderReturnsList = await returnsClient.getReturnsByOrderId(orderId);
    dispatch({
      type: ReturnsListActionTypes.RETURNS_GET_LIST,
      orderReturnsList: orderReturnsList?.value
    });
  } catch (err) {
    log.error(err, "error getting returns list for orderId ", orderId);
    dispatch({
      err,
      type: ReturnsListActionTypes.RETURNS_GET_LIST_ERROR
    });
  }
};
export const resetOrderReturnsList = createActionCreator(ReturnsListActionTypes.RETURNS_RESET_LIST);