import { defineMessages } from "react-intl";
export const ChannelsErrorMessages = defineMessages({
  getSalesChannels: {
    id: "channels.error.getSalesChannels",
    defaultMessage: "There was an issue getting your sales channels"
  },
  deactivateSalesChannel: {
    id: "channels.error.deactivateSalesChannel",
    defaultMessage: "There was an issue deactivating your sales channel"
  },
  activateSalesChannel: {
    id: "channels.error.activateSalesChannel",
    defaultMessage: "There was an issue activating your sales channel"
  }
});