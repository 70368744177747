import { useState } from "react";
import { getItemProductAndInventoryDetails } from "common/wholesale-common/getItemProductAndInventoryDetails";
import { InvalidOrderItemReason, InvalidOrderItemResolution } from "@deliverr/replenishment-client";
import { FeatureName, useFeatureOn } from "common/Split";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useAsync } from "react-use";
import { resolveInvalidItem } from "transfers/create/actions/resolveInvalidItems";
import { calculateReserveStorageAvailableQuantity } from "common/utils/product/calculateReserveStorageAvailableQuantity";
import { setOriginWarehouseForReplenishmentInvalidItemOrder } from "./setOriginWarehouseForReplenishmentInvalidItemOrder";
export const useInvalidOrderItemBlade = ({
  onCloseClick,
  invalidItem
}) => {
  const [resolution, setResolution] = useState(invalidItem.resolution);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const {
    formatMessage
  } = useIntl();
  const dispatch = useDispatch();
  const {
    loading: isResolutionDataLoading
  } = useAsync(async () => {
    if (invalidItem.resolvedDsku) {
      const products = await getItemProductAndInventoryDetails([{
        dsku: invalidItem.resolvedDsku,
        qty: invalidItem.requestedTotalUnits
      }], dispatch, isMultiCasePackEnabled, false);
      const product = products.selectedProducts[invalidItem.resolvedDsku];
      setSelectedProduct({
        ...product,
        qty: invalidItem.resolvedTotalUnits ?? invalidItem.requestedTotalUnits
      });
      setResolution(InvalidOrderItemResolution.MODIFIED);
    } else {
      setSelectedProduct(undefined);
      setResolution(invalidItem.resolution);
    }
  }, [invalidItem, dispatch, isMultiCasePackEnabled]);
  const onChange = ev => {
    if (ev.target.value === "true") {
      setSelectedProduct(undefined);
      setResolution(InvalidOrderItemResolution.REMOVED);
    } else {
      setResolution(undefined);
    }
  };
  const onContinueClick = async () => {
    setIsContinueLoading(true);
    try {
      await dispatch(resolveInvalidItem({
        ...invalidItem,
        resolution,
        resolvedDsku: selectedProduct?.dsku,
        resolvedTotalUnits: selectedProduct ? getResolvedTotalUnits(selectedProduct, invalidItem) : undefined
      }));
      await dispatch(setOriginWarehouseForReplenishmentInvalidItemOrder());
    } finally {
      setIsContinueLoading(false);
    }
    onCloseClick();
  };
  const getResolvedTotalUnits = (selectedProductData, invalidOrderItem) => {
    switch (invalidOrderItem.reason) {
      case InvalidOrderItemReason.INVALID_ITEM:
      case InvalidOrderItemReason.INVALID_CASE_CONFIG:
        // Minimum of the requested total units and the reserve storage available quantity (divisible by case qty)
        return Math.min(Math.floor(invalidOrderItem.requestedTotalUnits / (selectedProductData.caseQty ?? 1)) * (selectedProductData.caseQty ?? 1), Math.floor(calculateReserveStorageAvailableQuantity(selectedProductData) / (selectedProductData.caseQty ?? 1)) * (selectedProductData.caseQty ?? 1));
      default:
        return invalidOrderItem.requestedTotalUnits;
    }
  };
  return {
    resolution,
    setResolution,
    selectedProduct,
    setSelectedProduct,
    formatMessage,
    dispatch,
    onCloseClick,
    onChange,
    onContinueClick,
    isResolutionDataLoading,
    isContinueLoading
  };
};