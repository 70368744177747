import { createSelector } from "reselect";
import log from "Logger";
import { getFreightCargoPalletDetails } from "freight/FreightSelectors";
export const getTotalCargoPalletDimensions = createSelector(getFreightCargoPalletDetails, ({
  palletLineItems
}) => {
  if (!palletLineItems) {
    log.warn({
      fn: "getCargoPalletDimenstions",
      palletLineItems
    }, "no ltl pallet dimenstions found");
    return [0, 0];
  }
  return palletLineItems.reduce((sums, {
    totalPallets,
    palletDimensions
  }) => [sums[0] + totalPallets, sums[1] + totalPallets * palletDimensions.weight], [0, 0]);
});