import { HazmatInformationStatus } from "@deliverr/commons-clients";
import { removeEmTag } from "common/StringUtils";
import idx from "idx";
import { isNumber, values } from "lodash/fp";
import { createSelector } from "reselect";
import { isDimsConfirmed } from "../../common/utils/product/isDimsConfirmed";
export const getEstimatedCostsServiceLevel = state => state.inventoryList.estimatedCostsServiceLevel;
export const getFiltersBladeOpen = state => state.inventoryList.filtersBladeOpen;
export const getInventoryListDskus = createSelector(state => state.inventoryList.productList.listItems, listItems => listItems.filter(item => idx(item, _ => _.dsku)).map(({
  dsku
}) => removeEmTag(dsku)));
export const selectedItemsHasInventory = createSelector(state => state.inventoryList.productList.selectedListItems, selectedListItems => Object.entries(selectedListItems).reduce((acc, [key, val]) => {
  if (isNumber(parseInt(key, 10))) {
    const innerSelectedItems = values(val);
    innerSelectedItems.forEach(item => {
      if (item && (item.onHandQty === undefined || item.onHandQty === 0 || item.onHandQty - item.unfilledOrderQty - item.unavailableQty <= 0)) {
        acc = false;
      }
    });
  }
  return acc;
}, true));
export const selectHasValidItemDimensions = createSelector(state => state.inventoryList.productList.selectedListItems, selectedListItems => Object.entries(selectedListItems).reduce((acc, [key, val]) => {
  if (isNumber(parseInt(key, 10))) {
    const innerSelectedItems = values(val);
    innerSelectedItems.forEach(item => {
      if (item && !isDimsConfirmed(item.dimSourceType)) {
        acc = false;
      }
    });
  }
  return acc;
}, true));
export const selectedItemsHasValidHazmat = createSelector(state => state.inventoryList.productList.selectedListItems, selectedListItems => Object.entries(selectedListItems).reduce((acc, [key, val]) => {
  if (isNumber(parseInt(key, 10))) {
    const innerSelectedItems = values(val);
    innerSelectedItems.forEach(item => {
      if (item?.hazmatInformation?.status && item?.hazmatInformation?.status !== HazmatInformationStatus.APPROVED) {
        acc = false;
      }
    });
  }
  return acc;
}, true));
export const getCurrentTab = state => state.inventoryList.currentTab;
export const getSelectedInventoryLocation = state => state.inventoryList.inventoryLocation;