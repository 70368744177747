import { FeatureName, getFeatureSelector } from "common/Split";
import { createSelector } from "reselect";
import { TransferCreationTypes } from "../transferCreationTypes";
export const selectTransferCreate = root => root.transferCreate;
export const selectIsMultiShipmentWithFeatureOn = createSelector(root => getFeatureSelector(FeatureName.FbaMultiShipmentShipping)(root), selectTransferCreate, (isMultiShipmentShippingFeatureOn, transferCreateState) => {
  const {
    destinationType,
    replenishmentOrder
  } = transferCreateState;
  return isMultiShipmentShippingFeatureOn && destinationType === TransferCreationTypes.FbaIntegration && (replenishmentOrder?.shipments?.length ?? 0) > 1;
});