import { border } from "./border";
import { breakpoints, mediaQueries } from "./breakpoint";
import { colorByAppearance, colors } from "./color";
import { font } from "./font";
import { iconSize } from "./icon";
import { shadow } from "./shadow";
import { spacing } from "./space";
import { zindex } from "./zindex";
import { input } from "./input";
export const defaultTheme = {
  name: "default",
  border,
  breakpoints,
  mediaQueries,
  colors,
  font,
  /**
   * @constant `I1` 8px | 0.5rem
   * @constant `I2` 10px | 0.625rem
   * @constant `I3 `12px | 0.75rem
   * @constant `I4 `14px | 0.875rem
   * @constant `I5 `16px | 1rem
   * @constant `I6 `22x | 1.375rem
   * @constant `I7 `26px | 1.625rem
   * @constant `I8 `30px | 1.875rem
   */
  iconSize,
  input,
  /**
   * @constant `S1` `0 1px 2px rgba(0, 1, 0, 0.13)`
   * @constant `S2` `0 1px 4px rgba(0, 1, 0, 0.13)`
   * @constant `S3` `0 2px 6px rgba(20, 48, 84, 0.2)`
   */
  shadow,
  /**
   *  @constant `S1` 4px | 0.25rem
   *  @constant `S2` 8px | 0.5rem
   *  @constant `S3` 12px | 0.75rem
   *  @constant `S4` 16px | 1rem
   *  @constant `S5` 24px | 1.5rem
   *  @constant `S6` 32px | 2rem
   *  @constant `S7` 40px | 2.5rem
   *  @constant `S8` 48px | 3rem
   *  @constant `S9` 64px | 4rem
   */
  spacing,
  /**
   * @constant `Z1` 100
   * @constant `Z2` 200
   * @constant `Z3` 300
   * @constant `Z4` 400
   * @constant `Z5` 500
   * @constant `Z6` 600
   */
  zindex,
  config: {
    colorByAppearance
  }
};