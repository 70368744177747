import { replenishmentClient } from "Clients";
import { logStart, logError } from "Logger";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import useSWRImmutable from "swr/immutable";
export const useFbaLocations = () => {
  const {
    formatMessage
  } = useIntl();
  const ctx = {
    fn: "getFBALocations"
  };
  const {
    data,
    error
  } = useSWRImmutable("fbaLocations", async () => {
    logStart(ctx);
    const fbaLocations = await replenishmentClient.getFBALocations();
    return fbaLocations;
  }, {
    errorRetryCount: 3,
    onError: err => {
      logError(ctx, err, "error fetching Amazon locations");
      toast.error(formatMessage({
        id: "getFbaLocations.error",
        defaultMessage: "Error fetching Amazon locations"
      }), {
        autoClose: 10000,
        toastId: "getFbaLocations.error"
      });
    }
  });
  const isLoading = !data && !error;
  return {
    data,
    error,
    isLoading
  };
};