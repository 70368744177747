import { mustBeDefined } from "common/utils/mustBeDefined";
import { WishError } from "./WishError";
import { ApiClient } from "../core/ApiClient";
import { mapError } from "../core/mapError";
import { isAuthenticated } from "../core/authentication";
export class WishClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.WISH_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1/`
    });
  }
  async isFastTagEnabled(sellerId, dsku) {
    try {
      const {
        isActive
      } = await this.apiClient.get({
        url: `/product/seller/${sellerId}/dsku/${dsku}/active`,
        authentication: isAuthenticated
      });
      return isActive;
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND]);
    }
  }
  async getLinksForDsku(sellerId, dsku) {
    try {
      return await this.apiClient.get({
        url: `/product/seller/${sellerId}/dsku/${dsku}/links`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async updateLinkForDsku(sellerId, dsku, variationIdentifier) {
    try {
      await this.apiClient.put({
        url: `/product/seller/${sellerId}/dsku/${dsku}/variant`,
        body: {
          variationIdentifier
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WishError.WISH_PRODUCT_IDENTIFIER_NOT_FOUND]);
    }
  }
  async updateSeller(sellerId, update) {
    try {
      return await this.apiClient.put({
        url: `/seller/${sellerId}`,
        body: update,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND]);
    }
  }
  async getSeller(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/seller/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND]);
    }
  }
  async authorizeSeller(sellerId, authorizationCode) {
    try {
      await this.apiClient.put({
        url: `/seller/${sellerId}/authorization`,
        body: {
          authorizationCode
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WishError.ACCESS_TOKEN_ERROR, WishError.DUPLICATE_MERCHANT_ID, WishError.DUPLICATE_TOKEN]);
    }
  }
  async enableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/product/seller/${sellerId}/fast-tag/enable`,
        body: [dsku],
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async disableFastTag(sellerId, dsku) {
    try {
      await this.apiClient.put({
        url: `/product/seller/${sellerId}/fast-tag/disable`,
        body: [dsku],
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WishError.SELLER_NOT_FOUND, WishError.NO_WISH_EXPRESS_WAREHOUSE]);
    }
  }
  async getDskuStateCoverage(dsku) {
    try {
      return await this.apiClient.get({
        url: `/availability/dsku/${dsku}/coverage/twoday`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, []);
    }
  }
  async getStateCoverageByServiceLevel(dsku) {
    const coverage = await this.getDskuStateCoverage(dsku);
    return {
      2: coverage
    };
  }
}