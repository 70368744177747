import { Environment, RecordSource, Store } from "relay-runtime";
import { NetworkMiddleware } from "./NetworkMiddleware";
import { PartialResponseMiddleware } from "./PartialResponseMiddleware";
import createNetworkWithMiddleware from "./createNetworkWithMiddleware";

// This value is set via webpack.config.js

const clientIdMiddleware = new NetworkMiddleware(async (request, next) => {
  request.fetchOpts.headers["flexport-client-name"] = "seller-portal";
  return await next(request);
});
const externalRequestDataMiddleware = new NetworkMiddleware(async (request, next) => {
  request.fetchOpts.headers["FP-External-Request-Data"] = "{}";
  return await next(request);
});
const gitRevisionMiddleware = new NetworkMiddleware(async (request, next) => {
  if (COMMIT) {
    request.fetchOpts.headers["X-FLEXPORT-APP-GIT-REVISION"] = COMMIT;
  }
  return await next(request);
});
const graphqlOperationNameMiddleware = new NetworkMiddleware(async (request, next) => {
  const operationName = request.operation.name;
  if (operationName) {
    request.fetchOpts.headers["X-GRAPHQL-OPERATION-NAME"] = operationName;
  }
  return await next(request);
});
const baseMiddleware = [
// TODO: wire up additional middlewares to improve telemetry (https://go/jira/SMB-1486)
clientIdMiddleware, externalRequestDataMiddleware, gitRevisionMiddleware, graphqlOperationNameMiddleware, PartialResponseMiddleware];

// eslint-disable-next-line import/prefer-default-export
function createEnvironment({
  records,
  additionalMiddleware = [],
  onError
}) {
  const store = new Store(new RecordSource(records));
  return new Environment({
    network: createNetworkWithMiddleware([...baseMiddleware, ...additionalMiddleware], onError).network,
    store
  });
}
const onError = (_err, _env) => {
  // TODO: Wire-up sentry (or Deliverr-preferred alternative?) https://go/jira/SMB-1486
};
export const environment = createEnvironment({
  additionalMiddleware: [],
  onError
});