import { GoogleShoppingError } from "common/clients/googleShopping/GoogleShoppingError";
import { googleShoppingClient } from "common/clients/instances";
import { notifyUserOfError } from "common/ErrorToast";
import { includes } from "lodash/fp";
import log, { logError, logStart } from "Logger";
import { batch } from "react-redux";
import { Enable, enableNext } from "tags/FastTagsActions";
export let GoogleActionTypes;
(function (GoogleActionTypes) {
  GoogleActionTypes["UPDATE_GOOGLE_ACTIVATION_ERROR"] = "UPDATE_GOOGLE_ACTIVATION_ERROR";
  GoogleActionTypes["UPDATE_GOOGLE_SUPPLEMENTAL_FEED_ERROR"] = "UPDATE_GOOGLE_SUPPLEMENTAL_FEED_ERROR";
  GoogleActionTypes["UPDATE_GOOGLE_MERCHANT_ID_ERROR"] = "UPDATE_GOOGLE_MERCHANT_ID_ERROR";
  GoogleActionTypes["UPDATE_GOOGLE_SELLER"] = "UPDATE_GOOGLE_SELLER";
  GoogleActionTypes["GET_GOOGLE_SELLER_CONFIGS"] = "GET_GOOGLE_SELLER_CONFIGS";
  GoogleActionTypes["GOOGLE_SERVICE_ACCOUNT_EMAIL_RECEIVED"] = "GOOGLE_SERVICE_ACCOUNT_EMAIL_RECEIVED";
})(GoogleActionTypes || (GoogleActionTypes = {}));
export const confirmAdminErrors = [GoogleShoppingError.NOT_AN_ADMIN, GoogleShoppingError.UNAUTHORIZED];
export const merchantIDErrors = [GoogleShoppingError.DUPLICATE_MERCHANT_ID, GoogleShoppingError.INVALID_FEED_ID, GoogleShoppingError.NOT_AN_ADMIN, GoogleShoppingError.SELLER_NOT_FOUND, GoogleShoppingError.UNAUTHORIZED];
export const productFeedIdErrors = [GoogleShoppingError.INVALID_FEED_ID, GoogleShoppingError.UNAUTHORIZED];
const linkAccountErrors = [...confirmAdminErrors, ...merchantIDErrors, ...productFeedIdErrors];
const hasInputErrors = errCode => includes(errCode, linkAccountErrors);
export const createGoogleSeller = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "createGoogleSeller"
  });
  const {
    user: {
      sellerId
    },
    google: {
      isActive,
      isActiveForAds,
      isActiveForShoppingActions
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "creating google seller");
    const googleSeller = await googleShoppingClient.saveOrUpdateSeller({
      sellerId,
      isActive,
      isActiveForAds,
      isActiveForShoppingActions
    });
    dispatch({
      type: GoogleActionTypes.GET_GOOGLE_SELLER_CONFIGS,
      ...googleSeller
    });
  } catch (err) {
    notifyUserOfError({
      err,
      toastId: "googleCreateGoogleSeller"
    });
    logError(ctx, err);
  }
};
export const getGoogleSellerConfigs = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "getGoogleSellerConfigs"
  });
  const {
    user: {
      sellerId
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "getting seller's google config");
    const sellerConfig = await Promise.all([googleShoppingClient.getSeller(sellerId), googleShoppingClient.isSellerEligibleForShoppingActions(sellerId)]).then(([config, isEligibleForShoppingActions]) => ({
      ...config,
      isEligibleForShoppingActions
    }));
    dispatch({
      type: GoogleActionTypes.GET_GOOGLE_SELLER_CONFIGS,
      ...sellerConfig
    });
  } catch (err) {
    if (err.code !== GoogleShoppingError.SELLER_NOT_FOUND) {
      notifyUserOfError({
        err,
        toastId: "googleSellerConfigsError"
      });
      logError(ctx, err);
      throw err;
    } else {
      log.info({
        ...ctx,
        err
      }, "google seller does not exist, creating seller");
      dispatch(createGoogleSeller());
    }
  }
};
export const updateMerchantId = googleMerchantId => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "updateMerchantId"
  });
  const {
    user: {
      sellerId
    },
    google: {
      merchantIdError
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "updating google merchant id");
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(merchantIdError)) {
      dispatch({
        type: GoogleActionTypes.UPDATE_GOOGLE_MERCHANT_ID_ERROR,
        merchantIdError: undefined
      });
    }
    await googleShoppingClient.updateSeller(sellerId, {
      merchantId: googleMerchantId
    });
    dispatch(enableNext(Enable.Transition, "merchantIdUpdated"));
  } catch (err) {
    const hasErrors = includes(err.code, linkAccountErrors);
    if (hasErrors) {
      dispatch({
        type: GoogleActionTypes.UPDATE_GOOGLE_MERCHANT_ID_ERROR,
        merchantIdError: err.code
      });
    } else {
      notifyUserOfError({
        err,
        toastId: "googleShoppingMerchantIdError"
      });
      logError(ctx, err);
    }
  }
};
export const activateGoogle = () => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "activateGoogle"
  });
  const {
    user: {
      sellerId
    },
    google: {
      activationError
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "activating google");
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(activationError)) {
      dispatch({
        type: GoogleActionTypes.UPDATE_GOOGLE_ACTIVATION_ERROR,
        activationError: undefined
      });
    }
    await googleShoppingClient.updateSeller(sellerId, {
      isActive: true
    });
  } catch (err) {
    const hasErrors = includes(err.code, linkAccountErrors);
    if (hasErrors) {
      dispatch({
        type: GoogleActionTypes.UPDATE_GOOGLE_ACTIVATION_ERROR,
        activationError: err.code
      });
    } else {
      notifyUserOfError({
        err,
        toastId: "googleShoppingActivationError"
      });
      logError(ctx, err);
    }
  }
};
export const deactivateGoogle = () => async (_, getState) => {
  const ctx = logStart({
    fn: "deactivateGoogle"
  });
  const {
    user: {
      sellerId
    }
  } = getState();
  try {
    await googleShoppingClient.deactivateSeller(sellerId);
    location.reload();
  } catch (err) {
    notifyUserOfError({
      err,
      toastId: "googleShoppingDeactivationError"
    });
    logError(ctx, err);
  }
};
export const updateGoogleSupplementalFeed = productFeedId => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "updateGoogleSupplementalFeed"
  });
  const {
    user: {
      sellerId
    },
    google: {
      supplementalFeedError
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "updating google supplemental feed");
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(supplementalFeedError)) {
      dispatch({
        type: GoogleActionTypes.UPDATE_GOOGLE_SUPPLEMENTAL_FEED_ERROR,
        supplementalFeedError: undefined
      });
    }
    await googleShoppingClient.updateSeller(sellerId, {
      productFeedId
    });
    batch(() => {
      dispatch(enableNext(Enable.Transition, "googleFeedAdded"));
      dispatch(activateGoogle());
    });
  } catch (err) {
    if (hasInputErrors(err.code)) {
      dispatch({
        type: GoogleActionTypes.UPDATE_GOOGLE_SUPPLEMENTAL_FEED_ERROR,
        supplementalFeedError: err.code
      });
    } else {
      notifyUserOfError({
        err,
        toastId: "googleShoppingSupplementalFeedError"
      });
      logError(ctx, err);
    }
  }
};
export const updateSelectedPrograms = ({
  isActiveForShoppingActions,
  isActiveForAds
}) => async (dispatch, getState) => {
  const ctx = logStart({
    fn: "updateSelectedPrograms"
  });
  const {
    user: {
      sellerId
    }
  } = getState();
  try {
    log.info({
      ...ctx
    }, "updating seller's google selected programs");
    await googleShoppingClient.updateSeller(sellerId, {
      isActiveForShoppingActions,
      isActiveForAds
    });
    batch(() => {
      dispatch({
        type: GoogleActionTypes.UPDATE_GOOGLE_SELLER,
        isActiveForShoppingActions,
        isActiveForAds
      });
    });
  } catch (err) {
    notifyUserOfError({
      err,
      toastId: "googleSelectProgramsError"
    });
    logError(ctx, err);
  }
};
export const getServiceAccountEmail = merchantId => async dispatch => {
  const serviceAccountEmail = await googleShoppingClient.getServiceAccountEmail(merchantId);
  dispatch({
    type: GoogleActionTypes.GOOGLE_SERVICE_ACCOUNT_EMAIL_RECEIVED,
    serviceAccountEmail
  });
};