import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { getStorageInboundDetail } from "../../../store";
import { FLEXPORT_CARRIER_CODE, FLEXPORT_TRACKING_PREFIX } from "inbounds/steps/ship/view/FlexportAutomatedTracking/constants";
import { getClosestWeekdayDate } from "common/date/getClosestWeekdayDate";
import { freightApiAdapter } from "common/clients/instances";
import React, { useEffect } from "react";
import { useMount } from "react-use";
import { toast } from "common/components/ui";
import { FreightTrackingInfoExternalIdType } from "common/clients/transportation/FreightTrackingInfo/FreightTrackingInfoExternalIdType";
import { isEqual } from "date-fns/fp";
import { validateFlexportID } from "common/FlexportValidationUtil";
export const useSendAndTrackShipmentDetail = () => {
  const {
    formatMessage
  } = useIntl();
  const {
    storageInboundOrder
  } = useSelector(getStorageInboundDetail);
  const [initialFreightInfo, setInitialFreightInfo] = React.useState();
  const [freightInfo, setFreightInfo] = React.useState();
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(true);
  const [isFlexport, setIsFlexport] = React.useState(false);
  useMount(async () => {
    if (storageInboundOrder.sellerId) {
      await loadFreightTrackingInfo();
    }
  });
  const loadFreightTrackingInfo = async () => {
    const fullFreightInfo = await freightApiAdapter.getFreightTrackingInfo(storageInboundOrder.sellerId, String(storageInboundOrder.shipmentId), FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT);
    setInitialFreightInfo({
      ...fullFreightInfo,
      estimatedShipDate: fullFreightInfo?.estimatedShipDate ? new Date(fullFreightInfo?.estimatedShipDate) : undefined
    });
    setFreightInfo({
      ...fullFreightInfo,
      estimatedShipDate: fullFreightInfo?.estimatedShipDate ? new Date(fullFreightInfo?.estimatedShipDate) : undefined
    });
    setIsFlexport(fullFreightInfo?.shippingProvider === FLEXPORT_CARRIER_CODE);
  };
  useEffect(() => {
    if ((initialFreightInfo?.bolId ?? "") !== (freightInfo?.bolId ?? "") || (initialFreightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : "")) !== (freightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : "")) || initialFreightInfo?.shippingProvider !== freightInfo?.shippingProvider || checkIfEstimatedDateChanged()) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [freightInfo]);
  const checkIfEstimatedDateChanged = () => {
    return !(initialFreightInfo?.estimatedShipDate && freightInfo?.estimatedShipDate && isEqual(initialFreightInfo?.estimatedShipDate, freightInfo?.estimatedShipDate)) && !!(initialFreightInfo?.estimatedShipDate ?? freightInfo?.estimatedShipDate);
  };
  const setProId = val => {
    if (isFlexport) {
      const validated = validateFlexportID(val);
      if (validated) {
        setFreightInfo({
          ...freightInfo,
          proId: validated
        });
      }
    } else {
      setFreightInfo({
        ...freightInfo,
        proId: val
      });
    }
  };
  const setBolId = val => {
    setFreightInfo({
      ...freightInfo,
      bolId: val
    });
  };
  const onDateChange = date => {
    setFreightInfo({
      ...freightInfo,
      estimatedShipDate: date
    });
  };
  const handleCarrierSelectionChange = option => {
    setFreightInfo({
      ...freightInfo,
      shippingProvider: option
    });
    setIsFlexport(option === FLEXPORT_CARRIER_CODE);
  };
  const minDate = getClosestWeekdayDate();
  const bolIdPlaceholder = formatMessage({
    id: "storage.inbounds.detail.sendAndTrackShipment.bolIdPlaceholder",
    defaultMessage: "Enter BOL number"
  });
  const proIdPlaceholder = formatMessage({
    id: "storage.inbounds.detail.sendAndTrackShipment.proIdPlaceholder",
    defaultMessage: "Enter PRO number"
  });
  const submitButtonMessage = formatMessage({
    id: "storage.inbounds.details.sendAndTrackShipment.submit.message",
    defaultMessage: "Save changes"
  });
  const getFreightTrackingInfoToBeSaved = () => {
    let freightTrackingInfoSaveData = {
      externalId: String(storageInboundOrder.shipmentId),
      externalIdType: FreightTrackingInfoExternalIdType.INBOUND_SHIPMENT
    };
    if ((initialFreightInfo?.bolId ?? "") !== (freightInfo?.bolId ?? "")) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        bolId: freightInfo?.bolId
      };
    }
    if ((initialFreightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : "")) !== (freightInfo?.proId ?? (isFlexport ? FLEXPORT_TRACKING_PREFIX : ""))) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        proId: freightInfo?.proId ?? ""
      };
    }
    if ((initialFreightInfo?.shippingProvider ?? "") !== (freightInfo?.shippingProvider ?? "")) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        shippingProvider: freightInfo?.shippingProvider ?? ""
      };
    }
    if (checkIfEstimatedDateChanged()) {
      freightTrackingInfoSaveData = {
        ...freightTrackingInfoSaveData,
        estimatedShipDate: freightInfo?.estimatedShipDate
      };
    }
    return freightTrackingInfoSaveData;
  };
  const onSubmit = async () => {
    const freightTrackingInfoSaveData = getFreightTrackingInfoToBeSaved();
    try {
      await freightApiAdapter.updateFreightTrackingInfo(storageInboundOrder.sellerId, freightTrackingInfoSaveData);
      setIsSubmitDisabled(true);
      toast.success(formatMessage({
        id: "storage.inbounds.details.sendAndTrackShipment.success",
        defaultMessage: "Carrier tracking details saved"
      }));
      await loadFreightTrackingInfo();
    } catch (err) {
      toast.error(formatMessage({
        id: "storage.inbounds.details.sendAndTrackShipment.error",
        defaultMessage: "Failed to save carrier tracking details"
      }));
    }
  };
  return {
    bolId: freightInfo?.bolId,
    setBolId,
    proId: freightInfo?.proId ?? undefined,
    setProId,
    bolIdPlaceholder,
    proIdPlaceholder,
    submitDisabled: isSubmitDisabled,
    onSubmit,
    date: freightInfo?.estimatedShipDate,
    onDateChange,
    minDate,
    isFlexport,
    submitButtonMessage,
    carrier: freightInfo?.shippingProvider,
    handleSelectionChange: handleCarrierSelectionChange
  };
};