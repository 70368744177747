import { inboundClient } from "Clients";
import { initialDeliverrLtlShipment } from "inbounds/store/reducers/LtlReducer";
import log, { logStart } from "Logger";

/**
 * Fetches any previous data submitted during an LTL inbound
 * Partially completed LTL state will be populated with boilerplate wherever data is missing
 * Uses existence of rate quotes to persist "last completed step" logic in step flow
 *
 * @param ltlShipmentIds an array of shipment ids of the LTL_DELIVERR shippingOption
 * @param sellerId
 */
export const loadLtlState = async (ltlShipmentIds, sellerId) => {
  const ctx = logStart({
    fn: "loadLtlState",
    sellerId,
    ltlShipmentIds
  });
  if (!ltlShipmentIds.length) {
    return {};
  }
  const ltlState = await inboundClient.getFreightStates(sellerId, ltlShipmentIds);
  const formattedLtlState = Object.keys(ltlState).reduce((newState, shipmentId) => {
    // given the shipment id map from BE, translate into state and set step boolean values based on quote existence
    const {
      pallets: palletConfigurations,
      pickup: pickupInfo,
      rateQuoteResult,
      rateBuyResult
    } = ltlState[shipmentId];
    const hasPurchasedRates = Boolean(rateBuyResult?.transactions[0]?.isSuccessful);
    const hasQuotes = Boolean(rateQuoteResult?.quotes.length);
    const {
      pickupInfo: defaultPickupInfo,
      palletConfigurations: defaultpalletConfigurations
    } = initialDeliverrLtlShipment;
    const numberOfPallets = palletConfigurations.reduce((sum, {
      identicalPallets
    }) => sum + identicalPallets, 0);
    const totalCargoWeight = palletConfigurations.reduce((sum, {
      identicalPallets,
      weight
    }) => sum + identicalPallets * weight, 0);
    newState[shipmentId] = {
      // populate pallets + pickup info with either the possibly submitted data or default boilerplate
      ...initialDeliverrLtlShipment,
      numberOfPallets,
      totalCargoWeight,
      palletConfigurations: palletConfigurations.length ? palletConfigurations : defaultpalletConfigurations,
      pickupInfo: pickupInfo ? {
        ...pickupInfo,
        liftGate: Boolean(pickupInfo?.liftGate)
      } : defaultPickupInfo,
      hasConfirmedPallets: hasPurchasedRates || hasQuotes,
      hasConfirmedPickupInfo: hasPurchasedRates || hasQuotes,
      hasConfirmedShipperInfo: hasPurchasedRates || hasQuotes,
      hasConfirmedRateQuote: hasPurchasedRates,
      quotes: rateQuoteResult?.quotes ?? [],
      selectedQuoteId: rateQuoteResult?.quotes[0]?.id ?? 0
    };
    return newState;
  }, {});
  log.info({
    ...ctx,
    formattedLtlState
  }, "Setting LTL loaded state");
  return formattedLtlState;
};