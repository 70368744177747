export let FreightListActionTypes;
(function (FreightListActionTypes) {
  FreightListActionTypes["FREIGHT_ORDERS_REQUESTED"] = "FREIGHT_ORDERS_REQUESTED";
  FreightListActionTypes["FREIGHT_ORDERS_RECEIVED"] = "FREIGHT_ORDERS_RECEIVED";
  FreightListActionTypes["FREIGHT_QUOTE_REQUESTS_REQUESTED"] = "FREIGHT_QUOTE_REQUESTS_REQUESTED";
  FreightListActionTypes["FREIGHT_QUOTE_REQUESTS_RECEIVED"] = "FREIGHT_QUOTE_REQUESTS_RECEIVED";
  FreightListActionTypes["FREIGHT_QUOTE_REQUESTS_GENERATED"] = "FREIGHT_QUOTE_REQUESTS_GENERATED";
  FreightListActionTypes["TOGGLE_LIST_VIEW"] = "TOGGLE_LIST_VIEW";
})(FreightListActionTypes || (FreightListActionTypes = {}));
export const freightOrdersLoaderId = "getFreightOrders";
export const freightQuotesLoaderId = "getFreightQuotes";
export const freightOrderDetailLoaderId = "getFreightOrderDetail";
export const freightOrderTrackingLoaderId = "getFreightOrderTracking";
export const generateFreightBookingLoaderId = "generateFreightBooking";