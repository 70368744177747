export const currencySymbols = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "Fr",
  BMD: "$",
  BND: "$",
  BOB: "Bs.",
  BOV: "MVDol",
  // Bolivian Mvdol (funds code)
  BRL: "R$",
  BSD: "$",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BZD: "$",
  CAD: "$",
  CDF: "Fr",
  CHE: "CHE",
  // WIR Euro (complementary currency)
  CHF: "Fr.",
  CHW: "CHW",
  // WIR Franc (complementary currency)
  CLF: "UF",
  // Unidad de Fomento (funds code)
  CLP: "$",
  CNH: "¥",
  // Renminbi (Offshore)
  CNY: "¥",
  COP: "$",
  COU: "COU",
  // Real Value unit
  CRC: "₡",
  CUC: "$",
  // Cuban Convertible Peso
  CUP: "$",
  // Cuban Peso
  CVE: "Esc or $",
  CZK: "Kč",
  DJF: "Fr",
  DKK: "kr",
  DOP: "$",
  DZD: "دج",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GHS: "₵",
  GIP: "£",
  GMD: "D",
  GNF: "Fr",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JMD: "$",
  JOD: "د.ا",
  JPY: "¥",
  KES: "Sh",
  KGS: "с",
  KHR: "៛",
  KMF: "Fr",
  KPW: "₩",
  KRW: "₩",
  KWD: "د.ك",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "ل.ل",
  LKR: "₨",
  LRD: "$",
  LSL: "L",
  LYD: "ل.د",
  MAD: "د.م.",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "P",
  MRU: "UM",
  MUR: "₨",
  MVR: "ރ.",
  MWK: "MK",
  MXN: "$",
  MXV: "MXV",
  // Mexican Unidad de Inversion (funds code)
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "₲",
  QAR: "﷼",
  RON: "lei",
  RSD: "дин",
  RUB: "₽",
  RWF: "Fr",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SLL: "Le",
  SOS: "Sh",
  SRD: "$",
  SSP: "£",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "L",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRY: "₺",
  TTD: "$",
  TWD: "NT$",
  TZS: "Sh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  USN: "USN",
  // US Dollar (Next day)
  UYI: "UYI",
  // Uruguayan Peso in Indexed Units
  UYU: "$",
  UYW: "UYW",
  // Unidad Previsional
  UZS: "лв",
  VED: "Bs.",
  VES: "Bs.",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "Fr",
  XAG: "XAG",
  // Silver (one troy ounce)
  XAU: "XAU",
  // Gold (one troy ounce)
  XBA: "XBA",
  // European Composite Unit
  XBB: "XBB",
  // European Monetary Unit (E.M.U.-6)
  XBC: "XBC",
  // European Unit of Account 9 (E.U.A.-9)
  XBD: "XBD",
  // European Unit of Account 17 (E.U.A.-17)
  XCD: "$",
  XDR: "XDR",
  // Special Drawing Rights
  XOF: "Fr",
  XPD: "XPD",
  // Palladium (one troy ounce)
  XPF: "Fr",
  XPT: "XPT",
  // Platinum (one troy ounce)
  XSU: "XSU",
  // SUCRE
  XTS: "XTS",
  // Code reserved for testing
  XUA: "XUA",
  // ADB Unit of Account
  XXX: "XXX",
  // No currency
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWL: "$"
};
export const DEFAULT_CURRENCY_TYPE = "USD";
export const DEFAULT_CURRENCY_SYMBOL = currencySymbols[DEFAULT_CURRENCY_TYPE];
export const currencySymbol = currencyType => {
  return currencySymbols[currencyType] || DEFAULT_CURRENCY_SYMBOL;
};