import { createReducer } from "common/ReduxUtils";
import { MediaActionTypes } from "./MediaActions";
export let MediaLoadingState;
(function (MediaLoadingState) {
  MediaLoadingState["Loading"] = "loading";
  MediaLoadingState["Loaded"] = "loaded";
  MediaLoadingState["Error"] = "error";
})(MediaLoadingState || (MediaLoadingState = {}));
export const mediaInitialState = {};
export const mediaReducer = createReducer(mediaInitialState, {
  [MediaActionTypes.MEDIA_FETCHED]: (state, {
    url
  }) => ({
    ...state,
    [url]: MediaLoadingState.Loading
  }),
  [MediaActionTypes.MEDIA_LOADED]: (state, {
    url
  }) => ({
    ...state,
    [url]: MediaLoadingState.Loaded
  }),
  [MediaActionTypes.MEDIA_LOADING_ERRORED]: (state, {
    url
  }) => ({
    ...state,
    [url]: MediaLoadingState.Error
  })
});