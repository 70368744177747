import { createSelector } from "reselect";
import { selectIsCasePack } from "./selectIsCasePack";
import { selectPlanItems } from "./selectPlanItems";

/**
 * Get the total number of case-packed boxes. Returns 0 if the Shipping Plan is not using Case Packs.
 */
export const selectCasePackTotalCaseQty = createSelector(selectIsCasePack, selectPlanItems, (isCasePack, planItems) => {
  if (!isCasePack) {
    return 0;
  }
  return Object.values(planItems).reduce((totalCases, planItem) => {
    return totalCases + planItem.numberOfCases;
  }, 0);
});