import { datadogRum } from "@datadog/browser-rum";
import { trackHeapEvent } from "common/utils/heap/trackHeapEvent";
import { EVENT_PREFIX } from "./analytics.types";

/**
 * Conditionally add properties to action/event
 * For the CreateShipment flow, the useSmbBookingContext already adds default properties to the global datadog context
 * In most cases, you will not need custom properties (refer to the SmbBookingCreationContext interface)
 */

export const trackSmbBookingCreationEvent = (event, properties) => {
  datadogRum.addAction(`${EVENT_PREFIX}.${event}`, {
    event: properties
  });
  trackHeapEvent(`${EVENT_PREFIX}.${event}`, properties);
};