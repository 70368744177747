import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import { ReturnsSellerActionTypes } from "./ReturnsSellerStore.types";
import produce from "immer";
export const returnsSellerInitialState = {
  destinationOptions: [],
  qcAddresses: [],
  fetching: false
};
const reducers = {
  ...handleSimpleReducerUpdates([ReturnsSellerActionTypes.RETURNS_SELLER_UPDATE_FAIL, ReturnsSellerActionTypes.RETURNS_SELLER_UPDATE_SUCCESS, ReturnsSellerActionTypes.RETURNS_SELLER_UPDATE_REQUEST]),
  [ReturnsSellerActionTypes.RETURNS_SELLER_RESET]: () => ({
    ...returnsSellerInitialState
  }),
  [ReturnsSellerActionTypes.RETURNS_SELLER_RESET_MESSAGE]: state => produce(state, draft => {
    draft.successMessage = undefined;
    draft.errorMessage = undefined;
  }),
  [ReturnsSellerActionTypes.RETURNS_SELLER_ADD_ADDRESS]: (state, {
    newSellerAddress
  }) => ({
    ...state,
    destinationOptions: state.destinationOptions.map(option => option.addressType === "NEW" ? newSellerAddress : option)
  }),
  [ReturnsSellerActionTypes.RETURNS_SELLER_ADD_ADDRESS]: (state, {
    newSellerAddress
  }) => produce(state, draft => {
    for (let index = 0; index < draft.destinationOptions.length; index++) {
      const option = draft.destinationOptions[index];
      if (option.addressType === "NEW") {
        draft.destinationOptions[index] = newSellerAddress;
        return;
      }
    }
  })
};
export const returnsSellerReducer = createReducer(returnsSellerInitialState, reducers);