import log from "Logger";
import history from "BrowserHistory";
import { fulfillmentInboundStepToPath } from "inbounds/navigation/fulfillment/fulfillmentInboundStepToPath";
import { setCurrentStep } from "inbounds/store/actions/navigation/setCurrentStep";
const supplyInboundPathParams = (path, shippingPlanId, shipmentId) => path.replace(":shippingPlanId", shippingPlanId ? shippingPlanId.toString() : "").replace(":shipmentId", shipmentId ? shipmentId.toString() : "");
export const goToInboundStep = (step, replace = false) => (dispatch, getState) => {
  log.info({
    fn: "goToInboundStep",
    step
  }, `sending to ${step}`);
  const {
    inbound: {
      plan,
      loadedShipmentId
    }
  } = getState();
  dispatch(setCurrentStep(step));
  const stepPath = fulfillmentInboundStepToPath[step];
  const destinationPath = supplyInboundPathParams(stepPath, plan.id, loadedShipmentId);
  if (replace) {
    history.replace(destinationPath);
  } else {
    history.push(destinationPath);
  }
};