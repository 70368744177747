import { useDispatch, useSelector } from "react-redux";
import { removeAllEmTags } from "common/StringUtils";
import { renameShippingPlan } from "inbounds/store/actions/shippingPlan/renameShippingPlan";
export const useNavbarShippingPlanName = () => {
  const dispatch = useDispatch();
  const sellerId = useSelector(state => state.user.sellerId);
  const shippingPlan = useSelector(state => state.inbound.plan);
  const {
    isPromotional
  } = shippingPlan;
  const handleSubmit = async editableName => {
    const name = removeAllEmTags(editableName);
    if (name?.length > 0 && name !== shippingPlan?.name) {
      await dispatch(renameShippingPlan({
        name,
        sellerId,
        shippingPlanId: shippingPlan.id
      }));
    }
  };
  return {
    displayName: shippingPlan.name,
    handleSubmit,
    isPromotional
  };
};