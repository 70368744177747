export let BreakPoint;
(function (BreakPoint) {
  BreakPoint["BASE"] = "BASE";
  BreakPoint["XS"] = "XS";
  BreakPoint["SM"] = "SM";
  BreakPoint["MD"] = "MD";
  BreakPoint["LG"] = "LG";
  BreakPoint["XL"] = "XL";
})(BreakPoint || (BreakPoint = {}));
export const breakpoints = {
  [BreakPoint.BASE]: "0px",
  // base
  [BreakPoint.XS]: "320px",
  // small phone
  [BreakPoint.SM]: "480px",
  // phone
  [BreakPoint.MD]: "768px",
  // tablet
  [BreakPoint.LG]: "1024px",
  // laptop
  [BreakPoint.XL]: "1280px" // massive
};

export const mediaQueries = {
  [BreakPoint.BASE]: `@media (min-width: ${breakpoints.BASE})`,
  [BreakPoint.XS]: `@media (min-width: ${breakpoints.XS})`,
  [BreakPoint.SM]: `@media (min-width: ${breakpoints.SM})`,
  [BreakPoint.MD]: `@media (min-width: ${breakpoints.MD})`,
  [BreakPoint.LG]: `@media (min-width: ${breakpoints.LG})`,
  [BreakPoint.XL]: `@media (min-width: ${breakpoints.XL})`
};