import { __, camelCase, defaultTo, pipe, prop, toUpper } from "lodash/fp";
import { ElevioArticleId } from "common/elevio";
import { RmtStepId } from "./store/RmtReducer";
import { getRoundedLogo } from "common/RoundedLogos";
const DELIVERR_URL = "https://deliverr.com";
const INTEGRATIONS_URL = `${DELIVERR_URL}/integrations`;
export const returnManagementConfig = {
  LOOP: {
    name: "Loop",
    integrationPage: `${INTEGRATIONS_URL}/loop`,
    roundedLogo: getRoundedLogo("LOOP"),
    connectRmtElevioArticleId: ElevioArticleId.ReturnsRmtConnectLoop
  },
  RETURNGO: {
    name: "ReturnGO",
    informationPage: "https://support.returngo.ai/integration-with-deliverr",
    integrationPage: `${INTEGRATIONS_URL}/returngo`,
    roundedLogo: getRoundedLogo("RETURNGO"),
    connectRmtElevioArticleId: ElevioArticleId.ReturnsRmtConnectReturnGo
  },
  AMAZON: {
    name: "Amazon",
    integrationPage: `${INTEGRATIONS_URL}/amazon`,
    roundedLogo: getRoundedLogo("AMAZON"),
    connectRmtElevioArticleId: ElevioArticleId.ReturnsRmtConnectAmazon
  },
  OTHER: {
    name: "Other",
    integrationPage: INTEGRATIONS_URL,
    roundedLogo: getRoundedLogo("UNKNOWN")
  }
};
export const standardizeListing = pipe(camelCase, toUpper);
export const rmtConfig = pipe(standardizeListing, prop(__, returnManagementConfig), defaultTo(returnManagementConfig.OTHER));
export const deriveStepId = (sellerRmts, rmtId, token) => {
  const rmt = sellerRmts.find(sellerRmt => sellerRmt.rmtId === rmtId);
  if (!rmt || token) {
    return RmtStepId.SETUP_RMT;
  }
  return RmtStepId.MANAGE_RMT;
};
export const getSellerRmtByRmtId = (sellerRmts, rmtId) => {
  return sellerRmts?.find(rmt => rmt.rmtId === rmtId);
};
export const rmtConfigProp = (listing, property) => pipe(rmtConfig, prop(property))(listing);