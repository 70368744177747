import { handleSimpleReducerUpdates } from "common/ReduxUtils";
import { fromCognito } from "common/user/CognitoConverter";
import { UserActionTypes } from "common/user/UserActions";
import { UserState } from "common/user/UserState";
import { CognitoLegacyActions } from "./CognitoReducer";
import { BoxLabelsFormat } from "common/clients/inbound/BoxLabelsFormat";
import { LabelLanguage } from "common/clients/inbound/LabelLanguage";
import { PaperSize } from "@deliverr/commons-objects";
import { DDPPreference } from "@deliverr/international-client/lib/shared/SellerSettings";

// Abstracts away cognito details

export const initialMutableSellerSettings = {
  isInternationalShippingEnabled: false
};
export const userInitialState = {
  company: "",
  email: "",
  isCatalogImported: false,
  listingTool: "",
  name: "",
  onboardingStage: {},
  phoneNumber: "",
  sellerId: "",
  state: UserState.CHECKING_LOGIN,
  updatedAt: new Date(),
  isAdmin: false,
  isOnWaitlist: false,
  isLinkedToFlexport: true,
  isPassEmailVerification: false,
  hasChargebeeAccount: false,
  groups: [],
  resultsPerPage: {},
  dealStage: "",
  source: "",
  activatedFastTag: false,
  votes: {},
  settings: {},
  splitLoaded: false,
  labelPreferences: {
    boxContentsLabels: {
      printSize: BoxLabelsFormat.THERMAL_4_X_6,
      language: LabelLanguage.ENGLISH
    },
    palletLabels: {
      printSize: PaperSize.A4,
      language: LabelLanguage.ENGLISH
    }
  },
  isMobile: false,
  internationalDDPPreference: DDPPreference.NONE,
  internationalMerchantGUID: undefined,
  ...initialMutableSellerSettings
};
const updateAttributes = (state, action) => ({
  ...state,
  ...fromCognito({
    ...action.user?.attributes,
    "cognito:groups": action.groups || state.groups
  })
});
const reducers = {
  ...handleSimpleReducerUpdates([UserActionTypes.SET_SPLIT_LOADED, UserActionTypes.SET_IS_MOBILE]),
  COGNITO_LOGGING_IN: updateAttributes,
  [CognitoLegacyActions.COGNITO_LOGIN]: updateAttributes,
  [CognitoLegacyActions.COGNITO_LOGOUT]: () => userInitialState,
  [CognitoLegacyActions.COGNITO_UPDATE_USER_ATTRIBUTES]: updateAttributes,
  [UserActionTypes.REFRESH_USER_SUCCESS]: updateAttributes,
  [UserActionTypes.UPDATE_LOCAL_ONBOARDING_STAGE]: (state, {
    onboardingStage
  }) => ({
    ...state,
    onboardingStage
  }),
  [UserActionTypes.UPDATE_USER_STATE]: (state, {
    userState
  }) => ({
    ...state,
    state: userState
  }),
  [UserActionTypes.UPDATE_LOCAL_USER]: (state, newState) => ({
    ...state,
    ...newState
  }),
  [UserActionTypes.UPDATE_EMAIL_VERIFICATION_PASS_STATUS]: (state, newState) => ({
    ...state,
    ...newState
  }),
  [UserActionTypes.VOTE]: (state, {
    id
  }) => ({
    ...state,
    votes: {
      ...state.votes,
      [id]: true
    }
  }),
  [UserActionTypes.SET_BOX_LABELS_DEFAULTS]: (state, {
    format,
    language
  }) => ({
    ...state,
    labelPreferences: {
      ...state.labelPreferences,
      boxContentsLabels: {
        printSize: format,
        language
      }
    }
  }),
  [UserActionTypes.SET_PALLET_LABELS_DEFAULTS]: (state, {
    format,
    language
  }) => ({
    ...state,
    labelPreferences: {
      ...state.labelPreferences,
      palletLabels: {
        printSize: format,
        language
      }
    }
  })
};
export const userReducer = (state = userInitialState, action) => ({
  ...(reducers[action.type] ? reducers[action.type](state, action) : state),
  ...(action.type.includes("COGNITO") && action.state ? action.state : {})
});