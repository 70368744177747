import produce from "immer";
import { concat, pipe, uniq } from "lodash/fp";
import { createReducer } from "common/ReduxUtils";
import { NOTIFICATIONS_PER_PAGE, NotificationsActionTypes } from "./NotificationsActions";
export const notificationsInitialState = {
  byId: {},
  sortedIds: [],
  nextPage: 0,
  hasNextPage: true,
  dlvrPaginationParams: {
    offset: 0,
    hasNextPage: true,
    hasError: false,
    reserveList: []
  },
  smbPaginationParams: {
    offset: 0,
    hasNextPage: true,
    hasError: false,
    reserveList: []
  },
  loading: false,
  error: false,
  viewed: false
};
const deduplicate = pipe(concat, uniq);
const reducers = {
  [NotificationsActionTypes.FETCH_NOTIFICATIONS_START]: state => produce(state, draft => {
    draft.loading = true;
  }),
  [NotificationsActionTypes.FETCH_NOTIFICATIONS_SUCCESS]: (state, {
    payload,
    dlvrPaginationParams,
    smbPaginationParams
  }) => produce(state, draft => {
    const notifications = payload;
    for (const notification of notifications) {
      draft.byId[notification.id] = notification;
    }

    // Deduplicate in case notifications changed since last page loaded,
    // resulting in an already seen notification being seen again.
    draft.sortedIds = deduplicate(draft.sortedIds, notifications.map(x => x.id));
    draft.nextPage += 1;
    draft.hasNextPage = notifications.length === NOTIFICATIONS_PER_PAGE;
    draft.loading = false;
    draft.error = false;
    if (dlvrPaginationParams) {
      draft.dlvrPaginationParams = {
        ...dlvrPaginationParams
      };
      draft.error = dlvrPaginationParams.hasError;
    }
    if (smbPaginationParams) {
      draft.smbPaginationParams = {
        ...smbPaginationParams
      };
      draft.error = draft.error || smbPaginationParams.hasError;
    }
  }),
  [NotificationsActionTypes.FETCH_NOTIFICATIONS_ERROR]: state => produce(state, draft => {
    draft.loading = false;
    draft.error = true;
  }),
  [NotificationsActionTypes.VIEW_NOTIFICATIONS]: state => produce(state, draft => {
    draft.viewed = true;
  }),
  [NotificationsActionTypes.RESET_NOTIFICATIONS]: () => notificationsInitialState
};
export const notificationsReducer = createReducer(notificationsInitialState, reducers);