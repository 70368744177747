import { useScript } from "common/hooks/useScript";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntercomChatLauncher } from "./useIntercomChatLauncher";

/**
 * The Forethought widget will offer some self-serve help options,
 * and then hand off to Intercom if the user wants to chat with a human.
 */

const hideForethoughtWidget = event => {
  if (event.data.event === "forethoughtWidgetClosed") {
    window.Forethought("widget", "hide");
  }
};
const FORETHOUGHT_SCRIPT_URL = "https://solve-widget.forethought.ai/embed.js";
export const useForethoughtChatLauncher = onLaunch => {
  const {
    email
  } = useSelector(state => state.user);
  const sellerId = useSelector(state => state.organization.sellerId);
  const [isOnIntercom, setIsOnIntercom] = useState(false);
  const attributes = useMemo(() => ({
    id: "forethought-widget-embed-script",
    type: "application/javascript",
    hidden: "true",
    "data-api-key": process.env.FORETHOUGHT_API_KEY,
    "data-builder-preview": "false",
    "data-ft-Embed-Script-Language": "en",
    "data-ft-Email": email,
    "data-ft-Seller-ID": sellerId,
    "data-ft-URL": "sellerportal.deliverr.com"
  }), [email, sellerId]);
  const status = useScript(FORETHOUGHT_SCRIPT_URL, true, attributes);

  // prep intercom, used for chat handoff
  const {
    bootIntercom
  } = useIntercomChatLauncher(onLaunch);
  useEffect(() => {
    if (status === "ready") {
      window.addEventListener("message", hideForethoughtWidget);
    }
    return () => window.removeEventListener("message", hideForethoughtWidget);
  }, [status]);
  const onClick = event => {
    event.preventDefault();
    event.stopPropagation();
    onLaunch?.();

    // If handoff to Intercom has occurred, we use the onClick to reopen the Intercom widget
    if (!isOnIntercom) {
      // open then display widget
      window.Forethought("widget", "open");
      window.Forethought("widget", "show");
      bootIntercom();
      window.Intercom("onShow", () => {
        setIsOnIntercom(true);
      });
    } else {
      // show intercom
      window.Intercom("show");
    }
  };
  return {
    onClick
  };
};