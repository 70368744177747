import { all } from "lodash/fp";
import { usePolling } from "../../../../common/hooks/usePolling";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import React, { useEffect, useState } from "react";
import { loadWholesaleOrderAction } from "../../actions/loadWholesaleOrder";
import { shipmentHasAllAttachments } from "../../../utils/shipmentHasAllAttachments";
import { MonitorStatus } from "./MonitorStatus";
const LABEL_UPLOAD_TIMEOUT = 150000; // 2.5 minutes
export const useFBAConfirmStatusMonitor = () => {
  const [monitorStatus, setMonitorStatus] = useState(MonitorStatus.POLLING);
  const {
    wholesaleOrder
  } = useSelector(selectTransferCreate);
  const orderHasAllLabelUploadedShipments = all(shipmentHasAllAttachments, wholesaleOrder?.shipments);
  useEffect(() => {
    const timer = setTimeout(() => {
      setMonitorStatus(MonitorStatus.TIMEOUT);
    }, LABEL_UPLOAD_TIMEOUT);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    if (orderHasAllLabelUploadedShipments) {
      setMonitorStatus(MonitorStatus.COMPLETE);
    }
  }, [wholesaleOrder?.shipments]);
  const loadWholesaleOrder = React.useCallback(() => dispatch(loadWholesaleOrderAction()), []);
  const dispatch = useDispatch();
  usePolling(4000, !orderHasAllLabelUploadedShipments, () => dispatch(loadWholesaleOrder));
  return {
    monitorStatus
  };
};