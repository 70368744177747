import { DimensionsError } from "./DimensionsError";
import { hasDimensionsAndWeight } from "common/utils/product/hasDimensionsAndWeight";
import { composeGetIsOverWeight } from "common/utils/product/composeGetIsOverWeight";
import { INBOUND_SPD_MAX_BOX_LONGEST, INBOUND_SPD_MAX_BOX_MIDDLE, INBOUND_SPD_MAX_BOX_SHORTEST, INBOUND_SPD_MAX_BOX_WEIGHT, INBOUND_SPD_SIOC_MIN_BOX_LONGEST, INBOUND_SPD_SIOC_MIN_BOX_MIDDLE, INBOUND_SPD_SIOC_MIN_BOX_SHORTEST, INBOUND_SPD_SIOC_MIN_BOX_WEIGHT } from "inbounds/constants/spdConstants";
import { composeGetIsOverDimensions } from "common/utils/product/composeGetIsOverDimensions";
export const getDimensionsError = product => {
  const isProductOverSized = composeGetIsOverDimensions({
    maxLongestSide: INBOUND_SPD_MAX_BOX_LONGEST,
    maxMiddleSide: INBOUND_SPD_MAX_BOX_MIDDLE,
    maxShortestSide: INBOUND_SPD_MAX_BOX_SHORTEST
  })(product);
  const isProductOverWeight = composeGetIsOverWeight(INBOUND_SPD_MAX_BOX_WEIGHT)(product);
  if (isProductOverSized && isProductOverWeight) {
    return DimensionsError.OVER_DIMENSIONS_AND_WEIGHT;
  } else if (isProductOverSized) {
    return DimensionsError.OVER_DIMENSIONS;
  } else if (isProductOverWeight) {
    return DimensionsError.OVER_WEIGHT;
  } else if (!hasDimensionsAndWeight(product)) {
    return DimensionsError.INCOMPLETE;
  } else if (composeGetIsOverDimensions({
    maxLongestSide: INBOUND_SPD_SIOC_MIN_BOX_LONGEST,
    maxMiddleSide: INBOUND_SPD_SIOC_MIN_BOX_MIDDLE,
    maxShortestSide: INBOUND_SPD_SIOC_MIN_BOX_SHORTEST
  })(product) || composeGetIsOverWeight(INBOUND_SPD_SIOC_MIN_BOX_WEIGHT)(product)) {
    return DimensionsError.SIOC;
  }
  return;
};