export let Color;
(function (Color) {
  Color["BLUE"] = "BLUE";
  Color["GREEN"] = "GREEN";
  Color["ORANGE"] = "ORANGE";
  Color["RED"] = "RED";
  Color["TEAL"] = "TEAL";
  Color["YELLOW"] = "YELLOW";
  Color["PURPLE"] = "PURPLE";
  Color["INDIGO"] = "INDIGO";
  Color["NEUTRAL"] = "NEUTRAL";
  Color["SHADOW"] = "SHADOW";
  Color["LOGO_BLUE"] = "LOGO_BLUE";
})(Color || (Color = {}));
export let Appearance;
(function (Appearance) {
  Appearance["PRIMARY"] = "PRIMARY";
  Appearance["DEFAULT"] = "DEFAULT";
  Appearance["DANGER"] = "DANGER";
  Appearance["SUCCESS"] = "SUCCESS";
  Appearance["WARNING"] = "WARNING";
  Appearance["INFO"] = "INFO";
  Appearance["LAUNCH"] = "LAUNCH";
  Appearance["CANCELLED"] = "CANCELLED";
})(Appearance || (Appearance = {}));
const BLUE = {
  /** #E8F4FF */
  "50": "#E8F4FF",
  /** #C3DDFA */
  "100": "#C3DDFA",
  /** #78B5FA */
  "200": "#78B5FA",
  /** #2D8CED */
  "300": "#2D8CED",
  /** #0D69B5 */
  "400": "#0D69B5",
  /** #0B4F87 */
  "500": "#0B4F87",
  /** #0B3A63 */
  "600": "#0B3A63"
};
const GREEN = {
  /** #E1FAF0 */
  "100": "#E1FAF0",
  /** #81EBBF */
  "200": "#81EBBF",
  /** #00C89A */
  "300": "#00C89A",
  /** #139E82 */
  "400": "#139E82",
  /** #207A70 */
  "500": "#207A70"
};
const RED = {
  /** #FFE6E3 */
  "100": "#FFE6E3",
  /** #FFA8A3 */
  "200": "#FFA8A3",
  /** #F74343 */
  "300": "#F74343",
  /** #C23439 */
  "400": "#C23439",
  /** #942831 */
  "500": "#942831"
};
const YELLOW = {
  /** #FFF8CC */
  "100": "#FFF8CC",
  /** #FAE896 */
  "200": "#FAE896",
  /** #F4C70F */
  "300": "#F4C70F",
  /** #E3A81E */
  "400": "#E3A81E",
  /** #C28F2B */
  "500": "#C28F2B"
};
const ORANGE = {
  /** #FFE6C2 */
  "100": "#FFE6C2",
  /** #FFC37A */
  "200": "#FFC37A",
  /** #F7921E */
  "300": "#F7921E",
  /** #E37A1D */
  "400": "#E37A1D",
  /** #BF5D11 */
  "500": "#BF5D11"
};
const TEAL = {
  /** #E3FFFF */
  "100": "#E3FFFF",
  /** #9BEFF2 */
  "200": "#9BEFF2",
  /** #2AD5E1 */
  "300": "#2AD5E1",
  /** #05A4B3 */
  "400": "#05A4B3",
  /** #1A7682 */
  "500": "#1A7682"
};
const PURPLE = {
  /** #F2D4FF */
  "100": "#F2D4FF",
  /** #CF8DF7 */
  "200": "#CF8DF7",
  /** #9F29E8 */
  "300": "#9F29E8",
  /** #7716B8 */
  "400": "#7716B8",
  /** #571F80 */
  "500": "#571F80"
};
const INDIGO = {
  /** #D9E5FF */
  "100": "#D9E5FF",
  /** #B2C8FF */
  "200": "#B2C8FF",
  /** #6488FF */
  "300": "#6488FF",
  /** #4358BF */
  "400": "#4358BF",
  /** #313A8C */
  "500": "#313A8C"
};
const NEUTRAL = {
  /** #FFFFFF */
  "00": "#FFFFFF",
  /** #FAFBFC */
  "20": "#FAFBFC",
  /** #F3F6F8 */
  "40": "#F3F6F8",
  /** #E9EDF2 */
  "60": "#E9EDF2",
  /** #DAE1E8 */
  "80": "#DAE1E8",
  /** #BEC8D1 */
  "100": "#BEC8D1",
  /** #98A6B2 */
  "200": "#98A6B2",
  /** #677685 */
  "300": "#677685",
  /** #45505B */
  "400": "#45505B",
  /** #262E36 */
  "500": "#262E36"
};
const LOGO_BLUE = "#00008B";

/** Black 10% opacity */
const SHADOW = "rgba(0,0,0,0.1)";
export const colors = {
  [Color.BLUE]: BLUE,
  [Color.GREEN]: GREEN,
  [Color.ORANGE]: ORANGE,
  [Color.RED]: RED,
  [Color.TEAL]: TEAL,
  [Color.YELLOW]: YELLOW,
  [Color.PURPLE]: PURPLE,
  [Color.INDIGO]: INDIGO,
  [Color.NEUTRAL]: NEUTRAL,
  [Color.SHADOW]: SHADOW,
  [Color.LOGO_BLUE]: LOGO_BLUE
};
export const colorByAppearance = {
  [Appearance.PRIMARY]: Color.BLUE,
  [Appearance.DEFAULT]: Color.BLUE,
  [Appearance.DANGER]: Color.RED,
  [Appearance.SUCCESS]: Color.GREEN,
  [Appearance.WARNING]: Color.YELLOW,
  [Appearance.INFO]: Color.NEUTRAL,
  [Appearance.LAUNCH]: Color.INDIGO,
  [Appearance.CANCELLED]: Color.SHADOW
};