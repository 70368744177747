import { useController, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "common/components/ui";
import log from "Logger";
import { getLogisticsAccountId, getSellerId } from "common/user/UserSelectors";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { productClient } from "Clients";
import history from "BrowserHistory";
export const useNewProductForm = (defaultValues = {
  name: "",
  sku: ""
}, onSuccess) => {
  const dispatch = useDispatch();
  const {
    formatMessage
  } = useIntl();
  const {
    control,
    formState: {
      isValid
    },
    handleSubmit
  } = useForm({
    mode: "onChange",
    defaultValues
  });
  const sku = useController({
    name: "sku",
    rules: {
      required: true,
      // Allow alpha-numeric characters, dashes, and underscores
      pattern: /^[a-zA-Z0-9-_]+$/
    },
    control
  });
  const name = useController({
    name: "name",
    rules: {
      required: true,
      // Allow non-empty strings that don't only contain whitespaces
      pattern: /^(?!\s+$).+/
    },
    control
  });
  const sellerId = useSelector(getSellerId);
  const logisticsAccountId = useSelector(getLogisticsAccountId);
  const onSubmit = handleSubmit(async data => {
    const ctx = {
      fn: "useNewProductForm.onSubmit",
      ...data
    };
    log.info(ctx, "Creating product");
    try {
      dispatch(addLoader("newProduct"));
      const product = await productClient.createProductV2({
        sellerId,
        logisticsAccountId,
        name: data.name,
        msku: data.sku,
        channelProductId: data.sku,
        channelId: "Manual"
      });
      onSuccess ? onSuccess(product) : history.push(`/inventory/${product.dsku}`);
      toast.success(formatMessage({
        id: "inventory.newProduct.toast.success",
        defaultMessage: "{name} has been successfully created."
      }, {
        name: data.name
      }), {
        autoClose: 5000,
        toastId: "newProductSuccess"
      });
    } catch (err) {
      log.error({
        ...ctx,
        err
      }, "Error creating product");
      toast.error(formatMessage({
        id: "inventory.newProduct.toast.error",
        defaultMessage: "Failed to create product. Please try again later."
      }), {
        autoClose: 5000,
        toastId: "newProductError"
      });
    } finally {
      dispatch(clearLoader("newProduct"));
    }
  });
  const isSubmitDisabled = !isValid;
  return {
    sku,
    name,
    isSubmitDisabled,
    onSubmit
  };
};