import { stringify } from "qs";
import { billingClient } from "common/clients/instances";
import { notifyUserOfError } from "common/ErrorToast";
import { refreshSessionIfExpired } from "common/user/TokenCheck";
import { getSellerId } from "common/user/UserSelectors";
import { signOut } from "common/utils/SSO";
import log, { logError, logStart } from "Logger";
import { billingClientV2 } from "Clients";
export let BillingActionTypes;
(function (BillingActionTypes) {
  BillingActionTypes["SET_CHARGEBEE_SESSION"] = "SET_CHARGEBEE_SESSION";
  BillingActionTypes["FETCH_BILLING_SUMMARY_START"] = "FETCH_BILLING_SUMMARY_START";
  BillingActionTypes["FETCH_BILLING_SUMMARY_SUCCESS"] = "FETCH_BILLING_SUMMARY_SUCCESS";
  BillingActionTypes["FETCH_BILLING_SUMMARY_ERROR"] = "FETCH_BILLING_SUMMARY_ERROR";
  BillingActionTypes["FETCH_INVOICES_START"] = "FETCH_INVOICES_START";
  BillingActionTypes["FETCH_INVOICES_SUCCESS"] = "FETCH_INVOICES_SUCCESS";
  BillingActionTypes["FETCH_INVOICES_ERROR"] = "FETCH_INVOICES_ERROR";
  BillingActionTypes["FETCH_CREDITS_START"] = "FETCH_CREDITS_START";
  BillingActionTypes["FETCH_CREDITS_SUCCESS"] = "FETCH_CREDITS_SUCCESS";
  BillingActionTypes["FETCH_CREDITS_ERROR"] = "FETCH_CREDITS_ERROR";
  BillingActionTypes["RESET_BILLING"] = "RESET_BILLING";
  BillingActionTypes["FETCH_PRIMARY_PAYMENT_SOURCE_START"] = "FETCH_PRIMARY_PAYMENT_SOURCE_START";
  BillingActionTypes["FETCH_PRIMARY_PAYMENT_SOURCE_SUCCESS"] = "FETCH_PRIMARY_PAYMENT_SOURCE_SUCCESS";
  BillingActionTypes["FETCH_PRIMARY_PAYMENT_SOURCE_ERROR"] = "FETCH_PRIMARY_PAYMENT_SOURCE_ERROR";
  BillingActionTypes["FETCH_CONTRA_REVENUE_START"] = "FETCH_CONTRA_REVENUE_START";
  BillingActionTypes["FETCH_CONTRA_REVENUE_SUCCESS"] = "FETCH_CONTRA_REVENUE_SUCCESS";
  BillingActionTypes["FETCH_CONTRA_REVENUE_ERROR"] = "FETCH_CONTRA_REVENUE_ERROR";
})(BillingActionTypes || (BillingActionTypes = {}));
const getChargebeeData = async (getState, path, query) => {
  const ctx = logStart({
    fn: "getChargebeeSession"
  });
  const querystring = query ? "?" + stringify(query) : "";
  await refreshSessionIfExpired();
  const {
    cognito
  } = getState();
  const idToken = cognito?.user?.signInUserSession?.idToken?.jwtToken;

  // idToken must exist
  if (!idToken) {
    log.error(ctx, "Error: idToken is null");
    await signOut();
    return;
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const response = await fetch(`${process.env.CHARGEBEE_SSO_LAMBDA_URL}/${path}${querystring}`, {
    headers: {
      Authorization: idToken
    }
  });
  if (response.ok) {
    return await response.json();
  } else {
    log.error({
      ...ctx,
      status: response.status,
      body: await response.json()
    }, "invalid chargebee response");
    throw new Error("invalid chargebee response");
  }
};
export const setChargebeeSession = () => async (dispatch, getState) => {
  const ctx = {
    fn: "setChargebeeSession"
  };
  log.info(ctx, "setting chargebee session");
  const {
    billing: {
      session
    }
  } = getState();
  const chargebeeInstance = window.Chargebee.getInstance();
  if (!session || session.expires_at * 1000 <= new Date().getTime()) {
    try {
      const newSession = await getChargebeeData(getState, "session");
      dispatch({
        type: BillingActionTypes.SET_CHARGEBEE_SESSION,
        chargebeeSession: newSession
      });
      chargebeeInstance.setPortalSession(newSession);
    } catch (err) {
      log.error({
        ...ctx,
        err
      }, "error getting chargebee portal session");
      notifyUserOfError({
        err,
        explanation: "Error getting billing details",
        toastId: "getBillingDetailsError"
      });
    }
  } else {
    chargebeeInstance.setPortalSession(session);
  }
};

// https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-portal-instance-object
const openSection = async (dispatch, section, onPaymentAdd) => {
  await dispatch(setChargebeeSession());
  const instance = window.Chargebee.getInstance();
  const portal = instance.createChargebeePortal();
  const callbacks = onPaymentAdd ? {
    paymentSourceAdd: onPaymentAdd
  } : {};
  portal.openSection({
    sectionType: window.Chargebee.getPortalSections()[section]
  }, callbacks);
};
export const showBillingAddress = () => async dispatch => await openSection(dispatch, "ADDRESS");
export const showPaymentMethods = onPaymentAdd => async dispatch => await openSection(dispatch, "PAYMENT_SOURCES", onPaymentAdd);
export const fetchBillingSummary = () => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const loading = state.billing.summary.loading;
  if (loading) {
    return;
  }
  const ctx = logStart({
    fn: "fetchBillingSummary"
  });
  dispatch({
    type: BillingActionTypes.FETCH_BILLING_SUMMARY_START
  });
  try {
    const result = await billingClientV2.getBillingSummary(sellerId);
    log.info(ctx, "successfully retrieved billing summary");
    dispatch({
      type: BillingActionTypes.FETCH_BILLING_SUMMARY_SUCCESS,
      payload: result
    });
  } catch (err) {
    logError(ctx, err);
    dispatch({
      type: BillingActionTypes.FETCH_BILLING_SUMMARY_ERROR
    });
  }
};
export const DEFAULT_INVOICES_PER_PAGE = 25;
export const fetchInvoices = page => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const pageSize = state.user.resultsPerPage.billing ?? DEFAULT_INVOICES_PER_PAGE;
  const loading = state.billing.invoices.loading;
  if (loading) {
    return;
  }
  const limit = pageSize;
  const offset = pageSize * page;
  const ctx = logStart({
    fn: "fetchInvoices"
  });
  dispatch({
    type: BillingActionTypes.FETCH_INVOICES_START
  });
  try {
    const {
      invoices
    } = await billingClientV2.getInvoiceHistory(sellerId, limit, offset);
    log.info(ctx, "successfully retrieved invoices");
    dispatch({
      type: BillingActionTypes.FETCH_INVOICES_SUCCESS,
      payload: {
        invoices,
        page
      }
    });
  } catch (err) {
    logError(ctx, err);
    dispatch({
      type: BillingActionTypes.FETCH_INVOICES_ERROR
    });
  }
};
export const CREDITS_PER_PAGE = 20;
export const fetchCredits = () => async (dispatch, getState) => {
  const state = getState();
  if (!state.billing.credits.hasNextPage || state.billing.credits.loading) {
    return;
  }
  const sellerId = getSellerId(state);
  const limit = CREDITS_PER_PAGE;
  const offset = state.billing.credits.nextPage * CREDITS_PER_PAGE;
  const ctx = logStart({
    fn: "fetchCredits"
  });
  dispatch({
    type: BillingActionTypes.FETCH_CREDITS_START
  });
  try {
    const {
      creditHistory: list
    } = await billingClientV2.getCreditsHistory(sellerId, limit, offset);
    log.info(ctx, "successfully retrieved credits");
    dispatch({
      type: BillingActionTypes.FETCH_CREDITS_SUCCESS,
      payload: list
    });
  } catch (err) {
    logError(ctx, err);
    dispatch({
      type: BillingActionTypes.FETCH_CREDITS_ERROR
    });
  }
};
export const fetchContraRevenueHistoryForInvoice = invoiceId => async (dispatch, getState) => {
  const state = getState();
  if (state.billing.contraRevenue.loading) {
    return;
  }
  const sellerId = getSellerId(state);
  const ctx = logStart({
    fn: "fetchContraRevenueItemsForInvoice"
  });
  dispatch({
    type: BillingActionTypes.FETCH_CONTRA_REVENUE_START
  });
  try {
    const contraRevenueItems = await billingClientV2.getContraRevenueItemsForInvoice(sellerId, invoiceId.toString());
    log.info(ctx, "successfully retrieved contra revenue items for invoice");
    dispatch({
      type: BillingActionTypes.FETCH_CONTRA_REVENUE_SUCCESS,
      payload: contraRevenueItems
    });
  } catch (err) {
    logError(ctx, err);
    dispatch({
      type: BillingActionTypes.FETCH_CONTRA_REVENUE_ERROR
    });
  }
};
export const resetBilling = () => async dispatch => {
  dispatch({
    type: BillingActionTypes.RESET_BILLING
  });
  dispatch(fetchBillingSummary());
};
export const fetchPrimaryPaymentSource = () => async (dispatch, getState) => {
  const state = getState();
  const sellerId = getSellerId(state);
  const loading = state.billing.primaryPaymentSource.loading;
  if (loading) {
    return;
  }
  const ctx = logStart({
    fn: "fetchPrimaryPaymentSource"
  });
  dispatch({
    type: BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_START
  });
  try {
    const result = await billingClient.getPrimaryPaymentSource(sellerId);
    log.info(ctx, "successfully retrieved primary payment source");
    dispatch({
      type: BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_SUCCESS,
      payload: result
    });
  } catch (err) {
    logError(ctx, err);
    dispatch({
      type: BillingActionTypes.FETCH_PRIMARY_PAYMENT_SOURCE_ERROR
    });
  }
};