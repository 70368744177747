import { PrepType } from "@deliverr/prep-service-client";
import { setBclSelection } from "prep/store";
import { checkIfPrepRequestHasAllSpecifiedPrepTypes } from "prep/util/checkIfPrepRequestHasAllSpecifiedPrepTypes";
import { selectIsBclPrepEnabledForFlow } from "inbounds/store/selectors/boxLabels/selectIsBclPrepEnabledForFlow";
import { getLoadedShipmentId } from "inbounds/store/selectors/shipments";
import { selectLoadedShipmentHasConfirmedBoxingChoice } from "inbounds/store/selectors/shipments/selectLoadedShipmentHasConfirmedBoxingChoice";
import { BoxContentLabelingOption } from "inbounds/types/BoxContentLabelingOption";
/**
 * Given a PrepRequest, update the related Prep data.
 * Note: must be called after loading shipment info.
 */
export const initializeBclPrepContext = prepRequest => (dispatch, getState) => {
  const state = getState();
  const isBclPrepEnabledForFlow = selectIsBclPrepEnabledForFlow(state);
  const hasBclPrepRequest = checkIfPrepRequestHasAllSpecifiedPrepTypes(prepRequest, PrepType.BOX_CONTENT_LABELS);
  const loadedShipmentId = getLoadedShipmentId(state);
  const hasConfirmedBoxingChoice = selectLoadedShipmentHasConfirmedBoxingChoice(state);

  // use combination of states to determine which Box Contents Labeling option should currently be selected
  let prepBclOption;
  if (!isBclPrepEnabledForFlow) {
    prepBclOption = BoxContentLabelingOption.DIY;
  } else if (hasBclPrepRequest) {
    prepBclOption = BoxContentLabelingOption.DELIVERR_PREP;
  } else if (hasConfirmedBoxingChoice) {
    prepBclOption = BoxContentLabelingOption.DIY;
  } else {
    prepBclOption = BoxContentLabelingOption.DELIVERR_PREP;
  }
  dispatch(setBclSelection(prepBclOption, loadedShipmentId));
};