import { Network } from "relay-runtime";
import md5 from "md5";
import fetchRetryBuilder from "fetch-retry";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { getBearerToken } from "../clients/core/getBearerToken";
import GraphQLError, { USER_DEACTIVATED_MESSAGE } from "./GraphQLError";
const fetchRetry = fetchRetryBuilder(fetch);
const isPersistedQueriesEnabled = true;
export default function createNetworkWithMiddleware(middlewares, onError = () => {}) {
  const fetchQuery = async function fetchQuery(operation, variables, _cacheConfig, uploadables, _logRequestInfo) {
    const graphqlUrl = mustBeDefined(process.env.FLEXPORT_GRAPHQL_URL);
    const credentialsMode = graphqlUrl.startsWith("/") ? "same-origin" : "include";
    const jwt = await getBearerToken();
    const baseHeaders = graphqlUrl.startsWith("/") ? {} : {
      Authorization: `Bearer ${jwt}`
    };
    const baseRequest = {
      operation,
      variables,
      uploadables,
      fetchOpts: {
        headers: baseHeaders,
        body: "",
        credentials: credentialsMode,
        method: "POST",
        retryOn: [502,
        // https://support.cloudflare.com/hc/en-us/articles/115003011431-Troubleshooting-Cloudflare-5XX-errors#502504error,
        503,
        // https://support.cloudflare.com/hc/en-us/articles/115003011431-Troubleshooting-Cloudflare-5XX-errors#503error
        504,
        // https://support.cloudflare.com/hc/en-us/articles/115003011431-Troubleshooting-Cloudflare-5XX-errors#502504error,
        520,
        // https://support.cloudflare.com/hc/en-us/articles/200171936-Error-520-Web-server-is-returning-an-unknown-error
        521,
        // https://support.cloudflare.com/hc/en-us/articles/200171916-Error-521-Web-server-is-down
        522,
        // https://support.cloudflare.com/hc/en-us/articles/200171906-Error-522-Connection-timed-out
        524 // https://support.cloudflare.com/hc/en-us/articles/200171926-Error-524-A-timeout-occurred
        ],

        retries: 3
      },
      graphqlUrl
    };
    const {
      text
    } = operation;
    let {
      id: queryHash
    } = operation;
    if (text != null && queryHash == null) {
      queryHash = md5(text);
    }
    if (uploadables) {
      const formData = new FormData();
      if (isPersistedQueriesEnabled && queryHash != null) {
        formData.append("query_hash", queryHash);
      } else if (text != null) {
        formData.append("query", text);
      }
      formData.append("variables", JSON.stringify(variables));
      const entries = Object.entries(uploadables);
      entries.forEach(([key, file]) => {
        if (file instanceof Blob) {
          formData.append(`uploadables[${key}]`, file);
        }
      });
      baseRequest.fetchOpts.body = formData;
    } else {
      baseRequest.fetchOpts.headers["content-type"] = "application/json";
      if (isPersistedQueriesEnabled) {
        baseRequest.fetchOpts.body = JSON.stringify({
          query_hash: queryHash,
          variables
        });
      } else {
        // NOTE: Passing in both query hash and text will result in an error from GraphQL Gateway
        // which prioritizes the hash value.  The hash value is currently null since we do not
        // have a working mechanism to build/publish the persisted query hashes.
        baseRequest.fetchOpts.body = JSON.stringify({
          query: text,
          variables
        });
      }
    }
    const middlewareCalls = [];

    // Check if middleware exist, we allow null and undefined middlewares for developer convenience
    const filteredMiddlewares = middlewares.filter(
    // This 'Boolean' syntax is for Flow:
    // https://stackoverflow.com/questions/44131502/filtering-an-array-of-maybe-nullable-types-in-flow-to-remove-null-values
    Boolean);
    filteredMiddlewares.forEach((middleware, index) => {
      middlewareCalls.push(async receivedRequest =>
      /* Each middleware calls the next middleware with the updated request,
       * getting the request and being allowed to manipulate it before
       * returning The last "middleware" is the fetch request itself, added
       * below. Then the response travels back up the middleware, starting
       * from the last middleware to first. Each middleware is also allowed
       * to manipulate the response */
      await middleware.call(receivedRequest, async forwardedRequest => await middlewareCalls[index + 1](forwardedRequest)));
    });
    middlewareCalls.push(async receivedRequest => {
      try {
        const fetchResponse = await fetchRetry(receivedRequest.graphqlUrl, receivedRequest.fetchOpts);

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const {
          ok,
          status
        } = fetchResponse;
        const requestId = fetchResponse.headers.get("x-request-id");
        if (ok) {
          const contentType = fetchResponse.headers.get("content-type");
          if (contentType == null || contentType.includes("application/json")) {
            try {
              const jsonBody = await fetchResponse.json();
              const {
                data,
                errors
              } = jsonBody;
              if (!errors) {
                return jsonBody;
              }
              const deactivatedErrors = errors.filter(e => e.message === USER_DEACTIVATED_MESSAGE);
              if (deactivatedErrors.length !== 0) {
                return await Promise.reject(new Error(USER_DEACTIVATED_MESSAGE));
              }
              if (operation.operationKind === "mutation") {
                return jsonBody;
              }

              // extract and log the errors
              let errorsArray;
              if (typeof errors === "string") {
                errorsArray = [errors];
              } else if (Array.isArray(errors)) {
                errorsArray = errors;
              } else {
                errorsArray = ["Graphql Error"];
              }
              const error = new GraphQLError("Backend", errorsArray, operation, variables, requestId,
              // If there are errors and data we have a partial response.
              // PartialResponseMiddleware can consume this enable partial
              // responses
              data ? {
                partialResponse: jsonBody
              } : {});
              if (receivedRequest.graphqlUrl.startsWith("http://localhost")) {
                // eslint-disable-next-line no-console
                console.error(error);
              }
              onError(error, "relay/environment");
              throw error;
            } catch (e) {
              const error = e;
              if (error instanceof GraphQLError) {
                throw error;
              }
              const level = error instanceof TypeError ? "Transport" : "Backend";
              throw new GraphQLError(level, [error], operation, variables, requestId);
            }
          } else {
            const errorMsg = `Unsupported content-type: ${contentType}`;
            let textBody;
            try {
              textBody = await fetchResponse.text();
            } catch (error) {
              throw new GraphQLError("Transport", [errorMsg, error], operation, variables, requestId);
            }
            throw new GraphQLError("Backend", [errorMsg], operation, variables, requestId, {
              body: textBody
            });
          }
        } else {
          let error;
          try {
            const jsonBody = await fetchResponse.json();
            const errors = jsonBody?.errors;
            if (Array.isArray(errors)) {
              error = new GraphQLError("Backend", errors, operation, variables, requestId);
            } else {
              error = new GraphQLError("Backend", [`Got unexpected http status ${status} for graphqlUrl ${receivedRequest.graphqlUrl}`], operation, variables, requestId);
            }
          } catch {
            error = new GraphQLError("Backend", [`Got unexpected http status ${status} for graphqlUrl ${receivedRequest.graphqlUrl}`], operation, variables, requestId);
          }
          if (operation.operationKind !== "mutation") {
            try {
              const textBody = await fetchResponse.text();
              onError(error, "relay/environment", {
                body: textBody
              });
            } catch {
              onError(error, "relay/environment");
            }
          }
          throw error;
        }
      } catch (error) {
        if (error instanceof GraphQLError) {
          throw error;
        }
        throw new GraphQLError("Transport", [error], operation, variables);
      }
    });
    return await middlewareCalls[0](baseRequest);
  };
  return {
    fetchQuery,
    network: Network.create(fetchQuery)
  };
}