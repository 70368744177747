import { useFeatureOn } from "common/Split";

/**
 * A helper hook for gating features based on the state of two features that are dependent on each other.
 * The state of the child feature flag is going to be checked regardless of the state of the parent feature flag.
 *
 * @param parentFeatureName Name of the "umbrella" feature flag that controls a set of more granular features.
 * @param childFeatureName Name of a feature flag that is dependent on the parent feature flag.
 * @returns Logical AND of the two feature flags.
 */
export const useCompositeFeatureOn = (parentFeatureName, childFeatureName) => {
  const isParentFeatureEnabled = useFeatureOn(parentFeatureName);
  const isChildFeatureEnabled = useFeatureOn(childFeatureName);
  return isParentFeatureEnabled && isChildFeatureEnabled;
};