import useSWR from "swr";
import { transportationClient } from "common/clients/instances";
import { logError } from "Logger";
const REQUEST_KEY = "crossdockInboundQuoteFallbackDimsAndWeights";
const DIM_FALLBACKS_REVALIDATE_INTERVAL_MS = 5 * 60 * 1000; // 5min

/**
 * Get fallback dims and weights from the transportation service,
 * but restrict requests to only every 5min.
 *
 * @returns fallbacks for unit weight and volume
 */
export const useCrossdockDimsAndWeightsFallbacks = () => {
  const {
    data
  } = useSWR(REQUEST_KEY, async () => await transportationClient.getCostEstimatorParams(), {
    // only request this data once every 5 min regardless of how many times the client is called
    dedupingInterval: DIM_FALLBACKS_REVALIDATE_INTERVAL_MS,
    onError: err => {
      logError({
        fn: "useCrossdockDimsAndWeightsFallbacks"
      }, err, "error fetching CrossdockInboundQuote dims and weights fallbacks");
    }
  });
  return data;
};