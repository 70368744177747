import { useSelector } from "react-redux";
import { useAsyncFn } from "react-use";
import { getSellerId } from "common/user/UserSelectors";
import { handleInvalidPermissions } from "./errors";
import { getProfile, checkPermissions } from "../connect/actions/FacebookActions";
import { getAuthCredentials } from "../model/Auth";
import { useSPDispatch } from "common/ReduxUtils";
export const useFacebookOnLogin = () => {
  const dispatch = useSPDispatch();
  const sellerId = useSelector(getSellerId);
  const [, onSuccessfulLogin] = useAsyncFn(async authResponse => {
    const {
      userId,
      accessToken
    } = getAuthCredentials(authResponse);
    void dispatch(getProfile(userId, accessToken));
    const hasValidPermissions = await dispatch(checkPermissions(sellerId, userId, accessToken));
    if (!hasValidPermissions) {
      handleInvalidPermissions();
    }
  }, [dispatch, sellerId]);
  return {
    onSuccessfulLogin
  };
};