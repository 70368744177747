import { Marketplace } from "common/Marketplace";
import { getRoundedLogo } from "common/RoundedLogos";
import { __, camelCase, includes, intersection, pipe, prop, toUpper } from "lodash/fp";
import { removeEmTag } from "./StringUtils";
export const marketplaceConfig = {
  [Marketplace.SHOPIFY]: {
    name: "Shopify",
    logo: getRoundedLogo("SHOPIFY")
  },
  [Marketplace.SHEIN]: {
    name: "Shein",
    logo: getRoundedLogo("SHEIN")
  },
  [Marketplace.WALMART]: {
    name: "Walmart",
    logo: getRoundedLogo("WALMART")
  },
  [Marketplace.EBAY]: {
    name: "eBay",
    logo: getRoundedLogo("EBAY")
  },
  [Marketplace.WISH]: {
    name: "Wish",
    logo: getRoundedLogo("WISH")
  },
  [Marketplace.AMAZON]: {
    name: "Amazon",
    logo: getRoundedLogo("AMAZON")
  },
  [Marketplace.GOOGLE]: {
    name: "Google",
    logo: getRoundedLogo("GOOGLE")
  },
  [Marketplace.DELIVERR]: {
    name: "Flexport",
    logo: getRoundedLogo("DELIVERR")
  },
  [Marketplace.FACEBOOK]: {
    name: "Facebook",
    logo: getRoundedLogo("FACEBOOK")
  },
  [Marketplace.BIGCOMMERCE]: {
    name: "BigCommerce",
    logo: getRoundedLogo("BIGCOMMERCE")
  },
  [Marketplace.WOOCOMMERCE]: {
    name: "WooCommerce",
    logo: getRoundedLogo("WOOCOMMERCE")
  },
  [Marketplace.ETSY]: {
    name: "Etsy",
    logo: getRoundedLogo("ETSY")
  },
  [Marketplace.MAGENTO]: {
    name: "Magento",
    logo: getRoundedLogo("MAGENTO")
  },
  [Marketplace.TARGET]: {
    name: "Target",
    logo: getRoundedLogo("TARGET")
  },
  [Marketplace.NEWEGG]: {
    name: "NewEgg",
    logo: getRoundedLogo("NEWEGG")
  },
  [Marketplace.INSTAGRAM]: {
    name: "Instagram",
    logo: getRoundedLogo("INSTAGRAM")
  },
  [Marketplace.ARTEZA]: {
    name: "Arteza",
    logo: getRoundedLogo("ARTEZA")
  },
  [Marketplace.OVERSTOCK]: {
    name: "Overstock",
    logo: getRoundedLogo("OVERSTOCK")
  },
  [Marketplace.JCPENNEY]: {
    name: "JCPenney",
    logo: getRoundedLogo("JCPENNEY")
  },
  [Marketplace.JANE]: {
    name: "Jane",
    logo: getRoundedLogo("JANE")
  },
  [Marketplace.AIRSHOP]: {
    name: "Airshop",
    logo: getRoundedLogo("AIRSHOP")
  },
  [Marketplace.KROGER]: {
    name: "Kroger",
    logo: getRoundedLogo("KROGER")
  },
  [Marketplace.SKUPREME]: {
    name: "Skupreme",
    logo: getRoundedLogo("SKUPREME")
  },
  [Marketplace.UNKNOWN]: {
    name: "UNKNOWN",
    logo: getRoundedLogo("UNKNOWN")
  }
};
const standarizeMarketplace = pipe(camelCase, toUpper);
export const parseMarketplaceId = marketplaceId => {
  const taglessMarketplaceId = toUpper(removeEmTag(marketplaceId));
  const possibleMarketplaceNames = taglessMarketplaceId.split(" ");
  const marketplaces = Object.keys(Marketplace);
  const matchedMarketplace = intersection(marketplaces, possibleMarketplaceNames);
  if (matchedMarketplace.length > 0) {
    return matchedMarketplace[0];
  }
  return marketplaces.reduce((acc, val) => {
    if (includes(val, taglessMarketplaceId)) {
      acc = val;
    }
    if (!marketplaceConfig[acc]) {
      acc = Marketplace.UNKNOWN;
    }
    return acc;
  }, taglessMarketplaceId);
};
export const marketplaceListingConfig = pipe(parseMarketplaceId, standarizeMarketplace, prop(__, marketplaceConfig));