import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
import { WebsiteFastTagError } from "./WebsiteFastTagError";
export class WebsiteFastTagClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.WEBSITE_FAST_TAG_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL: `${baseURL}/v1`
    });
  }
  async getWebsiteSeller(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/website/seller/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }
  async getServiceLevelCoverageForProduct(dsku) {
    try {
      return await this.apiClient.get({
        url: `/dsku/${dsku}/coverage/servicelevel`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }
  async getExperimentEnrollmentsForSeller(sellerId) {
    try {
      return await this.apiClient.get({
        url: `/experiment/enrollments/seller/${sellerId}`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }
  async setExperimentEnrollment(experimentName, sellerId, storeName, isActive) {
    try {
      return await this.apiClient.put({
        url: `/experiment/${experimentName}/seller/${sellerId}/store/${storeName}`,
        body: {
          isActive
        },
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err, [WebsiteFastTagError.NOT_FOUND]);
    }
  }
}