import styled from "@emotion/styled";
export const InboundStepTable = styled.table(({
  theme
}) => `
    border-collapse: collapse;

    td {
      padding: ${theme.spacing.S4};
    }

    tr td {
      &:first-of-type {
        width: 100%;
      }

      &:not(:first-of-type) {
        white-space: nowrap;
      }
    }

    thead td {
      border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    }

    label {
      margin-bottom: 0;
    }
  `);