import { FeatureName, isFeatureOn } from "common/Split";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { mapReplenishOrderSkusToPackSkus } from "transfers/utils/mapReplenishOrderSkusToPackSkus";
export const populateReplenishmentOrderWithPacks = (replenishmentOrder, mergeProductPacks = false) => async dispatch => {
  const isMultiCasePackEnabled = isFeatureOn(FeatureName.StorageMultiCasePack);
  return isMultiCasePackEnabled || mergeProductPacks ? await handleMultiCasePack(dispatch, replenishmentOrder) : replenishmentOrder;
};
const handleMultiCasePack = async (dispatch, replenishmentOrder) => {
  const replenishmentOrderItems = replenishmentOrder.orderItems ?? [];
  const orderSkus = (replenishmentOrderItems ?? []).map(item => item.dsku);
  const unifiedProducts = await fetchMultiPackProductsByDskus(orderSkus, dispatch);
  return mapReplenishOrderSkusToPackSkus(replenishmentOrder, unifiedProducts);
};