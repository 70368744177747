import { inboundClient } from "Clients";
import { logError, logStart } from "Logger";
import { setHasInactiveLabels } from "./setHasInactiveLabels";
export const loadHasInactiveLabels = (sellerId, shippingPlanId) => async dispatch => {
  const ctx = logStart({
    fn: "loadHasInactiveLabels",
    sellerId,
    shippingPlanId
  });
  try {
    const hasInactiveLabels = await inboundClient.hasInactiveShipmentsWithLabels(sellerId, shippingPlanId);
    dispatch(setHasInactiveLabels(hasInactiveLabels));
  } catch (err) {
    logError(ctx, err);
  }
};