import { productClient } from "Clients";
import log from "Logger";
import { addStorageProductDetails } from "./SelectStorageProductsActions";
export const getStorageItemDetails = products => async dispatch => {
  try {
    const dskus = products.map(product => product.dsku);
    const productDetails = await productClient.getUnifiedProducts(dskus);
    dispatch(addStorageProductDetails(productDetails));
  } catch (err) {
    log.error({
      err
    }, "error getting storage item details");
  }
};