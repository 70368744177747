import { Destination } from "@deliverr/commons-objects";
import { isInternationalAddress } from "../../AddressUtils";
import { isAddressInAkHiOther, isMilitaryAddress } from "@deliverr/commons-utils";
export const getDestinationParams = address => {
  // The order of these checks matter because there can be overlap between them
  if (isAddressInAkHiOther(address) || isMilitaryAddress(address)) {
    return Destination.NON_CONTIGUOUS_US;
  }
  if (isInternationalAddress(address)) {
    return Destination.INTERNATIONAL;
  }
  return Destination.CONTIGUOUS_US;
};