import { validate as validateUPC } from "barcoder";
import { isString } from "lodash/fp";
export let BarcodeFormat; // PO# / AsnId
(function (BarcodeFormat) {
  BarcodeFormat["DSKU"] = "dsku";
  BarcodeFormat["UPC"] = "upc";
  BarcodeFormat["FNSKU"] = "fnsku";
  BarcodeFormat["PONUM"] = "ponum";
})(BarcodeFormat || (BarcodeFormat = {}));
export let BarcodeFormatErrors;
(function (BarcodeFormatErrors) {
  BarcodeFormatErrors["UNKNOWN"] = "";
})(BarcodeFormatErrors || (BarcodeFormatErrors = {}));
const barcodeFormatToLength = {
  [BarcodeFormat.DSKU]: /^.{11}$/,
  // D + 10 chars
  [BarcodeFormat.FNSKU]: /^.{10}$/,
  // X or F + 9 chars
  [BarcodeFormat.UPC]: /^.{12,14}$/,
  [BarcodeFormat.PONUM]: /^.{1,}$/
};
const barcodePatterns = {
  [BarcodeFormat.DSKU]: /^D\w{10}$/,
  // starts w/ "D", followed by 10 alphanumeric characters
  [BarcodeFormat.FNSKU]: /^B\w{9}$|^X00\w{7}$/,
  // starts w/ "B" (asin) followed by 9 or "X00" (fnsku) followed by 7, alphanumeric characters
  [BarcodeFormat.UPC]: /^\d{12,14}$/,
  // 12-14 numeric characters (12 upc, 13 ean/upc, 14 upc)
  [BarcodeFormat.PONUM]: /^\d{1,}$/ // one or more numeric characters
};

export const getBarcodeFormat = barcode => {
  if (!barcode) {
    return BarcodeFormatErrors.UNKNOWN;
  }
  const matchingPattern = Object.entries(barcodePatterns).find(([_, regex]) => Boolean(barcode.match(regex)));
  return matchingPattern && matchingPattern[0];
};
export function isValidBarcodeLength(format, barcode) {
  return Boolean(barcode.match(barcodeFormatToLength[format]));
}
export const validateBarcode = (format, barcode) => {
  if (!isString(barcode) || barcode.match(barcodePatterns[format]) === null) {
    return false;
  }
  if (BarcodeFormat.UPC === format) {
    // somehow .d.ts files are not working in jest
    return validateUPC(barcode);
  }
  return true;
};
export const limitInputBarcodeLength = (format, barcode) => {
  const maxLengthIndex = {
    [BarcodeFormat.DSKU]: 11,
    [BarcodeFormat.FNSKU]: 10,
    [BarcodeFormat.UPC]: 14
  }[format];
  return barcode.slice(0, maxLengthIndex);
};
const BARCODE_FEE_PER_SKU = 56;
const BARCODE_FEE_PER_UNIT = 0.25;
export const calculateBarcodeFee = (numOfSkus, numOfUnits) => numOfSkus * BARCODE_FEE_PER_SKU + numOfUnits * BARCODE_FEE_PER_UNIT;
export const deliverrBarcodeFormatToJsBarcodeFormat = (barcode, format) => format === BarcodeFormat.UPC ? barcode.length === 12 || barcode.length === 14 ? "UPC" : "EAN13" : "CODE128";
export const isAsin = (barcode = "") => {
  return barcode.match(/^B\w{9}$/) !== null;
};