import { nonComplianceDynamicRoutes } from "inbounds/non-compliance/NonComplianceRoutes";
import { ProblemType } from "common/problems/ProblemType";
export const getNonComplianceFlowRoute = problemType => {
  switch (problemType) {
    case ProblemType.UNKNOWN_BARCODE:
      return nonComplianceDynamicRoutes.unknownBarcode;
    case ProblemType.MISSING_BARCODE:
      return nonComplianceDynamicRoutes.missingBarcode;
    default:
      return null;
  }
};