import { debounce } from "lodash/fp";
import { saveInboundLocally, savePlannedPackagesLocally, savePlannedShipmentLocally } from "inbounds/steps/InboundLocalStorage";
import log, { logStart, logError, logSuccess } from "Logger";
const INBOUND_DEBOUNCE_INTERVAL = 3000;
const debouncedSaveInbound = debounce(INBOUND_DEBOUNCE_INTERVAL, async (_, getState) => {
  const {
    inbound: {
      fromAddress,
      plan,
      plannedShipments,
      plannedPackages,
      step
    },
    productCompliance: {
      completedShippingPlanIds
    }
  } = getState();
  const ctx = logStart({
    fn: "saveInbound",
    shippingPlanId: plan.id,
    step
  });
  log.info(ctx, "saving inbound");
  try {
    const inbound = {
      fromAddress,
      completedShippingPlanIds,
      step
    };
    saveInboundLocally(inbound, plan.id);
    plannedShipments.ids.forEach(shipmentId => savePlannedShipmentLocally(plannedShipments.byId[shipmentId]));
    savePlannedPackagesLocally(plannedPackages, plan.id);
    logSuccess(ctx, "inbound saved");
  } catch (err) {
    if (err?.name.includes("QuotaExceededError")) {
      log.warn({
        ...ctx,
        err
      }, "localStorage character quota exceeded");
    } else {
      logError(ctx, err, "error during save");
    }
  }
});
export const saveInbound = () => debouncedSaveInbound;
export const dispatchThenSaveInbound = action => (...args) => async dispatch => {
  await dispatch(action(...args));
  await dispatch(saveInbound());
};