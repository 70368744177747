import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getStorageInboundCreate } from "storage/inbounds/create/store/selector/getStorageInboundCreate";
import { StoragePickupInputNames } from "./StoragePickupInputValues";
import { storagePickupDetailsLabels, cargoPickupDetailsLabels } from "./storagePickupDetailsLabels";
import { getHours, setHours } from "date-fns";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { updateStorageInboundOrder } from "../store/actions/updateStorageInboundOrder";
import { setFreightOrderDetails } from "../selectShipping/actions/setFreightOrderDetails";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { useIntl } from "react-intl";
import { isInvalidUSCanadaPhoneNumber } from "@deliverr/commons-utils";
import { emailRegexPattern } from "common/regex";
import { isEmpty } from "lodash/fp";
import { isValidPhoneNumber } from "common/forms/PhoneUtils";
const START_OF_DAY_HOURS = 8;
const END_OF_DAY_HOURS = 18;
export const useStorageAddPickupDetails = () => {
  const dispatch = useDispatch();
  const {
    freightOrderDetails
  } = useSelector(getStorageInboundCreate);
  const {
    fromAddress
  } = useSelector(state => state.inbound);
  const {
    goToNextStep,
    goToPreviousStep
  } = useStorageInboundNavigation();
  const {
    formatMessage
  } = useIntl();
  const today = new Date();
  const startOfToday = new Date(today.setHours(START_OF_DAY_HOURS));
  const endOfToday = new Date(today.setHours(END_OF_DAY_HOURS));
  const formHandler = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      [StoragePickupInputNames.FIRST_NAME]: freightOrderDetails?.pickupDetails?.location?.name?.split(" ")[0],
      [StoragePickupInputNames.LAST_NAME]: freightOrderDetails?.pickupDetails?.location?.name?.split(" ")[1],
      [StoragePickupInputNames.EMAIL]: freightOrderDetails?.pickupDetails?.location?.email,
      [StoragePickupInputNames.PHONE_NUMBER]: freightOrderDetails?.pickupDetails?.location?.phone,
      [StoragePickupInputNames.READY_DATE]: freightOrderDetails?.pickupDetails?.appointment?.startTime,
      [StoragePickupInputNames.PICKUP_REFERENCE_NUMBER]: freightOrderDetails?.customerReference,
      [StoragePickupInputNames.OPERATING_HOURS]: {
        startTime: String(getHours(new Date(freightOrderDetails?.pickupDetails?.appointment?.startTime ?? startOfToday))),
        endTime: String(getHours(new Date(freightOrderDetails?.pickupDetails?.appointment?.endTime ?? endOfToday)))
      },
      [StoragePickupInputNames.LIFTGATE_REQUIRED]: !!freightOrderDetails?.pickupDetails?.requiresLiftGate
    }
  });
  const currentFormData = formHandler.watch();
  const {
    formState: {
      errors
    },
    register
  } = formHandler;
  const pickupDateInputProps = {
    ...register(StoragePickupInputNames.READY_DATE, {
      required: "required"
    }),
    errorMessage: cargoPickupDetailsLabels.readyDateError,
    hasError: !!errors?.READY_DATE?.message,
    label: cargoPickupDetailsLabels.readyDateLabel,
    helpText: cargoPickupDetailsLabels.readyDateHelpText
  };
  const onNextClick = async () => {
    const appointmentDate = currentFormData[StoragePickupInputNames.READY_DATE];
    const facilityOperatingHours = currentFormData[StoragePickupInputNames.OPERATING_HOURS];
    const requiresLiftGate = !!currentFormData[StoragePickupInputNames.LIFTGATE_REQUIRED];
    const firstName = currentFormData[StoragePickupInputNames.FIRST_NAME];
    const lastName = currentFormData[StoragePickupInputNames.LAST_NAME];
    const email = currentFormData[StoragePickupInputNames.EMAIL];
    const phoneNumber = currentFormData[StoragePickupInputNames.PHONE_NUMBER];
    const pickupReferenceNumber = currentFormData[StoragePickupInputNames.PICKUP_REFERENCE_NUMBER];
    const startTime = appointmentDate && setHours(appointmentDate, parseInt(facilityOperatingHours?.startTime ?? "0"));
    const endTime = appointmentDate && setHours(appointmentDate, parseInt(facilityOperatingHours?.endTime ?? "12"));
    await dispatch(setFreightOrderDetails({
      pickupDetails: {
        appointment: {
          startTime,
          endTime
        },
        requiresLiftGate,
        workingHours: {
          startTime,
          endTime
        },
        location: {
          ...fromAddress,
          name: `${firstName} ${lastName}`,
          email,
          phone: phoneNumber
        }
      },
      customerReference: pickupReferenceNumber
    }));
    dispatch(addLoader(InboundLoaderId.transition));
    if (await dispatch(updateStorageInboundOrder())) {
      await goToNextStep();
    }
    dispatch(clearLoader(InboundLoaderId.transition));
  };
  const onPreviousClick = () => {
    goToPreviousStep();
  };
  const isInValidPhoneNumberInput = !!(currentFormData?.PHONE_NUMBER && (!isValidPhoneNumber(currentFormData?.PHONE_NUMBER, {
    countryCode: "US",
    isStrict: true
  }) || isInvalidUSCanadaPhoneNumber(currentFormData?.PHONE_NUMBER)));
  const isNextDisabled = [StoragePickupInputNames.READY_DATE, StoragePickupInputNames.EMAIL, StoragePickupInputNames.FIRST_NAME, StoragePickupInputNames.LAST_NAME, StoragePickupInputNames.OPERATING_HOURS, StoragePickupInputNames.PHONE_NUMBER].some(field => !currentFormData[field]) || !isEmpty(errors) || isInValidPhoneNumberInput;
  const customerReferenceNumberInputProps = {
    ...register(StoragePickupInputNames.PICKUP_REFERENCE_NUMBER),
    label: cargoPickupDetailsLabels.pickupReferenceNumber,
    isOptional: true
  };
  const firstNameInputProps = {
    ...register(StoragePickupInputNames.FIRST_NAME, {
      required: formatMessage({
        id: "addressInput.firstName.invalid",
        defaultMessage: "First name is required."
      })
    }),
    label: storagePickupDetailsLabels.firstName,
    isOptional: false,
    hasError: !!errors?.FIRST_NAME?.message,
    errorMessage: errors?.FIRST_NAME?.message
  };
  const lastNameInputProps = {
    ...register(StoragePickupInputNames.LAST_NAME, {
      required: formatMessage({
        id: "addressInput.lastName.invalid",
        defaultMessage: "Last name is required."
      })
    }),
    hasError: !!errors?.LAST_NAME?.message,
    errorMessage: errors?.LAST_NAME?.message,
    label: storagePickupDetailsLabels.lastName,
    isOptional: false
  };
  const emailAddressInputProps = {
    ...register(StoragePickupInputNames.EMAIL, {
      required: formatMessage({
        id: "addressInput.email.invalid",
        defaultMessage: "Email is invalid."
      }),
      pattern: {
        value: emailRegexPattern,
        message: formatMessage({
          id: "addressInput.email.invalid",
          defaultMessage: "Email is invalid."
        })
      }
    }),
    label: storagePickupDetailsLabels.email,
    hasError: !!errors?.EMAIL?.message,
    errorMessage: errors?.EMAIL?.message,
    isOptional: false
  };
  const liftgateRequiredCheckboxProps = {
    ...register(StoragePickupInputNames.LIFTGATE_REQUIRED),
    label: cargoPickupDetailsLabels.liftgateRequired
  };
  const phoneNumberInputProps = {
    ...register(StoragePickupInputNames.PHONE_NUMBER),
    label: storagePickupDetailsLabels.phoneNumber,
    initialCountry: "us",
    hasError: isInValidPhoneNumberInput,
    errorMessage: formatMessage({
      id: "addressInput.phoneNumber.invalid",
      defaultMessage: "Phone number is invalid."
    })
  };
  const handleFacilityOperatingHoursInputProps = {
    ...register(StoragePickupInputNames.OPERATING_HOURS),
    hasMinRange: true
  };
  return {
    handleFacilityOperatingHoursInputProps,
    phoneNumberInputProps,
    firstNameInputProps,
    lastNameInputProps,
    emailAddressInputProps,
    formHandler,
    pickupDateInputProps,
    customerReferenceNumberInputProps,
    liftgateRequiredCheckboxProps,
    onNextClick,
    onPreviousClick,
    isNextDisabled
  };
};