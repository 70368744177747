import styled from "@emotion/styled";
export const DetailItemSection = styled.dl(({
  theme,
  darken
}) => `
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: ${theme.spacing.S2} 0;
    padding: ${theme.spacing.S5};
    border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
    border-radius: ${theme.border.radius.R4};
    background-color: ${darken ? theme.colors.NEUTRAL[20] : ""};
  `);