import { cargoPalletDetailsInitialState } from "freight/store/cargoPalletDetails/reducer/cargoPalletDetailsInitialState";
import { freightBookingAccordionStepsInitialState } from "freight/store/accordionSteps/reducer/freightBookingAccordionStepsInitialState";
import { OrderStatus } from "freight/types";
import { freightGeneratedOrdersInitialState } from "./freightGeneratedOrderReducer";
import { pickupDetailsInitialState } from "freight/store/pickupDetails/reducer/freightPickupDetailsInitialState";
import { deliveryDetailsInitialState } from "freight/store/deliveryDetails/reducer/freightDeliveryDetailsInitialState";
import { drayageDetailsInitialState } from "freight/store/drayageDetails/reducer/drayageDetailsInitialState";
import { drayageCargoDetailsInitialState } from "freight/store/drayageCargoDetails/reducer/drayageCargoDetailsInitialState";
import { fbaDeliveryDetailsInitialState } from "freight/store/fbaDeliveryDetails/reducer/freightFbaDeliveryDetailsInitialState";
export const freightBookingInitialState = {
  cargoPalletDetails: cargoPalletDetailsInitialState,
  freightPickupDetails: pickupDetailsInitialState,
  freightDeliveryDetails: deliveryDetailsInitialState,
  drayageDetails: drayageDetailsInitialState,
  drayageCargoDetails: drayageCargoDetailsInitialState,
  freightFbaDeliveryDetails: fbaDeliveryDetailsInitialState,
  bookingSteps: freightBookingAccordionStepsInitialState,
  status: OrderStatus.DRAFT,
  quote: null,
  freightOrderId: null,
  generatedOrders: freightGeneratedOrdersInitialState
};