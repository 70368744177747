/**
 * Based on https://gist.github.com/douglascayers/346e061fb7c1f38da00ee98c214464ae
 */

import { DeliverrError } from "@deliverr/commons-objects";

/**
 * The function you pass to `asyncPoll` should return a promise
 * that resolves with object that satisfies this interface.
 *
 * The `done` property indicates to the async poller whether to
 * continue polling or not.
 *
 * When done is `true` that means you've got what you need
 * and the poller will resolve with `data`.
 *
 * When done is `false` that means you don't have what you need
 * and the poller will continue polling.
 */

export async function asyncPoll(
/**
 * Function to call periodically until it resolves or rejects.
 *
 * It should resolve as soon as possible indicating if it found
 * what it was looking for or not. If not then it will be reinvoked
 * after the `pollInterval` if we haven't timed out.
 *
 * Rejections will stop the polling and be propagated.
 */
fn,
/**
 * Milliseconds to wait before attempting to resolve the promise again.
 * The promise won't be called concurrently. This is the wait period
 * after the promise has resolved/rejected before trying again for a
 * successful resolve so long as we haven't timed out.
 *
 * Default 5 seconds.
 */
pollInterval = 1000,
/**
 * Max time to keep polling to receive a successful resolved response.
 * If the promise never resolves before the timeout then this method
 * rejects with a timeout error.
 *
 * Default 30 seconds.
 */
pollTimeout = 30 * 1000, timeoutErrorCode) {
  const endTime = new Date().getTime() + pollTimeout;
  const checkCondition = (resolve, reject) => {
    Promise.resolve(fn()).then(result => {
      const now = new Date().getTime();
      if (result.isDone) {
        resolve(result.data);
      } else if (now < endTime) {
        setTimeout(checkCondition, pollInterval, resolve, reject);
      } else {
        reject(new DeliverrError({
          message: "Polling timed out",
          code: timeoutErrorCode ?? "POLL_TIMEOUT"
        }));
      }
    }).catch(err => {
      reject(err);
    });
  };
  return await new Promise(checkCondition);
}