import { createReducer, handleSimpleReducerUpdates } from "common/ReduxUtils";
import produce from "immer";
import { FreightOrderDetailActionTypes } from "./actions/FreightOrderDetailActionTypes";
export const freightOrderDetailInitialState = {
  detail: null,
  tracking: null,
  trackingError: null,
  bol: {},
  error: null
};
export const freightOrderReducer = createReducer(freightOrderDetailInitialState, {
  ...handleSimpleReducerUpdates([FreightOrderDetailActionTypes.FREIGHT_ORDER_DETAIL_RECEIVED, FreightOrderDetailActionTypes.FREIGHT_ORDER_DETAIL_ERRORED]),
  [FreightOrderDetailActionTypes.FREIGHT_BOL_RECEIVED]: (state, {
    bolId,
    isBolComplete,
    url
  }) => produce(state, draftState => {
    draftState[bolId] = {
      url: url.customerBolUrl,
      isBolComplete
    };
  }),
  [FreightOrderDetailActionTypes.FREIGHT_ORDER_TRACKING_ERRORED]: (state, {
    error
  }) => produce(state, draftState => {
    draftState.trackingError = error;
    draftState.tracking = null;
  }),
  [FreightOrderDetailActionTypes.FREIGHT_ORDER_TRACKING_RECEIVED]: (state, {
    tracking
  }) => produce(state, draftState => {
    draftState.error = null;
    draftState.trackingError = null;
    draftState.tracking = tracking;
  })
});