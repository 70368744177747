import { defineMessages } from "react-intl";
export let LotInformationError;
(function (LotInformationError) {
  LotInformationError["LOT_NUMBER_REQUIRED"] = "LOT_NUMBER_REQUIRED";
  LotInformationError["LOT_NUMBER_INVALID"] = "LOT_NUMBER_INVALID";
  LotInformationError["EXPIRATION_DATE_REQUIRED"] = "EXPIRATION_DATE_REQUIRED";
  LotInformationError["EXPIRATION_DATE_INVALID"] = "EXPIRATION_DATE_INVALID";
})(LotInformationError || (LotInformationError = {}));
const ID_PREFIX = "inbounds.lotInformationError";
export const LOT_INFORMATION_ERROR_LABELS = defineMessages({
  [LotInformationError.LOT_NUMBER_REQUIRED]: {
    id: `${ID_PREFIX}.lotNumberRequired`,
    defaultMessage: "Enter a valid lot number"
  },
  [LotInformationError.LOT_NUMBER_INVALID]: {
    id: `${ID_PREFIX}.lotNumberInvalid`,
    defaultMessage: "Enter a valid lot number"
  },
  [LotInformationError.EXPIRATION_DATE_REQUIRED]: {
    id: `${ID_PREFIX}.expirationDateRequired`,
    defaultMessage: "Enter a valid date"
  },
  [LotInformationError.EXPIRATION_DATE_INVALID]: {
    id: `${ID_PREFIX}.expirationDateInvalid`,
    defaultMessage: "Enter a valid date"
  }
});