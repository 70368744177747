import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { isAuthenticated } from "../core/authentication";
import { mapError } from "../core/mapError";
export class TransportationClient {
  constructor({
    baseURL
  } = {
    baseURL: mustBeDefined(process.env.TRANSPORTATION_SERVICE_URL)
  }) {
    this.apiClient = new ApiClient({
      baseURL
    });
  }
  async getCostEstimatorParams() {
    try {
      return await this.apiClient.get({
        url: `/v1/inbound/params`,
        authentication: isAuthenticated
      });
    } catch (err) {
      throw mapError(err);
    }
  }
}