import { compact } from "lodash/fp";
import log from "Logger";
import { warehouseClient } from "common/clients/instances";
export let DeliverrActionTypes;

// load warehouses that aren't currently loaded
(function (DeliverrActionTypes) {
  DeliverrActionTypes["LOAD_WAREHOUSES"] = "LOAD_WAREHOUSES";
})(DeliverrActionTypes || (DeliverrActionTypes = {}));
export const loadWarehouses = warehouseIds => async (dispatch, getState) => {
  const ctx = {
    fn: "loadWarehouses",
    warehouseIds
  };
  log.info(ctx, "loading warehouses");
  const {
    deliverr: {
      warehouses
    }
  } = getState();
  const idsToLoad = warehouseIds.filter(warehouseId => !warehouses[warehouseId]);
  if (idsToLoad.length === 0) {
    return;
  }
  try {
    dispatch({
      type: DeliverrActionTypes.LOAD_WAREHOUSES,
      warehouses: compact(await warehouseClient.getWarehouses(idsToLoad))
    });
  } catch (err) {
    log.error({
      ...ctx,
      err
    }, "error loading warehouses");
  }
};