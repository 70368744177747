import { useIntl } from "react-intl";
import { toast } from "common/components/ui";
import useSWRImmutable from "swr/immutable";
import { fetchIsFlexportUserConnected } from "../user/UserActions";
import { useSPDispatch, useSPSelector } from "common/ReduxUtils";
import { getFlexportUser } from "./FlexportUserSelectors";
import { getEffectiveUserEmail } from "common/user/UserSelectors";
import { useEffect } from "react";
import isUndefined from "lodash/isUndefined";
export const FLEXPORT_USER_CONNECTED_QUERY_KEY = "flexportUser.isUserConnected";

// This hook checks if the user is connected to Flexport
export const useIsUserConnectedWithFlexport = () => {
  const {
    formatMessage
  } = useIntl();
  const userEmail = useSPSelector(getEffectiveUserEmail);
  const flexportUser = useSPSelector(getFlexportUser);
  const {
    isUserConnectedWithFlexport
  } = flexportUser;
  const dispatch = useSPDispatch();
  const {
    data,
    error,
    isValidating,
    mutate: revalidate
  } = useSWRImmutable(isUserConnectedWithFlexport ? null : [FLEXPORT_USER_CONNECTED_QUERY_KEY, userEmail], async () => await fetchIsFlexportUserConnected(userEmail), {
    errorRetryCount: 3,
    onError: () => {
      toast.error(formatMessage({
        id: "inbounds.flexportUser.connectionCheck.error",
        defaultMessage: "There was an issue checking your account's international freight eligibility. Try refreshing the page."
      }), {
        toastId: "sendEmailVerificationError"
      });
    }
  });
  useEffect(() => {
    // SWR's onSuccess may not always get called due to this hook being remounted in multiple places
    if (!isUndefined(data) && data !== isUserConnectedWithFlexport) {
      dispatch({
        type: "SET_IS_USER_CONNECTED_WITH_FLEXPORT",
        isUserConnectedWithFlexport: data
      });
    }
  }, [data, isUserConnectedWithFlexport, dispatch]);

  // This isLoading is true when there is no cached data and no error. the built-in SWR loading state will return true every time it revalidates
  const isLoading = isUserConnectedWithFlexport === null && !error;
  return {
    isUserConnectedWithFlexport,
    isLoading,
    isValidating,
    revalidate
  };
};