import { selectHasConfirmedPrepBcl } from "prep/store/selectors/selectHasConfirmedPrepBcl";
import { createSelector } from "reselect";
import { selectCasePackTotalCaseQty } from "../plan/selectCasePackTotalCaseQty";
import { selectLoadedShipmentBoxes } from "./selectLoadedShipmentBoxes";

/**
 * Returns the number of boxes that have been confirmed for the loaded shipment:
 * either a case pack estimate for shipments with Prep or the number of configured boxes for non-Prep.
 */
export const selectLoadedShipmentConfirmedBoxCount = createSelector(selectLoadedShipmentBoxes, selectHasConfirmedPrepBcl, selectCasePackTotalCaseQty, (boxes, hasConfirmedPrepBcl, casePackTotalCaseQty) => {
  return hasConfirmedPrepBcl ? casePackTotalCaseQty : boxes.length;
});