import { fromCognito } from "./CognitoConverter";
export const cognitoInitialState = {};
export let CognitoLegacyActions;
(function (CognitoLegacyActions) {
  CognitoLegacyActions["COGNITO_LOGIN"] = "COGNITO_LOGIN";
  CognitoLegacyActions["COGNITO_LOGOUT"] = "COGNITO_LOGOUT";
  CognitoLegacyActions["COGNITO_UPDATE_USER_ATTRIBUTES"] = "COGNITO_UPDATE_USER_ATTRIBUTES";
})(CognitoLegacyActions || (CognitoLegacyActions = {}));
export function createCognitoStateFromUser(user) {
  const groups = user.signInUserSession?.idToken?.payload["cognito:groups"];
  return {
    user,
    attributes: user.attributes,
    ...fromCognito(user.attributes),
    groups
  };
}
export const cognitoReducer = (state = cognitoInitialState, action) => {
  const {
    type,
    ...payload
  } = action;

  // propagates all amplify event payload to cognito state
  return type?.startsWith("COGNITO") ? type === CognitoLegacyActions.COGNITO_LOGIN || type === CognitoLegacyActions.COGNITO_UPDATE_USER_ATTRIBUTES ? createCognitoStateFromUser(payload.user) : payload : state;
};