export let DimensionsError;
(function (DimensionsError) {
  DimensionsError["INCOMPLETE"] = "INCOMPLETE";
  DimensionsError["SIOC"] = "SIOC";
  DimensionsError["OVER_DIMENSIONS"] = "OVER_DIMENSIONS";
  DimensionsError["OVER_WEIGHT"] = "OVER_WEIGHT";
  DimensionsError["OVER_DIMENSIONS_AND_WEIGHT"] = "OVER_DIMENSIONS_AND_WEIGHT";
  DimensionsError["STORAGE_OVER_DIMENSIONS"] = "STORAGE_OVER_DIMENSIONS";
  DimensionsError["STORAGE_OVER_WEIGHT"] = "STORAGE_OVER_WEIGHT";
  DimensionsError["STORAGE_OVER_DIMENSIONS_AND_WEIGHT"] = "STORAGE_OVER_DIMENSIONS_AND_WEIGHT";
  DimensionsError["INVALID_DIMENSIONS_AND_WEIGHT"] = "INVALID_DIMENSIONS_AND_WEIGHT";
})(DimensionsError || (DimensionsError = {}));