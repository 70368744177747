import { inboundClient } from "Clients";
import { setShippingPlanId } from "./simpleUpdateActions";
import { logError, logStart, logSuccess } from "Logger";
import { selectBookingRequestByStep, selectCurrentStep } from "../selectors";
import { SmbBookingCreationTimedEvent } from "inbounds/createShipment/common/analytics/analytics.types";
export const createBooking = shouldSubmit => async (dispatch, getState) => {
  const state = getState();
  const currentStep = selectCurrentStep(state);
  const bookingRequest = selectBookingRequestByStep(currentStep, shouldSubmit)(state);
  const ctx = logStart({
    fn: SmbBookingCreationTimedEvent.CreateBooking,
    bookingRequest
  });
  try {
    const response = await inboundClient.createBooking(bookingRequest);
    logSuccess(ctx, "successfully created booking");
    const {
      shippingPlanId
    } = response;
    dispatch(setShippingPlanId(shippingPlanId));
    return response;
  } catch (error) {
    logError(ctx, error);
    throw error;
  }
};