import { createShipmentInitialFormState as defaults } from "inbounds/createShipment/store/CreateShipmentState";
import { _parseBookingAddress } from "./_parseBookingAddress";
import { _parseTargetDeliveryDate } from "./_parseTargetDeliveryDate";
import { getIsPlaceAddress } from "inbounds/createShipment/steps/OriginSearchStep/utils";
import { parseBookingType } from "inbounds/createShipment/store/utils/parseBookingType";
export const getP2PDestinationState = booking => {
  const {
    isP2P
  } = parseBookingType(booking.bookingType);
  const {
    to,
    destinationPortGrouping,
    destinationAddressFid
  } = booking;
  const destinationAddress = to?.fbaWarehouseAddress;
  const destination = _parseBookingAddress(destinationAddress ?? undefined, destinationPortGrouping);
  const deliveryDate = _parseTargetDeliveryDate(booking);
  const isDestinationAddress = getIsPlaceAddress(destination);
  const destinationFacilityName = isDestinationAddress ? destinationAddress?.name ?? null : null;
  return {
    deliveryDate,
    destination: isP2P ? destination : defaults.destination,
    destinationFacilityName: isP2P ? destinationFacilityName : defaults.destinationFacilityName,
    isLiftgateRequired: isP2P ? destinationAddress?.isLiftgateRequired ?? defaults.isLiftgateRequired : defaults.isLiftgateRequired,
    destinationAddressFid: destinationAddressFid ?? null
  };
};