import { getSellerId } from "common/user/UserSelectors";
import { getShippingPlanItemTotals } from "inbounds/store/selectors/plan/getShippingPlanItemTotals";
/**
 * Selector that returns a function to build a CrossdockInboundQuoteRequest
 */
export const getCrossdockQuoteRequest = state => untrustedDimsFallbacks => {
  const sellerId = getSellerId(state);
  const {
    fromAddress,
    plan
  } = state.inbound;
  const {
    totalVolume,
    totalWeight,
    totalItemQty: itemQty
  } = getShippingPlanItemTotals(state)(untrustedDimsFallbacks);
  return {
    sellerId,
    shippingPlanId: plan.id,
    fromZip: fromAddress?.zip,
    totalVolume,
    totalWeight,
    itemQty
  };
};