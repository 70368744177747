import { ShippingPartner } from "@deliverr/replenishment-client";
import { defineMessages } from "react-intl";
import { shipmentSummaryLabels } from "transfers/detail/shipmentSummary.labels";
export const shippingPartnerMessage = defineMessages({
  [ShippingPartner.DELIVERR]: shipmentSummaryLabels.methodDeliverr,
  [ShippingPartner.SELLER]: shipmentSummaryLabels.methodYourCarrier,
  [ShippingPartner.FBA]: shipmentSummaryLabels.methodFBA,
  [ShippingPartner.WFS]: shipmentSummaryLabels.methodWFS,
  [ShippingPartner.COLLECT]: shipmentSummaryLabels.methodCollect,
  UNKNOWN: shipmentSummaryLabels.methodUnknown
});