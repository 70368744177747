import idx from "idx";
export function lookupAddress(components, field, nameLength = "short_name", findHandler) {
  const finder = findHandler ? findHandler.bind(null, field) : component => component.types.includes(field);
  const foundComponent = components.find(finder);
  return idx(foundComponent, _ => _[nameLength]) ?? "";
}
export function lookupCity(components) {
  const borough = lookupAddress(components, "sublocality", "long_name"); // ex: Brooklyn, Etobicoke, Itaim Bibi
  const city = lookupAddress(components,
  // order by priority
  ["locality",
  // ex: San Francisco, Shenzhen
  "postal_town",
  // ex: London - UK in few case scenarios
  "administrative_area_level_1",
  // ex: Sao Paulo, Buenos Aires
  "country" // ex: City that are countries: Luxemburg, Singapore
  ], "long_name", (fields, component) => component.types.find(type => fields.includes(type)));
  return `${borough && `${borough} - `}${city}`;
}
export const mapGeocodeAddressToDeliverrAddress = components => ({
  name: "",
  company: "",
  street1: [lookupAddress(components, "street_number"), lookupAddress(components, "route")].join(" ").trim(),
  street2: lookupAddress(components, "subpremise"),
  state: lookupAddress(components, "administrative_area_level_1"),
  zip: lookupAddress(components, "postal_code"),
  country: lookupAddress(components, "country", "short_name"),
  city: lookupCity(components)
});